import '../inputs/neb-select';
import '../inputs/neb-textfield';

import { isRequiredIf } from '@neb/form-validators';
import { html } from 'lit';

import { phone } from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';

import { ELEMENTS as BASE_ELEMENTS, Item } from './neb-item';

export const ELEMENTS = { ...BASE_ELEMENTS };

const PHONE_TYPES = ['Mobile', 'Work', 'Home', 'Fax'];

export default class PhoneItem extends Item {
  static get properties() {
    return {
      errors: Object,
      helpers: Object,
      model: Object,
    };
  }

  static createModel() {
    return {
      number: '',
      type: '',
    };
  }

  static createSelectors() {
    return {
      children: {
        number: selectors.phoneNumber(),
        type: [isRequiredIf('number')],
      },
    };
  }

  initState() {
    super.initState();

    this.errors = {
      number: '',
      type: '',
    };

    this.helpers = {
      number: '',
      type: '',
    };

    this.model = {
      number: '',
      type: '',
    };
  }

  renderLeftContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.leftContent.id}"
        class="field"
        maxLength="14"
        name="number"
        label="Phone Number"
        .inputMode="${'numeric'}"
        .mask="${phone}"
        .value="${this.model.number}"
        .error="${this.errors.number}"
        .helper="${this.helpers.number || ' '}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  renderRightContent() {
    return html`
      <neb-select
        id="${ELEMENTS.rightContent.id}"
        class="field field-selector"
        name="type"
        label="Type"
        .items="${PHONE_TYPES}"
        .value="${this.model.type}"
        .error="${this.errors.type}"
        .helper="${this.helpers.type || ' '}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }
}

window.customElements.define('neb-phone-item', PhoneItem);
