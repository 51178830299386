export class ChargeBalancer {
  constructor({
    billedAmount = 0,
    taxRate = 0,
    allowedAmount = 0,
    adjustmentAmount = 0,
  } = {}) {
    this.__billedAmount = billedAmount;
    this.__taxRate = taxRate;
    this.__allowedAmount = allowedAmount;
    this.__adjustmentAmount = adjustmentAmount;
  }

  get billedAmount() {
    return this.__billedAmount;
  }

  set billedAmount(newBilledAmount) {
    this.__allowedAmount =
      this.__billedAmount === 0
        ? newBilledAmount
        : (this.__allowedAmount / this.__billedAmount) * newBilledAmount;

    this.__billedAmount = newBilledAmount;

    this.__adjustmentAmount =
      this.billedAmount + this.taxAmount - Math.round(this.allowedAmount);
  }

  get taxRate() {
    return this.__taxRate;
  }

  set taxRate(newTaxRate) {
    const oldTaxAmount = this.taxAmount;
    this.__taxRate = newTaxRate;
    const newTaxAmount = this.taxAmount;
    this.__allowedAmount = this.allowedAmount - oldTaxAmount + newTaxAmount;
  }

  get taxAmount() {
    return Math.round(this.billedAmount * (this.taxRate / 100));
  }

  get allowedAmount() {
    return this.__allowedAmount;
  }

  set allowedAmount(newAllowedAmount) {
    this.__adjustmentAmount =
      this.billedAmount + this.taxAmount - newAllowedAmount;

    this.__allowedAmount = Math.round(newAllowedAmount);
  }

  get adjustmentAmount() {
    return this.__adjustmentAmount;
  }

  set adjustmentAmount(newAdjustmentAmount) {
    this.__allowedAmount = Math.round(
      this.billedAmount + this.taxAmount - newAdjustmentAmount,
    );

    this.__adjustmentAmount = newAdjustmentAmount;
  }
}
