import '../neb-date-picker';
import '../neb-tooltip';
import '../neb-info';
import '../neb-pagination';
import '../controls/neb-button-action';
import '../tables/neb-table';
import '../inputs/neb-select';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import {
  openPayfacIframePopup,
  ADD_PAYMENT_METHOD_TEMPLATE,
  filterPaymentMethodsByMerchantProvider,
} from '../../../../../src/features/payfac/utils';
import { openPayment } from '../../../../../src/utils/payment-util';
import { TYPE, fetch } from '../../../../neb-api-client/src/billing-codes';
import { fetchOne } from '../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../neb-api-client/src/patient-relationship-api-client';
import { getPaymentMethods } from '../../../../neb-api-client/src/payments/payment-methods-api-client';
import { getPaymentByElectronicPaymentId } from '../../../../neb-api-client/src/payments-api-client';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING_ROW,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { parseDate, dateToRaw } from '../../../../neb-utils/date-util';
import {
  SCHEDULE_PAYMENT_STATUS,
  SCHEDULE_RUN_STATUS,
  PAYMENT_FREQUENCY_OPTIONS,
  PAYMENT_FREQUENCY,
} from '../../../../neb-utils/enums';
import {
  hasFeatureOrBeta,
  FEATURE_FLAGS,
} from '../../../../neb-utils/feature-util';
import { centsToCurrency, toNumeric } from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { required } from '../../../../neb-utils/validators';

import { ELEMENTS as ELEMENTS_BASE, NebFormOld } from './neb-form-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  nameField: { id: 'name' },
  paymentTypeDropdown: { id: 'payment-type' },
  amountField: { id: 'amount' },
  paymentFrequencyDropdown: { id: 'frequency' },
  startDatePicker: { id: 'start-date' },
  endDatePicker: { id: 'end-date' },
  paymentMethodDropdown: { id: 'payment-method' },
  addPaymentMethodButton: { id: 'add-payment-method' },
  addPaymentMethodTooltip: { id: 'add-payment-method-tooltip' },
  merchantAccountDropdown: { id: 'merchant-account' },
  linkedInfo: { id: 'linked-info' },
  linkSubscription: { id: 'link-subscription' },
  paymentsTable: { id: 'payments-table' },
  pagination: { id: 'pagination' },
};

export const PAYMENTS_TABLE_CONFIG = [
  {
    key: 'start',
    label: 'Payment Date',
    flex: css`1 0 0`,
    formatter: v => parseDate(v).format('MM/DD/YYYY'),
  },
  {
    key: 'amount',
    label: 'Payment Amount',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v),
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`1 0 0`,
    formatter: v => (v === null ? 'Scheduled' : v),
  },
];

const CONFIRM_DELETE_BASE = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Delete Recurring Payment Series',
};

const CONFIRM_STOP_BASE = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Stop Recurring Payment Series',
};

export const CONFIRM_DELETE = {
  ...CONFIRM_DELETE_BASE,
  message: html`
    Deleting the recurring payment series will remove all payments in the series
    from the recurring and scheduled payments list and the payments will not be
    processed.
    <p>Are you sure you want to delete this recurring payment series?</p>
  `,
};

export const CONFIRM_STOP = {
  ...CONFIRM_STOP_BASE,
  message: html`
    Stopping the recurring payment series will remove all future payments in the
    series from the recurring and scheduled payments list and the future
    payments will not be processed. All payments already processed will not be
    affected.
    <p>Are you sure you want to stop this recurring payment series?</p>
  `,
};

export const buildSubscriptionConfirmDeleteMessage = subscription => ({
  ...CONFIRM_DELETE_BASE,
  message: html`
    This recurring payment series is associated with the following subscription:
    <p>${subscription}</p>
    <p>
      Deleting the recurring payment series will remove all payments in the
      series from the recurring and scheduled payments list and the payments
      will not be processed.
    </p>
    <p>
      The associated subscription will be marked as inactive, and the patient
      will no longer be able to use this subscription.
    </p>
    <p>
      Are you sure you want to delete this recurring payment series and archive
      the associated subscription?
    </p>
  `,
});

export const buildSubscriptionConfirmStopMessage = subscription => ({
  ...CONFIRM_STOP_BASE,
  message: html`
    This recurring payment series is associated with the following subscription:
    <p>${subscription}</p>
    <p>
      Stopping the recurring payment series will remove all future payments in
      the series from the recurring and scheduled payments list and the future
      payments will not be processed. All payments already processed will not be
      affected.
    </p>
    <p>
      After the current subscription period ends, the associated subscription
      will be marked as inactive, and the patient will no longer be able to use
      this subscription.
    </p>
    <p>
      Are you sure you want to stop this recurring payment series and archive
      the associated subscription once the current subscription period ends?
    </p>
  `,
});

export default class NebFormScheduledPaymentRecurring extends NebFormOld {
  static get properties() {
    return {
      __merchantAccountNames: Array,
      __paymentMethodNames: Array,
      __paymentTypeNames: Array,
      __collectionState: Object,
      schedule: Array,
      patientId: String,
      merchantAccounts: Array,
      forPatientPackage: Boolean,
      patientPackage: Object,
    };
  }

  static createModel() {
    return {
      id: '',
      name: '',
      paymentType: null,
      amount: 0,
      frequency: null,
      start: null,
      end: null,
      paymentMethod: null,
      merchantAccount: null,
    };
  }

  constructor() {
    super();

    this.__initServices();
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasPaymentFrequencyFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.PAYMENT_FREQUENCY,
    );

    if (!this.__hasPaymentFrequencyFeature) {
      this.formService.apply('frequency', PAYMENT_FREQUENCY.MONTHLY);
    }
  }

  initState() {
    super.initState();

    this.__merchantAccountNames = [];
    this.__paymentMethods = [];
    this.__paymentMethodNames = [];
    this.__paymentTypeNames = [];
    this.__today = parseDate().startOf('day');
    this.merchantAccounts = [];
    this.forPatientPackage = false;
    this.schedule = [];
    this.__collectionState = CollectionService.createModel();
    this.patientPackage = null;

    this.onDelete = () => {};

    this.onStop = () => {};

    this.onOpenSubscription = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      changeFrequency: ({ value }) => {
        this.formService.apply('frequency', value);
        this.handlers.handleEndDate();
      },
      changeStartDate: ({ value }) => {
        this.formService.apply('start', value);
        this.handlers.handleEndDate();
      },
      handleEndDate: () => {
        if (
          this.state.end !== null &&
          this.state.end <
            moment(this.state.start)
              .add(...this.__interval())
              .toDate()
        ) {
          this.formService.apply('end', null);
        }
        this.__updateEndDateSelectable();
      },
      startDateSelectable: date => date > this.__today,
      addPaymentMethod: async () => {
        const { addresses, firstName, lastName } = await fetchOne(
          this.patientId,
          true,
          true,
        );

        const holderAddress =
          addresses.length > 0
            ? {
                postalCode: addresses[0].zipcode,
                address1: addresses[0].address1,
                address2: addresses[0].address2,
                city: addresses[0].city,
                state: addresses[0].state,
              }
            : {
                postalCode: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
              };

        const result = await openPayfacIframePopup({
          ...ADD_PAYMENT_METHOD_TEMPLATE,
          merchantAccounts: this.merchantAccounts,
          firstName,
          lastName,
          holderId: this.patientId,
          ...holderAddress,
        });

        if (result) {
          this.__paymentMethods = await this.__fetchPaymentMethods();

          const addedPaymentMethod = this.__paymentMethods.find(
            pm => pm.id === result.id,
          );

          const merchantAccountOption = this.__merchantAccountNames.find(
            ma => ma.id === addedPaymentMethod.merchantAccountId,
          );

          this.__setPaymentMethodItems(merchantAccountOption);

          const paymentMethodOption = this.__paymentMethodNames.find(
            pm => pm.id === addedPaymentMethod.id,
          );

          this.formService.apply('merchantAccount', merchantAccountOption);
          this.formService.apply('paymentMethod', paymentMethodOption);
        }
      },
      openSubscription: async () => {
        if (this.__dirty && !(await openDirtyPopup())) return undefined;

        return this.onOpenSubscription();
      },
      changeCollectionState: state => {
        this.__collectionState = state;
      },
      sort: (a, b) => {
        const d1 = new Date(a.start);
        const d2 = new Date(b.start);

        return d1 > d2 ? 1 : -1;
      },
      viewPayment: async (name, item, _index) => {
        if (
          this.layout === 'large' &&
          item.status === SCHEDULE_PAYMENT_STATUS.PAID
        ) {
          if (item.run.length) {
            if (this.__dirty && !(await openDirtyPopup())) return;
            const paidRun = item.run.find(
              r => r.status === SCHEDULE_RUN_STATUS.PAID,
            );

            if (paidRun.transactionId) {
              const payment = await getPaymentByElectronicPaymentId(
                paidRun.transactionId,
              );

              await openPayment({ payment, readonly: false });

              this.model = { ...this.model };
            }
          }
        }
      },
      pageChanged: index => this.__collectionService.setPageIndex(index),
      delete: async () => {
        let confirmed;

        const { patientPackageId } = this.model;

        if (this.__hasNoProcessedPayments()) {
          let confirmDelete = CONFIRM_DELETE;

          if (patientPackageId) {
            confirmDelete = buildSubscriptionConfirmDeleteMessage(
              this.patientPackage.name,
            );
          }

          confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, confirmDelete);
        } else {
          let confirmStop = CONFIRM_STOP;

          if (patientPackageId) {
            confirmStop = buildSubscriptionConfirmStopMessage(
              this.patientPackage.name,
            );
          }

          confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, confirmStop);
        }

        if (confirmed) {
          if (this.__hasNoProcessedPayments()) this.onDelete();
          else this.onStop();
        }
      },
      save: () => {
        if (this.formService.validate()) {
          const start = dateToRaw(this.state.start);
          const end = dateToRaw(this.state.end);

          let model = this.formService.buildModel();

          model = { ...model, start, end };

          this.__saving = true;
          this.onSave(model);
        }
      },
      change: e => this.__onChange(e),
    };

    this.__updateEndDateSelectable();
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: this.handlers.changeCollectionState,
        onSort: this.handlers.sort,
      },
      {
        hideInactive: false,
      },
    );
  }

  buildSelectors() {
    return {
      name: [required()],
      paymentType: [required()],
      amount: {
        format: v => centsToCurrency(v),
        unformat: v => toNumeric(v),
        validators: [
          {
            error: 'Must be greater than $0.00',
            validate: v => v !== '$0.00',
          },
          {
            error: 'Cannot exceed $200,000.00 for electronic payments',
            validate: v =>
              parseInt(Number(v.replace(/[^0-9.]+/g, '') * 100), 10) <=
              20000000,
          },
        ],
      },
      frequency: [required()],
      start: [required()],
      end: {
        validators: [
          {
            error: 'Required',
            validate: v =>
              this.forPatientPackage || this.__linkedToPackage() || v,
          },
        ],
      },
      paymentMethod: [required()],
    };
  }

  __linkedToPackage() {
    return this.model.patientPackageId && this.model.patientPackageId.length;
  }

  __updatingRecurring() {
    return this.model.id.length;
  }

  __interval() {
    switch (this.state.frequency) {
      case PAYMENT_FREQUENCY.WEEKLY:
        return [1, 'week'];
      case PAYMENT_FREQUENCY.BI_WEEKLY:
        return [2, 'weeks'];
      default:
        return [1, 'month'];
    }
  }

  __updateEndDateSelectable() {
    this.handlers.endDateSelectable = date =>
      this.state.start
        ? date.isSameOrAfter(
            parseDate(this.state.start).add(...this.__interval()),
            'day',
          )
        : false;
  }

  async __fetchPaymentMethods() {
    if (!this.merchantAccounts.length) return [];

    const relationships = await getPatientRelationshipsActiveGroup(
      this.patientId,
    );

    const paymentMethods = await getPaymentMethods(
      this.merchantAccounts[0],
      this.patientId,
      {
        fetchAll: true,
        deleted: false,
      },
      relationships.related.length > 0
        ? [relationships.primary, ...relationships.related]
        : null,
    );

    return paymentMethods;
  }

  async __fetchBillingCodes() {
    const originalItems = await fetch(TYPE.PAYMENT);

    return originalItems.filter(v => v.active);
  }

  __loadDropdownSelections() {
    const model = this.formService.buildModel();

    let paymentType = null;
    let paymentMethod = null;

    if (this.merchantAccounts.length) {
      if (model.merchantAccountId && model.merchantAccountId.length) {
        model.merchantAccount =
          this.__merchantAccountNames.find(
            account => account.id === model.merchantAccountId,
          ) || null;
      } else {
        model.merchantAccount = this.__merchantAccountNames[0];
      }
    }

    if (
      this.__paymentTypeNames.length &&
      model.paymentTypeId &&
      model.paymentTypeId.length
    ) {
      paymentType =
        this.__paymentTypeNames.find(
          paymentType => paymentType.id === model.paymentTypeId,
        ) || null;

      model.paymentType = paymentType;
    } else if (this.forPatientPackage) {
      paymentType = this.__paymentTypeNames.find(
        paymentType => paymentType.code === 'Sub',
      );

      model.paymentType = paymentType;
    }

    if (
      this.__paymentMethodNames.length &&
      model.paymentMethodId &&
      model.paymentMethodId.length
    ) {
      paymentMethod =
        this.__paymentMethodNames.find(
          paymentMethod => paymentMethod.id === model.paymentMethodId,
        ) || null;

      model.paymentMethod = paymentMethod;
    }

    this.formService.refresh(model);

    this.formService.apply('paymentType', paymentType);
    this.formService.apply('paymentMethod', paymentMethod);
  }

  async updated(changedProps) {
    if (changedProps.has('patientId') || changedProps.has('model')) {
      this.__merchantAccountNames = this.merchantAccounts
        .filter(v => v.active)
        .map(v => ({
          ...v,
          label: v.name,
        }));

      const paymentTypes = await this.__fetchBillingCodes();

      this.__paymentMethods = await this.__fetchPaymentMethods();

      this.__paymentTypeNames = paymentTypes.map(v => ({
        ...v,
        label: `${v.code} - ${v.description}`,
      }));

      this.__setPaymentMethodItems();

      this.__loadDropdownSelections();

      const { pageIndex } = this.__collectionState;

      this.__collectionService.setItems(this.schedule);

      this.__collectionService.setPageIndex(pageIndex);
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          align-items: center;
        }

        .grid-2 {
          grid-template-columns: 1fr 1fr;
        }

        .date-picker {
          width: 100%;
          height: 70px;
        }

        .grid-row-tooltip {
          display: flex;
        }

        .tooltip {
          margin-left: 10px;
        }

        .linked-info {
          padding-top: ${CSS_SPACING};
        }

        .link-subscription {
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }

        .table {
          padding-top: ${CSS_SPACING};
        }

        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  __onChange({ name, value, event }) {
    this.formService.apply(name, value);

    if (name === 'merchantAccount' && event === 'select') {
      this.__setPaymentMethodItems(value);
    }
  }

  __setPaymentMethodItems(filterByMerchantAccount) {
    let filteredPaymentMethods = this.__paymentMethods;

    if (filterByMerchantAccount || this.merchantAccounts.length) {
      const currentMerchantAccount = this.merchantAccounts.find(
        m => m.id === this.model.merchantAccountId,
      );

      const filteredPaymentMethodIds = filterPaymentMethodsByMerchantProvider(
        this.__paymentMethods,
        this.merchantAccounts,
        filterByMerchantAccount ||
          currentMerchantAccount ||
          this.merchantAccounts[0],
      ).map(({ id }) => id);

      filteredPaymentMethods = filteredPaymentMethods.filter(({ id }) =>
        filteredPaymentMethodIds.includes(id),
      );

      if (!filteredPaymentMethodIds.includes(this.state.paymentMethod?.id)) {
        this.formService.apply('paymentMethod', null);
      }
    }

    this.__paymentMethodNames = filteredPaymentMethods.map(v => ({
      ...v,
      label: v.description,
    }));
  }

  __hasNoProcessedPayments() {
    return this.schedule
      ? this.schedule.every(
          entry => entry.status === SCHEDULE_PAYMENT_STATUS.SCHEDULED,
        )
      : true;
  }

  __deleteLabel() {
    return this.__hasNoProcessedPayments() ? 'Delete' : 'Stop Payments';
  }

  renderActionBar() {
    if (this.__updatingRecurring()) {
      return html`
        <neb-action-bar
          id="${ELEMENTS.actionBar.id}"
          confirmLabel="${this.confirmLabel}"
          cancelLabel="${this.__deleteLabel()}"
          removeLabel="${this.cancelLabel}"
          .onConfirm="${this.handlers.save}"
          .onCancel="${this.handlers.delete}"
          .onRemove="${this.handlers.cancel}"
        ></neb-action-bar>
      `;
    }

    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderMethodsSection() {
    const merchantAccountField =
      this.__merchantAccountNames.length > 1
        ? html`
            <neb-select
              id="${ELEMENTS.merchantAccountDropdown.id}"
              name="merchantAccount"
              class="merchant-account"
              helper="Required"
              label="Merchant Account"
              .items="${this.__merchantAccountNames}"
              .value="${this.state.merchantAccount}"
              .onChange="${this.handlers.change}"
            ></neb-select>
          `
        : '';

    const paymentMethodField = html`
      <neb-select
        id="${ELEMENTS.paymentMethodDropdown.id}"
        name="paymentMethod"
        helper="Required"
        label="Payment Method"
        .items="${this.__paymentMethodNames}"
        .value="${this.state.paymentMethod}"
        .error="${this.errors.paymentMethod}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;

    const addPaymentMethodTooltip = html`
      <div class="grid-row-tooltip">
        <neb-button-action
          id="${ELEMENTS.addPaymentMethodButton.id}"
          label="Add Payment Method"
          .onClick="${this.handlers.addPaymentMethod}"
        ></neb-button-action>
        <neb-tooltip
          id="${ELEMENTS.addPaymentMethodTooltip.id}"
          class="tooltip"
          defaultAnchor="right"
          ><div slot="tooltip">
            Cards stored on file can be used to easily process transactions
            without requiring a card swipe. Debit cards that require a PIN for
            processing cannot be stored and can only be used by swiping with a
            card reader.
          </div>
        </neb-tooltip>
      </div>
    `;

    if (merchantAccountField) {
      return html`
        ${merchantAccountField} ${paymentMethodField}
        <div></div>
        ${addPaymentMethodTooltip}
      `;
    }
    return html`
      ${paymentMethodField} ${addPaymentMethodTooltip}
    `;
  }

  renderContent() {
    return html`
      <div class="grid grid-2">
        <neb-textfield
          id="${ELEMENTS.nameField.id}"
          name="name"
          helper="Required"
          label="Scheduled Payment Name"
          maxLength="50"
          .value="${this.state.name}"
          .error="${this.errors.name}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.paymentTypeDropdown.id}"
          name="paymentType"
          helper="Required"
          label="Payment Type"
          .items="${this.__paymentTypeNames}"
          .value="${this.state.paymentType}"
          .error="${this.errors.paymentType}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.forPatientPackage || this.__updatingRecurring()}"
        ></neb-select>

        <neb-textfield
          id="${ELEMENTS.amountField.id}"
          name="amount"
          helper="Required"
          label="Payment Amount"
          maxLength="11"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${this.state.amount}"
          .error="${this.errors.amount}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.forPatientPackage || this.__updatingRecurring()}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.paymentFrequencyDropdown.id}"
          name="frequency"
          helper="Required"
          label="Payment Frequency"
          .items="${
            this.__hasPaymentFrequencyFeature
              ? PAYMENT_FREQUENCY_OPTIONS
              : [PAYMENT_FREQUENCY.MONTHLY]
          }"
          .value="${this.state.frequency}"
          .error="${this.errors.frequency}"
          .onChange="${this.handlers.changeFrequency}"
          ?disabled="${this.forPatientPackage || this.__updatingRecurring()}"
        ></neb-select>

        <neb-date-picker
          id="${ELEMENTS.startDatePicker.id}"
          name="start"
          class="date-picker"
          helperText="Required"
          label="Start Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .selectedDate="${
            this.state.start ? parseDate(this.state.start) : null
          }"
          .invalidText="Required"
          .onChange="${this.handlers.changeStartDate}"
          .isDateSelectable="${this.handlers.startDateSelectable}"
          ?invalid="${!!this.errors.start}"
          ?disabled="${this.forPatientPackage || this.__updatingRecurring()}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.endDatePicker.id}"
          name="end"
          class="date-picker"
          helperText="${
            this.forPatientPackage || this.__linkedToPackage() ? '' : 'Required'
          }"
          label="End Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .selectedDate="${this.state.end ? parseDate(this.state.end) : null}"
          .invalidText="Required"
          .onChange="${this.handlers.change}"
          .isDateSelectable="${this.handlers.endDateSelectable}"
          ?invalid="${!!this.errors.end}"
          ?disabled="${
            !this.state.frequency ||
              !this.state.start ||
              this.forPatientPackage ||
              this.__updatingRecurring()
          }"
          momentFlag
        ></neb-date-picker>

        ${this.renderMethodsSection()}
      </div>

      ${
        this.__linkedToPackage()
          ? html`
              <div class="linked-info">
                <neb-info id="${ELEMENTS.linkedInfo.id}">
                  <span>
                    This scheduled recurring payment series is linked to a
                    <span
                      id="${ELEMENTS.linkSubscription.id}"
                      class="link-subscription"
                      @click="${this.handlers.openSubscription}"
                      >subscription</span
                    >.
                  </span>
                </neb-info>
              </div>
            `
          : ''
      }
      ${
        this.__updatingRecurring()
          ? html`
              <neb-table
                id="${ELEMENTS.paymentsTable.id}"
                class="table"
                mode="detail"
                emptyMessage="There are no payments"
                .config="${PAYMENTS_TABLE_CONFIG}"
                .onSelectRow="${this.handlers.viewPayment}"
                .model="${this.__collectionState.pageItems}"
              ></neb-table>

              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                class="pagination"
                .pageCount="${this.__collectionState.pageCount}"
                .currentPage="${this.__collectionState.pageIndex}"
                .onPageChanged="${this.handlers.pageChanged}"
              ></neb-pagination>
            `
          : ''
      }
    `;
  }
}

customElements.define(
  'neb-form-scheduled-payment-recurring',
  NebFormScheduledPaymentRecurring,
);
