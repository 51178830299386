import { openPopup } from '@neb/popup';
import { css, html, LitElement } from 'lit';
import './neb-calendar-event';

import { getQuickActions } from '../../../packages/neb-api-client/src/appointment-api-client';
import { getEncounterCharges } from '../../../packages/neb-api-client/src/charting/encounter-charge';
import * as dragDropController from '../../../packages/neb-calendar/neb-cal-drag-drop-controller';
import { APPOINTMENT_ACTIONS_ENCOUNTER_CHECK } from '../../../packages/neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  openAppointmentPage,
  openRescheduledAppointmentPage,
} from '../../../packages/neb-lit-components/src/utils/appointment-overlays-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { fetchAndOpenMatchPopup } from '../../../packages/neb-lit-components/src/utils/patients';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { capitalize } from '../../../packages/neb-utils/formatters';
import {
  calcIntervalArray,
  TIME_SLOT_HEIGHT,
} from '../../../packages/neb-utils/neb-cal-util';
import { CSS_COLOR_GREY_2, CSS_COLOR_GREY_4 } from '../../styles';
import { ResizeController } from '../../utils/calendar-event-resize-controller';
import {
  getAppointmentId,
  getQuickActionHandlers,
  handleSecondaryAction,
  fetchAndMapQuickActionAppointmentModel,
  validQuickActionResponse,
  getRescheduleQuickActionHandlers,
} from '../../utils/context/appointment-quick-action';
import { CONTEXT_KEYS, openContext } from '../../utils/context/constants';
import { SELECT_BY_TYPE } from '../../utils/scheduling/appointments';
import NebFormAppointment from '../forms/appointments/neb-form-appointment';

import { MAXIMUM_END, MINIMUM_START } from './neb-base-calendar-view';

const HOUR_IN_MS = 3600000;
const MAX_ZOOM_INTERVAL = 12;
const FREE_SPACE = 10;

export const ELEMENTS = {
  calendarEvents: { selector: 'neb-calendar-event' },
  timeSlots: { selector: '.time-slot' },
  highlightedSlots: { selector: '.highlighted-slot' },
  highlightedSlotsContainer: { id: 'highlighted-slots-container' },
  unavailableSlots: { selector: '.unavailable' },
};

class NebCalendarDayColumn extends LitElement {
  static get properties() {
    return {
      events: Array,
      __unavailableSlots: Array,
      __coloredSlots: Array,
      maskedSlots: Array,
      locationId: String,
      providerId: String,
      resourceId: String,
      date: String,
      zoomInterval: Number,
      start: Number,
      end: Number,
      mobile: Boolean,
      isDragging: Boolean,
      hasSplits: Boolean,
      isResizing: { type: Boolean, reflect: true, attribute: 'is-resizing' },
      overlay: { type: Boolean, reflect: true },
      type: {
        type: String,
        reflect: true,
      },

      __contextOpen: Boolean,
      __highlightedSlots: Array,
      __timeSlots: Array,
    };
  }

  static get styles() {
    return css`
      :host {
        display: block;
        position: relative;
        user-select: none;
      }

      :host([is-resizing]) {
        cursor: ns-resize;
      }

      .unavailable {
        position: absolute;
        left: 0;
        right: 0;
        background-color: #dedede;
        opacity: 1;

        color: #a3a3a3;
        font-size: 11px;
        padding-left: 12px;
        padding-top: 1px;
        overflow: hidden;
      }

      .colored {
        position: absolute;
        left: 0;
        right: 0;
        font-size: 11px;
        padding-left: 12px;
        padding-top: 1px;
        overflow: hidden;
        opacity: 1;
      }

      .time-slot {
        cursor: pointer;
        position: relative;
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        box-sizing: border-box;
        height: 70px;
        user-select: none;
      }

      :host([is-resizing]) .time-slot {
        cursor: ns-resize;
      }

      .time-slot:hover {
        background-color: ${CSS_COLOR_GREY_4};
      }

      .calendar-day-event {
        position: absolute;
        border-radius: 4px;
        box-sizing: border-box;
        user-select: none;
      }

      :host([is-resizing]) .calendar-day-event {
        cursor: ns-resize;
      }

      .start-time {
        opacity: 0%;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        font-size: 11px;
        padding-right: 2px;
        user-select: none;
      }

      .start-time:hover {
        opacity: 100%;
      }

      .highlighted-slot {
        box-sizing: border-box;
      }

      .highlighted-slots-container {
        position: absolute;
        top: 0;
        width: calc(100% - 2px);
        left: 1px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();
  }

  __initState() {
    this.events = [];
    this.__unavailableSlots = [];
    this.__coloredSlots = [];
    this.maskedSlots = [];
    this.zoomInterval = 2;
    this.locationId = null;
    this.providerId = null;
    this.resourceId = null;
    this.date = null;
    this.type = '';
    this.isDragging = false;
    this.mobile = false;
    this.active = true;
    this.start = MINIMUM_START;
    this.end = MAXIMUM_END;
    this.hasSplits = false;

    this.onUpdateCalendar = () => {};

    this.onUpdateDragging = () => {};

    this.onClickTimeSlot = () => {};

    this.onResizeDown = () => {};

    this.onResizeUp = () => {};

    this.onUpdateResizing = () => {};

    this.__contextOpen = false;

    this.__timeSlots = [];
    this.__highlightedSlots = [];
  }

  __initHandlers() {
    this.__handlers = {
      clickEvent: async event => {
        if (event.isBOT) {
          await openOverlay(OVERLAY_KEYS.BLOCKED_OFF_TIME_PAGE, {
            appointmentId: event.id,
          });
        } else {
          let popup = {};

          if (event.unmatched) {
            popup = await fetchAndOpenMatchPopup(event.accountId, event.id);
          }

          if (!popup.back) {
            if (event.isRescheduled) {
              await openRescheduledAppointmentPage(
                event.isSplit ? event.appointmentHistoryId : event.id,
                event.appointmentId,
              );
            } else {
              await openAppointmentPage(event.id);
            }
          }
        }

        this.onUpdateCalendar();
      },
      clickTimeSlot: async slot => {
        if (this.overlay) {
          this.onClickTimeSlot({ slot, date: this.date });
          return;
        }

        if (this.mobile) return;

        if (!this.active) {
          await this.__renderInactivePopup();

          return;
        }

        const start = parseDate(this.date)
          .hours(slot.hour)
          .minutes(slot.min);

        const duration = HOUR_IN_MS / this.zoomInterval;

        const end = start.clone().add(duration, 'ms');

        const type = this.__isInUnavailableSlot(
          slot.hour,
          slot.min,
          end.hour(),
          end.minute(),
        )
          ? SELECT_BY_TYPE.CUSTOM
          : SELECT_BY_TYPE.TIME;

        await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
          ...NebFormAppointment.createModel(),
          ...this.__getAppointmentIds(),
          duration,
          start: start.toISOString(),
          locationId: this.locationId,
          type,
        });

        this.onUpdateCalendar();
      },
      eventResizeDown: this.__resizeController.handlers.eventResizeDown,
      eventResizeUp: this.__resizeController.handlers.eventResizeUp,
      eventDragStart: ({ y, target, dataTransfer }) => {
        dataTransfer.setData('text', target.id);
        dataTransfer.dropEffect = 'move';
        dataTransfer.effectAllowed = 'move';

        dragDropController.startDragging(target, y);

        this.onUpdateDragging(true);
      },
      eventDrag: ({ y }) => {
        this.shadowRoot.getElementById(
          ELEMENTS.highlightedSlotsContainer.id,
        ).style.zIndex = '1001';

        dragDropController.updateDraggingPosition(y);
      },
      eventDragEnd: () => {
        dragDropController.removeHighlightedSlots();

        const draggedState = dragDropController.getDraggedState();

        if (draggedState) {
          dragDropController.stopDragging();
        }

        this.onUpdateDragging(false);
      },
      slotDragEnter: ({ target }) => {
        const highlightedContainer = this.shadowRoot.getElementById(
          ELEMENTS.highlightedSlotsContainer.id,
        );

        dragDropController.initializeDroppedState(target, highlightedContainer);
      },
      slotDragLeave: ({ target }) => {
        const targetQuery = target.getAttribute('query');
        const droppedState = dragDropController.getDroppedState();
        const droppedQuery = droppedState.element.getAttribute('query');

        if (targetQuery === droppedQuery) {
          dragDropController.removeHighlightedSlots();
        }
      },
      slotDragOver: e => {
        e.preventDefault();
      },
      slotDrop: async e => {
        const { target } = e;

        if (target) {
          dragDropController.removeHighlightedSlots();

          const droppedState = dragDropController.getDroppedState();

          if (!droppedState) return;

          const {
            element: { model: event },
          } = dragDropController.getDraggedState();

          if (await this.__isDropDisabled(event)) {
            return;
          }

          const firstSlot = droppedState.highlightedSlots[0];
          const lastSlot =
            droppedState.highlightedSlots[
              droppedState.highlightedSlots.length - 1
            ];

          const startHour = parseInt(firstSlot.getAttribute('hour'), 10);
          const startMinute = parseInt(firstSlot.getAttribute('minute'), 10);
          const endHour = parseInt(lastSlot.getAttribute('hour'), 10);
          const endMinute = parseInt(lastSlot.getAttribute('minute'), 10);

          const dayValue = parseDate(this.date)
            .hours(startHour)
            .minutes(startMinute);

          this.onUpdateDragging(false);
          dragDropController.clearDroppedState();

          if (event.preview) {
            this.onClickTimeSlot({
              slot: { hour: startHour, min: startMinute },
              date: this.date,
            });

            return;
          }

          const type = this.__isInUnavailableSlot(
            startHour,
            startMinute,
            endHour,
            endMinute,
          )
            ? SELECT_BY_TYPE.CUSTOM
            : SELECT_BY_TYPE.DATE;

          const model = await fetchAndMapQuickActionAppointmentModel({
            ...NebFormAppointment.createModel(),
            ...this.__getAppointmentIds(),
            id: event.id,
            locationId: this.locationId,
            start: dayValue.toISOString(),
            type,
            providerCalendarFetch: !!this.providerId,
            roomCalendarFetch: !!this.resourceId,
            splitRescheduleIndex: event.splitIndex || 0,
            hasEncounter: !!event.encounterId,
          });

          await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, model);

          this.onUpdateCalendar();
        }

        e.preventDefault();
      },
      openContext: async (e, item) => {
        if (item && item.id) {
          e.preventDefault();
          e.stopPropagation();

          if (item.unmatched) {
            const popup = await fetchAndOpenMatchPopup(item.accountId, item.id);

            if (!popup.back) {
              await openAppointmentPage(item.id);
            }

            this.onUpdateCalendar();
            return;
          }

          if (item.isRescheduled) {
            const items = getRescheduleQuickActionHandlers(item.appointmentId);

            await openContext(CONTEXT_KEYS.MENU, {
              left: e.x,
              top: e.y,
              items: [items],
              data: {},
            });

            return;
          }

          const appointmentId = getAppointmentId(item);

          const { appointment, ...actions } = await getQuickActions(
            appointmentId,
            true,
          );

          const items = getQuickActionHandlers(appointment, actions);

          if (items.length) {
            this.__contextOpen = true;
            const res = await openContext(CONTEXT_KEYS.MENU, {
              left: e.x,
              top: e.y,
              items,
              data: {},
            });

            await this.__handleQuickActionResponse(appointment, res);
          }
        }
      },
    };
  }

  __initServices() {
    this.__resizeController = new ResizeController(this, {
      onUpdateResizing: isResizing => this.onUpdateResizing(isResizing),
      onResizeDown: duration => this.onResizeDown(duration),
      onResizeUp: duration => this.onResizeUp(duration),
    });
  }

  // eslint-disable-next-line complexity
  updated(changedProps) {
    if (
      changedProps.has('zoomInterval') ||
      changedProps.has('start') ||
      changedProps.has('end')
    ) {
      this.__timeSlots = calcIntervalArray(
        this.zoomInterval,
        this.start,
        this.end,
      );

      this.__highlightedSlots = calcIntervalArray(
        MAX_ZOOM_INTERVAL,
        this.start,
        this.end,
      );

      this.__resizeController.zoomInterval = this.zoomInterval;
    }

    if (changedProps.has('isDragging')) {
      this.shadowRoot.getElementById(
        ELEMENTS.highlightedSlotsContainer.id,
      ).style.zIndex = this.isDragging ? '999' : '';
    }

    if (changedProps.has('maskedSlots') && this.maskedSlots) {
      this.__unavailableSlots = this.maskedSlots.filter(
        item => !item.available,
      );

      this.__coloredSlots = this.maskedSlots.filter(item => item.available);
    }
  }

  async __handleQuickActionResponse(item, res) {
    if (validQuickActionResponse(res)) {
      this.__contextOpen = false;
      this.onUpdateCalendar();
    }

    if (res && res.secondaryAction) {
      this.__contextOpen = false;

      const secondaryRes = await handleSecondaryAction(item, res);

      if (validQuickActionResponse(secondaryRes)) {
        this.onUpdateCalendar();
      }
    }
  }

  __getAppointmentIds() {
    const result = {};

    if (this.providerId) result.providerId = this.providerId;
    if (this.resourceId) result.resourceId = this.resourceId;

    return result;
  }

  // eslint-disable-next-line complexity
  async __isDropDisabled(event) {
    if (!this.active) {
      await this.__renderInactivePopup();

      return true;
    }

    if (event.encounterId) {
      if (this.providerId && this.providerId !== event.providerId) {
        await openPopup(
          POPUP_RENDER_KEYS.MESSAGE,
          APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_PROVIDER,
        );

        return true;
      }
    }

    const charges = event.encounterId
      ? await getEncounterCharges(event.encounterId)
      : [];

    if (event.signedEncounter && charges.some(x => x.postedToLedgerId)) {
      await openPopup(
        POPUP_RENDER_KEYS.MESSAGE,
        APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE,
      );

      return true;
    }

    if (event.signedEncounter) {
      await openPopup(
        POPUP_RENDER_KEYS.MESSAGE,
        APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_SIGNED_ENCOUNTER,
      );

      return true;
    }

    if (charges.some(x => x.postedToLedgerId)) {
      await openPopup(
        POPUP_RENDER_KEYS.MESSAGE,
        APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_POSTED_CHARGES,
      );

      return true;
    }

    return false;
  }

  __isInUnavailableSlot(startHour, startMinute, endHour, endMinute) {
    if (!this.__unavailableSlots) return false;

    const startTime = Number(startHour) + Number(startMinute) / 60;
    const endTime = Number(endHour) + Number(endMinute) / 60;

    const top = Math.round(startTime * 12) * 5;
    const bottom = Math.round(endTime * 12) * 5;

    const unavailableRanges = this.__unavailableSlots.map(slot => ({
      start: slot.top,
      end: slot.top + slot.height - 1,
    }));

    return unavailableRanges.some(
      range =>
        (range.start <= top && top <= range.end) ||
        (range.start <= bottom && bottom <= range.end),
    );
  }

  __computeStyling(layout, pxPerMin, topOffset = 0, heightOffset) {
    let { height, top } = layout;

    const leftOffset = (layout.left * FREE_SPACE) / 100;

    const widthOffset = (layout.width * FREE_SPACE) / 100;

    if (top + height > this.end) {
      height = this.end - top;
    }

    top -= this.start;

    let styles = `top: ${top * pxPerMin + topOffset}px;
    height: ${height * pxPerMin - heightOffset}px;`;

    if (layout.left) {
      styles += `left: calc(${layout.left}% + 1px - ${leftOffset}px);`;
    }

    if (layout.width) {
      styles += `width: calc(${layout.width}% - 2px - ${widthOffset}px);`;
    }

    if (layout.color) styles += `background-color: ${layout.color};`;

    return styles;
  }

  __renderInactivePopup() {
    return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
      title: 'Inactive Location',
      message: 'This location is inactive.  Please choose an active location.',
    });
  }

  __renderHighlightedSlots() {
    return this.__highlightedSlots.map(slot => {
      const height = (TIME_SLOT_HEIGHT * this.zoomInterval) / MAX_ZOOM_INTERVAL;

      return html`
        <div
          class="highlighted-slot"
          name="highlightedSlot"
          style="height: ${height}px;"
          query="time${slot.hour}_${slot.min}"
          hour="${slot.hour}"
          minute="${slot.min}"
          @dragenter="${this.__handlers.slotDragEnter}"
          @dragover="${this.__handlers.slotDragOver}"
          @dragleave="${this.__handlers.slotDragLeave}"
          @drop="${this.__handlers.slotDrop}"
        ></div>
      `;
    });
  }

  __renderTimeSlots() {
    return this.__timeSlots.map(
      slot => html`
        <div
          class="time-slot"
          .time="${slot.time}"
          @click="${() => this.__handlers.clickTimeSlot(slot)}"
        >
          <div class="start-time">${slot.time}</div>
        </div>
      `,
    );
  }

  __renderUnavailableSlots() {
    if (!this.__unavailableSlots) return '';

    const pxPerMin = (TIME_SLOT_HEIGHT * this.zoomInterval) / 60;

    return this.__unavailableSlots.map(slot => {
      if (slot.top + slot.height <= this.start) return '';
      if (slot.top >= this.end) return '';

      const styles = this.__computeStyling(slot, pxPerMin, 0, 1);

      return html`
        <div class="unavailable" style="${styles}">
          ${capitalize(this.type)} Unavailable
        </div>
      `;
    });
  }

  // eslint-disable-next-line complexity
  __getEventConfig(event, pxPerMin) {
    const styles = this.__computeStyling(event.layout, pxPerMin, 1, 3);

    const draggable =
      !event.isBOT &&
      !this.mobile &&
      (event.noShow || event.active) &&
      !this.__resizeController.isResizingDown &&
      !this.__resizeController.isResizingUp;

    const click = !event.preview
      ? () => this.__handlers.clickEvent(event)
      : undefined;

    const rightClick =
      !event.preview && !event.canceled
        ? e => this.__handlers.openContext(e, event)
        : undefined;

    return { styles, draggable, click, rightClick };
  }

  __renderColoredSlots() {
    if (!this.__coloredSlots) return '';

    const pxPerMin = (TIME_SLOT_HEIGHT * this.zoomInterval) / 60;

    return this.__coloredSlots.map(slot => {
      if (slot.top + slot.height <= this.start) return '';
      if (slot.top >= this.end) return '';

      const styles = this.__computeStyling(slot, pxPerMin, 0, 1);

      return html`
        <div class="colored" style="${styles}"></div>
      `;
    });
  }

  __renderEvents() {
    if (!this.events) return '';
    const pxPerMin = (TIME_SLOT_HEIGHT * this.zoomInterval) / 60;

    return this.events.map(event => {
      if (event.layout.top + event.layout.height <= this.start) return '';
      if (event.layout.top >= this.end) return '';

      const { styles, draggable, click, rightClick } = this.__getEventConfig(
        event,
        pxPerMin,
      );

      return html`
        <neb-calendar-event
          class="calendar-day-event"
          name="event"
          style="${styles}"
          .model="${event}"
          .contextOpen="${this.__contextOpen}"
          .isResizing="${this.isResizing}"
          .hasSplits="${this.hasSplits}"
          @click="${click}"
          @contextmenu="${rightClick}"
          @drag="${this.__handlers.eventDrag}"
          @dragstart="${this.__handlers.eventDragStart}"
          @dragend="${this.__handlers.eventDragEnd}"
          draggable="${draggable}"
          ?small="${event.layout.height * pxPerMin < 20}"
          ?preview="${event.preview}"
          .onResizeDown="${this.__handlers.eventResizeDown}"
          .onResizeUp="${this.__handlers.eventResizeUp}"
        >
        </neb-calendar-event>
      `;
    });
  }

  render() {
    return html`
      <div
        id="${ELEMENTS.highlightedSlotsContainer.id}"
        class="highlighted-slots-container"
      >
        ${this.__renderHighlightedSlots()}
      </div>

      ${this.__renderColoredSlots()} ${this.__renderUnavailableSlots()}
      ${this.__renderTimeSlots()} ${this.__renderEvents()}
    `;
  }
}

customElements.define('neb-calendar-day-column', NebCalendarDayColumn);
