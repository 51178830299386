import '../../../../packages/neb-lit-components/src/components/inputs/neb-menu';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textbox';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-text-helper';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-floating-label';
import '../field-groups/neb-pill';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  CSS_FIELD_MARGIN,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  label: { id: 'label' },
  textbox: { id: 'textbox' },
  input: { id: 'input' },
  menu: { id: 'menu' },
  helper: { id: 'helper' },
  pills: { selector: '.pill' },
};

class SelectSearchMulti extends LitElement {
  static get properties() {
    return {
      __open: {
        reflect: true,
        type: Boolean,
        attribute: 'open',
      },
      __focused: {
        reflect: true,
        type: Boolean,
        attribute: 'focused',
      },
      disabled: {
        reflect: true,
        type: Boolean,
      },
      pinLabel: {
        reflect: true,
        type: Boolean,
      },
      label: {
        reflect: true,
        type: String,
      },
      maxLength: Number,
      maxVisibleItems: Number,
      itemHeight: Number,
      thresholdCount: Number,
      name: String,
      search: String,
      helper: String,
      error: Boolean || String,
      placeholder: String,
      items: Array,
      value: Array,
      onRenderItem: Function,
      onRenderPill: Function,
      scrollOnKeydown: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: inline-block;
          height: 100px;
          --max-pill-width: 120px;
        }

        .container {
          position: relative;
          width: 100%;
          height: 100%;
          padding-top: ${CSS_FIELD_MARGIN};
        }

        :host([label='']) .container {
          padding-top: 0;
        }

        .textbox {
          position: relative;
          height: 100%;
          min-height: 0;
          max-height: 80px;
          cursor: pointer;
          padding: 0;
        }

        :host([disabled]) .textbox {
          pointer-events: none;
        }

        .pills {
          display: flex;
          overflow-y: auto;
          padding: 8px 12px;
          width: 100%;
          flex-wrap: wrap;
          align-items: baseline;
        }

        :host([focused]) .pills {
          padding: 7px 11px;
        }

        .pill {
          margin-right: 4px;
          margin-bottom: 4px;
          --max-width: var(--max-pill-width);
        }

        .input {
          border: none;
          padding: 0 ${CSS_SPACING} 0 0;
          width: fit-content;
          min-width: 48px;
          font-size: ${CSS_FONT_SIZE_BODY};
          flex: 1 0 0;
        }

        .menu {
          left: 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__open = false;
    this.__focused = false;

    this.disabled = false;
    this.pinLabel = false;
    this.maxVisibleItems = 4;
    this.maxLength = 255;
    this.itemHeight = 48;
    this.thresholdCount = 4;
    this.name = '';
    this.search = '';
    this.label = '';
    this.helper = '';
    this.error = '';
    this.placeholder = '';
    this.items = [];
    this.value = [];
    this.scrollOnKeydown = true;

    this.onChange = () => {};

    this.onSearch = () => {};

    this.onRequest = () => {};

    this.onRenderItem = null;

    this.onRenderPill = null;
  }

  __initHandlers() {
    this.__handlers = {
      request: () => this.onRequest(),
      focus: () => {
        if (!this.disabled) {
          this.__focused = true;
        }
      },
      closeMenu: () => {
        if (!this.disabled) {
          this.__focused = false;
        }
      },
      click: e => {
        e.stopPropagation();

        if (!this.disabled && !this.__open) {
          this.__focused = true;
          this.__inputEl.focus();
        }
      },
      keydown: e => {
        if (!this.disabled) {
          if (!this.__focused && (e.key === 'Enter' || e.key === ' ')) {
            setTimeout(() => {
              this.__focused = true;
            });
          }

          if (e.key === 'Escape') {
            this.__focused = false;
          }

          if (e.key === 'Backspace' && this.value.length && !this.search) {
            const index = this.value.length - 1;
            const value = [...this.value];
            value.splice(index, 1);

            this.onChange({
              name: this.name,
              value,
            });
          }
        }
      },
      input: e => {
        this.onSearch({
          name: this.name,
          value: e.currentTarget.value,
        });
      },
      select: index => {
        const item = this.items[index];

        if (item) {
          const value = [...this.value, item];

          this.__updateValueAndSearch(value);
        }
      },
      remove: e => {
        e.stopPropagation();

        const index = Number(e.currentTarget.index);
        const value = [...this.value];
        value.splice(index, 1);

        this.__updateValueAndSearch(value);
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('click', this.__handlers.closeMenu);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('click', this.__handlers.closeMenu);
  }

  __updateValueAndSearch(value) {
    this.onChange({
      name: this.name,
      value,
    });

    this.onSearch({
      name: this.name,
      value: '',
    });
  }

  isPinned() {
    return this.value.length || this.__focused || this.search || this.pinLabel;
  }

  getLabel() {
    const useAsterisk = this.label.trim() && this.helper === 'Required';

    return useAsterisk ? `${this.label}*` : this.label;
  }

  getHelperText() {
    if (this.error !== '') {
      switch (typeof this.error) {
        case 'boolean':
          return this.error ? this.helper : '';

        case 'string':
          return this.error;

        default:
          return this.helper;
      }
    }

    return this.helper;
  }

  getPlaceholder() {
    const show =
      this.placeholder.length > 0 &&
      (this.label.trim().length === 0 || this.isPinned());

    return show ? this.placeholder : '';
  }

  firstUpdated() {
    this.__inputEl = this.shadowRoot.getElementById(ELEMENTS.input.id);
  }

  update(changedProps) {
    if (changedProps.has('__focused')) {
      this.__open = this.__focused;
    }

    super.update(changedProps);
  }

  renderPill(item, index) {
    const backgroundColor = item.data.color
      ? `--background-color: ${item.data.color}`
      : '';

    return this.onRenderPill
      ? this.onRenderPill(item, index)
      : html`
          <neb-pill
            class="pill"
            style="${backgroundColor}"
            .index="${index}"
            ?disabled="${this.disabled}"
            @click="${this.__handlers.remove}"
            >${item.label}</neb-pill
          >
        `;
  }

  renderLabel() {
    return this.label
      ? html`
          <neb-floating-label
            id="${ELEMENTS.label.id}"
            text="${this.getLabel()}"
            ?focused="${this.__focused}"
            ?invalid="${this.__invalid}"
            ?pinned="${this.isPinned()}"
            ?disabled="${this.disabled}"
          ></neb-floating-label>
        `
      : '';
  }

  render() {
    return html`
      <div class="container">
        <neb-textbox
          id="${ELEMENTS.textbox.id}"
          class="textbox"
          ?focused="${this.__focused}"
          ?invalid="${Boolean(this.error)}"
          ?disabled="${this.disabled}"
          @click="${this.__handlers.click}"
          @keydown="${this.__handlers.keydown}"
        >
          <div class="pills">
            ${this.value.map((item, index) => this.renderPill(item, index))}

            <input
              id="${ELEMENTS.input.id}"
              class="input"
              type="text"
              maxlength="${this.maxLength}"
              .value="${this.search}"
              .placeholder="${this.getPlaceholder()}"
              @focus="${this.__handlers.focus}"
              @input="${this.__handlers.input}"
            />
          </div>

          <neb-menu
            id="${ELEMENTS.menu.id}"
            class="menu"
            .maxVisibleItems="${this.maxVisibleItems}"
            .thresholdCount="${this.thresholdCount}"
            .itemHeight="${this.itemHeight}"
            .items="${this.items}"
            .onChange="${this.__handlers.select}"
            .onRequest="${this.__handlers.request}"
            .onRenderItem="${this.onRenderItem}"
            .scrollOnKeydown="${this.scrollOnKeydown}"
            ?open="${this.__open}"
            selectSearchMulti
          ></neb-menu>
        </neb-textbox>

        ${this.renderLabel()}

        <neb-text-helper
          id="${ELEMENTS.helper.id}"
          .text="${this.getHelperText()}"
          ?invalid="${Boolean(this.error)}"
          ?disabled="${this.disabled}"
        ></neb-text-helper>
      </div>
    `;
  }
}

window.customElements.define('neb-select-search-multi', SelectSearchMulti);
