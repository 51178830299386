import { html, css } from 'lit';

import { getPracticeUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import NebOverlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import NebFormEraEobManagement from '../../forms/era-eob/neb-form-era-eob-management';

export const ELEMENTS = {
  form: { id: 'form-era-eob-management-detail' },
};

class NebOverlayEraEobManagement extends NebOverlay {
  static get properties() {
    return {
      __inputModel: Object,
      __displayItems: Array,
      __practiceUsers: Array,
      __eraOverviewFF: Boolean,
      __eraActionsFF: Boolean,
      __hasEraActionsManualPostFF: Boolean,
      __hasEraOverviewNavigationAFF: Boolean,
      __hasEraOverviewNavigationBFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__inputModel = NebFormEraEobManagement.createModel();
    this.__displayItems = [];
    this.__practiceUsers = [];
    this.__eraOverviewFF = false;
    this.__eraActionsFF = false;
    this.__hasEraActionsManualPostFF = false;
    this.__hasEraOverviewNavigationAFF = false;
    this.__hasEraOverviewNavigationBFF = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      updateModel: model => {
        this.__inputModel = { ...model };
      },
    };
  }

  async connectedCallback() {
    const features = await getFeatures();
    this.__eraOverviewFF = features.includes(FEATURE_FLAGS.ERA_OVERVIEW);
    this.__eraActionsFF = features.includes(FEATURE_FLAGS.ERA_ACTIONS);
    this.__hasEraActionsManualPostFF = features.includes(
      FEATURE_FLAGS.ERA_ACTION_MANUAL_POST,
    );

    this.__hasEraOverviewNavigationAFF = features.includes(
      FEATURE_FLAGS.ERA_OVERVIEW_NAVIGATION_A,
    );

    this.__hasEraOverviewNavigationBFF = features.includes(
      FEATURE_FLAGS.ERA_OVERVIEW_NAVIGATION_B,
    );

    this.__practiceUsers = await getPracticeUsers();
    super.connectedCallback();
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { era, payerPayment } = this.model;
      this.__inputModel = { ...this.__inputModel, ...era };

      if (payerPayment) {
        this.__inputModel = {
          ...this.__inputModel,
          ...payerPayment.eob,
          payerPayment,
        };
      }
    }

    super.update(changedProps);
  }

  renderContent() {
    const hasEraPayments = this.__inputModel?.payments?.length;
    return html`
      <neb-form-era-eob-management
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .model="${this.__inputModel}"
        .displayItems="${this.__displayItems}"
        .hasEraPayments="${!!hasEraPayments}"
        .onDismiss="${this.handlers.dismiss}"
        .practiceUsers="${this.__practiceUsers}"
        .eraOverviewFF="${this.__eraOverviewFF}"
        .eraActionsFF="${this.__eraActionsFF}"
        .hasEraActionsManualPostFF="${this.__hasEraActionsManualPostFF}"
        .hasRcmEraEobEnhancementsFF="${this.model.hasRcmEraEobEnhancementsFF}"
        .hasEraOverviewNavigationAFF="${this.__hasEraOverviewNavigationAFF}"
        .hasEraOverviewNavigationBFF="${this.__hasEraOverviewNavigationBFF}"
        .onUpdateModel="${this.handlers.updateModel}"
      ></neb-form-era-eob-management>
    `;
  }
}

customElements.define(
  'neb-overlay-era-eob-management',
  NebOverlayEraEobManagement,
);
