import { SCRUB_CODE } from '../../../packages/neb-lit-components/src/utils/claim-validation';
import { OVERLAY_KEYS } from '../../../packages/neb-lit-components/src/utils/overlay-constants';

export const CLAIM_ERROR_DICT = {
  ITD_DOO: {
    errorCodes: [
      SCRUB_CODE.PL007,
      SCRUB_CODE.PL010,
      SCRUB_CODE.PL020,
      SCRUB_CODE.PL021,
    ],
    overlay: OVERLAY_KEYS.CLAIM_ERROR_ITD_DOO,
  },
  MANAGE_ENCOUNTER: {
    errorCodes: [
      SCRUB_CODE.CL001,
      SCRUB_CODE.CL002,
      SCRUB_CODE.CL003,
      SCRUB_CODE.CL004,
      SCRUB_CODE.CL005,
      SCRUB_CODE.CL006,
      SCRUB_CODE.CL007,
      SCRUB_CODE.CL008,
      SCRUB_CODE.PL012,
      SCRUB_CODE.PL013,
      SCRUB_CODE.PL015,
      SCRUB_CODE.PL016,
      SCRUB_CODE.PL031,
      SCRUB_CODE.PL032,
    ],
    overlay: OVERLAY_KEYS.CLAIM_ERROR_MANAGE_ENCOUNTER,
  },
};

export const formatClaimInfo = ({ claim, claimError, practiceInfo }) => {
  const { claimValidationErrors } = claim;
  const errorDescriptions = claimValidationErrors
    .filter(err => claimError.errorCodes.includes(err.code))
    .map(errDesc => errDesc.description);

  return {
    claimNumber: claim.claimNumber,
    invoiceId: claim.invoiceId,
    patient: claim.patient,
    patientId: claim.patient.id,
    serviceDate: claim.dateOfService,
    lastUpdated: claim.lastUpdated,
    provider: claim.provider,
    payer: claim.primaryPayerAlias,
    errorDescriptions,
    practiceInfo,
  };
};
