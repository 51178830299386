import { html, css } from 'lit';

import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { fetchMany } from '../../../packages/neb-api-client/src/patient-cases';
import { getPatientDemographicReportUrl } from '../../../packages/neb-api-client/src/reports-api-client';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-popup/src/neb-popup';
import { CSS_SPACING } from '../../styles';
import { createReportUrl } from '../../utils/reports-tab-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  caseOptions: {
    id: 'case-options',
  },
  includeNotesButton: {
    id: 'include-note-button',
  },
  confirmButton: {
    id: 'confirm-button',
  },
  cancelButton: {
    id: 'cancel-button',
  },
  logoutIframe: { id: 'logout-iframe' },
};

class NebPopupPatientDemographicReportParams extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      __activeCases: Array,
      __reportParams: Object,
      __logoutUrl: String,
    };
  }

  initState() {
    super.initState();

    this.title = 'Patient Demographic Report';
    this.__activeCases = [];
    this.__reportParams = { cases: [], includeNotes: false };
    this.__logoutUrl = '';
  }

  initHandlers() {
    super.initHandlers();
    this.__handlers = {
      updateCaseCheckbox: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          this.__reportParams.cases = e.value;
        }

        this.__reportParams = { ...this.__reportParams };
      },
      updateIncludeNotesCheckbox: () => {
        this.__reportParams.includeNotes = !this.__reportParams.includeNotes;

        this.__reportParams = { ...this.__reportParams };
      },

      confirm: async () => {
        const newTab = window.open();
        const { reportURL, logoutURL } = await getPatientDemographicReportUrl({
          patientId: this.model.patientId,
          caseIds: this.__reportParams.cases.map(caseItem => caseItem.data.id),
          includeNotes: this.__reportParams.includeNotes,
        });

        newTab.location = createReportUrl(reportURL, 'Patient Demographics');
        this.__logoutUrl = logoutURL;

        this.onClose();
      },
      cancel: () => {
        this.onClose();
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 500px;
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 450px;
        }

        :host([layout='small']) .content-container {
          width: 300px;
        }

        .button-container {
          display: flex;
          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: ${CSS_SPACING};
        }

        .container-checkboxes {
          display: flex;
          flex-direction: column;
          gap: ${CSS_SPACING};
        }

        .iframe-content {
          border: 0;
          width: 0;
          height: 0;
          position: absolute;
          top: 0px;
          left: 0px;
        }
      `,
    ];
  }

  async firstUpdated() {
    super.firstUpdated();
    const allCases = await fetchMany(this.model.patientId);
    this.__activeCases = allCases
      .filter(caseItem => caseItem.active)
      .map(caseItem => ({ label: caseItem.name, data: caseItem }));
  }

  __renderCaseOptions() {
    return html`
      <neb-select
        id="${ELEMENTS.caseOptions.id}"
        label="Cases"
        helper=" "
        .items="${this.__activeCases}"
        .value="${this.__reportParams.cases}"
        allLabel="Cases"
        .onChange="${this.__handlers.updateCaseCheckbox}"
        multiSelect
      ></neb-select>
    `;
  }

  __renderIncludeNotesCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.includeNotesButton.id}"
        .label="${'Include Notes'}"
        .onChange="${this.__handlers.updateIncludeNotesCheckbox}"
        .checked="${this.__reportParams.includeNotes}"
      >
      </neb-checkbox>
    `;
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="container-checkboxes">
          ${this.__renderCaseOptions()}${this.__renderIncludeNotesCheckbox()}
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.confirmButton.id}"
            class="button"
            label="Generate"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.__handlers.confirm}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.cancelButton.id}"
            class="button"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.cancel}"
          ></neb-button>
        </div>
      </div>
      <iframe
        id="${ELEMENTS.logoutIframe.id}"
        src="${this.__logoutUrl}"
        class="iframe-content"
      ></iframe>
    `;
  }
}

customElements.define(
  'neb-popup-patient-demographic-report-params',
  NebPopupPatientDemographicReportParams,
);
