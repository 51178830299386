import { css, html } from 'lit';
import '../../../../packages/neb-lit-components/src/components/encounter/neb-encounter-summary';

import { getEncounterSummary } from '../../../../packages/neb-api-client/src/invoice-api-client';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  encounterSummary: {
    id: 'encounter-summary',
  },
};

class NebOverlayClaimErrorItdDoo extends NebOverlayClaimError {
  static get properties() {
    return {
      __summaryModel: Object,
    };
  }

  initState() {
    super.initState();
    this.__summaryModel = {};
    this.widthRatio = '65/35';
  }

  static get styles() {
    return [
      super.styles,
      css`
        .encounter-summary {
          padding: 5px 10px;
          overflow: auto;
        }
      `,
    ];
  }

  async firstUpdated(changedProps) {
    if (changedProps.has('model')) {
      const { invoiceId } = this.model;

      const { data: encounterSummaryDetails } = await getEncounterSummary({
        invoiceId,
      });

      if (encounterSummaryDetails.length) {
        this.__summaryModel = {
          patient: this.model.patient,
          encounter: encounterSummaryDetails[0].encounter,
          chartNotes: encounterSummaryDetails[0].chartNotes,
          practiceInfo: this.model.practiceInfo,
        };
      }
    }
    super.firstUpdated();
  }

  renderLeftPanel() {
    return html`
      <div id="${ELEMENTS.leftPanel.id}">
        <neb-encounter-summary
          id="${ELEMENTS.encounterSummary.id}"
          class="encounter-summary"
          .model="${this.__summaryModel}"
        >
        </neb-encounter-summary>
      </div>
    `;
  }
}

customElements.define(
  'neb-overlay-claim-error-itd-doo',
  NebOverlayClaimErrorItdDoo,
);
