/* eslint-disable complexity */
import '../../../../../packages/neb-lit-components/src/components/field-groups/neb-date-type-list';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../../packages/neb-lit-components/src/components/neb-tooltip';
import '../../../../../packages/neb-lit-components/src/components/neb-radio-button';
import '../../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../../../packages/neb-material-design/src/components/neb-md-textfield';
import '../../../controls/field-groups/neb-attorney-list';
import '../../../controls/inputs/neb-checkbox';
import '../../../misc/neb-icon';

import { html, css } from 'lit';
import moment from 'moment-timezone';

import {
  CARE_TYPES,
  CARE_TYPES_ACCIDENT,
  EMPTY_CARE_TYPE,
  ITEMS_ADDITIONAL_DATES,
  BILL_TYPES,
} from '../../../../../packages/neb-api-client/src/mappers/patient-case-mapper';
import { DATE_SELECTION_RULES } from '../../../../../packages/neb-lit-components/src/components/field-groups/neb-two-date-item';
import NebForm from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { POPOVER_HORIZ_ALIGN } from '../../../../../packages/neb-lit-components/src/components/neb-date-picker';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { baseStyles } from '../../../../../packages/neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_GREY_7,
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_SPACING_ROW_LARGE,
  CSS_BUTTON_SPACING_ROW,
} from '../../../../../packages/neb-styles/neb-variables';
import {
  REFERRING_PROVIDER_QUALIFIERS,
  SPINAL_MANIPULATION_OPTION,
} from '../../../../../packages/neb-utils/claims';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import { STATES } from '../../../../../packages/neb-utils/enums';
import { number } from '../../../../../packages/neb-utils/masks';
import * as selectors from '../../../../../packages/neb-utils/selectors';
import { padArray } from '../../../../../packages/neb-utils/utils';
import {
  genRequireSelect,
  required,
  isPhoneNumber,
} from '../../../../../packages/neb-utils/validators';
import { UpdateNotificationService } from '../../../../services/update-notifications';
import { ANY } from '../../../../utils/update-notifications';
import { ATTORNEY_TYPES } from '../../../controls/field-groups/neb-attorney-item';

export const ITEM_SELF = {
  label: 'Self',
  data: { id: 'Self', active: true },
};

export const EMPTY_ITEM = {
  label: '',
  id: '',
};

export const ITEMS = [
  { label: '' },
  ...Object.values(SPINAL_MANIPULATION_OPTION).map(option => ({
    label: option,
  })),
];

export const QUALIFIERS = [
  {
    value: null,
    label: '',
  },
  ...REFERRING_PROVIDER_QUALIFIERS,
];

export const ITEMS_ADDITIONAL_DATES_SOURCE = [{ id: '', label: '' }].concat(
  ITEMS_ADDITIONAL_DATES,
);

export const invalidNPI = providerNPI =>
  providerNPI && providerNPI.length !== 10;

export const DATE_SELECT_RULES_UNABLE_TO_WORK = [
  DATE_SELECTION_RULES.DATE1_LESS_OR_EQUAL_DATE2,
  DATE_SELECTION_RULES.DATE2_GREATER_OR_EQUAL_DATE1,
];

const DATE_SELECT_RULES_HOSPITALIZED = [
  DATE_SELECTION_RULES.DATE1_NOT_FUTURE_DATE,
  DATE_SELECTION_RULES.DATE1_LESS_OR_EQUAL_DATE2,
  DATE_SELECTION_RULES.DATE2_GREATER_OR_EQUAL_DATE1,
];

const createUnableToWorkLabels = (requiredDisabilityBegin = false) => ({
  date1: 'Disability Begin Date',
  date2: 'Disability End Date',
  checkbox: 'Unable to Work',
  helperText1: requiredDisabilityBegin ? 'Required' : '',
  helperText2: '',
});

const createHospitalizedLabels = (requiredText = false) => ({
  date1: 'Hospital Admission Date',
  date2: 'Hospital Discharge Date',
  checkbox: 'Hospitalized',
  helperText1: requiredText ? 'Required' : '',
  helperText2: requiredText ? 'Required' : '',
});

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  textHeaderGeneral: {
    id: 'text-header-general',
  },
  defaultCheckbox: {
    id: 'default-checkbox',
  },
  textName: {
    id: 'name',
  },
  selectCareType: {
    id: 'careType',
  },
  selectGuarantor: {
    id: 'select-guarantor',
  },
  tooltipGuarantor: {
    id: 'tooltip-guarantor',
  },
  textClaimNumber: {
    id: 'claimNumber',
  },
  buttonAddGuarantor: {
    id: 'button-add-guarantor',
  },
  buttonAddInsurance: {
    id: 'button-add-insurance',
  },
  buttonAddPackage: {
    id: 'button-add-package',
  },
  caseBillTypes: {
    id: 'case-bill-types',
  },
  textHeaderBillType: {
    id: 'text-header-bill-type',
  },
  selfPayRadioButton: {
    id: 'self-pay-radio-button',
  },
  insuranceRadioButton: {
    id: 'insurance-radio-button',
  },
  carePackageRadioButton: {
    id: 'care-package-radio-button',
  },
  selectSelf: {
    id: 'select-self',
  },
  selectCarePackage: {
    id: 'select-care-package',
  },
  selectPrimaryInsurance: {
    id: 'select-primary-insurance',
  },
  tooltipInsurance: {
    id: 'tooltip-insurance',
  },
  selectPrimaryPayer: {
    id: 'select-primary-payer',
  },
  buttonAddPayer: {
    id: 'button-add-payer',
  },
  selectSecondaryInsurance: {
    id: 'select-secondary-insurance',
  },
  iconMinus: {
    id: 'icon-minus',
  },
  selectSecondaryPayer: {
    id: 'select-secondary-payer',
  },
  textHeaderDates: {
    id: 'text-header-dates',
  },
  textHelpSymptoms: {
    id: 'test-help-symptoms',
  },
  tooltipOnsetSymptoms: {
    id: 'tooltip-onset-symptoms',
  },
  radioDate: {
    id: 'onsetSymptomsDate-isDate',
  },
  selectDateSymptom: {
    id: 'onsetSymptomsDate-date',
  },
  radioGradual: {
    id: 'onsetSymptomsDate-isGradual',
  },
  selectDateInitialTx: {
    id: 'initial-tx-date',
  },
  selectDateLastSeen: {
    id: 'last-seen-date',
  },
  selectDateAccident: {
    id: 'select-date-accident',
  },
  selectState: {
    id: 'select-state',
  },
  unableToWork: {
    id: 'unable-to-work',
  },
  hospitalized: {
    id: 'hospitalized',
  },
  textHeaderAdditionalDates: {
    id: 'text-header-additional-dates',
  },
  additionalDates: {
    id: 'additional-dates',
  },
  textHeaderSpinal: {
    id: 'text-header-spinal',
  },
  code: {
    id: 'dropdown-code',
  },
  tooltipSpinal: {
    id: 'tooltip-spinal',
  },
  description: {
    id: 'textfield-description',
  },
  description2: {
    id: 'textfield-description2',
  },
  textHeaderAdditionalInformation: {
    id: 'text-header-additional-information',
  },
  textReferringProviderFirstName: {
    id: 'referringProviderFirstName',
  },
  textReferringProviderMiddleName: {
    id: 'referringProviderMiddleName',
  },
  textReferringProviderLastName: {
    id: 'referringProviderLastName',
  },
  textReferringProviderCredentials: {
    id: 'referringProviderCredentials',
  },
  textReferringProviderOtherId: {
    id: 'textReferringProviderOtherId',
  },
  referringProviderQualifierDropdown: {
    id: 'referringProviderQualifierDropdown',
  },
  textReferringProviderNPI: {
    id: 'textReferringProviderNPI',
  },
  textOrderingProviderFirstName: {
    id: 'orderingProviderFirstName',
  },
  textOrderingProviderMiddleName: {
    id: 'orderingProviderMiddleName',
  },
  textOrderingProviderLastName: {
    id: 'orderingProviderLastName',
  },
  textOrderingProviderCredentials: {
    id: 'orderingProviderCredentials',
  },
  textOrderingProviderOtherId: {
    id: 'textOrderingProviderOtherId',
  },
  orderingProviderQualifierDropdown: {
    id: 'orderingProviderQualifierDropdown',
  },
  textOrderingProviderNPI: {
    id: 'orderingProviderNPI',
  },
  attorneys: {
    id: 'attorneys',
  },
  textNote: {
    id: 'note',
  },
  buttonToggleActive: {
    id: 'active',
  },
  actionBar: {
    id: 'action-bar',
  },
};

const CUSTOM_BOUND_TOP_OFFSET = 200;

const isRequiredSpinal = () => ({
  error: 'Required',
  validate: (v, _, state) => {
    const isRequired = state.additionalDates.some(
      x => x.type.id === 'acuteManifestationDate',
    );

    return isRequired ? isRequired && !!v : true;
  },
});

export default class NebFormPatientCase extends NebForm {
  static get properties() {
    return {
      __showSecondaryPayer: Boolean,
      __updateDependencyLastSeenDate: String,
      __updateDependencyInitialTxDate: String,
      __unableToWorkLabels: Object,
      __hospitalizedLabels: Object,
      __billType: String,
      __dateErrors: Boolean,

      guarantors: Array,
      insurances: Array,
      organizations: Array,
      patientPackages: Array,
    };
  }

  constructor() {
    super();

    this.initServices();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        :host([hidden]) {
          display: none;
          width: unset;
          height: unset;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .container-form {
          flex: 1 0 0;
          display: block;
          flex-direction: column;
          min-height: 0;
          overflow: auto;
        }

        .input,
        .input-date,
        .select,
        .select-search {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .text-header {
          padding-top: ${CSS_SPACING};
        }

        .input-date {
          display: block;
          height: 40px;
        }

        .icon {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_GREY_1};
        }

        .padding {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .form-general {
          display: grid;
          grid-template-rows: auto auto;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          padding: ${CSS_SPACING_ROW_LARGE} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .form-additional-dates {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING} ${CSS_SPACING} 0 ${CSS_SPACING};
        }

        .form-payer-insurance {
          padding: 0 ${CSS_SPACING} 5px ${CSS_SPACING};
        }

        .ordering-provider,
        .referring-provider {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          padding-bottom: ${CSS_SPACING};
        }

        .other-additional-fields {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          padding: ${CSS_SPACING} 0px;
        }

        .referring-provider {
          grid-template-areas:
            'trpf trpm'
            'trpl trpc'
            'trpo rpqd'
            'trpn .  ';
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }

        .ordering-provider {
          grid-template-areas:
            'topf topm'
            'topl topc'
            'topo opqd'
            'topn .  ';
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
          padding-top: ${CSS_SPACING};
        }

        .other-additional-fields {
          grid-template-areas:
            'tn  tn '
            'bta bta';
        }

        .grid-two-rows-two-columns {
          display: grid;
          width: 100%;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 72px 72px;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
        }

        .cell {
          width: auto;
          flex: 1 0 0;
        }

        .w-fit {
          width: fit-content;
        }

        .border-short {
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
        }

        .container-radio-buttons {
          display: flex;
          margin-bottom: 25px;
          margin-left: -10px;
        }

        .container-help-symptoms {
          display: flex;
          align-items: flex-end;
        }

        .container-select-help {
          display: flex;
          align-items: center;
          height: fit-content;
        }

        .container-subsection {
          padding: ${CSS_SPACING} 0 ${CSS_SPACING} 0;
        }

        .container-two-date-item {
          display: flex;
          width: 100%;
          padding-top: ${CSS_BUTTON_SPACING_ROW};
        }

        .unable-to-work {
          padding-bottom: ${CSS_SPACING};
        }

        .select-tooltip {
          margin-right: 10px;
        }

        .button-toggle-active {
          grid-area: bta;
          margin-top: ${CSS_BUTTON_SPACING_ROW};
        }

        .button-secondary-payer {
          padding-top: ${CSS_BUTTON_SPACING_ROW};
        }

        .button-add-guarantor,
        .button-add-insurance,
        .button-add-package {
          padding: ${CSS_BUTTON_SPACING_ROW} 0;
        }

        .default-checkbox {
          grid-area: bta;
          margin-top: 10px;
          margin-left: 100px;
        }

        .text-referring-provider-first {
          grid-area: trpf;
        }

        .text-referring-provider-last {
          grid-area: trpl;
        }

        .text-referring-provider-middle {
          grid-area: trpm;
        }

        .text-referring-provider-credential {
          grid-area: trpc;
        }

        .text-referring-provider-other-id {
          grid-area: trpo;
        }

        .referring-provider-qualifier-dropdown {
          grid-area: rpqd;
        }

        .text-referring-provider-npi {
          grid-area: trpn;
        }

        .text-ordering-provider-first {
          grid-area: topf;
        }

        .text-ordering-provider-last {
          grid-area: topl;
        }

        .text-ordering-provider-middle {
          grid-area: topm;
        }

        .text-ordering-provider-credential {
          grid-area: topc;
        }

        .text-ordering-provider-other-id {
          grid-area: topo;
        }

        .ordering-provider-qualifier-dropdown {
          grid-area: opqd;
        }

        .text-ordering-provider-npi {
          grid-area: topn;
        }

        .input-date-symptom {
          width: 200px;
          margin-right: 10px;
        }

        .text-note {
          height: 180px;
          grid-area: tn;
        }

        .text-onset-symptoms {
          width: fit-content;
          margin-right: 10px;
        }

        .spinal-manip {
          height: 75px;
        }

        .tooltip-spinal {
          padding: 15px 0 20px 0;
        }

        .tooltip {
          padding-top: 15px;
        }

        .grid {
          display: grid;
          width: 100%;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto auto;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
        }

        .container-insurance {
          padding: 0 20px 0;
          width: 100%;
          height: 100%;
        }

        .grid-insurance {
          display: grid;
          width: 100%;
          height: 70px;
          grid-template-columns: 1fr 20px;
          grid-gap: ${CSS_SPACING_ROW} 10px;
        }

        .icon-minus {
          cursor: pointer;
          width: 24px;
          height: 24px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          align-self: center;
        }

        .control-icon {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .grid-area {
          grid-template-areas:
            'a b'
            'c d';
        }

        .grid-d {
          grid-area: d;
        }

        .bill-type {
          grid-column: 1 / -1;
        }

        .radio-buttons {
          display: flex;
          gap: 20px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      isDefault: false,
      name: '',
      careType: '',
      guarantorId: '',
      claimNumber: '',
      payerInsurance: {
        primaryInsuranceId: '',
        primaryPayerId: null,
        secondaryInsuranceId: null,
        secondaryPayerId: null,
      },
      patientPackageId: '',
      onsetSymptomsDate: {
        date: null,
        gradual: true,
      },
      initialTxDate: null,
      lastSeenDate: null,
      accidentDate: null,
      accidentState: null,
      unableToWork: {
        date1: null,
        date2: null,
        enabled: false,
      },
      hospitalized: {
        date1: null,
        date2: null,
        enabled: false,
      },
      additionalDates: [],
      spinalManipulationInfoCode: '',
      spinalManipulationInfoDescription: '',
      spinalManipulationInfoDescription2: '',
      referringProviderFirstName: '',
      referringProviderMiddleName: '',
      referringProviderLastName: '',
      referringProviderCredentials: '',
      referringProviderOtherId: '',
      referringProviderQualifier: null,
      referringProviderNPI: '',
      orderingProviderFirstName: '',
      orderingProviderMiddleName: '',
      orderingProviderLastName: '',
      orderingProviderCredentials: '',
      orderingProviderOtherId: '',
      orderingProviderQualifier: null,
      orderingProviderNPI: '',
      attorneys: [
        {
          name: '',
          type: '',
          phoneNumber: '',
          organizationId: null,
        },
      ],
      note: '',
      active: true,
    };
  }

  initState() {
    super.initState();

    this.__today = new Date();
    this.__showSecondaryPayer = false;
    this.__updateDependencyLastSeenDate = null;
    this.__updateDependencyInitialTxDate = null;
    this.__unableToWorkLabels = createUnableToWorkLabels();
    this.__hospitalizedLabels = createHospitalizedLabels();
    this.__billType = BILL_TYPES.SELF;
    this.__dateErrors = false;

    this.guarantors = [ITEM_SELF];
    this.insurances = [];
    this.organizations = [];
    this.patientPackages = [];

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onGuarantorChange = () => {};

    this.onSearchOrganizations = () => {};

    this.onAddInsurance = () => {};

    this.onAddPackage = () => {};

    this.onUpdateOrganization = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addGuarantor: async () => {
        const result = await openOverlay(OVERLAY_KEYS.GUARANTOR, {
          context: {
            patientId: this.model.patientId,
          },
        });

        if (result && result.id) {
          await this.onGuarantorChange(this.model.patientId);

          const guarantor = this.guarantors.find(
            item => item.data.id === result.id,
          );

          this.formService.apply('guarantorId', guarantor);
        }
      },
      addInsurance: async () => {
        const context = {
          cancel: 'cancel',
          rteEnabled: true,
          showBackButton: true,
          hasAddOnCTVerify: this.hasAddOnCTVerify,
        };

        let result;

        if (this.hasAddOnCTVerify) {
          result = await openOverlay(OVERLAY_KEYS.ADD_INSURANCE, {
            patientId: this.model.patientId,
            patientInsurances: this.insurances.filter(item => item.active),
            context,
          });
        } else {
          result = await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_ADD, {
            patientId: this.model.patientId,
            patientInsurances: this.insurances.filter(item => item.active),
            context,
          });
        }

        if (result) {
          await this.onAddInsurance();

          const insurance = this.insurances.find(item => item.id === result.id);

          const { payerPlan } = insurance;

          if (this.__showSecondaryPayer) {
            this.formService.apply(
              'payerInsurance.secondaryInsuranceId',
              insurance,
            );

            this.formService.apply(
              'payerInsurance.secondaryPayerId',
              payerPlan,
            );
          } else {
            this.formService.apply(
              'payerInsurance.primaryInsuranceId',
              insurance,
            );

            this.formService.apply('payerInsurance.primaryPayerId', payerPlan);
          }
        }
      },
      addPackage: async () => {
        const result = await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_ADD, {
          context: {
            patientId: this.model.patientId,
            filteredCount: this.patientPackages.length,
          },
        });

        if (result) {
          await this.onAddPackage();

          const patientPackage = this.patientPackages.find(
            item => item.data.id === result.id,
          );

          this.formService.apply('patientPackageId', patientPackage);
        }
      },
      changeBillType: value => {
        if (this.__billType === value) {
          return;
        }

        if (this.__billType === BILL_TYPES.CARE_PACKAGE) {
          this.formService.apply('patientPackageId', ITEM_SELF);
        } else if (this.__billType === BILL_TYPES.INSURANCE) {
          this.formService.apply(
            'payerInsurance.primaryInsuranceId',
            ITEM_SELF,
          );

          this.formService.apply('payerInsurance.primaryPayerId', null);
          this.formService.apply('payerInsurance.secondaryInsuranceId', null);
          this.formService.apply('payerInsurance.secondaryPayerId', null);

          this.__showSecondaryPayer = false;
        }

        this.__billType = value;

        if (value === BILL_TYPES.INSURANCE) {
          const primaryInsurance = this.__getPrimaryInsurance(
            this.model.payerInsurance.primaryInsuranceId,
          );

          const secondaryInsurance =
            this.model.payerInsurance.primaryInsuranceId &&
            this.model.payerInsurance.primaryInsuranceId !== 'Self' &&
            !this.model.payerInsurance.secondaryInsuranceId
              ? ''
              : this.__getSecondaryInsurance(
                  this.model.payerInsurance.secondaryInsuranceId,
                );

          if (primaryInsurance) {
            this.formService.apply(
              'payerInsurance.primaryInsuranceId',
              primaryInsurance,
            );

            this.formService.apply(
              'payerInsurance.primaryPayerId',
              primaryInsurance.payerPlan,
            );
          }

          if (secondaryInsurance) {
            this.__showSecondaryPayer = true;

            this.formService.apply(
              'payerInsurance.secondaryInsuranceId',
              secondaryInsurance,
            );

            this.formService.apply(
              'payerInsurance.secondaryPayerId',
              secondaryInsurance.payerPlan,
            );
          }

          if (!primaryInsurance && !secondaryInsurance) {
            this.formService.apply(
              'payerInsurance.primaryInsuranceId',
              EMPTY_ITEM,
            );
          }
        } else if (value === BILL_TYPES.CARE_PACKAGE) {
          const patientPackage = this.__getPatientPackage(
            this.model.patientPackageId,
          );

          if (patientPackage) {
            this.formService.apply('patientPackageId', patientPackage);
          } else {
            this.formService.apply('patientPackageId', EMPTY_ITEM);
          }
        }
      },
      changePrimaryInsurance: ({ name, value }) => {
        this.formService.apply(name, value);

        const { payerPlan } = value;

        if (payerPlan) {
          this.formService.apply('payerInsurance.primaryPayerId', payerPlan);
        } else {
          this.formService.apply('payerInsurance.primaryPayerId', null);
        }
      },
      addPayer: () => {
        this.__showSecondaryPayer = true;
      },
      removePayer: () => {
        this.__showSecondaryPayer = false;
        this.formService.apply('payerInsurance.secondaryInsuranceId', null);
        this.formService.apply('payerInsurance.secondaryPayerId', null);
      },
      changeSecondaryInsurance: ({ name, value }) => {
        this.formService.apply(name, value);

        if (value) {
          const { payerPlan } = value;

          this.formService.apply('payerInsurance.secondaryPayerId', payerPlan);
        }
      },
      setGradual: () => {
        this.formService.apply('onsetSymptomsDate.gradual', true);
        this.formService.apply('onsetSymptomsDate.date', null);
      },
      unsetGradual: () => {
        this.formService.apply('onsetSymptomsDate.gradual', false);
        this.formService.apply('onsetSymptomsDate.date', null);
      },
      isNotFutureDate: date => date <= this.__today,
      changeDate: ({ name, value }) => {
        if (value instanceof moment) {
          this.formService.apply(
            name,
            parseDate(value)
              .startOf('day')
              .toISOString(),
          );
        } else {
          this.formService.apply(name, value);
        }
      },
      isInitialTxDateSelectable: date => {
        if (this.state.lastSeenDate) {
          return date <= parseDate(this.state.lastSeenDate);
        }

        return date <= this.__today;
      },
      isLastSeenDateSelectable: date => {
        if (this.state.initialTxDate) {
          return (
            date <= this.__today && date >= parseDate(this.state.initialTxDate)
          );
        }

        return date <= this.__today;
      },
      changeInitialTxDate: ({ name, value }) => {
        this.handlers.changeDate({ name, value });

        this.__updateDependencyLastSeenDate = value
          ? value.toISOString()
          : value;
      },
      changeLastSeenDate: ({ name, value }) => {
        this.handlers.changeDate({ name, value });

        this.__updateDependencyInitialTxDate = value
          ? value.toISOString()
          : value;
      },
      changeCareType: ({ name, value }) => {
        if (
          !this.__isAccidentCareType(value) ||
          !this.__isAccidentCareType(this.state.careType)
        ) {
          this.formService.apply(name, value);

          this.formService.apply('accidentDate', null);
          this.formService.apply('accidentState', null);
        } else {
          this.formService.apply(name, value);
        }
      },
      changeUnableToWork: ({ name, value }) => {
        if (name.includes('enabled')) {
          this.__unableToWorkLabels = createUnableToWorkLabels(value);
        }

        this.handlers.changeDate({ name, value });
      },
      changeHospitalized: ({ name, value }) => {
        if (name.includes('enabled')) {
          this.__hospitalizedLabels = createHospitalizedLabels(value);
        }

        this.handlers.changeDate({ name, value });
      },
      changeAdditionalDate: ({ name, value }) => {
        const fullPath = name.split('.');
        const index = fullPath[1];
        const key = fullPath[2];

        if (key === 'date') {
          this.handlers.changeDate({ name, value });
        } else {
          this.formService.apply(name, value);
        }

        if (value && key === 'type') {
          this.formService.validateKey(
            ['additionalDates', `${index}`, 'date'],
            true,
          );

          this.formService.validateKey(['spinalManipulationInfoCode'], true);

          this.formService.validateKey([
            'spinalManipulationInfoDescription',
            true,
          ]);
        } else if (value && key === 'date') {
          this.formService.validateKey(
            ['additionalDates', `${index}`, 'type'],
            true,
          );
        } else {
          this.formService.validateKey(
            ['additionalDates', `${index}`, 'date'],
            true,
          );
        }
      },
      removeAdditionalDate: (name, index) => {
        this.formService.removeItem(name, index);

        this.formService.validateKey(['spinalManipulationInfoCode'], true);

        this.formService.validateKey([
          'spinalManipulationInfoDescription',
          true,
        ]);
      },
      changeAttorney: ({ name, value }) => {
        this.formService.apply(name, value);

        this.state.attorneys.forEach(att => {
          const fullPath = name.split('.');
          const index = fullPath[1];
          Object.keys(att).forEach(type => {
            switch (type) {
              case 'name':
              case 'type':
              case 'organizationId':
              case 'phoneNumber':
                this.formService.validateKey(
                  ['attorneys', `${index}`, `${type}`],
                  true,
                );

                break;

              default:
            }
          });
        });
      },
      changeOrganization: ({ name, value }) => {
        this.formService.apply(name, value || null);
      },
      searchOrganization: search => this.onSearchOrganizations(search),
    };
  }

  initServices() {
    this.__notificationService = new UpdateNotificationService({
      callback: () => {
        this.onUpdateOrganization();
      },
      defaultQuery: {
        organization: ANY,
      },
    });
  }

  connectedCallback() {
    super.connectedCallback();

    this.__notificationService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__notificationService.disconnect();
  }

  createSelectors() {
    return {
      children: {
        name: {
          validators: [required()],
        },
        careType: {
          ...selectors.select(CARE_TYPES, EMPTY_CARE_TYPE),
          validators: [genRequireSelect()],
          format: code =>
            CARE_TYPES.find(x => x.data.code === code) || EMPTY_CARE_TYPE,
          unformat: careType => careType.data.code,
        },
        guarantorId: {
          ...selectors.select(this.guarantors, ITEM_SELF),
          unformat: v => (v.label === 'Self' ? null : v.data.id),
        },
        payerInsurance: {
          children: {
            primaryInsuranceId: {
              ...selectors.select(this.insurances, EMPTY_ITEM),
              validators: [
                {
                  error: 'Required',
                  validate: v =>
                    v.label !== '' || this.__billType === BILL_TYPES.SELF,
                },
              ],
              format: v =>
                this.insurances.find(item => item.id === v) || ITEM_SELF,
              unformat: v => (v.label === 'Self' ? null : v.id),
              ignorePristine: true,
            },
            primaryPayerId: {
              format: v => {
                const insurance = this.insurances.find(item =>
                  item.payerPlan ? item.payerPlan.id === v : false,
                );

                if (insurance) {
                  return insurance.payerPlan;
                }

                return null;
              },
              unformat: v => (v ? v.id : null),
              unsafe: true,
              clipPristine: true,
            },
            secondaryInsuranceId: {
              ...selectors.select([EMPTY_ITEM], EMPTY_ITEM),
              format: v => {
                const insurance = this.insurances.find(item => item.id === v);

                if (insurance && insurance.id) {
                  return insurance;
                }

                return null;
              },
              unformat: v => (v ? v.id : null),
            },
            secondaryPayerId: {
              format: v => {
                const insurance = this.insurances.find(item =>
                  item.payerPlan ? item.payerPlan.id === v : false,
                );

                if (insurance) {
                  return insurance.payerPlan;
                }

                return null;
              },
              unformat: v => (v ? v.id : null),
              unsafe: true,
              clipPristine: true,
            },
          },
        },
        patientPackageId: {
          ...selectors.select(this.patientPackages, ITEM_SELF),
          validators: [
            {
              error: 'Required',
              validate: v =>
                v.label !== '' || this.__billType === BILL_TYPES.SELF,
            },
          ],
          format: v =>
            this.patientPackages.find(item => v === item.data.id) || ITEM_SELF,
          unformat: v => (v.label === 'Self' ? null : v.data && v.data.id),
          ignorePristine: true,
        },
        onsetSymptomsDate: {
          children: {
            date: {
              validators: [
                {
                  error: 'Required',
                  validate: (date, _, state) => {
                    if (state.onsetSymptomsDate.gradual) {
                      return true;
                    }

                    return !!date;
                  },
                },
              ],
              unsafe: true,
              clipPristine: true,
              ignorePristine: true,
            },
          },
          unformat: v => {
            if (v.date) {
              return v.date;
            }

            return null;
          },
        },
        initialTxDate: {
          validators: [
            {
              error: 'mm/dd/yyyy',
              validate: () => this.__validateSelectedDate(),
            },
          ],
        },
        lastSeenDate: {
          validators: [
            {
              error: 'mm/dd/yyyy',
              validate: () => this.__validateSelectedDate(),
            },
          ],
        },
        accidentDate: {
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) =>
                !this.__isAccidentCareType(state.careType) || v,
            },
          ],
          clipPristine: true,
          ignorePristine: true,
        },
        accidentState: {
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) =>
                !this.__isAccidentCareType(state.careType) || v,
            },
          ],
          clipPristine: true,
          ignorePristine: true,
        },
        unableToWork: {
          children: {
            date1: {
              clipPristine: true,
              ignorePristine: true,
              validators: [
                {
                  error: 'Required',
                  validate: (v, _, state) => !state.unableToWork.enabled || !!v,
                },
                {
                  error: 'mm/dd/yyyy',
                  validate: () => {
                    const unableToWork = this.shadowRoot.getElementById(
                      'unable-to-work',
                    );

                    const datePicker = unableToWork.shadowRoot.getElementById(
                      'textfield-date1',
                    );

                    if (datePicker) {
                      const dateErrors = datePicker.invalidTextInput;

                      return !dateErrors;
                    }
                    return true;
                  },
                },
              ],
            },
            date2: {
              clipPristine: true,
              ignorePristine: true,
              validators: [
                {
                  error: 'mm/dd/yyyy',
                  validate: () => {
                    const unableToWork = this.shadowRoot.getElementById(
                      'unable-to-work',
                    );

                    const datePicker = unableToWork.shadowRoot.getElementById(
                      'textfield-date2',
                    );

                    if (datePicker) {
                      const dateErrors = datePicker.invalidTextInput;

                      return !dateErrors;
                    }
                    return true;
                  },
                },
              ],
            },
          },
        },
        hospitalized: {
          children: {
            date1: {
              clipPristine: true,
              ignorePristine: true,
              validators: [
                {
                  error: 'Required',
                  validate: (v, _, state) => !state.hospitalized.enabled || !!v,
                },
              ],
            },
            date2: {
              clipPristine: true,
              ignorePristine: true,
              validators: [
                {
                  error: 'Required',
                  validate: (v, _, state) => !state.hospitalized.enabled || !!v,
                },
              ],
            },
          },
        },
        additionalDates: {
          createItem: () => ({
            type: {
              id: '',
              label: '',
            },
            date: null,
          }),
          format: v =>
            padArray(v, {
              type: {
                id: '',
                label: '',
              },
              date: null,
            }),
          unformat: v => {
            const blankRecordIndex = v.findIndex(x => x.type.id === '');

            if (blankRecordIndex >= 0) {
              v.splice(blankRecordIndex, 1);
            }

            return v.map(x => ({
              ...x,
              date: x.date,
            }));
          },
          children: {
            $: {
              children: {
                type: {
                  ...selectors.select(ITEMS_ADDITIONAL_DATES_SOURCE, [
                    { id: '', label: '' },
                  ]),
                  format: v => v,
                  unformat: v => v,
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];
                        const { date } = state.additionalDates[idx];

                        return v.id || !date;
                      },
                    },
                  ],
                },
                date: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];
                        const { type } = state.additionalDates[idx];

                        return v || !type.id;
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        spinalManipulationInfoCode: {
          ...selectors.select(ITEMS, ITEMS[0]),
          format: v => ITEMS.find(item => item.label === v),
          unformat: v => (v.label ? v.label : ''),
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) => {
                const isRequired = state.additionalDates.some(
                  x => x.type.id === 'acuteManifestationDate',
                );

                return isRequired ? isRequired && !!v.label : true;
              },
            },
          ],
        },
        spinalManipulationInfoDescription: {
          validators: [isRequiredSpinal()],
        },
        referringProviderQualifier: {
          ...selectors.select(QUALIFIERS, QUALIFIERS[0]),
          format: v => QUALIFIERS.find(item => item.value === v),
          unformat: v => v,
        },
        orderingProviderQualifier: {
          ...selectors.select(QUALIFIERS, QUALIFIERS[0]),
          format: v => QUALIFIERS.find(item => item.value === v),
          unformat: v => v,
        },
        attorneys: {
          createItem: () => ({
            name: '',
            type: '',
            organizationId: null,
            phoneNumber: '',
          }),
          format: v =>
            padArray(v, {
              name: '',
              type: '',
              organizationId: null,
              phoneNumber: '',
            }),
          unformat: v =>
            v.reduce(
              (acc, { ...items }) =>
                (items.name && items.type) || items.organizationId
                  ? [...acc, items]
                  : [...acc],
              [],
            ),
          children: {
            $: {
              children: {
                name: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (_, keyPath, state) => {
                        const idx = keyPath[1];
                        const item = state.attorneys[idx];

                        return !(
                          (item.type && !item.name) ||
                          (item.phoneNumber && !item.name)
                        );
                      },
                    },
                    {
                      error: 'Required',
                      validate: (_, keyPath, state) => {
                        const idx = keyPath[1];
                        const item = state.attorneys[idx];

                        return state.attorneys.length > 1
                          ? !item.organizationId || item.name
                          : true;
                      },
                    },
                  ],
                },
                type: {
                  ...selectors.select(ATTORNEY_TYPES, ATTORNEY_TYPES[0]),
                  format: v =>
                    ATTORNEY_TYPES.find(item => item === v) ||
                    ATTORNEY_TYPES[0],
                  unformat: v => v,
                  validators: [
                    {
                      error: 'Required',
                      validate: (_, keyPath, state) => {
                        const idx = keyPath[1];
                        const item = state.attorneys[idx];

                        return !(
                          (item.name && !item.type) ||
                          (item.phoneNumber && !item.type)
                        );
                      },
                    },
                    {
                      error: 'Required',
                      validate: (_, keyPath, state) => {
                        const idx = keyPath[1];
                        const item = state.attorneys[idx];

                        return state.attorneys.length > 1
                          ? !item.organizationId || item.type
                          : true;
                      },
                    },
                  ],
                },
                phoneNumber: {
                  validators: [isPhoneNumber],
                },
                organizationId: {
                  validators: [
                    {
                      error: 'Required',
                      validate: (_, keyPath, state) => {
                        const idx = keyPath[1];
                        const item = state.attorneys[idx];

                        return state.attorneys.length > 1
                          ? !(
                              (item.name && !item.organizationId) ||
                              (item.type && !item.organizationId) ||
                              (item.phoneNumber && !item.organizationId)
                            )
                          : true;
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      },
    };
  }

  __validateSelectedDate() {
    const elementIds = ['selectDateLastSeen', 'selectDateInitialTx'];

    const dateErrors = elementIds.some(elementId => {
      const element = this.shadowRoot.getElementById(ELEMENTS[elementId].id);

      return !!element.invalidTextInput;
    });

    return !dateErrors;
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      if (
        !this.__showSecondaryPayer &&
        this.model.payerInsurance.secondaryInsuranceId
      ) {
        this.__showSecondaryPayer = true;
      }

      if (this.model.billType) {
        this.__billType = this.model.billType;
      }

      if (this.model.unableToWork && this.model.unableToWork.enabled) {
        this.__unableToWorkLabels = createUnableToWorkLabels(true);
      }

      if (this.model.hospitalized && this.model.hospitalized.enabled) {
        this.__hospitalizedLabels = createHospitalizedLabels(true);
      }

      this.__updateDependencyLastSeenDate = this.model.initialTxDate;
      this.__updateDependencyInitialTxDate = this.model.lastSeenDate;
    }
  }

  __getPrimaryInsurance(id) {
    if (id && id !== 'Self') {
      return this.insurances.find(i => i.id === id);
    }

    return (
      this.insurances.find(i => i.defaultLevel === 'Primary' && i.active) || ''
    );
  }

  __getSecondaryInsurance(id) {
    if (id) {
      return this.insurances.find(i => i.id === id);
    }

    return (
      this.insurances.find(i => i.defaultLevel === 'Secondary' && i.active) ||
      ''
    );
  }

  __getPatientPackage(id) {
    if (id) {
      return this.patientPackages.find(c => c.data.id === id);
    }

    return this.patientPackages.find(c => c.data.isDefault) || '';
  }

  __getSpinalHelperText() {
    return this.state.additionalDates.some(
      x => x.type.id === 'acuteManifestationDate',
    )
      ? 'Required'
      : '';
  }

  __isAccidentCareType(careType) {
    return (
      careType &&
      CARE_TYPES_ACCIDENT.some(x => x.data.code === careType.data.code)
    );
  }

  __isCurrentStateCareTypeAccident() {
    return this.__isAccidentCareType(this.state.careType);
  }

  __renderAttorneys() {
    return html`
      <neb-attorney-list
        id="${ELEMENTS.attorneys.id}"
        name="attorneys"
        label="Add New Attorney"
        .model="${this.state.attorneys}"
        .errors="${this.errors.attorneys}"
        .organizations="${this.organizations}"
        .onChange="${this.handlers.changeAttorney}"
        .onChangeOrganization="${this.handlers.changeOrganization}"
        .onSearchOrganization="${this.handlers.searchOrganization}"
        .onAdd="${this.handlers.addItem}"
        .onRemove="${this.handlers.removeItem}"
      ></neb-attorney-list>
    `;
  }

  __renderAdditionalInformation() {
    return html`
      <neb-header
        id="${ELEMENTS.textHeaderAdditionalInformation.id}"
        class="text text-header"
        label="Additional Information"
      >
      </neb-header>

      <div class="padding">
        <div class="referring-provider">
          <neb-textfield
            id="${ELEMENTS.textReferringProviderFirstName.id}"
            name="referringProviderFirstName"
            class="text text-referring-provider-first"
            label="Referring Provider First Name"
            .value="${this.state.referringProviderFirstName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textReferringProviderMiddleName.id}"
            name="referringProviderMiddleName"
            class="text text-referring-provider-middle"
            label="Referring Provider Middle Name"
            .value="${this.state.referringProviderMiddleName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textReferringProviderLastName.id}"
            name="referringProviderLastName"
            class="text text-referring-provider-last"
            label="Referring Provider Last Name"
            .value="${this.state.referringProviderLastName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textReferringProviderCredentials.id}"
            name="referringProviderCredentials"
            class="text text-referring-provider-credential"
            label="Referring Provider Credentials"
            .value="${this.state.referringProviderCredentials}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textReferringProviderOtherId.id}"
            class="text-referring-provider-other-id"
            name="referringProviderOtherId"
            label="Referring Provider Other ID"
            maxLength="17"
            .value="${this.state.referringProviderOtherId}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.referringProviderQualifierDropdown.id}"
            class="referring-provider-qualifier-dropdown"
            name="referringProviderQualifier"
            label="Referring Provider Qualifier"
            .items="${Object.keys(QUALIFIERS).map(type => QUALIFIERS[type])}"
            .value="${this.state.referringProviderQualifier}"
            .onChange="${this.handlers.change}"
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.textReferringProviderNPI.id}"
            class="text-referring-provider-npi"
            name="referringProviderNPI"
            label="Referring Provider NPI"
            maxLength="10"
            .error="${invalidNPI(this.state.referringProviderNPI)}"
            helper="${
              invalidNPI(this.state.referringProviderNPI)
                ? 'Must be 10 characters'
                : ''
            }"
            .mask="${number}"
            .value="${this.state.referringProviderNPI}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        <div class="ordering-provider">
          <neb-textfield
            id="${ELEMENTS.textOrderingProviderFirstName.id}"
            name="orderingProviderFirstName"
            class="text text-ordering-provider-first"
            label="Ordering Provider First Name"
            .value="${this.state.orderingProviderFirstName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textOrderingProviderMiddleName.id}"
            name="orderingProviderMiddleName"
            class="text text-ordering-provider-middle"
            label="Ordering Provider Middle Name"
            .value="${this.state.orderingProviderMiddleName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textOrderingProviderLastName.id}"
            name="orderingProviderLastName"
            class="text text-ordering-provider-last"
            label="Ordering Provider Last Name"
            .value="${this.state.orderingProviderLastName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textOrderingProviderCredentials.id}"
            name="orderingProviderCredentials"
            class="text text-ordering-provider-credential"
            label="Ordering Provider Credentials"
            .value="${this.state.orderingProviderCredentials}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textOrderingProviderOtherId.id}"
            name="orderingProviderOtherId"
            class="text-ordering-provider-other-id"
            label="Ordering Provider Other ID"
            maxLength="17"
            .value="${this.state.orderingProviderOtherId}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.orderingProviderQualifierDropdown.id}"
            class="ordering-provider-qualifier-dropdown"
            name="orderingProviderQualifier"
            label="Ordering Provider Qualifier"
            .items="${Object.keys(QUALIFIERS).map(type => QUALIFIERS[type])}"
            .value="${this.state.orderingProviderQualifier}"
            .onChange="${this.handlers.change}"
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.textOrderingProviderNPI.id}"
            class="text-ordering-provider-npi"
            name="orderingProviderNPI"
            label="Ordering Provider NPI"
            maxLength="10"
            .error="${invalidNPI(this.state.orderingProviderNPI)}"
            helper="${
              invalidNPI(this.state.orderingProviderNPI)
                ? 'Must be 10 characters'
                : ''
            }"
            .mask="${number}"
            .value="${this.state.orderingProviderNPI}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>

        ${this.__renderAttorneys()}

        <div class="other-additional-fields">
          <neb-textarea
            id="${ELEMENTS.textNote.id}"
            name="note"
            class="text text-note"
            label="Note"
            .value="${this.state.note}"
            .onChange="${this.handlers.change}"
            maxlength="850"
            showCount
          ></neb-textarea>

          <neb-switch
            id="${ELEMENTS.buttonToggleActive.id}"
            class="button button-toggle-active"
            name="active"
            label="Active"
            .onChange="${this.handlers.change}"
            ?on="${this.state.active}"
          >
          </neb-switch>
        </div>
      </div>
    `;
  }

  __renderSpinalManipulation() {
    return html`
      <neb-header
        id="${ELEMENTS.textHeaderSpinal.id}"
        class="text text-header"
        label="Spinal Manipulation Information"
      >
      </neb-header>

      <div class="form-general grid-area">
        <div class="spinal-manip container-select-help">
          <neb-select
            id="${ELEMENTS.code.id}"
            class="select select-tooltip"
            name="spinalManipulationInfoCode"
            label="NOC (Nature of Condition)"
            helper="${this.__getSpinalHelperText()}"
            .items="${ITEMS}"
            .error="${this.errors.spinalManipulationInfoCode}"
            .value="${this.state.spinalManipulationInfoCode}"
            .onChange="${this.handlers.change}"
            showFullText
          >
          </neb-select>

          <neb-tooltip
            id="${ELEMENTS.tooltipSpinal.id}"
            defaultAnchor="right"
            class="tooltip-spinal"
          >
            <p slot="tooltip">
              These fields are required to enable Medicare Spinal Manipulation
              Claim Submission when the Date Type of 'Acute Manifestation of a
              Chronic Condition' is selected
            </p>
          </neb-tooltip>
        </div>

        <neb-textfield
          id="${ELEMENTS.description.id}"
          name="spinalManipulationInfoDescription"
          label="Description 1"
          maxLength="80"
          helper="${this.__getSpinalHelperText()}"
          .error="${this.errors.spinalManipulationInfoDescription}"
          .value="${this.state.spinalManipulationInfoDescription || ''}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.description2.id}"
          class="grid-d"
          name="spinalManipulationInfoDescription2"
          label="Description 2"
          maxLength="80"
          .value="${this.state.spinalManipulationInfoDescription2 || ''}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>
      </div>
    `;
  }

  __renderAdditionalDates() {
    return html`
      <neb-header
        id="${ELEMENTS.textHeaderAdditionalDates.id}"
        class="text text-header"
        label="Additional Dates"
      >
      </neb-header>

      <neb-date-type-list
        id="${ELEMENTS.additionalDates.id}"
        name="additionalDates"
        class="form-additional-dates"
        label="Add Additional Date"
        .model="${this.state.additionalDates}"
        .dateTypes="${ITEMS_ADDITIONAL_DATES_SOURCE}"
        .errors="${this.errors.additionalDates}"
        .onChange="${this.handlers.changeAdditionalDate}"
        .onAdd="${this.handlers.addItem}"
        .onRemove="${this.handlers.removeAdditionalDate}"
      ></neb-date-type-list>
    `;
  }

  __renderHospitalized() {
    return html`
      <neb-two-date-item
        id="${ELEMENTS.hospitalized.id}"
        name="hospitalized"
        class="container-two-date-item"
        .model="${this.state.hospitalized}"
        .labels="${this.__hospitalizedLabels}"
        .errors="${this.errors.hospitalized}"
        .onChange="${this.handlers.changeHospitalized}"
        .dateSelectionRules="${DATE_SELECT_RULES_HOSPITALIZED}"
        showCheckBox
      ></neb-two-date-item>
    `;
  }

  __renderUnableToWork() {
    return html`
      <neb-two-date-item
        id="${ELEMENTS.unableToWork.id}"
        name="unableToWork"
        class="container-two-date-item unable-to-work border-short"
        .model="${this.state.unableToWork}"
        .labels="${this.__unableToWorkLabels}"
        .errors="${this.errors.unableToWork}"
        .onChange="${this.handlers.changeUnableToWork}"
        .dateSelectionRules="${DATE_SELECT_RULES_UNABLE_TO_WORK}"
        showCheckBox
      ></neb-two-date-item>
    `;
  }

  __renderDates() {
    return html`
      <neb-header
        id="${ELEMENTS.textHeaderDates.id}"
        class="text text-header"
        label="Dates"
      >
      </neb-header>

      <div class="padding">
        <div class="container-help-symptoms">
          <div
            id="${ELEMENTS.textHelpSymptoms.id}"
            class="text text-onset-symptoms"
          >
            Date of Onset*
          </div>

          <neb-tooltip
            id="${ELEMENTS.tooltipOnsetSymptoms.id}"
            defaultAnchor="right"
          >
            <p slot="tooltip">
              Enter the first date of the present illness, injury/accident date,
              or pregnancy.
            </p>
          </neb-tooltip>
        </div>

        <div class="container-subsection border-short">
          <div class="container-radio-buttons">
            <neb-radio-button
              id="${ELEMENTS.radioDate.id}"
              class="radio-button"
              name="onsetSymptomsDate.gradual"
              .onSelect="${this.handlers.unsetGradual}"
              ?checked="${!this.state.onsetSymptomsDate.gradual}"
            >
            </neb-radio-button>

            <neb-date-picker
              id="${ELEMENTS.selectDateSymptom.id}"
              name="onsetSymptomsDate.date"
              class="input-date input-date-symptom"
              placeholder="Date"
              .selectedDate="${parseDate(this.state.onsetSymptomsDate.date)}"
              .disabled="${this.state.onsetSymptomsDate.gradual}"
              .onChange="${this.handlers.changeDate}"
              .isDateSelectable="${this.handlers.isNotFutureDate}"
              .manualPopoverHorizontalAlignment="${POPOVER_HORIZ_ALIGN.LEFT}"
              .customBoundTop="${CUSTOM_BOUND_TOP_OFFSET}"
              .customBoundBottom="${this.clientHeight}"
              ?invalid="${!!this.errors.onsetSymptomsDate.date}"
              momentFlag
            ></neb-date-picker>

            <neb-radio-button
              id="${ELEMENTS.radioGradual.id}"
              class="radio-button"
              name="onsetSymptomsDate.gradual"
              label="Gradual"
              .onSelect="${this.handlers.setGradual}"
              ?checked="${this.state.onsetSymptomsDate.gradual}"
            >
            </neb-radio-button>
          </div>

          <div class="grid-two-rows-two-columns">
            <neb-date-picker
              id="${ELEMENTS.selectDateInitialTx.id}"
              name="initialTxDate"
              class="cell"
              label="Initial Treatment Date"
              placeholder="Select Date"
              .selectedDate="${parseDate(this.state.initialTxDate)}"
              .onChange="${this.handlers.changeInitialTxDate}"
              .isDateSelectable="${this.handlers.isInitialTxDateSelectable}"
              .updateDependency="${this.__updateDependencyInitialTxDate}"
              .error="${this.errors.initialTxDate}"
              momentFlag
            ></neb-date-picker>

            <neb-date-picker
              id="${ELEMENTS.selectDateLastSeen.id}"
              name="lastSeenDate"
              class="cell"
              label="Last Seen Date"
              placeholder="Select Date"
              .selectedDate="${parseDate(this.state.lastSeenDate)}"
              .onChange="${this.handlers.changeLastSeenDate}"
              .isDateSelectable="${this.handlers.isLastSeenDateSelectable}"
              .updateDependency="${this.__updateDependencyLastSeenDate}"
              momentFlag
            ></neb-date-picker>

            <neb-date-picker
              id="${ELEMENTS.selectDateAccident.id}"
              name="accidentDate"
              class="cell"
              label="Accident Date"
              placeholder="Select Date"
              helperText="${
                this.__isCurrentStateCareTypeAccident() ? 'Required' : ''
              }"
              .isDateSelectable="${this.handlers.isNotFutureDate}"
              .selectedDate="${parseDate(this.state.accidentDate)}"
              .onChange="${this.handlers.changeDate}"
              ?invalid="${!!this.errors.accidentDate}"
              .invalidText="${this.errors.accidentDate}"
              .disabled="${!this.__isCurrentStateCareTypeAccident()}"
              momentFlag
            ></neb-date-picker>

            <neb-select
              id="${ELEMENTS.selectState.id}"
              name="accidentState"
              class="cell"
              label="Accident State"
              maxVisibleItems="8"
              .helper="${
                this.__isCurrentStateCareTypeAccident() ? 'Required' : ''
              }"
              .items="${STATES}"
              .value="${this.state.accidentState}"
              .error="${this.errors.accidentState}"
              .onChange="${this.handlers.change}"
              .disabled="${!this.__isCurrentStateCareTypeAccident()}"
            ></neb-select>
          </div>
        </div>

        ${this.__renderUnableToWork()} ${this.__renderHospitalized()}
      </div>
    `;
  }

  __getSecondaryInsurances() {
    return this.insurances.filter(
      item =>
        item.id !== this.state.payerInsurance.primaryInsuranceId.id &&
        item.active,
    );
  }

  __renderSecondaryInsurance() {
    return this.__showSecondaryPayer
      ? html`
          <div class="grid-insurance">
            <neb-select
              id="${ELEMENTS.selectSecondaryInsurance.id}"
              class="select select-tooltip"
              name="payerInsurance.secondaryInsuranceId"
              emptyMessage="No results"
              label="Secondary Insurance"
              .items="${this.__getSecondaryInsurances()}"
              .value="${this.state.payerInsurance.secondaryInsuranceId}"
              .onChange="${this.handlers.changeSecondaryInsurance}"
              ?disabled="${this.__getSecondaryInsurances().length < 1}"
              showFullText
            ></neb-select>

            <neb-icon
              icon="neb:minus"
              class="icon-minus"
              id="${ELEMENTS.iconMinus.id}"
              @click="${this.handlers.removePayer}"
            ></neb-icon>
          </div>

          <neb-md-textfield
            id="${ELEMENTS.selectSecondaryPayer.id}"
            name="payerInsurance.secondaryPayerId"
            labelText="Secondary Payer"
            .value="${
              this.state.payerInsurance.secondaryPayerId &&
                this.state.payerInsurance.secondaryPayerId.label
            }"
            showFullText
            disabled
          ></neb-md-textfield>
        `
      : html`
          <neb-button-action
            id="${ELEMENTS.buttonAddPayer.id}"
            class="w-fit button-secondary-payer"
            label="Choose Secondary Payer"
            .onClick="${this.handlers.addPayer}"
            ?disabled="${
              !(
                this.state.payerInsurance.primaryInsuranceId.id &&
                this.state.payerInsurance.primaryInsuranceId.id !== 'Self'
              )
            }"
          ></neb-button-action>
        `;
  }

  __getPrimaryInsurances() {
    return this.state.payerInsurance.secondaryInsuranceId
      ? this.insurances.filter(
          item =>
            item.id !== this.state.payerInsurance.secondaryInsuranceId.id &&
            item.active,
        )
      : this.insurances.filter(item => item.active);
  }

  __getPatientPackages() {
    return this.patientPackages.filter(
      p =>
        p.data.active &&
        (p.data.patientId === this.model.patientId ||
          (p.data.patientPackageRelatedPatients &&
            p.data.patientPackageRelatedPatients.some(
              patientPackage =>
                patientPackage.relatedPatientId === this.model.patientId &&
                patientPackage.active,
            ))),
    );
  }

  __renderBillType() {
    if (this.__billType === BILL_TYPES.INSURANCE) {
      return html`
        <div class="grid-insurance">
          <neb-select
            id="${ELEMENTS.selectPrimaryInsurance.id}"
            class="select select-tooltip"
            name="payerInsurance.primaryInsuranceId"
            label="Primary Insurance"
            helper="Required"
            .error="${this.errors.payerInsurance.primaryInsuranceId}"
            .items="${this.__getPrimaryInsurances()}"
            .value="${this.state.payerInsurance.primaryInsuranceId}"
            .onChange="${this.handlers.changePrimaryInsurance}"
            showFullText
          ></neb-select>

          <neb-tooltip
            id="${ELEMENTS.tooltipInsurance.id}"
            defaultAnchor="right"
            class="control-icon"
          >
            <p slot="tooltip">
              If this case is covered by an insurance plan, make that selection
              here. If this case is not covered by an insurance plan and will
              not be billed to an insurance plan, make no selection.
            </p>
          </neb-tooltip>
        </div>

        <neb-md-textfield
          id="${ELEMENTS.selectPrimaryPayer.id}"
          name="payerInsurance.primaryPayerId"
          labelText="Primary Payer"
          .value="${
            this.state.payerInsurance.primaryPayerId &&
              this.state.payerInsurance.primaryPayerId.label
          }"
          showFullText
          disabled
        ></neb-md-textfield>

        ${this.__renderSecondaryInsurance()}
      `;
    }

    if (this.__billType === BILL_TYPES.CARE_PACKAGE) {
      return html`
        <neb-select
          id="${ELEMENTS.selectCarePackage.id}"
          class="select select-tooltip"
          name="patientPackageId"
          label="Patient Package"
          helper="Required"
          .items="${this.__getPatientPackages()}"
          .value="${this.state.patientPackageId}"
          .error="${this.errors.patientPackageId}"
          .onChange="${this.handlers.change}"
          showFullText
        ></neb-select>
      `;
    }

    return html`
      <neb-select
        id="${ELEMENTS.selectSelf.id}"
        class="select select-tooltip"
        label="Primary Payer"
        helper="Required"
        .value="${ITEM_SELF}"
        disabled
      ></neb-select>
    `;
  }

  __renderAddButton() {
    if (this.__billType === BILL_TYPES.INSURANCE) {
      return html`
        <neb-button-action
          id="${ELEMENTS.buttonAddInsurance.id}"
          class="w-fit button-add-insurance"
          label="Add Insurance"
          .onClick="${this.handlers.addInsurance}"
        ></neb-button-action>
      `;
    }

    if (this.__billType === BILL_TYPES.CARE_PACKAGE) {
      return html`
        <neb-button-action
          id="${ELEMENTS.buttonAddPackage.id}"
          class="w-fit button-add-package"
          label="Add New Package"
          .onClick="${this.handlers.addPackage}"
        ></neb-button-action>
      `;
    }

    return html``;
  }

  __renderCaseBillTypes() {
    return html`
      <b class="bill-type">Case Bill Type</b>
      <div class="bill-type radio-buttons">
        <neb-radio-button
          id="${ELEMENTS.selfPayRadioButton.id}"
          label="Self Pay"
          .value="${BILL_TYPES.SELF}"
          .onSelect="${this.handlers.changeBillType}"
          ?checked="${this.__billType === BILL_TYPES.SELF}"
        ></neb-radio-button>
        <neb-radio-button
          id="${ELEMENTS.insuranceRadioButton.id}"
          label="Insurance"
          .value="${BILL_TYPES.INSURANCE}"
          .onSelect="${this.handlers.changeBillType}"
          ?checked="${this.__billType === BILL_TYPES.INSURANCE}"
        ></neb-radio-button>
        <neb-radio-button
          id="${ELEMENTS.carePackageRadioButton.id}"
          label="Care Package"
          .value="${BILL_TYPES.CARE_PACKAGE}"
          .onSelect="${this.handlers.changeBillType}"
          ?checked="${this.__billType === BILL_TYPES.CARE_PACKAGE}"
        ></neb-radio-button>
      </div>
      ${this.__renderBillType()}
    `;
  }

  __renderGeneral() {
    return html`
      <neb-header
        id="${ELEMENTS.textHeaderGeneral.id}"
        class="text-header"
        label="General"
      >
      </neb-header>

      <div class="padding w-fit">
        <neb-checkbox
          id="${ELEMENTS.defaultCheckbox.id}"
          name="isDefault"
          label="Default"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.isDefault}"
        ></neb-checkbox>
      </div>

      <div class="form-general">
        <neb-textfield
          id="${ELEMENTS.textName.id}"
          class="text"
          name="name"
          label="Name"
          helper="Required"
          .value="${this.state.name}"
          .error="${this.errors.name}"
          .onChange="${this.handlers.change}"
          required
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.selectCareType.id}"
          class="select select-care-type"
          name="careType"
          label="Care Type"
          helper="Required"
          .maxVisibleItems="${CARE_TYPES.length}"
          .items="${CARE_TYPES}"
          .value="${this.state.careType}"
          .error="${this.errors.careType}"
          .onChange="${this.handlers.changeCareType}"
          required
        ></neb-select>

        <div class="container-select-help">
          <neb-select
            id="${ELEMENTS.selectGuarantor.id}"
            class="select select-tooltip"
            name="guarantorId"
            label="Guarantor"
            .items="${this.guarantors.filter(g => g.data.active)}"
            .value="${this.state.guarantorId}"
            .onChange="${this.handlers.change}"
          ></neb-select>

          <neb-tooltip
            id="${ELEMENTS.tooltipGuarantor.id}"
            defaultAnchor="right"
            class="tooltip"
          >
            <p slot="tooltip">
              Select a guarantor for this case. This value will be referenced
              for billing activities.
            </p>
          </neb-tooltip>
        </div>

        <neb-textfield
          id="${ELEMENTS.textClaimNumber.id}"
          name="claimNumber"
          class="text"
          label="Claim #"
          maxLength="71"
          .value="${this.state.claimNumber}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-button-action
          id="${ELEMENTS.buttonAddGuarantor.id}"
          class="w-fit button-add-guarantor"
          label="Add Patient Guarantor"
          .onClick="${this.handlers.addGuarantor}"
        ></neb-button-action>

        ${this.__renderAddButton()} ${this.__renderCaseBillTypes()}
      </div>
    `;
  }

  __renderActionBar() {
    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="Save Case"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
            ?processing="${this.saving}"
          ></neb-action-bar>
        `
      : '';
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        <div class="container-form">
          ${this.__renderGeneral()} ${this.__renderDates()}
          ${this.__renderAdditionalDates()} ${this.__renderSpinalManipulation()}
          ${this.__renderAdditionalInformation()}
        </div>
        ${this.__renderActionBar()}
      </div>
    `;
  }
}

window.customElements.define('neb-form-patient-case', NebFormPatientCase);
