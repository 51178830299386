import { getEncounterCharges } from '../../../packages/neb-api-client/src/charting/encounter-charge';
import { getDocumentsWithThumbnails } from '../../../packages/neb-api-client/src/document-api-client';
import { getEncounterDiagnoses } from '../../../packages/neb-api-client/src/encounters-api-client';
import * as listingsApiClient from '../../../packages/neb-api-client/src/listings-api-client';
import { fetchNotes } from '../../../packages/neb-api-client/src/notes';
import {
  getTreatmentPlan,
  getEmptyTreatmentPlan,
} from '../../../packages/neb-api-client/src/treatment-plans';
import { createModel } from '../../../packages/neb-utils/listings';
import { getProblemList } from '../../api-clients/problem-list';
import {
  getEmptyProviderNote,
  getProviderNote,
} from '../../api-clients/provider-notes';
import { UpdateNotificationService } from '../../services/update-notifications';

import { FORCE_RELOAD_ENCOUNTER } from './neb-charting-util';
import { safeRequest } from './shared/safe-request';

const NOTES = {
  subjective: '',
  objective: '',
  assessment: '',
  plan: '',
};

export const PANEL_KEYS = {
  CHART_NOTES: 'chartNotes',
  DIAGNOSIS: 'diagnosis',
  CHARGES: 'charges',
  PROBLEM_LIST: 'problemList',
  DOCUMENTS: 'documents',
  LISTINGS: 'listings',
  TREATMENT_PLAN: 'treatmentPlan',
  PROVIDER_NOTES: 'providerNotes',
};

export class UnsignedEncounterDataController {
  constructor(host) {
    host.addController(this);
    this.host = host;

    this.__initState();
    this.__initServices();
  }

  __initState() {
    this.__loadingFlags = {
      notes: false,
      diagnoses: false,
      charges: false,
      problemList: false,
      treatmentPlan: false,
      documents: false,
      listings: false,
      providerNotes: false,
    };

    this.__state = {
      chartNotes: {
        subjective: '',
        objective: '',
        assessment: '',
        plan: '',
      },

      charges: [],
      diagnoses: [],
      documents: { count: 0, data: [] },
      listings: createModel(),
      problemList: [],
      treatmentPlan: getEmptyTreatmentPlan(),
      providerNotes: { note: '' },
    };

    this.__encounterId = null;
    this.__patientId = null;
  }

  __initServices() {
    this.__forceReloadService = new UpdateNotificationService({
      defaultQuery: FORCE_RELOAD_ENCOUNTER,
      callback: () => {
        this.__setAllPanelsToLoading();

        this.__fetchEncounterDependentData();
        this.__fetchProblemList();
        this.__fetchTreatmentPlan();
        this.__fetchListings();
        this.fetchDocuments({ reset: true });
        this.__fetchProviderNotes();
      },
    });
  }

  // eslint-disable-next-line complexity
  getRefetchPanelCommand(panelKey) {
    const refetchEncounterDependentDataCommand = () => {
      this.__loadingFlags.diagnoses = true;
      this.__loadingFlags.charges = true;
      this.__loadingFlags.notes = true;

      this.host.requestUpdate();

      this.__fetchEncounterDependentData();
    };

    switch (panelKey) {
      case PANEL_KEYS.CHART_NOTES:
        return () => {
          this.__loadingFlags.notes = true;

          this.host.requestUpdate();

          this.__fetchChartNotes();
        };
      case PANEL_KEYS.DIAGNOSIS:
        return refetchEncounterDependentDataCommand;

      case PANEL_KEYS.CHARGES:
        return refetchEncounterDependentDataCommand;

      case PANEL_KEYS.PROBLEM_LIST:
        return () => {
          this.__loadingFlags.problemList = true;

          this.host.requestUpdate();

          this.__fetchProblemList();
        };

      case PANEL_KEYS.LISTINGS:
        return () => {
          this.__loadingFlags.listings = true;

          this.host.requestUpdate();

          this.__fetchListings();
        };

      case PANEL_KEYS.DOCUMENTS:
        return () => {
          this.__loadingFlags.documents = true;

          this.host.requestUpdate();
          this.fetchDocuments({ reset: true });
        };

      case PANEL_KEYS.TREATMENT_PLAN:
        return () => {
          this.__loadingFlags.treatmentPlan = true;

          this.host.requestUpdate();

          this.__fetchTreatmentPlan();
        };

      case PANEL_KEYS.PROVIDER_NOTES:
        return () => {
          this.__loadingFlags.providerNotes = true;

          this.host.requestUpdate();

          this.__fetchProviderNotes();
        };

      default:
        return () => {
          console.warn(`No refetch command for ${panelKey}`);
        };
    }
  }

  get loadingFlags() {
    return this.__loadingFlags;
  }

  get state() {
    return this.__state;
  }

  __setAllPanelsToLoading() {
    this.__loadingFlags = {
      notes: true,
      diagnoses: true,
      charges: true,
      problemList: true,
      treatmentPlan: true,
      documents: true,
      listings: true,
      providerNotes: true,
    };

    this.host.requestUpdate();
  }

  __setEncounterDependentDataPanelsToLoading() {
    this.__loadingFlags.notes = true;
    this.__loadingFlags.diagnoses = true;
    this.__loadingFlags.charges = true;

    this.host.requestUpdate();
  }

  __setPatientDependentDataPanelsToLoading() {
    this.__loadingFlags.problemList = true;
    this.__loadingFlags.treatmentPlan = true;
    this.__loadingFlags.documents = true;
    this.__loadingFlags.listings = true;
    this.__loadingFlags.providerNotes = true;

    this.host.requestUpdate();
  }

  __fetchEncounterDependentData() {
    this.__fetchChartNotes();
    this.__fetchDiagnoses();
    this.__fetchCharges();
  }

  async __fetchChartNotes() {
    const req = await safeRequest(async () => {
      const chartNotes = await fetchNotes(NOTES, this.__encounterId, true);

      this.__loadingFlags.notes = false;
      this.__state.chartNotes = chartNotes;

      this.host.requestUpdate();
    }, 'chart notes');

    if (!req) {
      this.__state.chartNotes = NOTES;
    }
  }

  async __fetchCharges() {
    const req = await safeRequest(async () => {
      const charges = await getEncounterCharges(this.__encounterId, true);

      if (charges.count > 0) {
        charges.reduce((accum, charge) => {
          charge = {
            ...charge,
            nationalDrugCodeEnabled: false,
            nationalDrugCodeQualifier: '',
            nationalDrugCode: null,
            nationalDrugCodeDosage: null,
            nationalDrugCodeUnitOfMeasurement: '',
            nationalDrugCodeNumberCategory: '',
            nationalDrugCodeSequenceOrPrescription: null,
          };

          accum.push(charge);
          return accum;
        }, []);
      }

      this.__loadingFlags.charges = false;
      this.__state.charges = charges;

      this.host.requestUpdate();
    }, 'charges');

    if (!req) {
      this.__state.charges = [];
    }
  }

  async __fetchDiagnoses() {
    const req = await safeRequest(async () => {
      const diagnoses = await getEncounterDiagnoses(this.__encounterId, true);

      this.__loadingFlags.diagnoses = false;
      this.__state.diagnoses = diagnoses;

      this.host.requestUpdate();
    }, 'diagnoses');

    if (!req) {
      this.__state.diagnoses = [];
    }
  }

  async __fetchProblemList() {
    const req = await safeRequest(async () => {
      const problems = await getProblemList(this.__patientId);

      this.__loadingFlags.problemList = false;
      this.__state.problemList = problems;

      this.host.requestUpdate();
    }, 'problem list');

    if (!req) {
      this.__state.problemList = [];
    }
  }

  async __fetchListings() {
    const req = await safeRequest(async () => {
      const listings = await listingsApiClient.fetch(this.__patientId, true);

      this.__loadingFlags.listings = false;
      this.__state.listings = listings;

      this.host.requestUpdate();
    }, 'listings');

    if (!req) {
      this.__state.listings = createModel();
    }
  }

  async __fetchTreatmentPlan() {
    const req = await safeRequest(async () => {
      const treatmentPlan = await getTreatmentPlan(this.__patientId);
      this.__loadingFlags.treatmentPlan = false;
      this.__state.treatmentPlan = treatmentPlan || getEmptyTreatmentPlan();

      this.host.requestUpdate();
    }, 'treatment plan');

    if (!req) {
      this.__state.treatmentPlan = getEmptyTreatmentPlan();
    }
  }

  async __fetchProviderNotes() {
    const req = await safeRequest(async () => {
      const providerNote = await getProviderNote(this.__patientId);

      this.__loadingFlags.providerNotes = false;
      this.__state.providerNotes =
        providerNote || getEmptyProviderNote(this.__patientId);

      this.host.requestUpdate();
    }, 'provider notes');

    if (!req) {
      this.__state.providerNotes = { note: '' };
    }
  }

  async fetchDocuments(options = {}) {
    const existingDocuments = options.reset ? [] : this.__state.documents.data;

    const req = await safeRequest(async () => {
      const { count, data } = await getDocumentsWithThumbnails(
        this.__patientId,
        10,
        existingDocuments.length,
        null,
        null,
        null,
        null,
        true,
      );

      this.__loadingFlags.documents = false;

      this.__state.documents = {
        count,
        data: existingDocuments.concat(data),
      };

      this.host.requestUpdate();
    }, 'documents');

    if (!req) {
      this.__state.documents = { count: 0, data: [] };
    }
  }

  refetch(panelKey) {
    const refetchPanel = this.getRefetchPanelCommand(panelKey);

    if (refetchPanel) {
      refetchPanel();
    }
  }

  hostConnected() {
    this.__forceReloadService.connect();
  }

  hostDisconnected() {
    this.__forceReloadService.disconnect();
  }

  hostUpdate() {
    const { encounterId, patientId } = this.host;

    if (this.__encounterId !== encounterId) {
      this.__encounterId = encounterId;

      this.__setEncounterDependentDataPanelsToLoading();
      this.__fetchEncounterDependentData();
    }

    if (patientId && this.__patientId !== patientId) {
      this.__patientId = patientId;
      this.__setPatientDependentDataPanelsToLoading();

      this.__fetchProblemList();
      this.__fetchTreatmentPlan();
      this.__fetchListings();
      this.__fetchProviderNotes();
      this.fetchDocuments({ reset: true });
    }
  }
}
