import { parseDate } from '../../packages/neb-utils/date-util';
import { getValueByPath } from '../../packages/neb-utils/utils';

function stringIncludes(str, substr) {
  return `${str}`.toLowerCase().includes(substr);
}

export const getKeyByValue = (object, value) =>
  Object.keys(object).find(key => object[key].includes(value));

export const STATUS_ITEMS = [
  {
    label: '',
    data: { id: '' },
  },
  {
    label: 'Active',
    data: { id: 'active' },
  },
  {
    label: 'Inactive',
    data: { id: 'inactive' },
  },
];

export const STATUSES = STATUS_ITEMS.map(item => item.data.id);

export function status() {
  return (item, selection) => {
    switch (selection) {
      case STATUSES[1]:
        return item.active;

      case STATUSES[2]:
        return !item.active;

      default:
        return true;
    }
  };
}

export function search(key) {
  return (item, str) => {
    const param = `${str}`.toLowerCase();
    const [path, nestedPath] = key.split('.*.');

    const subValue = getValueByPath(item, path.split('.'));

    if (nestedPath) {
      if (!str && !subValue.length) {
        return true;
      }

      return subValue.some(s => {
        const value = getValueByPath(s, nestedPath.split('.'));

        return stringIncludes(value, param);
      });
    }

    return stringIncludes(subValue, param);
  };
}

export function numberRange(key) {
  return (item, { min, max }) => {
    const subValue = getValueByPath(item, key.split('.'));
    const passesMin = min === null || subValue >= min;
    const passesMax = max === null || subValue <= max;

    return passesMin && passesMax;
  };
}

export function dateRange(key) {
  return (item, { from, to }) => {
    const subValue = getValueByPath(item, key.split('.'));
    const passesMin = !from || parseDate(subValue).isSameOrAfter(from);
    const passesMax = !to || parseDate(subValue).isSameOrBefore(to);

    return passesMin && passesMax;
  };
}

export function singleSelectSingleTarget(key) {
  return (item, id) => {
    const value = getValueByPath(item, key.split('.'));

    return !id || id === value;
  };
}

export function multiSelectSingleTarget(key) {
  return (item, selection) => {
    const value = getValueByPath(item, key.split('.'));

    return (
      !selection.length || selection.find(id => id === value) !== undefined
    );
  };
}

export function multiSelectMultiTarget(listKey) {
  return (item, selection) => {
    const list = getValueByPath(item, listKey.split('.'));

    return (
      !selection.length ||
      selection.find(id => list.find(i => i.id === id)) !== undefined
    );
  };
}
