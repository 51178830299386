import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  leftSwitch: {
    id: 'leftSwitch',
  },
  rightSwitch: {
    id: 'rightSwitch',
  },
};

class NebPillSwitch extends LitElement {
  static get properties() {
    return {
      labels: Object,
      on: { type: Boolean, reflect: true },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          border-radius: 999px;
          border: 1px solid ${CSS_COLOR_GREY_1};
          cursor: pointer;
          position: relative;
          user-select: none;
          width: 100%;
        }

        .switch {
          padding: 15px;
          border-radius: 999px;
          display: flex;
          align-self: center;
          align-items: center;
          height: 100%;
          width: 50%;
          justify-content: center;
          z-index: 2;
          white-space: nowrap;
        }

        .slider {
          position: absolute;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          height: 100%;
          width: 50%;
          border-radius: 999px;
          transform: translateX(0%);
          transition: transform 0.2s;
        }

        :host(:not([on])) .slider {
          transition: transform 0.2s;
          transform: translateX(100%);
        }

        :host(:not([on])) .rightSwitch {
          color: ${CSS_COLOR_WHITE};
          transition: 0.2s;
        }

        :host([on]) .leftSwitch {
          color: ${CSS_COLOR_WHITE};
          transition: 0.2s;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.labels = {};
    this.on = true;

    this.onToggle = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      toggle: () => {
        this.onToggle(!this.on);
      },
    };
  }

  render() {
    return html`
      <div class="container" @click="${this.__handlers.toggle}">
        <div class="slider"></div>

        <div id="${ELEMENTS.leftSwitch.id}" class="switch leftSwitch">
          ${this.labels.leftSwitch}
        </div>

        <div id="${ELEMENTS.rightSwitch.id}" class="switch rightSwitch">
          ${this.labels.rightSwitch}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-pill-switch', NebPillSwitch);
