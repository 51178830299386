import './neb-calendar-day-column';
import '../../../packages/neb-calendar/neb-cal-swipe';
import './neb-calendar-time-column';

import { LitElement, css, html } from 'lit';

import { parseDate } from '../../../packages/neb-utils/date-util';
import { TIME_SLOT_HEIGHT } from '../../../packages/neb-utils/neb-cal-util';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_BODY,
  CSS_SPACING,
} from '../../styles';

import { getTimeLinePosition } from './neb-base-calendar-view';
import { DATE_FORMAT } from './neb-scheduling-calendar';

export const ELEMENTS = {
  calDayColumn: { id: 'cal-day-column', tag: 'neb-calendar-day-column' },
  calDayColumnNext: { id: 'cal-day-column-next' },
  calDayColumnPrev: { id: 'cal-day-column-prev' },
  calendarDayColumns: { selector: '.day-column' },
  calendarSwipe: { id: 'calendar-swipe' },
  containerTimeLine: { id: 'container-time-line' },
  timeColumn: { id: 'time-column' },
  viewport: { id: 'viewport' },
};

const ZOOM_INTERVAL = 2;
class NebCalendarDayViewMobile extends LitElement {
  static get properties() {
    return {
      model: Object,
      providerId: String,
      locationId: String,
      targetDate: String,
      __timeLineTopOffset: Number,
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex: 1;
        flex-basis: 0.000000001px;
        flex-direction: column;
      }

      .container {
        display: flex;
        position: relative;
        padding-bottom: ${CSS_SPACING};
      }

      .time-column {
        --neb-cal-time-column-width: 80px;
      }

      .columns-container-layout {
        position: relative;
        display: flex;
      }

      .column-layout {
        flex: 1;
        min-width: 300px;
      }

      .column-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: ${CSS_FONT_SIZE_BODY};
        font-weight: bold;
      }

      .left-time-container {
        width: 75px;
      }

      .right-content-container {
        width: calc(100% - 80px);
      }

      .viewport-timeline {
        position: absolute;
        left: 67px;
        right: 5px;
        display: flex;
        align-items: center;
        pointer-events: none;
      }

      .timeline-circle {
        height: 8px;
        width: 8px;
        background-color: ${CSS_COLOR_HIGHLIGHT};
        border-radius: 8px;
      }

      .timeline {
        height: 1px;
        background-color: ${CSS_COLOR_HIGHLIGHT};
        flex: 1;
      }

      .day-column {
        box-sizing: border-box;
        border-right: 1px solid ${CSS_COLOR_GREY_2};
        border-top: 1px solid ${CSS_COLOR_GREY_2};
        border-left: 1px solid ${CSS_COLOR_GREY_2};
        margin-left: 10px;
      }

      .day-column:first-child {
        margin-left: 0;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = {};
    this.targetDate = '';
    this.providerId = '';
    this.locationId = '';

    this.onSwipe = () => {};

    this.onUpdateCalendar = () => {};

    this.__timeLineTopOffset = 0;
  }

  __initHandlers() {
    this.__handlers = {
      swipeLeft: () => {
        this.onSwipe(this.dayAfterTargetDate);
      },
      swipeRight: () => {
        this.onSwipe(this.dayBeforeTargetDate);
      },
      updateCalendar: () => {
        this.onUpdateCalendar();
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.addEventListener('neb-swipe-left-complete', this.__handlers.swipeLeft);

    this.addEventListener(
      'neb-swipe-right-complete',
      this.__handlers.swipeRight,
    );

    this.__timeLineInterval = setInterval(
      () => this.__updateTimeLinePosition(),
      15000,
    );
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener(
      'neb-swipe-left-complete',
      this.__handlers.swipeLeft,
    );

    this.removeEventListener(
      'neb-swipe-right-complete',
      this.__handlers.swipeRight,
    );

    clearInterval(this.__timeLineInterval);
  }

  firstUpdated() {
    this.__scrollToNow();
  }

  updated() {
    this.__updateTimeLinePosition();
  }

  get dayBeforeTargetDate() {
    return parseDate(this.targetDate)
      .subtract(1, 'day')
      .format(DATE_FORMAT);
  }

  get dayAfterTargetDate() {
    return parseDate(this.targetDate)
      .add(1, 'day')
      .format(DATE_FORMAT);
  }

  __updateTimeLinePosition() {
    const viewport = this.shadowRoot.getElementById(ELEMENTS.viewport.id);

    if (viewport && this.targetDate) {
      const now = parseDate();

      const pixelsPerMinute = ZOOM_INTERVAL * (TIME_SLOT_HEIGHT / 60);

      this.__timeLineTopOffset = getTimeLinePosition(
        viewport,
        now,
        pixelsPerMinute,
        0,
      );
    }
  }

  __scrollToNow() {
    const now = parseDate();

    const pixelsPerMinute = TIME_SLOT_HEIGHT / 60;

    const numberOfMinutes = now.hours() * 60 + now.minutes();

    const thirdOfWindowSize = this.clientHeight / 3;

    this.scrollTop = numberOfMinutes * pixelsPerMinute - thirdOfWindowSize;
  }

  __getEvents(date) {
    const primaryKey = this.isProvider ? this.providerId : this.locationId;
    const secondaryKey = this.isProvider ? this.locationId : this.resourceId;

    if (
      this.model[date] &&
      this.model[date][primaryKey] &&
      this.model[date][primaryKey][secondaryKey]
    ) {
      return this.model[date][primaryKey][secondaryKey].events;
    }

    return null;
  }

  __renderTimeLine() {
    return this.targetDate && this.__timeLineTopOffset !== null
      ? html`
          <div
            id="${ELEMENTS.containerTimeLine.id}"
            class="viewport-timeline"
            style="top: ${this.__timeLineTopOffset}px"
          >
            <div class="timeline-circle"></div>

            <div class="timeline"></div>
          </div>
        `
      : '';
  }

  __renderDayColumn(date, id) {
    return html`
      <neb-calendar-day-column
        class="day-column column-layout"
        .id="${id}"
        .date="${date}"
        .providerId="${this.providerId}"
        .locationId="${this.locationId}"
        .events="${this.__getEvents(date)}"
        .onUpdateCalendar="${this.__handlers.updateCalendar}"
        .mobile="${'true'}"
      ></neb-calendar-day-column>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="left-time-container">
          <neb-calendar-time-column
            id="${ELEMENTS.timeColumn.id}"
            class="time-column"
            .interval="${ZOOM_INTERVAL}"
            small
          ></neb-calendar-time-column>
        </div>

        <div id="${ELEMENTS.viewport.id}" class="right-content-container">
          <neb-cal-swipe
            id="${ELEMENTS.calendarSwipe.id}"
            .targetDate="${this.targetDate}"
          >
            <div slot="left" class="columns-container-layout">
              ${
                this.__renderDayColumn(
                  this.dayBeforeTargetDate,
                  ELEMENTS.calDayColumnPrev.id,
                )
              }
            </div>
            <div slot="center" class="columns-container-layout">
              ${
                this.__renderDayColumn(
                  this.targetDate,
                  ELEMENTS.calDayColumn.id,
                )
              }
            </div>
            <div slot="right" class="columns-container-layout">
              ${
                this.__renderDayColumn(
                  this.dayAfterTargetDate,
                  ELEMENTS.calDayColumnNext.id,
                )
              }
            </div>
          </neb-cal-swipe>
        </div>

        ${this.__renderTimeLine()}
      </div>
    `;
  }
}

customElements.define('neb-calendar-day-view-mobile', NebCalendarDayViewMobile);
