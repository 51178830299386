import '../../forms/neb-form-patient-insurance-edit';

import { html, css } from 'lit';

import { getPatientInsurances } from '../../../../../neb-api-client/src/patient-insurance-api-client';
import {
  createModelNewPatientInsurance,
  mapToPatientInsuranceModel,
} from '../../../../../neb-utils/patientInsurance';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  formEditInsurance: {
    id: 'form-edit-insurance',
  },
};

class NebOverlayPatientInsuranceEdit extends Overlay {
  static get properties() {
    return {
      __patientId: String,
      __patientInsurances: Array,
      __patientInsurance: Object,
    };
  }

  initState() {
    super.initState();
    this.__patientId = '';
    this.__patientInsurances = [];
    this.__patientInsurance = { ...createModelNewPatientInsurance() };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: obj => {
        this.__result = obj;
        this.handlers.dirty(false);
        this.handlers.dismiss(obj);
      },
    };
  }

  async __load(patientId) {
    this.__patientInsurances = (await getPatientInsurances(patientId)).map(
      ins => mapToPatientInsuranceModel(ins),
    );
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { patientId = '', patientInsurance } = this.model;

      this.__patientId = patientId;
      this.__patientInsurance = patientInsurance;

      this.__load();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-patient-insurance-edit
        id="${ELEMENTS.formEditInsurance.id}"
        class="form"
        .layout="${this.layout}"
        .patientId="${this.__patientId}"
        .patientInsurances="${this.__patientInsurances}"
        .patientInsurance="${this.__patientInsurance}"
        .hasAddOnCTVerify="${this.model.context.hasAddOnCTVerify}"
        .providers="${this.model.context.providers}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-patient-insurance-edit>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-insurance-edit',
  NebOverlayPatientInsuranceEdit,
);
