import '../../../packages/neb-lit-components/src/components/neb-toggle-chevron';
import '../misc/neb-icon';

import FormService from '@neb/form-service';
import { LitElement, html, css } from 'lit';

import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { baseStyles } from '../../../packages/neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_COLOR_WHITE,
  CSS_COLOR_BLACK,
  CSS_BORDER_GREY_3,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_HIGHLIGHT,
} from '../../../packages/neb-styles/neb-variables';
import { layoutStyles } from '../../styles';

export const ELEMENTS = {
  applyButton: { id: 'button-apply' },
  resetButton: { id: 'button-reset' },
  toggle: {
    id: 'toggle',
  },
  collapsedContent: { id: 'collapsed-content' },
  totalFiltersApplied: { id: 'total-filters-applied' },
  actionButtons: { id: 'action-buttons' },
  filtersTitle: { id: 'filters-title' },
};

export class NebFilters extends LitElement {
  static get properties() {
    return {
      expanded: {
        type: Boolean,
        reflect: true,
        attribute: 'expanded',
      },
      totalFiltersApplied: Number,
      __dirty: Boolean,
      __state: Object,
      hasCollapsedFilters: { type: Boolean, reflect: true },
    };
  }

  set state(_value) {
    throw new Error(
      'Cannot mutate state directly. Did you mean to use this.formService.apply()?',
    );
  }

  get state() {
    return this.__state;
  }

  set formService(_value) {
    throw new Error('Cannot re-assign formService directly');
  }

  get formService() {
    return this.__formService;
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
        }

        .container {
          padding: ${CSS_SPACING} 0 ${CSS_SPACING};
          width: 100%;
          height: 100%;
          border-radius: 4px;
          border: ${CSS_BORDER_GREY_3};
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-buttons {
          padding: 0 ${CSS_SPACING};
          display: inline-grid;
          grid-gap: ${CSS_SPACING};
          grid-template-columns: auto auto;
        }

        :host([hasCollapsedFilters]) .container-buttons {
          padding-left: 5px;
          gap: 5px;
        }

        .title {
          padding-left: ${CSS_SPACING};
          font-size: 14px;
          color: ${CSS_COLOR_BLACK};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .title-badge {
          font-size: 14px;
          color: ${CSS_COLOR_BLACK};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .content {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
          padding: 10px ${CSS_SPACING};
        }

        .icon {
          fill: ${CSS_COLOR_HIGHLIGHT};
          transform: rotate(0deg);
          height: 10px;
          width: 10px;
        }

        :host([expanded]) .icon {
          transform: rotate(180deg);
        }

        .collapsed-filters-container {
          display: grid;
          grid-template-columns: auto 1fr auto;
          grid-template-rows: 1fr auto;
          grid-template-areas:
            'title collapsed-filters action-buttons'
            'expanded-filters expanded-filters expanded-filters';
        }

        :host([hasCollapsedFilters]) .collapsed-filters-container {
          grid-template-areas:
            'title collapsed-filters action-buttons'
            '. expanded-filters .';
          padding-top: 2px;
          padding-bottom: 0px;
        }

        :host([hasCollapsedFilters]:not([expanded]))
          .collapsed-filters-container {
          height: 78px;
        }

        .collapsed-filters-title {
          grid-area: title;
          align-self: center;
          grid-template-columns: auto auto 1fr;
          cursor: pointer;
        }

        :host([hasCollapsedFilters]) .collapsed-filters-title {
          gap: 0px 5px;
        }

        .collapsed-filters {
          grid-area: collapsed-filters;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 5px;
          padding: 0px 5px;
        }

        .collapsed-filters-buttons {
          grid-area: action-buttons;
          align-self: center;
        }

        .expanded-filters {
          grid-area: expanded-filters;
        }

        :host([hasCollapsedFilters]) .expanded-filters-content {
          padding: 0px 5px;
          gap: 5px;
        }
      `,
    ];
  }

  static createModel() {
    throw new Error('createModel() not implemented');
  }

  constructor() {
    super();
    this.initState();
    this.initHandlers();
    this.build();
  }

  initState() {
    this.expanded = true;
    this.__dirty = false;
    this.__state = {};
    this.totalFiltersApplied = 0;
    this.hasCollapsedFilters = false;

    this.onApply = () => {};
  }

  initHandlers() {
    this.handlers = {
      change: e => this.__formService.apply(e.name, e.value),
      apply: () => {
        const model = this.formService.build();
        this.onApply(model);
      },
      reset: () => {
        this.formService.reset();
      },
      toggle: () => {
        this.expanded = !this.expanded;
      },
    };
  }

  createSelectors() {
    return {};
  }

  build() {
    if (!this.__formService) {
      this.__formService = new FormService(
        this.constructor.createModel(),
        this.createSelectors(),
        (dirty, state) => {
          this.__dirty = dirty;
          this.__state = state;
        },
      );
    }
  }

  async load() {
    await Promise.resolve();
  }

  async reload() {
    await this.load();
    this.build();
  }

  firstUpdated() {
    this.reload();
  }

  __renderButtons() {
    return html`
      <div class="container-buttons" id="${ELEMENTS.actionButtons.id}">
        <neb-button
          id="${ELEMENTS.applyButton.id}"
          label="Apply"
          .role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.apply}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.resetButton.id}"
          label="Reset"
          .role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.reset}"
        ></neb-button>
      </div>
    `;
  }

  renderCollapsedContent() {
    throw new Error('renderCollapsedContent() not implemented');
  }

  __renderCollapsedFilters() {
    return this.hasCollapsedFilters
      ? html`
          <div class="collapsed-filters" id="${ELEMENTS.collapsedContent.id}">
            ${this.renderCollapsedContent()}
          </div>
        `
      : html``;
  }

  renderContent() {
    throw new Error('renderContent() not implemented');
  }

  __renderExpandedFilters() {
    return this.expanded
      ? html`
          <div class="expanded-filters">
            <div class="content expanded-filters-content">
              ${this.renderContent()}
            </div>
            ${!this.hasCollapsedFilters ? this.__renderButtons() : ''}
          </div>
        `
      : html``;
  }

  __renderTotalFiltersApplied() {
    return this.totalFiltersApplied
      ? html`
          <span class="title-badge" id="${ELEMENTS.totalFiltersApplied.id}"
            >(${this.totalFiltersApplied})
          </span>
        `
      : '';
  }

  __renderTitle() {
    return html`
      <div
        class="grid grid-auto-left collapsed-filters-title"
        id="${ELEMENTS.filtersTitle.id}"
        @click="${this.handlers.toggle}"
      >
        <span class="title">Filters</span>${
          this.__renderTotalFiltersApplied()
        }<neb-icon
          id="${ELEMENTS.toggle.id}"
          class="icon"
          icon="neb:chevron"
        ></neb-icon>
      </div>
    `;
  }

  render() {
    return html`
      <div class="container collapsed-filters-container">
        ${this.__renderTitle()} ${this.__renderCollapsedFilters()}
        <div class="collapsed-filters-buttons">
          ${this.hasCollapsedFilters ? this.__renderButtons() : ''}
        </div>
        ${this.__renderExpandedFilters()}
      </div>
    `;
  }
}

window.customElements.define('neb-filters', NebFilters);
