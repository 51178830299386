import { html, css } from 'lit';

import { getBillingInfo } from '../../../packages/neb-api-client/src/billing-information-api-client';
import {
  openSuccess,
  openError,
} from '../../../packages/neb-dialog/neb-banner-state';
import { ISO_DATE_FORMAT } from '../../../packages/neb-input/nebFormatUtils';
import NebPopup, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-popup/src/neb-popup';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { CSS_FONT_FAMILY } from '../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import { printPdf } from '../../../packages/neb-utils/neb-pdf-print-util';
import { generateCaseStatements } from '../../api-clients/patient-case-statements';
import { fetch as getPracticeLogo } from '../../api-clients/practice-logo';
import {
  GENERATE_CASE_STATEMENTS_ERROR,
  GENERATE_CASE_STATEMENTS_SUCCESSFULL,
  GENERATE_CASE_STATEMENTS_NO_CHARGES_FOUND,
} from '../../utils/user-message';
import { NebFormGenerateCaseStatement } from '../forms/patients/cases/neb-form-generate-case-statement';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
};

class NebPopupGenerateCaseStatement extends NebPopup {
  static get properties() {
    return {
      __formModel: Object,
      __hasStatementImprovementsFeatureFlag: Boolean,
    };
  }

  initState() {
    super.initState();

    this.__patientId = '';
    this.__caseId = '';

    this.__formModel = NebFormGenerateCaseStatement.createModel();

    this.title = 'Generate New Case Statement';

    this.__hasStatementImprovementsFeatureFlag = false;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      generate: formData => this.__saveCaseStatement(formData),
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasStatementImprovementsFeatureFlag = await hasFeatureOrBeta(
      FEATURE_FLAGS.STATEMENT_IMPROVEMENTS,
    );
  }

  async __getLogo(practiceInfoLocation) {
    let practiceLogo;

    if (!practiceInfoLocation.hideLogo || !this.model.locationId) {
      try {
        practiceLogo = await getPracticeLogo();
      } catch (_) {
        practiceLogo = '';
      }
    }
    return practiceLogo;
  }

  __getOrganizationNPI(
    selectedLocationId,
    practiceInfoLocation,
    billingInformation,
  ) {
    const organizationNPI = selectedLocationId
      ? practiceInfoLocation.organizationNPI
      : billingInformation.organizationNPI;

    return organizationNPI;
  }

  __getTaxId(selectedLocationId, practiceInfoLocation, billingInformation) {
    const taxId = selectedLocationId
      ? practiceInfoLocation.taxId
      : billingInformation.taxId;

    return taxId;
  }

  async __getPracticeInfoByLocationId(selectedLocationId) {
    const billingInformation = await getBillingInfo(true);
    const { locationId: billingLocationId } = billingInformation.billingAddress;
    const locationId = selectedLocationId || billingLocationId;

    const practiceInformation = store.getState().practiceInformation.item;
    const practiceInfoLocation = locationId
      ? practiceInformation.locations.find(l => l.id === locationId)
      : practiceInformation.locations[0];

    return {
      ...(practiceInfoLocation.hideLogo
        ? {}
        : { practiceLogo: (await this.__getLogo(practiceInfoLocation)) || '' }),
      practiceInformation: {
        address: {
          address1: practiceInfoLocation.address1,
          address2: practiceInfoLocation.address2,
          city: practiceInfoLocation.city,
          state: practiceInfoLocation.state,
          zipcode: practiceInfoLocation.zipCode,
        },
        name: selectedLocationId
          ? practiceInfoLocation.businessName || practiceInformation.name
          : practiceInformation.name,
        emailAddress: practiceInfoLocation.emailAddress,
        phoneNumber: practiceInfoLocation.phoneNumber,
        organizationNPI: this.__getOrganizationNPI(
          selectedLocationId,
          practiceInfoLocation,
          billingInformation,
        ),
        taxId: this.__getTaxId(
          selectedLocationId,
          practiceInfoLocation,
          billingInformation,
        ),
        timezone: practiceInformation.timezone,
      },
    };
  }

  __getDateValue(date) {
    return date ? parseDate(date).format(ISO_DATE_FORMAT) : undefined;
  }

  __getArrayValue(array) {
    return array && array.length > 0 ? array : undefined;
  }

  async __formatGenerateCaseStatementPayload({
    dateOfServiceFrom,
    dateOfServiceTo,
    attorneyIds,
    organizationIds,
    providerIds,
    uniqueCaseStatementPerProvider,
    includePurchaseCharges,
    includePaymentDetails,
    includeEncounterSummaries,
    includeEncounterDocuments,
    includeClaims,
    locationId,
    unassignedProviderId,
  }) {
    return {
      providerIds: this.__getArrayValue(providerIds),
      attorneyIds: this.__getArrayValue(attorneyIds),
      organizationIds: this.__getArrayValue(organizationIds),
      dateOfServiceFrom: this.__getDateValue(dateOfServiceFrom),
      dateOfServiceTo: this.__getDateValue(dateOfServiceTo),
      showProviderLicenseNumber: this.__hasStatementImprovementsFeatureFlag,
      uniqueCaseStatementPerProvider,
      includePurchaseCharges,
      includePaymentDetails,
      includeEncounterSummaries,
      includeEncounterDocuments,
      includeClaims,
      unassignedProviderId,
      ...(await this.__getPracticeInfoByLocationId(locationId)),
    };
  }

  async __saveCaseStatement(model) {
    try {
      const payload = await this.__formatGenerateCaseStatementPayload(model);
      const buffer = await generateCaseStatements(
        this.__patientId,
        this.__caseId,
        payload,
      );

      printPdf(Promise.resolve(buffer));

      store.dispatch(openSuccess(GENERATE_CASE_STATEMENTS_SUCCESSFULL));

      this.onClose(true);
    } catch (error) {
      const message =
        error.statusCode === 404
          ? GENERATE_CASE_STATEMENTS_NO_CHARGES_FOUND
          : GENERATE_CASE_STATEMENTS_ERROR;
      store.dispatch(openError(message));
    }

    this.__formModel = model;
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.patientId && this.model.id) {
        this.__patientId = this.model.patientId;
        this.__caseId = this.model.id;
      }
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          width: 525px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-generate-case-statement
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .patientName="${this.model.patientName}"
        .attorneys="${this.model.attorneys}"
        .organizations="${this.model.organizations}"
        .providers="${this.model.providers}"
        .preferredProviderId="${this.model.preferredProviderId}"
        .onSave="${this.handlers.generate}"
        .onCancel="${this.handlers.cancel}"
        .hasStatementImprovementsFeatureFlag="${
          this.__hasStatementImprovementsFeatureFlag
        }"
      ></neb-form-generate-case-statement>
    `;
  }
}

customElements.define(
  'neb-popup-generate-case-statement',
  NebPopupGenerateCaseStatement,
);
