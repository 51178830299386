import { openPopup } from '@neb/popup';

import { getPossibleMatchedLineItems } from '../../../../packages/neb-api-client/src/billing/era/era-charges-match-api-client';
import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';
import { getLineItemDetails } from '../../../../packages/neb-api-client/src/ledger/line-items';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import {
  ALL_CLAIM_CHARGE_MATCH,
  MATCHING_CHARGE_CANNOT_BE_FOUND_IN_SYSTEM,
  MATCH_CHARGE,
  MULTIPLE_CLAIM_CHARGE_MATCH,
  NO,
  NO_CLAIM_CHARGE_MATCH,
  SOME_CLAIM_CHARGE_MATCH,
  YES,
} from '../../../utils/user-message';
import { associateCharges } from '../../era';

import { mapLineItemToEClaimInfo } from './match-charges';

const CLAIM_MATCH_STATUS = Object.freeze({
  ALL_MATCH: 'ALL_MATCH',
  SOME_MATCH: 'SOME_MATCH',
  NO_MATCH: 'NO_MATCH',
});

const CLAIM_STATUSES = Object.freeze({
  1: 'Processed as Primary',
  2: 'Processed as Secondary',
  3: 'Processed as Tertiary',
  4: 'Denied',
  19: 'Processed as Primary, Forwarded to Additional Payer(s)',
  20: 'Processed as Secondary, Forwarded to Additional Payer(s)',
  21: 'Processed as Tertiary, Forwarded to Additional Payer(s)',
  22: 'Reversal of Previous Payment',
  23: 'Not Our Claim, Forwarded to Additional Payer(s)',
  25: 'Predetermination Pricing Only - No Payment',
});

const getStatusCode = status =>
  Object.entries(CLAIM_STATUSES).find(([, val]) => val === status)[0];

const setUpClaimMatchLineItems = (lineItems, possibleMatchedLineItems) =>
  lineItems.map(li => {
    const matchedLineItems = possibleMatchedLineItems[li.lineItemReportId];
    return !matchedLineItems.length
      ? { ...li, errorMessage: [MATCHING_CHARGE_CANNOT_BE_FOUND_IN_SYSTEM] }
      : {
          ...li,
          id: matchedLineItems.length === 1 ? matchedLineItems[0] : '',
          errorMessage:
            matchedLineItems.length > 1 ? [MULTIPLE_CLAIM_CHARGE_MATCH] : [],
        };
  });

const setUpClaimMatchDetails = ({
  claim,
  patientMedicalRecordNumber,
  possibleMatchedLineItems,
}) => ({
  ...claim,
  patientMedicalRecordNumber,
  errorMessage: '',
  lineItems: setUpClaimMatchLineItems(
    claim.lineItems,
    possibleMatchedLineItems,
  ),
});

const setUpClaimMatch = ({
  reportData,
  patientMedicalRecordNumber,
  claimReportId,
  possibleMatchedLineItems,
}) => ({
  ...reportData,
  claims: reportData.claims.map(claim => {
    const isClaimMatch = claim.claimReportId === claimReportId;
    return !isClaimMatch
      ? claim
      : setUpClaimMatchDetails({
          claim,
          patientMedicalRecordNumber,
          possibleMatchedLineItems,
        });
  }),
});

const hasModifiers = li =>
  [li.modifier_1, li.modifier_2, li.modifier_3, li.modifier_4].some(modifier =>
    Boolean(modifier),
  );

const setupMatchedClaimLineItems = (lineItems, modifiers) =>
  lineItems.map(li =>
    mapLineItemToEClaimInfo({
      ...li,
      modifiers: modifiers[li.id] || [],
    }),
  );

const setUpMatchedClaim = (
  {
    claimReportId,
    isClaimSecondary: secondary,
    claimId: patientControlNumber,
    patientMedicalRecordNumber,
    lineItems,
    claimStatus,
  },
  modifiers,
) => ({
  id: claimReportId,
  claimId: claimReportId,
  secondary,
  claimReportId,
  claimStatusCode: getStatusCode(claimStatus),
  patientControlNumber,
  originalReferenceNumber: patientMedicalRecordNumber.match(/(\d+)/)[0],
  patientMedicalRecordNumber,
  lineItems: setupMatchedClaimLineItems(lineItems, modifiers),
});

const setupEClaimInfo = ({ matchedClaim, modifiers, eClaimInfo }) => ({
  ...eClaimInfo,
  claims: [...eClaimInfo.claims, setUpMatchedClaim(matchedClaim, modifiers)],
});

const claimMatchAction = async (data, message) => {
  const response = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
    title: `${MATCH_CHARGE}s`,
    message,
    confirmText: YES,
    cancelText: NO,
  });

  if (!response) return Promise.resolve();

  const report = setUpClaimMatch(data);
  const {
    eraId,
    reportId,
    possibleMatchedLineItems,
    claimReportId,
    reportData: { eClaimInfo: claimInfo },
  } = data;
  const lineItemIds = Object.values(possibleMatchedLineItems)
    .filter(lineItems => lineItems.length === 1)
    .flatMap(li => li);

  const lineItemDetails = lineItemIds.length
    ? await getLineItemDetails({}, { lineItemIds })
    : [];
  const modifiers = lineItemDetails
    .map(li => ({
      [li.id]: hasModifiers(li)
        ? [
            li.modifier_1 ?? '',
            li.modifier_2 ?? '',
            li.modifier_3 ?? '',
            li.modifier_4 ?? '',
          ]
        : [],
    }))
    .reduce((memo, item) => ({ ...memo, ...item }), {});

  const matchedClaim = report.claims.find(
    claim => claim.claimReportId === claimReportId,
  );
  const eClaimInfo = setupEClaimInfo({
    matchedClaim,
    modifiers,
    eClaimInfo: claimInfo,
  });

  return Promise.all([
    updateERAReport(eraId, { reportData: { ...report, eClaimInfo } }),
    ...(lineItemIds.length
      ? [
          associateCharges({
            version: 2,
            lineItemIds,
            eraId,
            reportId,
          }),
        ]
      : []),
  ]);
};

const getMatchedProcedureCodes = ({
  reportData,
  possibleMatchedLineItems,
  claimReportId,
}) =>
  reportData.claims
    .find(claim => claim.claimReportId === claimReportId)
    .lineItems.filter(
      ({ lineItemReportId }) =>
        possibleMatchedLineItems[lineItemReportId].length === 1,
    )
    .map(({ procedureCode }) => procedureCode.split(' ')[0]);

const MATCH_CLAIM_ACTIONS = Object.freeze({
  [CLAIM_MATCH_STATUS.ALL_MATCH]: data =>
    claimMatchAction(data, ALL_CLAIM_CHARGE_MATCH),
  [CLAIM_MATCH_STATUS.SOME_MATCH]: data =>
    claimMatchAction(
      data,
      SOME_CLAIM_CHARGE_MATCH(getMatchedProcedureCodes(data)),
    ),
  [CLAIM_MATCH_STATUS.NO_MATCH]: data =>
    claimMatchAction(data, NO_CLAIM_CHARGE_MATCH),
});

const getClaimMatchStatus = possibleMatchedLineItems => {
  const matchedCount = Object.values(possibleMatchedLineItems).map(
    lineItems => lineItems.length,
  );

  if (matchedCount.every(mc => mc === 0)) return CLAIM_MATCH_STATUS.NO_MATCH;
  if (matchedCount.some(mc => mc === 0)) return CLAIM_MATCH_STATUS.SOME_MATCH;
  return CLAIM_MATCH_STATUS.ALL_MATCH;
};

export default async ({
  claimReportId,
  patient: { id: patientId, mrn: patientMedicalRecordNumber },
  model: { id: eraId, reportId },
  reportData,
}) => {
  const possibleMatchedLineItems = await getPossibleMatchedLineItems({
    claimReportId,
    patientId,
    eraId,
  });

  const claimMatchStatus = getClaimMatchStatus(possibleMatchedLineItems);

  return MATCH_CLAIM_ACTIONS[claimMatchStatus]({
    claimReportId,
    patientMedicalRecordNumber,
    eraId,
    possibleMatchedLineItems,
    reportData,
    reportId,
  });
};
