import '../neb-date-picker';
import '../neb-tooltip';
import '../neb-info';
import '../neb-pagination';
import '../controls/neb-button-action';
import '../tables/neb-table';
import '../inputs/neb-select';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  openPayfacIframePopup,
  ADD_PAYMENT_METHOD_TEMPLATE,
  filterPaymentMethodsByMerchantProvider,
} from '../../../../../src/features/payfac/utils';
import { openPayment } from '../../../../../src/utils/payment-util';
import { TYPE, fetch } from '../../../../neb-api-client/src/billing-codes';
import { fetchOne } from '../../../../neb-api-client/src/patient-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../../neb-api-client/src/patient-relationship-api-client';
import { getPaymentMethods } from '../../../../neb-api-client/src/payments/payment-methods-api-client';
import { getPaymentByElectronicPaymentId } from '../../../../neb-api-client/src/payments-api-client';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import {
  CSS_SPACING_ROW,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { parseDate, dateToRaw } from '../../../../neb-utils/date-util';
import {
  SCHEDULE_PAYMENT_STATUS,
  SCHEDULE_RUN_STATUS,
  PAYMENT_FREQUENCY_OPTIONS,
  PAYMENT_FREQUENCY,
} from '../../../../neb-utils/enums';
import {
  hasFeatureOrBeta,
  FEATURE_FLAGS,
} from '../../../../neb-utils/feature-util';
import { centsToCurrency, toNumeric } from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { required } from '../../../../neb-utils/validators';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';

import { ELEMENTS as ELEMENTS_BASE, NebFormOld } from './neb-form-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  nameField: { id: 'name' },
  paymentTypeDropdown: { id: 'payment-type' },
  startingBalanceField: { id: 'starting-balance' },
  amountField: { id: 'amount' },
  paymentFrequencyDropdown: { id: 'frequency' },
  startDatePicker: { id: 'start-date' },
  paymentMethodDropdown: { id: 'payment-method' },
  addPaymentMethodButton: { id: 'add-payment-method' },
  addPaymentMethodTooltip: { id: 'add-payment-method-tooltip' },
  merchantAccountDropdown: { id: 'merchant-account' },
  paymentsInfo: { id: 'payments-info' },
  paymentsTable: { id: 'payments-table' },
  pagination: { id: 'pagination' },
};

export const PAYMENT_LIMIT = 240;

export const PAYMENTS_TABLE_CONFIG = [
  {
    key: 'start',
    label: 'Payment Date',
    flex: css`1 0 0`,
    formatter: v => parseDate(v).format('MM/DD/YYYY'),
  },
  {
    key: 'amount',
    label: 'Payment Amount',
    flex: css`1 0 0`,
    formatter: v => centsToCurrency(v),
  },
  {
    key: 'status',
    label: 'Status',
    flex: css`1 0 0`,
    formatter: v => (v === null ? 'Scheduled' : v),
  },
  {
    key: 'planBalance',
    label: 'Plan Balance',
    flex: css`1 0 0`,
    formatter: (v, row) => {
      if (row.status === null) return '-';

      return centsToCurrency(v);
    },
  },
];

export const CONFIRM_DELETE = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Delete Recurring Plan Series',
  message: html`
    Deleting the recurring plan series will remove all payments in the series
    from the recurring and scheduled payments list and the payments will not be
    processed.
    <p>Are you sure you want to delete this recurring plan series?</p>
  `,
};

export const CONFIRM_STOP = {
  confirmText: 'Yes',
  cancelText: 'No',
  title: 'Stop Recurring Plan Series',
  message: html`
    Stopping the recurring plan series will remove all future payments in the
    series from the recurring and scheduled payments list and the future
    payments will not be processed. All payments already processed will not be
    affected.
    <p>Are you sure you want to stop this recurring plan series?</p>
  `,
};

export const LIMIT_EXCEEDED_CONFIG = {
  title: 'Payment Plan Exceeds Limit',
  message:
    'You have entered a Payment Plan Starting Balance and Payment Amount that would result in more than 240 scheduled payments. Please update the Payment Plan Starting Balance and/or Payment Amount to decrease the number of payments for this Payment Plan, and save again.',
  confirmText: 'Yes',
};

export default class NebFormScheduledPaymentPlan extends NebFormOld {
  static get properties() {
    return {
      __merchantAccountNames: Array,
      __paymentMethodNames: Array,
      __paymentTypeNames: Array,
      __collectionState: Object,
      __paidPaymentsText: String,
      schedule: Array,
      patientId: String,
      merchantAccounts: Array,
    };
  }

  static createModel() {
    return {
      id: '',
      name: '',
      paymentType: null,
      startingBalance: 0,
      amount: 0,
      frequency: null,
      start: null,
      paymentMethod: null,
      merchantAccount: null,
    };
  }

  constructor() {
    super();

    this.__initServices();
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasPaymentFrequencyFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.PAYMENT_FREQUENCY,
    );

    if (!this.__hasPaymentFrequencyFeature) {
      this.formService.apply('frequency', PAYMENT_FREQUENCY.MONTHLY);
    }
  }

  initState() {
    super.initState();

    this.__merchantAccountNames = [];
    this.__paymentMethods = [];
    this.__paymentMethodNames = [];
    this.__paymentTypeNames = [];
    this.__today = parseDate();
    this.__collectionState = CollectionService.createModel();
    this.__paidPaymentsText = '';
    this.merchantAccounts = [];
    this.schedule = [];

    this.onDelete = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      startDateSelectable: date => date > this.__today,
      addPaymentMethod: async () => {
        const { addresses, firstName, lastName } = await fetchOne(
          this.patientId,
          true,
          true,
        );

        const holderAddress =
          addresses.length > 0
            ? {
                postalCode: addresses[0].zipcode,
                address1: addresses[0].address1,
                address2: addresses[0].address2,
                city: addresses[0].city,
                state: addresses[0].state,
              }
            : {
                postalCode: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
              };

        const result = await openPayfacIframePopup({
          ...ADD_PAYMENT_METHOD_TEMPLATE,
          merchantAccounts: this.merchantAccounts,
          firstName,
          lastName,
          holderId: this.patientId,
          ...holderAddress,
        });

        if (result) {
          this.__paymentMethods = await this.__fetchPaymentMethods();

          const addedPaymentMethod = this.__paymentMethods.find(
            pm => pm.id === result.id,
          );

          const merchantAccountOption = this.__merchantAccountNames.find(
            ma => ma.id === addedPaymentMethod.merchantAccountId,
          );

          this.__setPaymentMethodItems(merchantAccountOption);

          const paymentMethodOption = this.__paymentMethodNames.find(
            pm => pm.id === addedPaymentMethod.id,
          );

          this.formService.apply('merchantAccount', merchantAccountOption);
          this.formService.apply('paymentMethod', paymentMethodOption);
        }
      },
      changeCollectionState: state => {
        this.__collectionState = state;
      },
      sort: (a, b) => {
        const d1 = new Date(a.start);
        const d2 = new Date(b.start);

        return d1 > d2 ? 1 : -1;
      },
      viewPayment: async (name, item, _index) => {
        if (item.status === SCHEDULE_PAYMENT_STATUS.FAILED) {
          const dirty = this.__dirty;

          const updatedSchedule = await openOverlay(
            OVERLAY_KEYS.FAILED_SCHEDULED_PAYMENT,
            {
              type: item.scheduleType,
              patientId: this.patientId,
              item: this.state,
              schedule: item,
            },
          );

          if (
            updatedSchedule &&
            updatedSchedule.status !== SCHEDULE_PAYMENT_STATUS.FAILED
          ) {
            if (updatedSchedule.manuallyPaid) {
              updatedSchedule.status = SCHEDULE_PAYMENT_STATUS.PAID_MANUALLY;
            }

            if (
              updatedSchedule.merchantAccount &&
              updatedSchedule.paymentMethod
            ) {
              if (
                this.__paymentMethodNames.every(
                  ({ id }) => id !== updatedSchedule.paymentMethodId,
                )
              ) {
                const paymentMethods = await this.__fetchPaymentMethods();

                this.__paymentMethodNames = paymentMethods.map(v => ({
                  ...v,
                  label: v.description,
                }));
              }

              this.formService.apply(
                'merchantAccount',
                updatedSchedule.merchantAccount,
              );

              this.formService.apply(
                'paymentMethod',
                updatedSchedule.paymentMethod,
              );
            }

            let planBalance = this.model.startingBalance;
            this.schedule = this.schedule.map(item => {
              if (item.id === updatedSchedule.id) {
                if (
                  updatedSchedule.status === SCHEDULE_PAYMENT_STATUS.PAID ||
                  updatedSchedule.manuallyPaid
                ) {
                  planBalance -= updatedSchedule.amount;
                }

                return {
                  ...item,
                  ...updatedSchedule,
                  planBalance,
                };
              }

              if (
                item.status === SCHEDULE_PAYMENT_STATUS.PAID ||
                item.manuallyPaid
              ) {
                planBalance -= item.amount;
              }

              return { ...item, planBalance };
            });

            this.__collectionService.setItems(this.schedule);

            this.__generatePaymentInfoText();
          }

          this.__dirty = dirty;
        } else if (
          this.layout === 'large' &&
          item.status === SCHEDULE_PAYMENT_STATUS.PAID
        ) {
          if (item.run.length) {
            if (this.__dirty && !(await openDirtyPopup())) return;

            const paidRun = item.run.find(
              r => r.status === SCHEDULE_RUN_STATUS.PAID,
            );

            if (paidRun.transactionId) {
              const payment = await getPaymentByElectronicPaymentId(
                paidRun.transactionId,
              );

              await openPayment({ payment, readonly: false });

              this.model = { ...this.model };
            }
          }
        }
      },
      pageChanged: index => this.__collectionService.setPageIndex(index),
      delete: async () => {
        let confirmed;

        if (this.__hasNoProcessedPayments()) {
          confirmed = await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            CONFIRM_DELETE,
          );
        } else {
          confirmed = await openPopup(POPUP_RENDER_KEYS.CONFIRM, CONFIRM_STOP);
        }

        if (confirmed) {
          this.onDelete();
        }
      },
      save: async () => {
        if (this.formService.validate() && (await this.__checkPaymentLimit())) {
          const start = dateToRaw(this.state.start);

          let model = this.formService.buildModel();

          model = { ...model, start };

          this.__saving = true;
          this.onSave(model);
        }
      },
      change: e => this.__onChange(e),
    };
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: this.handlers.changeCollectionState,
        onSort: this.handlers.sort,
      },
      {
        hideInactive: false,
      },
    );
  }

  async __checkPaymentLimit() {
    if (
      Math.ceil(
        parseInt(toNumeric(this.state.startingBalance), 10) /
          parseInt(toNumeric(this.state.amount), 10),
      ) > PAYMENT_LIMIT
    ) {
      await openPopup(POPUP_RENDER_KEYS.MESSAGE, LIMIT_EXCEEDED_CONFIG);

      return false;
    }

    return true;
  }

  __updatingPaymentPlan() {
    return this.model.id.length;
  }

  __onChange({ name, value, event }) {
    this.formService.apply(name, value);

    if (name === 'merchantAccount' && event === 'select') {
      this.__setPaymentMethodItems(value);
    }
  }

  __setPaymentMethodItems(filterByMerchantAccount) {
    let filteredPaymentMethods = this.__paymentMethods;

    if (filterByMerchantAccount || this.merchantAccounts.length) {
      const currentMerchantAccount = this.merchantAccounts.find(
        m => m.id === this.model.merchantAccountId,
      );

      const filteredPaymentMethodIds = filterPaymentMethodsByMerchantProvider(
        this.__paymentMethods,
        this.merchantAccounts,
        filterByMerchantAccount ||
          currentMerchantAccount ||
          this.merchantAccounts[0],
      ).map(({ id }) => id);

      filteredPaymentMethods = filteredPaymentMethods.filter(({ id }) =>
        filteredPaymentMethodIds.includes(id),
      );

      if (!filteredPaymentMethodIds.includes(this.state.paymentMethod?.id)) {
        this.formService.apply('paymentMethod', null);
      }
    }

    this.__paymentMethodNames = filteredPaymentMethods.map(v => ({
      ...v,
      label: v.description,
    }));
  }

  buildSelectors() {
    return {
      name: [required()],
      paymentType: [required()],
      startingBalance: {
        format: v => centsToCurrency(v),
        unformat: v => toNumeric(v),
        validators: [
          {
            error: 'Must be greater than $0.00',
            validate: v => v !== '$0.00',
          },
        ],
      },
      amount: {
        format: v => centsToCurrency(v),
        unformat: v => toNumeric(v),
        validators: [
          {
            error: 'Must be greater than $0.00',
            validate: v => v !== '$0.00',
          },
          {
            error: 'Cannot exceed $200,000.00 for electronic payments',
            validate: v =>
              parseInt(Number(v.replace(/[^0-9.]+/g, '') * 100), 10) <=
              20000000,
          },
          {
            error: 'Must be less than starting balance',
            validate: v =>
              parseInt(toNumeric(v), 10) <
              parseInt(toNumeric(this.state.startingBalance), 10),
          },
        ],
      },
      frequency: [required()],
      paymentMethod: [required()],
      start: [required()],
    };
  }

  async __fetchPaymentMethods() {
    if (!this.merchantAccounts.length) return [];

    const relationships = await getPatientRelationshipsActiveGroup(
      this.patientId,
    );

    const paymentMethods = await getPaymentMethods(
      this.merchantAccounts[0],
      this.patientId,
      {
        fetchAll: true,
        deleted: false,
      },
      relationships.related.length > 0
        ? [relationships.primary, ...relationships.related]
        : null,
    );

    return paymentMethods;
  }

  async __fetchBillingCodes() {
    const originalItems = await fetch(TYPE.PAYMENT);

    return originalItems.filter(v => v.active);
  }

  __loadDropdownSelections() {
    const model = this.formService.buildModel();

    let paymentType = null;
    let paymentMethod = null;

    if (this.merchantAccounts.length) {
      if (model.merchantAccountId && model.merchantAccountId.length) {
        model.merchantAccount =
          this.__merchantAccountNames.find(
            account => account.id === model.merchantAccountId,
          ) || null;
      } else {
        model.merchantAccount = this.__merchantAccountNames[0];
      }
    }

    if (
      this.__paymentTypeNames.length &&
      model.paymentTypeId &&
      model.paymentTypeId.length
    ) {
      paymentType =
        this.__paymentTypeNames.find(
          paymentType => paymentType.id === model.paymentTypeId,
        ) || null;

      model.paymentType = paymentType;
    }

    if (
      this.__paymentMethodNames.length &&
      model.paymentMethodId &&
      model.paymentMethodId.length
    ) {
      paymentMethod =
        this.__paymentMethodNames.find(
          paymentMethod => paymentMethod.id === model.paymentMethodId,
        ) || null;

      model.paymentMethod = paymentMethod;
    }

    this.formService.refresh(model);

    this.formService.apply('paymentType', paymentType);
    this.formService.apply('paymentMethod', paymentMethod);

    this.__setPaymentMethodItems();
  }

  async updated(changedProps) {
    if (changedProps.has('patientId') || changedProps.has('model')) {
      this.__generatePaymentInfoText();

      this.__merchantAccountNames = this.merchantAccounts
        .filter(v => v.active)
        .map(v => ({
          ...v,
          label: v.name,
        }));

      const paymentTypes = await this.__fetchBillingCodes();

      this.__paymentMethods = await this.__fetchPaymentMethods();

      this.__paymentTypeNames = paymentTypes.map(v => ({
        ...v,
        label: `${v.code} - ${v.description}`,
      }));

      this.__setPaymentMethodItems();

      this.__loadDropdownSelections();

      const { pageIndex } = this.__collectionState;

      let planBalance = this.model.startingBalance;

      const scheduleWithBalance = this.schedule.map(v => {
        if (v.status === SCHEDULE_PAYMENT_STATUS.PAID || v.manuallyPaid) {
          planBalance -= v.amount;
        }

        return {
          ...v,
          planBalance,
        };
      });

      this.__collectionService.setItems(scheduleWithBalance);

      this.__collectionService.setPageIndex(pageIndex);
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
          grid-auto-rows: min-content;
          align-items: center;
        }

        .grid-2 {
          grid-template-columns: 1fr 1fr;
        }

        .date-picker {
          width: 100%;
          height: 70px;
        }

        .grid-row-tooltip {
          display: flex;
        }

        .tooltip {
          margin-left: 10px;
        }

        .payments-info {
          padding-top: ${CSS_SPACING};
        }

        .table {
          padding-top: ${CSS_SPACING};
        }

        .pagination {
          flex-direction: row-reverse;
          padding-top: ${CSS_SPACING};
        }
      `,
    ];
  }

  __generatePaymentInfoText() {
    const allPaidPayments = this.schedule.filter(
      v => v.status === SCHEDULE_PAYMENT_STATUS.PAID || v.manuallyPaid,
    );

    this.__paidPaymentsText = `${allPaidPayments.length} of ${
      this.schedule.length
    } payments have been successfully completed.`;
  }

  __hasNoProcessedPayments() {
    return this.schedule
      ? this.schedule.every(
          entry => entry.status === SCHEDULE_PAYMENT_STATUS.SCHEDULED,
        )
      : true;
  }

  __deleteLabel() {
    return this.__hasNoProcessedPayments() ? 'Delete' : 'Stop Payments';
  }

  renderActionBar() {
    if (this.__updatingPaymentPlan()) {
      return html`
        <neb-action-bar
          id="${ELEMENTS.actionBar.id}"
          confirmLabel="${this.confirmLabel}"
          cancelLabel="${this.__deleteLabel()}"
          removeLabel="${this.cancelLabel}"
          .onConfirm="${this.handlers.save}"
          .onCancel="${this.handlers.delete}"
          .onRemove="${this.handlers.cancel}"
        ></neb-action-bar>
      `;
    }

    return this.__dirty
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            confirmLabel="${this.confirmLabel}"
            cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderMethodsSection() {
    const merchantAccountField =
      this.__merchantAccountNames.length > 1
        ? html`
            <neb-select
              id="${ELEMENTS.merchantAccountDropdown.id}"
              name="merchantAccount"
              class="merchant-account"
              helper="Required"
              label="Merchant Account"
              .items="${this.__merchantAccountNames}"
              .value="${this.state.merchantAccount}"
              .onChange="${this.handlers.change}"
            ></neb-select>
          `
        : '';

    const paymentMethodField = html`
      <neb-select
        id="${ELEMENTS.paymentMethodDropdown.id}"
        name="paymentMethod"
        helper="Required"
        label="Payment Method"
        .items="${this.__paymentMethodNames}"
        .value="${this.state.paymentMethod}"
        .error="${this.errors.paymentMethod}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;

    const addPaymentMethodTooltip = html`
      <div class="grid-row-tooltip">
        <neb-button-action
          id="${ELEMENTS.addPaymentMethodButton.id}"
          label="Add Payment Method"
          .onClick="${this.handlers.addPaymentMethod}"
        ></neb-button-action>
        <neb-tooltip
          id="${ELEMENTS.addPaymentMethodTooltip.id}"
          class="tooltip"
          defaultAnchor="right"
          ><div slot="tooltip">
            Cards stored on file can be used to easily process transactions
            without requiring a card swipe. Debit cards that require a PIN for
            processing cannot be stored and can only be used by swiping with a
            card reader.
          </div>
        </neb-tooltip>
      </div>
    `;

    if (merchantAccountField) {
      return html`
        ${merchantAccountField} ${paymentMethodField}
        <div></div>
        ${addPaymentMethodTooltip}
      `;
    }
    return html`
      ${paymentMethodField} ${addPaymentMethodTooltip}
    `;
  }

  renderContent() {
    return html`
      <div class="grid grid-2">
        <neb-textfield
          id="${ELEMENTS.nameField.id}"
          name="name"
          helper="Required"
          label="Scheduled Payment Name"
          maxLength="50"
          .value="${this.state.name}"
          .error="${this.errors.name}"
          .onChange="${this.handlers.change}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.paymentTypeDropdown.id}"
          name="paymentType"
          helper="Required"
          label="Payment Type"
          .items="${this.__paymentTypeNames}"
          .value="${this.state.paymentType}"
          .error="${this.errors.paymentType}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.__updatingPaymentPlan()}"
        ></neb-select>

        <neb-textfield
          id="${ELEMENTS.startingBalanceField.id}"
          name="startingBalance"
          helper="Required"
          label="Payment Plan Starting Balance"
          maxLength="11"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${this.state.startingBalance}"
          .error="${this.errors.startingBalance}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.__updatingPaymentPlan()}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.amountField.id}"
          name="amount"
          helper="Required"
          label="Payment Amount"
          maxLength="11"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .value="${this.state.amount}"
          .error="${this.errors.amount}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.__updatingPaymentPlan()}"
        ></neb-textfield>

        <neb-select
          id="${ELEMENTS.paymentFrequencyDropdown.id}"
          name="frequency"
          helper="Required"
          label="Payment Frequency"
          .items="${
            this.__hasPaymentFrequencyFeature
              ? PAYMENT_FREQUENCY_OPTIONS
              : [PAYMENT_FREQUENCY.MONTHLY]
          }"
          .value="${this.state.frequency}"
          .error="${this.errors.frequency}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.__updatingPaymentPlan()}"
        ></neb-select>

        <neb-date-picker
          id="${ELEMENTS.startDatePicker.id}"
          name="start"
          class="date-picker"
          helperText="Required"
          label="Start Date"
          placeholder="Select Date"
          manualPopoverPosition="center"
          .selectedDate="${
            this.state.start ? parseDate(this.state.start) : null
          }"
          .invalidText="Required"
          .onChange="${this.handlers.change}"
          .isDateSelectable="${this.handlers.startDateSelectable}"
          ?invalid="${!!this.errors.start}"
          ?disabled="${this.__updatingPaymentPlan()}"
          momentFlag
        ></neb-date-picker>

        ${this.renderMethodsSection()}
      </div>

      ${
        this.__updatingPaymentPlan()
          ? html`
              <div class="payments-info">
                <neb-info id="${ELEMENTS.paymentsInfo.id}">
                  <span>${this.__paidPaymentsText}</span>
                </neb-info>
              </div>

              <neb-table
                id="${ELEMENTS.paymentsTable.id}"
                class="table"
                mode="detail"
                emptyMessage="There are no payments"
                .config="${PAYMENTS_TABLE_CONFIG}"
                .onSelectRow="${this.handlers.viewPayment}"
                .model="${this.__collectionState.pageItems}"
              ></neb-table>

              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                class="pagination"
                .pageCount="${this.__collectionState.pageCount}"
                .currentPage="${this.__collectionState.pageIndex}"
                .onPageChanged="${this.handlers.pageChanged}"
              ></neb-pagination>
            `
          : ''
      }
    `;
  }
}

customElements.define(
  'neb-form-scheduled-payment-plan',
  NebFormScheduledPaymentPlan,
);
