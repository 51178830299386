import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  hasFeatureOrBeta,
  FEATURE_FLAGS,
} from '../../../../packages/neb-utils/feature-util';

export const openOverlayCheckInOut = async (overlayKey, model) => {
  const hasFeatureFlagV2On = await hasFeatureOrBeta(
    FEATURE_FLAGS.CHECK_IN_OUT_V2,
  );

  if (!hasFeatureFlagV2On) {
    return openOverlay(overlayKey, model);
  }

  if (model.mode === 'checkIn') {
    return openOverlay(OVERLAY_KEYS.CHECK_IN, model);
  }

  return openOverlay(OVERLAY_KEYS.CHECK_OUT, model);
};
