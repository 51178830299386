import './controls/neb-button-action';
import '../../../neb-material-design/src/components/neb-loading-spinner';

import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../neb-styles/neb-styles';
import {
  CSS_COLOR_BLUE_BORDER,
  CSS_SPACING,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  button: {
    id: 'button',
  },
  card: {
    id: 'card',
  },
  items: {
    selector: '.item',
  },
  title: {
    id: 'title',
  },
  values: {
    selector: '.value',
  },
  description: {
    selector: '.description',
  },
  loadingSpinner: {
    id: 'loading-spinner',
  },
};

class NebCard extends LitElement {
  static get properties() {
    return {
      buttonConfig: {
        type: Object,
      },
      items: {
        type: Array,
      },
      selected: {
        type: Boolean,
        reflect: true,
      },
      title: {
        type: String,
      },
      disableCardClick: {
        type: Boolean,
        reflect: true,
      },
      loading: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.buttonConfig = null;
    this.items = [];
    this.selected = false;
    this.title = '';
    this.disableCardClick = false;
    this.loading = false;

    this.onButtonClick = () => {};

    this.onCardClick = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      clickButton: () => this.onButtonClick(),
      clickCard: e => {
        e.stopPropagation();
        return this.disableCardClick ? null : this.onCardClick();
      },
      keyup: e => e.key === 'Enter' && this.__handlers.clickCard(e),
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('keyup', this.__handlers.keyup);
    this.addEventListener('click', this.__handlers.clickCard);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          height: 170px;
          width: 100%;
          box-shadow: 0 1px 3px #00000033;
          border-radius: 4px;
          padding: ${CSS_SPACING} 12px;
          --text-size: 1px;
        }

        :host(:not([selected])) {
          border: 3px solid transparent;
          cursor: pointer;
        }

        :host([disableCardClick]) {
          cursor: default;
        }

        :host([selected]) {
          cursor: default;
          border: 3px solid ${CSS_COLOR_BLUE_BORDER};
        }

        :host(:not([selected]):not([disableCardClick]):hover) {
          background-color: #f5f5f5;
        }

        :host(:not([selected]):not([disableCardClick]):focus) {
          background-color: #f5f5f5;
        }

        .card {
          display: flex;
          flex-direction: column;
          height: 100%;
        }

        neb-loading-spinner {
          margin: 0 auto;
        }

        .container {
          display: grid;
          grid-gap: 12px;
          grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        }

        .button-container {
          display: flex;
          justify-content: center;
          margin-top: auto;
        }

        .item {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
        }

        .description {
          font-size: 14px;
          max-width: 100px;
        }

        .item,
        .title {
          text-align: center;
        }

        .title {
          font-weight: bold;
          font-size: 16px;
          padding-bottom: 15px;
        }

        .value {
          font-weight: Bold;
          font-size: var(--text-size);
          color: ${CSS_COLOR_BLUE_BORDER};
        }

        .button {
          margin-top: 10px;
        }
      `,
    ];
  }

  renderTitle() {
    if (!this.title) {
      return '';
    }

    return html`
      <div id="${ELEMENTS.title.id}" class="title">${this.title}</div>
    `;
  }

  renderContent() {
    if (this.loading) {
      return html`
        <neb-loading-spinner
          id="${ELEMENTS.loadingSpinner.id}"
        ></neb-loading-spinner>
      `;
    }

    return html`
      <div class="container">
        ${
          this.items.map(
            item => html`
              <div class="item">
                <div class="value">${item.value}</div>
                <div class="description">${item.description}</div>
              </div>
            `,
          )
        }
      </div>
    `;
  }

  renderButtons() {
    if (!this.buttonConfig) {
      return '';
    }

    return html`
      <div class="button-container">
        <neb-button-action
          id="${ELEMENTS.button.id}"
          class="button"
          .label="${this.buttonConfig.label}"
          .leadingIcon="${this.buttonConfig.icon}"
          .onClick="${this.__handlers.clickButton}"
        ></neb-button-action>
      </div>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.card.id}" class="card">
        ${this.renderTitle()} ${this.renderContent()} ${this.renderButtons()}
      </div>
    `;
  }
}

customElements.define('neb-card', NebCard);
