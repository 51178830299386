import '../inputs/neb-select';
import '../inputs/neb-select-search';
import '../controls/neb-button-icon';

import { isPropRequired, isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import { openWarning } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import { CSS_WARNING_COLOR } from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { CARE_PACKAGE_STATUS } from '../../../../neb-utils/enums';
import {
  ITEM_SELF,
  ITEM_SELF_PAY,
  BILL_TYPE,
  BILL_TYPE_ITEMS,
} from '../../../../neb-utils/neb-ledger-util';
import {
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  hasAuthorizationRemaining,
} from '../../../../neb-utils/patientAuthorization';
import {
  getCarePackageStatus,
  getSharedCarePackageHistory,
} from '../../../../neb-utils/patientPackage';
import * as selectors from '../../../../neb-utils/selectors';
import { getValueByPath } from '../../../../neb-utils/utils';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import { EDIT_MODE } from '../forms/neb-form-ledger-charges';

import NebTable from './neb-table';

export const ELEMENTS = {
  editButton: { id: 'button-edit', tag: 'neb-button-icon' },
  addCaseButton: { id: 'button-add-case', tag: 'neb-button-icon' },
  billTypeSelect: { id: 'select-bill-type', tag: 'neb-select' },
  payerSearch: { id: 'select-payer', tag: 'neb-select-search' },
  planSelect: { id: 'select-primary-insurance', tag: 'neb-select' },
  secondaryInsuranceSelect: {
    id: 'select-secondary-insurance',
    tag: 'neb-select',
  },
  guarantorSelect: { id: 'select-guarantor', tag: 'neb-select' },
  caseSelect: { id: 'select-case', tag: 'neb-select' },
  authorizationSelect: { id: 'select-authorization', tag: 'neb-select' },
  planLabel: { id: 'field-primary-insurance' },
  payerLabel: { id: 'field-primary-payer' },
  caseLabel: { id: 'field-case' },
  caseHeader: { id: 'header-case' },
  authorizationLabel: { id: 'field-authorization' },
  iconAuthorizationWarning: { id: 'icon-authorization-warning' },
  secondaryInsuranceLabel: { id: 'field-secondary-insurance' },
  selfPayerLabel: { id: 'field-self-payer' },
  billTypeTooltip: { id: 'tooltip-bill-type' },
  carePackageSelect: { id: 'select-care-package' },
  carePackageLabel: { id: 'care-package-label' },
  carePackageCheckMark: { id: 'care-package-check-mark' },
};

const ITEM_HEIGHT = 64;

const TOOL_TIP_BILL_TYPE_AUTH =
  'Billing Type, Payer, Plan, and Secondary are set to the selected Authorization. Clear the Case and Authorization in order to apply different Billing Details.';

export default class PayerInfoLedgerTable extends NebTable {
  static get properties() {
    return {
      disableEdit: Boolean,
      searchText: String,
      itemsMap: Object,
      patientId: String,
      allAuthorizations: Array,
      editMode: String,
      carePackageWithInsuranceEnabled: Boolean,
      multiCarePackageEnabled: Boolean,
      __authInitialWarningShown: Boolean,
    };
  }

  static createModel() {
    return [
      {
        billType: BILL_TYPE.SELF,
        payerId: '',
        caseId: '',
        patientAuthorizationId: '',
        packageId: '',
        insuranceId: '',
        guarantorId: '',
        secondaryInsuranceId: '',
        allocations: [],
        datesOfService: [],
        secondaryInsuranceIds: [],
      },
    ];
  }

  static createModelItemsMap() {
    return {
      payers: [],
      cases: [],
      packages: [],
      insurances: [],
      guarantors: [],
      authorizations: [],
    };
  }

  static createSelectors(itemsMap) {
    return {
      children: {
        $: {
          children: {
            billType: selectors.select(BILL_TYPE_ITEMS, ITEM_SELF_PAY, {
              validators: [isPropRequired('data.id')],
            }),
            caseId: selectors.select(itemsMap.cases, selectors.ITEM_EMPTY, {
              validators: [],
            }),
            patientAuthorizationId: selectors.select(
              itemsMap.authorizations,
              selectors.ITEM_EMPTY,
              { validators: [] },
            ),
            payerId: selectors.select(itemsMap.payers, ITEM_SELF, {
              validators: [isRequired()],
            }),
            packageId: selectors.select(
              itemsMap.packages,
              selectors.ITEM_EMPTY,
              {
                validators: [
                  {
                    error: 'Required',
                    validate: (v, keyPath, state, _service) => {
                      const rawValue = v.data.id;
                      const rootPath = keyPath.slice(0, keyPath.length - 1);
                      const billTypePath = [...rootPath, 'billType'];
                      const billTypeItem = getValueByPath(state, billTypePath);
                      const billType = billTypeItem.data.id;
                      const notCarePackage = billType !== BILL_TYPE.PACKAGE;

                      return rawValue || notCarePackage;
                    },
                  },
                ],
              },
            ),
            insuranceId: selectors.select(
              itemsMap.insurances,
              selectors.ITEM_EMPTY,
              {
                validators: [
                  {
                    error: 'Required',
                    validate: (v, keyPath, state, _service) => {
                      const rawValue = v?.data?.id;
                      const rootPath = keyPath.slice(0, keyPath.length - 1);
                      const billTypePath = [...rootPath, 'billType'];
                      const billTypeItem = getValueByPath(state, billTypePath);
                      const billType = billTypeItem.data.id;
                      const notInsurance = billType !== BILL_TYPE.INSURANCE;

                      return rawValue || notInsurance;
                    },
                  },
                ],
              },
            ),
            secondaryInsuranceId: selectors.select(
              itemsMap.insurances,
              selectors.ITEM_EMPTY,
              { validators: [] },
            ),
            guarantorId: selectors.select(itemsMap.guarantors, ITEM_SELF, {
              validators: [],
            }),
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.preview = true;
    this.disableEdit = false;
    this.searchText = '';
    this.itemsMap = PayerInfoLedgerTable.createModelItemsMap();
    this.allAuthorizations = [];
    this.editMode = EDIT_MODE.DISABLED;
    this.carePackageWithInsuranceEnabled = false;
    this.multiCarePackageEnabled = false;
    this.__authInitialWarningShown = false;

    this.onSearch = () => {};

    this.onToggleEdit = () => {};

    this.onSelectPlan = () => {};

    this.onSelectPayer = () => {};

    this.onSelectCase = () => {};

    this.onAddedCase = () => {};

    this.onSelectAuthorization = () => {};

    this.onSelectPackage = () => {};

    this.onOpenPackageSummary = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      toggleEdit: () => this.onToggleEdit(),
      searchPayers: e => this.onSearch(e.value),
      selectPrimaryPayer: () => {
        const payer = this.model[0].payerId.data;
        this.onSelectPayer(payer);
      },
      selectPrimaryPlan: () => {
        const plan = this.getPlan().data;

        return this.model[0].billType.data.id === BILL_TYPE.PACKAGE
          ? this.onSelectPackage(plan)
          : this.onSelectPlan(plan);
      },
      selectSecondaryPlan: () => {
        const plan = this.model[0].secondaryInsuranceId.data;

        this.onSelectPlan(plan);
      },
      selectCase: () => {
        const patientCase = this.model[0].caseId.data;

        this.onSelectCase(patientCase);
      },
      selectAuthorization: () => this.onSelectAuthorization(),
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
      addNewCase: () =>
        this.__openNewCaseOverlay({
          patientId: this.patientId,
          guarantors: this.itemsMap.guarantors,
        }),
      selectCarePackage: () => {
        const carePackage = this.model[0].packageId.data;

        return this.onSelectPackage(carePackage);
      },
      openPatientPackagesSummary: () => this.onOpenPackageSummary(),
    };
  }

  __showAuthorizationWarning() {
    store.dispatch(
      openWarning(
        NO_REMAINING_AUTHORIZATIONS_MESSAGE,
        this.handlers.selectAuthorization,
      ),
    );
  }

  __buildAuthorizationItems(model, defaultItems) {
    if (model.caseId?.data?.id) {
      const caseAuthIds = model.caseId.data.patientAuthorizations.map(
        auth => auth.id,
      );

      return defaultItems.filter(auth => caseAuthIds.includes(auth.data.id));
    }

    return defaultItems;
  }

  __shouldRenderCarePackageField(row, billType) {
    if (this.multiCarePackageEnabled) {
      return null;
    }

    const carePackage =
      row && row.packageId && row.packageId.data ? row.packageId.data.id : null;

    return (
      billType === BILL_TYPE.INSURANCE &&
      (carePackage || this.carePackageWithInsuranceEnabled)
    );
  }

  __addCarePackageColumnConfig(billType) {
    if (this.writable || billType !== BILL_TYPE.SELF) {
      if (!this.multiCarePackageEnabled) {
        if (billType !== BILL_TYPE.PACKAGE) {
          return null;
        }

        return {
          id: ELEMENTS.carePackageSelect.id,
          key: 'packageId',
          label: 'Care Package',
          helper: 'Required',
          flex: css`1 0 0`,
          menuItems: this.getPrimaryPlanItems(),
          truncate: true,
        };
      }

      if (!this.writable && billType === BILL_TYPE.PACKAGE) {
        return {
          id: ELEMENTS.carePackageLabel.id,
          key: 'packageCheckMark',
          label: 'Care Package',
          flex: css`1 0 0`,
        };
      }
    }

    return null;
  }

  __getActiveCarePackages() {
    const activePackages = this.itemsMap.packages.filter(
      carePackage => carePackage.data.active,
    );

    return [selectors.ITEM_EMPTY, ...activePackages];
  }

  __buildTableConfig() {
    const row = this.model[0];

    const billType =
      row && row.billType && row.billType.data && row.billType.data.id
        ? row.billType.data.id
        : BILL_TYPE.SELF;

    this.config = [
      {
        id: ELEMENTS.editButton.id,
        key: 'editButton',
        label: '',
        flex: css`0 0 20px`,
        disabled: this.editMode === EDIT_MODE.TABLE || this.writable,
      },
      {
        id: ELEMENTS.billTypeSelect.id,
        disabled: this.hasCaseWithAuthAndPayer(),
        key: 'billType',
        label: 'Bill Type',
        helper: 'Required',
        flex: css`0 0 140px`,
        menuItems: BILL_TYPE_ITEMS,
        ...(this.hasCaseWithAuthAndPayer()
          ? {
              tooltip: {
                id: ELEMENTS.billTypeTooltip.id,
                message: TOOL_TIP_BILL_TYPE_AUTH,
              },
            }
          : {}),
      },
      {
        id: ELEMENTS.payerSearch.id,
        disabled: !this.usingInsurance() || this.hasCaseWithAuthAndPayer(),
        key: 'payerId',
        label: 'Payer',
        helper: 'Required',
        flex: css`1 0 0`,
        menuItems: this.usingInsurance()
          ? this.getFilteredPayers()
          : [ITEM_SELF],
        truncate: true,
      },
      (this.writable && billType !== BILL_TYPE.PACKAGE) ||
      billType === BILL_TYPE.INSURANCE
        ? {
            id: ELEMENTS.planSelect.id,
            disabled:
              billType === BILL_TYPE.SELF || this.hasCaseWithAuthAndInsurance(),
            key: 'plan',
            label: 'Plan',
            helper: 'Required',
            flex: css`1 0 0`,
            menuItems: this.getPrimaryPlanItems(),
            truncate: true,
          }
        : null,
      this.__addCarePackageColumnConfig(billType),
      this.writable || billType === BILL_TYPE.INSURANCE
        ? {
            id: ELEMENTS.secondaryInsuranceSelect.id,
            truncate: true,
            disabled:
              !this.usingInsurance() ||
              !this.hasPlan() ||
              this.hasCaseWithAuthAndSecInsurance(),
            key: 'secondaryInsuranceId',
            label: 'Secondary',
            helper: ' ',
            flex: css`1 0 0`,
            menuItems: this.getSecondaryPlanItems(),
          }
        : null,
      this.__shouldRenderCarePackageField(row, billType)
        ? {
            id: ELEMENTS.carePackageSelect.id,
            key: 'packageId',
            label: 'Care Package',
            flex: css`1 0 0`,
            truncate: true,
            menuItems: this.__getActiveCarePackages(),
          }
        : null,
      {
        id: ELEMENTS.guarantorSelect.id,
        key: 'guarantorId',
        label: 'Guarantor',
        helper: 'Required',
        flex: css`1 0 0`,
        menuItems: [ITEM_SELF, ...this.itemsMap.guarantors],
        truncate: true,
      },
      {
        id: ELEMENTS.caseSelect.id,
        key: 'caseId',
        label: 'Case',
        helper: ' ',
        flex: css`1 0 0`,
        menuItems: [
          selectors.ITEM_EMPTY,
          ...(this.editMode !== EDIT_MODE.HEADER
            ? this.itemsMap.cases
            : this.itemsMap.cases.filter(({ data }) => data.active)),
        ],
        truncate: true,
      },
      {
        id: ELEMENTS.authorizationSelect.id,
        key: 'patientAuthorizationId',
        label: 'Authorization',
        helper: ' ',
        flex: css`1 0 0`,
        menuItems: this.__buildAuthorizationItems(row, [
          selectors.ITEM_EMPTY,
          ...this.itemsMap.authorizations,
        ]),
        truncate: true,
        forceAlignMenu: 'right',
      },
    ].filter(item => item);
  }

  hasPayment() {
    const row = this.model[0];
    return row
      ? row.allocations.find(({ credit }) => !credit.patientPackageId)
      : false;
  }

  hasPlan() {
    const row = this.model[0];

    return row ? Boolean(row.insuranceId.data.id) : false;
  }

  hasCaseWithAuthAndPayer() {
    const row = this.model[0];

    return row
      ? Boolean(
          row.caseId?.data?.id &&
            row.caseId.data.patientAuthorizations?.length &&
            row.caseId.data.payerInsurance?.primaryPayerId &&
            row.payerId?.data?.id,
        )
      : false;
  }

  hasCaseWithAuthAndInsurance() {
    const row = this.model[0];

    return row
      ? this.hasCaseWithAuthAndPayer() &&
          Boolean(
            row.caseId.data.payerInsurance.primaryInsuranceId &&
              row.insuranceId.data.id,
          )
      : false;
  }

  hasCaseWithAuthAndSecInsurance() {
    const row = this.model[0];

    return row
      ? this.hasCaseWithAuthAndInsurance() &&
          Boolean(
            row.caseId.data.payerInsurance.secondaryInsuranceId &&
              row.secondaryInsuranceId.data.id,
          )
      : false;
  }

  usingInsurance() {
    const row = this.model[0];
    const billType =
      row && row.billType && row.billType.data && row.billType.data.id;

    return billType === BILL_TYPE.INSURANCE;
  }

  getPlan() {
    const row = this.model[0];
    const billType =
      row && row.billType && row.billType.data && row.billType.data.id;

    switch (billType) {
      case BILL_TYPE.SELF:
        return '';

      case BILL_TYPE.PACKAGE:
        return row.packageId;

      case BILL_TYPE.INSURANCE:
        return row.insuranceId;

      default:
        return undefined;
    }
  }

  getPlanKey() {
    const row = this.model[0];
    const billType =
      row && row.billType && row.billType.data && row.billType.data.id;

    switch (billType) {
      case BILL_TYPE.SELF:
        return '';

      case BILL_TYPE.PACKAGE:
        return 'packageId';

      case BILL_TYPE.INSURANCE:
        return 'insuranceId';

      default:
        return undefined;
    }
  }

  __filterPackagesBasedOnHistory(
    packageList,
    datesOfService,
    patientPackageId,
  ) {
    return [
      ...packageList.filter(
        patientPackage =>
          patientPackage.data.patientId === this.patientId ||
          patientPackage.data.id === patientPackageId ||
          (patientPackage.data.patientId !== this.patientId &&
            getSharedCarePackageHistory(
              patientPackage.data,
              datesOfService,
              this.patientId,
            )),
      ),
    ];
  }

  getPrimaryPlanItems() {
    if (!this.model || !this.model.length) {
      return [];
    }

    const row = this.model[0];

    const billType = row.billType && row.billType.data && row.billType.data.id;
    const payer = row.payerId && row.payerId.data;
    const patientPackageId =
      row.packageId && row.packageId.data && row.packageId.data.id;

    switch (billType) {
      case BILL_TYPE.SELF:
        return [];

      case BILL_TYPE.PACKAGE: {
        const res = [
          selectors.ITEM_EMPTY,
          ...this.__filterPackagesBasedOnHistory(
            this.itemsMap.packages.filter(
              ({ data: patientPackage }) =>
                patientPackage.id === patientPackageId ||
                row.datesOfService.find(
                  dateOfService =>
                    getCarePackageStatus(
                      patientPackage,
                      new Date(dateOfService),
                    ) === CARE_PACKAGE_STATUS.VALID,
                ),
            ),
            this.model[0].datesOfService.map(date => new Date(date)),
            patientPackageId,
          ),
        ];
        return res;
      }
      default:
    }

    const { insurances } = this.itemsMap;

    const secondaryInsuranceIds = new Set([
      ...(row.secondaryInsuranceIds ? row.secondaryInsuranceIds : []),
      this.model[0].secondaryInsuranceId.data.id,
    ]);

    const result =
      payer && payer.id
        ? insurances.filter(
            item =>
              item.data.active &&
              item.data.payerPlan.alias === payer.alias &&
              !secondaryInsuranceIds.has(item.data.id),
          )
        : insurances.filter(
            item =>
              item.data.active && !secondaryInsuranceIds.has(item.data.id),
          );

    return [selectors.ITEM_EMPTY, ...result];
  }

  getSecondaryPlanItems() {
    if (!this.model.length) {
      return [];
    }

    const row = this.model[0];

    return this.usingInsurance()
      ? [
          selectors.ITEM_EMPTY,
          ...this.itemsMap.insurances.filter(
            item =>
              item.data.active && item.data.id !== row.insuranceId.data.id,
          ),
        ]
      : [];
  }

  getFilteredPayers() {
    const { payers } = this.itemsMap;

    if (this.searchText) {
      const terms = this.searchText
        .replace(/[()]/g, '')
        .toLowerCase()
        .trim()
        .split(' ');

      return payers.filter(item =>
        terms.every(
          text =>
            item.data.alias.toLowerCase().includes(text) ||
            item.data.payerName.toLowerCase().includes(text),
        ),
      );
    }

    return payers;
  }

  renderItems({ label }) {
    return html`
      <p style="margin: 0 16px; width: max-content; max-width: 450px">
        ${label}
      </p>
    `;
  }

  __displayAuthorizationWarning() {
    if (
      this.model?.length &&
      this.model[0].caseId?.data?.patientAuthorization?.id &&
      this.allAuthorizations?.length
    ) {
      if (
        !hasAuthorizationRemaining(
          this.allAuthorizations.find(
            a =>
              a.data.id === this.model[0].caseId.data.patientAuthorization.id,
          ).data,
        )
      ) {
        if (!this.__authInitialWarningShown) {
          this.__showAuthorizationWarning();
          this.__authInitialWarningShown = true;
        }
      } else {
        this.__authInitialWarningShown = false;
      }
    }
  }

  update(changedProps) {
    const buildProps = ['writable', 'searchText', 'model', 'editMode'];

    if (buildProps.some(item => changedProps.has(item))) {
      this.__buildTableConfig();
    }

    if (changedProps.has('model') && this.model) {
      this.__displayAuthorizationWarning();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .select {
          width: 100%;
        }

        .cell-data[key='plan']:not([writable]),
        .cell-data[key='secondaryInsuranceId']:not([writable]) {
          overflow: hidden;
        }

        .flex-row {
          display: flex;
        }

        .tooltip {
          padding-top: 25px;
          padding-left: 10px;
        }

        .icon-authorization-warning {
          margin-right: 4px;
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_WARNING_COLOR};
        }

        .authorization-container {
          display: flex;
          align-items: center;
        }

        .case-header-container {
          width: 100%;
          justify-content: flex-start;
          align-items: flex-end;
          display: flex;
          gap: 12px;
        }
      `,
    ];
  }

  __renderSelect({ columnConfig, helper, name, value, error, items }) {
    if (
      columnConfig.key === 'packageId' &&
      this.model[0].billType.data.id === BILL_TYPE.INSURANCE
    ) {
      error = false;
    }

    return html`
      <neb-select
        id="${columnConfig.id}"
        class="select"
        label=" "
        .helper="${helper}"
        .name="${name}"
        .value="${value}"
        .error="${error}"
        .items="${items}"
        .onChange="${this.handlers.change}"
        ?disabled="${columnConfig.disabled}"
        .itemHeight="${ITEM_HEIGHT}"
        .onRenderItem="${this.renderItems}"
        .forceAlignMenu="${columnConfig.forceAlignMenu || 'left'}"
        showFullText
        wrapText
      ></neb-select>
    `;
  }

  __renderSelectAndToolTip(config) {
    const {
      columnConfig: { tooltip },
    } = config;

    return tooltip
      ? html`
          <div class="flex-row">
            ${this.__renderSelect(config)}
            <neb-tooltip
              class="tooltip"
              id="${tooltip.id}"
              defaultAnchor="right"
            >
              <div slot="tooltip">${tooltip.message}</div>
            </neb-tooltip>
          </div>
        `
      : this.__renderSelect(config);
  }

  __checkCellAuthorizationWarning() {
    if (
      this.model?.length &&
      this.model[0]?.caseId?.data?.patientAuthorizations?.length &&
      this.model[0]?.patientAuthorizationId?.data?.id &&
      this.allAuthorizations?.length
    ) {
      return !hasAuthorizationRemaining(
        this.model[0].patientAuthorizationId.data,
      );
    }

    return false;
  }

  __formatGradualDate(date, dateFormat = 'MM/DD/YYYY') {
    return date ? parseDate(date).format(dateFormat) : 'Gradual';
  }

  __selectCase(caseItem) {
    this.handlers.change({
      name: '0.caseId',
      value: caseItem,
      event: 'select',
    });
  }

  async __openNewCaseOverlay({ guarantors, patientId }) {
    const { cases } = this.itemsMap;

    const newCase = await openOverlay(OVERLAY_KEYS.CASE, {
      context: {
        patientId,
        guarantors,
        isFirstCase: !cases.length,
      },
    });

    if (!newCase) return;

    const newCaseItem = {
      data: newCase,
      label: `${newCase.name} - ${this.__formatGradualDate(
        newCase.onsetSymptomsDate,
      )}`,
    };

    await this.onAddedCase();
    this.__selectCase(newCaseItem);
  }

  renderHeaderCell(columnConfig) {
    if (this.writable && columnConfig.key === 'caseId') {
      return html`
        <div class="case-header-container">
          <neb-text id="${ELEMENTS.caseHeader.id}" bold
            >${columnConfig.label}</neb-text
          >
          <neb-button-action
            id="${ELEMENTS.addCaseButton.id}"
            class="button"
            label="Add Case"
            .onClick="${this.handlers.addNewCase}"
          ></neb-button-action>
        </div>
      `;
    }

    return super.renderHeaderCell(columnConfig);
  }

  renderDataCell(value, columnConfig, rowIndex, name, error) {
    const helper = columnConfig.helper || ' ';
    const items = columnConfig.menuItems;
    const label = value ? value.label : '';

    if (columnConfig.key === 'editButton') {
      return html`
        <neb-button-icon
          id="${ELEMENTS.editButton.id}"
          icon="neb:edit"
          .onClick="${this.handlers.toggleEdit}"
          ?disabled="${columnConfig.disabled}"
        ></neb-button-icon>
      `;
    }

    if (this.writable) {
      switch (columnConfig.key) {
        case 'payerId':
          return html`
            <neb-select-search
              id="${columnConfig.id}"
              class="select"
              label=" "
              .helper="${helper}"
              .name="${name}"
              .value="${value}"
              .error="${error}"
              .items="${items}"
              .onChange="${this.handlers.change}"
              .onSearch="${this.handlers.searchPayers}"
              ?disabled="${columnConfig.disabled}"
              showSearch
              showFullText
            ></neb-select-search>
          `;

        case 'patientAuthorizationId':
          return html`
            <div class="authorization-container">
              ${
                hasAuthorizationRemaining(value?.data)
                  ? ''
                  : html`
                      <neb-icon
                        id="${ELEMENTS.iconAuthorizationWarning.id}"
                        class="icon-authorization-warning"
                        icon="neb:warning"
                        @click="${this.handlers.authorizationWarningClick}"
                      ></neb-icon>
                    `
              }
              ${
                this.__renderSelectAndToolTip({
                  columnConfig,
                  helper,
                  name,
                  value,
                  error,
                  items,
                })
              }
            </div>
          `;

        default:
          if (columnConfig.key === 'plan') {
            name = `${rowIndex}.${this.getPlanKey()}`;
            value = this.getPlan();
            error = this.errors[rowIndex][this.getPlanKey()];
          }

          return this.__renderSelectAndToolTip({
            columnConfig,
            helper,
            name,
            value,
            error,
            items,
          });
      }
    } else {
      switch (columnConfig.key) {
        case 'payerId':
          return label === ITEM_SELF.label
            ? html`
                <neb-text id="${ELEMENTS.selfPayerLabel.id}">${label}</neb-text>
              `
            : html`
                <neb-text
                  id="${ELEMENTS.payerLabel.id}"
                  bold
                  link
                  truncate
                  .onClick="${this.handlers.selectPrimaryPayer}"
                  >${label}</neb-text
                >
              `;

        case 'plan':
          return html`
            <neb-text
              id="${ELEMENTS.planLabel.id}"
              bold
              link
              truncate
              .onClick="${this.handlers.selectPrimaryPlan}"
              >${this.getPlan().label}</neb-text
            >
          `;

        case 'secondaryInsuranceId':
          return html`
            <neb-text
              id="${ELEMENTS.secondaryInsuranceLabel.id}"
              bold
              link
              truncate
              .onClick="${this.handlers.selectSecondaryPlan}"
              >${label}</neb-text
            >
          `;

        case 'packageId':
          return html`
            <neb-text
              id="${ELEMENTS.carePackageLabel.id}"
              bold
              link
              truncate
              .onClick="${this.handlers.selectCarePackage}"
              >${label}</neb-text
            >
          `;

        case 'packageCheckMark':
          return html`
            <neb-text
              id="${ELEMENTS.carePackageCheckMark.id}"
              .onClick="${this.handlers.openPatientPackagesSummary}"
              link
              >✓</neb-text
            >
          `;

        case 'caseId':
          return html`
            <neb-text
              id="${ELEMENTS.caseLabel.id}"
              bold
              link
              truncate
              .onClick="${this.handlers.selectCase}"
              >${label}</neb-text
            >
          `;

        case 'patientAuthorizationId':
          return html`
            <div class="authorization-container">
              ${
                this.__checkCellAuthorizationWarning()
                  ? html`
                      <neb-icon
                        id="${ELEMENTS.iconAuthorizationWarning.id}"
                        class="icon-authorization-warning"
                        icon="neb:warning"
                        @click="${this.handlers.authorizationWarningClick}"
                      ></neb-icon>
                    `
                  : ''
              }
              <neb-text
                id="${ELEMENTS.authorizationLabel.id}"
                bold
                link
                truncate
                .onClick="${this.handlers.selectAuthorization}"
                >${label}</neb-text
              >
            </div>
          `;

        default:
      }
    }

    return super.renderDataCell(label, columnConfig);
  }
}

customElements.define('neb-table-ledger-payer-info', PayerInfoLedgerTable);
