import '../../tables/ledger/neb-table-charges';
import '../../../../packages/neb-lit-components/src/components/neb-pagination';

import { css, html, LitElement } from 'lit';

import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { BILLING_NOTE_TYPES } from '../../../../packages/neb-utils/constants';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { LINE_ITEM_TYPE } from '../../../../packages/neb-utils/neb-ledger-util';
import {
  NEBULA_REFRESH_EVENT,
  REFRESH_CHANGE_TYPE,
} from '../../../../packages/neb-utils/neb-refresh';
import { MODE } from '../../../../packages/neb-utils/table';
import { getChargesTable } from '../../../api-clients/charges-table';
import { formatCharges, formatTotals } from '../../../formatters/charges-table';
import { CSS_SPACING, CSS_COLOR_GREY_4 } from '../../../styles';
import { NebChargesTableFilters } from '../../filters/neb-filters-charges-table';

export const ELEMENTS = {
  filters: { id: 'filters' },
  chargesTable: { id: 'charges-table' },
  pagination: { id: 'pagination' },
};

export const MESSAGES = {
  EMPTY: 'There are no charges for this patient.',
  ERROR: 'An error occurred while loading charges.',
  LOADING: 'Loading...',
};

export const COLUMNS = [
  {
    key: 'hasBillingNote',
    label: '',
    flex: css`0 0 20px`,
  },
  {
    key: 'dateOfService',
    label: 'Date',
    flex: css`1 0 80px`,
    sortable: true,
  },
  {
    key: 'code',
    label: 'Code',
    flex: css`1 0 60px`,
    truncate: true,
  },
  {
    key: 'billed',
    label: 'Billed',
    flex: css`1 0 55px`,
  },
  {
    key: 'billedAmount',
    label: 'Billed Amt.',
    flex: css`1 0 80px`,
  },
  {
    key: 'allowedAmount',
    label: 'Allowed',
    flex: css`1 0 80px`,
  },
  {
    key: 'taxAmount',
    label: 'Tax',
    flex: css`1 0 80px`,
  },
  {
    key: 'adjustmentAmount',
    label: 'Adj.',
    flex: css`1 0 80px`,
  },
  {
    key: 'primaryPaidAmount',
    label: 'Prim. Paid',
    flex: css`1 0 80px`,
  },
  {
    key: 'primaryBalanceAmount',
    label: 'Prim. Balance',
    flex: css`1 0 80px`,
  },
  {
    key: 'secondaryPaidAmount',
    label: 'Sec. Paid',
    flex: css`1 0 80px`,
  },
  {
    key: 'secondaryBalanceAmount',
    label: 'Sec. Balance',
    flex: css`1 0 80px`,
  },
  {
    key: 'patientPaidAmount',
    label: 'Pt. Paid',
    flex: css`1 0 80px`,
  },
  {
    key: 'patientBalanceAmount',
    label: 'Pt. Balance',
    flex: css`1 0 80px`,
  },
];

const DATE_FORMAT = 'YYYY-MM-DD';

class NebPageCharges extends LitElement {
  static get properties() {
    return {
      patient: Object,
      pageSize: Number,
      __currentPage: Number,
      __charges: Array,
      __totals: Object,
      __message: String,
      __sortParams: Array,
    };
  }

  static get styles() {
    return css`
      .pagination-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        position: sticky;
        bottom: -${CSS_SPACING};

        width: 100%;
        box-sizing: border-box;
        padding: 10px ${CSS_SPACING};
        background-color: ${CSS_COLOR_GREY_4};
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.patient = null;
    this.pageSize = 100;
    this.__currentPage = 0;
    this.__filters = NebChargesTableFilters.createModel();
    this.__rawCharges = [];
    this.__charges = [];
    this.__totals = formatTotals(this.__charges);
    this.__message = MESSAGES.EMPTY;
    this.__sortParams = [
      {
        key: 'dateOfService',
        dir: 'desc',
      },
    ];

    this.__selectableDatesCache = null;
  }

  __initHandlers() {
    this.__handlers = {
      refresh: ({ detail: { changed } }) => {
        if (changed.includes(REFRESH_CHANGE_TYPE.LEDGER)) {
          this.__fetchData();
        }
      },

      filter: filters => {
        this.__filters = filters;
        this.__applyFilters();
        this.__handlers.pageChanged(0);
      },

      sort: (_, updatedParams) => {
        this.__sortParams = updatedParams;
        this.__charges = [...this.__charges].reverse();
      },

      pageChanged: index => {
        this.__currentPage = index;
        this.updateComplete.then(() =>
          this.scrollIntoView({ behavior: 'smooth' }),
        );
      },

      openChargeOverlay: async (_, charge) => {
        switch (charge.type) {
          case LINE_ITEM_TYPE.ENCOUNTER_CHARGE:
            await openOverlay(OVERLAY_KEYS.LEDGER_LINE_ITEM, {
              id: charge.id,
              patientId: this.patient.id,
            });

            this.__fetchData();
            break;

          case LINE_ITEM_TYPE.FEE:
          case LINE_ITEM_TYPE.PURCHASE:
            await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
              patient: { id: this.patient.id },
              lineItemIds: [charge.id],
              selectedIds: [charge.id],
              editTable: true,
              closeOnSave: true,
            });

            this.__fetchData();
            break;

          default:
            throw new Error(`Unexpected charge type ${charge.type}`);
        }
      },

      openBillingNoteOverlay: async charge => {
        const updated = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.CHARGE,
          parentId: charge.id,
          patientId: this.patient.id,
          parentData: {
            dateOfService: charge.dateOfService,
            code: charge.code,
          },
        });

        if (updated) this.__fetchData();
      },

      isDateSelectable: date => {
        this.__selectableDatesCache =
          this.__selectableDatesCache ||
          new Set(
            this.__charges.map(({ dateOfService }) =>
              parseDate(dateOfService).format(DATE_FORMAT),
            ),
          );

        return this.__selectableDatesCache.has(date.format(DATE_FORMAT));
      },

      spotlightFirstChargeWithMatchingDate: date => {
        const chargeIndex = this.__charges.findIndex(
          ({ dateOfService }) =>
            parseDate(dateOfService).format(DATE_FORMAT) ===
            date.format(DATE_FORMAT),
        );

        const pageNumber = Math.floor(chargeIndex / this.pageSize);
        const rowIndex = chargeIndex - this.pageSize * pageNumber;

        this.__currentPage = pageNumber;

        return this.updateComplete.then(() => {
          const row = this.shadowRoot
            .getElementById(ELEMENTS.chargesTable.id)
            .shadowRoot.getElementById(`row-${rowIndex}`);

          row.scrollIntoView({ behavior: 'smooth', block: 'center' });
          row.classList.add('highlight');

          row.addEventListener(
            'animationend',
            () => row.classList.remove('highlight'),
            { once: true },
          );
        });
      },
    };
  }

  connectedCallback() {
    window.addEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
    super.connectedCallback();
  }

  disconnectedCallback() {
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
    super.disconnectedCallback();
  }

  updated(changedProps) {
    if (changedProps.has('patient') && this.patient) {
      this.__fetchData();
    }
  }

  async fetch() {
    await this.__fetchData();
  }

  async __fetchData() {
    this.__selectableDatesCache = null;

    try {
      this.__message = MESSAGES.LOADING;
      this.__rawCharges = await getChargesTable(this.patient.id);
      this.__message = MESSAGES.EMPTY;
    } catch (error) {
      this.__rawCharges = [];
      this.__message = MESSAGES.ERROR;
      console.error(error);
    }

    this.__applyFilters();
  }

  __applyFilters() {
    // eslint-disable-next-line complexity
    this.__charges = this.__rawCharges.filter(charge => {
      const billedStatusOk =
        !this.__filters.billedStatus.length ||
        this.__filters.billedStatus.some(billedStatus => charge[billedStatus]);

      const balanceRemainingOk =
        !this.__filters.balanceRemaining.length ||
        this.__filters.balanceRemaining.some(
          responsibleParty =>
            charge[`${responsibleParty}OwedAmount`] -
            charge[`${responsibleParty}PaidAmount`],
        );

      const dateOfServiceFromOk =
        !this.__filters.dateOfService.from ||
        charge.dateOfService >= this.__filters.dateOfService.from;

      const dateOfServiceToOk =
        !this.__filters.dateOfService.to ||
        charge.dateOfService <= this.__filters.dateOfService.to;

      return (
        billedStatusOk &&
        balanceRemainingOk &&
        dateOfServiceFromOk &&
        dateOfServiceToOk
      );
    });

    if (this.__sortParams[0].dir === 'asc') {
      this.__charges = [...this.__charges].reverse();
    }

    this.__totals = formatTotals(this.__charges);
  }

  get __pageCount() {
    return Math.ceil(this.__charges.length / this.pageSize);
  }

  __renderPagination() {
    if (this.__charges.length <= this.pageSize) {
      return '';
    }

    return html`
      <div class="pagination-wrapper">
        <neb-pagination
          id="${ELEMENTS.pagination.id}"
          .currentPage="${this.__currentPage}"
          .onPageChanged="${this.__handlers.pageChanged}"
          .pageCount="${this.__pageCount}"
        ></neb-pagination>
      </div>
    `;
  }

  get __pageBoundaries() {
    const start = this.__currentPage * this.pageSize;
    const end = Math.min(start + this.pageSize, this.__charges.length);
    return { start, end };
  }

  get __pageCharges() {
    const { start, end } = this.__pageBoundaries;
    const pageCharges = this.__charges.slice(start, end);
    return formatCharges(pageCharges);
  }

  render() {
    return html`
      <neb-filters-charges-table
        id="${ELEMENTS.filters.id}"
        .onApply="${this.__handlers.filter}"
      ></neb-filters-charges-table>

      <neb-table-charges
        id="${ELEMENTS.chargesTable.id}"
        mode="${MODE.DETAIL}"
        .emptyMessage="${this.__message}"
        .config="${COLUMNS}"
        .model="${this.__pageCharges}"
        .totals="${this.__totals}"
        .sortParams="${this.__sortParams}"
        .onSelectRow="${this.__handlers.openChargeOverlay}"
        .onBillingNoteIconClick="${this.__handlers.openBillingNoteOverlay}"
        .onSort="${this.__handlers.sort}"
        .isDateSelectable="${this.__handlers.isDateSelectable}"
        .onDateSelected="${
          this.__handlers.spotlightFirstChargeWithMatchingDate
        }"
        showShadedRows
      ></neb-table-charges>

      ${this.__renderPagination()}
    `;
  }
}

customElements.define('neb-page-charges', NebPageCharges);
