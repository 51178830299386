import '../../neb-popup-header';

import { html, css } from 'lit';

import * as clearinghouseApi from '../../../../../../src/api-clients/clearinghouse';
import { updateDefault } from '../../../../../../src/api-clients/support/billing-clearinghouse-api-client';
import { CSS_SPACING } from '../../../../../../src/styles';
import {
  allAddOns,
  ADD_ONS,
  hasAddOn,
} from '../../../../../../src/utils/add-ons';
import {
  CLEARINGHOUSE_PRESETS,
  BANNER_MESSAGE,
  CLEARINGHOUSES,
  hasByocAddons,
} from '../../../../../../src/utils/clearinghouse-settings';
import * as eClaimsSettingsApi from '../../../../../neb-api-client/src/electronic-claims/settings';
import { testConnection } from '../../../../../neb-api-client/src/files-api-client';
import * as payerPlansApi from '../../../../../neb-api-client/src/payer-plan-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import { FormClearingHouse } from '../../forms/neb-form-clearinghouse';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

class OverlayClearingHouse extends Overlay {
  static get properties() {
    return {
      __savingError: Object,
      __formModel: Object,
      __hasAddOnCtVerify: Boolean,
      __isCHC: Boolean,
      __hasAcknowledgementFeatureFlag: Boolean,
      __hasBYOC277FF: Boolean,
      __byocAddons: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: grid;
          width: 1024px;
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__byocAddons = {};
    this.__hasAddOnCtVerify = false;
    this.__isCHC = false;
    this.__hasAcknowledgementFeatureFlag = false;
    this.__hasBYOC277FF = false;
    this.__savingError = null;
    this.__formModel = FormClearingHouse.createModel();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      save: model => {
        const action = !model.id ? 'add' : 'update';

        this.__save(model, action);
      },
    };
  }

  async __setSupportAddOns() {
    if (this.model.item && this.model.item.accessFromSupport) {
      this.__byocAddons = {
        CT_FLEXCLEAR: this.model.addOnsSupport.includes('ct-flexclear'),
        CT_MAXCLEAR: this.model.addOnsSupport.includes('ct-maxclear'),
        CT_PROCLEAR: this.model.addOnsSupport.includes('ct-proclear'),
        CT_PROCLEAR_WAYSTAR: this.model.addOnsSupport.includes(
          'ct-proclear-waystar',
        ),
      };

      this.__hasAddOnCtVerify = {
        CT_VERIFY: this.model.addOnsSupport.includes('ct-verify'),
      };
    } else {
      this.__byocAddons = await hasByocAddons();
      this.__hasAddOnCtVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
    }
  }

  async connectedCallback() {
    super.connectedCallback();

    await this.__setSupportAddOns();
    this.__hasAcknowledgementFeatureFlag = await hasFeatureOrBeta(
      FEATURE_FLAGS.ACKNOWLEDGEMENT,
    );

    this.__hasBYOC277FF = await hasFeatureOrBeta(FEATURE_FLAGS.BYOC_277);

    this.__isCHC =
      this.model &&
      this.model.item &&
      this.model.item.partner === CLEARINGHOUSES.CHC;

    this.__formModel =
      this.model.item && this.model.item.id
        ? await this.__getUpdateModel()
        : this.__getAddModel();
  }

  __getValidPaths(model) {
    return [
      model.sftpPathClaim,
      model.sftpDownloadActive && model.sftpPathEra,
      this.__hasAcknowledgementFeatureFlag &&
        model.sftpDownloadActive &&
        model.sftpPathAcknowledgement,
      this.__hasBYOC277FF && model.sftpDownloadActive && model.sftpPath277,
    ].filter(exists => exists);
  }

  async __save(model, action) {
    try {
      if (model.partner === CLEARINGHOUSES.CHC) {
        const addOns = await allAddOns();
        const chcPayerPlans = model.payerPlans.map(p =>
          eClaimsSettingsApi.mapPayerPlanToCHCRawV2(p, model.id),
        );

        await Promise.all([
          eClaimsSettingsApi.update(model),
          payerPlansApi.updateCHCPayerPlans(chcPayerPlans),
          updateDefault({ addOns }),
        ]);
      } else {
        if (model.sftpActive) {
          const paths = this.__getValidPaths(model);

          const { connectionStatus } = await testConnection({
            id: model.id,
            host: model.sftpHost,
            username: model.sftpUsername,
            password: model.sftpPassword,
            port: model.sftpPort,
            paths,
          });

          if (connectionStatus !== 'Connection Success') {
            throw Error('No Connection');
          }
        }

        await clearinghouseApi[action](model);
      }

      this.__savingError = null;
      this.isDirty = false;

      this.dismiss(model);

      store.dispatch(openSuccess(BANNER_MESSAGE.success));
    } catch (e) {
      if (e.message === 'No Connection') {
        store.dispatch(openError(BANNER_MESSAGE.connectionError));
      }

      if (e.statusCode === 500) {
        store.dispatch(openError(BANNER_MESSAGE.payerUnavailable));
      } else {
        store.dispatch(openError(BANNER_MESSAGE.error));
      }

      this.__savingError = e;
    }
  }

  async __getUpdateModel() {
    let formModel = {};

    if (this.model.item.partner === CLEARINGHOUSES.CHC) {
      formModel = await this.__getCHCModel();
    } else {
      formModel = await clearinghouseApi.fetchOne(this.model.item.id);

      formModel = {
        ...formModel,
      };
    }

    return {
      ...FormClearingHouse.createModel(!this.model.context.activeItemCount),
      ...formModel,
      ...(!this.__isCHC && { submitterContactType: 'Phone' }),
    };
  }

  async __getCHCModel() {
    const [chc, { payerPlan }] = await Promise.all([
      eClaimsSettingsApi.fetch(),
      payerPlansApi.getPayerPlans({}),
    ]);

    const chcPayerPlans = payerPlan.filter(p => p.chcPayerId !== null);
    return {
      ...chc,
      partner: CLEARINGHOUSES.CHC,
      payerPlans: chcPayerPlans.map(p =>
        eClaimsSettingsApi.mapPayerPlanToBYOCModel(p),
      ),
    };
  }

  __getAddModel() {
    const partner = this.model.item && this.model.item.partner;
    const formModel = partner
      ? {
          partner,
          ...(CLEARINGHOUSE_PRESETS[partner]
            ? {
                sftpHost: CLEARINGHOUSE_PRESETS[partner].sftpHost,
                sftpPort: CLEARINGHOUSE_PRESETS[partner].sftpPort,
                sftpPathClaim: CLEARINGHOUSE_PRESETS[partner].sftpPathClaim,
                sftpPathEra: CLEARINGHOUSE_PRESETS[partner].sftpPathEra,
                sftpPathAcknowledgement:
                  CLEARINGHOUSE_PRESETS[partner].sftpPathAcknowledgement,
                sftpPath277: CLEARINGHOUSE_PRESETS[partner].sftpPath277,
                clearinghouseIdentifierId:
                  CLEARINGHOUSE_PRESETS[partner].clearinghouseIdentifierId,
                clearinghouseEtin:
                  CLEARINGHOUSE_PRESETS[partner].clearinghouseEtin,
                clearinghouseApplicationCode:
                  CLEARINGHOUSE_PRESETS[partner].clearinghouseApplicationCode,
                practiceIdentifierIdPlaceholder:
                  CLEARINGHOUSE_PRESETS[partner]
                    .practiceIdentifierIdPlaceholder,
                practiceEtinPlaceholder:
                  CLEARINGHOUSE_PRESETS[partner].practiceEtinPlaceholder,
                practiceApplicationCodePlaceholder:
                  CLEARINGHOUSE_PRESETS[partner]
                    .practiceApplicationCodePlaceholder,
                customExtension:
                  CLEARINGHOUSE_PRESETS[partner].customExtension || '',
              }
            : {}),
        }
      : {};
    return {
      ...FormClearingHouse.createModel(!this.model.context.activeItemCount),
      ...formModel,
      ...(!this.__isCHC && { submitterContactType: 'Phone' }),
    };
  }

  __getTitle() {
    if (this.model.context.readOnly) {
      return 'View Clearinghouse';
    }

    if (this.model.item && this.model.item.id) {
      return 'Update Clearinghouse';
    }

    return 'Add Clearinghouse';
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${this.__getTitle()}"
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-clearinghouse
        id="${ELEMENTS.form.id}"
        .isCHC="${this.__isCHC}"
        .model="${this.__formModel}"
        .savingError="${this.__savingError}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCancel="${this.handlers.dismiss}"
        .byocAddons="${this.__byocAddons}"
        .payerTableConfig="${this.model.payerTableConfig}"
        .hasAddOnCtVerify="${this.__hasAddOnCtVerify}"
        .defaultDisabled="${this.__byocAddons.CT_MAXCLEAR}"
        .existingClearinghouses="${this.model.context.existingClearinghouses}"
        .hasAcknowledgementFeatureFlag="${this.__hasAcknowledgementFeatureFlag}"
        .hasBYOC277FF="${this.__hasBYOC277FF}"
        ?readOnly="${this.model.context.readOnly}"
        activeItemCount="${this.model.context.activeItemCount}"
      ></neb-form-clearinghouse>
    `;
  }
}

customElements.define('neb-overlay-clearinghouse', OverlayClearingHouse);
