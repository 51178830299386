import { html, css } from 'lit';
import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';

import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { CSS_SPACING, CSS_COLOR_GREY_2 } from '../../../../styles';
import '../../../../../packages/neb-lit-components/src/components/patients/neb-patient-summary-controller';
import './neb-form-check-in';

export const ELEMENTS = {
  patientSummary: {
    id: 'patient-summary',
  },
  header: {
    id: 'header',
  },
  formCheckIn: {
    id: 'form-check-in',
  },
};

class NebOverlayCheckIn extends Overlay {
  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      cancel: () =>
        this.dismiss({
          success: true,
          model: { id: this.model.appointment?.id },
          cancelled: true,
        }),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex-flow: row nowrap;
          width: 80rem;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .content-left {
          padding: ${CSS_SPACING};
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content-right {
          display: flex;
          flex-flow: column nowrap;
          width: 100%;
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      ${
        this.layout !== 'small'
          ? html`
              <neb-patient-summary-controller
                id="${ELEMENTS.patientSummary.id}"
                class="content-left"
                .patientId="${this.model.patientId}"
                .showTreatmentPlanLink="${true}"
                .initialModel="${this.model.patientSummaryModel}"
              >
              </neb-patient-summary-controller>
            `
          : ''
      }

      <div class="content-right">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="Check In"
          .onBack="${this.handlers.cancel}"
          .onCancel="${this.handlers.cancel}"
          .showBackButton="${this.layout === 'small'}"
          .showCancelButton="${this.layout !== 'small'}"
        ></neb-popup-header>
        <neb-form-check-in
          id="${ELEMENTS.formCheckIn.id}"
          class="form"
          .layout="${this.layout}"
          .initialModel="${this.model}"
          .onCancel="${this.handlers.cancel}"
          .onChangeDirty="${this.handlers.dirty}"
        ></neb-form-check-in>
      </div>
    `;
  }
}

customElements.define('neb-overlay-check-in', NebOverlayCheckIn);
