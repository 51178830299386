import { LitElement, html, css } from 'lit';

import { ADD_ONS, hasAddOn } from '../../../../../src/utils/add-ons';
import { getReport } from '../../../../neb-api-client/src/reports-api-client';
import { KEY_LOCAL_PRACTICE_ENTERPRISE_ID } from '../../../../neb-practice-information/actions/practice-information';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import '../neb-loading-overlay';
import '../neb-tabs-old';
import '../../../../../src/components/pages/dashboard/neb-page-reports';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../neb-utils/feature-util';

export const ELEMENTS = {
  tabs: { id: 'tabs' },
  subTabs: { id: 'subTabs' },
  tabItems: { selector: '.tab' },
  subTabItems: { selector: '.sub-tab' },
  iframe: {
    id: 'iframe',
  },
  spinner: {
    id: 'spinner',
  },
  reportsPage: { id: 'reports-page' },
};

const TABS = {
  dashboard: {
    title: 'Dashboard',
    subTabs: {
      daily: { title: 'Daily' },
      appointments: { title: 'Appointments' },
      encounters: { title: 'Encounters' },
      patients: { title: 'Patients' },
      subBilling: { title: 'Charges & Payments' },
      claims: { title: 'Claims' },
      ar: { title: 'AR' },
    },
  },
  reports: {
    title: 'Reports',
    subTabs: {
      reports: {
        title: 'Reports',
      },
      logiondemand: {
        title: 'Report Status',
      },
    },
  },
  audit_log: { title: 'Audit Log' },
  growth_opportunities: {
    title: 'Growth Opportunities',
    billingFlag: ADD_ONS.CT_INSIGHTS,
  },
};

const REPORTS = 'reports';

class NebDashboard extends LitElement {
  static get properties() {
    return {
      __iframeUrl: {
        type: String,
      },
      __selectedTab: {
        type: String,
      },
      __selectedSubTab: {
        type: String,
      },
      __loading: {
        type: Boolean,
      },
      __tabs: { type: Object },
      __subTabs: { type: Object },
      __enabledTabs: { type: Object },
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__iframeUrl = '';
    this.__loading = false;
    this.__selectedTab = '';
    this.__selectedSubTab = '';
    this.__tabs = TABS;
    this.__subTabs = {};
    this.__enabledTabs = {};
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => {
        this.__selectedTab = tab;
        this.__getEnabledSubTabs();

        this.__iframeUrl = '';
        return this.__getIframeUrl();
      },
      selectSubTab: subTab => {
        this.__selectedSubTab = subTab;
        this.__iframeUrl = '';
        return this.__getIframeUrl();
      },
    };
  }

  async __getEnabledTabs() {
    const billingFlags = Object.entries(this.__tabs).reduce(
      (acc, [, { billingFlag }]) => {
        if (!billingFlag || acc.includes(billingFlag)) return acc;
        return [...acc, billingFlag];
      },
      [],
    );

    const isBillingFlagEnabled = await billingFlags.reduce(
      async (acc, flag) => {
        await acc;
        const enabled = await hasAddOn(flag);
        return { ...acc, [flag]: enabled };
      },
      Promise.resolve({}),
    );

    return Object.entries(this.__tabs).reduce(
      (acc, [tab, { title, billingFlag, subTabs }]) => {
        if (billingFlag && !isBillingFlagEnabled[billingFlag]) {
          return acc;
        }

        return {
          ...acc,
          [tab]: {
            title,
            subTabs,
          },
        };
      },
      {},
    );
  }

  __getEnabledSubTabs() {
    this.__subTabs = {};
    this.__selectedSubTab = '';

    const selectedTab = this.__enabledTabs[this.__selectedTab];

    if (selectedTab && selectedTab.subTabs) {
      this.__subTabs = selectedTab.subTabs;
      [this.__selectedSubTab] = Object.keys(selectedTab.subTabs);
    }
  }

  __insertTab(tabs, newTabParamName, value, position) {
    const keys = Object.keys(tabs);
    const values = Object.values(tabs);

    keys.splice(position, 0, newTabParamName);
    values.splice(position, 0, value);

    const newObj = {};

    for (let i = 0; i < keys.length; i++) {
      newObj[keys[i]] = values[i];
    }

    return newObj;
  }

  async connectedCallback() {
    super.connectedCallback();

    const hasEnterpriseReportsFeatureFlag = await hasFeatureOrBeta(
      FEATURE_FLAGS.ENTERPRISE_REPORTS,
    );

    const tenantBelongsToEnterprise = JSON.parse(
      localStorage.getItem(KEY_LOCAL_PRACTICE_ENTERPRISE_ID),
    );

    if (hasEnterpriseReportsFeatureFlag && tenantBelongsToEnterprise) {
      this.__tabs = this.__insertTab(
        this.__tabs,
        'enterprise',
        { title: 'Enterprise' },
        3,
      );
    }

    this.__enabledTabs = await this.__getEnabledTabs();

    this.__subTabs = TABS.dashboard.subTabs;
    this.__selectedTab = 'dashboard';
    this.__selectedSubTab = 'daily';
  }

  async __getIframeUrl() {
    let tab = this.__selectedTab;

    if (!tab || this.__shouldRenderReportPage()) return;
    this.__loading = true;

    if (this.__selectedSubTab !== '') {
      tab = this.__selectedSubTab;
    }

    const { iframeUrl } = await getReport(tab);
    this.__iframeUrl = iframeUrl;

    this.__loading = false;
  }

  updated() {
    if (!this.__iframeUrl && !this.__loading) {
      this.__getIframeUrl();
    }
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        padding: 0 ${CSS_SPACING};
      }

      .frame {
        display: flex;
        flex: 1 0 0;
        width: 100%;
        overflow: hidden;
      }

      .spinner {
        display: flex;
        flex: 1 0 0;
        width: 100%;
      }

      .tabs {
        flex: 0 0 auto;
        overflow: auto;
        white-space: nowrap;
      }

      .sub-tabs {
        padding: 10px ${CSS_SPACING} 0 ${CSS_SPACING};
        background-color: ${CSS_COLOR_WHITE};
        border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        flex: 0 0 auto;
        overflow: auto;
        white-space: nowrap;
      }
    `;
  }

  __shouldRenderReportPage() {
    return this.__selectedSubTab === REPORTS && this.__selectedTab === REPORTS;
  }

  __renderTabs() {
    return this.__enabledTabs
      ? html`
          <neb-tabs-old
            id="${ELEMENTS.tabs.id}"
            class="tabs"
            .selected="${this.__selectedTab}"
            .onChange="${this.__handlers.selectTab}"
            primary
            >${
              Object.entries(this.__enabledTabs).map(
                ([name, { title }]) =>
                  html`
                    <neb-tab-old class="tab" name="${name}"
                      >${title}</neb-tab-old
                    >
                  `,
              )
            }
          </neb-tabs-old>
        `
      : '';
  }

  __renderSubTabs() {
    return Object.keys(this.__subTabs).length > 0
      ? html`
          <neb-tabs-old
            id="${ELEMENTS.subTabs.id}"
            class="sub-tabs"
            .selected="${this.__selectedSubTab}"
            .onChange="${this.__handlers.selectSubTab}"
            underline
            >${
              Object.entries(this.__subTabs).map(
                ([name, { title }]) => html`
                  <neb-tab-old class="sub-tab" name="${name}"
                    >${title}</neb-tab-old
                  >
                `,
              )
            }
          </neb-tabs-old>
        `
      : '';
  }

  renderContent() {
    if (this.__shouldRenderReportPage()) {
      return html`
        <neb-page-reports id="${ELEMENTS.reportsPage.id}"></neb-page-reports>
      `;
    }

    return this.__iframeUrl && typeof this.__iframeUrl === 'string'
      ? html`
          <iframe
            id="${ELEMENTS.iframe.id}"
            class="frame"
            frameborder="0"
            src="${this.__iframeUrl}"
          ></iframe>
        `
      : html`
          <neb-loading-overlay
            id="${ELEMENTS.spinner.id}"
            class="spinner"
            title="Loading Analytics..."
            showDelay="0"
            .show="${true}"
          ></neb-loading-overlay>
        `;
  }

  render() {
    return html`
      ${this.__renderTabs()} ${this.__renderSubTabs()} ${this.renderContent()}
    `;
  }
}

customElements.define('neb-dashboard', NebDashboard);
