import '../../patients/insurance/view/neb-patient-insurance-view-controller';

import { html, css } from 'lit';

import { getPatientInsurance } from '../../../../../neb-api-client/src/patient-insurance-api-client';
import {
  createModelNewPatientInsurance,
  mapToPatientInsuranceModel,
} from '../../../../../neb-utils/patientInsurance';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  controller: {
    id: 'controller',
  },
};

class NebOverlayPatientInsuranceView extends Overlay {
  static get properties() {
    return {
      __patientInsurance: Object,

      patientId: String,
      patientInsuranceId: String,
    };
  }

  initState() {
    super.initState();

    this.__patientInsurance = { ...createModelNewPatientInsurance() };

    this.patientId = '';
    this.patientInsuranceId = null;

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: () => {
        this.result = true;
        this.fetch();
      },
      dismiss: () => this.dismiss(this.result),
    };
  }

  dismissWithBlocker() {
    this.dismiss(this.result);
  }

  async fetch() {
    if (!this.patientInsuranceId) return;

    const rawInsurance = await getPatientInsurance(
      this.patientId,
      this.patientInsuranceId,
      2,
    );

    this.__patientInsurance = { ...mapToPatientInsuranceModel(rawInsurance) };
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      const { patientId, patientInsurance } = this.model;

      this.patientId = patientId || '';
      this.patientInsuranceId = patientInsurance.id;

      await this.fetch();
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }

        .controller {
          flex: 1 0 0;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-patient-insurance-view-controller
        id="${ELEMENTS.controller.id}"
        class="controller"
        .layout="${this.layout}"
        .patientId="${this.patientId}"
        .patientInsurance="${this.__patientInsurance}"
        .onClose="${this.handlers.dismiss}"
        .onChange="${this.handlers.change}"
        slim
      ></neb-patient-insurance-view-controller>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-insurance-view',
  NebOverlayPatientInsuranceView,
);
