import { getEncounterCharges } from '../neb-api-client/src/charting/encounter-charge';
import { APPOINTMENT_STATUS } from '../neb-appointment/neb-appointments';
import {
  APPOINTMENT_DISPLAY_STATUSES,
  APPOINTMENT_ACTIONS,
  APPOINTMENT_ACTIONS_ENCOUNTER_CHECK,
} from '../neb-lit-components/src/components/scheduling/neb-appointment-options';
import { ELEMENTS } from '../neb-lit-components/src/components/scheduling/neb-appointment-page-view';

import { parseDate } from './date-util';

export const getEncounterInfo = async (
  encounter,
  optOutLoadingIndicator = false,
) => {
  const encounterInfo = {
    exists: encounter != null && !encounter.archived,
    signed: !!encounter && encounter.signed,
    charges:
      encounter && encounter.id
        ? await getEncounterCharges(encounter.id, optOutLoadingIndicator)
        : [],
  };

  return encounterInfo;
};

export const getAppointmentActionButtons = (
  appointmentStatus,
  appointmentDate,
  encounterInfo = null,
) => {
  const items = [];

  let actionLabel = '';

  const displayCheckIn =
    appointmentStatus === APPOINTMENT_STATUS.ACTIVE &&
    parseDate(appointmentDate).isBefore(parseDate().endOf('day'));

  actionLabel = displayCheckIn ? APPOINTMENT_ACTIONS.CHECK_IN.action : '';

  if (actionLabel) {
    items.push({
      id: ELEMENTS.buttonCheckIn.id,
      label: APPOINTMENT_ACTIONS.CHECK_IN.label,
      class: 'button button-check-in',
      isOutlined: false,
      actionName: actionLabel,
      encounterCheck: { allowed: true },
    });
  }

  actionLabel =
    appointmentStatus === APPOINTMENT_STATUS.CHECKED_IN
      ? APPOINTMENT_ACTIONS.CHECK_OUT.action
      : '';

  if (actionLabel) {
    items.push({
      id: ELEMENTS.buttonCheckOut.id,
      label: APPOINTMENT_ACTIONS.CHECK_OUT.label,
      class: 'button button-check-out',
      isOutlined: false,
      actionName: actionLabel,
      encounterCheck: { allowed: true },
    });
  }

  actionLabel = displayCheckIn ? APPOINTMENT_ACTIONS.NO_SHOW.action : '';

  if (actionLabel) {
    items.push({
      id: ELEMENTS.buttonNoShow.id,
      label: APPOINTMENT_ACTIONS.NO_SHOW.label,
      class: 'button button-no-show',
      isOutlined: true,
      actionName: actionLabel,
      encounterCheck:
        encounterInfo && encounterInfo.exists
          ? APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.NO_SHOW
          : { allowed: true },
    });
  }

  return items;
};

const createMenuItem = appointmentAction => ({
  id: ELEMENTS.actionMenu.id,
  label: appointmentAction.label,
  class: ELEMENTS.actionMenu.id,
  isOutlined: false,
  actionName: appointmentAction.action,
  title: appointmentAction.title,
  message: appointmentAction.message,
  encounterCheck: { allowed: true },
});

const isEncounterInfoSignedOrHasPostedCharges = encounterInfo => {
  if (!encounterInfo) return false;

  return (
    encounterInfo.signed || encounterInfo.charges.some(x => x.postedToLedgerId)
  );
};

const getMenuItem = (appointmentAction, encounterInfo) => {
  const menuItem = createMenuItem(appointmentAction);

  if (encounterInfo && encounterInfo.exists) {
    if (appointmentAction.action === APPOINTMENT_ACTIONS.CANCEL.action) {
      return {
        ...menuItem,
        encounterCheck: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.CANCEL,
      };
    }

    if (appointmentAction.action === APPOINTMENT_ACTIONS.DELETE.action) {
      return {
        ...menuItem,
        encounterCheck: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.DELETE,
      };
    }
  }

  if (isEncounterInfoSignedOrHasPostedCharges(encounterInfo)) {
    if (appointmentAction.action === APPOINTMENT_ACTIONS.EDIT.action) {
      return {
        ...menuItem,
        encounterCheck: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.EDIT,
      };
    }

    if (
      encounterInfo.signed &&
      encounterInfo.charges.some(x => x.postedToLedgerId)
    ) {
      return {
        ...menuItem,
        encounterCheck: APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE,
      };
    }

    if (encounterInfo.signed) {
      return {
        ...menuItem,
        encounterCheck:
          APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_SIGNED_ENCOUNTER,
      };
    }
    return {
      ...menuItem,
      encounterCheck:
        APPOINTMENT_ACTIONS_ENCOUNTER_CHECK.RESCHEDULE_POSTED_CHARGES,
    };
  }

  return menuItem;
};

export const getAppointmentActionMenuItems = (
  appointmentStatus,
  recurrenceEventId = null,
  encounterInfo = null,
  touchedPastAppointment = false,
  confirmed = false,
  arrived = false,
) => {
  const items = [];

  if (appointmentStatus === APPOINTMENT_STATUS.ACTIVE) {
    items.push(getMenuItem(APPOINTMENT_ACTIONS.EDIT, encounterInfo));
    items.push(getMenuItem(APPOINTMENT_ACTIONS.RESCHEDULE, encounterInfo));

    if (confirmed) {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.UNCONFIRM));
    } else {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.CONFIRM));
    }

    if (arrived) {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED));
    } else {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.ARRIVED));
    }

    items.push(getMenuItem(APPOINTMENT_ACTIONS.CANCEL, encounterInfo));
    items.push(getMenuItem(APPOINTMENT_ACTIONS.DELETE, encounterInfo));

    if (
      recurrenceEventId &&
      !touchedPastAppointment &&
      appointmentStatus !== APPOINTMENT_STATUS.CHECKED_IN
    ) {
      items.push(createMenuItem(APPOINTMENT_ACTIONS.DELETE_SERIES));
    }
  } else if (appointmentStatus === APPOINTMENT_STATUS.NO_SHOW) {
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RESCHEDULE));
  } else if (appointmentStatus === APPOINTMENT_STATUS.CHECKED_IN) {
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED));

    items.push(createMenuItem(APPOINTMENT_ACTIONS.CHANGE_ROOM));
  } else if (appointmentStatus === APPOINTMENT_STATUS.CHECKED_OUT) {
    items.push(createMenuItem(APPOINTMENT_ACTIONS.RETURN_TO_SCHEDULED));
  }

  return items;
};

export const getAppointmentDisplayStatus = appointmentStatus => {
  const index = APPOINTMENT_DISPLAY_STATUSES.findIndex(
    x => x.status === appointmentStatus,
  );
  return index < 0 ? '' : APPOINTMENT_DISPLAY_STATUSES[index].name;
};
