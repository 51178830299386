import '../../../src/components/misc/neb-patient-review-matches';
import './neb-popup-patient-summary';

import { html, css } from 'lit';

import { matchBookingAccountToPatient } from '../../neb-api-client/src/patient-api-client';
import { openError } from '../../neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import { store } from '../../neb-redux/neb-redux-store';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';

export const ELEMENTS = {
  backButton: {
    id: 'button-back',
  },
  matchButton: {
    id: 'button-confirm',
  },
  reviewMatchPage: {
    id: 'review-match-page',
  },
  popupPatientSummary: {
    id: 'popup-patient-summary',
  },
};
export const ERROR_MESSAGE = 'Unable to link account';

class NebPopupMatchingReviewSelection extends NebPopup {
  initState() {
    super.initState();
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.back = () =>
      this.onClose({
        back: true,
      });

    this.handlers.match = async () => {
      if (this.model.match) {
        this.model.match.completedConsent = !!this.model.match.completedConsent;
        this.model.match.noticePrivacyPractice = !!this.model.match
          .noticePrivacyPractice;
      }

      try {
        const result = this.model.isOnlineAccountMatch
          ? await matchBookingAccountToPatient(
              this.model.account,
              this.model.match.cognitoId,
            )
          : await matchBookingAccountToPatient(
              this.model.match,
              this.model.account.cognitoId,
            );
        this.onClose(result);
      } catch (e) {
        store.dispatch(openError(ERROR_MESSAGE));
      }
    };
  }

  modelChanged() {
    this.title = this.model.title;
    this.headerAccount = this.model.headerAccount;
    this.headerReviewMatch = this.model.headerReviewMatch;
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 996px;
          height: 600px;
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
          overflow-x: hidden;
        }

        :host([layout='small']) .content {
          margin: 0;
        }

        :host([layout='small']) .popup-footer {
          display: block;
          margin-top: 35px;
          padding: ${CSS_SPACING} 17px;
          border-top: 1px ${CSS_COLOR_HIGHLIGHT} solid;
        }
        .header {
          margin: 22px 23px 0 17px;
        }

        .container {
          flex: 1 0 0;
          padding: unset;
        }

        .content {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
        }

        .popup-body {
          flex: 1 0 0;
        }

        .popup-footer {
          padding: 7px 17px;
          display: flex;
          justify-content: left;
          height: 70px;
        }

        .back {
          padding-left: 10px;
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <div class="popup-body">
        ${
          this.model.isOnlineAccountMatch
            ? html`
                <neb-popup-patient-summary
                  id="${ELEMENTS.popupPatientSummary.id}"
                  .layout="${this.layout}"
                  .patient="${this.model.account}"
                  .appointments="${this.model.appointments}"
                ></neb-popup-patient-summary>
              `
            : ''
        }
        <neb-patient-review-matches
          id="${ELEMENTS.reviewMatchPage.id}"
          .model="${this.model}"
          .layout="${this.layout}"
          .headerAccount="${this.headerAccount}"
          .headerReviewMatch="${this.headerReviewMatch}"
        ></neb-patient-review-matches>
      </div>

      <div class="popup-footer">
        <neb-button
          id="${ELEMENTS.matchButton.id}"
          label="match account"
          role="${BUTTON_ROLE.CONFIRM}"
          .onClick="${this.handlers.match}"
        ></neb-button>
        <neb-button
          id="${ELEMENTS.backButton.id}"
          class="button back"
          role="${BUTTON_ROLE.CANCEL}"
          label="back"
          .onClick="${this.handlers.back}"
        ></neb-button>
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-matching-review-selection',
  NebPopupMatchingReviewSelection,
);
