import '../../../../../../src/components/filters/neb-filters-claims-worklist';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { css, html, LitElement } from 'lit';
import moment from 'moment-timezone';

import {
  CORRECT_CLAIM_SELECTED_MULTIPLE_TITLE,
  CORRECT_CLAIM_SELECTED_MULTIPLE_MESSAGE,
  CORRECT_CLAIM_SELECTED_MEDICARE_TITLE,
  CORRECT_CLAIM_SELECTED_MEDICARE_MESSAGE,
  VOID_CLAIM_SELECTED_MEDICARE_MESSAGE,
  VOID_CLAIM_SELECTED_MEDICARE_TITLE,
  VOID_CLAIM_SELECTED_MULTIPLE_TITLE,
  VOID_CLAIM_SELECTED_MULTIPLE_MESSAGE,
  CONFIRM_REBILL_TITLE,
  CONFIRM_REBILL_MESSAGE,
} from '../../../../../../src/utils/user-message';
import { getClaimsData } from '../../../../../neb-api-client/src/claims';
import { getLedgerInvoiceItems } from '../../../../../neb-api-client/src/invoice-api-client';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { getProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../../neb-utils/constants';
import { parseDate } from '../../../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../../neb-utils/formatters';
import {
  fetchInsuranceItems,
  formatDate,
} from '../../../../../neb-utils/neb-ledger-util';
import { FINANCIAL_CLASSES } from '../../../../../neb-utils/patientInsurance';
import {
  EMPTY_RESPONSE,
  FetchService,
} from '../../../../../neb-utils/services/fetch';
import { MODE } from '../../../../../neb-utils/table';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import { SORT_DIR } from '../../neb-table-header';
import { BULK_ACTIONS_NO_CLAIMS_SELECTED_POPUP_TEXT } from '../../tables/neb-table-claims-worklist';
import { CARDS } from '../neb-claims-worklist-cards';
import {
  BULK_ACTION_OPTIONS,
  BULK_ACTION_OPTION_ID,
  CLAIMS_NO_ITEMS_INITIAL_LOAD,
} from '../utils';

import {
  createFilterQueries,
  getTotalFiltersApplied,
} from './claims-controller-utils';

export const ELEMENTS = {
  table: { id: 'table' },
  filters: { id: 'filters' },
  pagination: { id: 'pagination' },
};

const CLAIM_LIMIT = 100;

class NebClaimStatusController extends LitElement {
  static get properties() {
    return {
      __state: Object,
      __model: Object,
      __providers: Array,
      __showHidden: Boolean,
      __showExpandedFilters: Boolean,
      __hasCollapsedFilters: Boolean,
      __hasDisableInitialLoadFF: Boolean,
      __hasAppliedFilters: Boolean,
      __selectedRows: Object,
      __totalFiltersApplied: Number,
      __selectedRowsForCurrentPage: Array,

      patientId: String,
      status: String,
      statuses: Array,
      userLocations: Array,
      defaultLocationId: String,
      refreshing: { type: Boolean },
      sortParams: Object,
      expandFlags: Object,
      showExpandAll: Boolean,
      hasMaxClear: Boolean,
    };
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
    this.initServices();
  }

  initState() {
    this.__hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;
    this.__initialSortKey = 'claimNumber';
    this.__providers = [];
    this.__state = FetchService.createModel();
    this.__showHidden = false;
    this.__showExpandedFilters = false;
    this.__hasCollapsedFilters = true;
    this.__totalFiltersApplied = 0;
    this.__selectedRowsForCurrentPage = [];
    this.__selectedRows = {
      selectAll: false,
    };

    this.patientId = '';
    this.userLocations = [];
    this.defaultLocationId = '';
    this.sortParams = { key: this.__initialSortKey, dir: SORT_DIR.ASC };
    this.expandFlagsStatusPage = {};
    this.showExpandAll = false;
    this.expandFlags = {};
    this.showExpandAll = true;
    this.refreshing = false;
    this.hasMaxClear = false;

    this.onRequestStatus = () => {};

    this.onTotalChange = () => {};

    this.onChangeClaimStatus = () => {};

    this.onSelect = () => {};

    this.onToggleExpand = () => {};

    this.onExpandAll = () => {};

    this.onHidePopup = () => {};

    this.onShowPopup = () => {};

    this.onAdjustPrintOffset = () => {};

    this.onRebillClaims = () => {};

    this.onPrintClaims = () => {};

    this.onOpenClaimBatchesOverlay = () => {};

    this.onCorrectClaim = () => {};

    this.onVoidClaim = () => {};
  }

  get __pageItems() {
    return this.__state.pageItems || this.__model.pageItems || [];
  }

  get __expandFlagsForCurrentPage() {
    return this.__pageItems.map(claim => !!this.expandFlags[claim.id]);
  }

  get __checkedRowItems() {
    return this.__pageItems.filter(
      row => this.__selectedRows.selectAll || this.__selectedRows[row.id],
    );
  }

  get __selectedRowsIds() {
    return Object.keys(this.__selectedRows).filter(
      key => key !== 'selectAll' && this.__selectedRows[key],
    );
  }

  get __totalClaims() {
    return this.__state.filteredCount;
  }

  initHandlers() {
    this.__handlers = {
      refresh: () => {
        this.refresh();
      },
      applyFilters: (model, isButtonPress) => {
        if (isButtonPress) {
          this.__hasAppliedFilters = true;
        }

        const filterQueries = createFilterQueries(
          model,
          this.__getUserLocationIds(),
        );
        this.__totalFiltersApplied = getTotalFiltersApplied(model);

        Object.entries(filterQueries).forEach(([k, v]) => {
          this.__service.setQuery(k, v);
        });

        this.__service.setPageIndex(0);
        this.__deselectAll();
      },
      pageChanged: index => {
        this.__service.setPageIndex(index);
      },
      changeState: state => {
        this.__state = state;
        this.__state = { ...this.__state };

        this.__changeTotal(state);
      },
      changeSort: (_name, result) => {
        this.sortParams = result[0];
        this.__service.setQuery('sortKey', result[0].key);
        this.__service.setQuery('sortDir', result[0].dir);
      },
      checkBoxChanged: item => {
        this.__state.pageItems[item.name].checked = item.value;
        this.__state = { ...this.__state };
      },
      clickLink: name => {
        this.__openOverlay(name);
      },
      clickPatient: e => {
        const index = e.currentTarget.getAttribute('index');
        const { patientId } = this.__state.pageItems[index];

        const route = `/patients/${patientId}/${'claims/worklist'}/${
          this.status
        }`;

        return navigate(route);
      },
      openClaimBatchesOverlay: async () => {
        await this.onOpenClaimBatchesOverlay();

        this.refresh();
        this.__deselectAll();
      },
      deselectAll: () => this.__deselectAll(),
      changeClaimStatus: async claims => {
        await this.onChangeClaimStatus(claims);
        this.refresh();
        this.__deselectAll();
      },
      toggleExpand: (_, ...toggleData) => {
        this.onToggleExpand(toggleData);
      },
      expandAll: (collapse = true) => {
        this.onExpandAll(this.__pageItems.map(claim => claim.id), collapse);
      },
      selectCheckbox: (item, index) =>
        this.__selectCheckbox(item, index, !this.__selectedRows[item.id]),
      selectAll: () => this.__selectAll(),
      selectAllForCurrentPage: () => {
        const { selectAll } = this.__selectedRows;
        const isAllSelected = this.__pageItems
          .map(item => item.checked)
          .every(Boolean);

        if (selectAll || isAllSelected) {
          this.__deselectAll();
        } else {
          this.__pageItems.forEach((item, index) =>
            this.__selectCheckbox(item, index, !isAllSelected),
          );
        }
      },
      selectedAmountLabel: () => {
        if (this.__selectedRows.selectAll) {
          return `${this.__totalClaims} Items Selected.`;
        }

        const totalItemsSelected = this.__selectedRowsIds.length;

        if (totalItemsSelected) {
          return `${totalItemsSelected} ${
            totalItemsSelected > 1 ? 'Items' : 'Item'
          } Selected.`;
        }

        return '';
      },
      openConfirmHidePopup: async checkedRowItems => {
        const result = await this.onHidePopup(checkedRowItems);

        if (result) {
          this.refresh();
          this.__deselectAll();
        }
      },
      openConfirmShowPopup: async checkedRowItems => {
        const result = await this.onShowPopup(checkedRowItems);

        if (result) {
          this.refresh();
          this.__deselectAll();
        }
      },
      requestStatusUpdate: async () => {
        await this.onRequestStatus(this.__checkedRowItems);
        this.__deselectAll();
      },
      rebillClaims: async (forceRebill = false) => {
        const res = forceRebill
          ? true
          : await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: CONFIRM_REBILL_TITLE(),
              message: CONFIRM_REBILL_MESSAGE(this.__checkedRowItems.length),
              confirmText: 'Yes',
              cancelText: 'No',
            });

        if (res) {
          await this.onRebillClaims(this.__checkedRowItems);
          this.__deselectAll();
        }
      },
      printClaims: async () => {
        await this.onPrintClaims(this.__checkedRowItems);
        this.__deselectAll();
      },
      correctClaim: async () => {
        if (this.__selectedRowsIds.length > 1) {
          await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: CORRECT_CLAIM_SELECTED_MULTIPLE_TITLE(),
            message: CORRECT_CLAIM_SELECTED_MULTIPLE_MESSAGE(),
          });

          return;
        }

        if (this.__checkedRowItems.length) {
          if (
            this.__checkedRowItems[0].financialClass ===
            FINANCIAL_CLASSES.Medicare
          ) {
            const rebillClaim = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: CORRECT_CLAIM_SELECTED_MEDICARE_TITLE(),
              message: CORRECT_CLAIM_SELECTED_MEDICARE_MESSAGE(),
              confirmText: 'Rebill Claim',
              cancelText: 'Close',
            });

            if (rebillClaim) {
              this.__handlers.rebillClaims(true);
            }

            return;
          }

          let { originalReferenceNumber } = this.__checkedRowItems[0];

          if (!originalReferenceNumber) {
            const result = await openPopup(
              POPUP_RENDER_KEYS.ORIGINAL_REFERENCE_NUMBER,
            );

            if (!result) return;

            ({ originalReferenceNumber } = result);
          }

          await this.onCorrectClaim({
            ...this.__checkedRowItems[0],
            originalReferenceNumber,
          });

          this.__deselectAll();
        }
      },
      voidClaim: async () => {
        if (this.__selectedRowsIds.length > 1) {
          await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: VOID_CLAIM_SELECTED_MULTIPLE_TITLE(),
            message: VOID_CLAIM_SELECTED_MULTIPLE_MESSAGE(),
          });

          return;
        }

        if (this.__checkedRowItems.length) {
          if (
            this.__checkedRowItems[0].financialClass ===
            FINANCIAL_CLASSES.Medicare
          ) {
            await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: VOID_CLAIM_SELECTED_MEDICARE_TITLE(),
              message: VOID_CLAIM_SELECTED_MEDICARE_MESSAGE(),
            });

            return;
          }

          let { originalReferenceNumber } = this.__checkedRowItems[0];

          if (!originalReferenceNumber) {
            const result = await openPopup(
              POPUP_RENDER_KEYS.ORIGINAL_REFERENCE_NUMBER,
            );

            if (!result) return;

            ({ originalReferenceNumber } = result);
          }

          await this.onVoidClaim({
            ...this.__checkedRowItems[0],
            originalReferenceNumber,
          });

          this.__deselectAll();
        }
      },
    };
  }

  __deselectRemovedItems(previousPageItems) {
    const selectedRows = previousPageItems.reduce((acc, key) => {
      if (this.__pageItems.some(pageItem => pageItem.id === key)) {
        acc[key] = this.__selectedRows[key];
      }

      return acc;
    }, {});

    this.__selectedRows = {
      ...selectedRows,
      selectAll: this.__selectedRows.selectAll,
    };
  }

  __deselectAll() {
    const selectedRows = Object.keys(this.__selectedRows).reduce((acc, key) => {
      acc[key] = false;

      return acc;
    }, {});

    this.__selectedRows = { ...selectedRows, selectAll: false };
    this.__state = this.__setChecked(false);
  }

  __selectCheckbox(item, index, value) {
    this.__selectedRows = {
      ...this.__selectedRows,
      [item.id]: value,
      selectAll: false,
    };

    this.__setCheckboxByIndex({ index, value });
  }

  __selectAll() {
    const isAllSelected = !!this.__selectedRows.selectAll;

    const selectedRows = Object.keys(this.__selectedRows).reduce((acc, key) => {
      acc[key] = !isAllSelected;

      return acc;
    }, {});

    this.__selectedRows = { ...selectedRows, selectAll: !isAllSelected };
    this.__state = this.__setChecked(!isAllSelected);
  }

  __setCheckboxByIndex({ index, value }) {
    this.__state.pageItems[index].checked = value;
    this.__state = { ...this.__state };
  }

  __bulkActionClaimsSelectedCheck(handler) {
    return this.__checkedRowItems.length
      ? handler(this.__checkedRowItems)
      : openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Bulk Actions',
          message: BULK_ACTIONS_NO_CLAIMS_SELECTED_POPUP_TEXT,
        });
  }

  __checkSelectAllItems() {
    return (
      this.__selectedRows.selectAll ||
      this.__totalClaims === this.__selectedRowsIds.length ||
      this.__selectedRowsIds.filter(row => !!row).length < CLAIM_LIMIT ||
      this.__state.pageCount <= 1
    );
  }

  __getBulkActionMenu() {
    return [
      ...(this.__selectedRowsIds.length
        ? [
            {
              ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SELECTED_AMOUNT],
              label: this.__handlers.selectedAmountLabel(),
              showTextOnly: true,
            },
          ]
        : []),
      ...(this.__checkSelectAllItems()
        ? []
        : [
            {
              ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SELECT_ALL],
              onSelect: this.__handlers.selectAll,
              ...(this.__totalClaims && {
                label: `Select All ${this.__totalClaims} Items`,
              }),
            },
          ]),
      ...(this.status === CARDS.SUBMITTED.name ||
      this.status === CARDS.DENIED.name
        ? [
            {
              ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.REBILL_CLAIMS],
              onSelect: () => this.__handlers.rebillClaims(),
              disabled:
                this.__selectedRowsIds.length === 0 ||
                (this.__selectedRowsIds.length > 100 ||
                  this.__selectedRows.selectAll),
            },
          ]
        : []),
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.CORRECT_CLAIM],
        onSelect: () => this.__handlers.correctClaim(),
        disabled: this.__selectedRowsIds.length === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.VOID_CLAIM],
        onSelect: () => this.__handlers.voidClaim(),
        disabled: this.__selectedRowsIds.length === 0,
      },
      ...(this.status === CARDS.SUBMITTED.name && this.hasMaxClear
        ? [
            {
              ...BULK_ACTION_OPTIONS[
                BULK_ACTION_OPTION_ID.REQUEST_STATUS_UPDATE
              ],
              onSelect: () =>
                this.__handlers.requestStatusUpdate(this.__checkedRowItems),
              disabled: this.__selectedRowsIds.length === 0,
            },
          ]
        : []),
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.CHANGE_STATUS],
        onSelect: () =>
          this.__handlers.changeClaimStatus(this.__checkedRowItems),
        disabled: this.__selectedRowsIds.length === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.PRINT_CLAIMS],
        onSelect: () => this.__handlers.printClaims(this.__checkedRowItems),
        disabled: this.__selectedRowsIds.length === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.HIDE_WORKLIST_ITEMS],
        onSelect: () =>
          this.__handlers.openConfirmHidePopup(this.__checkedRowItems),
        disabled: this.__selectedRowsIds.length === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SHOW_WORKLIST_ITEMS],
        onSelect: () =>
          this.__handlers.openConfirmShowPopup(this.__checkedRowItems),
        disabled: this.__selectedRowsIds.length === 0,
      },
    ];
  }

  async __openOverlay(name) {
    let datesOfService = '';

    const [index, key] = name.split('.');
    const row = this.__state.pageItems[index];

    const currentPageItems = this.__model.pageItems.map(item => item.id);

    switch (key) {
      case 'primaryPayerAlias':
        await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: row.primaryPayerId,
        });

        break;

      case 'primaryPlanName': {
        const insurances = await fetchInsuranceItems(row.patient.id);

        const selectedInsurance = insurances.find(
          insurance => insurance.data.id === row.primaryInsuranceId,
        ).data;

        await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_VIEW, {
          patientId: row.patient.id,
          patientInsurances: insurances,
          patientInsurance: selectedInsurance,
        });

        break;
      }

      case 'claimNumber':
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, {
          claimId: row.id,
        });

        break;

      case 'batchNumber': {
        const batchId = row.batches.length ? row.batches[0] : '';

        await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
          batchId,
          patientId: this.patientId,
          onSelect: this.onSelect,
        });

        break;
      }

      case 'invoiceNumber':
        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
          patient: {
            id: row.patient.id,
            name: objToName(row.patient.name, DEFAULT_NAME_OPTS),
            mrn: row.patient.medicalRecordNumber,
          },
          lineItemIds: (await getLedgerInvoiceItems(row.invoiceId)).data.map(
            li => li.id,
          ),
          selectedIds: [],
        });

        break;

      case 'invoiceBillingNote':
        datesOfService = `${parseDate(row.dosFrom).format(
          'MM/DD/YYYY',
        )} - ${parseDate(row.dosTo).format('MM/DD/YYYY')}`;

        if (row.dosFrom === row.dosTo) {
          datesOfService = `${parseDate(row.dosFrom).format('MM/DD/YYYY')}`;
        }

        await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.INVOICE,
          parentId: row.invoiceId,
          parentData: {
            datesOfService,
            invoice: row.invoice.invoiceNumber,
          },
          patientId: row.patientId,
        });

        break;

      case 'claimBillingNote':
        datesOfService = `${parseDate(row.dosFrom).format(
          'MM/DD/YYYY',
        )} - ${parseDate(row.dosTo).format('MM/DD/YYYY')}`;

        if (row.dosFrom === row.dosTo) {
          datesOfService = `${parseDate(row.dosFrom).format('MM/DD/YYYY')}`;
        }

        await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.CLAIM,
          parentId: row.id,
          parentData: {
            datesOfService,
            claimNumber: row.claimNumber,
            amount: row.amount,
            payer: `${row.primaryPayerAlias}, ${row.primaryPlanName}`,
          },
          patientId: row.patientId,
        });

        break;

      default:
        return;
    }

    await this.refresh();
    this.__deselectRemovedItems(currentPageItems);
  }

  __getUserLocationIds() {
    return this.userLocations
      ? this.userLocations.map(({ data }) => data.id)
      : [];
  }

  __sortFirstEncounterLocation() {
    const allOrderedClaimCharges = this.__state.pageItems.map(pi => {
      const orderedClaimCharges = pi.claimCharges.sort(
        (a, b) => a.dateOfService - b.dateOfService,
      );

      return {
        ...pi,
        claimCharges: [...orderedClaimCharges],
      };
    });

    this.__model = {
      ...this.__model,
      pageItems: allOrderedClaimCharges,
    };
  }

  initServices() {
    const client = async query => {
      if (
        this.__hasDisableInitialLoadFF &&
        !this.__hasAppliedFilters &&
        !this.patientId
      ) {
        return EMPTY_RESPONSE;
      }

      const claims = await getClaimsData(this.status, {
        ...query,
        patientId: query.patientId || this.patientId,
        statuses: query.statuses || this.statuses,
        includeLedgerData: this.status === 'approved',
        locationIds: query.locationIds || this.__getUserLocationIds(),
      });

      if (!claims.data.length) {
        return claims;
      }

      const patientIds = claims.data.map(x => x.patientId);
      const patients = await patientApiClient.fetchSome(
        patientIds,
        {},
        false,
        false,
        true,
      );

      const claimIds = claims.data.map(claim => claim.id);
      this.__selectedRows = {
        ...claimIds.reduce((acc, cur) => ({ [cur]: false, ...acc }), {}),
        ...this.__selectedRows,
      };

      claims.data.forEach(claim => {
        claim.patient = patients.find(p => p.id === claim.patientId);
      });

      return claims;
    };

    if (this.__service) {
      this.__service.cancel();
    }

    this.__service = new FetchService(
      {
        onChange: this.__handlers.changeState,
        onMapItem: claim => ({
          ...claim,
          dateOfService: this.__formatDateOfService(claim),
          invoiceNumber: claim.invoice.invoiceNumber,
          lastUpdated: formatDate(claim.lastUpdated),
          primaryPayerId: claim.insurance.payerPlanId,
          primaryInsuranceId:
            claim.paymentResponsibilityLevelCode === 'Primary'
              ? claim.claimCharges[0].lineItem.primaryInsuranceId
              : claim.claimCharges[0].lineItem.secondaryInsuranceId,
          ...(this.status === 'approved' && {
            owedAmount: {
              patientOwed: claim.patientOwed,
              primaryOwed: claim.primaryOwed,
              secondaryOwed: claim.secondaryOwed,
            },
            payments: {
              patientPaid: claim.patientPaid,
              primaryPaid: claim.primaryPaid,
              secondaryPaid: claim.secondaryPaid,
            },
            checked: this.__selectedRows[claim.id] || false,
          }),
        }),
      },
      client,
      {
        hideInactive: false,
        sortParams: this.sortParams,
        pageSize: CLAIM_LIMIT,
      },
    );
  }

  __formatDateOfService(claim) {
    const from = moment(parseDate(claim.dosFrom));
    const to = moment(parseDate(claim.dosTo));

    return from.isSame(to, 'day')
      ? `${from.format('L')}`
      : `${from.format('L')} - ${to.format('L')}`;
  }

  __setChecked(checked) {
    this.__state.pageItems = this.__state.pageItems.map(item => ({
      ...item,
      checked,
    }));

    return { ...this.__state };
  }

  async refresh() {
    if (this.userLocations && this.userLocations.length) {
      await this.load();
    }
  }

  __changeTotal(state) {
    const amount =
      this.status === 'approved'
        ? state.body.totalBalanceAmount
        : state.body.totalChargeAmount;

    return this.onTotalChange({
      [`${this.status}Count`]: state.filteredCount,
      [`${this.status}Amount`]: amount || 0,
    });
  }

  async load() {
    await this.__service.fetch();
    this.__providers = await getProviderUsers();
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    super.connectedCallback();
  }

  firstUpdated() {
    this.refresh();
  }

  updated(changedProps) {
    if (changedProps.has('userLocations')) {
      if (this.userLocations && this.userLocations.length) {
        const locationIds = this.defaultLocationId
          ? [this.defaultLocationId]
          : this.__getUserLocationIds();
        this.__service.setQuery('locationIds', locationIds);

        if (this.status === 'approved') {
          this.__service.setQuery('statuses', ['Approved']);
          this.__totalFiltersApplied = 1;
        }
        this.refresh();
      }
    }

    if (
      changedProps.has('__state') ||
      changedProps.has('__pageItems') ||
      changedProps.has('__selectedRows')
    ) {
      this.__selectedRowsForCurrentPage = this.__pageItems.map(
        claim =>
          !!this.__selectedRows.selectAll || !!this.__selectedRows[claim.id],
      );
    }

    super.updated(changedProps);
  }

  update(changedProps) {
    if (changedProps.has('__state')) {
      this.__sortFirstEncounterLocation();

      if (this.expandFlags.expandAll) {
        this.__handlers.expandAll(false);
      }
    }

    if (this.refreshing) {
      this.refresh();
      this.refreshing = false;
    }

    if (changedProps.has('patientId') && this.userLocations?.length) {
      this.refresh();
    }

    super.update(changedProps);
  }

  __getEmptyMessage() {
    return this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
      ? CLAIMS_NO_ITEMS_INITIAL_LOAD
      : this.emptyMessage;
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) {
          height: auto;
        }

        .pagination {
          padding: ${CSS_SPACING};
          justify-content: flex-end;
        }
      `,
    ];
  }

  renderPagination() {
    return this.__state.pageCount > 1
      ? html`
          <neb-pagination
            id="${ELEMENTS.pagination.id}"
            class="pagination"
            .currentPage="${this.__state.pageIndex}"
            .onPageChanged="${this.__handlers.pageChanged}"
            .pageCount="${this.__state.pageCount}"
          ></neb-pagination>
        `
      : '';
  }

  __renderFilters() {
    const totalFiltersApplied = this.__totalFiltersApplied
      ? this.__totalFiltersApplied
      : 0;

    return html`
      <neb-filters-claims-worklist
        id="${ELEMENTS.filters.id}"
        class="filters"
        .userLocations="${this.userLocations}"
        .defaultLocationId="${this.defaultLocationId}"
        .providers="${this.__providers}"
        .onApply="${this.__handlers.applyFilters}"
        ?enablePatient="${!this.patientId}"
        .status="${this.status}"
        .showHidden="${this.__showHidden}"
        .expanded="${this.__showExpandedFilters}"
        .totalFiltersApplied="${totalFiltersApplied}"
        .hasCollapsedFilters="${this.__hasCollapsedFilters}"
      ></neb-filters-claims-worklist>
    `;
  }

  __renderTable() {
    return html`
      <neb-table-claims-worklist
        id="${ELEMENTS.table.id}"
        .config="${this.config}"
        .emptyMessage="${this.__getEmptyMessage()}"
        .model="${this.__pageItems}"
        .providers="${this.__providers}"
        .mode="${MODE.EXPAND}"
        .sortParams="${[this.sortParams]}"
        .status="${this.status}"
        .onChange="${this.__handlers.checkBoxChanged}"
        .onRefresh="${this.__handlers.refresh}"
        .onSort="${this.__handlers.changeSort}"
        .onClickLink="${this.__handlers.clickLink}"
        .onClickPatient="${this.__handlers.clickPatient}"
        .onToggleExpand="${this.__handlers.toggleExpand}"
        .onExpandAll="${this.__handlers.expandAll}"
        .showExpandAll="${this.showExpandAll}"
        .expandFlags="${this.__expandFlagsForCurrentPage}"
        .bulkActionItems="${this.__getBulkActionMenu()}"
        .selectedItems="${this.__selectedRowsForCurrentPage}"
        .onSelectCheckbox="${this.__handlers.selectCheckbox}"
        .onSelectAll="${this.__handlers.selectAllForCurrentPage}"
        .onRequestStatus="${this.onRequestStatus}"
      >
      </neb-table-claims-worklist>
    `;
  }

  render() {
    return html`
      ${this.__renderFilters()} ${this.__renderTable()}
      ${this.renderPagination()}
    `;
  }
}

customElements.define('neb-claim-status-controller', NebClaimStatusController);
