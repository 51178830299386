import { readDataUrlFromBlob } from '../../neb-utils/blobProcessor';

import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'api' });
export const billingClient = new ApiClient({ microservice: 'billing' });
export const headerJSON = { 'Content-Type': 'application/json' };

export const getSignatureFile = async (
  providerId,
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  try {
    const response = await billingClient.makeRequest({
      optOutLoadingIndicator,
      path: `providers/${providerId}/signature`,
      responseType: RESPONSE_TYPE.RAW,
      method: Method.GET,
      queryParams,
      version: 1,
    });
    return readDataUrlFromBlob(await response.blob());
  } catch (err) {
    return null;
  }
};

export const saveSignatureFile = async (providerId, base64File) => {
  try {
    const fileResponse = await fetch(base64File);
    const file = await fileResponse.blob();
    const response = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      path: `providers/${providerId}/signature`,
      responseType: RESPONSE_TYPE.RAW,
      method: Method.PUT,
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
    return readDataUrlFromBlob(await response.blob());
  } catch (err) {
    return null;
  }
};

export const deleteSignatureFile = async providerId => {
  try {
    await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      method: Method.DELETE,
      path: `providers/${providerId}/signature`,
      responseType: RESPONSE_TYPE.RAW,
      headers: headerJSON,
    });

    return true;
  } catch (err) {
    return false;
  }
};

export const getProviderSignatureS3Key = async providerId => {
  const {
    data: [s3Key],
  } = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `providers/${providerId}/signature-s3-key`,
    headers: headerJSON,
    cacheKey: `${providerId}-s3-key`,
  });

  return s3Key || null;
};
