import { getPaymentDetail } from '../../packages/neb-api-client/src/payments-api-client';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../packages/neb-lit-components/src/utils/overlay-constants';
import { isPayerPayment } from '../../packages/neb-utils/neb-payment-util';
import { getDataById } from '../api-clients/era-eob';

const isLinkedEraEob = eraEobInfo => eraEobInfo && !eraEobInfo.isLegacy;

const isLinkedPayerPayment = payment => {
  const isNewERAPayerPayment = payment.eRA && !payment.eRA.isLegacy;
  const isNewEOBPayerPayment =
    payment.eobPayments &&
    payment.eobPayments.length === 1 &&
    !payment.eobPayments[0].eOB.isLegacy;
  return (
    isPayerPayment(payment) && (isNewERAPayerPayment || isNewEOBPayerPayment)
  );
};

const getPaymentDetailOverlayKey = payment =>
  !payment.payerPlanId
    ? OVERLAY_KEYS.PAYMENT_DETAIL_2
    : OVERLAY_KEYS.PAYMENT_DETAIL;

const getEraEobData = async filters => {
  const eraEobInfo = await getDataById(filters);

  if (eraEobInfo && eraEobInfo.count === 1) {
    return eraEobInfo.data[0];
  }
  return null;
};

export const openPayment = async (
  { payment, readonly, eraEobInfo } = { payment: null, readonly: false },
) => {
  if (!payment) return null;

  if (isLinkedEraEob(eraEobInfo) || isLinkedPayerPayment(payment)) {
    let eraEobData = eraEobInfo;

    if (!eraEobData) {
      const { id, reportId, type } = payment.eRA
        ? { ...payment.eRA, type: 'ERA' }
        : { ...payment.eobPayments[0].eOB, type: 'EOB' };
      eraEobData = await getEraEobData({ id, reportId, type });
    }

    if (eraEobData) {
      return openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
        readonly,
        era: eraEobData,
      });
    }
    return null;
  }

  return openOverlay(getPaymentDetailOverlayKey(payment), {
    readonly,
    payment,
  });
};

export const openPaymentDetailOverlay = async payment => {
  const { codeDiscountId, id, patientId } = payment;

  if (codeDiscountId) {
    return openOverlay(OVERLAY_KEYS.DISCOUNT, {
      id,
      patientId,
    });
  }

  const paymentDetails = await getPaymentDetail(id);

  return openPayment({
    payment: { ...payment, ...paymentDetails },
    readonly: false,
  });
};
