import '../../../neb-material-design/src/components/neb-md-select';

import { LitElement, html, css } from 'lit';

import { CSS_SPACING } from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  dropdownHours: {
    id: 'duration-hour',
  },
  dropdownMinutes: {
    id: 'duration-minute',
  },
  textHeader: {
    id: 'text-duration',
  },
};
export const HOURS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
export const MINUTES = [
  '0',
  '5',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

class NebDurationFieldLit extends LitElement {
  static get properties() {
    return {
      duration: {
        type: Number,
      },
      required: {
        type: Boolean,
      },
      __mdSelectValid: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.duration = 0;
    this.required = false;
    this.__mdSelectValid = true;

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changeHours: e => {
        const hours = parseInt(e.value, 10);
        const newDuration = (this.getMinutes() + hours * 60) * 60000;
        this.onChange(newDuration);
        this.validate(newDuration);
      },
      changeMinutes: e => {
        const minutes = parseInt(e.value, 10);
        const newDuration = (minutes + this.getHours() * 60) * 60000;
        this.onChange(newDuration);
        this.validate(newDuration);
      },
    };
  }

  __getHoursList() {
    return this.getMinutes() === 0 && this.duration > 0
      ? HOURS.slice(1, HOURS.length + 1)
      : HOURS;
  }

  __getMinutesList() {
    return this.getHours() === 0 && this.duration > 0
      ? MINUTES.slice(1, MINUTES.length + 1)
      : MINUTES;
  }

  getHours() {
    return Math.floor(this.duration / 3600000);
  }

  getMinutes() {
    return (this.duration / 60000) % 60;
  }

  validate(duration = this.duration) {
    if (duration > 0) {
      this.__mdSelectValid = true;
    } else {
      this.__mdSelectValid = false;
    }

    return duration > 0;
  }

  static get styles() {
    return css`
      :host {
        display: flex;
      }

      .container {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .text-duration {
        margin-bottom: 11px;
      }

      .container-duration {
        display: flex;
        justify-content: space-between;
      }

      .dropdown {
        display: flex;
        width: 100%;
      }

      .dropdown-hours {
        margin-right: ${CSS_SPACING};
      }
    `;
  }

  render() {
    return html`
      <div class="container">
        <span id="${ELEMENTS.textHeader.id}" class="text-duration"
          >Duration</span
        >

        <div class="container-duration">
          <neb-md-select
            id="${ELEMENTS.dropdownHours.id}"
            class="dropdown dropdown-hours"
            labelText="Hours"
            helperText="${this.required ? 'Required' : ''}"
            .layout="${this.layout}"
            .items="${this.__getHoursList()}"
            .value="${`${this.getHours()}`}"
            .required="${this.required}"
            .onChange="${this.__handlers.changeHours}"
            ?valid="${this.__mdSelectValid}"
          ></neb-md-select>

          <neb-md-select
            id="${ELEMENTS.dropdownMinutes.id}"
            class="dropdown"
            labelText="Minutes"
            helperText="${this.required ? 'Required' : ''}"
            .layout="${this.layout}"
            .items="${this.__getMinutesList()}"
            .value="${`${this.getMinutes()}`}"
            .required="${this.required}"
            .onChange="${this.__handlers.changeMinutes}"
            ?valid="${this.__mdSelectValid}"
          ></neb-md-select>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-duration-field-lit', NebDurationFieldLit);
