import '../../../../../../../src/components/filters/neb-filters-ledger-transactions';
import '../../../neb-text';
import '../../../neb-pagination';
import '../../../tables/neb-table-ledger-activity-transactions';

import { navigate } from '@neb/router';
import { css, html, LitElement, unsafeCSS } from 'lit';

import {
  formatBilled,
  formatHold,
} from '../../../../../../../src/formatters/line-items';
import { getEncounter } from '../../../../../../neb-api-client/src/encounters-api-client';
import { getLedgerInvoiceItems } from '../../../../../../neb-api-client/src/invoice-api-client';
import * as patientApiClient from '../../../../../../neb-api-client/src/patient-api-client';
import { getPatientLedgerActivityTransactions } from '../../../../../../neb-api-client/src/patient-ledger-activity-api-client';
import { getPracticeInformation } from '../../../../../../neb-api-client/src/practice-information';
import { getPracticeLedgerActivityTransactions } from '../../../../../../neb-api-client/src/practice-ledger-activity-api-client';
import { getPracticeUsers } from '../../../../../../neb-api-client/src/practice-users-api-client';
import { store } from '../../../../../../neb-redux/neb-redux-store';
import { LocationsService } from '../../../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../../../neb-styles/neb-variables';
import { SYSTEM_USER } from '../../../../../../neb-utils/constants';
import {
  PAYMENT_METHODS,
  TRANSACTION_TYPE,
  WRITE_OFF_CODE,
  WRITE_OFF_TYPE,
} from '../../../../../../neb-utils/enums';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../../neb-utils/feature-util';
import { objToName } from '../../../../../../neb-utils/formatters';
import { NEBULA_REFRESH_EVENT } from '../../../../../../neb-utils/neb-refresh';
import { mapToPatientModel } from '../../../../../../neb-utils/patient';
import { CollectionService } from '../../../../../../neb-utils/services/collection';
import { MODE } from '../../../../../../neb-utils/table';
import { openEncounterSummary } from '../../../../utils/encounter-overlays-util';
import { openOverlay, OVERLAY_KEYS } from '../../../../utils/overlay-constants';
import { SORT_DIR } from '../../../tables/neb-table';

export const MENU_HEIGHT = 416;

export const ELEMENTS = {
  content: { id: 'content' },
  transactionsTable: { id: 'transactions' },
  header: { id: 'header' },
  filters: { id: 'filters' },
  paginationControl: { id: 'pagination-control' },
};

export const HEADER_TEXT_PATIENT =
  'Review transaction history for the patient.';

export const HEADER_TEXT_PRACTICE =
  'Review transaction history for the practice.';

function buildQueryParam(name, params) {
  const { from, to, min, max } = params;

  switch (name) {
    case 'dateOfService':
      return {
        dosFrom: from ? from.format('YYYY-MM-DD') : undefined,
        dosTo: to ? to.format('YYYY-MM-DD') : undefined,
      };

    case 'amount':
      return {
        amountMin: min !== null ? min : undefined,
        amountMax: max !== null ? max : undefined,
      };

    case 'notInvoiced':
      return { invoice: 'NOT_INVOICED' };

    default:
      return { [name]: params };
  }
}

class NebLedgerActivityTransactionsPage extends LitElement {
  static get properties() {
    return {
      __locations: Array,
      patientId: String,
      __expandFlags: Array,
      __transactionHistory: Array,
      layout: String,
      __collectionState: Object,
      __currentPageIndex: Number,
      __pageCount: Number,
      __items: Array,
      __users: Array,
      __practiceInfo: Object,
      __marginBottom: Number,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.layout = 'large';
    this.__expandFlags = [];
    this.__transactionHistory = [];
    this.__collectionState = CollectionService.createModel();
    this.__currentPageIndex = 0;
    this.__locations = [];
    this.__items = [];
    this.__users = [];
    this.__practiceInfo = { practiceName: '' };
    this.__sortParams = {
      key: 'transactionDate',
      dir: SORT_DIR.DESC,
    };

    this.__marginBottom = 0;
    this.__enablePatientFilter = false;
    this.__hasAppliedFilters = false;
  }

  __initHandlers() {
    this.handlers = {
      ...this.handlers,
      changeCollectionState: state => {
        this.__collectionState = state;

        this.changeOccurred();
      },
      pageChanged: async index => {
        this.__currentPageIndex = index;

        await this.fetch();

        this.changeOccurred();
      },
      refresh: async () => {
        this.__currentPageIndex = 0;

        await this.fetch();

        this.changeOccurred();
      },
      toggleExpand: (_name, _item, index) => {
        const expandFlags = this.__expandFlags;

        expandFlags[index] = !expandFlags[index];

        this.__expandFlags = [...expandFlags];
      },
      linkClick: (rowIndex, column) => {
        this.linkOpenOverlay(rowIndex, column);
      },
      applyFilters: model => {
        this.__hasAppliedFilters = true;

        Object.entries(model).forEach(([k, v]) =>
          this.__collectionService.updateFilter(k, v),
        );

        this.__currentPageIndex = 0;

        this.fetch();
      },
    };
  }

  async refetchItems() {
    this.__currentPageIndex = 0;

    await this.fetch();

    this.changeOccurred();
  }

  async linkOpenOverlay(rowIndex, column) {
    const { patient } = this.__items[rowIndex];

    const transactionHistory = this.__transactionHistory[rowIndex];

    const { lineItem, payment, adjustment } = transactionHistory;

    const rowItem = lineItem || payment || adjustment.lineItem;

    if (column === 'patient') {
      navigate(`/patients/${patient.id}/ledger/activity`);
    }

    if (
      column === 'invoice' ||
      (column === 'chargeId' && rowItem.type !== 'encounterCharge')
    ) {
      const lineItemIds = rowItem.invoiceId
        ? (await getLedgerInvoiceItems(rowItem.invoiceId)).data.map(li => li.id)
        : [rowItem.id];

      await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
        patient: {
          id: patient.id,
          ...(!this.patientId && {
            name: this.__getPatientPayerName(patient),
            mrn: patient.medicalRecordNumber,
          }),
        },
        lineItemIds,
        selectedIds: [],
      });

      return this.fetch();
    }

    if (column === 'chargeId') {
      const id = [rowItem.id];

      await openOverlay(OVERLAY_KEYS.LEDGER_LINE_ITEM, {
        patientId: patient.id,
        id,
      });

      return this.fetch();
    }

    if (column === 'payer') {
      await openOverlay(OVERLAY_KEYS.PAYER_PLAN, { id: rowItem.payerPlan.id });

      return this.fetch();
    }

    if (column === 'encounter') {
      const {
        encounterCharge: { encounterId },
      } = rowItem;

      const encounter = await getEncounter(encounterId);

      await openEncounterSummary({
        encounterId,
        appointmentTypeId: encounter.appointmentTypeId,
        patient,
      });

      return this.fetch();
    }

    return undefined;
  }

  changeOccurred() {
    this.__expandFlags = this.__items.map(() => false);
  }

  __initServices() {
    this.__collectionService = new CollectionService(
      {
        onChange: this.handlers.changeCollectionState,
      },
      {
        sortParams: this.__sortParams,
      },
    );

    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    super.connectedCallback();

    window.addEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);

    this.__locationsService.connect();

    const emptyFn = () => {};

    this.__collectionService
      .addFilter('patientId', emptyFn, '')
      .addFilter('dateOfService', emptyFn, { from: null, to: null })
      .addFilter('encounter', emptyFn, '')
      .addFilter('invoice', emptyFn, '')
      .addFilter('notInvoiced', emptyFn, false)
      .addFilter('amount', emptyFn, { min: null, max: null });
  }

  handleDisconnected() {
    this.__collectionService
      .removeFilter('patientId')
      .removeFilter('dateOfService')
      .removeFilter('encounter')
      .removeFilter('invoice')
      .removeFilter('notInvoiced')
      .removeFilter('amount');
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__locationsService.disconnect();
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);

    this.handleDisconnected();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .header {
          margin: ${CSS_SPACING};
        }

        .table {
          border-radius: 5px;
          padding: ${CSS_SPACING} 0;
          background-color: ${CSS_COLOR_WHITE};
        }

        .pagination {
          display: flex;
          justify-content: flex-end;
          padding: ${CSS_SPACING} ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  __isInitialLoadDisabled() {
    return (
      this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
    );
  }

  __calculateMarginBottom() {
    const { length } = this.__collectionState.allItems;

    if (!length) {
      this.__marginBottom = MENU_HEIGHT;
    } else if (length > 2) {
      this.__marginBottom = 0;
    }
  }

  __formatPayerPlanName(payer) {
    return payer.payerName;
  }

  __mapPayment(item, patientName) {
    const { type } = item;

    const {
      payment: {
        paymentMethod,
        authEFT,
        maskedCardDescription,
        postedById,
        codePayment: { code: paymentCode, description: paymentDescription },
        codeDiscount,
        payerPlan,
        referenceId,
        dateOfServiceFrom,
        dateOfServiceTo,
        voidPayment,
        refundPayment,
        createdAt,
        voidedAt,
        refundedAt,
        patientOnline,
      },
    } = item;

    let userId = postedById;
    let transactionDate = createdAt;
    let paymentType;
    let code;
    let transactionDescription;

    if (codeDiscount) {
      paymentType = 'Discount';
      code = codeDiscount.code;
      transactionDescription = codeDiscount.description;
    } else {
      paymentType = 'Payment';
      code = paymentCode;
      transactionDescription = paymentDescription;
    }

    if (type === TRANSACTION_TYPE.VOID_PAYMENT) paymentType = 'Void';
    if (type === TRANSACTION_TYPE.REFUND_PAYMENT) paymentType = 'Refund';

    let displayPaymentMethod = paymentMethod;

    if (
      paymentMethod === PAYMENT_METHODS.CHECK ||
      paymentMethod === PAYMENT_METHODS.DIRECT_DEPOSIT
    ) {
      displayPaymentMethod =
        authEFT && authEFT.length
          ? `${paymentMethod} - ${authEFT}`
          : paymentMethod;
    }

    if (paymentMethod === PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD) {
      displayPaymentMethod =
        maskedCardDescription && maskedCardDescription.length
          ? `${paymentMethod} - ${maskedCardDescription}`
          : paymentMethod;
    }

    if (type === TRANSACTION_TYPE.VOID_PAYMENT && voidPayment) {
      const {
        voidedById,
        codeRefund: { code: voidCode, description: voidDescription },
      } = voidPayment;

      userId = voidedById;
      code = voidCode;
      transactionDescription = voidDescription;
      transactionDate = voidedAt;
    } else if (type === TRANSACTION_TYPE.REFUND_PAYMENT && refundPayment) {
      const {
        refundedById,
        refundMethod,
        codeRefund: { code: refundCode, description: refundDescription },
      } = refundPayment;

      userId = refundedById;
      code = refundCode;
      transactionDescription = refundDescription;
      transactionDate = refundedAt;
      displayPaymentMethod = refundMethod;
    }

    let postedByName = '-';

    if (userId === SYSTEM_USER.id) postedByName = SYSTEM_USER.displayName;
    else {
      const user = this.__users.find(u => u.id === userId);

      if (!user && patientOnline) {
        postedByName = 'Patient Online';
      } else {
        postedByName = user ? objToName(user.name, { reverse: true }) : '-';
      }
    }

    return {
      name: `${code} ${paymentType}`,
      postedBy: postedByName,
      paymentMethod: displayPaymentMethod,
      primaryPayerId: payerPlan ? payerPlan.id : undefined,
      payer: payerPlan ? this.__formatPayerPlanName(payerPlan) : patientName,
      referenceId,
      dateOfServiceFrom,
      dateOfServiceTo,
      transactionDescription,
      transactionDate: new Date(transactionDate),
    };
  }

  __mapLineItem(item, patientName) {
    const { lineItem } = item;
    const {
      dateOfService,
      invoice,
      encounterCharge,
      chargeNumber,
      code,
      description,
      payerPlan,
      primaryPayerId,
      lineItemPatientPackage,
      voidedAt,
      voidedById,
    } = lineItem;

    let packageName = '';

    if (lineItemPatientPackage) {
      const {
        patientPackage: { name: patientPackageName },
      } = lineItemPatientPackage;

      packageName = patientPackageName;
    }

    let voidedByName = '';

    if (voidedById === SYSTEM_USER.id) voidedByName = SYSTEM_USER.displayName;
    else {
      const user = this.__users.find(u => u.id === voidedById);
      voidedByName = user ? objToName(user.name) : '';
    }

    return {
      name: encounterCharge ? 'Encounter Charge' : `${code} Charge`,
      serviceDate: new Date(dateOfService),
      encounterNumber: encounterCharge ? encounterCharge.encounterNumber : null,
      locationId: encounterCharge ? encounterCharge.locationId : null,
      invoiceNumber: invoice ? invoice.invoiceNumber : null,
      chargeNumber,
      transactionDescription: description,
      primaryPayerId,
      payer: payerPlan ? this.__formatPayerPlanName(payerPlan) : patientName,
      packageName,
      billed: formatBilled(lineItem),
      hold: formatHold(lineItem),
      voidedAt: voidedAt ? new Date(voidedAt) : null,
      voidedByName,
    };
  }

  __mapAdjustment(item, practiceName) {
    const { adjustment } = item;
    const {
      codeWriteOff: { codeGroup, code, description },
      lineItem: { dateOfService, invoice, chargeNumber, patientPackage },
    } = adjustment;

    let packageName = {};

    if (
      codeGroup === WRITE_OFF_TYPE.OTHER_ADJUSTMENT &&
      code === WRITE_OFF_CODE.PACKAGE &&
      patientPackage
    ) {
      const { name: patientPackageName } = patientPackage;

      packageName = { packageName: patientPackageName || '-' };
    }

    return {
      name: `${code} Adjustment`,
      serviceDate: new Date(dateOfService),
      invoiceNumber: invoice ? invoice.invoiceNumber : null,
      chargeNumber,
      transactionDescription: description,
      payer: practiceName,
      ...packageName,
    };
  }

  __getPatientPayerName(patient) {
    if (!patient) return '-';
    return objToName(patient.name, {
      reverse: true,
      middleInitial: true,
      preferred: true,
    });
  }

  __mapToModel(data) {
    return Promise.all(
      data.map(async item => {
        const { id, patientId, type, amount, createdAt } = item;

        let attrs = {};

        let patient;

        if (patientId) {
          const rawPatient = await patientApiClient.fetchOne(
            patientId,
            false,
            true,
          );
          patient = rawPatient ? mapToPatientModel(rawPatient) : null;
        } else {
          patient = this.__patient || null;
        }

        const patientPayerName = this.__getPatientPayerName(patient);

        const practiceName = this.__practiceInfo.name;

        if (
          type === TRANSACTION_TYPE.PAYMENT ||
          type === TRANSACTION_TYPE.VOID_PAYMENT ||
          type === TRANSACTION_TYPE.REFUND_PAYMENT
        ) {
          attrs = { ...this.__mapPayment(item, patientPayerName) };
        } else if (type === TRANSACTION_TYPE.LINE_ITEM) {
          attrs = { ...this.__mapLineItem(item, patientPayerName) };
        } else if (type === TRANSACTION_TYPE.ADJUSTMENT) {
          attrs = { ...this.__mapAdjustment(item, practiceName) };
        }

        return {
          id,
          patientId,
          patient,
          type,
          transactionDate: new Date(createdAt),
          amount,
          ...attrs,
        };
      }),
    );
  }

  async fetch() {
    if (this.__isInitialLoadDisabled()) {
      return;
    }

    let tenantId;

    if (store.getState().session.item) {
      tenantId = store.getState().session.item.tenantId;
    }

    const queryParams = this.__collectionState.filters.reduce(
      (accum, { name, params }) =>
        params ? { ...accum, ...buildQueryParam(name, params) } : accum,
      {},
    );

    this.__practiceInfo = await getPracticeInformation(tenantId);
    this.__users = await getPracticeUsers();

    let results;
    let count;

    if (this.patientId) {
      this.__patient = await patientApiClient.fetchOne(this.patientId);

      ({ results, count } = await getPatientLedgerActivityTransactions(
        this.patientId,
        {
          offset: this.__currentPageIndex * 10,
          limit: 10,
          ...queryParams,
        },
      ));
    } else {
      this.__patient = null;

      ({ results, count } = await getPracticeLedgerActivityTransactions({
        offset: this.__currentPageIndex * 10,
        limit: 10,
        ...queryParams,
      }));
    }

    this.__transactionHistory = results;
    this.__items = await this.__mapToModel(this.__transactionHistory);

    this.__pageCount = count > 1 ? Math.ceil(count / 10) : 1;
    this.__collectionService.setPageIndex(this.__currentPageIndex);
  }

  async firstUpdated() {
    await this.fetch();
  }

  update(changedProps) {
    if (changedProps.has('__items') || changedProps.has('__currentPageIndex')) {
      const { pageIndex } = this.__collectionState;

      this.__collectionService.setItems(this.__items);

      this.__collectionService.setPageIndex(pageIndex);
    }

    if (changedProps.has('patientId')) {
      this.__enablePatientFilter = !this.patientId;

      this.refetchItems();
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (changedProps.has('__collectionState')) {
      this.__calculateMarginBottom();
    }

    super.updated(changedProps);
  }

  renderHeader() {
    return html`
      <neb-text id="${ELEMENTS.header.id}" class="header">
        ${!this.patientId ? HEADER_TEXT_PRACTICE : HEADER_TEXT_PATIENT}
      </neb-text>

      <neb-filters-ledger-transactions
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.handlers.applyFilters}"
        ?enablePatient="${this.__enablePatientFilter}"
      ></neb-filters-ledger-transactions>
    `;
  }

  renderPagination() {
    return this.__items.length
      ? html`
          <neb-pagination
            id="${ELEMENTS.paginationControl.id}"
            class="pagination"
            .currentPage="${this.__currentPageIndex}"
            .onPageChanged="${this.handlers.pageChanged}"
            .pageCount="${this.__pageCount}"
          ></neb-pagination>
        `
      : '';
  }

  renderTable() {
    return html`
      <div
        id="${ELEMENTS.content.id}"
        style="margin-bottom: ${unsafeCSS(this.__marginBottom)}px;"
      >
        <div class="container">
          <div class="table">
            <neb-table-ledger-activity-transactions
              id="${ELEMENTS.transactionsTable.id}"
              .name="table-transactions"
              .model="${this.__collectionState.allItems}"
              .mode="${MODE.EXPAND}"
              .locations="${this.__locations}"
              .includePatient="${!this.patientId}"
              .expandFlags="${this.__expandFlags}"
              .layout="${this.layout}"
              .onSelectRow="${this.handlers.selectItem}"
              .onToggleExpand="${this.handlers.toggleExpand}"
              .onLinkClick="${this.handlers.linkClick}"
              .isInitialLoadDisabled="${this.__isInitialLoadDisabled()}"
            ></neb-table-ledger-activity-transactions>
            ${this.renderPagination()}
          </div>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      ${this.renderHeader()} ${this.renderTable()}
    `;
  }
}

customElements.define(
  'neb-ledger-activity-transactions-page',
  NebLedgerActivityTransactionsPage,
);
