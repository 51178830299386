import deepEqual from 'fast-deep-equal';

import ApiClient, { Method } from './utils/api-client-utils';

const BASE_PATH = 'payments';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const allocatePayment = async (paymentId, lineItems, version = 3) => {
  await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/${paymentId}/allocations`,
    body: JSON.stringify({ lineItems, hasAutoUnallocate: true }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    version,
  });
};

export const deleteAllocation = async (paymentId, lineItems) => {
  await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/${paymentId}/allocations`,
    body: JSON.stringify({ lineItems }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.DELETE,
    version: 1,
  });
};

export const getPreviewCharges = async (
  paymentId,
  queryParams,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    path: `${BASE_PATH}/${paymentId}/allocations/preview`,
    ...(queryParams && { queryParams }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 1,
    optOutLoadingIndicator,
  });

  return res.data;
};

export const getAllocatedCharges = async (
  paymentId,
  queryParams,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    path: `${BASE_PATH}/${paymentId}/allocations`,
    ...(queryParams && { queryParams }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    version: 1,
    optOutLoadingIndicator,
  });

  return res.data;
};

const formatAllocation = li => ({
  id: li.id,
  allowedAmount: li.allowedAmount,
  debits: li.lineItemDebits.map(
    ({
      debit: { id, allocated, amount, payerId },
      codePaymentId,
      patientInsuranceId,
    }) => ({
      ...(id && { id }),
      allocated: allocated || 0,
      amount: amount || 0,
      payerId,
      codePaymentId: codePaymentId || null,
      patientInsuranceId: patientInsuranceId || null,
    }),
  ),
  adjustments: li.adjustments
    .filter(a => a.codeId && a.amount)
    .map(({ id, codeId, amount }) => ({
      ...(id && { id }),
      codeId,
      amount,
    })),
});

const formatAllocationDetails = lineItems =>
  lineItems.map(li => formatAllocation(li));

export const getChangedLineItemsForAllocation = (
  previousLineItems,
  lineItems,
  isPreview,
  selectIndexes,
) => {
  const previousAllocations = formatAllocationDetails(previousLineItems);
  const allocations = formatAllocationDetails(lineItems);

  if (isPreview) return allocations.filter((_, index) => selectIndexes[index]);

  return allocations.reduce((memo, item) => {
    const previous = previousAllocations.find(prev => prev.id === item.id);

    if (previous && !deepEqual(previous, item)) {
      memo.push(item);
    }

    return memo;
  }, []);
};

const formatAllocationV2 = li => ({
  id: li.id,
  allowedAmount: li.allowedAmount,
  debits: li.debits.map(
    ({
      id,
      allocated,
      amount,
      payerId,
      codePaymentId,
      patientInsuranceId,
    }) => ({
      ...(id && { id }),
      allocated: allocated || 0,
      amount: amount || 0,
      payerId,
      codePaymentId: codePaymentId || null,
      patientInsuranceId: patientInsuranceId || null,
    }),
  ),
  adjustments: li.adjustments
    .filter(a => a.codeId && a.amount)
    .map(({ id, codeId, amount }) => ({
      ...(id && { id }),
      codeId,
      amount,
    })),
});

const formatAllocationDetailsV2 = lineItems =>
  lineItems.map(li => formatAllocationV2(li));

export const getChangedLineItemsForAllocationV2 = (
  previousLineItems,
  lineItems,
  isPreview,
  selectIndexes,
) => {
  const previousAllocations = formatAllocationDetailsV2(previousLineItems);
  const allocations = formatAllocationDetailsV2(lineItems);

  if (isPreview) return allocations.filter((_, index) => selectIndexes[index]);

  return allocations.reduce((memo, item) => {
    const previous = previousAllocations.find(prev => prev.id === item.id);

    if (previous && !deepEqual(previous, item)) {
      memo.push(item);
    }

    return memo;
  }, []);
};
