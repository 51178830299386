import { html, css } from 'lit';

import { CSS_SPACING_ROW } from '../../../../neb-styles/neb-variables';
import { toNumeric, formatPhoneNumber } from '../../../../neb-utils/formatters';
import { padArray } from '../../../../neb-utils/utils';
import { requireIf, isPhoneNumber } from '../../../../neb-utils/validators';

import { ELEMENTS as BASE_ELEMENTS, List } from './neb-list';
import PhoneItem from './neb-phone-item';

export const phoneListSelectorOld = {
  genItem: () => ({
    number: '',
    type: '',
  }),
  format: v =>
    padArray(v, {
      number: '',
      type: '',
    }),
  unformat: v => v.filter(item => item.number && item.type),
  validators: [requireIf('number', 'type')],
  children: {
    number: {
      format: v => formatPhoneNumber(v),
      unformat: v => toNumeric(v),
      validators: [isPhoneNumber],
    },
  },
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  items: {
    selector: 'neb-phone-item',
  },
};

export default class PhoneList extends List {
  static get properties() {
    return {
      helpers: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container {
          display: grid;
          gap: ${CSS_SPACING_ROW};
        }
      `,
    ];
  }

  static createModel() {
    return [PhoneItem.createModel()];
  }

  static createSelectors() {
    return {
      createItem: () => PhoneItem.createModel(),
      format: v => padArray(v, PhoneItem.createModel()),
      unformat: v => v.filter(item => item.number && item.type),
      children: {
        $: PhoneItem.createSelectors(),
      },
    };
  }

  initState() {
    super.initState();

    this.addLabel = 'Add Phone Number';
    this.helpers = {
      number: '',
      type: '',
    };

    this.maxItems = 3;
  }

  renderItem(item, index) {
    return html`
      <neb-phone-item
        .name="${index}"
        .model="${item}"
        .errors="${this.errors[index]}"
        .helpers="${this.helpers}"
        .onRemove="${this.handlers.remove}"
        .onChange="${this.handlers.change}"
        ?showRemoveButton="${this.model.length > 1}"
      ></neb-phone-item>
    `;
  }
}

window.customElements.define('neb-phone-list', PhoneList);
