import { html } from 'lit';

import { capitalize } from '../../packages/neb-utils/formatters';

export const plural = (count, noun, displayCount = true) =>
  `${displayCount ? count : ''} ${noun}${count === 1 ? '' : 's'}`;

export const getMessage = (message, ...parameters) =>
  message.replace(/\{(\d+)\}/g, (_, i) => parameters[i]);

export const errorOccurred = (action, item) =>
  getMessage(`An error occurred when ${action} ${item}`);

const unableTo = (item, action = 'save') =>
  getMessage(`Unable to ${action} ${item}`);

export const successfullyMessage = (item, action = 'saved') =>
  getMessage(`${item} ${action} successfully`);

export const buildSearchPlaceholder = items => {
  const itemsStr = items.join(', ').replace(/, ([^,]*)$/, ' or $1');
  return `Enter ${itemsStr} to filter list below`;
};

export const buildNoItems = item => `There are no ${item}s.`;

export const buildNoItemsClickToAdd = item => {
  const itemCapitalized = capitalize(item);
  return `${buildNoItems(
    item,
  )} Select "Add ${itemCapitalized}" above to add ${item}s.`;
};

export const getOverlayTitle = ({ id }, item) =>
  id ? `Update ${item}` : `Add ${item}`;

const getConfirmMessage = (action, item) =>
  `Are you sure that you want to ${action} this ${item}?`;

export const getSelectToAssociate = (selectItems, associateParent) =>
  `Select ${selectItems} to associate with the ${associateParent}.`;

export const CONFIRM_MESSAGE = () =>
  getMessage('Are you sure you want to continue?');

export const DOCUMENT_TAGS_BANNER_SUCCESS = successfullyMessage('Document Tag');

export const DOCUMENT_TAGS_BANNER_ERROR = unableTo('Document Tag');

export const ERA_BANNER_SUCCESS = successfullyMessage('ERA status', 'updated');

export const EOB_BANNER_SUCCESS = successfullyMessage('EOB status', 'updated');

export const ERA_BANNER_ERROR = errorOccurred('updating', 'the ERA status');

export const EOB_BANNER_ERROR = errorOccurred('updating', 'the EOB status');

export const UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_SUCCESS = successfullyMessage(
  'Date of Onset in encounter',
  'updated',
);

export const UPDATE_ENCOUNTER_DOO_WITH_CASE_BANNER_ERROR = errorOccurred(
  'updating',
  'date of onset in encounter',
);

export const UPDATE_ENCOUNTER_CASE_BANNER_SUCCESS = successfullyMessage(
  'Case',
  'updated',
);

export const UPDATE_ENCOUNTER_CASE_BANNER_ERROR = errorOccurred(
  'updating',
  'the case',
);

export const UPDATE_ENCOUNTER_DATE_OF_ONSET_SUCCESS = successfullyMessage(
  'Date of onset on encounter',
  'updated',
);

export const UPDATE_ENCOUNTER_DATE_OF_ONSET_ERROR = errorOccurred(
  'updating',
  'date of onset',
);
export const SUCCESS_COPY_HOT_BUTTONS = successfullyMessage(
  'Hot Button categories',
  'copied',
);

export const UNABLE_TO_COPY_HOT_BUTTONS = unableTo(
  'Hot Button categories',
  'copy',
);

export const SUCCESS_AUTO_ALLOCATE_PAYMENT = successfullyMessage(
  'Payment(s)',
  'auto allocated',
);

export const SUCCESS_AUTO_ALLOCATE_DISCOUNT = successfullyMessage(
  'Discount(s)',
  'auto allocated',
);

export const SUCCESS_AUTO_ALLOCATE_ON_POST_PAYMENT = successfullyMessage(
  'Payment',
  'posted and auto allocated',
);

export const ERROR_AUTO_ALLOCATE_PAYMENT = errorOccurred(
  'Payment(s)',
  'auto allocating',
);

export const ERROR_AUTO_ALLOCATE_DISCOUNT = errorOccurred(
  'Discount(s)',
  'auto allocating',
);

export const DID_NOT_AUTO_ALLOCATE_PAYMENT = 'No payment(s) auto allocated';

export const DID_NOT_AUTO_ALLOCATE_DISCOUNT = 'No discount(s) auto allocated';

export const DOCUMENT_TAGS_DUPLICATE_NAME_POPUP_TITLE = () =>
  getMessage('Duplicate Tag Name');

export const DOCUMENT_TAGS_DUPLICATE_NAME_POPUP_MESSAGE = () =>
  getMessage(
    'There is an existing document tag with this name. Please create new or update existing document tags accordingly.',
  );

export const DOCUMENT_TAGS_DELETE_POPUP_TITLE = () => getMessage('Delete Tag');

export const DOCUMENT_TAGS_DELETE_POPUP_MESSAGE = () =>
  getMessage(
    'Deleting this tag will remove it from all associated documents. Previously associated documents will no longer be grouped by this tag.',
  );

export const DOCUMENT_TAGS_DELETE_SUCCESS_MESSAGE = successfullyMessage(
  'Document Tag',
  'deleted',
);

export const DOCUMENT_TAGS_DELETE_ERROR_MESSAGE = unableTo(
  'Document Tag',
  'delete',
);

export const DOCUMENT_TAGS_REPLACE_POPUP_TITLE = () =>
  getMessage('Replace Tag');

export const DOCUMENT_TAGS_REPLACE_POPUP_MESSAGE = () =>
  getMessage(
    'The new tag will replace the existing tag on all associated documents.',
  );

export const DOCUMENT_TAGS_REPLACE_SUCCESS_MESSAGE = successfullyMessage(
  'Document Tag',
  'replaced',
);

export const DOCUMENT_TAGS_REPLACE_ERROR_MESSAGE = unableTo(
  'Document Tag',
  'replace',
);

export const HOT_BUTTONS_SUCCESS_MESSAGE = successfullyMessage('Hot Buttons');
export const HOT_BUTTONS_ERROR_MESSAGE = unableTo('Hot Buttons');

export const TRANSFER_BALANCE_SUCCESS_MESSAGE = successfullyMessage(
  'Balance',
  'transferred',
);

export const TRANSFER_BALANCE_ERROR_MESSAGE = unableTo('transfer', 'balance');

export const ERROR_SAME_PARTY = 'Cannot transfer to same party';
export const ERROR_ZERO_BALANCE = 'No available balance to transfer';
export const ERROR_MISSING_PAYER = (payer = '') =>
  getMessage(`No ${payer.toLowerCase()} payer listed`);

export const PATIENT_INSURANCE_VISIT_LIMIT_NEAR_OR_AT_LIMIT = (
  remainingVisits,
  maxVisits,
) =>
  getMessage(
    `After today's visit, the patient's primary policy will have ${remainingVisits} of ${maxVisits} visits remaining for the annual visit limit`,
  );

export const PATIENT_INSURANCE_VISIT_LIMIT_EXCEEDED = getMessage(
  "This patient's primary policy has exceeded the annual visit limit",
);

export const PATIENT_WARNING_WITH_SAME_MRN = () =>
  getMessage(
    "There is another patient in the system with the same Medical Record Number as this patient. Please update this patient's Medical Record Number.",
  );
export const PATIENT_CONFIRM_UPDATE_OR_CREATE = action =>
  getConfirmMessage(action, 'patient');

export const PATIENT_WARNING_DUPLICATE_LAST_AND_DOB = () =>
  getMessage(
    'There is another patient in the system with the same Last Name and Date of Birth as this patient. Please check the existing patient to ensure that you are not creating a duplicate patient record.',
  );
export const PATIENT_UNABLE_TO_SAVE = () =>
  getMessage('Unable to save patient');

export const PATIENT_PROFILE_SAVED_SUCCESS = () =>
  getMessage('Patient profile saved successfully');

export const PROVIDER_CARD_DISABLED_WARNING = () =>
  getMessage(
    'To add a new Provider user to your account, please contact ChiroTouch Support, who can assist you with setting up an Additional Provider subscription.',
  );

export const ABANDONED_CLAIM_COUNT_ERROR = errorOccurred(
  'loading',
  'Unlinked Claim Responses',
);

export const FAILED_PAYMENTS_COUNT_ERROR = errorOccurred(
  'loading',
  'Payment Alerts',
);

export const DISMISSING_CLAIM_RESPONSE_ERROR = errorOccurred(
  'dismissing',
  'response',
);

export const POST_PAYMENT_ERROR = errorOccurred('posting', 'payment');

export const VOID_PAYMENT_ERROR = errorOccurred('voiding', 'payment');

export const REFUND_PAYMENT_ERROR = errorOccurred('refunding', 'payment');

export const LINK_CLAIM_RESPONSE_ERROR = errorOccurred(
  'trying to link',
  'the claim and the response',
);

export const LOCATION_SAVED_ERROR = errorOccurred('saving', 'location');

export const ROOM_CHECKED_IN_ERROR = errorOccurred('validating', 'room');

export const CHARGES_UNABLE_TO_SAVE = unableTo('charges');

export const POST_PAYMENT_SUCCESS = successfullyMessage('Payment', 'posted');

export const VOID_PAYMENT_SUCCESS = successfullyMessage('Payment', 'voided');

export const SUPPLEMENTAL_INFO_SUCCESS = successfullyMessage(
  'Supplemental Information',
  'updated',
);

export const SUPPLEMENTAL_INFO_ERROR = errorOccurred(
  'updating',
  'Supplemental Information',
);

export const REFUND_PAYMENT_SUCCESS = successfullyMessage(
  'Payment',
  'refunded',
);

export const DISMISS_CLAIM_RESPONSE_SUCCESS = successfullyMessage(
  'Response',
  'dismissed',
);

export const LINK_CLAIM_RESPONSE_SUCCESS = successfullyMessage(
  'Claim and response',
  'have been linked',
);

export const SAVE_CHARGES_BANNER_SUCCESS = successfullyMessage(
  'Charge(s)',
  'saved',
);

export const LOCATION_SAVED_SUCCESS = successfullyMessage('Location');

export const USER_MANAGEMENT_ACTION_DISABLE_MESSAGE = () =>
  getMessage(
    "Seeking to inactivate a Provider user? This can be done by visiting ChiroTouch Community, then clicking on 'My Account' to manage the appropriate Additional Provider subscription.",
  );

export const USER_MANAGEMENT_ACTION_RE_ENABLE_MESSAGE = () =>
  getMessage(
    'To enable a previously deactivated Provider user, please contact ChiroTouch Support, who can assist you with reactivating the appropriate Additional Provider subscription.',
  );

export const USER_MANAGEMENT_ACTION_MESSAGE = action =>
  getMessage(
    'To {0} an invitation to the provider, please contact Support.',
    action,
  );

export const UPDATE_ERA_PAYMENT_SUCCESS = successfullyMessage(
  'Amount',
  'updated',
);

export const UPDATE_ERA_PAYMENT_ERROR = errorOccurred('updating', 'amount');

export const POPUP_UPDATE_PAYMENT_HELPER = () =>
  getMessage(
    'This ERA contains charges that could not be allocated and may have originated in a legacy system.  You can accept the displayed amount or enter another amount.',
  );

export const UNLINKED_CLAIM_STATUS_DESCRIPTION = `The following responses are missing the proper identifiers to
  automatically link to the appropriate claim. Select a response to view more details and manually
  link to the appropriate claim.`;

export const UNLINKED_ERA_DESCRIPTION = `The following ERAs are missing the proper data to automatically post and
  allocate payment. Select a response to view more details and manually update the missing information.`;

export const POPUP_MESSAGE_DISABLE_CT_VERIFY = () =>
  getMessage(
    'Please disable CT Verify from my subscription. This will immediately revoke the ability for Real-Time Eligibility to be used.',
  );

export const POPUP_MESSAGE_DISABLE_CT_MAXCLEAR = () =>
  getMessage(
    'Disabling CT MaxClear will remove all CHC Payer IDs. Do you wish to continue?',
  );

export const CONFIRM_GENERATE_CLAIM_BATCH_IF_PENDING = () =>
  getMessage(
    `At least one other batch is in a Pending state. If you submit this batch, the same claim might generate twice. ${CONFIRM_MESSAGE()}`,
  );

export const POPUP_MESSAGE_UNABLE_TO_INACTIVATE_ONLY_SERVICE_LOCATION = () =>
  getMessage(
    'This is currently the only active service location. You must activate another location first.',
  );

export const POPUP_MESSAGE_CONFIRM_INACTIVATE_LOCATION = () =>
  getMessage('Are you sure you want to make this location inactive?');

export const ENCOUNTER_BULK_SELECT_ONE_POPUP_TITLE = () =>
  getMessage('Bulk Actions');

export const ENCOUNTER_BULK_SELECT_ONE_POPUP_MESSAGE = () =>
  getMessage(
    'Please select one or more encounters before performing an action.',
  );

export const ECLAIM_STATUS_CHANGE_SUCCESS_MESSAGE = (
  claimCount,
  submissionMethod,
) =>
  getMessage(
    `Status changed successfully for ${claimCount} ${submissionMethod} claim(s).`.replaceAll(
      '  ',
      ' ',
    ),
  );

export const ECLAIM_STATUS_CHANGE_CANT_UPDATE_MESSAGE = () =>
  getMessage(
    'Status can not be changed for the selected claim(s) because their existing status does not allow it.',
  );

export const ECLAIM_STATUS_CHANGE_NO_CHARGES_SELECTED = () =>
  getMessage('No charges were selected.');

export const ECLAIM_STATUS_CHANGE_CHARGES_NOT_ASSOCIATED_WITH_CLAIM = () =>
  getMessage('One or more charges are not associated with a claim.');

export const REFRESH_SINGLE_CLAIM_DRAFT_SUCCESS =
  'Claim refreshed successfully';

export const REFRESH_CLAIMS_ERROR = errorOccurred('refreshing', 'claims');

export const REFRESH_CLAIM_DRAFTS_ERROR = errorOccurred(
  'refreshing',
  'claim drafts',
);

export const REFRESH_AND_SUBMIT_CLAIMS_ERROR = errorOccurred(
  'refreshing and submitting',
  'claims',
);

export const CLAIM_REFRESH_SUCCESS_MESSAGE = claimsCount =>
  getMessage(`${claimsCount} claim(s) refreshed successfully`);

export const CLAIM_DRAFT_REFRESH_SUCCESS_MESSAGE = count =>
  getMessage(
    `${count} claim ${plural(count, 'draft', false)} ${
      count > 0 ? 'successfully' : ''
    } refreshed`,
  );

export const CLAIM_NOT_REFRESHED_MESSAGE = claimsCount =>
  getMessage(`${claimsCount} claim(s) were not refreshed`);

export const ESPECIFIC_ERA_RESOLUTION_MESSAGE =
  'You can access ERA Management by resizing your browser to fit the resolution of a desktop/laptop.';

export const UNSUPPORTED_PAGE_DESKTOP_MESSAGE =
  'Desktop/Laptop: Re-size your browser to be larger';

export const UNSUPPORTED_PAGE_TABLET_MESSAGE =
  'Tablet: Rotate your device to the landscape layout';

export const EPAYMENT_FILE_TOO_LARGE_MESSAGE = (
  invalidFileLength,
  fileLength,
) =>
  getMessage(
    `(${invalidFileLength}/${fileLength}) Files are too large. The maximum size of a file that can be uploaded is 10 MB.`,
  );

export const EPAYMENT_FILE_VALID_SIZE_MESSAGE =
  'Do you want to upload the files with valid size?';

export const EPAYMENT_FILE_SIZE_MESSAGE = 'Files are too large';

export const EPAYMENT_MAX_FILE_SIZE_MESSAGE =
  'The maximum size of a file that can be uploaded is 10 MB';

export const ERA_MERGE_LINE_ITEM_SUCCESS = successfullyMessage(
  'Line items',
  'merged',
);

export const ERA_MERGE_LINE_ITEM_ERROR = errorOccurred('merging', 'line items');

export const ERA_RESET_LINE_ITEM_SUCCESS =
  'Line item status reset successfully';
export const ERA_RESET_LINE_ITEM_ERROR =
  'An error occurred when resetting line item status';

export const DOWNLOAD_HISTORY_DELETE_POPUP_TITLE = 'Delete Download History';
export const DOWNLOAD_HISTORY_DELETE_POPUP_MESSAGE =
  "Deleting will remove claim downloaded from the history. You can always re-download claim files from 'Claim 837 Files' tab.";
export const DOWNLOAD_HISTORY_DELETE_MESSAGE_NONE_SELECTED =
  'Please select files history that were downloaded before performing an action.';
export const DOWNLOAD_HISTORY_DELETE_MESSAGE_SUCCESS = successfullyMessage(
  'Claim download history',
  'deleted',
);
export const DOWNLOAD_HISTORY_DELETE_MESSAGE_ERROR = unableTo(
  'Claim download history',
  'delete',
);

export const ERA_EOB_STATUS_CHANGE_SUCCESS_MESSAGE = count =>
  getMessage(`Status successfully changed for ${count} ERA/EOB(s).`);

export const ERA_EOB_CLAIM_STATUS_UPDATE_SUCCESS = successfullyMessage(
  'Claim status',
  'updated',
);

export const ERA_EOB_CLAIM_STATUS_UPDATE_ERROR = errorOccurred(
  'updating',
  'the claim status',
);

export const NO_RESULTS_FOUND_MESSAGE = getMessage('No results found');

export const EOB_SAVE_SUCCESSFUL = successfullyMessage('EOB');

export const EOB_CREATE_SUCCESSFUL = successfullyMessage('EOB', 'created');

export const EOB_UPDATE_SUCCESSFUL = successfullyMessage('EOB', 'updated');

export const EOB_SAVE_ERROR = errorOccurred('saving', 'EOB');

export const DISMISS_PAYMENT_ERROR =
  'An error occurred when attempting to dismiss the payment';
export const REPROCESS_SUCCESS = 'Scheduled Payment processed successfully';
export const REPROCESS_ERROR =
  'An error occurred when processing the Scheduled Payment';

export const WORKLIST_ITEMS_SHOWN_SUCCESSFULLY = successfullyMessage(
  'Worklist items',
  'shown',
);

export const WORKLIST_ITEMS_HIDDEN_SUCCESSFULLY = successfullyMessage(
  'Worklist items',
  'hidden',
);

export const WORKLIST_ITEMS_SHOWN_ERROR = errorOccurred(
  'showing',
  'worklist items',
);

export const WORKLIST_ITEMS_HIDDEN_ERROR = errorOccurred(
  'hiding',
  'worklist items',
);

export const PRINT_UPCOMING_APPOINTMENTS_ERROR = unableTo(
  'upcoming appointments',
  'print',
);

export const ASSOCIATE_CHARGES_SUCCESSFULLY = type =>
  successfullyMessage('Charge(s)', `associated to the ${type}`);

export const ASSOCIATE_CHARGES_ERROR = type =>
  errorOccurred('associating', `Charge(s) to the ${type}`);

export const DELETE_CHARGES_SUCCESSFUL = ({ type = 'EOB' }) =>
  successfullyMessage('Charge(s)', `removed from ${type}`);

export const DELETE_CHARGES_ERROR = ({ type = 'EOB' }) =>
  errorOccurred('removing', `Charge(s) from ${type}`);

export const GETTING_CHARGES_FETCH_ERROR = errorOccurred(
  'fetching',
  'charges data',
);

export const SPLIT_PAYMENT_RECEIPT_NOTICE = getMessage(
  'Call Office for Receipt',
);

export const ASSOCIATE_PAYMENT_SUCCESSFULL = successfullyMessage(
  'Payments',
  'associated',
);

export const ASSOCIATE_PAYMENT_ERROR = reportType =>
  errorOccurred('associating', `payments to the ${reportType}`);

export const NO_OPEN_ENCOUNTERS =
  'There are no open encounters for this patient.';
export const NO_RESULTS = 'No results.';
export const NO_ENCOUNTER_CHARGES = 'There are no charges for this encounter.';
export const NO_CHARGES_SELECT_ADD_CHARGES = buildNoItemsClickToAdd('charge');
export const NO_SELECTED_ENCOUNTER_CHARGES =
  'There is no selected encounter. Select an encounter to add charges.';

export const NO_CASE_STATEMENTS_AVAILABLE =
  'There are no case statements available for this case.';

export const TEXT_BIG_FILE_EOB =
  'The file size must be less than 7MB and the file type must be a PDF.';
export const IMG_BAD_FILE_ERROR =
  'Bad or corrupt file. Try fixing file and upload again.';

export const SECONDARY_CLAIMS_CREATION_SUCCESSFUL = count =>
  `${count} Secondary Claims have been created successfully`;

export const SECONDARY_CLAIMS_CREATION_WARNING = count =>
  `${count} claims not created due to an existing claim in the worklist`;

export const SECONDARY_CLAIMS_CREATION_ERROR = errorOccurred(
  'creating',
  'Secondary Claims',
);

export const TEXT_UNLINK_PAYMENT_SUCCESS = reportType =>
  `Payment association successfully removed from the ${reportType}`;
export const TEXT_UNLINK_PAYMENT_ERROR = reportType =>
  errorOccurred('removing', `Payment Association from the ${reportType}`);

export const BULK_SELECT_ONE_POPUP_MESSAGE = item =>
  getMessage(`Please select one or more ${item} before performing an action.`);

export const ASSOCIATE_DOCUMENT_TO_ENCOUNTER_SUCCESSFULL = successfullyMessage(
  'Document(s)',
  'associated',
);

export const ASSOCIATE_DOCUMENT_TO_ENCOUNTER_ERROR = errorOccurred(
  'associating',
  'documents to the encounter',
);

export const REMOVE_DOCUMENT_FROM_ENCOUNTER_SUCCESSFULL = successfullyMessage(
  'Document(s)',
  'removed from encounter',
);

export const REMOVE_DOCUMENT_FROM_ENCOUNTER_ERROR = errorOccurred(
  'removing',
  'documents from encounter',
);

export const CONFIRM_REMOVE_DOCUMENTS_MESSAGE = getMessage(
  'Are you sure you want to remove the selected document(s) from the encounter?',
);

export const SAVE_ENCOUNTER_WITH_AUTOSALT_SUCCESS =
  'Encounter created and Auto SALT applied successfully';

export const SAVE_ENCOUNTER_SUCCESS = 'Encounter created successfully';

export const LEGAL_ACKNOWLEDGEMENT =
  'I acknowledge that the products and services noted above were provided.';

export const GENERATE_CASE_STATEMENTS_SUCCESSFULL = successfullyMessage(
  'Case statement(s)',
  'generated',
);

export const GENERATE_CASE_STATEMENTS_NO_CHARGES_FOUND =
  'An error occurred when generating case statements. There are no charges for the selected parameters.';

export const GENERATE_CASE_STATEMENTS_ERROR = errorOccurred(
  'generating',
  'case statement(s)',
);

export const PAYER_MAINTENANCE_TITLE = 'Payer Maintenance';
export const PAYER_MAINTENANCE_DESCRIPTION =
  'Set up the payers and payer groups that you conduct business with.';

export const PAYER_SEARCH_ITEMS = ['Alias', 'Payer ID', 'Payer Name'];
export const PAYER = 'payer';
export const PAYERS = 'Payers';

export const PAYER_GROUP_SEARCH_ITEMS = ['Payer Group Name', 'Payer'];
export const PAYER_GROUPS = 'Payer Groups';
export const PAYER_GROUP = 'Payer Group';

export const PAYER_GROUP_NAME_LABEL = 'Payer Group Name';
export const ACTIVE = 'Active';

export const ASSOCIATED_PAYER = 'associated payer';

export const DIAGNOSIS_WARNING =
  'The diagnosis code is not active for the date of service';

export const CHARGES_COVERAGE_WARNING =
  'The primary policy may be out of the covered date range for this date of service';

export const PAGE_CONFIG_DESCRIPTION = Object.freeze({
  HOT_BUTTONS:
    'Hot Buttons allow you to create a category of your favorite diagnoses and charges. Create a new Hot Button category per provider or inactivate to hide them from Charting.',
});

export const ASSOCIATE_PAYER = 'Associate Payer';
export const FEE_SCHEDULE = 'fee schedule';

export const PROVIDER = 'Provider';
export const ROOM = 'Room';

export const DAY = 'Day';
export const WEEK = 'week';
export const WORK_WEEK = 'Work Week';
export const FULL_WEEK = 'Full Week';
export const MONTH = 'Month';

export const HOT_BUTTON_ADD_DX_TITLE =
  'Search for and select diagnoses to associate with this category.';

export const HOT_BUTTON_ADD_CHARGES_TITLE = 'Add Charges';
export const HOT_BUTTON_ADD_CHARGES_DESCRIPTION =
  'Search for and select charges to associate with this category.';

export const SELECT_LOCATION_MESSAGE_FOR_RECEIPTS =
  'Please select a location to generate this receipt for.';
export const SELECT_LOCATION_MESSAGE_FOR_APPTS =
  'Please select a location to generate this patient appointment list for.';

export const VIEW = 'View';
export const CREATE = 'Create';
export const ENCOUNTER = 'Encounter';
export const PATIENT_RECORD = 'Patient Record';
export const CURRENT = 'Current';
export const PRIOR = 'Prior';
export const NO_PRIOR_ENCOUNTERS = 'No Prior Encounters';

export const PATIENT_OWED_VS_PAID_AMOUNT_ERROR_MSG =
  'The Patient Owed amount must be equal to or greater than the Paid amount';
export const ALLOCATED_AMOUNT_NOT_BALANCED_TO_PAYER_OWED_AMOUNT_ERROR_MSG =
  "The allocated amount may not exceed the payer's owed amount.";

export const DELETE_DRAFT_CLAIM_SUCCESS = 'Claim draft deleted successfully';
export const DELETE_DRAFT_CLAIM_UNABLE =
  'Unable to delete claim (Claim previously Transmitted)';

export const DELETE_DRAFT_CLAIMS_SUCCESS = count =>
  successfullyMessage(`${plural(count, 'claim')} draft`, 'deleted');

export const DELETE_DRAFT_CLAIMS_UNABLE = (selected, deleted) => {
  const TRANSMITTED_MESSAGE = '(Claims previously Transmitted)';

  if (deleted === 0) {
    return `Unable to delete ${plural(
      selected,
      'claim',
    )} ${TRANSMITTED_MESSAGE}`;
  }

  return `${plural(deleted, 'claim')} deleted successfully, ${selected -
    deleted} unable to delete ${TRANSMITTED_MESSAGE}`;
};

export const REPROCESSING_ERA_TITLE = 'Reprocess ERA';
export const REPROCESSING_ERA_INFO = html`
  <div>Reprocessing this ERA will affect customer data.</div>
  <br />
  <div>
    It can potentially create a new payment, and make new allocations and
    adjustments in the customer&apos;s practice.
  </div>
  <br />
  <div>${getConfirmMessage('reprocess', 'ERA')}</div>
`;

export const STATUS_UPDATED_TO_POSTED_SUCCESS_MESSAGE =
  'Status updated to posted successfully';

export const STATUS_UPDATED_TO_POSTED_ERROR_MESSAGE = errorOccurred(
  'updating',
  'status to posted',
);

export const ERA_REPROCESSED_SUCCESS_MESSAGE = 'ERA reprocessed successfully';
export const ERA_REPROCESSED_ERROR_MESSAGE = 'Unable to reprocess ERA';

export const REVIEW_OPEN_ERAS = 'Review open ERAs.';
export const REVIEW_CLOSED_ERAS = 'Review closed ERAs.';

export const OPEN = 'Open';
export const CLOSED = 'Closed';
export const YES = 'Yes';
export const NO = 'No';

export const successfulGenerateBatch =
  'Please check claim status or history log later for updated information.';

export const failedCorrectedClaimBatch =
  '1 claim failed to send and was not added to a batch. Please recreate the claim.';

export const generateRebillErrorMessage = numberOfErroredClaims =>
  `${numberOfErroredClaims} claim${
    numberOfErroredClaims === 1 ? '' : 's'
  } failed to rebill. Please try again.`;

export const generateInternalErrorMessage = numberOfErroredClaims =>
  `${numberOfErroredClaims} claim${
    numberOfErroredClaims === 1 ? '' : 's'
  } failed to generate. Please try again.`;

export const generateValidationErrorMessage = numberOfErroredClaims =>
  `${numberOfErroredClaims} claim${
    numberOfErroredClaims === 1 ? '' : 's'
  } failed validation and were not added to a batch please check Needs Attention tab for more information.`;

export const ERA_PAYER_NAME = 'ERA Payer Name';

export const SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING = invoiceNumber => [
  html`
    <div>
      Updating the case will update the ordering provider information of all
      charges associated to <b>Invoice ${invoiceNumber}.</b>
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_ENCOUNTER_LEVEL = invoiceNumbers => [
  html`
    <div>
      Updating the case will update the ordering provider information of all
      charges associated to the current invoice.<br /><br />
      This invoice is linked to an encounter that contains charges in multiple
      invoices: <b>${invoiceNumbers.join(', ')}</b>.<br />Updating the case will
      <b>not</b> update all associated invoices.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING_LEDGER_2 = invoiceNumber => [
  html`
    <div>
      Updating the billing information will update all charges associated to
      <b>Invoice #${invoiceNumber}</b>.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const WARNING_MULTIPLE_INVOICES_ASSOCIATED_TO_ENCOUNTER_WARNING_LEDGER_2 = invoiceNumbers => [
  html`
    <div>
      Updating the billing information will update all charges associated to
      <b>Invoices #${invoiceNumbers.join(', ')}</b>.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const MULTIPLE_ENCOUNTER_ASSOCIATE_WITH_CHARGES_ASSOCIATED_WARNING = [
  html`
    <div>
      You are about to update the case for the selected encounter(s). <br />
      Updating the case will update the ordering provider information for all
      associated charges.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const MULTIPLE_ENCOUNTER_REMOVE_CASE_ASSOCIATE__WITH_CHARGES_ASSOCIATED_WARNING = [
  html`
    <div>
      You are about to remove the associated case for all selected encounters.
      <br />
      Updating the case will update the ordering provider information for all
      associated charges.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const MULTIPLE_ENCOUNTER_REMOVE_CASE_ASSOCIATE_WARNING = [
  html`
    <div>
      You are about to remove the associated case for all selected encounters.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const WARNING_SINGLE_INVOICE_ASSOCIATED_AT_APPOINTMENT_LEVEL = invoiceNumber => [
  html`
    <div>
      Updating the case will update the ordering provider information of all
      charges associated to <b>Invoice ${invoiceNumber}.</b>
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_APPOINTMENT_LEVEL = invoiceNumbers => [
  html`
    <div>
      This appointment is linked to an encounter that contains charges in
      multiple invoices: <b>${invoiceNumbers.join(', ')}</b>.<br />Updating the
      case will update the ordering provider information of all associated
      records.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const WARNING_SINGLE_INVOICE_ASSOCIATED_AT_CHARGE_LEVEL = invoiceNumber => [
  html`
    <div>
      Updating the case will update the ordering provider information of all
      charges associated to <b>Invoice ${invoiceNumber}.</b>
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_CHARGE_LEVEL = invoiceNumbers => [
  html`
    <div>
      This appointment is linked to an encounter that contains charges in
      multiple invoices: <b>${invoiceNumbers.join(', ')}</b>.<br />Updating the
      case will update the ordering provider information of all associated
      records.
    </div>
    <br />
  `,
  'Do you wish to proceed?',
];

export const WARNING_INVOICES_ASSOCIATED_CHARGE_LEVEL = invoiceNumbers =>
  invoiceNumbers.length === 1
    ? WARNING_SINGLE_INVOICE_ASSOCIATED_AT_CHARGE_LEVEL(invoiceNumbers[0])
    : WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_CHARGE_LEVEL(invoiceNumbers);

export const CLAIM_STATUS_REQUEST_MESSAGE_TYPE = {
  paper: 'paper',
  status: 'status',
  notCHC: 'not-chc',
};

export const CLAIM_STATUS_REQUEST_MESSAGE = (count, type) => {
  switch (type) {
    case CLAIM_STATUS_REQUEST_MESSAGE_TYPE.status:
      return `Status request not sent for ${count} ${
        count === 1 ? 'claim because it has' : 'claims because they have'
      } a status of Error or Validation Error.`;

    case CLAIM_STATUS_REQUEST_MESSAGE_TYPE.paper:
      return `Status request not sent for ${count} ${
        count === 1
          ? 'claim because it is a paper claim'
          : 'claims because they are paper claims'
      }.`;

    default:
      return `Status request was not sent for ${plural(
        count,
        'claim',
      )} because status request is only valid for Change Healthcare.`;
  }
};

export const TOTAL_REVERSALS = 'Total Reversals';
export const ADJUSTMENT_CODE = 'Adjustment Code';
export const ADJUSTMENT_IDENTIFIER = 'Adjustment Identifier';
export const NO_REMIT_LEVEL_ADJUSTMENTS_PROVIDED =
  'There are no remit level adjustments for this ERA.';

export const MATCH_CLAIM = 'Match Claim';
export const MATCH_CHARGE = 'Match Charge';
export const MATCH_CHARGE_HEADER = 'Select a charge to match to the line item.';
export const CHARGE_HEADER =
  'Select individual or multiple charges; commands are conditionally available based on charge/claim status.';

export const CORRECT_CLAIM_SELECTED_MULTIPLE_TITLE = () =>
  getMessage('Select a Single Claim');
export const CORRECT_CLAIM_SELECTED_MULTIPLE_MESSAGE = () =>
  getMessage('Please select one claim at a time to Correct Claim.');
export const CORRECT_CLAIM_SELECTED_MEDICARE_TITLE = () =>
  getMessage('Medicare Claims');
export const CORRECT_CLAIM_SELECTED_MEDICARE_MESSAGE = () =>
  getMessage(
    'Cannot use Correct Claim action for Medicare claims, please Rebill claim.',
  );

export const VOID_CLAIM_SELECTED_MULTIPLE_TITLE = () =>
  getMessage('Select a Single Claim');
export const VOID_CLAIM_SELECTED_MULTIPLE_MESSAGE = () =>
  getMessage('Please select one claim at a time to Void Claim.');
export const VOID_CLAIM_SELECTED_MEDICARE_TITLE = () =>
  getMessage('Medicare claims');
export const VOID_CLAIM_SELECTED_MEDICARE_MESSAGE = () =>
  getMessage(
    'Cannot use Void Claim action for Medicare claims, please Rebill claim.',
  );
export const CORRECT_CLAIM_ERROR = errorOccurred('correcting', 'the claim');

export const VOID_CLAIM_ERROR = errorOccurred('voiding', 'the claim');
export const UNABLE_TO_MATCH_CHARGE = 'Unable to Match Charge';
export const SELECT_CHARGE_TO_MATCH =
  'Select a charge to match to the line item.';

export const ERA_MATCH_LINE_ITEM_SUCCESS = successfullyMessage(
  'Line items',
  'matched',
);

export const ERA_MATCH_LINE_ITEM_ERROR = errorOccurred(
  'matching',
  'line items',
);

export const CONFIRM_REBILL_TITLE = () => getMessage('Rebill Claims');

export const CONFIRM_REBILL_MESSAGE = count => {
  if (count === 1) {
    return 'Are you sure you want to rebill this claim? The claim will be canceled and a new claim will be submitted.';
  }
  return `Are you sure you want to rebill ${plural(
    count,
    'claim',
  )}? The selected claims will be canceled and new claims will be submitted.`;
};
export const NO_CLAIMS_SUBMISSION_METHOD_TITLE = () =>
  getMessage('Unable to Generate Claim For Payer');
export const NO_CLAIMS_PRIMARY_MESSAGE = html`
  <div>
    This Payer is not configured to support the generation of primary claims.
  </div>
  <br />
  <div>
    To enable the generation of primary claims, update settings for this Payer
    in Payer Plan Maintenance.
  </div>
`;

export const NO_CLAIMS_SECONDARY_MESSAGE = html`
  <div>
    This Payer is not configured to support the generation of secondary claims.
  </div>
  <br />
  <div>
    To enable the generation of secondary claims, update settings for this Payer
    in Payer Plan Maintenance.
  </div>
`;

export const NO_ITEMS_INITIAL_LOAD = 'Please select apply to show results.';

export const PATIENT_STATEMENT_SETTINGS_TITLE = 'Patient Statement Settings';
export const PATIENT_STATEMENT_SETTINGS_SWITCH =
  'Exclude from Patient Statements';
export const PATIENT_STATEMENT_SETTINGS_SUCCESS =
  'Patient statement settings saved successfully';
export const PATIENT_STATEMENT_SETTINGS_ERROR =
  'Error when saving the patient statement settings';

export const NO_CLAIMS_ERROR_PRIMARY =
  'The Payer is not configured to support the generation of primary claims.';
export const NO_CLAIMS_ERROR_SECONDARY =
  'The Payer is not configured to support the generation of primary claims.';

export const REMOVE_READY_TO_GENERATE_BATCH_MESSAGE = {
  message: html`
    <p>Are you sure you want to delete this statement batch?</p>
  `,
  title: 'Delete Statement Batch ',
  confirmText: 'Yes',
  cancelText: 'No',
};

export const MATCH_CLAIM_POPUP_MESSAGES = [
  'Please select a patient for this claim.',
  'The system will search for patient charges matching this claim.',
];

export const NO_CLAIM_CHARGE_MATCH = `Unable to find matches for any charges in the claim.
Once the patient is matched to the claim, you can search for the charges using the Match Charge action.
Are you sure you want to match the patient to the claim?`;

export const ALL_CLAIM_CHARGE_MATCH = `Matches have been found for all charges in the claim.
Are you sure you want to match these charges to this claim?`;

export const MULTIPLE_CLAIM_CHARGE_MATCH =
  'Multiple possible matches identified, please use the “Match Charge” action to manually select the correct charge.';

export const MATCHING_CHARGE_CANNOT_BE_FOUND_IN_SYSTEM =
  'Matching charge cannot be found in system';

export const SOME_CLAIM_CHARGE_MATCH = matchedCharges => html`
  <div>
    Matches have been found for some charges in the claim.
    <div>
      ${
        matchedCharges.map(
          charge =>
            html`
              <div>- ${charge}</div>
            `,
        )
      }
      <div>Are you sure you want to match these charges to this claim?</div>
    </div>
  </div>
`;

export const MANUAL_POST_LINE_ITEM = 'Post Line Item';

export const MANUAL_POST_LINE_ITEM_SUCCESS = successfullyMessage(
  'Charges and payments',
  'allocated',
);

export const MANUAL_POST_LINE_ITEM_ERROR = errorOccurred(
  'allocating',
  'payment(s)',
);

export const CLAIM_STATUS_UPDATE_SUCCESS = successfullyMessage(
  'Claim status',
  'updated',
);

export const CLAIM_STATUS_UPDATE_ERROR = errorOccurred(
  'updating',
  'the claim status',
);

export const CLAIM_STATUS_BILLED_UPDATE_SUCCESS =
  'Updated claim status has changed the billed status of associated charges';

export const GENERATE_BATCH_FAILED_MESSAGE = errorOccurred(
  'generating',
  'the batch',
);

export const MANUAL_POST_CLAIM_DENIED_CONFIRMATION =
  'Do you want to change status of this claim to Denied?';

export const MARK_CLAIM_AS_DENIED_CONFIRMATION = getMessage(
  'Are you sure you want to mark this claim as Denied?',
);

export const ERROR_EXPORTING_STATEMENT_BATCH_CSV_MESSAGE =
  'An error occurred when exporting the CSV. There are no statements for the current batch.';

export const ERROR_FETCHING_PREVIEW_CHARGES = errorOccurred(
  'fetching',
  'preview charges',
);

export const ERROR_FETCH_ERA_DATA = errorOccurred('fetching', 'ERA data');
