import { PERMISSION_KEYS } from '@neb/permissions';
import { openPopup, clearPopups, popPopup } from '@neb/popup';
import { navigate } from '@neb/router';

import { datadogRumStartView } from '../../../../src/library/datadog';
import { clearContexts } from '../../../../src/utils/context/constants';
import { hasPermission } from '../../../neb-api-client/src/permissions-api-client';
import { hasFeatureOrBeta } from '../../../neb-utils/feature-util';
import { URL_NO_ACCESS } from '../../../neb-utils/neb-request-security';

export const OVERLAY_STACK_KEY = 'overlays';

export const clearOverlays = () => clearPopups(OVERLAY_STACK_KEY);

export const popOverlay = () => popPopup(OVERLAY_STACK_KEY);

export const OVERLAY_KEYS = {
  ABANDONED_CLAIM_STATUS: { name: 'abandoned-claim-status', permission: '' },
  ABANDONED_ERA: { name: 'abandoned-era', permission: '' },
  ADD_AUTHORIZED_PROCEDURES: { name: 'authorized-procedures', permission: '' },
  ADD_CHARGE: { name: 'add-charge', permission: '' },
  ADD_CLEARINGHOUSE: { name: 'add-clearinhouse', permission: '' },
  ADD_CODE_BUNDLE: { name: 'add-code-bundle', permission: '' },
  ADD_CODE_BUNDLE_TO_ENCOUNTER: {
    name: 'add-code-bundle-to-encounter',
    permission: '',
  },
  ADD_CHECK_IN_QUESTIONS: { name: 'add-check-in-questions', permissions: '' },
  ADD_DIAGNOSIS: { name: 'add-diagnosis', permission: '' },
  ADD_HOT_BUTTONS: { name: 'add-hot-buttons', permission: '' },
  ADD_INSURANCE_RTE: { name: 'add-insurance-rte', permission: '' },
  ADD_PATIENT_PAYMENT: { name: 'add-patient-payment', permission: '' },
  ADD_PAYER_PAYMENT: { name: 'add-payer-payment', permission: '' },
  ADD_PAYER_PLANS: { name: 'add-payer-plans', permission: '' },
  ADD_PAYMENT: { name: 'add-payment', permission: '' },
  ADD_INSURANCE: { name: 'add-insurance', permission: '' },
  ADD_SCHEDULED_PAYMENT: { name: 'add-scheduled-payment', permission: '' },
  ADVANCED_MACRO_TYPE: { name: 'advanced-macro-type', permission: '' },
  ALLOCATE_PAYMENT: { name: 'allocate-payment', permission: '' },
  ALLOCATE_PAYMENT_2: { name: 'allocate-payment-2', permission: '' },
  APPOINTMENT_EDIT_FORM: { name: 'appointment-edit-form', permission: '' },
  APPOINTMENT_FORM: { name: 'appointment-form', permission: '' },
  APPOINTMENT_HISTORY: { name: 'appointment-history', permission: '' },
  APPOINTMENT_PAGE: { name: 'appointment-page', permission: '' },
  APPOINTMENT_REQUEST: { name: 'appointment-request', permission: '' },
  APPOINTMENT_TYPE: { name: 'appointment-type', permission: '' },
  ASSOCIATE_FEE_SCHEDULE: { name: 'associate-fee-schedule', permission: '' },
  ASSOCIATE_CHARGES: {
    name: 'associate-charges',
    permission: PERMISSION_KEYS.billing,
  },
  ASSOCIATE_ENCOUNTERS: {
    name: 'associate-encounters',
    permission: '',
  },
  ASSOCIATE_PAYER_GROUPS: {
    name: 'associate-payer-groups',
    permission: PERMISSION_KEYS.billing,
  },
  ASSOCIATE_RULES: {
    name: 'associate-rules',
    permission: PERMISSION_KEYS.billing,
  },
  ASSOCIATED_RULE_SETS: {
    name: 'associated-rule-sets',
    permission: '',
  },
  AUTHORIZATION: { name: 'authorization', permission: '' },
  BLOCKED_OFF_TIME_FORM: { name: 'blocked-off-time-form', permission: '' },
  BLOCKED_OFF_TIME_PAGE: { name: 'blocked-off-time-page', permission: '' },
  CARD_READERS: { name: 'card-readers', permission: '' },
  CASE: { name: 'case', permission: '' },
  CHARGE_RULE_SET: {
    name: 'charge-rule-set',
    permission: PERMISSION_KEYS.billing,
  },
  CHECK_IN_OUT: { name: 'check-in-out', permission: '' },
  CHECK_IN: { name: 'check-in', permission: '' },
  CHECK_OUT: { name: 'check-out', permission: '' },
  CLAIM_BATCHES: { name: 'claim-batches', permission: PERMISSION_KEYS.billing },
  CLAIM_HISTORY_LOG: { name: 'claim-history-log', permission: '' },
  CLEARINGHOUSE: { name: 'clearinghouse', permission: '' },
  CONVERSION: { name: 'conversion', permission: '' },
  CONVERSION_COPY: { name: 'copy-conversion', permission: '' },
  CONVERSION_JOB: { name: 'conversion-job', permission: '' },
  COPY_CATEGORIES: { name: 'copy-categories', permission: '' },
  DISCOUNT: { name: 'discount', permission: '' },
  DOCUMENT_TAGS: { name: 'document-tags', permission: '' },
  ENCOUNTER_HISTORY: { name: 'encounter-history', permission: '' },
  ENCOUNTER_LIST: { name: 'encounter-list', permission: '' },
  ENCOUNTER_SUMMARY: {
    name: 'encounter-summary',
    permission: PERMISSION_KEYS.charting,
  },
  ENCOUNTER_INFO: { name: 'encounter-info', permission: '' },
  FAILED_PAYMENTS: {
    name: 'failed-payments',
    permission: '',
  },
  FAILED_SCHEDULED_PAYMENT: {
    name: 'failed-scheduled-payment',
    permission: '',
  },
  FEE_SCHEDULES: { name: 'fee-schedules', permission: '' },
  FEE_SCHEDULES_FEATURE: { name: 'fee-schedules-feature', permission: '' },
  FORM_FEATURE_FLAG: { name: 'form-feature-flag', permission: '' },
  GUARANTOR: { name: 'patient-guarantor', permission: '' },
  HOT_BUTTONS: { name: 'hot-buttons', permission: '' },
  INVENTORY_ITEM: { name: 'inventory-item', permission: '' },
  LEDGER_ADD_CHARGE: { name: 'ledger-add-charge', permission: '' },
  LEDGER_ENCOUNTER_CHARGE: { name: 'ledger-encounter-charge', permission: '' },
  MANAGE_ENCOUNTER: {
    name: 'manage-encounter',
    permission: '',
  },
  LEDGER_FEE: { name: 'ledger-fee', permission: '' },
  LEDGER_PURCHASE: { name: 'ledger-purchase', permission: '' },
  LEDGER_VIEW_SELECTED_CHARGES: {
    name: 'ledger-view-selected-charges',
    permission: '',
  },
  LEDGER_VIEW_SELECTED_CHARGES_V2: {
    name: 'ledger-view-selected-charges-v2',
    permission: '',
  },
  LEDGER_GENERATE_CLAIM: { name: 'ledger-generate-claim', permission: '' },
  LEDGER_LINE_ITEM: { name: 'ledger-line-item', permission: '' },
  MACRO_BUTTON_TYPE: { name: 'macro-button-type', permission: '' },
  MACRO_CHOOSE: { name: 'macro-choose', permission: '' },
  MACRO_FOLDER_FINDER: { name: 'macro-folder-finder', permission: '' },
  MACRO_NO_DEFAULT: { name: 'macro-no-default', permission: '' },
  MACRO_REGION: { name: 'macro-region', permission: '' },
  MACRO_SETS: { name: 'macro-sets', permission: '' },
  MACRO_SPINE: { name: 'macro-spine', permission: '' },
  MERCHANT_ACCOUNT: { name: 'merchant-account', permission: '' },
  ORGANIZATION_FORM: { name: 'organization-form', permission: '' },
  PACKAGE_TEMPLATE: { name: 'package-template', permission: '' },
  PATIENT_ADD_CHARGE: { name: 'patient-add-charge', permission: '' },
  PATIENT_ALERT: { name: 'patient-alert', permission: '' },
  PATIENT_CREATE: { name: 'patient-create', permission: '' },
  PATIENT_INSURANCE_ADD: { name: 'patient-insurance-add', permission: '' },
  PATIENT_INSURANCE_VIEW: { name: 'patient-insurance-view', permission: '' },
  PATIENT_INSURANCE_EDIT: { name: 'patient-insurance-edit', permission: '' },
  PATIENT_INSURANCE_ADD_COVERED_PROCEDURE: {
    name: 'patient-insurance-add-covered-procedure',
    permission: '',
  },
  PATIENT_INSURANCE_SERVICES: {
    name: 'patient-insurance-services',
    permission: '',
  },
  PATIENT_LIST: { name: 'patient-list', permission: '' },
  PATIENT_PACKAGE_ADD: { name: 'patient-package-add', permission: '' },
  PATIENT_PACKAGE_EDIT: { name: 'patient-package-edit', permission: '' },
  PATIENT_PACKAGE_HISTORY: { name: 'patient-package-history', permission: '' },
  PATIENT_PACKAGES_SUMMARY: {
    name: 'patient-packages-summary',
    permission: '',
  },
  PATIENT: { name: 'patient', permission: '' },
  PAYER_RULE_SET: {
    name: 'payer-rule-set',
    permission: PERMISSION_KEYS.billing,
  },
  PAYER_PLAN: { name: 'payer-plan', permission: '' },
  PAYER_GROUPS: { name: 'payer-groups', permission: '' },
  ASSOCIATE_PAYER: { name: 'associate-payer', permission: '' },
  ASSOCIATE_RULE_SET_PAYER: {
    name: 'associate-rule-set-payer',
    permission: '',
  },
  PAYMENT_DETAIL: {
    name: 'payment-detail',
    permission: PERMISSION_KEYS.billing,
  },
  PAYMENT_DETAIL_2: {
    name: 'payment-detail-2',
    permission: PERMISSION_KEYS.billing,
  },
  PERSON: { name: 'person-form', permission: '' },
  POPUP: { name: 'popup', permission: '' },
  PRACTICE: { name: 'practice', permission: '' },
  PRACTICE_DETAILS: { name: 'practice-details', permission: '' },
  PRACTICE_LOCATION: { name: 'practice-location', permission: '' },
  RELATIONSHIP: { name: 'relationship', permission: '' },
  REPORT_LOGS: { name: 'report-logs', permission: '' },
  RESCHEDULED_APPOINTMENT_PAGE: {
    name: 'rescheduled-appointment-page',
    permission: '',
  },
  SCHEDULED_PAYMENT: { name: 'scheduled-payment', permission: '' },
  SELECT_CHARGES: { name: 'select-charges', permission: '' },
  TEST: { name: 'test', permission: '' },
  UNLINKED_CLAIM_RESPONSES: {
    name: 'unlinked-claim-responses',
    permission: '',
  },
  UNMATCHED_APPOINTMENTS: {
    name: 'unmatched-appointments',
    permissions: '',
  },
  UPDATE_PAYER_PAYMENT: {
    name: 'update-payer-payment',
    permission: PERMISSION_KEYS.billing,
  },
  USER_ADD: { name: 'user-add', permission: '' },
  VIEW_BATCHES: { name: 'view-batches', permission: '' },
  VIEW_CODE_BUNDLE_DETAIL: { name: 'view-code-bundle-detail', permission: '' },
  VENDOR: { name: 'vendor', permission: '' },
  ERA_EOB_MANAGEMENT: { name: 'era-eob-management', permission: '' },
  EOB_FORM: { name: 'eob-form', permission: '' },
  SPLIT_PAYMENT: { name: 'split-payment', permission: PERMISSION_KEYS.billing },
  ASSOCIATE_PAYMENT: {
    name: 'associate-payment',
    permission: PERMISSION_KEYS.billing,
  },
  ROOMS: { name: 'rooms', permission: '' },
  VISIT_SUMMARY: { name: 'visit-summary', permission: '' },
  BILLING_NOTE: { name: 'billing-note', permission: '' },
  ADD_REFERRAL_SOURCE_CATEGORY: {
    name: 'add-referral-source-category',
    permission: '',
  },
  PREVIEW_STATEMENT_BATCH: { name: 'preview-statement-batch', permission: '' },
  CLAIM_ERROR: { name: 'claim-error', permission: '' },
  CLAIM_ERROR_ITD_DOO: { name: 'claim-error-itd-doo', permission: '' },
  CLAIM_ERROR_MANAGE_ENCOUNTER: {
    name: 'claim-error-manage-encounter',
    permission: '',
  },
};

/* example for how to use FEATURE_FLAG_ROUTING:
  const FEATURE_FLAG_ROUTING = Object.freeze({
    [OVERLAY_KEYS.ALLOCATE_PAYMENT_2.name]: {
      newKey: OVERLAY_KEYS.ALLOCATE_PAYMENT,
      featureFlag: FEATURE_FLAGS.PAYMENTS_2_REVERT,
    };
  });
*/
const FEATURE_FLAG_ROUTING = Object.freeze({});

const verifyFeatureFlagRouting = async (key, featureFlagRouting) => {
  if (featureFlagRouting[key.name] !== undefined) {
    const hasFeatureFlagOn = await hasFeatureOrBeta(
      featureFlagRouting[key.name].featureFlag,
    );

    if (hasFeatureFlagOn) {
      return featureFlagRouting[key.name].newKey;
    }
  }

  return key;
};

export const openOverlay = async (
  overlayKey,
  model,
  options = { featureFlagRouting: FEATURE_FLAG_ROUTING },
) => {
  clearContexts();
  const key = await verifyFeatureFlagRouting(
    overlayKey,
    options.featureFlagRouting,
  );

  datadogRumStartView(key.name);

  if (!key.permission || hasPermission(key.permission)) {
    return openPopup(key.name, model, OVERLAY_STACK_KEY);
  }
  navigate(URL_NO_ACCESS);
  return Promise.resolve();
};
