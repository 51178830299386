import './neb-linked-records';
import './neb-authorization-details';
import '../../controls/neb-tab-group';
import '../../controls/neb-tab';
import '../../../../../../src/components/pages/patients/cases/neb-case-statements';
import '../../../../../../src/components/forms/patients/cases/neb-form-patient-case';
import { LitElement, html, css } from 'lit';

import { MdcProviderService } from '../../../../../neb-redux/services/mdc-provider';
import { baseStyles } from '../../../../../neb-styles/neb-styles';

export const ELEMENTS = {
  container: {
    id: 'container',
  },
  tabs: {
    id: 'tabs',
  },
  caseInfo: {
    id: 'case-info',
  },
  case: {
    id: 'case',
  },
  authorizationDetails: {
    id: 'authorizationDetails',
  },
  authorization: {
    id: 'authorization',
  },
  linkedRecords: {
    id: 'linked-records',
  },
  records: {
    id: 'records',
  },
  caseStatements: {
    id: 'case-statements',
  },
};
const NO_PROVIDER = {
  id: null,
  label: '',
};

class NebPatientCaseForm extends LitElement {
  static get properties() {
    return {
      __navItems: {
        type: Array,
      },
      __selectedTab: {
        type: String,
      },
      __providers: {
        type: Array,
      },
      layout: {
        reflect: true,
        type: String,
      },
      model: {
        type: Object,
      },
      organizations: { type: Array },
      insurances: { type: Array },
      guarantors: { type: Array },
      patientPackages: { type: Array },
      preferredProviderId: { type: String },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.layout = 'large';
    this.guarantors = [];
    this.organizations = [];
    this.insurances = [];
    this.patientPackages = [];
    this.model = {};
    this.preferredProviderId = '';

    this.__providers = [];
    this.__selectedTab = 'case';
    this.__navItems = this.__genNavItems();

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onChangeDirty = () => {};

    this.onAuthorizationChange = () => {};

    this.onGuarantorChange = () => {};

    this.onSearchOrganizations = () => {};

    this.onUpdateOrganization = () => {};

    this.onAddInsurance = () => {};

    this.onAddPackage = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      dirty: dirty => this.onChangeDirty(dirty),
      cancel: () => this.onCancel(),
      save: patientCase => this.onSave(patientCase),
      selectTab: tab => {
        if (tab !== this.__selectedTab) {
          this.__selectedTab = tab;
        }
      },
      addInsurance: () => this.onAddInsurance(),
      addPackage: () => this.onAddPackage(),
    };
  }

  __initServices() {
    this.__providerService = new MdcProviderService(({ allProviders }) => {
      const providers = allProviders.map(x => ({
        id: x.id,
        label: x.value,
      }));

      this.__providers = [NO_PROVIDER, ...providers];
    });
  }

  connectedCallback() {
    this.__providerService.connect();

    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__providerService.disconnect();
  }

  __genNavItems() {
    return [
      {
        id: 'case',
        label: 'Case Info',
        renderer: () => html`
          <neb-form-patient-case
            id="${ELEMENTS.caseInfo.id}"
            class="page case-info"
            .model="${this.model}"
            .insurances="${this.insurances}"
            .guarantors="${this.guarantors}"
            .hasAddOnCTVerify="${this.hasAddOnCTVerify}"
            .organizations="${this.organizations}"
            .patientPackages="${this.patientPackages}"
            .onSave="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
            .onChangeDirty="${this.__handlers.dirty}"
            .onGuarantorChange="${this.onGuarantorChange}"
            .onAddInsurance="${this.__handlers.addInsurance}"
            .onAddPackage="${this.__handlers.addPackage}"
            .onSearchOrganizations="${this.onSearchOrganizations}"
            .onUpdateOrganization="${this.onUpdateOrganization}"
          ></neb-form-patient-case>
        `,
      },
      ...(!this.model.id
        ? []
        : [
            {
              id: 'authorization',
              label: 'Authorizations',
              renderer: () =>
                html`
                  <neb-authorization-details
                    id="${ELEMENTS.authorizationDetails.id}"
                    class="page"
                    .patientId="${this.model.patientId}"
                    .caseId="${this.model.id}"
                    .layout="${this.layout}"
                    .onAuthorizationChange="${this.onAuthorizationChange}"
                    >Authorization</neb-authorization-details
                  >
                `,
            },
            {
              id: 'records',
              label: 'Linked Records',
              renderer: () => html`
                <neb-linked-records
                  id="${ELEMENTS.linkedRecords.id}"
                  class="page"
                  .layout="${this.layout}"
                  .caseId="${this.model.id}"
                  .patientId="${this.model.patientId}"
                  .providers="${this.__providers}"
                  >Linked Records</neb-linked-records
                >
              `,
            },
          ]),
      ...(!this.model.id
        ? []
        : [
            {
              id: 'case-statements',
              label: 'Case Statements',
              renderer: () => html`
                <neb-case-statements
                  id="${ELEMENTS.caseStatements.id}"
                  class="page"
                  .layout="${this.layout}"
                  .model="${this.model}"
                  .organizations="${this.organizations}"
                  .onSearchOrganizations="${this.onSearchOrganizations}"
                  .preferredProviderId="${this.preferredProviderId}"
                ></neb-case-statements>
              `,
            },
          ]),
    ];
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
          flex-flow: column nowrap;
        }

        .tabs {
          flex: 0 0 auto;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }

        .tab[selected='true'] {
          font-weight: 700;
        }

        .page {
          flex: 1 0 0;
          overflow: hidden;
          display: flex;
          padding-bottom: 5px;
        }
      `,
    ];
  }

  __renderTabs() {
    this.__navItems = this.__genNavItems();

    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectTab}"
      >
      </neb-tab-group>
    `;
  }

  __renderTabContent() {
    return this.__navItems
      .find(({ id }) => id === this.__selectedTab)
      .renderer();
  }

  render() {
    return html`
      <div id="${ELEMENTS.container.id}" class="container">
        ${this.__renderTabs()} ${this.__renderTabContent()}
      </div>
    `;
  }
}

window.customElements.define('neb-patient-case-form', NebPatientCaseForm);
