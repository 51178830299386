import { css, html } from 'lit';

import { buildModel } from '../../../../../neb-api-client/src/services/encounter-charge';
import checkUnallocationAuthorization from '../../../../../neb-api-client/src/services/encounter-charge/check-unallocation-authorization';
import saveCharges from '../../../../../neb-api-client/src/services/encounter-charge/save-charges';
import {
  CHARGES,
  DIAGNOSES,
  ENCOUNTER,
  EncounterDataService,
} from '../../../../../neb-api-client/src/services/encounter-data';
import { EncounterService } from '../../../../../neb-patient/src/services/encounter';
import { LocationsService } from '../../../../../neb-redux/services/locations';
import '../../forms/encounter/neb-form-manage-encounter/index';
import nebFormManageEncounterService from '../../forms/encounter/neb-form-manage-encounter/neb-form-manage-encounter-service';
import { EMPTY_SELECT_VALUE } from '../../forms/utils';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  form: { id: 'manage-encounter-form' },
};

class NebOverlayManageEncounter extends Overlay {
  static get properties() {
    return {
      __patientEncounters: Array,
      __encounterCharges: Array,
      __encounterDiagnoses: Array,

      __encounters: Array,
      __billingEncounterCharges: Array,

      __selectedEncounter: Object,
      __locations: Array,

      __formModel: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 100%;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.__patientEncounters = [];
    this.__encounterCharges = [];
    this.__encounterDiagnoses = [];

    this.__encounters = [];
    this.__billingEncounterCharges = [];

    this.__selectedEncounter = EMPTY_SELECT_VALUE;
    this.__locations = [];

    this.__formModel = buildModel();

    this.initServices();
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      encounterSelected: selectedEncounter => {
        this.__selectedEncounter = selectedEncounter;
        const { id } = selectedEncounter;

        if (!id) {
          this.__encounterCharges = [];
          this.__encounterDiagnoses = [];
          return;
        }

        this.__encounterDataService.update(id, [ENCOUNTER, CHARGES, DIAGNOSES]);
      },
      save: async ({ charges, diagnoses }) => {
        const { id: encounterId, patientId, signed } = this.__selectedEncounter;
        const { charges: pristineCharges } = this.__formModel;

        const formattedDiagnoses = diagnoses.map(({ item }) => item);
        const diagnosesDirty = !(
          JSON.stringify(formattedDiagnoses) ===
          JSON.stringify(this.__encounterDiagnoses)
        );

        const savedCharges = await saveCharges({
          pristineCharges,
          charges,
          patientId,
          billingEncounterCharges: this.__billingEncounterCharges,
          encounterId,
          signed,
          diagnosesUpdated: diagnosesDirty,
          diagnoses: formattedDiagnoses,
        });

        if (savedCharges) {
          this.isDirty = false;
          this.handlers.dismiss(true);
        }
      },
      cancel: () => this.handlers.dismiss(this.isDirty),
      back: () => this.handlers.dismiss(false),
      checkUnallocationAuth: charges => {
        const { charges: pristineCharges } = this.__formModel;
        return checkUnallocationAuthorization({ charges, pristineCharges });
      },
    };
  }

  initServices() {
    this.__encounterService = new EncounterService(patientEncounters => {
      this.__patientEncounters = patientEncounters;
    });

    this.__encounterDataService = new EncounterDataService(
      ({ charges, diagnoses }) => {
        this.__encounterCharges = charges;
        this.__encounterDiagnoses = diagnoses;
      },
    );

    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  connectedCallback() {
    super.connectedCallback();
    this.__encounterService.connect();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__encounterService.disconnect();
    this.__locationsService.disconnect();
  }

  __checkUpdateManageEncounterForm(changedProps) {
    const setupProps = ['__patientEncounters', '__encounterDiagnoses'];
    return setupProps.some(prop => changedProps.has(prop));
  }

  get __validEncounters() {
    const { encounterIds } = this.model;
    return this.__patientEncounters.filter(({ id }) =>
      encounterIds.includes(id),
    );
  }

  __checkReloadCharges() {
    return (
      this.__validEncounters.length === 1 &&
      this.__encounterCharges.length === 0
    );
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      const { patientId } = this.model;
      this.__encounterService.update(patientId);
    }

    if (this.__checkUpdateManageEncounterForm(changedProps)) {
      if (this.__checkReloadCharges()) {
        this.__encounterDataService.update(this.__validEncounters[0].id, [
          ENCOUNTER,
          CHARGES,
          DIAGNOSES,
        ]);
      }

      const {
        encounters,
        charges = [],
        diagnoses = [],
        encounterCharges,
        selectedEncounter,
      } = await nebFormManageEncounterService({
        ...this.model,
        patientEncounters: this.__validEncounters,
        patientCharges: this.__encounterCharges,
        patientDiagnoses: this.__encounterDiagnoses,
        selectedEncounter: this.__selectedEncounter,
        locations: this.__locations,
      });

      this.__encounters = encounters;
      this.__billingEncounterCharges = encounterCharges;
      this.__selectedEncounter = selectedEncounter;
      this.__formModel = { charges, diagnoses };
    }

    super.updated(changedProps);
  }

  renderContent() {
    return html`
      <neb-form-manage-encounter
        id="${ELEMENTS.form.id}"
        .layout="${this.layout}"
        .encounters="${this.__encounters}"
        .model="${this.__formModel}"
        .selectedEncounter="${this.__selectedEncounter}"
        .encounterSelected="${this.handlers.encounterSelected}"
        .encounterCharges="${this.__billingEncounterCharges}"
        .onCancel="${this.handlers.cancel}"
        .onBack="${this.handlers.back}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
        .onCheckingUnallocationAuth="${this.handlers.checkUnallocationAuth}"
      ></neb-form-manage-encounter>
    `;
  }
}

customElements.define(
  'neb-overlay-manage-encounter',
  NebOverlayManageEncounter,
);
