import '../inputs/neb-select';
import '../inputs/neb-textfield';
import '../inputs/neb-select-search';

import { atMin } from '@neb/form-validators';
import equal from 'fast-deep-equal';
import { html, css, LitElement, unsafeCSS } from 'lit';

import { getChartingPermissions } from '../../../../neb-api-client/src/permissions-api-client';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import {
  centsToCurrency,
  currencyToCents,
  splitIntoTerms,
} from '../../../../neb-utils/formatters';
import { number, currency } from '../../../../neb-utils/masks';
import {
  EPSDT_TYPE,
  CODE_TYPE,
  LINE_ITEM_TYPE,
  BILL_TYPE,
  validateAllowedAgainstBilled,
  validateAllowedAgainstTotalOwed,
  lineItemHasPackageCreditsOrAdjustments,
} from '../../../../neb-utils/neb-ledger-util';
import * as selectors from '../../../../neb-utils/selectors';

export const CONFIG = [
  {
    label: 'Units',
    width: 80,
  },
  {
    label: 'Unit Chrg',
    width: 108,
  },
  {
    label: 'Fee Sch Chrg',
    width: 108,
  },
  {
    label: 'Billed',
    width: 80,
  },
  {
    label: 'Tax',
    width: 80,
  },
  {
    label: 'Allowed',
    width: 108,
  },
];

export const ELEMENTS = {
  units: { id: 'units' },
  unitCharge: { id: 'unit-price' },
  feeScheduleCharge: { id: 'fee-schedule-charge' },
  billedAmount: { id: 'billed-amount' },
  allowedAmount: { id: 'allowed-amount' },
  taxAmount: { id: 'tax-amount' },
  epsdt: { id: 'epsdt' },
  feeScheduleSearch: { id: 'fee-schedule-search' },
  taxRate: { id: 'tax-rate' },
};

export class NebUnitsTaxCell extends LitElement {
  static get properties() {
    return {
      name: String,
      isCarePackageWithInsurance: Boolean,
      lineItemType: String,
      epsdtItems: Array,
      taxRateItems: Array,
      feeSchedules: Array,
      model: Object,
      errors: Object,
      initialState: Object,
      patientFeeSchedules: Array,

      __feeScheduleSearchValue: String,
    };
  }

  static get styles() {
    const COLUMN_WIDTHS = unsafeCSS(CONFIG.map(v => `${v.width}px`).join(' '));
    return [
      baseStyles,
      css`
        .container {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: ${COLUMN_WIDTHS};
          align-items: baseline;
        }

        .grid-2 {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: min-content;
          grid-column: span 5;
        }

        .grid-3 {
          display: grid;
          grid-gap: 0 ${CSS_SPACING};
          grid-template-columns: 1fr 1fr 1fr;
          grid-auto-rows: min-content;
          grid-column: span 6;
        }
      `,
    ];
  }

  static createModel() {
    return {
      units: 0,
      unitCharge: 0,
      feeScheduleCharge: 0,
      billedAmount: 0,
      allowedAmount: 0,
      taxAmount: 0,
      epsdt: {
        label: '',
        data: {
          code: false,
          type: '',
        },
      },
      feeScheduleId: selectors.ITEM_EMPTY,
      taxRate: {
        label: '',
        data: {
          name: '',
          rate: 0,
        },
      },
    };
  }

  static createSelectors(itemsMap) {
    return {
      units: selectors.numeric(0, {
        validateManually: true,
        validateRaw: true,
        validators: [atMin(1, true, 'Units is required')],
      }),
      unitCharge: selectors.currency({
        validateManually: true,
        validateRaw: true,
        validators: [
          atMin(0, true, 'Unit Charge must be greater than or equal to $0.00'),
        ],
      }),
      feeScheduleCharge: selectors.currency(),
      billedAmount: selectors.currency(),
      allowedAmount: selectors.currency({
        validateManually: true,
        validateRaw: true,
        validators: [
          validateAllowedAgainstBilled(
            'The total Owed amounts must equal the Allowed amount',
          ),

          validateAllowedAgainstTotalOwed(
            'The total Owed amounts must equal the Allowed amount',
            true,
          ),
        ],
      }),
      taxAmount: selectors.currency(),
      taxRate: {
        ...selectors.createDefaultModifiers({
          unsafe: true,
          clipPristine: true,
          validators: [],
        }),
        format: v =>
          itemsMap.taxRates.find(item => equal(item.data, v)) || {
            label: `${v.name} - ${v.rate}%`,
            data: { name: v.name, rate: v.rate },
          },
        unformat: v => v.data,
      },
      epsdt: {
        ...selectors.createDefaultModifiers({
          unsafe: true,
          clipPristine: true,
          validators: [],
        }),
        format: v =>
          v.code
            ? itemsMap.epsdt.find(item => item.data.type === v.type)
            : EPSDT_TYPE.EMPTY,
        unformat: v => v.data,
      },
      feeScheduleId: selectors.select(itemsMap.feeSchedules),
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__feeScheduleSearchValue = '';
    this.name = '';
    this.isCarePackageWithInsurance = false;
    this.epsdtItems = [];
    this.taxRateItems = [];
    this.feeSchedules = [];
    this.model = NebUnitsTaxCell.createModel();
    this.errors = NebUnitsTaxCell.createModel();
    this.initialState = NebUnitsTaxCell.createModel();
    this.patientFeeSchedules = [];

    this.onChange = () => {};

    this.onBlur = () => {};

    this.onChangeFeeSchedule = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      changeFeeSchedule: async e => {
        if (
          e.event !== 'blur' &&
          e.value !== this.model.feeScheduleId &&
          this.model.billType === BILL_TYPE.SELF
        ) {
          const [index] = this.name.split('.');

          e.value = e.value ? e.value : selectors.ITEM_EMPTY;

          const result = await this.__updateFeeScheduleChargeFieldsFF(e, index);

          if (result) {
            this.onChange({ ...e, name: `${index}.${e.name}` });

            const newEvent = {
              ...e,
              name: `${index}.feeScheduleCharge`,
              value: this.getFeeScheduleChargeAmount(e.value.data),
            };

            this.onChange(newEvent);
            this.__changeAdditionalKeys({
              ...newEvent,
              name: 'feeScheduleCharge',
            });

            const secondNewEvent = {
              ...e,
              name: `${index}.allowedAmount`,
              value: this.getFeeScheduleAllowedAmount(e.value.data),
            };

            this.onChange(secondNewEvent);
            this.__changeAdditionalKeys({
              ...secondNewEvent,
              name: 'allowedAmount',
            });
          }
        }
      },
      searchFeeSchedule: e => {
        this.__feeScheduleSearchValue = e.value;
      },
      change: e => {
        const [index] = this.name.split('.');

        this.onChange({ ...e, name: `${index}.${e.name}` });
        this.__changeAdditionalKeys(e);
      },
      blur: e => {
        const [index] = this.name.split('.');
        this.onBlur({ ...e, name: `${index}.${e.name}` });
      },

      changeTaxRate: e => {
        let taxRate;

        const [index] = this.name.split('.');

        if (e.event === 'select' || e.event === 'blur') {
          this.onChange({ ...e, name: `${index}.${e.name}` });
          taxRate = e.value.data.rate / 100;
          this.__handlers.change({
            ...e,
            name: 'taxAmount',
            value: centsToCurrency(
              Math.round(currencyToCents(this.model.billedAmount) * taxRate),
            ),
          });

          if (e.event === 'blur') {
            this.onBlur({ ...e, name: `${index}.${e.name}` });
          }
        }
      },
    };
  }

  __changeAdditionalKeys(e) {
    const [index] = this.name.split('.');

    let newEvent;

    switch (e.name) {
      case 'units': {
        newEvent = {
          ...e,
          name: `${index}.billedAmount`,
          value: centsToCurrency(
            e.value * currencyToCents(this.model.feeScheduleCharge),
          ),
        };

        this.onChange(newEvent);
        this.__changeAdditionalKeys({ ...newEvent, name: 'billedAmount' });

        break;
      }

      case 'feeScheduleCharge': {
        newEvent = {
          ...e,
          name: `${index}.billedAmount`,
          value: centsToCurrency(this.model.units * currencyToCents(e.value)),
        };

        this.onChange(newEvent);
        this.__changeAdditionalKeys({ ...newEvent, name: 'billedAmount' });

        break;
      }

      case 'taxRate': {
        const taxRate = e.value.data.rate / 100;
        newEvent = {
          ...e,
          name: `${index}.taxAmount`,
          value: centsToCurrency(
            Math.round(currencyToCents(this.model.billedAmount) * taxRate),
          ),
        };

        this.onChange(newEvent);

        break;
      }

      case 'billedAmount': {
        const taxRate = this.model.taxRate.data.rate / 100;
        newEvent = {
          ...e,
          name: `${index}.taxAmount`,
          value: centsToCurrency(
            Math.round(currencyToCents(e.value) * taxRate),
          ),
        };

        this.onChange(newEvent);

        break;
      }

      case 'unitCharge': {
        newEvent = {
          ...e,
          name: `${index}.feeScheduleCharge`,
          value: e.value,
        };

        this.onChange(newEvent);
        this.__changeAdditionalKeys({ ...newEvent, name: 'feeScheduleCharge' });

        break;
      }

      default:
        break;
    }
  }

  getFeeScheduleChargeAmount(feeSchedule) {
    return feeSchedule.id
      ? centsToCurrency(
          feeSchedule.charges.find(
            c =>
              this.model[this.model.type] &&
              c.id === this.model[this.model.type].chargeId,
          ).amount,
        )
      : this.initialState.feeScheduleCharge;
  }

  getFeeScheduleAllowedAmount(feeSchedule) {
    if (feeSchedule.id) {
      const { adjustmentAmount, amount } = feeSchedule.charges.find(
        c =>
          this.model[this.model.type] &&
          c.id === this.model[this.model.type].chargeId,
      );

      return centsToCurrency((amount - adjustmentAmount) * this.model.units);
    }

    return this.initialState.allowedAmount;
  }

  getFeeScheduleItems(model) {
    const { feeScheduleId } = model;

    if (!this.feeSchedules) return [selectors.ITEM_EMPTY];

    return this.feeSchedules.reduce(
      (items, fs) => {
        const { active, charges, id } = fs.data;
        const covered = charges
          ? charges.find(
              c => model[model.type] && c.id === model[model.type].chargeId,
            )
          : false;

        return (active && covered) || id === feeScheduleId.data.id
          ? [...items, fs]
          : items;
      },
      [selectors.ITEM_EMPTY],
    );
  }

  async __updateFeeScheduleChargeFieldsFF(e, index) {
    if (this.model.type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE) {
      if (e.value.data.id) {
        const result = await this.onChangeFeeSchedule(index, e.value);

        if (!result) return false;

        const { chargeId } = this.model.encounterCharge;

        const foundCharge = e.value.data.charges.find(c => c.id === chargeId);

        foundCharge.modifiers.forEach((modifier, idx) => {
          const modifierEvent = {
            ...e,
            name: `${index}.modifier_${idx + 1}`,
            value: modifier || null,
          };

          this.onChange(modifierEvent);
        });

        const suppressFromClaimEvent = {
          ...e,
          name: `${index}.encounterCharge.suppressFromClaim`,
          value: foundCharge.suppressFromClaim,
        };

        this.onChange(suppressFromClaimEvent);

        const feeScheduleNameEvent = {
          ...e,
          name: `${index}.encounterCharge.feeScheduleName`,
          value: e.value.label,
        };

        this.onChange(feeScheduleNameEvent);
      } else {
        const { chargeId } = this.model.encounterCharge;

        const settingsCharge = this.charges.find(c => c.id === chargeId);

        const suppressFromClaimEvent = {
          ...e,
          name: `${index}.encounterCharge.suppressFromClaim`,
          value: settingsCharge.suppressFromClaim,
        };

        this.onChange(suppressFromClaimEvent);

        const feeScheduleNameEvent = {
          ...e,
          name: `${index}.encounterCharge.feeScheduleName`,
          value: '',
        };

        this.onChange(feeScheduleNameEvent);
      }
    }

    return true;
  }

  __findMatchingFeeSchedules(defaultFeeSchedule, feeScheduleId) {
    const isChargeCovered = fs => {
      const { active, charges } = fs.data;

      const covered = charges
        ? charges.find(
            c =>
              this.model[this.model.type] &&
              c.id === this.model[this.model.type].chargeId,
          )
        : false;

      return active && covered;
    };

    const defaultPatientFeeSchedule = this.feeSchedules.find(
      fs => isChargeCovered(fs) && fs.data.id === defaultFeeSchedule?.id,
    );

    const patientFeeSchedules = this.feeSchedules.filter(
      fs =>
        isChargeCovered(fs) &&
        this.patientFeeSchedules.some(
          pfs => pfs.id === fs.data.id && defaultFeeSchedule?.id !== pfs.id,
        ),
    );

    const otherFeeSchedules = this.feeSchedules.filter(
      fs =>
        (isChargeCovered(fs) || fs.data.id === feeScheduleId.data.id) &&
        patientFeeSchedules.every(pfs => pfs.data.id !== fs.data.id) &&
        defaultPatientFeeSchedule?.data.id !== fs.data.id,
    );

    return {
      defaultPatientFeeSchedule,
      patientFeeSchedules,
      otherFeeSchedules,
    };
  }

  getFeeScheduleItemsFF() {
    const { feeScheduleId } = this.model;

    if (!this.feeSchedules.length) return [selectors.ITEM_EMPTY];

    const defaultFeeSchedule = this.patientFeeSchedules.length
      ? this.patientFeeSchedules[0]
      : null;

    const {
      defaultPatientFeeSchedule,
      patientFeeSchedules,
      otherFeeSchedules,
    } = this.__findMatchingFeeSchedules(defaultFeeSchedule, feeScheduleId);

    const feeSchedules = [
      selectors.ITEM_EMPTY,
      ...(defaultPatientFeeSchedule ? [defaultPatientFeeSchedule] : []),
      ...patientFeeSchedules,
      ...otherFeeSchedules,
    ];

    if (this.__feeScheduleSearchValue) {
      const terms = splitIntoTerms(this.__feeScheduleSearchValue);

      return feeSchedules.filter(feeSchedule =>
        terms.every(term => feeSchedule.label.toLowerCase().includes(term)),
      );
    }

    return feeSchedules;
  }

  __getNonEditable() {
    return !getChartingPermissions() && this.model.encounterCharge.signed;
  }

  __renderFeeScheduleDropdown() {
    const isDisabled =
      this.model.billType === BILL_TYPE.INSURANCE ||
      this.model.billType === BILL_TYPE.PACKAGE;

    return html`
      <neb-select-search
        id="${ELEMENTS.feeScheduleSearch.id}"
        label="Fee Schedule"
        helper=" "
        name="feeScheduleId"
        .value="${this.model.feeScheduleId}"
        .search="${this.__feeScheduleSearchValue}"
        .items="${this.getFeeScheduleItemsFF()}"
        .onChange="${this.__handlers.changeFeeSchedule}"
        .onSearch="${this.__handlers.searchFeeSchedule}"
        .disabled="${isDisabled}"
        showSearch
      ></neb-select-search>
    `;
  }

  render() {
    const { model, errors } = this;
    const disabled = this.__getNonEditable();

    return html`
      <div class="container">
        <neb-textfield
          id="${ELEMENTS.units.id}"
          label=" "
          helper=" "
          name="units"
          maxLength="3"
          .value="${model.units}"
          .error="${!!errors.units}"
          .mask="${number}"
          .inputMode="${'numeric'}"
          .onChange="${this.__handlers.change}"
          .onBlur="${this.__handlers.blur}"
          ?disabled="${disabled}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.unitCharge.id}"
          label=" "
          helper=" "
          name="unitCharge"
          .value="${model.unitCharge}"
          .error="${!!errors.unitCharge}"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .onChange="${this.__handlers.change}"
          .onBlur="${this.__handlers.blur}"
          ?disabled="${model.codeType === CODE_TYPE.CHARGE}"
        ></neb-textfield>

        <neb-textfield
          id="${ELEMENTS.feeScheduleCharge.id}"
          label=" "
          helper=" "
          name="feeScheduleCharge"
          .value="${model.feeScheduleCharge}"
          .error="${!!errors.feeScheduleCharge}"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .onChange="${this.__handlers.change}"
          .onBlur="${this.__handlers.blur}"
          ?disabled="${
            model.type === LINE_ITEM_TYPE.FEE ||
              (lineItemHasPackageCreditsOrAdjustments(this.model) &&
                !this.isCarePackageWithInsurance)
          }"
        ></neb-textfield>

        <span id="${ELEMENTS.billedAmount.id}">${model.billedAmount}</span>

        <span id="${ELEMENTS.taxAmount.id}">${model.taxAmount}</span>

        <neb-textfield
          id="${ELEMENTS.allowedAmount.id}"
          label=" "
          helper=" "
          name="allowedAmount"
          .value="${model.allowedAmount}"
          .error="${!!errors.allowedAmount}"
          .mask="${currency}"
          .inputMode="${'numeric'}"
          .onChange="${this.__handlers.change}"
          .onBlur="${this.__handlers.blur}"
        ></neb-textfield>

        <div class="grid-3">
          <neb-select
            id="${ELEMENTS.epsdt.id}"
            label="EPSDT"
            helper=" "
            name="epsdt"
            .value="${model.epsdt}"
            .error="${errors.epsdt}"
            .items="${this.epsdtItems}"
            .onChange="${this.__handlers.change}"
            .disabled="${!model.EPSDTCode}"
          ></neb-select>

          ${this.__renderFeeScheduleDropdown()}

          <neb-select
            id="${ELEMENTS.taxRate.id}"
            label="Tax Rate"
            helper=" "
            name="taxRate"
            .value="${model.taxRate}"
            .error="${errors.taxRate}"
            .items="${this.taxRateItems}"
            .onChange="${this.__handlers.changeTaxRate}"
          ></neb-select>
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-units-tax-cell', NebUnitsTaxCell);
