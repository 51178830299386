import { matchRouteSwitch } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { ADD_ONS, hasAddOn } from '../../../../../src/utils/add-ons';
import { getPatientInsurances } from '../../../../neb-api-client/src/patient-insurance-api-client';
import { openOverlayPatientInsuranceView } from '../../../../neb-app-layout/neb-open-overlay';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../../neb-redux/neb-redux-store';
import { navigate } from '../../../../neb-route/neb-route-state';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
} from '../../../../neb-styles/neb-variables';
import { formatDisplayProviders } from '../../../../neb-utils/formatters';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
  REFRESH_PATIENT_KEY,
  NEBULA_REFRESH_EVENT,
} from '../../../../neb-utils/neb-refresh';
import { mapToPatientInsuranceModel } from '../../../../neb-utils/patientInsurance';
import '../../../../neb-lit-components/src/components/controls/neb-button-action';
import '../../../../neb-lit-components/src/components/controls/neb-tab-group';
import '../../../../../src/components/tables/neb-table-patient-insurance';

export const ELEMENTS = {
  addInsuranceButton: { id: 'add-insurance-button' },
  activeInsuranceTable: { id: 'active-insurance-table' },
  inactiveInsuranceTable: { id: 'inactive-insurance-table' },
  tabGroup: { id: 'tab-group' },
};

const TABS = {
  active: { id: 'active', label: 'Active & Default' },
  inactive: { id: 'inactive', label: 'Inactive & Expired' },
};

const PATH = 'insurance';

class NebPatientInsuranceController extends LitElement {
  static get properties() {
    return {
      __mappedPatientInsurances: Array,
      __selectedTab: String,

      layout: { reflect: true, type: String },
      patientId: String,
      route: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          height: 100%;
          overflow-y: auto;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: ${CSS_SPACING} 0;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) .container {
          width: max-content;
        }

        .add-button {
          padding: 10px 0 30px ${CSS_SPACING};
          width: fit-content;
        }

        .table {
          padding: ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__hasAddOnCTVerify = false;
    this.__mappedPatientInsurances = [];
    this.__navItems = this.genNavItems();
    this.__patientInsurances = [];
    this.__selectedTab = 'active';

    this.layout = '';
    this.patientId = '';
    this.route = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: selectedTab =>
        store.dispatch(navigate(`${this.__basePath()}/${selectedTab}`)),
      onAddInsurance: async () => {
        const context = {
          cancel: 'cancelAll',
          rteEnabled: true,
          showBackButton: true,
          hasAddOnCTVerify: this.__hasAddOnCTVerify,
        };

        if (this.__hasAddOnCTVerify) {
          await openOverlay(OVERLAY_KEYS.ADD_INSURANCE, {
            patientId: this.patientId,
            patientInsurances: this.__patientInsurances,
            context: {
              ...context,
              providers: formatDisplayProviders(
                store.getState().providers.item,
              ),
            },
          });
        } else {
          const result = await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_ADD, {
            patientId: this.patientId,
            patientInsurances: this.__patientInsurances,
            context,
          });

          if (result) await this.__showViewInsurance(result);
        }
      },
      viewInsurance: (_name, insurance) => {
        this.__showViewInsurance(insurance);
      },
      refresh: ({ detail }) => {
        if (!this.patientId) return;

        const { changed } = detail[REFRESH_PATIENT_KEY][this.patientId];

        if (changed.includes(REFRESH_CHANGE_TYPE.PATIENT_INSURANCE)) {
          this.__setPatientInsurances();
        }
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);

    this.__setPatientInsurances();

    window.addEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
  }

  disconnectedCallback() {
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);

    super.disconnectedCallback();
  }

  genNavItems() {
    return [
      {
        exact: false,
        path: '/active/',
        resolver: () => html`
          <neb-table-patient-insurance
            id="${ELEMENTS.activeInsuranceTable.id}"
            class="table"
            emptyMessage="There is no active insurance recorded for this patient. Click &quot;Add Insurance&quot; to create a new record."
            .model="${this.__filterItemsByActive(true)}"
            .onSelectRow="${this.__handlers.viewInsurance}"
            showDefault
          ></neb-table-patient-insurance>
        `,
      },
      {
        exact: false,
        path: '/inactive/',
        resolver: () => html`
          <neb-table-patient-insurance
            id="${ELEMENTS.inactiveInsuranceTable.id}"
            class="table"
            emptyMessage="There is no inactive or expired insurance recorded for this patient."
            .model="${this.__filterItemsByActive(false)}"
            .onSelectRow="${this.__handlers.viewInsurance}"
          ></neb-table-patient-insurance>
        `,
      },
    ];
  }

  __basePath() {
    return `#/patients/${this.patientId}/${PATH}`;
  }

  __filterItemsByActive(value) {
    return this.__mappedPatientInsurances.filter(
      insurance => insurance.active === value,
    );
  }

  __getInactiveInsuranceCount() {
    const count = this.__patientInsurances.filter(({ active }) => !active)
      .length;

    return count ? ` (${count})` : '';
  }

  __getActiveInsuranceCount() {
    const count = this.__patientInsurances.filter(({ active }) => active)
      .length;

    return count ? ` (${count})` : '';
  }

  __getTabs() {
    return [
      {
        id: 'active',
        label: `Active & Default${this.__getActiveInsuranceCount()}`,
      },
      {
        id: 'inactive',
        label: `Inactive & Expired${this.__getInactiveInsuranceCount()}`,
      },
    ];
  }

  async __setPatientInsurances() {
    this.__patientInsurances = await getPatientInsurances(this.patientId);
    this.__mappedPatientInsurances = this.__patientInsurances.map(insurance =>
      mapToPatientInsuranceModel(insurance),
    );
  }

  async __showViewInsurance(insurance) {
    await openOverlayPatientInsuranceView({
      patientId: this.patientId,
      patientInsurance: { id: insurance.id },
    });

    sendRefreshNotification(
      [REFRESH_CHANGE_TYPE.PATIENT_INSURANCE, REFRESH_CHANGE_TYPE.PATIENT],
      this.patientId,
    );
  }

  update(changedProps) {
    if (changedProps.has('patientId') && changedProps.get('patientId')) {
      this.__setPatientInsurances();
    }

    if (changedProps.has('route')) {
      this.__selectedTab = this.route.split('/')[1];

      if (!TABS[this.__selectedTab]) {
        store.dispatch(navigate(`${this.__basePath()}/active`));
      }
    }

    super.update(changedProps);
  }

  render() {
    return html`
      <div class="container">
        <neb-button-action
          id="${ELEMENTS.addInsuranceButton.id}"
          class="add-button"
          label="Add Insurance"
          .onClick="${this.__handlers.onAddInsurance}"
        ></neb-button-action>

        <neb-tab-group
          id="${ELEMENTS.tabGroup.id}"
          class="tabs"
          .layout="${this.layout}"
          .items="${this.__getTabs()}"
          .selectedId="${this.__selectedTab}"
          .onSelect="${this.__handlers.selectTab}"
        ></neb-tab-group>

        ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

customElements.define(
  'neb-patient-insurance-controller',
  NebPatientInsuranceController,
);
