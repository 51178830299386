import '../neb-action-bar-paged';
import '../neb-progress-tracker';
import '../inputs/neb-select-search';
import '../patients/neb-patient-card';

import { isRequired, isPhoneNumber } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import { formatPersonForPatientCard } from '../../../../neb-api-client/src/formatters/person';
import { getOrganizations } from '../../../../neb-api-client/src/organizations';
import { fetchOne } from '../../../../neb-api-client/src/patient-api-client';
import { createPatientInsurance } from '../../../../neb-api-client/src/patient-insurance-api-client';
import { getSettingsPermissions } from '../../../../neb-api-client/src/permissions-api-client';
import { getServiceTypes } from '../../../../neb-api-client/src/service-types';
import { PayerPlanQueryService } from '../../../../neb-api-client/src/services/payer-plan';
import { PersonService } from '../../../../neb-api-client/src/services/person';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_BUTTON_SPACING_ROW,
  CSS_COLOR_GREY_1,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import Debouncer from '../../../../neb-utils/debouncer';
import { currencyToCents } from '../../../../neb-utils/formatters';
import {
  currency,
  phone,
  numberNoLeadingZeroAllowZero,
} from '../../../../neb-utils/masks';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../neb-utils/neb-refresh';
import { URL_NO_ACCESS } from '../../../../neb-utils/neb-request-security';
import { createModel } from '../../../../neb-utils/patient';
import {
  createModelNewPatientInsurance,
  DATA_KEYS,
  DEFAULT_LEVEL,
  EMPTY_PAYER_PLAN,
  INSURED_RELATIONSHIP,
  patientInsuranceToRaw,
  mapToPatientInsuranceModel,
  getIndexOrderForDefaultLevel,
  INSURANCE_TYPE,
  isMedicareSecondary,
  POLICY_HOLDER_ITEMS,
  isWorkersComp,
  getSelectedServiceTypes,
  mapCoverageLevelValueSimplified,
  mapCoverageLevelDisplayValue,
  mapSelectedServiceTypesDisplay,
  findNewPayerId,
  addLabelToPlan,
  getInsurancePlanName,
  removeFutureDates,
  isSelfSelected,
  shouldDisablePolicyHolderField,
} from '../../../../neb-utils/patientInsurance';
import {
  EMPTY_POLICY_HOLDER,
  rawPatientToPolicyHolder,
  personToPolicyHolder,
} from '../../../../neb-utils/policyHolder';
import { max } from '../../../../neb-utils/validators';
import { sortServiceTypes } from '../../utils/insurance-util';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import { addLabelToEntity } from '../guarantor/guarantor-util';
import { POPOVER_POSITION } from '../neb-date-picker';
import {
  POPUP_CONFIRM_PAYER,
  POPUP_CONFIRM_PLAN_ID,
  POPUP_MSG_PAYER,
  POPUP_MSG_PLAN_ID,
  findMatchingMemberAndPayerPlan,
  findMatchingMemberAndGroup,
  getRequiredText,
  rowValidationNetworkFields,
} from '../patients/insurance/shared/neb-patient-insurance-validation-util';
import { SEX } from '../patients/neb-patient-profile-data';

import NebForm, { ELEMENTS as BASE_ELEMENTS } from './neb-form';
import { NO_RESULTS_FOUND } from './neb-form-patient-insurance-edit';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  progressTracker: {
    id: 'progress-tracker',
  },
  formStep1: {
    id: 'form-step1',
  },
  addNewPayerButton: {
    id: 'add-new-payer',
  },
  copayCoverageLevelDropdown: {
    id: 'copay-coverage-level-dropdown',
  },
  copayServiceTypesMultiselect: {
    id: 'copay-service-types-multiselect',
  },
  copayInNetworkField: {
    id: 'copay-in-network-field',
  },
  copayOutOfNetworkField: {
    id: 'copay-out-of-network-field',
  },
  coinsCoverageLevelDropdown: {
    id: 'coins-coverage-level-dropdown',
  },
  coinsServiceTypesMultiselect: {
    id: 'coins-service-types-multiselect',
  },
  coinsInNetworkField: {
    id: 'coins-in-network-field',
  },
  coinsOutOfNetworkField: {
    id: 'coins-out-of-network-field',
  },
  deductiblesCoverageLevelDropdown: {
    id: 'deductible-coverage-level-dropdown',
  },
  deductiblesServiceTypesMultiselect: {
    id: 'deductible-service-types-multiselect',
  },
  deductiblesInNetworkAmount: {
    id: 'deductible-in-network-amount',
  },
  deductiblesInNetworkRemaining: {
    id: 'deductible-in-network-remaining',
  },
  deductiblesInNetworkStart: {
    id: 'deductible-in-network-start',
  },
  deductiblesOutOfNetworkAmount: {
    id: 'deductible-out-of-network-amount',
  },
  deductiblesOutOfNetworkRemaining: {
    id: 'deductible-out-of-network-remaining',
  },
  deductiblesOutOfNetworkStart: {
    id: 'deductible-out-of-network-start',
  },
  outOfPocketsCoverageLevelDropdown: {
    id: 'outOfPockets-coverage-level-dropdown',
  },
  outOfPocketsInNetworkAmount: {
    id: 'outOfPockets-in-network-amount',
  },
  outOfPocketsInNetworkRemaining: {
    id: 'outOfPockets-in-network-remaining',
  },
  outOfPocketsInNetworkStart: {
    id: 'outOfPockets-in-network-start',
  },
  outOfPocketsOutOfNetworkAmount: {
    id: 'outOfPockets-out-of-network-amount',
  },
  outOfPocketsOutOfNetworkRemaining: {
    id: 'outOfPockets-out-of-network-remaining',
  },
  outOfPocketsOutOfNetworkStart: {
    id: 'outOfPockets-out-of-network-start',
  },
  defaultLevelDropdown: {
    id: 'defaultLevel',
  },
  dobPicker: {
    id: `${DATA_KEYS.PolicyHolder}-dateOfBirth`,
  },
  extensionField: {
    id: `${DATA_KEYS.PlanInfo}-extension`,
  },
  firstNameField: {
    id: `${DATA_KEYS.PolicyHolder}-firstName`,
  },
  groupIdField: {
    id: `${DATA_KEYS.PlanInfo}-groupIdentifier`,
  },
  groupIdTooltip: {
    id: 'group-id-tooltip',
  },
  groupIdTooltipText: {
    id: 'group-id-tooltip-text',
  },
  insuranceTypeCodeDropdown: {
    id: 'insuranceTypeCode',
  },
  lastNameField: {
    id: `${DATA_KEYS.PolicyHolder}-lastName`,
  },
  memberIdentifierField: {
    id: `${DATA_KEYS.PlanInfo}-memberIdentifier`,
  },
  middleNameField: {
    id: `${DATA_KEYS.PolicyHolder}-middleName`,
  },
  personSearchField: {
    id: 'person-search',
  },
  payerDropdown: {
    id: 'payerPlan-id',
  },
  phoneField: {
    id: `${DATA_KEYS.PlanInfo}-phone`,
  },
  planNameField: {
    id: `${DATA_KEYS.PlanInfo}-planName`,
  },
  policyHolderInsuredNameField: {
    id: `${DATA_KEYS.PolicyHolder}-insuredName`,
  },
  policyHolderRelationDropdown: {
    id: 'insuredRelationship',
  },
  sexDropdown: { id: `${DATA_KEYS.PolicyHolder}-sex` },
  suffixField: { id: `${DATA_KEYS.PolicyHolder}-suffix` },
  typeField: {
    id: 'payerPlan-financialClass',
  },
  toolTip: {
    id: 'policyHolder-tooltip',
  },
  toolTipText: {
    id: 'policyHolder-tooltip-text',
  },
  websiteField: {
    id: `${DATA_KEYS.PlanInfo}-website`,
  },
  startDateField: {
    id: `${DATA_KEYS.PlanInfo}-start`,
  },
  termDateField: {
    id: `${DATA_KEYS.PlanInfo}-end`,
  },
  wcbCaseNumber: {
    id: `${DATA_KEYS.PlanInfo}-wcb-case-number`,
  },
  startDateTooltip: {
    id: 'start-date-tooltip',
  },
  startDateTooltipText: {
    id: 'start-date-tooltip-text',
  },
  termDateTooltip: {
    id: 'term-date-tooltip',
  },
  termDateTooltipText: {
    id: 'term-date-tooltip-text',
  },
  resetDateField: {
    id: 'reset-date-field',
  },
  resetDateTooltip: {
    id: 'reset-date-tooltip',
  },
  resetDateTooltipText: {
    id: 'reset-date-tooltiptext',
  },
  visitLimitAsOf: {
    id: 'visit-limit-as-of',
  },
  renderedOutsidePractice: {
    id: 'rendered-outside-practice-textfield',
    label: 'Rendered Outside Practice',
  },
  maxVisits: {
    id: 'max-visits-textfield',
    label: 'Annual Visit Limit',
  },
  formStep2: {
    id: 'form-step2',
  },
  formStep3: {
    id: 'form-step3',
  },
};

const PROGRESS_ITEMS = [
  'Plan & Policyholder',
  'Copay & Coinsurance',
  'Other Coverage Details',
];

export const SAVE_SUCCESS_TEXT = 'Insurance saved.';
export const SAVE_ERROR_TEXT = 'An error occurred when saving the Insurance.';

class NebFormPatientInsuranceAdd extends NebForm {
  static get properties() {
    return {
      __formProgressState: Number,
      __patient: Object,
      __selfPolicyHolder: Object,
      __payerPlans: Array,
      __payerPlanItems: Array,
      __processing: Boolean,
      __selectedPayerPlan: Object,
      __searchResults: Array,
      __serviceTypes: Array,
      patientId: String,
      patientInsurances: Array,
    };
  }

  static createModel() {
    return {
      ...createModelNewPatientInsurance(),
    };
  }

  createSelectors() {
    return {
      children: {
        payerPlanId: [isRequired()],
        insuranceTypeCode: {
          validators: [
            {
              error: 'Required',
              validate: v =>
                !isMedicareSecondary(
                  this.state.payerPlan.financialClass,
                  this.state.defaultLevel,
                ) || v.trim(),
            },
          ],
        },
        payerPlan: {
          clipPristine: true,
          unsafe: true,
        },
        planInfo: {
          children: {
            memberIdentifier: [isRequired()],
            phone: [isPhoneNumber('(###) ###-####')],
          },
        },
        policyHolder: {
          unsafe: true,
          children: {
            insuredName: {
              validators: [
                {
                  error: 'Required',
                  validate: v =>
                    !isWorkersComp(this.state.payerPlan) || v.trim(),
                },
              ],
            },
            firstName: {
              validators: [
                {
                  error: 'Required',
                  validate: v =>
                    isWorkersComp(this.state.payerPlan) || v.trim(),
                },
              ],
            },
            lastName: {
              validators: [
                {
                  error: 'Required',
                  validate: v =>
                    isWorkersComp(this.state.payerPlan) || v.trim(),
                },
              ],
            },
            dateOfBirth: {
              unsafe: true,
            },
            address: {
              clipPristine: true,
            },
            phones: {
              clipPristine: true,
              unsafe: true,
            },
          },
        },
        copays: {
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                serviceTypes: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v => v.length,
                    },
                  ],
                  createItem: () => ({
                    serviceTypeId: '',
                  }),
                },
                inNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.copays[0].coverageLevel.code === 'NA' ||
                        v !== '' ||
                        this.state.copays[0].outOfNetworkAmount !== '',
                    },
                  ],
                },
                outOfNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.copays[0].coverageLevel.code === 'NA' ||
                        v !== '' ||
                        this.state.copays[0].inNetworkAmount !== '',
                    },
                  ],
                },
              },
            },
          },
        },
        coinsurances: {
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                serviceTypes: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v => v.length,
                    },
                  ],
                  createItem: () => ({
                    serviceTypeId: '',
                  }),
                },
                inNetworkPercent: {
                  validators: [
                    {
                      error: 'May not exceed 100',
                      validate: v =>
                        this.state.coinsurances[0].coverageLevel.code ===
                          'NA' || v <= 100,
                    },
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.coinsurances[0].coverageLevel.code ===
                          'NA' ||
                        v !== '' ||
                        this.state.coinsurances[0].outOfNetworkPercent !== '',
                    },
                  ],
                },
                outOfNetworkPercent: {
                  validators: [
                    {
                      error: 'May not exceed 100',
                      validate: v =>
                        this.state.coinsurances[0].coverageLevel.code ===
                          'NA' || v <= 100,
                    },
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.coinsurances[0].coverageLevel.code ===
                          'NA' ||
                        v !== '' ||
                        this.state.coinsurances[0].inNetworkPercent !== '',
                    },
                  ],
                },
              },
            },
          },
        },
        deductibles: {
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                serviceTypes: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v => v.length,
                    },
                  ],
                  createItem: () => ({
                    serviceTypeId: '',
                  }),
                },
                inNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        v !== '' ||
                        rowValidationNetworkFields(
                          v,
                          this.state.deductibles[0].inNetworkRemaining,
                          this.state.deductibles[0].inNetworkStart,
                          this.state.deductibles[0].outOfNetworkAmount,
                        ),
                    },
                  ],
                },
                inNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        v !== '' ||
                        (v === '') ===
                          (this.state.deductibles[0].inNetworkStart === null),
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        currencyToCents(v) <=
                          currencyToCents(
                            this.state.deductibles[0].inNetworkAmount,
                          ),
                    },
                  ],
                },
                inNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        v !== null ||
                        (v === null) ===
                          (this.state.deductibles[0].inNetworkRemaining === ''),
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: () => {
                        if (
                          this.shadowRoot.getElementById(
                            ELEMENTS.deductiblesInNetworkStart.id,
                          )
                        ) {
                          const dateErrors = this.shadowRoot.getElementById(
                            ELEMENTS.deductiblesInNetworkStart.id,
                          ).invalidTextInput;
                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
                outOfNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        v !== '' ||
                        rowValidationNetworkFields(
                          v,
                          this.state.deductibles[0].outOfNetworkRemaining,
                          this.state.deductibles[0].outOfNetworkStart,
                          this.state.deductibles[0].inNetworkAmount,
                        ),
                    },
                  ],
                },
                outOfNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        v !== '' ||
                        (v === '') ===
                          (this.state.deductibles[0].outOfNetworkStart ===
                            null),
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        currencyToCents(v) <=
                          currencyToCents(
                            this.state.deductibles[0].outOfNetworkAmount,
                          ),
                    },
                  ],
                },
                outOfNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.deductibles[0].coverageLevel.code === 'NA' ||
                        v !== null ||
                        (v === null) ===
                          (this.state.deductibles[0].outOfNetworkRemaining ===
                            ''),
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: () => {
                        if (
                          this.shadowRoot.getElementById(
                            ELEMENTS.deductiblesOutOfNetworkStart.id,
                          )
                        ) {
                          const dateErrors = this.shadowRoot.getElementById(
                            ELEMENTS.deductiblesOutOfNetworkStart.id,
                          ).invalidTextInput;
                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        outOfPockets: {
          children: {
            $: {
              children: {
                coverageLevel: {
                  clipPristine: true,
                },
                serviceTypes: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v => v.length,
                    },
                  ],
                  createItem: () => ({
                    serviceTypeId: '',
                  }),
                },
                inNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        v !== '' ||
                        rowValidationNetworkFields(
                          v,
                          this.state.outOfPockets[0].inNetworkRemaining,
                          this.state.outOfPockets[0].inNetworkStart,
                          this.state.outOfPockets[0].outOfNetworkAmount,
                        ),
                    },
                  ],
                },
                inNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        v !== '' ||
                        (v === '') ===
                          (this.state.outOfPockets[0].inNetworkStart === null),
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        currencyToCents(v) <=
                          currencyToCents(
                            this.state.outOfPockets[0].inNetworkAmount,
                          ),
                    },
                  ],
                },
                inNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        v !== null ||
                        (v === null) ===
                          (this.state.outOfPockets[0].inNetworkRemaining ===
                            ''),
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: () => {
                        if (
                          this.shadowRoot.getElementById(
                            ELEMENTS.outOfPocketsInNetworkStart.id,
                          )
                        ) {
                          const dateErrors = this.shadowRoot.getElementById(
                            ELEMENTS.outOfPocketsInNetworkStart.id,
                          ).invalidTextInput;
                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
                outOfNetworkAmount: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        v !== '' ||
                        rowValidationNetworkFields(
                          v,
                          this.state.outOfPockets[0].outOfNetworkRemaining,
                          this.state.outOfPockets[0].outOfNetworkStart,
                          this.state.outOfPockets[0].inNetworkAmount,
                        ),
                    },
                  ],
                },
                outOfNetworkRemaining: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        v !== '' ||
                        (v === '') ===
                          (this.state.outOfPockets[0].outOfNetworkStart ===
                            null),
                    },
                    {
                      error: 'Can’t exceed Amount Value',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        currencyToCents(v) <=
                          currencyToCents(
                            this.state.outOfPockets[0].outOfNetworkAmount,
                          ),
                    },
                  ],
                },
                outOfNetworkStart: {
                  validators: [
                    {
                      error: 'Required',
                      validate: v =>
                        this.state.outOfPockets[0].coverageLevel.code ===
                          'NA' ||
                        v !== null ||
                        (v === null) ===
                          (this.state.outOfPockets[0].outOfNetworkRemaining ===
                            ''),
                    },
                    {
                      error: 'mm/dd/yyyy',
                      validate: () => {
                        if (
                          this.shadowRoot.getElementById(
                            ELEMENTS.outOfPocketsOutOfNetworkStart.id,
                          )
                        ) {
                          const dateErrors = this.shadowRoot.getElementById(
                            ELEMENTS.outOfPocketsOutOfNetworkStart.id,
                          ).invalidTextInput;
                          return !dateErrors;
                        }
                        return true;
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        patientInsuranceVisitLimit: {
          children: {
            maxVisits: {
              validators: [
                {
                  error: 'Must be a numeric value',
                  validate: v => Number.isInteger(Number(v)),
                },
                max(999, true, 'Must be at max 999'),
                {
                  error: 'Required',
                  validate: (v, _, state) =>
                    !this.__coverageTabViewed ||
                    v !== '' ||
                    (!state.patientInsuranceVisitLimit.asOf &&
                      !state.patientInsuranceVisitLimit
                        .visitsRenderedOutOfOffice),
                },
              ],
            },
            visitsRenderedOutOfOffice: {
              validators: [
                {
                  error: 'Must be a numeric value',
                  validate: v => Number.isInteger(Number(v)),
                },
                max(999, true, 'Must be at max 999'),
                {
                  error: 'Required',

                  validate: (v, _, state) =>
                    !this.__coverageTabViewed ||
                    v !== '' ||
                    !state.patientInsuranceVisitLimit.asOf,
                },
              ],
            },
            asOf: {
              validators: [
                {
                  error: 'Required',
                  validate: (v, _, state) => {
                    const {
                      visitsRenderedOutOfOffice,
                    } = state.patientInsuranceVisitLimit;

                    if (visitsRenderedOutOfOffice) {
                      return !!visitsRenderedOutOfOffice && !!v;
                    }

                    return true;
                  },
                },
              ],
            },
          },
        },
      },
    };
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.patientInsurances = [];
    this.organizations = [];
    this.__processing = false;
    this.__patient = createModel();
    this.__selfPolicyHolder = null;
    this.__formProgressState = 1;
    this.__payerPlans = [];
    this.__payerPlanItems = [];
    this.__payerPlanCreated = false;
    this.__searchResults = [];
    this.__selectedEntity = null;
    this.__selectedPayerPlan = null;
    this.__payerSearchValue = '';
    this.__serviceTypes = [];
    this.__today = parseDate();
    this.__searchDebouncer = new Debouncer(() => {
      this.__doDebounce();
    });

    this.__setupPayerPlanQueryService();

    this.__personService = new PersonService(data => {
      this.__searchResults = [...data, ...this.organizations];
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      startDateSelectable: date => date > this.__today,
      changeResetDate: ({ value }) => {
        this.formService.apply(
          'planInfo.resetDate',
          value !== null
            ? moment
                .utc(value)
                .endOf('day')
                .toISOString()
            : value,
        );

        this.__updateResetDateSelectable();
      },
      changeInsuredRelationship: ({ value: insuredRelationship }) => {
        if (insuredRelationship !== this.state.insuredRelationship) {
          if (insuredRelationship === INSURED_RELATIONSHIP.Self) {
            this.__selectedEntity = null;

            this.__setPolicyHolder(this.__selfPolicyHolder);
          } else if (
            this.state.insuredRelationship === INSURED_RELATIONSHIP.Self
          ) {
            this.__setPolicyHolder(EMPTY_POLICY_HOLDER);
          }

          this.formService.apply('insuredRelationship', insuredRelationship);
        }
      },
      changeDefaultLevel: e => {
        if (e.value === this.state.defaultLevel) return;

        const indexOrder = getIndexOrderForDefaultLevel(e.value);

        if (
          isMedicareSecondary(
            this.state.payerPlan.financialClass,
            this.state.defaultLevel,
          )
        ) {
          this.formService.apply('defaultLevel', e.value);
          this.formService.apply('indexOrder', indexOrder);
          this.formService.apply('insuranceTypeCode', '');
        } else {
          this.formService.apply('defaultLevel', e.value);
          this.formService.apply('indexOrder', indexOrder);
        }
      },
      changeCopayCoverageLevel: e => {
        if (
          e.event === 'select' &&
          this.state.copays[0].coverageLevel.code !== e.value.code
        ) {
          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          this.__removeServiceTypes('copays');

          if (this.state.copays[0].coverageLevel.code === 'NA') {
            this.formService.apply('copays.0.inNetworkAmount', '');
            this.formService.apply('copays.0.outOfNetworkAmount', '');
          } else {
            this.__serviceTypes.forEach((types, idx) => {
              this.formService.addItem('copays.0.serviceTypes');
              this.formService.apply(
                `copays.0.serviceTypes.${idx}.serviceTypeId`,
                types.id,
              );
            });

            this.formService.validateKey(['copays', '0', 'serviceTypes'], true);
          }
        }
      },
      changeCoinsCoverageLevel: e => {
        if (
          e.event === 'select' &&
          this.state.coinsurances[0].coverageLevel.code !== e.value.code
        ) {
          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          this.__removeServiceTypes('coinsurances');

          if (this.state.coinsurances[0].coverageLevel.code === 'NA') {
            this.formService.apply('coinsurances.0.inNetworkPercent', '');
            this.formService.apply('coinsurances.0.outOfNetworkPercent', '');
          } else {
            this.__serviceTypes.forEach((types, idx) => {
              this.formService.addItem('coinsurances.0.serviceTypes');
              this.formService.apply(
                `coinsurances.0.serviceTypes.${idx}.serviceTypeId`,
                types.id,
              );
            });

            this.formService.validateKey(
              ['coinsurances', '0', 'serviceTypes'],
              true,
            );
          }
        }
      },
      changeDeductiblesCoverageLevel: e => {
        if (
          e.event === 'select' &&
          this.state.deductibles[0].coverageLevel.code !== e.value.code
        ) {
          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          this.__removeServiceTypes('deductibles');

          if (this.state.deductibles[0].coverageLevel.code === 'NA') {
            this.formService.apply('deductibles.0.inNetworkAmount', '');
            this.formService.apply('deductibles.0.inNetworkRemaining', '');
            this.formService.apply('deductibles.0.inNetworkStart', null);
            this.formService.apply('deductibles.0.outOfNetworkAmount', '');
            this.formService.apply('deductibles.0.outOfNetworkRemaining', '');
            this.formService.apply('deductibles.0.outOfNetworkStart', null);
          } else {
            this.__serviceTypes.forEach((types, idx) => {
              this.formService.addItem('deductibles.0.serviceTypes');
              this.formService.apply(
                `deductibles.0.serviceTypes.${idx}.serviceTypeId`,
                types.id,
              );
            });

            this.formService.validateKey(
              ['deductibles', '0', 'serviceTypes'],
              true,
            );
          }
        }
      },
      changeOutOfPocketsCoverageLevel: e => {
        if (
          e.event === 'select' &&
          this.state.outOfPockets[0].coverageLevel.code !== e.value.code
        ) {
          this.formService.apply(e.name, {
            code: e.value.code,
            description: e.value.description,
          });

          if (this.state.outOfPockets[0].coverageLevel.code === 'NA') {
            this.formService.apply('outOfPockets.0.inNetworkAmount', '');
            this.formService.apply('outOfPockets.0.inNetworkRemaining', '');
            this.formService.apply('outOfPockets.0.inNetworkStart', null);
            this.formService.apply('outOfPockets.0.outOfNetworkAmount', '');
            this.formService.apply('outOfPockets.0.outOfNetworkRemaining', '');
            this.formService.apply('outOfPockets.0.outOfNetworkStart', null);
          }
        }
      },
      multiSelectChange: e => {
        const payType = e.name.split('.')[0];

        if (e.event === 'select' || e.event === 'selectAll') {
          this.__removeServiceTypes(payType);

          e.value.forEach((types, idx) => {
            this.formService.addItem(`${payType}.0.serviceTypes`);
            this.formService.apply(
              `${payType}.0.serviceTypes.${idx}.serviceTypeId`,
              types.id,
            );
          });
        }
        this.formService.validateKey([payType, '0', 'serviceTypes'], true);
      },
      selectEntity: ({ value: entity }) => {
        if (!entity) {
          this.__selectedEntity = null;

          this.__setPolicyHolder({
            ...EMPTY_POLICY_HOLDER,
            insuredName: this.state.policyHolder.insuredName,
          });
        } else if (entity !== NO_RESULTS_FOUND) {
          this.__selectedEntity = entity;

          this.__setPolicyHolder(
            personToPolicyHolder(entity, {
              address: entity.isOrganization ? entity.address : null,
              currentInsuredName: this.state.policyHolder.insuredName,
            }),
          );
        }
      },
      searchPerson: ({ value }) => {
        this.__searchValue = value;
        this.__showResults = Boolean(this.__searchValue);
        this.__searchDebouncer.debounce();
      },
      copayTextChange: e => {
        const copayField = e.name.split('.')[2];

        if (this.state.copays[0][copayField] !== e.value) {
          this.formService.apply(e.name, e.value);
          this.formService.validateKey(
            ['copays', '0', 'inNetworkAmount'],
            true,
          );

          this.formService.validateKey(
            ['copays', '0', 'outOfNetworkAmount'],
            true,
          );
        }
      },
      coinsTextChange: e => {
        const coinsField = e.name.split('.')[2];

        if (this.state.coinsurances[0][coinsField] !== e.value) {
          this.formService.apply(e.name, e.value);
          this.formService.validateKey(
            ['coinsurances', '0', 'inNetworkPercent'],
            true,
          );

          this.formService.validateKey(
            ['coinsurances', '0', 'outOfNetworkPercent'],
            true,
          );
        }
      },
      visitLimitChange: ({ name, value }) => {
        this.formService.apply(name, value);
        this.__validateVisitLimits();
      },
      changePage3: e => {
        const section = e.name.split('.')[0];
        const field = e.name.split('.')[2];

        if (this.state[section][0][field] !== e.value) {
          this.formService.apply(e.name, e.value);

          this.__validateKeysPage3(section);
        }
      },
      inNetworkTextClear: () => {
        this.formService.apply('copays.0.inNetworkAmount', '');
        this.formService.validateKey(['copays', '0', 'inNetworkAmount'], true);
        this.formService.validateKey(
          ['copays', '0', 'outOfNetworkAmount'],
          true,
        );
      },
      outOfNetworkTextClear: () => {
        this.formService.apply('copays.0.outOfNetworkAmount', '');
        this.formService.validateKey(['copays', '0', 'inNetworkAmount'], true);
        this.formService.validateKey(
          ['copays', '0', 'outOfNetworkAmount'],
          true,
        );
      },
      textClearPage3: e => {
        const section = e.split('.')[0];
        const field = e.split('.')[2];
        this.formService.apply(`${section}.0.${field}`, '');

        this.__validateKeysPage3(section);
      },
      changePayer: e => {
        if (e.value) return this.__applyPayerPlan(e.value.item.id);

        return this.__clearPayerPlan();
      },
      searchPayer: e => {
        this.__payerSearchValue = e.value;

        this.__payerPlanQueryService.update({
          search: e.value,
          hideInactive: true,
        });
      },
      changeStartDate: ({ value }) => {
        this.formService.apply(
          'planInfo.start',
          value !== null
            ? parseDate(value)
                .startOf('day')
                .toISOString()
            : value,
        );

        if (value && !this.state.planInfo.resetDate) {
          let resetDate = null;

          const today = parseDate();
          const startDate = parseDate(value);

          if (startDate.isAfter(today)) {
            resetDate = startDate
              .add(1, 'year')
              .startOf('year')
              .toISOString();
          } else {
            resetDate = today
              .add(1, 'year')
              .startOf('year')
              .toISOString();
          }

          this.formService.apply('planInfo.resetDate', resetDate);
        }

        this.__updateStartDateSelectable();
      },
      changeEndDate: ({ value }) => {
        this.formService.apply(
          'planInfo.end',
          value !== null
            ? parseDate(value)
                .endOf('day')
                .toISOString()
            : value,
        );

        this.__updateEndDateSelectable();
      },
      cancel: () => {
        if (!this.__processing) this.onCancel();
      },
      // eslint-disable-next-line complexity
      proceed: async () => {
        if (this.__processing) return;

        this.__checkSetPlanName();

        if (!this.__validateStartEndResetDateFields()) return;

        if (this.formService.validate()) {
          if (
            this.__formProgressState === 1 &&
            !(await this.__validateForm1Popups())
          ) {
            return;
          }

          if (this.__formProgressState === 3) {
            this.__processing = true;

            const postBody = patientInsuranceToRaw(
              {
                ...this.state,
                copays: this.__formatCst('copays'),
                coinsurances: this.__formatCst('coinsurances'),
                deductibles: this.__formatCst('deductibles'),
                outOfPockets: this.__formatCst('outOfPockets'),
              },
              isMedicareSecondary(
                this.state.payerPlan.financialClass,
                this.state.defaultLevel,
              ),
            );

            delete postBody.id;

            try {
              const response = await createPatientInsurance(
                this.patientId,
                postBody,
              );

              sendRefreshNotification(
                [
                  REFRESH_CHANGE_TYPE.PATIENT_INSURANCE,
                  REFRESH_CHANGE_TYPE.PATIENT,
                ],
                this.patientId,
              );

              store.dispatch(openSuccess(SAVE_SUCCESS_TEXT));

              const mappedInsurance = mapToPatientInsuranceModel(
                response,
                false,
              );

              this.onSave(mappedInsurance);
            } catch (e) {
              store.dispatch(openError(SAVE_ERROR_TEXT));
              this.__processing = false;
            }

            return;
          }

          this.__formProgressState += 1;
        }
      },
      back: () => {
        if (this.formService.validate() && this.__canGoBack()) {
          this.__formProgressState -= 1;
        }
      },
      addPayerPlan: async () => {
        if (getSettingsPermissions()) {
          const shouldUpdate = await openOverlay(OVERLAY_KEYS.PAYER_PLAN);

          if (shouldUpdate) {
            this.__payerPlanCreated = true;
            this.__getPayerPlans();

            this.formService.apply('insuranceTypeCode', '');
          }
        } else {
          navigate(URL_NO_ACCESS);
        }
      },
    };

    this.__updateStartDateSelectable();
    this.__updateEndDateSelectable();
    this.__updateResetDateSelectable();
  }

  __validateStartEndResetDateFields() {
    const elementIds = ['resetDateField', 'startDateField', 'termDateField'];

    const hasError = elementIds.some(elementId => {
      const element = this.shadowRoot.getElementById(ELEMENTS[elementId].id);
      return element && element.invalidTextInput;
    });

    return !hasError;
  }

  __updateStartDateSelectable() {
    // eslint-disable-next-line complexity
    this.handlers.startDateSelectable = date => {
      const { end, resetDate } = this.state.planInfo;

      if (!end && !resetDate) {
        return true;
      }

      date = parseDate(date);
      let isEndBefore = false;
      let isResetBefore = false;
      let isResetDateTheSame = false;
      let isEndDateTheSame = false;

      if (end && resetDate) {
        isEndBefore = date.isBefore(end);
        isResetBefore = date.isBefore(resetDate);
        isResetDateTheSame = date
          .startOf('day')
          .isSame(parseDate(resetDate).startOf('day'));

        isEndDateTheSame = date
          .startOf('day')
          .isSame(parseDate(end).startOf('day'));

        if (isResetBefore < isEndBefore) {
          return isResetDateTheSame ? false : isResetBefore;
        }

        if (isResetBefore > isEndBefore) {
          return isEndDateTheSame ? false : isEndBefore;
        }
      }

      if (resetDate) {
        isResetBefore = date.isBefore(resetDate);
        isResetDateTheSame = date
          .startOf('day')
          .isSame(parseDate(resetDate).startOf('day'));

        return isResetDateTheSame ? false : isResetBefore;
      }

      if (end) {
        isEndBefore = date.isBefore(end);
        isEndDateTheSame = date
          .startOf('day')
          .isSame(parseDate(end).startOf('day'));

        return isEndDateTheSame ? false : isEndBefore;
      }

      return false;
    };
  }

  __updateResetDateSelectable() {
    this.handlers.resetDateSelectable = date => {
      if (!this.state.planInfo.start) {
        return true;
      }

      const dayIsSame = parseDate(date)
        .startOf('day')
        .isSame(parseDate(this.state.planInfo.start).startOf('day'));

      if (dayIsSame) return false;

      return date.isAfter(this.state.planInfo.start);
    };
  }

  __updateEndDateSelectable() {
    this.handlers.endDateSelectable = date => {
      if (!this.state.planInfo.start) {
        return true;
      }

      const dayIsSame = parseDate(date)
        .startOf('day')
        .isSame(parseDate(this.state.planInfo.start).startOf('day'));

      if (dayIsSame) return false;

      if (date.isAfter(this.state.planInfo.start)) {
        return true;
      }
      return false;
    };
  }

  __validateVisitLimits() {
    const parent = 'patientInsuranceVisitLimit';

    this.formService.validateKey([parent, 'maxVisits'], true);
    this.formService.validateKey([parent, 'visitsRenderedOutOfOffice'], true);
    this.formService.validateKey([parent, 'asOf'], true);
  }

  __checkSetPlanName() {
    const planName = getInsurancePlanName(this.__payerPlans, this.state);

    if (planName) {
      this.formService.apply('planInfo.planName', planName);
    }
  }

  __formatCst(cst) {
    return this.state[cst].map(m => ({
      ...m,
      isDefault: m.coverageLevel.code !== 'NA' ? true : null,
    }));
  }

  __removeServiceTypes(coType = 'copays') {
    for (
      let count = this.state[coType][0].serviceTypes.length;
      count > 0;
      count--
    ) {
      this.formService.removeItem(`${coType}.0.serviceTypes`, count - 1);
    }
  }

  __validateKeysPage3(section) {
    this.formService.validateKey([section, '0', 'inNetworkAmount'], true);

    this.formService.validateKey([section, '0', 'inNetworkRemaining'], true);

    this.formService.validateKey([section, '0', 'inNetworkStart'], true);

    this.formService.validateKey([section, '0', 'outOfNetworkAmount'], true);

    this.formService.validateKey([section, '0', 'outOfNetworkRemaining'], true);

    this.formService.validateKey([section, '0', 'outOfNetworkStart'], true);
  }

  __setPolicyHolder(policyHolder) {
    const fields = [
      'address',
      'insuredName',
      'dateOfBirth',
      'firstName',
      'middleName',
      'lastName',
      'suffix',
      'sex',
      'phones',
    ];

    fields.forEach(field => {
      this.formService.apply(`policyHolder.${field}`, policyHolder[field]);
    });
  }

  __setupPayerPlanQueryService() {
    const version = 2;

    this.__payerPlanQueryService = new PayerPlanQueryService(({ model }) => {
      let newId;

      if (this.__payerPlanCreated) {
        newId = findNewPayerId(this.__payerPlans, model);
      }

      this.__payerPlans = model;
      this.__payerPlanItems = this.__payerPlans.map(item => ({
        item,
        label: `(${item.alias}) ${item.payerName}`,
      }));

      if (this.__payerPlanCreated && newId) {
        this.__applyPayerPlan(newId);
      }

      this.__payerPlanCreated = false;
    }, version);
  }

  async __doDebounce() {
    const organizations = await getOrganizations();

    const modifiedOrganizations = organizations.map(org => ({
      ...org,
      isOrganization: true,
    }));

    this.organizations = modifiedOrganizations;
    await this.__personService.search(this.__searchValue, this.patientId);
  }

  __applyPayerPlan(id) {
    const selectedPayerPlan = this.__payerPlans.find(p => p.id === id) || {
      ...EMPTY_PAYER_PLAN,
    };

    this.__selectedPayerPlan = selectedPayerPlan;

    this.formService.apply('payerPlanId', id);
    this.formService.apply('payerPlan', selectedPayerPlan);
    this.formService.apply('planInfo.phone', selectedPayerPlan.phone);
    this.formService.apply('planInfo.extension', selectedPayerPlan.extension);
    this.formService.apply('planInfo.website', selectedPayerPlan.website);
    this.formService.apply(
      'planInfo.contactName',
      selectedPayerPlan.contactName,
    );
  }

  __clearPayerPlan() {
    this.__selectedPayerPlan = null;

    this.formService.apply('payerPlanId', '');
    this.formService.apply('payerPlan', this.model.payerPlan);
    this.formService.apply('planInfo.phone', '');
    this.formService.apply('planInfo.extension', '');
    this.formService.apply('planInfo.website', '');
    this.formService.apply('planInfo.contactName', '');
    this.formService.apply('insuranceTypeCode', '');
  }

  async __validateForm1Popups() {
    let gotoNextStep = true;

    if (findMatchingMemberAndPayerPlan(this.patientInsurances, this.state)) {
      gotoNextStep = await openPopup(POPUP_CONFIRM_PAYER, POPUP_MSG_PAYER);

      if (!gotoNextStep) return gotoNextStep;
    }

    if (findMatchingMemberAndGroup(this.patientInsurances, this.state)) {
      gotoNextStep = await openPopup(POPUP_CONFIRM_PLAN_ID, POPUP_MSG_PLAN_ID);
    }

    return gotoNextStep;
  }

  async __initPatient() {
    this.__patient = await fetchOne(this.patientId, true, true);
    this.__selfPolicyHolder = rawPatientToPolicyHolder(this.__patient);
  }

  __getPayerPlans() {
    this.__payerPlanQueryService.update({
      hideInactive: true,
    });
  }

  getVisitLimit() {
    return (
      this.state.patientInsuranceVisitLimit ||
      this.patientInsurance.patientInsuranceVisitLimit ||
      {}
    );
  }

  __canGoBack() {
    return !this.__processing && this.__formProgressState > 1;
  }

  __setInitialModel() {
    const model = this.formService.build();

    model.patientId = this.patientId;

    model.policyHolder = {
      ...this.__selfPolicyHolder,
      insuredName: '',
    };

    model.insuredRelationship = INSURED_RELATIONSHIP.Self;

    const activeInsuranceDefaultLevels = this.patientInsurances
      .filter(ins => ins.active)
      .map(x => x.defaultLevel);

    if (!activeInsuranceDefaultLevels.includes(DEFAULT_LEVEL.Primary)) {
      model.defaultLevel = DEFAULT_LEVEL.Primary;
      model.indexOrder = getIndexOrderForDefaultLevel(DEFAULT_LEVEL.Primary);
    } else if (
      !activeInsuranceDefaultLevels.includes(DEFAULT_LEVEL.Secondary)
    ) {
      model.defaultLevel = DEFAULT_LEVEL.Secondary;
      model.indexOrder = getIndexOrderForDefaultLevel(DEFAULT_LEVEL.Secondary);
    } else if (!activeInsuranceDefaultLevels.includes(DEFAULT_LEVEL.Tertiary)) {
      model.defaultLevel = DEFAULT_LEVEL.Tertiary;
      model.indexOrder = getIndexOrderForDefaultLevel(DEFAULT_LEVEL.Tertiary);
    }

    this.formService.refresh(model);
  }

  async firstUpdated() {
    this.__getPayerPlans();

    await this.__initPatient();

    this.__serviceTypes = sortServiceTypes(
      await getServiceTypes({
        hideInactive: true,
      }),
    );

    this.__setInitialModel();

    this.__sideLoading = false;
    this.__reloadPromise = null;
  }

  __proceedLabel() {
    return this.__formProgressState === 3 ? 'Save' : 'Next';
  }

  __backLabel() {
    return this.__canGoBack() ? 'Back' : '';
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
        }

        .container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }

        .progress-tracker {
          padding-left: 100px;
          padding-right: 100px;
          padding-bottom: 50px;
        }

        .tab-container {
          display: flex;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0;
          width: 100%;
          height: 100%;
          overflow-y: auto;
        }

        .top-title-container {
          padding: 0 0 4px ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
        }

        .bottom-title-container {
          padding: ${CSS_SPACING} 0 4px ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_1};
        }

        .title {
          padding-bottom: 4px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .description {
          font-size: ${CSS_FONT_SIZE_BODY};
        }

        .fields-container {
          padding: ${CSS_SPACING} ${CSS_SPACING} 0;
        }

        .col-fields {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-auto-rows: minmax(min-content, max-content);
          grid-template-columns: 1fr 1fr 1fr;
          flex: 1 0 0;
        }

        .col-fields-two {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-auto-rows: minmax(min-content, max-content);
          grid-template-columns: 1fr 1fr;
          flex: 1 0 0;
        }

        .page-3-spacer {
          padding-top: ${CSS_SPACING_ROW};
          padding-bottom: 4px;
        }

        .col-fields-four {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-auto-rows: minmax(min-content, max-content);
          grid-template-columns: 1fr 2fr 2fr 3fr;
          flex: 1 0 0;
        }

        .column-one {
          grid-column: 1 / 2;
        }

        .column-two {
          grid-column: 2 / 2;
        }

        .column-one-two {
          grid-column: 1 / 3;
        }

        .column-two-three {
          grid-column: 2 / 4;
        }

        .column-three {
          grid-column: 3 / 4;
        }

        .column-four {
          grid-column: 4 / 4;
        }

        .column-one-three {
          grid-column: 1 / 4;
        }

        .add-payer-button {
          width: fit-content;
          padding-top: ${CSS_BUTTON_SPACING_ROW};
        }

        .text-percent {
          width: 100%;
        }

        .percent {
          padding: 25px 0px 0px 10px;
        }

        .combo-field {
          display: flex;
        }

        .flex-three {
          flex: 3;
        }

        .date-picker {
          height: 41px;
          width: 100%;
          padding-bottom: 80px;
        }

        .tooltip {
          padding-left: 10px;
          padding-top: 24px;
        }

        .search {
          display: flex;
          z-index: 2;
        }

        .labels {
          padding-left: 10px;
          margin-top: 16px;
          margin-right: 20px;
        }

        .bottom-padding {
          padding-bottom: 30px;
        }
      `,
    ];
  }

  __renderWcbCaseNumber() {
    const showWcbCaseNumber =
      this.__selectedPayerPlan?.wcbCaseNumberOverride || false;

    return showWcbCaseNumber
      ? html`
          <neb-textfield
            id="${ELEMENTS.wcbCaseNumber.id}"
            name="planInfo.wcbCaseNumber"
            label="WCB Case Number"
            helper=" "
            maxLength="50"
            .value="${this.state.planInfo.wcbCaseNumber}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        `
      : '';
  }

  __renderPlanInfoSection() {
    return html`
      <div class="fields-container">
        <div class="col-fields">
          <neb-textfield
            id="${ELEMENTS.planNameField.id}"
            class="column-one-two"
            name="planInfo.planName"
            label="Enter Group or Plan Name"
            helper=" "
            maxLength="255"
            .error="${this.errors.planInfo.planName}"
            .value="${this.state.planInfo.planName}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-select-search
            id="${ELEMENTS.payerDropdown.id}"
            name="payerPlanId"
            class="search"
            label="Payer"
            helper="Required"
            .error="${this.errors.payerPlanId}"
            .items="${this.__payerPlanItems}"
            .search="${this.__payerSearchValue}"
            .value="${addLabelToPlan(this.__selectedPayerPlan)}"
            .onChange="${this.handlers.changePayer}"
            .onSearch="${this.handlers.searchPayer}"
            showSearch
          ></neb-select-search>

          <neb-button-action
            id="${ELEMENTS.addNewPayerButton.id}"
            class="column-three add-payer-button"
            label="Add New Payer"
            .onClick="${this.handlers.addPayerPlan}"
          ></neb-button-action>

          <div class="combo-field">
            <neb-textfield
              id="${ELEMENTS.groupIdField.id}"
              class="flex-three"
              name="planInfo.groupIdentifier"
              label="Group ID"
              helper=" "
              maxLength="255"
              .error="${this.errors.planInfo.groupIdentifier}"
              .value="${this.state.planInfo.groupIdentifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-tooltip
              id="${ELEMENTS.groupIdTooltip.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <div id="${ELEMENTS.groupIdTooltipText.id}" slot="tooltip">
                Enter the group or plan ID if known.
              </div>
            </neb-tooltip>
          </div>

          <neb-select
            id="${ELEMENTS.defaultLevelDropdown.id}"
            name="defaultLevel"
            label="Set As"
            .items="${Object.values(DEFAULT_LEVEL)}"
            .value="${this.state.defaultLevel}"
            .onChange="${this.handlers.changeDefaultLevel}"
            required
          ></neb-select>

          ${this.__renderWcbCaseNumber()}

          <neb-textfield
            id="${ELEMENTS.typeField.id}"
            class="column-one"
            name="payerPlan.financialClass"
            label="Type"
            helper=" "
            maxLength="255"
            .value="${this.state.payerPlan.financialClass}"
            ?disabled="${true}"
          ></neb-textfield>

          <neb-select
            id="${ELEMENTS.insuranceTypeCodeDropdown.id}"
            class="column-two-three"
            name="insuranceTypeCode"
            label="Medicare Type Code"
            helper="${
              getRequiredText(
                isMedicareSecondary(
                  this.state.payerPlan.financialClass,
                  this.state.defaultLevel,
                ),
              )
            }"
            .items="${
              Object.values(INSURANCE_TYPE).map(
                insurance => insurance.description,
              )
            }"
            .error="${this.errors.insuranceTypeCode}"
            .value="${this.state.insuranceTypeCode}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              !isMedicareSecondary(
                this.state.payerPlan.financialClass,
                this.state.defaultLevel,
              )
            }"
            wrapText
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.phoneField.id}"
            class="column-one"
            name="planInfo.phone"
            label="Phone Number"
            helper=" "
            maxLength="14"
            .mask="${phone}"
            .value="${this.state.planInfo.phone}"
            .error="${this.errors.planInfo.phone}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.extensionField.id}"
            name="planInfo.extension"
            label="Extension"
            helper=" "
            inputMode="numeric"
            maxLength="9"
            .error="${this.errors.planInfo.extension}"
            .value="${this.state.planInfo.extension}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.websiteField.id}"
            name="planInfo.website"
            label="Website"
            helper=" "
            maxLength="255"
            .error="${this.errors.planInfo.website}"
            .value="${this.state.planInfo.website}"
            .onChange="${this.handlers.change}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }

  __renderPatientCardItem(model) {
    const patientModel = formatPersonForPatientCard(model);

    return html`
      <neb-patient-card .model="${patientModel}" hidePhoto></neb-patient-card>
    `;
  }

  __renderPolicyHolderSection() {
    return html`
      <div class="fields-container">
        <div class="col-fields">
          <div class="combo-field">
            <neb-textfield
              id="${ELEMENTS.memberIdentifierField.id}"
              class="flex-three"
              name="planInfo.memberIdentifier"
              label="Insured ID or Policy #"
              helper="Required"
              maxLength="50"
              .error="${this.errors.planInfo.memberIdentifier}"
              .value="${this.state.planInfo.memberIdentifier}"
              .onChange="${this.handlers.change}"
            ></neb-textfield>

            <neb-tooltip
              id="${ELEMENTS.toolTip.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <div id="${ELEMENTS.toolTipText.id}" slot="tooltip">
                Enter the insured's policy # or member ID that identifies the
                policyholder.
              </div>
            </neb-tooltip>
          </div>

          ${
            isWorkersComp(this.state.payerPlan)
              ? html`
                  <neb-textfield
                    id="${ELEMENTS.policyHolderInsuredNameField.id}"
                    class="column-one-three"
                    name="policyHolder.insuredName"
                    label="Insured Name"
                    helper="Required"
                    maxLength="255"
                    .error="${this.errors.policyHolder.insuredName}"
                    .value="${this.state.policyHolder.insuredName}"
                    .onChange="${this.handlers.change}"
                  ></neb-textfield>
                `
              : ''
          }

          <neb-select
            id="${ELEMENTS.policyHolderRelationDropdown.id}"
            class="column-one"
            name="insuredRelationship"
            label="Policyholder Relation to Patient"
            helper=" "
            .items="${POLICY_HOLDER_ITEMS}"
            .error="${this.errors.insuredRelationship}"
            .value="${this.state.insuredRelationship}"
            .onChange="${this.handlers.changeInsuredRelationship}"
          ></neb-select>

          <neb-select-search
            id="${ELEMENTS.personSearchField.id}"
            class="column-two-three search"
            emptyMessage="${NO_RESULTS_FOUND}"
            label="Search for an existing patient or guarantor"
            .items="${this.__searchResults}"
            .value="${
              this.__selectedEntity
                ? addLabelToEntity(this.__selectedEntity)
                : ''
            }"
            .itemHeight="${84}"
            .showMenu="${this.__showResults}"
            .onChange="${this.handlers.selectEntity}"
            .onSearch="${this.handlers.searchPerson}"
            .onRenderItem="${this.__renderPatientCardItem}"
            .disabled="${isSelfSelected(this.state.insuredRelationship)}"
            showSearch
          ></neb-select-search>

          <neb-textfield
            id="${ELEMENTS.firstNameField.id}"
            name="policyHolder.firstName"
            label="First Name"
            helper="${getRequiredText(!isWorkersComp(this.state.payerPlan))}"
            maxLength="255"
            .error="${this.errors.policyHolder.firstName}"
            .value="${this.state.policyHolder.firstName}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              shouldDisablePolicyHolderField(
                this.state.insuredRelationship,
                this.__selectedEntity,
                this.state.payerPlan,
              )
            }"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.middleNameField.id}"
            name="policyHolder.middleName"
            label="Middle Name"
            helper=" "
            maxLength="255"
            .value="${this.state.policyHolder.middleName}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              shouldDisablePolicyHolderField(
                this.state.insuredRelationship,
                this.__selectedEntity,
                this.state.payerPlan,
              )
            }"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.lastNameField.id}"
            name="policyHolder.lastName"
            label="Last Name"
            helper="${getRequiredText(!isWorkersComp(this.state.payerPlan))}"
            maxLength="255"
            .error="${this.errors.policyHolder.lastName}"
            .value="${this.state.policyHolder.lastName}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              shouldDisablePolicyHolderField(
                this.state.insuredRelationship,
                this.__selectedEntity,
                this.state.payerPlan,
              )
            }"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.suffixField.id}"
            name="policyHolder.suffix"
            label="Suffix"
            helper=" "
            maxLength="10"
            .value="${this.state.policyHolder.suffix}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              shouldDisablePolicyHolderField(
                this.state.insuredRelationship,
                this.__selectedEntity,
                this.state.payerPlan,
              )
            }"
          ></neb-textfield>

          <neb-date-picker
            id="${ELEMENTS.dobPicker.id}"
            name="policyHolder.dateOfBirth"
            class="date-picker"
            label="Date of Birth"
            placeholder="Select Date"
            manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            .isDateSelectable="${removeFutureDates}"
            .onChange="${this.handlers.change}"
            .selectedDate="${
              this.state.policyHolder.dateOfBirth
                ? moment(this.state.policyHolder.dateOfBirth)
                : null
            }"
            ?disabled="${
              shouldDisablePolicyHolderField(
                this.state.insuredRelationship,
                this.__selectedEntity,
                this.state.payerPlan,
              )
            }"
            momentFlag
          ></neb-date-picker>

          <neb-select
            id="${ELEMENTS.sexDropdown.id}"
            name="policyHolder.sex"
            label="Sex"
            helper=" "
            .items="${SEX}"
            .value="${this.state.policyHolder.sex}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              shouldDisablePolicyHolderField(
                this.state.insuredRelationship,
                this.__selectedEntity,
                this.state.payerPlan,
              )
            }"
          ></neb-select>
        </div>
      </div>
    `;
  }

  __renderFormStep1() {
    return html`
      <div class="tab-container">
        <div class="top-title-container">
          <div class="title">Plan Information</div>
          <div class="description">
            Enter plan information to begin building your patient's insurance
            record.
          </div>
        </div>

        ${this.__renderPlanInfoSection()}

        <div class="bottom-title-container">
          <div class="title">Policyholder Information</div>
          <div class="description">
            Enter policyholder details; if the policyholder is another patient,
            conduct a search.
          </div>
        </div>

        ${this.__renderPolicyHolderSection()}

        <div class="bottom-title-container">
          <div class="title">Eligibility</div>
          <div class="description">
            Enter eligibility information to keep track of patient coverage
            dates.
          </div>
        </div>

        ${this.__renderEligibilitySection()}
      </div>
    `;
  }

  __renderResetDateField() {
    return html`
      <div class="combo-field">
        <neb-date-picker
          id="${ELEMENTS.resetDateField.id}"
          name="planInfo.resetDate"
          class="width-hundred"
          label="Reset Date"
          placeholder="Reset Date"
          manualPopoverPosition="${POPOVER_POSITION.CENTER}"
          .isDateSelectable="${this.handlers.resetDateSelectable}"
          .onChange="${this.handlers.changeResetDate}"
          .selectedDate="${
            this.state.planInfo.resetDate
              ? moment.utc(this.state.planInfo.resetDate).endOf('day')
              : null
          }"
          momentFlag
        ></neb-date-picker>

        <neb-tooltip
          id="${ELEMENTS.resetDateTooltip.id}"
          class="tooltip"
          defaultAnchor="right"
        >
          <div id="${ELEMENTS.resetDateTooltipText.id}" slot="tooltip">
            Enter the reset date for this plan. This value can be used to
            determine when the deductible, max out pocket and annual visit
            limits are reset.
          </div>
        </neb-tooltip>
      </div>
    `;
  }

  __renderEligibilitySection() {
    return html`
      <div class="fields-container bottom-padding">
        <div class="col-fields">
          <div class="combo-field">
            <neb-date-picker
              id="${ELEMENTS.startDateField.id}"
              name="planInfo.start"
              class="width-hundred"
              label="Start Date"
              placeholder="Start Date"
              manualPopoverPosition="${POPOVER_POSITION.CENTER}"
              .onChange="${this.handlers.changeStartDate}"
              .isDateSelectable="${this.handlers.startDateSelectable}"
              .selectedDate="${
                this.state.planInfo.start
                  ? parseDate(this.state.planInfo.start).startOf('day')
                  : null
              }"
              momentFlag
            ></neb-date-picker>

            <neb-tooltip
              id="${ELEMENTS.startDateTooltip.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <div id="${ELEMENTS.startDateTooltipText.id}" slot="tooltip">
                Enter date coverage terms start for this coverage period. This
                value can be used to determine copay and deductible collection.
              </div>
            </neb-tooltip>
          </div>

          <div class="combo-field">
            <neb-date-picker
              id="${ELEMENTS.termDateField.id}"
              name="planInfo.end"
              class="width-hundred"
              label="Term Date"
              placeholder="Term Date"
              manualPopoverPosition="${POPOVER_POSITION.CENTER}"
              .onChange="${this.handlers.changeEndDate}"
              .isDateSelectable="${this.handlers.endDateSelectable}"
              .selectedDate="${
                this.state.planInfo.end
                  ? parseDate(this.state.planInfo.end).endOf('day')
                  : null
              }"
              momentFlag
            ></neb-date-picker>

            <neb-tooltip
              id="${ELEMENTS.termDateTooltip.id}"
              class="tooltip"
              defaultAnchor="right"
            >
              <div id="${ELEMENTS.termDateTooltipText.id}" slot="tooltip">
                Enter date coverage terms end for this coverage period. This
                value can be used to determine copay and deductible collection.
              </div>
            </neb-tooltip>
          </div>
          ${this.__renderResetDateField()}
        </div>
      </div>
    `;
  }

  __renderDeductibleInNetwork() {
    return html`
      <div class="column-one labels">In Network</div>
      <neb-textfield
        id="${ELEMENTS.deductiblesInNetworkAmount.id}"
        name="deductibles.0.inNetworkAmount"
        class="column-two"
        label="Amount"
        maxLength="13"
        helper="${
          getRequiredText(
            this.state.deductibles[0].coverageLevel.code !== 'NA' &&
              this.state.deductibles[0].outOfNetworkAmount === '',
          )
        }"
        .trailingIcon="${
          this.state.deductibles[0].inNetworkAmount ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.deductibles[0].inNetworkAmount}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.deductibles[0].inNetworkAmount}"
        ?disabled="${this.state.deductibles[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.deductiblesInNetworkRemaining.id}"
        name="deductibles.0.inNetworkRemaining"
        class="column-three"
        label="Remaining"
        maxLength="13"
        helper=" "
        .trailingIcon="${
          this.state.deductibles[0].inNetworkRemaining ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.deductibles[0].inNetworkRemaining}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.deductibles[0].inNetworkRemaining}"
        ?disabled="${this.state.deductibles[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-date-picker
        id="${ELEMENTS.deductiblesInNetworkStart.id}"
        class="column-four date-picker"
        name="deductibles.0.inNetworkStart"
        placeholder="Date${
          this.errors.deductibles[0].inNetworkStart ? '*' : ''
        }"
        label="As of"
        momentFlag
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${this.state.deductibles[0].inNetworkStart}"
        .invalidText="${this.errors.deductibles[0].inNetworkStart}"
        .onChange="${this.handlers.changePage3}"
        ?invalid="${!!this.errors.deductibles[0].inNetworkStart}"
        ?disabled="${this.state.deductibles[0].coverageLevel.code === 'NA'}"
      ></neb-date-picker>
    `;
  }

  __renderDeductibleOutOfNetwork() {
    return html`
      <div class="column-one labels">Out of Network</div>
      <neb-textfield
        id="${ELEMENTS.deductiblesOutOfNetworkAmount.id}"
        name="deductibles.0.outOfNetworkAmount"
        class="column-two"
        label="Amount"
        maxLength="13"
        helper="${
          getRequiredText(
            this.state.deductibles[0].coverageLevel.code !== 'NA' &&
              this.state.deductibles[0].inNetworkAmount === '',
          )
        }"
        .trailingIcon="${
          this.state.deductibles[0].outOfNetworkAmount ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.deductibles[0].outOfNetworkAmount}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.deductibles[0].outOfNetworkAmount}"
        ?disabled="${this.state.deductibles[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.deductiblesOutOfNetworkRemaining.id}"
        name="deductibles.0.outOfNetworkRemaining"
        class="column-three"
        label="Remaining"
        maxLength="13"
        helper=" "
        .trailingIcon="${
          this.state.deductibles[0].outOfNetworkRemaining ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.deductibles[0].outOfNetworkRemaining}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.deductibles[0].outOfNetworkRemaining}"
        ?disabled="${this.state.deductibles[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-date-picker
        id="${ELEMENTS.deductiblesOutOfNetworkStart.id}"
        class="column-four date-picker"
        name="deductibles.0.outOfNetworkStart"
        placeholder="Date${
          this.errors.deductibles[0].outOfNetworkStart ? '*' : ''
        }"
        label="As of"
        momentFlag
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${this.state.deductibles[0].outOfNetworkStart}"
        .invalidText="${this.errors.deductibles[0].outOfNetworkStart}"
        .onChange="${this.handlers.changePage3}"
        ?invalid="${!!this.errors.deductibles[0].outOfNetworkStart}"
        ?disabled="${this.state.deductibles[0].coverageLevel.code === 'NA'}"
      ></neb-date-picker>
    `;
  }

  __renderMaxOutOfPocketInNetwork() {
    return html`
      <div class="column-one labels">In Network</div>
      <neb-textfield
        id="${ELEMENTS.outOfPocketsInNetworkAmount.id}"
        name="outOfPockets.0.inNetworkAmount"
        class="column-two"
        label="Amount"
        maxLength="13"
        helper="${
          getRequiredText(
            this.state.outOfPockets[0].coverageLevel.code !== 'NA' &&
              this.state.outOfPockets[0].outOfNetworkAmount === '',
          )
        }"
        .trailingIcon="${
          this.state.outOfPockets[0].inNetworkAmount ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.outOfPockets[0].inNetworkAmount}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.outOfPockets[0].inNetworkAmount}"
        ?disabled="${this.state.outOfPockets[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.outOfPocketsInNetworkRemaining.id}"
        name="outOfPockets.0.inNetworkRemaining"
        class="column-three"
        label="Remaining"
        maxLength="13"
        helper=" "
        .trailingIcon="${
          this.state.outOfPockets[0].inNetworkRemaining ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.outOfPockets[0].inNetworkRemaining}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.outOfPockets[0].inNetworkRemaining}"
        ?disabled="${this.state.outOfPockets[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-date-picker
        id="${ELEMENTS.outOfPocketsInNetworkStart.id}"
        class="column-four date-picker"
        name="outOfPockets.0.inNetworkStart"
        placeholder="Date${
          this.errors.outOfPockets[0].inNetworkStart ? '*' : ''
        }"
        label="As of"
        momentFlag
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${this.state.outOfPockets[0].inNetworkStart}"
        .invalidText="${this.errors.outOfPockets[0].inNetworkStart}"
        .onChange="${this.handlers.changePage3}"
        ?invalid="${!!this.errors.outOfPockets[0].inNetworkStart}"
        ?disabled="${this.state.outOfPockets[0].coverageLevel.code === 'NA'}"
      ></neb-date-picker>
    `;
  }

  __renderMaxOutOfPocketOutOfNetwork() {
    return html`
      <div class="column-one labels">Out of Network</div>
      <neb-textfield
        id="${ELEMENTS.outOfPocketsOutOfNetworkAmount.id}"
        name="outOfPockets.0.outOfNetworkAmount"
        class="column-two"
        label="Amount"
        maxLength="13"
        helper="${
          getRequiredText(
            this.state.outOfPockets[0].coverageLevel.code !== 'NA' &&
              this.state.outOfPockets[0].inNetworkAmount === '',
          )
        }"
        .trailingIcon="${
          this.state.outOfPockets[0].outOfNetworkAmount ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.outOfPockets[0].outOfNetworkAmount}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.outOfPockets[0].outOfNetworkAmount}"
        ?disabled="${this.state.outOfPockets[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-textfield
        id="${ELEMENTS.outOfPocketsOutOfNetworkRemaining.id}"
        name="outOfPockets.0.outOfNetworkRemaining"
        class="column-three"
        label="Remaining"
        maxLength="13"
        helper=" "
        .trailingIcon="${
          this.state.outOfPockets[0].outOfNetworkRemaining ? 'neb:clear' : ''
        }"
        .mask="${currency}"
        .inputMode="${'numeric'}"
        .value="${this.state.outOfPockets[0].outOfNetworkRemaining}"
        .onChange="${this.handlers.changePage3}"
        .onClickIcon="${this.handlers.textClearPage3}"
        .error="${this.errors.outOfPockets[0].outOfNetworkRemaining}"
        ?disabled="${this.state.outOfPockets[0].coverageLevel.code === 'NA'}"
      ></neb-textfield>

      <neb-date-picker
        id="${ELEMENTS.outOfPocketsOutOfNetworkStart.id}"
        class="column-four date-picker"
        name="outOfPockets.0.outOfNetworkStart"
        placeholder="Date${
          this.errors.outOfPockets[0].outOfNetworkStart ? '*' : ''
        }"
        label="As of"
        momentFlag
        manualPopoverPosition="${POPOVER_POSITION.CENTER}"
        .selectedDate="${this.state.outOfPockets[0].outOfNetworkStart}"
        .invalidText="${this.errors.outOfPockets[0].outOfNetworkStart}"
        .onChange="${this.handlers.changePage3}"
        ?invalid="${!!this.errors.outOfPockets[0].outOfNetworkStart}"
        ?disabled="${this.state.outOfPockets[0].coverageLevel.code === 'NA'}"
      ></neb-date-picker>
    `;
  }

  __renderDeductible() {
    return html`
      <div class="fields-container">
        <div class="col-fields-two">
          <neb-select
            id="${ELEMENTS.deductiblesCoverageLevelDropdown.id}"
            class="column-one"
            name="deductibles.0.coverageLevel"
            label="Coverage Level"
            helper=" "
            .items="${mapCoverageLevelValueSimplified(0)}"
            .value="${
              mapCoverageLevelDisplayValue(
                this.state.deductibles[0].coverageLevel,
              )
            }"
            .onChange="${this.handlers.changeDeductiblesCoverageLevel}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.deductiblesServiceTypesMultiselect.id}"
            class="column-two"
            name="deductibles.0.serviceTypes"
            label="Service Types"
            helper="${
              getRequiredText(
                this.state.deductibles[0].coverageLevel.code !== 'NA',
              )
            }"
            .value="${
              mapSelectedServiceTypesDisplay(
                getSelectedServiceTypes(
                  this.__serviceTypes,
                  this.state.deductibles[0].serviceTypes,
                ),
              )
            }"
            .items="${mapSelectedServiceTypesDisplay(this.__serviceTypes)}"
            .onChange="${this.handlers.multiSelectChange}"
            multiSelect
            .error="${this.errors.deductibles[0].serviceTypes}"
            allLabel="Service Types"
            ?disabled="${this.state.deductibles[0].coverageLevel.code === 'NA'}"
          ></neb-select>
        </div>
        <div class="col-fields-four page-3-spacer">
          ${this.__renderDeductibleInNetwork()}
        </div>

        <div class="col-fields-four">
          ${this.__renderDeductibleOutOfNetwork()}
        </div>
      </div>
    `;
  }

  __renderMaxOutOfPocket() {
    return html`
      <div class="fields-container">
        <div class="col-fields-two">
          <neb-select
            id="${ELEMENTS.outOfPocketsCoverageLevelDropdown.id}"
            class="column-one"
            name="outOfPockets.0.coverageLevel"
            label="Coverage Level"
            helper=" "
            .items="${mapCoverageLevelValueSimplified(0)}"
            .value="${
              mapCoverageLevelDisplayValue(
                this.state.outOfPockets[0].coverageLevel,
              )
            }"
            .onChange="${this.handlers.changeOutOfPocketsCoverageLevel}"
          ></neb-select>
        </div>
        <div class="col-fields-four page-3-spacer">
          ${this.__renderMaxOutOfPocketInNetwork()}
        </div>

        <div class="col-fields-four">
          ${this.__renderMaxOutOfPocketOutOfNetwork()}
        </div>
      </div>
    `;
  }

  __renderMaxVisitLimit() {
    return html`
      <div class="fields-container">
        <div class="col-fields bottom-padding">
          <neb-textfield
            id="${ELEMENTS.maxVisits.id}"
            name="patientInsuranceVisitLimit.maxVisits"
            class="column-one"
            label="${ELEMENTS.maxVisits.label}"
            maxLength="3"
            helper=" "
            .mask="${numberNoLeadingZeroAllowZero}"
            .inputMode="${'numeric'}"
            .value="${String(this.state.patientInsuranceVisitLimit.maxVisits)}"
            .error="${this.errors.patientInsuranceVisitLimit.maxVisits}"
            .onChange="${this.handlers.visitLimitChange}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.renderedOutsidePractice.id}"
            name="patientInsuranceVisitLimit.visitsRenderedOutOfOffice"
            class="column-two"
            label="${ELEMENTS.renderedOutsidePractice.label}"
            maxLength="3"
            helper=" "
            .inputMode="${'numeric'}"
            .error="${
              this.errors.patientInsuranceVisitLimit.visitsRenderedOutOfOffice
            }"
            .mask="${numberNoLeadingZeroAllowZero}"
            .value="${
              String(
                this.state.patientInsuranceVisitLimit.visitsRenderedOutOfOffice,
              )
            }"
            .onChange="${this.handlers.visitLimitChange}"
          ></neb-textfield>

          <neb-date-picker
            id="${ELEMENTS.visitLimitAsOf.id}"
            class="column-three date-picker"
            name="patientInsuranceVisitLimit.asOf"
            label="As of"
            manualPopoverPosition="${POPOVER_POSITION.CENTER}"
            .selectedDate="${
              this.getVisitLimit().asOf
                ? moment(this.getVisitLimit().asOf).utc()
                : null
            }"
            .isDateSelectable="${removeFutureDates}"
            .onChange="${this.handlers.visitLimitChange}"
            .invalidText="${this.errors.patientInsuranceVisitLimit.asOf}"
            ?invalid="${!!this.errors.patientInsuranceVisitLimit.asOf}"
            momentFlag
          ></neb-date-picker>
        </div>
      </div>
    `;
  }

  __renderFormStep2() {
    return html`
      <div class="tab-container">
        <div class="top-title-container">
          <div class="title">Copay</div>
          <div class="description">
            Add copay details. This is the amount the patient will pay for
            covered services after the deductible is met.
          </div>
        </div>
        <div class="fields-container">
          <div class="col-fields-two">
            <neb-select
              id="${ELEMENTS.copayCoverageLevelDropdown.id}"
              class="column-one"
              name="copays.0.coverageLevel"
              label="Coverage Level"
              helper=" "
              .items="${mapCoverageLevelValueSimplified(0)}"
              .value="${
                mapCoverageLevelDisplayValue(this.state.copays[0].coverageLevel)
              }"
              .onChange="${this.handlers.changeCopayCoverageLevel}"
            ></neb-select>

            <neb-select
              id="${ELEMENTS.copayServiceTypesMultiselect.id}"
              class="column-two"
              name="copays.0.serviceTypes"
              label="Service Types"
              helper="${
                getRequiredText(
                  this.state.copays[0].coverageLevel.code !== 'NA',
                )
              }"
              .value="${
                mapSelectedServiceTypesDisplay(
                  getSelectedServiceTypes(
                    this.__serviceTypes,
                    this.state.copays[0].serviceTypes,
                  ),
                )
              }"
              .items="${mapSelectedServiceTypesDisplay(this.__serviceTypes)}"
              .onChange="${this.handlers.multiSelectChange}"
              multiSelect
              .error="${this.errors.copays[0].serviceTypes}"
              allLabel="Service Types"
              ?disabled="${this.state.copays[0].coverageLevel.code === 'NA'}"
            ></neb-select>

            <neb-textfield
              id="${ELEMENTS.copayInNetworkField.id}"
              name="copays.0.inNetworkAmount"
              class="column-one"
              label="In Network"
              maxLength="13"
              helper="${
                getRequiredText(
                  this.state.copays[0].coverageLevel.code !== 'NA' &&
                    this.state.copays[0].outOfNetworkAmount === '',
                )
              }"
              .trailingIcon="${
                this.state.copays[0].inNetworkAmount ? 'neb:clear' : ''
              }"
              .mask="${currency}"
              .inputMode="${'numeric'}"
              .value="${this.state.copays[0].inNetworkAmount}"
              .onChange="${this.handlers.copayTextChange}"
              .onClickIcon="${this.handlers.inNetworkTextClear}"
              .error="${this.errors.copays[0].inNetworkAmount}"
              ?disabled="${this.state.copays[0].coverageLevel.code === 'NA'}"
            ></neb-textfield>

            <neb-textfield
              id="${ELEMENTS.copayOutOfNetworkField.id}"
              name="copays.0.outOfNetworkAmount"
              class="column-two"
              label="Out of Network"
              maxLength="13"
              helper="${
                getRequiredText(
                  this.state.copays[0].coverageLevel.code !== 'NA' &&
                    this.state.copays[0].inNetworkAmount === '',
                )
              }"
              .trailingIcon="${
                this.state.copays[0].outOfNetworkAmount ? 'neb:clear' : ''
              }"
              .mask="${currency}"
              .inputMode="${'numeric'}"
              .value="${this.state.copays[0].outOfNetworkAmount}"
              .onChange="${this.handlers.copayTextChange}"
              .onClickIcon="${this.handlers.outOfNetworkTextClear}"
              .error="${this.errors.copays[0].outOfNetworkAmount}"
              ?disabled="${this.state.copays[0].coverageLevel.code === 'NA'}"
            ></neb-textfield>
          </div>
        </div>
        <div class="bottom-title-container">
          <div class="title">Coinsurance</div>
          <div class="description">
            Add coinsurance details. This is the percentage the patient will pay
            for covered services after the deductible is met.
          </div>
        </div>
        <div class="fields-container">
          <div class="col-fields-two">
            <neb-select
              id="${ELEMENTS.coinsCoverageLevelDropdown.id}"
              class="column-one"
              name="coinsurances.0.coverageLevel"
              label="Coverage Level"
              helper=" "
              .items="${mapCoverageLevelValueSimplified(0)}"
              .value="${
                mapCoverageLevelDisplayValue(
                  this.state.coinsurances[0].coverageLevel,
                )
              }"
              .onChange="${this.handlers.changeCoinsCoverageLevel}"
            ></neb-select>

            <neb-select
              id="${ELEMENTS.coinsServiceTypesMultiselect.id}"
              class="column-two"
              name="coinsurances.0.serviceTypes"
              label="Service Types"
              helper="${
                getRequiredText(
                  this.state.coinsurances[0].coverageLevel.code !== 'NA',
                )
              }"
              .value="${
                mapSelectedServiceTypesDisplay(
                  getSelectedServiceTypes(
                    this.__serviceTypes,
                    this.state.coinsurances[0].serviceTypes,
                  ),
                )
              }"
              .items="${mapSelectedServiceTypesDisplay(this.__serviceTypes)}"
              .onChange="${this.handlers.multiSelectChange}"
              multiSelect
              .error="${this.errors.coinsurances[0].serviceTypes}"
              allLabel="Service Types"
              ?disabled="${
                this.state.coinsurances[0].coverageLevel.code === 'NA'
              }"
            ></neb-select>

            <div class="column-one combo-field">
              <neb-textfield
                id="${ELEMENTS.coinsInNetworkField.id}"
                name="coinsurances.0.inNetworkPercent"
                class="text-percent"
                label="In Network Patient %"
                helper="${
                  getRequiredText(
                    this.state.coinsurances[0].coverageLevel.code !== 'NA' &&
                      this.state.coinsurances[0].outOfNetworkPercent === '',
                  )
                }"
                maxLength="3"
                .mask="${numberNoLeadingZeroAllowZero}"
                .inputMode="${'numeric'}"
                .value="${this.state.coinsurances[0].inNetworkPercent}"
                .onChange="${this.handlers.coinsTextChange}"
                .error="${this.errors.coinsurances[0].inNetworkPercent}"
                ?disabled="${
                  this.state.coinsurances[0].coverageLevel.code === 'NA'
                }"
              ></neb-textfield>
              <div class="percent">%</div>
            </div>
            <div class="column-two combo-field">
              <neb-textfield
                id="${ELEMENTS.coinsOutOfNetworkField.id}"
                name="coinsurances.0.outOfNetworkPercent"
                class="text-percent"
                label="Out of Network Patient %"
                helper="${
                  getRequiredText(
                    this.state.coinsurances[0].coverageLevel.code !== 'NA' &&
                      this.state.coinsurances[0].inNetworkPercent === '',
                  )
                }"
                maxLength="3"
                .mask="${numberNoLeadingZeroAllowZero}"
                .inputMode="${'numeric'}"
                .value="${this.state.coinsurances[0].outOfNetworkPercent}"
                .onChange="${this.handlers.coinsTextChange}"
                .error="${this.errors.coinsurances[0].outOfNetworkPercent}"
                ?disabled="${
                  this.state.coinsurances[0].coverageLevel.code === 'NA'
                }"
              ></neb-textfield>
              <div class="percent">%</div>
            </div>
            <br />
          </div>
        </div>
      </div>
    `;
  }

  __renderFormStep3() {
    return html`
      <div class="tab-container">
        <div class="top-title-container">
          <div class="title">Deductible</div>
          <div class="description">
          Add deductible details. This is the amount the patient will pay before the insurance plan will begin to pay for covered services.
          </div>
        </div>
        ${this.__renderDeductible()}

        <div class="bottom-title-container">
         
            <div class="title">Max Out of Pocket</div>
            <div class="description">
            Add max out of pocket details. This is the maximum amount the patient will pay before the insurance plan pays for 100% of covered services.
            </div>
          </div>
         
          ${this.__renderMaxOutOfPocket()}

          <div class="bottom-title-container ">
            <div class="title">Visit Limits</div>
            <div class="description">
            Add additional detail regarding visit limits to be alerted when a patient is nearing their annual limit.
            </div>
          </div>

          ${this.__renderMaxVisitLimit()}
        </div>
      </div>
    `;
  }

  __renderProgressForm() {
    if (this.__formProgressState === 1) return this.__renderFormStep1();
    if (this.__formProgressState === 2) return this.__renderFormStep2();

    return this.__renderFormStep3();
  }

  renderActionBar() {
    return html`
      <neb-action-bar-paged
        id="${ELEMENTS.actionBar.id}"
        confirmLabel="${this.__proceedLabel()}"
        backLabel="${this.__backLabel()}"
        .onConfirm="${this.handlers.proceed}"
        .onBack="${this.handlers.back}"
        .onCancel="${this.handlers.cancel}"
      ></neb-action-bar-paged>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderProgressForm()}
    `;
  }

  render() {
    return html`
      <div class="container">
        <neb-progress-tracker
          id="${ELEMENTS.progressTracker.id}"
          class="progress-tracker"
          .items="${PROGRESS_ITEMS}"
          .step="${this.__formProgressState}"
          .layout="${this.layout}"
        ></neb-progress-tracker>
        ${this.renderContent()} ${this.renderFooter()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-form-patient-insurance-add',
  NebFormPatientInsuranceAdd,
);
