import '../../../../packages/neb-lit-components/src/components/neb-button';
import '../../tables/ledger/neb-table-running-ledger';

import { css, html, LitElement } from 'lit';

import { getPaymentDetail } from '../../../../packages/neb-api-client/src/payments-api-client';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_SPACING,
  CSS_BORDER_GREY_2,
  CSS_COLOR_GREY_10,
} from '../../../../packages/neb-styles/neb-variables';
import { parseDate } from '../../../../packages/neb-utils/date-util';
import { LINE_ITEM_TYPE } from '../../../../packages/neb-utils/neb-ledger-util';
import {
  NEBULA_REFRESH_EVENT,
  REFRESH_CHANGE_TYPE,
  sendRefreshNotification,
} from '../../../../packages/neb-utils/neb-refresh';
import { MODE } from '../../../../packages/neb-utils/table';
import { getRunningLedgerItems } from '../../../api-clients/running-ledger';
import {
  addRunningBalance,
  formatLedgerItemsToTableModel,
  formatTotals,
  LINE_ITEM_TYPE_LABEL,
  sortRunningLedger,
} from '../../../formatters/running-ledger';
import { navigateToRow } from '../../../utils/navigation-util';
import { openPayment } from '../../../utils/payment-util';

export const ELEMENTS = {
  runningLedgerTable: { id: 'running-ledger-table' },
  pagination: { id: 'pagination' },
};

const EMPTY_MESSAGE = 'There is no activity for this patient.';

const CONFIG = [
  {
    key: 'payer',
    label: 'Payer/Guarantor',
    flex: css`3 0 120px`,
    truncate: true,
  },
  {
    key: 'billed',
    label: 'Billed',
    flex: css`1 0 55px`,
  },
  {
    key: 'hold',
    label: 'Hold',
    flex: css`1 0 65px`,
    truncate: true,
  },
  {
    key: 'dateOfService',
    label: 'Date',
    flex: css`1 0 80px`,
    sortable: true,
  },
  {
    key: 'type',
    label: 'Type',
    flex: css`1 0 60px`,
    truncate: true,
  },
  {
    key: 'code',
    label: 'Code',
    flex: css`3 0 60px`,
    truncate: true,
  },
  {
    key: 'debit',
    label: 'Debit',
    flex: css`1 0 80px`,
  },
  {
    key: 'credit',
    label: 'Credit',
    flex: css`1 0 80px`,
  },
  {
    key: 'adjustment',
    label: 'Adj.',
    flex: css`1 0 80px`,
  },
  {
    key: 'tax',
    label: 'Tax',
    flex: css`1 0 80px`,
  },
  {
    key: 'balance',
    label: 'Balance',
    flex: css`1 0 80px`,
  },
];

const DATE_FORMAT = 'YYYY-MM-DD';

class NebPageRunningLedger extends LitElement {
  static get properties() {
    return {
      patient: Object,
      pageSize: Number,
      __currentPage: Number,
      __ledgerItems: Array,
      __sortParams: Array,
      __emptyMessage: String,
    };
  }

  static get styles() {
    return css`
      .pagination-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        position: sticky;
        bottom: -${CSS_SPACING};

        width: 100%;
        box-sizing: border-box;
        padding: 10px ${CSS_SPACING};
        background-color: ${CSS_COLOR_GREY_10};
        border-top: ${CSS_BORDER_GREY_2};
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.patient = { id: '', name: '' };
    this.pageSize = 100;
    this.__currentPage = 0;
    this.__ledgerItems = [];
    this.__selectableDatesCache = null;
    this.__totals = {};
    this.__emptyMessage = EMPTY_MESSAGE;
    this.__sortParams = [
      {
        key: 'dateOfService',
        dir: 'desc',
      },
    ];
  }

  __initHandlers() {
    this.__handlers = {
      // eslint-disable-next-line complexity
      selectItemRow: async (_, item) => {
        const { overlay, options } = this.__chooseOverlay(item);

        let updated;

        if (overlay === OVERLAY_KEYS.PAYMENT_DETAIL) {
          const payment = await getPaymentDetail(item.paymentId);
          updated = await openPayment({ payment, readonly: false });
        } else {
          updated = await openOverlay(overlay, options);
        }

        if (
          overlay === OVERLAY_KEYS.PAYMENT_DETAIL ||
          overlay === OVERLAY_KEYS.PAYMENT_DETAIL_2 ||
          overlay === OVERLAY_KEYS.DISCOUNT
        ) {
          sendRefreshNotification();
        }

        if (
          updated ||
          overlay === OVERLAY_KEYS.PAYMENT_DETAIL ||
          overlay === OVERLAY_KEYS.PAYMENT_DETAIL_2 ||
          overlay === OVERLAY_KEYS.DISCOUNT ||
          overlay === OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES ||
          overlay === OVERLAY_KEYS.LEDGER_LINE_ITEM
        ) {
          await this.__fetchLedgerItems();
        }
      },

      refresh: async ({ detail: { changed } }) => {
        if (changed.includes(REFRESH_CHANGE_TYPE.LEDGER)) {
          await this.__fetchLedgerItems();
        }
      },

      refreshLedgerItems: () => this.__fetchLedgerItems(),

      pageChanged: index => {
        this.__currentPage = index;
        this.updateComplete.then(() =>
          this.scrollIntoView({ behavior: 'smooth' }),
        );
      },

      sort: (_, updatedParams) => {
        this.__sortParams = updatedParams;
        this.__ledgerItems = [...this.__ledgerItems].reverse();
      },

      isDateSelectable: date => {
        if (!this.__selectableDatesCache) {
          this.__selectableDatesCache = new Set();
          this.__ledgerItems.forEach(({ dateOfService, transactionDate }) => {
            this.__selectableDatesCache.add(
              parseDate(dateOfService || transactionDate).format(DATE_FORMAT),
            );
          });
        }

        return this.__selectableDatesCache.has(date.format(DATE_FORMAT));
      },

      searchDate: date => {
        const ledgerItemIndex = this.__ledgerItems.findIndex(
          ({ dateOfService, transactionDate }) =>
            parseDate(dateOfService || transactionDate).format(DATE_FORMAT) ===
            date.format(DATE_FORMAT),
        );

        const page = Math.floor(ledgerItemIndex / this.pageSize);
        const elementNumber = ledgerItemIndex - this.pageSize * page;
        this.__currentPage = page;

        return this.updateComplete.then(() => {
          const table = this.shadowRoot.getElementById(
            ELEMENTS.runningLedgerTable.id,
          );
          const row = table.shadowRoot.getElementById(`row-${elementNumber}`);
          navigateToRow(row);
        });
      },
    };
  }

  __chooseOverlay(item) {
    const id = item.paymentId || item.id;

    if (!item.paymentId) {
      switch (item.type) {
        case LINE_ITEM_TYPE_LABEL[LINE_ITEM_TYPE.PURCHASE]:
        case LINE_ITEM_TYPE_LABEL[LINE_ITEM_TYPE.FEE]:
          return {
            overlay: OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES,
            options: {
              patient: { id: this.patient.id },
              lineItemIds: [item.id],
              selectedIds: [item.id],
              closeOnSave: true,
            },
          };

        default: {
          const lineItems = this.__ledgerItems.filter(
            li => li.type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE,
          );

          return {
            overlay: OVERLAY_KEYS.LEDGER_LINE_ITEM,
            options: {
              id,
              patientId: this.patient.id,
              lineItems,
            },
          };
        }
      }
    }

    if (item.isDiscount) {
      return {
        overlay: OVERLAY_KEYS.DISCOUNT,
        options: {
          id,
          patientId: this.patient.id,
        },
      };
    }

    if (!item.isPayerPayment) {
      return {
        overlay: OVERLAY_KEYS.PAYMENT_DETAIL_2,
        options: {
          readonly: false,
          payment: {
            id,
            patientId: this.patient.id,
          },
        },
      };
    }

    return {
      overlay: OVERLAY_KEYS.PAYMENT_DETAIL,
      options: {
        readonly: false,
        payment: {
          id,
          patientId: this.patient.id,
        },
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('patient')) {
      this.__fetchLedgerItems();
    }
  }

  async __getRunningLedgerItems(patientId) {
    try {
      this.__emptyMessage = 'Loading...';
      const ledgerItems = await getRunningLedgerItems(patientId);
      this.__emptyMessage = EMPTY_MESSAGE;
      return ledgerItems;
    } catch (err) {
      console.error(err);
      this.__emptyMessage = 'An error occurred while loading.';
      return [];
    }
  }

  async refetchLedgerItems() {
    await this.__fetchLedgerItems();
  }

  async __fetchLedgerItems() {
    const patientId = this.patient.id;

    if (patientId) {
      this.__selectableDatesCache = null;

      const ledgerItems = await this.__getRunningLedgerItems(patientId);
      this.__totals = formatTotals(ledgerItems);

      const ledgerItemsSorted = sortRunningLedger(ledgerItems);
      this.__ledgerItems = addRunningBalance(ledgerItemsSorted);

      if (this.__sortParams[0].dir === 'asc') {
        this.__ledgerItems = [...this.__ledgerItems].reverse();
      }
    }
  }

  connectedCallback() {
    window.addEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
    super.connectedCallback();
  }

  disconnectedCallback() {
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.__handlers.refresh);
    super.disconnectedCallback();
  }

  get __pageBoundaries() {
    const start = this.__currentPage * this.pageSize;
    const end = Math.min(start + this.pageSize, this.__ledgerItems.length);
    return { start, end };
  }

  get __pageItems() {
    const { start, end } = this.__pageBoundaries;
    const items = this.__ledgerItems.slice(start, end);

    return formatLedgerItemsToTableModel(items, this.patient.name);
  }

  get __pageCount() {
    return Math.ceil(this.__ledgerItems.length / this.pageSize);
  }

  __renderPagination() {
    if (this.__ledgerItems.length <= this.pageSize) {
      return '';
    }

    return html`
      <div class="pagination-wrapper">
        <neb-pagination
          id="${ELEMENTS.pagination.id}"
          .currentPage="${this.__currentPage}"
          .onPageChanged="${this.__handlers.pageChanged}"
          .pageCount="${this.__pageCount}"
        ></neb-pagination>
      </div>
    `;
  }

  render() {
    return html`
      <neb-table-running-ledger
        id="${ELEMENTS.runningLedgerTable.id}"
        .emptyMessage="${this.__emptyMessage}"
        .totals="${this.__totals}"
        .model="${this.__pageItems}"
        .config="${CONFIG}"
        .onSelectRow="${this.__handlers.selectItemRow}"
        .onSort="${this.__handlers.sort}"
        .isDateSelectable="${this.__handlers.isDateSelectable}"
        .onSearchDate="${this.__handlers.searchDate}"
        .onRefresh="${this.__handlers.refreshLedgerItems}"
        .sortParams="${this.__sortParams}"
        .patientId="${this.patient.id}"
        mode="${MODE.DETAIL}"
      ></neb-table-running-ledger>

      ${this.__renderPagination()}
    `;
  }
}

customElements.define('neb-page-running-ledger', NebPageRunningLedger);
