import { matchRouteSwitch, navigate } from '@neb/router';
import { css, html, LitElement } from 'lit';

import '../../../packages/neb-lit-components/src/components/neb-calendar-picker';
import '../../../packages/neb-www-practice/src/components/scheduling/neb-scheduling-error-handling-page';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/controls/neb-menu-button';
import './neb-calendar-filtering';
import './neb-calendar-month-view';
import './neb-icon';
import './neb-provider-calendar-day-view';
import './neb-provider-calendar-week-view';
import './neb-room-calendar-day-view';
import './neb-room-calendar-filtering';
import './neb-room-calendar-week-view';

import {
  getCalendarSummary,
  getCalendarSummaryCondensed,
} from '../../../packages/neb-api-client/src/calendar-summary-api-client';
import * as settingsApiClient from '../../../packages/neb-api-client/src/settings-api-client';
import { APPOINTMENT_STATUS } from '../../../packages/neb-appointment/neb-appointments';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { SchedulingRequirementService } from '../../../packages/neb-redux/services/scheduling-requirement';
import {
  CALENDAR_TYPES,
  CALENDAR_TYPES_SWITCH_LABELS,
  formatSchedulingView,
  PERIOD_OPTIONS,
  SCHEDULER_VIEW_INTERVAL,
} from '../../../packages/neb-utils/calendar-resources-util';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { computeFullWeekRange } from '../../../packages/neb-utils/moment-flag-conversion';
import { getUnmatchedAppointments } from '../../api-clients/appointments';
import { UpdateNotificationService } from '../../services/update-notifications';
import {
  CSS_COLOR_ERROR,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_CAPTION,
  CSS_SPACING,
} from '../../styles';
import { clearContexts } from '../../utils/context/constants';
import { PollController } from '../../utils/poll-controller';
import { ANY } from '../../utils/update-notifications';
import { DAY, FULL_WEEK, MONTH, WORK_WEEK } from '../../utils/user-message';
import NebFormAppointment from '../forms/appointments/neb-form-appointment';

export const ELEMENTS = {
  header: { id: 'header' },
  calendarPicker: { id: 'calendar-picker' },
  viewContainer: { id: 'view-container' },
  viewDropdown: { id: 'view-dropdown' },
  todayLink: { id: 'today-link' },
  dayView: { id: 'day-view' },
  weekView: { id: 'week-view' },
  monthView: { id: 'month-view' },
  roomDayView: { id: 'room-day-view' },
  roomWeekView: { id: 'room-week-view' },
  calendarFiltering: { id: 'calendar-filtering' },
  roomsCalendarFiltering: { id: 'rooms-calendar-filtering' },
  addButton: { id: 'add-button' },
  unmatchedAppointmentsLink: { id: 'unmatched-appointments-link' },
  schedulingError: { id: 'scheduling-error' },
  statusesDropdown: { id: 'statuses-dropdown' },
  typePillSwitch: { id: 'type-pill-switch' },
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const ADD_NEW_APPOINTMENT_LABEL = 'Add New Appointment';
const ADD_BLOCKED_OFF_TIME_LABEL = 'Add Blocked Off Time';

export const ADD_OPTIONS = [
  {
    label: ADD_NEW_APPOINTMENT_LABEL,
  },
  {
    label: ADD_BLOCKED_OFF_TIME_LABEL,
  },
];

const STATUS_ITEMS = [
  {
    label: APPOINTMENT_STATUS.ACTIVE,
    value: APPOINTMENT_STATUS.ACTIVE,
  },
  {
    label: APPOINTMENT_STATUS.CHECKED_IN,
    value: APPOINTMENT_STATUS.CHECKED_IN,
  },
  {
    label: APPOINTMENT_STATUS.CHECKED_OUT,
    value: APPOINTMENT_STATUS.CHECKED_OUT,
  },
  {
    label: APPOINTMENT_STATUS.CANCELED,
    value: APPOINTMENT_STATUS.CANCELED,
  },
  {
    label: APPOINTMENT_STATUS.NO_SHOW,
    value: APPOINTMENT_STATUS.NO_SHOW,
  },
  {
    label: 'Rescheduled',
    value: 'Rescheduled',
  },
];

const VIEW_ROUTES = PERIOD_OPTIONS.map(formatSchedulingView);

const VALID_ROUTES = VIEW_ROUTES.map(view => `/calendar/${view}`);

function getZoomIntervalKey(tenantId) {
  return `scheduling-zoom-interval:${tenantId}`;
}

function getCalendarTypeKey(tenantId) {
  return `scheduling-calendar-type:${tenantId}`;
}

function getStatusesKey(tenantId) {
  return `scheduling-statuses:${tenantId}`;
}

export function getZoomIntervalFromLocalStorage(tenantId) {
  const localZoomInterval = JSON.parse(
    localStorage.getItem(getZoomIntervalKey(tenantId)),
  );

  return localZoomInterval || 2;
}

const CALENDAR_TYPE_NORMALIZER = {
  provider: CALENDAR_TYPES.PROVIDER,
  Provider: CALENDAR_TYPES.PROVIDER,
  room: CALENDAR_TYPES.ROOM,
  Room: CALENDAR_TYPES.ROOM,
};

const DEFAULT_STATUSES = [
  STATUS_ITEMS[0],
  STATUS_ITEMS[1],
  STATUS_ITEMS[2],
  STATUS_ITEMS[4],
];

function getCalendarTypeFromLocalStorage(tenantId) {
  const calendarType = localStorage.getItem(getCalendarTypeKey(tenantId));

  return CALENDAR_TYPE_NORMALIZER[calendarType] || CALENDAR_TYPES.PROVIDER;
}

function getStatusesFromLocalStorage(tenantId) {
  const statuses = JSON.parse(localStorage.getItem(getStatusesKey(tenantId)));

  return statuses || DEFAULT_STATUSES;
}

function roundFoldToNearestInterval(fold, interval) {
  const divisor = 60 / interval;
  const isStart = fold.start === 0;

  if (isStart) {
    return {
      roundedStart: Math.floor(fold.start / divisor) * divisor,
      roundedEnd: Math.floor(fold.end / divisor) * divisor,
    };
  }

  return {
    roundedStart: Math.ceil(fold.start / divisor) * divisor,
    roundedEnd: Math.ceil(fold.end / divisor) * divisor,
  };
}

class NebSchedulingCalendar extends LitElement {
  static get properties() {
    return {
      __calendarSummary: Array,
      __calendarSummaryCondensed: Array,
      __calendarFolds: Array,
      __locations: Array,
      __providers: Array,
      __rooms: Array,
      __statuses: Array,
      __type: String,
      __targetDate: String,
      __view: String,
      __zoomInterval: Number,
      __unmatchedAppointmentCount: Number,
      __autoFoldCalendar: Boolean,
      __isLoading: Boolean,
      __schedulingDisabled: Boolean,
      view: String,
      route: String,
      tenantId: String,
      layout: { type: String, reflect: true },
    };
  }

  static get styles() {
    return css`
      .container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        background-color: ${CSS_COLOR_WHITE};
      }

      .header {
        display: grid;
        align-items: center;
        padding: ${CSS_SPACING};
        grid-template-columns: 0fr 0fr 1fr 1fr;
      }

      .container-calendar-filter-add-button {
        display: flex;
        justify-self: end;
        align-items: center;
        grid-area: 1 / 3 / auto / 5;
      }

      .views {
        display: block;
        flex: 1 0 0;
        position: relative;
      }

      .icon {
        margin-right: 5px;
      }

      .icon-warning {
        width: 24px;
        height: 24px;
        fill: ${CSS_COLOR_ERROR};
      }

      .container-link {
        display: flex;
        grid-area: 2 / 4 / auto / 4;
        overflow: hidden;
        justify-self: end;
        align-self: end;
      }

      .scheduling-error {
        z-index: 5;
      }

      .unmatched-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .statuses-dropdown {
        margin-right: ${CSS_SPACING};
      }

      .picker {
        grid-area: 2 / 1 / auto / 5;
        justify-self: center;
        margin-top: ${CSS_SPACING};
      }

      .type-toolbar {
        grid-area: 1 / 1 / auto / auto;
      }

      .neb-pill-switch {
        display: flex;
        height: 38px;
        grid-area: 1 / 2 / auto / auto;
        margin-right: ${CSS_SPACING};
        width: fit-content;
      }

      .view-container {
        display: flex;
        align-items: baseline;
        justify-content: center;
        width: fit-content;
        height: 100%;
      }

      .today-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;

        color: ${CSS_COLOR_HIGHLIGHT};
        font-size: ${CSS_FONT_SIZE_CAPTION};
        text-decoration: underline;
        margin-right: ${CSS_SPACING};
        margin-left: ${CSS_SPACING};
      }

      .view-dropdown {
        width: 128px;
      }

      @media (max-width: 1430px) {
        .container-calendar-filter-add-button {
          grid-area: 1 / 2 / auto / 5;
        }

        .neb-pill-switch {
          grid-area: 2 / 1 / auto / auto;
        }

        .container-calendar-filter-add-button {
          grid-area: 1 / 2 / auto / 5;
        }
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__calendarSummary = {};
    this.__calendarFolds = [];
    this.__locations = [];
    this.__providers = [];
    this.__rooms = [];
    this.__statuses = [];
    this.__targetDate = parseDate().format(DATE_FORMAT);
    this.__type = CALENDAR_TYPES.PROVIDER;
    this.__unmatchedAppointmentCount = 0;
    this.__zoomInterval = 1;
    this.__autoFoldCalendar = true;
    this.__isLoading = false;
    this.__schedulingDisabled = false;

    this.view = DAY;
    this.route = '';
    this.tenantId = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectDate: date => {
        this.__targetDate = parseDate(date).format(DATE_FORMAT);
      },
      goToToday: () => {
        this.__targetDate = parseDate().format(DATE_FORMAT);
      },
      changeType: toggledOn => {
        this.__calendarFolds = [];
        this.__type = toggledOn ? CALENDAR_TYPES.PROVIDER : CALENDAR_TYPES.ROOM;

        localStorage.setItem(getCalendarTypeKey(this.tenantId), this.__type);
      },
      changeZoomInterval: interval => {
        localStorage.setItem(
          getZoomIntervalKey(this.tenantId),
          JSON.stringify(interval),
        );

        this.__zoomInterval = interval;
      },
      selectSingleLocation: location => {
        this.__locations = [location ? location.data : {}];
      },
      selectLocations: locations => {
        this.__locations = locations.map(l => l.data);
      },
      selectProviders: providers => {
        this.__providers = providers.map(p => p.data);
      },
      selectRooms: rooms => {
        this.__rooms = rooms.map(r => r.data);
      },
      selectStatuses: e => {
        this.__statuses = e.value;

        localStorage.setItem(
          getStatusesKey(this.tenantId),
          JSON.stringify(e.value),
        );
      },
      addOptionSelected: async selected => {
        if (selected.value.label === ADD_NEW_APPOINTMENT_LABEL) {
          await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
            ...NebFormAppointment.createModel(),
          });
        } else {
          await openOverlay(OVERLAY_KEYS.BLOCKED_OFF_TIME_FORM, {});
        }

        return this.__fetch(false);
      },
      clickUnmatchedAppointments: async () => {
        const res = await openOverlay(OVERLAY_KEYS.UNMATCHED_APPOINTMENTS);

        if (res && res.deleted) {
          this.__fetch(false);
        }
      },
      updateCalendar: () => {
        clearContexts();
        return this.__fetch(false);
      },
      clickMonthDay: date => {
        this.__targetDate = date;
        this.__navigateTo(DAY);
      },
      clickFold: foldIndex => {
        const fold = this.__calendarFolds[foldIndex];

        fold.isFolded = !fold.isFolded;

        this.__calendarFolds = [...this.__calendarFolds];
      },
      changeView: ({ value: view }) => {
        this.__navigateTo(view);
      },
    };
  }

  __navigateTo(view) {
    if (view === this.__view) return;
    this.__calendarFolds = [];
    localStorage.setItem(SCHEDULER_VIEW_INTERVAL, view);
    this.__view = view;
    navigate(`#/scheduling${this.__getRoute()}`);
  }

  __initServices() {
    this.__schedulingRequirementService = new SchedulingRequirementService(
      requirementsMet => {
        this.__schedulingDisabled = !requirementsMet;
      },
    );

    this.__notificationService = new UpdateNotificationService({
      callback: () => this.__fetchUnmatchedAppointmentCount(),
      defaultQuery: { patient: ANY },
    });

    this.__pollController = new PollController(this, async () => {
      await this.__fetch(true);
    });
  }

  async connectedCallback() {
    const { data: settings } = await settingsApiClient.fetchOne(true);

    this.__autoFoldCalendar = settings.autoFoldCalendar;

    super.connectedCallback();

    this.__schedulingRequirementService.connect();
    this.__notificationService.connect();
    this.__zoomInterval = getZoomIntervalFromLocalStorage(this.tenantId);
    this.__type = getCalendarTypeFromLocalStorage(this.tenantId);
    this.__statuses = getStatusesFromLocalStorage(this.tenantId);

    await this.__fetchUnmatchedAppointmentCount();
  }

  disconnectedCallback() {
    this.__schedulingRequirementService.disconnect();
    this.__notificationService.disconnect();

    super.disconnectedCallback();
  }

  __renderWeekNav() {
    return this.__type === CALENDAR_TYPES.PROVIDER
      ? html`
          <neb-provider-calendar-week-view
            id="${ELEMENTS.weekView.id}"
            targetDate="${this.__targetDate}"
            .model="${this.__calendarSummary}"
            .folds="${this.__calendarFolds}"
            .locations="${this.__locations}"
            .providers="${this.__providers}"
            .zoomInterval="${this.__zoomInterval}"
            .isWorkWeek="${this.__isWorkWeek(this.__view)}"
            .isLoading="${this.__isLoading}"
            .onUpdateCalendar="${this.__handlers.updateCalendar}"
            .onChangeZoomInterval="${this.__handlers.changeZoomInterval}"
            .onClickFold="${this.__handlers.clickFold}"
          ></neb-provider-calendar-week-view>
        `
      : html`
          <neb-room-calendar-week-view
            id="${ELEMENTS.roomWeekView.id}"
            targetDate="${this.__targetDate}"
            .model="${this.__calendarSummary}"
            .folds="${this.__calendarFolds}"
            .location="${this.__locations[0] || {}}"
            .rooms="${this.__rooms}"
            .zoomInterval="${this.__zoomInterval}"
            .isWorkWeek="${this.__isWorkWeek(this.__view)}"
            .isLoading="${this.__isLoading}"
            .onUpdateCalendar="${this.__handlers.updateCalendar}"
            .onChangeZoomInterval="${this.__handlers.changeZoomInterval}"
            .onClickFold="${this.__handlers.clickFold}"
          ></neb-room-calendar-week-view>
        `;
  }

  __renderMonth() {
    return html`
      <neb-calendar-month-view
        id="${ELEMENTS.monthView.id}"
        targetDate="${this.__targetDate}"
        .model="${this.__calendarSummaryCondensed}"
        .locations="${this.__locations}"
        .providers="${this.__providers}"
        .isLoading="${this.__isLoading}"
        .onClickDay="${this.__handlers.clickMonthDay}"
        .onUpdateCalendar="${this.__handlers.updateCalendar}"
      ></neb-calendar-month-view>
    `;
  }

  __renderDay() {
    return this.__type === CALENDAR_TYPES.PROVIDER
      ? html`
          <neb-provider-calendar-day-view
            id="${ELEMENTS.dayView.id}"
            targetDate="${this.__targetDate}"
            .model="${this.__calendarSummary}"
            .folds="${this.__calendarFolds}"
            .locations="${this.__locations}"
            .providers="${this.__providers}"
            .zoomInterval="${this.__zoomInterval}"
            .isLoading="${this.__isLoading}"
            .onUpdateCalendar="${this.__handlers.updateCalendar}"
            .onChangeZoomInterval="${this.__handlers.changeZoomInterval}"
            .onClickFold="${this.__handlers.clickFold}"
          ></neb-provider-calendar-day-view>
        `
      : html`
          <neb-room-calendar-day-view
            id="${ELEMENTS.roomDayView.id}"
            targetDate="${this.__targetDate}"
            .model="${this.__calendarSummary}"
            .folds="${this.__calendarFolds}"
            .location="${this.__locations[0] || {}}"
            .rooms="${this.__rooms}"
            .zoomInterval="${this.__zoomInterval}"
            .isLoading="${this.__isLoading}"
            .onUpdateCalendar="${this.__handlers.updateCalendar}"
            .onChangeZoomInterval="${this.__handlers.changeZoomInterval}"
            .onClickFold="${this.__handlers.clickFold}"
          ></neb-room-calendar-day-view>
        `;
  }

  __isWorkWeek(view) {
    return view === WORK_WEEK;
  }

  __genNavItems() {
    return PERIOD_OPTIONS.map(period => {
      switch (period) {
        case MONTH:
          return {
            exact: false,
            path: VALID_ROUTES[3],
            resolver: () => this.__renderMonth(),
          };
        case WORK_WEEK:
        case FULL_WEEK:
          return {
            exact: false,
            path: VALID_ROUTES[this.__isWorkWeek(period) ? 1 : 2],
            resolver: () => this.__renderWeekNav(),
          };
        default:
          return {
            exact: false,
            path: VALID_ROUTES[0],
            resolver: () => this.__renderDay(),
          };
      }
    });
  }

  // eslint-disable-next-line complexity
  async updated(changedProps) {
    if (
      changedProps.has('__targetDate') ||
      changedProps.has('__locations') ||
      changedProps.has('__providers') ||
      changedProps.has('__rooms') ||
      changedProps.has('__view') ||
      changedProps.has('__statuses')
    ) {
      await this.__fetch(false);
    }

    if (changedProps.has('route') && !this.__isRouteValid(this.route)) {
      this.__view = DAY;
    }

    if (changedProps.has('__zoomInterval')) {
      this.__updateCalendarFolds(this.__calendarFolds);
    }
  }

  __isRouteValid(route) {
    return VALID_ROUTES.includes(route);
  }

  __getRoute() {
    return `/calendar/${formatSchedulingView(this.__view)}`;
  }

  get primaryFilterIds() {
    return this.__type === CALENDAR_TYPES.PROVIDER
      ? this.__providers.map(p => p.id)
      : this.__rooms.map(r => r.id);
  }

  __getStartAndEndDates() {
    switch (this.__view) {
      case MONTH: {
        return {
          start: parseDate(this.__targetDate)
            .startOf('month')
            .format('YYYY-MM-DD'),
          end: parseDate(this.__targetDate)
            .endOf('month')
            .format('YYYY-MM-DD'),
        };
      }

      case WORK_WEEK:
      case FULL_WEEK:
        return this.__weekRange;

      case DAY: {
        return { start: this.__targetDate, end: this.__targetDate };
      }
      default:
        return { start: null, end: null };
    }
  }

  get __weekRange() {
    const { start, end } = computeFullWeekRange(true, this.__targetDate);

    return {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD'),
    };
  }

  // eslint-disable-next-line complexity
  async __fetch(optOutLoading = false) {
    const locationIds = this.__locations.map(l => l.id);

    if (
      !this.primaryFilterIds.length ||
      !locationIds.length ||
      !this.__statuses.length
    ) {
      this.__calendarSummary = null;
      this.__calendarSummaryCondensed = null;
      return;
    }

    const { start, end } = this.__getStartAndEndDates();
    if (!(start && end)) return;

    this.__isLoading = !optOutLoading;

    if (this.__abortController) {
      this.__abortController.abort();
    }

    this.__abortController = new AbortController();

    const queryParams = {
      type: this.__type,
      start,
      end,
      ...(this.__type === CALENDAR_TYPES.PROVIDER
        ? {
            providerIds: this.primaryFilterIds,
            locationIds,
            includeSplits: false,
          }
        : {
            resourceIds: this.primaryFilterIds,
            locationIds: [locationIds[0]],
            includeSplits: true,
          }),
      status: this.__statuses.map(s => s.value),
      signal: this.__abortController.signal,
    };

    if (this.__view === MONTH) {
      await this.__fetchCalendarSummaryCondensed(queryParams);
    } else {
      await this.__fetchCalendarSummary(queryParams);
    }

    this.__isLoading = false;
  }

  async __fetchCalendarSummaryCondensed(queryParams) {
    try {
      const res = await getCalendarSummaryCondensed(queryParams);

      this.__calendarSummaryCondensed = res;
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }

  async __fetchCalendarSummary(queryParams) {
    try {
      const res = await getCalendarSummary(queryParams);

      this.__calendarSummary = res && res.data[0];

      this.__updateCalendarFolds(res.folds);
    } catch (error) {
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
  }

  async __fetchUnmatchedAppointmentCount() {
    const { count } = await getUnmatchedAppointments({
      limit: 0,
      offset: 0,
      sortParams: 'asc',
    });

    this.__unmatchedAppointmentCount = count;
  }

  __updateCalendarFolds(folds) {
    const foldedArray = this.__calendarFolds.map(fold => fold.isFolded);

    this.__calendarFolds = folds.map((fold, idx) => {
      const { roundedStart, roundedEnd } = roundFoldToNearestInterval(
        fold,
        this.__zoomInterval,
      );

      return {
        ...fold,
        isFolded:
          foldedArray[idx] !== undefined
            ? foldedArray[idx]
            : this.__autoFoldCalendar,
        roundedStart,
        roundedEnd,
      };
    });
  }

  __renderSchedulingErrorPage() {
    return this.__schedulingDisabled
      ? html`
          <neb-scheduling-error-handling-page
            id="${ELEMENTS.schedulingError.id}"
            class="scheduling-error"
          ></neb-scheduling-error-handling-page>
        `
      : '';
  }

  __renderUnmatchedAppointmentsLink() {
    return this.__unmatchedAppointmentCount
      ? html`
          <div class="container-link">
            <neb-icon class="icon icon-warning" icon="neb:warning"></neb-icon>
            <neb-text
              class="unmatched-text"
              id="${ELEMENTS.unmatchedAppointmentsLink.id}"
              @click="${this.__handlers.clickUnmatchedAppointments}"
              link
              bold
              >Unmatched Appointments (${this.__unmatchedAppointmentCount})
            </neb-text>
          </div>
        `
      : '';
  }

  __renderCalendarFiltering() {
    return this.__type === CALENDAR_TYPES.PROVIDER
      ? html`
          <neb-calendar-filtering
            id="${ELEMENTS.calendarFiltering.id}"
            .tenantId="${this.tenantId}"
            .onSelectLocations="${this.__handlers.selectLocations}"
            .onSelectProviders="${this.__handlers.selectProviders}"
          ></neb-calendar-filtering>
        `
      : html`
          <neb-room-calendar-filtering
            id="${ELEMENTS.roomsCalendarFiltering.id}"
            .tenantId="${this.tenantId}"
            .onSelectLocation="${this.__handlers.selectSingleLocation}"
            .onSelectRooms="${this.__handlers.selectRooms}"
          ></neb-room-calendar-filtering>
        `;
  }

  __renderPillSwitch() {
    return html`
      <neb-pill-switch
        id="${ELEMENTS.typePillSwitch.id}"
        class="neb-pill-switch"
        .on="${this.__type === CALENDAR_TYPES.PROVIDER}"
        .labels="${CALENDAR_TYPES_SWITCH_LABELS}"
        .onToggle="${this.__handlers.changeType}"
      ></neb-pill-switch>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div id="${ELEMENTS.header.id}" class="header">
          <div id="${ELEMENTS.viewContainer.id}" class="view-container">
            <neb-select
              id="${ELEMENTS.viewDropdown.id}"
              class="view-dropdown"
              helper=" "
              .items="${PERIOD_OPTIONS}"
              .value="${this.__view}"
              .onChange="${this.__handlers.changeView}"
            ></neb-select>

            <div
              id="${ELEMENTS.todayLink.id}"
              class="text today-link"
              @click="${this.__handlers.goToToday}"
            >
              Today
            </div>
          </div>
          ${this.__renderPillSwitch()}

          <neb-calendar-picker
            id="${ELEMENTS.calendarPicker.id}"
            class="picker"
            .selectedDate="${parseDate(this.__targetDate)}"
            .onDateSelected="${this.__handlers.selectDate}"
            .mode="${this.__view}"
            momentFlag
          >
          </neb-calendar-picker>

          <div class="container-calendar-filter-add-button">
            <neb-select
              id="${ELEMENTS.statusesDropdown.id}"
              class="statuses-dropdown"
              placeholder="No Statuses Selected"
              allLabel="Statuses"
              name="statuses"
              .value="${this.__statuses}"
              .items="${STATUS_ITEMS}"
              .onChange="${this.__handlers.selectStatuses}"
              multiSelect
              wrapText
            ></neb-select>
            ${this.__renderCalendarFiltering()}

            <div class="button-container">
              <neb-menu-button
                id="${ELEMENTS.addButton.id}"
                class="menu"
                align="right"
                vertical-offset="45"
                label="Add"
                .items="${ADD_OPTIONS}"
                .onSelect="${this.__handlers.addOptionSelected}"
              >
              </neb-menu-button>
            </div>
          </div>

          ${this.__renderUnmatchedAppointmentsLink()}
        </div>

        <div class="views">
          ${matchRouteSwitch(this.__genNavItems(), this.__getRoute())}
        </div>

        ${this.__renderSchedulingErrorPage()}
      </div>
    `;
  }
}

customElements.define('neb-scheduling-calendar', NebSchedulingCalendar);
