import '../../../../../packages/neb-lit-components/src/components/neb-tooltip';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../controls/inputs/neb-checkbox';
import '../../../../../packages/neb-lit-components/src/components/neb-date-picker';
import '../../../../../packages/neb-lit-components/src/components/ledger/neb-all-patients-card';

import { isRequired } from '@neb/form-validators';
import { html, css } from 'lit';

import Form from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { formatToItems } from '../../../../../packages/neb-lit-components/src/components/forms/neb-form-new-statement';
import { BUTTON_ROLE } from '../../../../../packages/neb-lit-components/src/components/neb-button';
import { LocationsService } from '../../../../../packages/neb-redux/services/locations';
import { parseDate } from '../../../../../packages/neb-utils/date-util';
import {
  objToName,
  DEFAULT_NAME_OPTS,
} from '../../../../../packages/neb-utils/formatters';
import * as selectors from '../../../../../packages/neb-utils/selectors';

export const PATIENT_CASE_STATEMENTS_SETTINGS_KEY =
  'patientCaseStatementsSettings';

export const ELEMENTS = {
  description: {
    id: 'description',
  },
  dateOfServiceFrom: {
    id: 'date-of-service-from',
  },
  dateOfServiceTo: {
    id: 'date-of-service-to',
  },
  toolTipPayers: {
    id: 'tooltip-payers',
  },
  selectProviders: {
    id: 'select-provider',
  },
  selectAttorneys: {
    id: 'select-attorney',
  },
  selectOrganizations: {
    id: 'select-attorney-organizations',
  },
  checkboxUniqueCaseStatement: {
    id: 'checkbox-unique-case-statement',
  },
  checkboxIncludeEncounterSummaries: {
    id: 'checkbox-include-encounter-summaries',
  },
  checkboxIncludePaymentDetails: {
    id: 'checkbox-include-payment-details',
  },
  checkboxIncludePurchaseCharges: {
    id: 'checkbox-include-purchase-charges',
  },
  unassignedProvidersDropdown: {
    id: 'unassigned-providers-dropdown',
  },
  checkboxIncludeEncounterDocuments: {
    id: 'checkbox-include-encounter-documents',
  },
  checkboxIncludeClaims: {
    id: 'checkbox-include-claims',
  },
  buttonGenerate: {
    id: 'button-generate',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  locationCheckbox: { id: 'location-checkbox' },
  locationDropdown: { id: 'location-dropdown' },
};

export class NebFormGenerateCaseStatement extends Form {
  static get properties() {
    return {
      patientName: Object,
      attorneys: Array,
      organizations: Array,
      providers: Array,
      preferredProviderId: String,
      __providerItems: Array,
      __attorneyItems: Array,
      __organizationsItems: Array,
      __showLocationDropdown: { type: Boolean, reflect: true },
      __activeLocations: { type: Array },
      hasStatementImprovementsFeatureFlag: Boolean,
    };
  }

  static createModel() {
    return {
      dateOfServiceFrom: '',
      dateOfServiceTo: '',
      providerIds: [undefined],
      attorneyIds: [undefined],
      organizationIds: [undefined],
      uniqueCaseStatementPerProvider: true,
      includePaymentDetails: false,
      includePurchaseCharges: true,
      includeEncounterSummaries: false,
      includeEncounterDocuments: false,
      includeClaims: false,
      locationId: '',
      unassignedProviderId: undefined,
    };
  }

  constructor() {
    super();

    this.initServices();
  }

  createSelectors() {
    return {
      children: {
        dateOfServiceFrom: {
          clipPristine: true,
          validators: [isRequired()],

          unformat: v =>
            v
              ? parseDate(v)
                  .startOf('day')
                  .toISOString()
              : null,
        },
        dateOfServiceTo: {
          clipPristine: true,
          validators: [isRequired()],
          unformat: v =>
            v
              ? parseDate(v)
                  .endOf('day')
                  .toISOString()
              : null,
        },
        providerIds: selectors.multiSelect(
          this.__providerItems,
          this.__providerItems,
          { validators: [isRequired()] },
        ),
        attorneyIds: selectors.multiSelect(
          this.__attorneyItems,
          this.__attorneyItems,
        ),
        organizationIds: selectors.multiSelect(
          this.__organizationsItems,
          this.__organizationsItems,
        ),
        locationId: selectors.select(this.locationItems, selectors.ITEM_EMPTY),
        unassignedProviderId: selectors.select(
          this.unassignedProviderItems,
          selectors.ITEM_EMPTY,
        ),
      },
    };
  }

  initState() {
    super.initState();

    this.patientName = '';
    this.preferredProviderId = '';
    this.attorneys = [];
    this.organizations = [];
    this.providers = [];

    this.__providerItems = [];
    this.__attorneyItems = [];
    this.__organizationsItems = [];

    this.__activeLocations = [];
    this.__showLocationDropdown = false;
    this.hasStatementImprovementsFeatureFlag = false;
  }

  initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__activeLocations = this.__formatLocations(userLocations).filter(
        location => location.data.active,
      );
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectDateOfServiceFrom: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : '');
        this.__updateDateOfServiceToSelectable();
      },
      selectDateOfServiceTo: e => {
        this.formService.apply(e.name, e.value ? e.value.toISOString() : '');
        this.__updateDateOfServiceFromSelectable();
      },
      toggleLocationDropdown: () => {
        this.__showLocationDropdown = !this.__showLocationDropdown;

        this.__clearLocationDropdown();
      },
      generate: params => {
        const model = this.formService.build();
        this.__storeStatementSettings(model);
        super.save(params);
      },
      changeProviders: e => {
        this.formService.apply(e.name, e.value);

        if (
          this.hasStatementImprovementsFeatureFlag &&
          this.state.providerIds.length <= 1
        ) {
          this.formService.apply('uniqueCaseStatementPerProvider', false);
        }
      },
    };

    this.__updateDateOfServiceFromSelectable();
    this.__updateDateOfServiceToSelectable();
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  __storeStatementSettings(rawModel) {
    if (!this.hasStatementImprovementsFeatureFlag) {
      return;
    }
    const keysToRemove = [
      'locationId',
      'dateOfServiceFrom',
      'dateOfServiceTo',
      'providerIds',
      'attorneyIds',
      'organizationIds',
      'unassignedProviderId',
    ];
    const settings = Object.fromEntries(
      Object.entries(rawModel).filter(([key]) => !keysToRemove.includes(key)),
    );
    localStorage.setItem(
      PATIENT_CASE_STATEMENTS_SETTINGS_KEY,
      JSON.stringify(settings),
    );
  }

  __loadLocalStorageData() {
    if (this.hasStatementImprovementsFeatureFlag) {
      const localStorageData = localStorage.getItem(
        PATIENT_CASE_STATEMENTS_SETTINGS_KEY,
      );

      if (localStorageData) {
        const storedInfo = JSON.parse(localStorageData);

        Object.keys(storedInfo).forEach(paramName => {
          this.formService.apply(paramName, storedInfo[paramName]);
        });
      }
    }
  }

  async firstUpdated() {
    await super.firstUpdated();
    this.__setDefaultProvider();
    this.__loadLocalStorageData();
  }

  __clearLocationDropdown() {
    if (!this.__showLocationDropdown) {
      this.state.locationId = {
        data: { ...selectors.ITEM_EMPTY.data },
      };
    }
  }

  __setPreferredProvider() {
    this.state.unassignedProviderId = this.unassignedProviderItems.find(
      provider => provider.data.id === this.preferredProviderId,
    );
  }

  __setFirstProvider() {
    this.state.unassignedProviderId = { ...this.unassignedProviderItems[1] };
  }

  __setDefaultProvider() {
    if (this.hasStatementImprovementsFeatureFlag) {
      if (this.preferredProviderId) {
        this.__setPreferredProvider();
      } else {
        this.__setFirstProvider();
      }
    }
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      data: location,
      label: location.name,
    }));
  }

  get locationItems() {
    return [selectors.ITEM_EMPTY, ...this.__activeLocations];
  }

  get unassignedProviderItems() {
    return [selectors.ITEM_EMPTY, ...this.__providerItems];
  }

  __updateDateOfServiceFromSelectable() {
    this.handlers.dateOfServiceFromSelectable = date =>
      (!this.state.dateOfServiceTo ||
        date.isSameOrBefore(parseDate(this.state.dateOfServiceTo))) &&
      date.isSameOrBefore(parseDate().startOf('day'));
  }

  __updateDateOfServiceToSelectable() {
    this.handlers.dateOfServiceToSelectable = date =>
      (!this.state.dateOfServiceFrom ||
        date.isSameOrAfter(parseDate(this.state.dateOfServiceFrom))) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __getDescription() {
    let patientName = '';

    if (this.patientName) {
      patientName = objToName(this.patientName, DEFAULT_NAME_OPTS);
    }

    return `Generate a new case statement for ${patientName} using the parameters below.`;
  }

  __getAndFormatOrganizations() {
    if (this.organizations) {
      const organizations = this.organizations.filter(elem =>
        this.attorneys.find(({ organizationId }) => elem.id === organizationId),
      );

      this.__organizationsItems = organizations.map(data => ({
        label: data.name,
        data,
      }));
    }
  }

  __getAndFormatAttorneys() {
    if (this.attorneys) {
      this.__attorneyItems = this.attorneys.map(data => ({
        label: data.name,
        data,
      }));
    }
  }

  __shouldDisableGenerate() {
    return !this.state.dateOfServiceFrom || !this.state.dateOfServiceTo;
  }

  __shouldRenderUnassignedPurchasesDropdown() {
    return (
      this.state.uniqueCaseStatementPerProvider &&
      this.state.includePurchaseCharges
    );
  }

  updated(changedProps) {
    if (changedProps.has('providers') && this.providers) {
      this.__providerItems = formatToItems(this.providers);
    }

    if (changedProps.has('attorneys')) {
      this.__getAndFormatAttorneys();
    }

    if (changedProps.has('organizations')) {
      this.__getAndFormatOrganizations();
    }

    super.updated(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex: 1 0 auto;
          overflow: visible;
        }

        .grid-buttons {
          grid-template-columns: auto auto auto 1fr;
        }

        .date-picker {
          width: 100%;
        }

        .no-required {
          margin-bottom: 16px;
        }

        .billingLocation {
          width: 100%;
        }

        .unassignedProviderId {
          width: 100%;
          margin-bottom: 16px;
        }
      `,
    ];
  }

  __renderLocationCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.locationCheckbox.id}"
        class="checkbox"
        name="locations"
        label="Select Billing Location"
        .checked="${this.__showLocationDropdown}"
        .onChange="${this.handlers.toggleLocationDropdown}"
      ></neb-checkbox>
    `;
  }

  __renderLocationDropdown() {
    return this.__showLocationDropdown
      ? html`
          <neb-select
            id="${ELEMENTS.locationDropdown.id}"
            class="billingLocation"
            name="locationId"
            label="Billing Location"
            select
            wrapText
            .items="${this.locationItems}"
            .value="${this.state.locationId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : '';
  }

  __renderUniqueCaseStatementCheckbox() {
    if (this.hasStatementImprovementsFeatureFlag) {
      return html`
        <neb-checkbox
          id="${ELEMENTS.checkboxUniqueCaseStatement.id}"
          class="span spacer-bottom"
          name="uniqueCaseStatementPerProvider"
          label="Unique Statement Per Provider"
          .checked="${this.state.uniqueCaseStatementPerProvider}"
          .onChange="${this.handlers.change}"
          .disabled="${this.state.providerIds.length <= 1}"
        ></neb-checkbox>
      `;
    }

    return this.state.providerIds.length > 1
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxUniqueCaseStatement.id}"
            class="span spacer-bottom"
            name="uniqueCaseStatementPerProvider"
            label="Unique Statement Per Provider"
            .checked="${this.state.uniqueCaseStatementPerProvider}"
            .onChange="${this.handlers.change}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderIncludePaymentDetailsCheckbox() {
    return this.hasStatementImprovementsFeatureFlag
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxIncludePaymentDetails.id}"
            class="span spacer-bottom"
            name="includePaymentDetails"
            label="Include Payment Details"
            .checked="${this.state.includePaymentDetails}"
            .onChange="${this.handlers.change}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderIncludePurchaseChargesCheckbox() {
    return this.hasStatementImprovementsFeatureFlag
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxIncludePurchaseCharges.id}"
            class="span spacer-bottom"
            name="includePurchaseCharges"
            label="Include Purchase/Fee Charges"
            .checked="${this.state.includePurchaseCharges}"
            .onChange="${this.handlers.change}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderUnassignedPurchasesDropdown() {
    if (!this.hasStatementImprovementsFeatureFlag) return '';

    return this.__shouldRenderUnassignedPurchasesDropdown()
      ? html`
          <neb-select
            id="${ELEMENTS.unassignedProvidersDropdown.id}"
            name="unassignedProviderId"
            class="unassignedProviderId"
            label="Select Provider for Unassigned Purchases & Fees"
            select
            wrapText
            .items="${this.unassignedProviderItems}"
            .value="${this.state.unassignedProviderId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : '';
  }

  __renderIncludeEncounterSummariesCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxIncludeEncounterSummaries.id}"
        class="span spacer-bottom"
        name="includeEncounterSummaries"
        label="Include Encounter Summaries"
        .checked="${this.state.includeEncounterSummaries}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderIncludeEncounterDocumentsCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxIncludeEncounterDocuments.id}"
        class="span spacer-bottom"
        name="includeEncounterDocuments"
        label="Include Encounter Documents"
        .checked="${this.state.includeEncounterDocuments}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderIncludeClaimsCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxIncludeClaims.id}"
        class="span spacer-bottom"
        name="includeClaims"
        label="Include Claims"
        .checked="${this.state.includeClaims}"
        .onChange="${this.handlers.change}"
      ></neb-checkbox>
    `;
  }

  __renderCheckboxes() {
    const wrap = ({ className, renderFunctions }) =>
      renderFunctions.map(fn => {
        const content = fn.call(this);
        if (!content) return '';

        return this.hasStatementImprovementsFeatureFlag
          ? html`
              <div class="${className}">${content}</div>
            `
          : content;
      });

    const render = items =>
      Object.entries(items)
        .map(([className, renderFunctions]) =>
          wrap({ className, renderFunctions }),
        )
        .flat();

    const checkboxes = {
      'spacer-bottom': [
        this.__renderUniqueCaseStatementCheckbox,
        this.__renderIncludeEncounterSummariesCheckbox,
        this.__renderIncludePaymentDetailsCheckbox,
        this.__renderIncludeEncounterDocumentsCheckbox,
        this.__renderIncludePurchaseChargesCheckbox,
        this.__renderIncludeClaimsCheckbox,
      ],
      span: [
        this.__renderUnassignedPurchasesDropdown,
        this.__renderLocationCheckbox,
        this.__renderLocationDropdown,
      ],
    };

    return html`
      ${render(checkboxes)}
    `;
  }

  renderContent() {
    const dos = {
      from: parseDate(this.state.dateOfServiceFrom),
      to: parseDate(this.state.dateOfServiceTo),
    };

    return html`
      <div class="grid grid-2 grid-lean">
        <div id="${ELEMENTS.description.id}" class="span">
          ${this.__getDescription()}
        </div>

        <neb-date-picker
          id="${ELEMENTS.dateOfServiceFrom.id}"
          class="date-picker"
          name="dateOfServiceFrom"
          label="Date of Service From"
          placeholder="Select Date"
          helperText="Required"
          manualPopoverPosition="center"
          momentFlag
          .invalidText="${this.errors.dateOfServiceFrom}"
          .selectedDate="${
            dos.from !== null ? dos.from.startOf('day') : dos.from
          }"
          .isDateSelectable="${this.handlers.dateOfServiceFromSelectable}"
          .onChange="${this.handlers.selectDateOfServiceFrom}"
          ?invalid="${this.errors.dateOfServiceFrom}"
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateOfServiceTo.id}"
          class="date-picker"
          name="dateOfServiceTo"
          label="Date of Service To"
          placeholder="Select Date"
          helperText="Required"
          manualPopoverPosition="center"
          momentFlag
          .invalidText="${this.errors.dateOfServiceTo}"
          .selectedDate="${dos.to !== null ? dos.to.endOf('day') : dos.to}"
          .isDateSelectable="${this.handlers.dateOfServiceToSelectable}"
          .onChange="${this.handlers.selectDateOfServiceTo}"
          ?invalid="${this.errors.dateOfServiceTo}"
        ></neb-date-picker>

        <neb-select
          id="${ELEMENTS.selectProviders.id}"
          class="span"
          name="providerIds"
          label="Providers"
          allLabel="Providers"
          helper="Required"
          .items="${this.__providerItems}"
          .value="${this.state.providerIds}"
          .onChange="${this.handlers.changeProviders}"
          .error="${this.errors.providerIds}"
          multiSelect
        ></neb-select>

        <neb-select
          id="${ELEMENTS.selectAttorneys.id}"
          class="span no-required"
          name="attorneyIds"
          label="Attorneys"
          allLabel="Attorneys"
          .items="${this.__attorneyItems}"
          .value="${this.state.attorneyIds}"
          .onChange="${this.handlers.change}"
          multiSelect
        ></neb-select>

        <neb-select
          id="${ELEMENTS.selectOrganizations.id}"
          class="span no-required"
          name="organizationIds"
          label="Organizations"
          allLabel="Organizations"
          .items="${this.__organizationsItems}"
          .value="${this.state.organizationIds}"
          .onChange="${this.handlers.change}"
          multiSelect
        ></neb-select>

        ${this.__renderCheckboxes()}
      </div>
    `;
  }

  renderActionBar() {
    return html`
      <div class="grid grid-lean grid-buttons">
        <neb-button
          id="${ELEMENTS.buttonGenerate.id}"
          label="Generate"
          .onClick="${this.handlers.generate}"
          ?disabled="${this.__shouldDisableGenerate()}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
          .role="${BUTTON_ROLE.OUTLINE}"
        ></neb-button>
      </div>
    `;
  }
}
customElements.define(
  'neb-form-generate-case-statement',
  NebFormGenerateCaseStatement,
);
