import { IDENTIFIERS, FEDERAL_TAX_ID_TYPE } from '../../../neb-utils/enums';

function identifiersToUser({ id, number, type }) {
  return {
    id,
    number,
    type: IDENTIFIERS[type],
  };
}

function identifiersToModel({ id, number, type }) {
  return {
    id,
    number,
    type: type.value,
  };
}

function subProvidersToModel(model) {
  return {
    providerId: model.providerId,
    subProviderPayers: model.payerIds,
  };
}

function subProvidersToUser(raw) {
  return raw.subProviders.map(provider => ({
    payerIds: provider.subProviderPayers,
    providerId: provider.providerId,
  }));
}

export function mapClientModelToUser(raw, includeTenantId = false) {
  return {
    active: raw.active,
    id: raw.id || '',
    type: raw.type,
    name: {
      first: raw.givenName ? raw.givenName.trim() : '',
      last: raw.familyName ? raw.familyName.trim() : '',
      preferred: raw.nickname ? raw.nickname.trim() : '',
      middle: raw.middleName ? raw.middleName.trim() : '',
      suffix: raw.suffix ? raw.suffix.trim() : '',
    },
    email: raw.email || '',
    phones: [...raw.phoneNumbers],
    licenses: raw.licenses ? [...raw.licenses] : [],
    identifiers: raw.identifiers ? raw.identifiers.map(identifiersToUser) : [],
    biography: raw.biography || '',
    taxonomy:
      raw.taxonomies && raw.taxonomies[0]
        ? raw.taxonomies[0]
        : {
            code: '',
            description: '',
          },
    NPI: raw.NPI,
    organizationNPI: raw.organizationNPI || '',
    taxId: raw.taxId || '',
    taxIdType: raw.taxId ? raw.taxIdType : FEDERAL_TAX_ID_TYPE.SSN,
    status: raw.status,
    substituteProviders: subProvidersToUser(raw),
    photo: { imageUrl: raw.profileImageUrl || '' },
    superUser: raw.superUser,
    permissions: raw.permissions,
    ...(includeTenantId && { tenantId: raw.tenantId }),
    locations: raw.locations,
    defaultLocationId: !raw.defaultLocationId ? '' : raw.defaultLocationId,
    inactiveLogout: raw.inactiveLogout,
    skipSignMessage: raw.skipSignMessage,
    createEncounterAtCheckIn: raw.createEncounterAtCheckIn,
    richTextFontFamily: raw.richTextFontFamily,
    richTextFontSize: raw.richTextFontSize,
  };
}

export function mapClientModelToProvider(raw) {
  const user = mapClientModelToUser(raw);
  return { ...user, firstName: user.name.first, lastName: user.name.last };
}

const mapPermissionToClientModel = (model, superUser = false) => ({
  name: model.name,
  access: superUser ? true : model.access,
});

export function mapUserToClientModel(model) {
  return {
    id: model.id,
    suffix: model.name.suffix,
    tenantId: model.tenantId,
    type: model.type,
    active: model.active,
    email: model.email,
    givenName: model.name.first,
    familyName: model.name.last,
    middleName: model.name.middle,
    nickname: model.name.preferred,
    phoneNumbers: [...model.phones],
    licenses: model.licenses ? [...model.licenses] : [],
    taxonomies: model.taxonomy ? [{ ...model.taxonomy }] : [],
    identifiers: model.identifiers
      ? model.identifiers.map(identifiersToModel)
      : [],
    biography: model.biography,
    NPI: model.NPI,
    organizationNPI: model.organizationNPI,
    taxId: model.taxId,
    taxIdType: model.taxId ? model.taxIdType : null,
    ...(model.photo && {
      profileImageUrl: model.photo.src ? model.photo.imageUrl : '',
    }),
    superUser: model.superUser,
    subProviders:
      model.substituteProviders && model.substituteProviders.length
        ? model.substituteProviders.map(subProvidersToModel)
        : [],

    permissions: model.permissions.map(x =>
      mapPermissionToClientModel(x, model.superUser),
    ),
    locations: model.locations,
    defaultLocationId: model.defaultLocationId || null,
    inactiveLogout: model.inactiveLogout,
    skipSignMessage: model.skipSignMessage,
    createEncounterAtCheckIn: model.createEncounterAtCheckIn,
    richTextFontFamily: model.richTextFontFamily,
    richTextFontSize: model.richTextFontSize,
  };
}
