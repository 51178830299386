import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';

const HOURS_12 = 12 * 60 * 60 * 1000;
export const apiClient = new ApiClient({
  maxParallelism: 1,
  cacheConfig: {
    max: 300,
    maxAge: HOURS_12,
  },
});

export const coreApiClient = new ApiClientV2({
  microservice: 'core',
});

/** Exposed for testing. */
export function __resetCache() {
  apiClient.clearCache();
}

const genPatientCacheKey = (patientId, imageSizeName) => {
  if (imageSizeName === 'large') {
    return `${patientId}-large`;
  }

  if (imageSizeName === 'small') {
    return `${patientId}-small`;
  }
  return null;
};

const genClearCacheKeys = patientId => [
  `${patientId}-large`,
  `${patientId}-small`,
];

/**
 * Post (upsert) profile image for patient
 *
 * @param {String} patientId - patient id
 * @param {String} src - file blob
 */

export function upsertPatientImage(patientId, src) {
  return coreApiClient.makeRequest({
    method: Method.POST,
    path: '/api/v1/tenants/:tenantId/patients/:patientId/images',
    headers: { 'Content-Type': 'application/json' },
    optOutLoadingIndicator: false,
    body: { imageSrc: src },
    responseType: RESPONSE_TYPE.RAW,
    replacements: { patientId },
    clearCacheKeys: genClearCacheKeys(patientId),
  });
}

export async function getPatientImage(
  patientId,
  imageSizeName = 'large',
  optOutLoadingIndicator = false,
) {
  if (!patientId) return undefined;

  try {
    const response = await apiClient.makeRequest({
      path: `patients/${patientId}/image/${imageSizeName}`,
      cacheKey: genPatientCacheKey(patientId, imageSizeName),
      responseType: RESPONSE_TYPE.OBJECT_URL,
      optOutLoadingIndicator,
    });

    if (response.res.status === 204) {
      return null;
    }

    return response.url();
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function deletePatientImage(patientId) {
  if (!patientId) return undefined;

  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: `patients/${patientId}/image`,
    responseType: RESPONSE_TYPE.RAW,
    clearCacheKeys: genClearCacheKeys(patientId),
  });
}
