import { html, LitElement, css } from 'lit';

import { ckeditorStyles } from '../../../../packages/neb-styles/neb-ckeditor-styles';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_HIGHLIGHT,
} from '../../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  iframe: {
    id: 'iframe',
  },
};

const buildRichTextEditorTemplate = () => `
  ${ckeditorStyles}

  <style>
    html {
      height: 100%;
    }

    body {
      margin: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .ck.ck-editor {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }

    .ck.ck-editor__main {
      flex: 1;
      overflow-y: auto;
      font-family: sans-serif, Arial, Verdana, 'Trebuchet MS',
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border: none;
      border-top: ${CSS_BORDER_GREY_2} !important;
    }

    .ck.ck-editor__editable {
      height: 100% !important;
      box-sizing: border-box;
    }

    .ck-dropdown {
      position: relative !important;
    }

    .ck .ck-insert-table-dropdown-grid-box {
      width: var(--ck-insert-table-dropdown-box-width) !important;
      height: var(--ck-insert-table-dropdown-box-height) !important;
      margin: var(--ck-insert-table-dropdown-box-margin) !important;
      border: 1px solid var(--ck-insert-table-dropdown-box-border-color) !important;
    }

    .ck .ck-insert-table-dropdown__grid {
      width: calc(
        var(--ck-insert-table-dropdown-box-width) * 10 +
          var(--ck-insert-table-dropdown-box-margin) * 20 +
          var(--ck-insert-table-dropdown-padding) * 2
      ) !important;
      padding: var(--ck-insert-table-dropdown-padding)
        var(--ck-insert-table-dropdown-padding) 0 !important;
    }

    .ck.ck-dropdown__panel {
      background: var(--ck-color-dropdown-panel-background) !important;
      border: 1px solid var(--ck-color-dropdown-panel-border) !important;
    }

    .ck.ck-dropdown.ck-font-family-dropdown > div {
      max-height: 170px;
      overflow-y: auto;
    }

    .ck.ck-dropdown.ck-font-size-dropdown > div {
      max-height: 170px;
      overflow-y: auto;
    }

   .ck.ck-dropdown.ck-font-family-dropdown, .ck.ck-dropdown.ck-font-size-dropdown {
      display: none;
    }    

    span.ck.ck-button__label {
      font-size: 12px !important;
    }

    .ck-content .table {
      margin: 1em 0 !important;
    }

    span[contenteditable='false'] {
      cursor: pointer;
      user-select: all;
      -moz-user-select: all;
      -webkit-user-select: all;
      text-decoration: underline;
      color: ${CSS_COLOR_HIGHLIGHT};
    }
  </style>

  <textarea name="content" id="editor"></textarea>

  <script src="../assets/ckeditor/ckeditor.js"></script>
  <script src="../ckeditor-scripts/neb-simple-rich-text-editor-script.js"></script>
`;

class NebSimpleRichTextEditor extends LitElement {
  static get properties() {
    return {
      name: String,
      value: String,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__editorText = '';

    this.name = '';
    this.value = '';

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      messageChanged: ({ source, data }) => {
        if (source !== this.__elements.iframe.contentWindow) {
          return;
        }

        if (data.iframeReady) {
          this.sync();
        } else if (data.text === undefined) {
          this.__editorText = '';
        } else {
          this.__editorText = data.text;
          this.onChange({ name: this.name, value: data.text });
        }
      },
    };
  }

  get __elements() {
    return {
      iframe: this.shadowRoot.getElementById(ELEMENTS.iframe.id),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('message', this.__handlers.messageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('message', this.__handlers.messageChanged);
  }

  sync() {
    if (
      this.value.replace(/\n/g, '') !== this.__editorText.replace(/\n/g, '')
    ) {
      this.__elements.iframe.contentWindow.postMessage(
        {
          body: this.value,
        },
        '*',
      );
    }
  }

  updated(_changedProps) {
    this.sync();
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .iframe {
        height: 100%;
        width: 100%;
        border: none;
        display: flex;
      }
    `;
  }

  render() {
    return html`
      <iframe
        id="${ELEMENTS.iframe.id}"
        class="iframe"
        title="rich text editor"
        srcdoc="${buildRichTextEditorTemplate()}"
      >
      </iframe>
    `;
  }
}

window.customElements.define(
  'neb-simple-rich-text-editor',
  NebSimpleRichTextEditor,
);
