import { html } from 'lit';
import { directive } from 'lit/directive.js';

import { getPracticeInformation } from '../neb-api-client/src/practice-information';
import { store } from '../neb-redux/neb-redux-store';

export const FEATURE_FLAGS = {
  ACKNOWLEDGEMENT: 'acknowledgement',
  BYOC_BATCH: 'byoc-batch',
  CLAIMS_PLAYGROUND: 'claims-playground',
  CT_CHECK_IN_FORMS: 'ct-checkin-forms',
  CT_INFORMS: 'ct-informs',
  CT_INSIGHTS: 'ct-insights',
  ENABLE_PROVIDER_ACTIONS: 'enable-provider-actions',
  RECENT_RECORDS: 'recent-records',
  MULTI_CARE_PACKAGE: 'multi-care-package',
  INSURANCE_CARE_PACKAGE: 'insurance-care-package',
  ERA_OVERVIEW: 'era-overview',
  ERA_ACTIONS: 'era-actions',
  ENHANCE_RTE: 'enhance-RTE',
  SHOW_ERA_EOB_ASSOCIATIONS: 'show-era-eob-associations',
  STATEMENT_IMPROVEMENTS: 'statement-improvements',
  EXCEL_REPORT_LINK: 'excel-report-link',
  LOG_DUPLICATE_API_CALLS: 'log-duplicate-api-calls',
  TESTING_REPORT: 'testing-report',
  ENTERPRISE_REPORTS: 'enterprise-reports',
  CHECK_IN_OUT_V2: 'check-in-out-v2',
  GOLDEN_FUNNEL: 'golden-funnel',
  PAY_TO_ADDRESS: 'pay-to-address',
  PAYMENT_FREQUENCY: 'payment-frequency',
  SPA_APPOINTMENTS_BETA: 'spa-appointments-beta', // Used in neb-ms-reports,
  FISERV_CAU: 'fiserv-cau',
  BOOKING_INVITE: 'booking-invite',
  STATEMENT_BATCHES: 'statement-batches',
  DISABLE_INITIAL_LOAD: 'disable-initial-load',
  ZERO_CHARGE_CARE_PACKAGES: 'zero-charge-care-packages', // Used in neb-ms-billing
  RCM_SECONDARY_FIELD: 'rcm-secondary-field',
  RCM_CHANGE_SECONDARY: 'rcm-change-secondary',
  RCM_CODE_SEARCH: 'rcm-code-search',
  RCM_EASY_BILLING_NOTES: 'rcm-easy-billing-notes',
  RCM_ALLOCATE_SECONDARY: 'rcm-allocate-secondary',
  RCM_QUICK_DELETE_SEARCH: 'rcm-quick-delete-search',
  RCM_CLAIM_FLAG: 'rcm-claim-flag',
  RCM_CLAIM_ACTION_HISTORY: 'rcm-claim-action-history',
  STATEMENT_BATCHES_PREVIEW: 'statement-batches-preview',
  BYOC_277: 'byoc-277',
  ERA_ACTION_MATCH_CLAIM: 'era-action-match-claim',
  ERA_ACTION_MANUAL_POST: 'era-action-manual-post',
  ADVANCED_PRACTICE_MENU: 'advanced-practice-menu',
  ALLOCATIONS_PATIENT_SEARCH: 'allocations-patient-search',
  AUTO_CALCULATE_ALLOWED_AMOUNT_FOR_CARE_PACKAGE:
    'auto-calculate-allowed-amount-for-care-package',
  APPLY_RESET_ALLOCATE_FIT: 'apply-reset-allocate-fit',
  PATIENT_DEMOGRAPHIC_REPORT: 'patient-demographic-report',
  PDF_CLEAN_UP_HTML_CONTENT: 'pdf-clean-up-html-content', // Used in neb-ms-pdf
  RTS_SCRUB: 'rts-scrub',
  RCM_RELEASE_2: 'rcm-release-2',
  RCM_ERA_EOB_ENHANCEMENTS: 'rcm-era-eob-enhancements',
  RCM_SECONDARY_CLAIMS: 'rcm-secondary-claims',
  RTS_LATENCY_IMPROVEMENT: 'rts-latency-improvement',
  RCM_PROVIDER_ADJUSTMENTS: 'rcm-provider-adjustments',
  USE_CLAIM_LEVEL_STATUS: 'use-claim-level-status',
  RCM_ITD_DOO_CLAIM_ERROR_OVERLAY: 'rcm-itd-doo-claim-error-overlay',
  FIT_INVOICE_OVERLAY_PERFORMANCE: 'fit-invoice-overlay-performance',
  ENABLE_RUM_SESSION_REPLAY: 'enable-rum-session-replay',
  SINGLE_CHC_QUEUE: 'single-chc-queue',
  RCM_MANAGE_ENCOUNTER_CLAIM_ERROR_OVERLAY:
    'rcm-manage-encounter-claim-error-overlay',
  ERA_OVERVIEW_NAVIGATION_A: 'era-overview-navigation-a',
  ERA_OVERVIEW_NAVIGATION_B: 'era-overview-navigation-b',
};

export const BETA_FLAGS = {
  CHARTING_V2: 'charting-v2',
  EOD_SUMMARY_BETA: 'eod-summary-beta',
};

export const FEATURE_FLAGS_ON_PRODUCTION = [
  FEATURE_FLAGS.ACKNOWLEDGEMENT,
  FEATURE_FLAGS.ALLOCATIONS_PATIENT_SEARCH,
  FEATURE_FLAGS.BOOKING_INVITE,
  FEATURE_FLAGS.ERA_ACTION_MANUAL_POST,
  FEATURE_FLAGS.ERA_ACTION_MATCH_CLAIM,
  FEATURE_FLAGS.ERA_ACTIONS,
  FEATURE_FLAGS.ERA_OVERVIEW,
  FEATURE_FLAGS.GOLDEN_FUNNEL,
  FEATURE_FLAGS.MULTI_CARE_PACKAGE,
  FEATURE_FLAGS.PAY_TO_ADDRESS,
  FEATURE_FLAGS.SHOW_ERA_EOB_ASSOCIATIONS,
  FEATURE_FLAGS.STATEMENT_IMPROVEMENTS,
  FEATURE_FLAGS.ZERO_CHARGE_CARE_PACKAGES,
];

export const RCM_FEATURE_FLAGS = [
  FEATURE_FLAGS.RCM_ALLOCATE_SECONDARY,
  FEATURE_FLAGS.RCM_CHANGE_SECONDARY,
  FEATURE_FLAGS.RCM_CLAIM_FLAG,
  FEATURE_FLAGS.RCM_CODE_SEARCH,
  FEATURE_FLAGS.RCM_EASY_BILLING_NOTES,
  FEATURE_FLAGS.RCM_SECONDARY_FIELD,
  FEATURE_FLAGS.RCM_CLAIM_FLAG,
  FEATURE_FLAGS.RCM_CLAIM_ACTION_HISTORY,
  FEATURE_FLAGS.RCM_RELEASE_2,
  FEATURE_FLAGS.RCM_ERA_EOB_ENHANCEMENTS,
  FEATURE_FLAGS.RCM_SECONDARY_CLAIMS,
  FEATURE_FLAGS.RCM_PROVIDER_ADJUSTMENTS,
  FEATURE_FLAGS.RCM_ITD_DOO_CLAIM_ERROR_OVERLAY,
  FEATURE_FLAGS.RCM_MANAGE_ENCOUNTER_CLAIM_ERROR_OVERLAY,
];

export const INTEGRATION_FEATURE_FLAGS = [];

const lookupPracticeInfo = () =>
  store.getState().practiceInformation.item
    ? store.getState().practiceInformation.item
    : store.getState().practiceInfo.practiceInfoItems[0];

export const hasFeatureOrBeta = async feature => {
  let practiceInfo;

  while (!practiceInfo) {
    practiceInfo = lookupPracticeInfo();

    if (!practiceInfo) await new Promise(resolve => setTimeout(resolve, 200));
  }

  return !!practiceInfo.features && practiceInfo.features.includes(feature);
};

export const supporthasFeatureOrBeta = async (tenantId, feature) => {
  let practiceInfo;

  while (!practiceInfo || practiceInfo === {}) {
    practiceInfo = await getPracticeInformation(tenantId);

    if (practiceInfo === {}) {
      await new Promise(resolve => setTimeout(resolve, 200));
    }
  }

  return !!practiceInfo.features && practiceInfo.features.includes(feature);
};

export const featureGate = directive(
  (featureName, withFeature, withoutFeature = html``) => part => {
    hasFeatureOrBeta(featureName).then(enabled => {
      if (enabled) {
        part.setValue(withFeature);
      } else {
        part.setValue(withoutFeature);
      }

      part.commit();
    });
  },
);

export const getFeatures = async () => {
  let practiceInfo;

  while (!practiceInfo) {
    practiceInfo = lookupPracticeInfo();

    if (!practiceInfo) await new Promise(resolve => setTimeout(resolve, 200));
  }

  return practiceInfo.features || [];
};

export const hasFeatureOrBetaSync = feature => {
  const practiceInfo = lookupPracticeInfo();

  return (
    !!practiceInfo &&
    !!practiceInfo.features &&
    practiceInfo.features.includes(feature)
  );
};

export const hasTierSync = tier => {
  const practiceInfo = lookupPracticeInfo();

  return !!practiceInfo && practiceInfo.tier === tier;
};
