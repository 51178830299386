import * as actions from './action-creator';

const generateBaseUrl = patientId => `patients/${patientId}/problem-list`;

const formatProblem = problem => {
  const result = { ...problem };
  Object.keys(result).forEach(k => {
    if (result[k] === null || result[k] === undefined) {
      delete result[k];
    }
  });

  return result;
};

export const getProblemList = patientId =>
  actions.createFetchItemsAction(
    'problems',
    generateBaseUrl(patientId),
    undefined,
    data => {
      const newData = data.map(formatProblem);
      newData.id = patientId;
      return [newData];
    },
  );

export const saveProblemList = (patientId, problems) =>
  actions.createUpdateItemsAction({
    name: 'problems',
    url: generateBaseUrl(patientId),
    item: problems.map(formatProblem),
  });
