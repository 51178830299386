import { css, html } from 'lit';

import '../../../../components/controls/inputs/neb-checkbox';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../../../packages/neb-lit-components/src/components/neb-button';
import {
  CSS_SPACING,
  CSS_COLOR_WHITE,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_HEADER,
} from '../../../../styles';
import {
  mapToCheckInModel,
  getPatientDisplayName,
  encounterExists,
  createNebFormCheckInModel,
} from '../../utils/neb-form-check-in-model-util';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  containerForm: { id: 'container-form' },
  patientNameLabel: { id: 'patient-name-label' },
  confirmButton: { id: 'confirm-button', label: 'Complete Check In' },
  cancelButton: { id: 'cancel-button', label: 'Cancel' },
  createEncounterCheckbox: {
    id: 'create-encounter-checkbox',
    label: 'Create Encounter',
  },
};

class NebFormCheckIn extends NebForm {
  static get properties() {
    return {
      initialModel: Object,
    };
  }

  static createModel() {
    return createNebFormCheckInModel();
  }

  createSelectors() {
    return {};
  }

  initState() {
    super.initState();
    this.initialModel = {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: () => {},
      cancel: () => this.onCancel(false),
    };
  }

  update(changedProps) {
    if (changedProps.has('initialModel')) {
      this.model = mapToCheckInModel(this.initialModel);
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .form {
          display: block;
          padding: 0;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .container-form {
          flex: 1 0 0;
          display: flex;
          width: 100%;
          height: 100%;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          min-height: 0;
          overflow: auto;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) .container-form {
          flex-direction: column;
        }

        .column-left {
          width: 50%;
          margin-right: ${CSS_SPACING};
        }

        .column-right {
          width: 50%;
        }

        :host([layout='small']) .column-left {
          width: 100%;
          margin: 0 0 40px 0;
        }

        :host([layout='small']) .column-right {
          width: 100%;
          margin: 0;
        }

        .patient-name-label {
          min-height: 22px;
          margin-bottom: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .button {
          margin-right: 10px;
        }

        .action-bar-container {
          display: flex;
          flex-wrap: wrap;
          align-content: stretch;
          padding: ${CSS_SPACING};
          border-top: 1px solid ${CSS_COLOR_HIGHLIGHT};
          height: 110px;
          gap: 15px;
        }

        .container-item {
          display: flex;
          align-items: center;
          flex-basis: 100%;
        }
      `,
    ];
  }

  __renderColumnLeft() {
    return html`
      <div class="column-left">
        <div id="${ELEMENTS.patientNameLabel.id}" class="patient-name-label">
          ${getPatientDisplayName(this.state)}
        </div>
      </div>
    `;
  }

  __renderColumnRight() {
    return html`
      <div class="column-right">Right info</div>
    `;
  }

  __renderCreateEncounterCheckbox() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.createEncounterCheckbox.id}"
        name="createEncounter"
        label="${ELEMENTS.createEncounterCheckbox.label}"
        .checked="${this.state.createEncounter}"
        .onChange="${this.handlers.change}"
        .disabled="${encounterExists(this.state)}"
      ></neb-checkbox>
    `;
  }

  __renderButtons() {
    return html`
      <neb-button
        id="${ELEMENTS.confirmButton.id}"
        class="button"
        .label="${ELEMENTS.confirmButton.label}"
        .role="${BUTTON_ROLE.CONFIRM}"
        .onClick="${this.handlers.save}"
        unelevated
      ></neb-button>
      <neb-button
        id="${ELEMENTS.cancelButton.id}"
        class="button"
        .label="${ELEMENTS.cancelButton.label}"
        .role="${BUTTON_ROLE.CANCEL}"
        .onClick="${this.handlers.cancel}"
      ></neb-button>
    `;
  }

  renderActionBar() {
    return html`
      <div class="action-bar-container">
        <div class="container-item">${this.__renderButtons()}</div>
        <div class="container-item">
          ${this.__renderCreateEncounterCheckbox()}
        </div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.containerForm.id}" class="container-form">
        ${this.__renderColumnLeft()} ${this.__renderColumnRight()}
      </div>
    `;
  }
}

customElements.define('neb-form-check-in', NebFormCheckIn);
