import { openError } from '../../../store';
import { errorOccurred } from '../../../utils/user-message';

export const safeRequest = async (cb, itemDescription) => {
  try {
    await cb();
    return true;
  } catch (e) {
    console.error(e);

    openError(errorOccurred('loading', itemDescription));
    return false;
  }
};
