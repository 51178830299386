/* eslint-disable import/no-unused-modules */

import { css } from 'lit';

export const CSS_SPACING = css`20px`;
export const CSS_SPACING_ROW = css`8px`;
export const CSS_SPACING_ROW_LARGE = css`12px`;
export const CSS_BUTTON_SPACING_ROW = css`5px`;
export const CSS_FIELD_MARGIN = css`16px`;
export const CSS_COLOR_WHITE = css`#fff`;
export const CSS_COLOR_BLACK = css`#000`;
export const CSS_COLOR_BLUE = css`#c0e6ff`;
export const CSS_COLOR_ERROR = css`#bf3131`;
export const CSS_COLOR_BADGE = css`#bf3131`;
export const CSS_COLOR_GREEN = css`#d2f2a4`;
export const CSS_COLOR_GREY_1 = css`#959595`;
export const CSS_COLOR_GREY_2 = css`#dedfe0`;
export const CSS_COLOR_GREY_3 = css`#f1f1f2`;
export const CSS_COLOR_GREY_4 = css`#f4f9fc`;
export const CSS_COLOR_GREY_5 = css`#757575`;
export const CSS_COLOR_GREY_6 = css`#adadad`;
export const CSS_COLOR_GREY_7 = css`#b4b4b4`;
export const CSS_COLOR_GREY_8 = css`#a9a9a9`;
export const CSS_COLOR_GREY_9 = css`#c4c4c4`;
export const CSS_COLOR_GREY_10 = css`#fafafa`;
export const CSS_COLOR_YELLOW = css`#E26108`;
export const CSS_COLOR_DISABLED = css`#c4c4c4`;
export const CSS_COLOR_DISABLED_TEXT = css`#c4c4c4`;
export const CSS_COLOR_HIGHLIGHT = css`#0caadc`;
export const CSS_COLOR_OVERLAY_LOADING = css`rgba(255, 255, 255, 0.8)`;
export const CSS_COLOR_BLUE_BORDER = css`#0b9fcb`;
export const CSS_COLOR_RED_BORDER = css`#b00020`;
export const CSS_FONT_SIZE_BADGE = css`9px`;
export const CSS_FONT_SIZE_CAPTION = css`12px`;
export const CSS_FONT_SIZE_BODY = css`14px`;
export const CSS_FONT_SIZE_HEADER = css`16px`;
export const CSS_FONT_FAMILY = css`'Open Sans', sans-serif`;
export const CSS_FONT_WEIGHT_BOLD = css`700`;
export const CSS_PRIMARY_NAV_WIDTH = css`200px`;
export const CSS_PRIMARY_NAV_WIDTH_MEDIUM = css`65px`;
export const CSS_PRIMARY_NAV_WIDTH_LARGE = css`265px`;
export const CSS_PRIMARY_NAV_WIDTH_SMALL = css`200px`;
export const CSS_PRIMARY_NAV_SMALL_HEIGHT = css`84px`;
export const CSS_SECONDARY_NAV_HEIGHT = css`42px`;
export const CSS_SECONDARY_NAV_SMALL_HEIGHT = css`34px`;
export const CSS_SECONDARY_NAV_SEARCH_WIDTH = css`370px`;
export const CSS_PERSISTENT_ACTION_BAR_HEIGHT = css`70px`;
export const CSS_ICON_SMALL_WIDTH = css`34px`;
export const CSS_ICON_SMALL_HEIGHT = css`34px`;
export const CSS_BORDER_GREY_1 = css`1px solid ${CSS_COLOR_GREY_1}`;
export const CSS_BORDER_GREY_2 = css`1px solid ${CSS_COLOR_GREY_2}`;
export const CSS_BORDER_GREY_3 = css`1px solid ${CSS_COLOR_GREY_3}`;
export const CSS_BORDER_HIGHLIGHT = css`1px solid ${CSS_COLOR_HIGHLIGHT}`;
export const CSS_PATIENT_LIST_WIDTH = css`318px`;
export const CSS_BANNER_SUCCESS_COLOR = css`#007568`;
export const CSS_BANNER_SUCCESS_BORDER_COLOR = css`#00B4A0`;
export const CSS_BANNER_SUCCESS_BACKGROUND_COLOR = css`#D2F2EE`;
export const CSS_BANNER_ERROR_COLOR = css`#BF3131`;
export const CSS_BANNER_ERROR_BORDER_COLOR = css`#D55E5E`;
export const CSS_ERROR_BACKGROUND_COLOR = css`#F8E3E3`;
export const CSS_BANNER_INFO_COLOR = css`#0A8FB8`;
export const CSS_BANNER_INFO_BORDER_COLOR = CSS_COLOR_HIGHLIGHT;
export const CSS_BANNER_INFO_BACKGROUND_COLOR = css`#D4F0F9`;
export const CSS_WARNING_COLOR = css`#e26a08`;
export const CSS_WARNING_BACKGROUND_COLOR = css`#fef5e2`;
export const CSS_DROPDOWN_BACKGROUND_COLOR = css`#131e2a`;
export const CSS_USER_HELPER_CONTAINER_WIDTH = css`340px`;
export const CSS_PRIMARY_1 = css`#354C67`;
export const OVERLAY_WIDTH_LARGE = css`880px`;

export const baseStyles = css`
  *,
  *:before,
  *:after {
    outline: none;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
  }

  a:link,
  a:visited {
    text-decoration: none;
    color: ${CSS_COLOR_HIGHLIGHT};
  }

  p,
  div,
  span {
    margin: 0;
    padding: 0;
  }

  textarea {
    font-family: inherit;
    font-size: inherit;
  }

  button {
    display: flex;
    cursor: pointer;
    padding: 0;
    border: 0;
    align-items: center;
    background: none;
  }

  slot,
  :host {
    font-size: ${CSS_FONT_SIZE_BODY};
  }
`;

export const layoutStyles = css`
  .layout {
    display: grid;
    padding-bottom: ${CSS_SPACING};
    background-color: ${CSS_COLOR_WHITE};
    grid-gap: ${CSS_SPACING} 0;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    flex: 1 0 0;
  }

  :host([layout='small']) .layout {
    padding: ${CSS_SPACING} 0;
  }

  :host([page]:not([layout='small'])) .layout {
    margin: ${CSS_SPACING};
    padding: ${CSS_SPACING} 0;
    border-radius: 4px;
  }

  .grid {
    display: grid;
    padding: 0 ${CSS_SPACING};
    grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    align-items: center;
  }

  .grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  .grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid-lean {
    padding: 0;
  }

  :host([layout='small']) .grid-span-mobile {
    grid-template-columns: 1fr;
  }

  .grid-auto-left {
    padding: 0;
    grid-gap: 0 12px;
    grid-template-columns: auto 1fr;
  }

  .grid-auto-right {
    padding: 0;
    grid-gap: 0 12px;
    grid-template-columns: 1fr auto;
  }

  .spacer-top {
    margin-top: ${CSS_FIELD_MARGIN};
  }

  .spacer-bottom {
    margin-bottom: ${CSS_FIELD_MARGIN};
  }

  .pad {
    padding: 0 ${CSS_SPACING};
  }

  .span {
    grid-column: 1 / -1;
  }
`;

export function fauxpaque(hex, opacity) {
  const hexColor = hex
    .slice(1, 7)
    .match(/.{2}/g)
    .map(pair => {
      const color = parseInt(pair, 16);
      const limit = 255 - color;
      const delta = Math.ceil(limit * opacity);

      return (color + delta).toString(16);
    })
    .join('');

  return `#${hexColor}`;
}

export function colorFlip(hex) {
  const [R, G, B] = hex
    .slice(1, 7)
    .match(/.{2}/g)
    .map(pair => parseInt(pair, 16));

  const luma = (0.299 * R + 0.587 * G + 0.114 * B) / 255;

  return luma > 0.5 ? '#000000' : '#FFFFFF';
}

export function getHexColorFromCSS(cssObject = {}) {
  return cssObject.cssText || '';
}
