import './neb-calendar-day-column';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../packages/neb-lit-components/src/components/controls/neb-pill-switch';

import { css, html, LitElement } from 'lit';

import { getTenantId } from '../../../packages/neb-api-client/src/utils/api-client-utils';
import { parseDate } from '../../../packages/neb-utils/date-util';
import { TIME_SLOT_HEIGHT } from '../../../packages/neb-utils/neb-cal-util';
import { UpdateNotificationService } from '../../services/update-notifications';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../styles';
import { ANY } from '../../utils/update-notifications';
import { COLOR_VIEW } from '../forms/appointments/neb-form-appointment';

import {
  INTERVALS,
  MAXIMUM_END,
  MINIMUM_START,
} from './neb-base-calendar-view';
import { getZoomIntervalFromLocalStorage } from './neb-scheduling-calendar';

export const ELEMENTS = {
  calendarColumn: { id: 'calendar-column' },
  header: { id: 'header' },
  viewport: { id: 'viewport' },
  viewportTimeColumn: { id: 'viewport-time-column' },
  calendarTimeColumn: { id: 'calendar-time-column' },
  containerTimeLine: { id: 'container-time-line' },
  buttonIconZoomIn: { id: 'button-icon-zoom-in' },
  buttonIconZoomOut: { id: 'button-icon-zoom-out' },
  pillSwitch: { id: 'color-view-pill-switch' },
};

const TIME_LINE_CENTER_OFFSET = 4;
const HEADER_HEIGHT = 75;

const PROVIDER_COLOR_VIEW = {
  leftSwitch: 'Prov. Colors',
  rightSwitch: 'Room',
};

const ROOM_COLOR_VIEW = {
  leftSwitch: 'Provider',
  rightSwitch: 'Room Colors',
};

class NebAppointmentCalendarView extends LitElement {
  static get properties() {
    return {
      appointment: Object,
      model: Object,
      start: Object,
      targetDate: String,
      locationId: String,
      providerId: String,
      layout: String,
      isResizing: Boolean,
      colorView: String,
      hasSplits: Boolean,
      __timeLineTopOffset: Number,
      __zoomInterval: Number,
      __isDragging: Boolean,
    };
  }

  static get styles() {
    return [
      css`
        .columns-header-container-layout,
        .columns-container-layout {
          position: relative;
          display: flex;
        }

        .day-column {
          flex: 1;
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          border-top: 1px solid ${CSS_COLOR_GREY_2};
          border-left: 1px solid ${CSS_COLOR_GREY_2};
          flex: 1 1 800px;
          user-select: none;
          overflow: hidden;
        }

        .viewport-time-column {
          float: left;
          display: flex;
          align-items: center;
          z-index: 3;
          background-color: ${CSS_COLOR_WHITE};
          width: 60px;
        }

        .calendar-time-column {
          display: flex;
          flex-direction: column;
        }

        .viewport-timeline {
          position: absolute;
          left: 50px;
          right: 0px;
          display: flex;
          align-items: center;
          pointer-events: none;
        }

        .timeline-circle {
          height: 8px;
          width: 8px;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          border-radius: 8px;
        }

        .timeline {
          height: 1px;
          background-color: ${CSS_COLOR_HIGHLIGHT};
          flex: 1;
        }

        .viewport {
          position: relative;
          flex-basis: 600px;
          width: 600px;
          height: fit-content;
        }

        .header {
          height: ${HEADER_HEIGHT}px;
          display: flex;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 2;
          width: 100%;
          justify-content: center;
          border-bottom: 1px ${CSS_COLOR_GREY_2} solid;
        }

        .container-button-icons {
          padding-top: 23px;
          position: absolute;
          left: 0;
          user-select: none;
        }

        .button-icon {
          width: 32px;
          height: 32px;
          user-select: none;
        }

        .text-date-time {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 16px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          padding-right: 10px;
          height: 100%;
          width: 65%;
          text-align: center;
          white-space: nowrap;
        }

        .pill-switch-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 45%;
        }

        .neb-pill-switch {
          display: flex;
          height: 30px;
          grid-area: 1 / 3 / 1 / 3;
        }

        @media (max-width: 1580px) {
          .header {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          .container-button-icons {
            bottom: 0;
            flex-direction: column;
          }

          .text-date-time {
            justify-content: center;
            width: 80%;
            height: 50%;
            padding-right: 0px;
          }

          .pill-switch-container {
            padding-bottom: 10px;
            padding-right: 0px;
            width: 80%;
            height: 50%;
          }
        }

        @media (max-width: 1080px) {
          .container-button-icons {
            display: flex;
          }
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.appointment = {};
    this.model = {};
    this.start = null;
    this.providerId = '';
    this.locationId = '';
    this.targetDate = '';
    this.isResizing = false;
    this.colorView = '';
    this.hasSplits = false;

    this.onClickTimeSlot = () => {};

    this.onResizeDown = () => {};

    this.onResizeUp = () => {};

    this.onUpdateCalendar = () => {};

    this.onUpdateResizing = () => {};

    this.onToggleColorView = () => {};

    this.__isDragging = false;
    this.__timeLineTopOffset = null;
    this.__zoomInterval = 1;
  }

  __initHandlers() {
    this.handlers = {
      resizeUp: duration => {
        this.onResizeUp(duration);
      },
      resizeDown: duration => {
        this.onResizeDown(duration);
      },
      updateCalendar: () => {
        if (this.__shouldRefetch) {
          this.__shouldRefetch = false;
          this.onUpdateCalendar();
        }
      },
      updateDragging: isDragging => {
        this.__isDragging = isDragging;
      },
      updateResizing: isResizing => {
        this.onUpdateResizing(isResizing);
      },
      clickTimeSlot: ({ slot, date }) => {
        const momentDate = parseDate(date);
        momentDate.hour(slot.hour);
        momentDate.minute(slot.min);

        this.onClickTimeSlot(momentDate);
      },
      incrementZoomLevel: () => {
        const intervalIndex = INTERVALS.findIndex(
          interval => interval === this.__zoomInterval,
        );

        const nextInterval = INTERVALS[intervalIndex + 1];

        if (nextInterval) this.__zoomInterval = nextInterval;
      },
      decrementZoomLevel: () => {
        const intervalIndex = INTERVALS.findIndex(
          interval => interval === this.__zoomInterval,
        );

        const prevInterval = INTERVALS[intervalIndex - 1];

        if (prevInterval) this.__zoomInterval = prevInterval;
      },
      toggleColorView: e => {
        this.onToggleColorView(e);
      },
    };
  }

  __initServices() {
    this.__appointmentNotificationService = new UpdateNotificationService({
      callback: () => {
        this.__shouldRefetch = true;
      },
      defaultQuery: {
        appointment: ANY,
        blockedOffTime: ANY,
      },
    });
  }

  connectedCallback() {
    super.connectedCallback();

    this.__zoomInterval = getZoomIntervalFromLocalStorage(getTenantId());

    this.__timeLineInterval = setInterval(
      () => this.__updateTimeLinePosition(),
      15000,
    );

    this.__appointmentNotificationService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    clearInterval(this.__timeLineInterval);

    this.__appointmentNotificationService.disconnect();
  }

  firstUpdated() {
    this.__scrollToStart(true);
  }

  updated(changedProps) {
    if (changedProps.has('start') || changedProps.has('__zoomInterval')) {
      this.__scrollToStart(false);
    }

    if (changedProps.has('__zoomInterval')) {
      this.__scrollToStart(true);
    }

    this.__updateTimeLinePosition();
  }

  __updateTimeLinePosition() {
    const now = parseDate();

    const pixelsPerMinute = this.__zoomInterval * (TIME_SLOT_HEIGHT / 60);

    const currentMinute = now.hours() * 60 + now.minutes();

    this.__timeLineTopOffset = currentMinute * pixelsPerMinute + HEADER_HEIGHT;
  }

  __scrollToStart(initialLoad) {
    if (!this.start || this.isResizing) return;

    const pixelsPerMinute = (TIME_SLOT_HEIGHT / 60) * this.__zoomInterval;

    const numberOfMinutes = this.start.hours() * 60 + this.start.minutes();

    const halfOfWindowSize = this.clientHeight / 2;

    if (initialLoad) {
      this.__scrollTimeout = setTimeout(() => {
        this.scrollTop = numberOfMinutes * pixelsPerMinute - halfOfWindowSize;
      }, 0);
    } else {
      this.scrollTop = numberOfMinutes * pixelsPerMinute - halfOfWindowSize;
    }
  }

  __renderTimeLine() {
    return this.targetDate && this.__timeLineTopOffset !== null
      ? html`
          <div
            id="${ELEMENTS.containerTimeLine.id}"
            class="viewport-timeline"
            style="top: ${this.__timeLineTopOffset - TIME_LINE_CENTER_OFFSET}px"
          >
            <div class="timeline-circle"></div>

            <div class="timeline"></div>
          </div>
        `
      : '';
  }

  __renderDate() {
    return this.start
      ? html`
          <div class="text-date-time">
            ${this.start.format('dddd, MMM DD, YYYY')}
          </div>
        `
      : '';
  }

  __colorViewLabels() {
    return this.colorView === COLOR_VIEW.PROVIDER
      ? PROVIDER_COLOR_VIEW
      : ROOM_COLOR_VIEW;
  }

  __renderColorViewPill() {
    return html`
      <div class="pill-switch-container">
        <neb-pill-switch
          id="${ELEMENTS.pillSwitch.id}"
          class="neb-pill-switch"
          .on="${this.colorView === COLOR_VIEW.PROVIDER}"
          .labels="${this.__colorViewLabels()}"
          .onToggle="${this.handlers.toggleColorView}"
        ></neb-pill-switch>
      </div>
    `;
  }

  renderColumn() {
    let events = null;
    let masked = null;

    if (this.model) {
      events = this.model.events;
      masked = this.model.masked;
    }

    if (this.appointment) {
      if (events) {
        events = events.filter(event => event.id !== this.appointment.id);
        events.push(this.appointment);
      }
    }

    return html`
      <neb-calendar-day-column
        id="${ELEMENTS.calendarColumn.id}"
        class="day-column"
        .events="${events}"
        .maskedSlots="${masked}"
        .locationId="${this.locationId}"
        .providerId="${this.providerId}"
        .resourceId="${this.resourceId}"
        .date="${this.targetDate}"
        .zoomInterval="${this.__zoomInterval}"
        .isDragging="${this.__isDragging}"
        .isResizing="${this.isResizing}"
        .hasSplits="${this.hasSplits}"
        .onUpdateCalendar="${this.handlers.updateCalendar}"
        .onUpdateDragging="${this.handlers.updateDragging}"
        .onUpdateResizing="${this.handlers.updateResizing}"
        .onClickTimeSlot="${this.handlers.clickTimeSlot}"
        .onResizeDown="${this.handlers.resizeDown}"
        .onResizeUp="${this.handlers.resizeUp}"
        overlay
      ></neb-calendar-day-column>
    `;
  }

  render() {
    return html`
      <div id="${ELEMENTS.viewport.id}" class="viewport">
        <div id="${ELEMENTS.header.id}" class="header">
          <div class="container-button-icons">
            <neb-button-icon
              id="${ELEMENTS.buttonIconZoomIn.id}"
              class="button-icon"
              icon="neb:zoomIn"
              .onClick="${this.handlers.incrementZoomLevel}"
            ></neb-button-icon>

            <neb-button-icon
              id="${ELEMENTS.buttonIconZoomOut.id}"
              class="button-icon zoom-out"
              icon="neb:zoomOut"
              .onClick="${this.handlers.decrementZoomLevel}"
            ></neb-button-icon>
          </div>

          ${this.__renderDate()} ${this.__renderColorViewPill()}
        </div>

        <div
          id="${ELEMENTS.viewportTimeColumn.id}"
          class="viewport-time-column"
        >
          <neb-calendar-time-column
            id="${ELEMENTS.calendarTimeColumn.id}"
            class="calendar-time-column"
            .interval="${this.__zoomInterval}"
            .start="${MINIMUM_START}"
            .end="${MAXIMUM_END}"
          ></neb-calendar-time-column>
        </div>

        <div class="columns-container-layout">${this.renderColumn()}</div>

        ${this.__renderTimeLine()}
      </div>
    `;
  }
}

customElements.define(
  'neb-appointment-calendar-view',
  NebAppointmentCalendarView,
);
