import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient();

export const CACHE_KEY = 'tenant-logo';
export const PATH = 'logo';

export function fetch() {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    path: PATH,
    responseType: RESPONSE_TYPE.DATA_URL,
  });
}

export function fetchPublic() {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    path: PATH,
    responseType: RESPONSE_TYPE.DATA_URL,
    version: 'public1',
  });
}

export function remove() {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    cacheKey: CACHE_KEY,
    method: Method.DELETE,
    path: PATH,
    responseType: RESPONSE_TYPE.RAW,
  });
}

export function update(blob) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: blob,
    cacheKey: CACHE_KEY,
    headers: {
      'Content-Type': blob.type,
    },
    method: Method.PUT,
    path: PATH,
    responseType: RESPONSE_TYPE.RAW,
  });
}
