import '../../../../../src/components/forms/neb-form-feature-flags';
import { LitElement, html, css } from 'lit';

import * as featureFlagApiClient from '../../../../../src/api-clients/feature-flags';
import { CSS_COLOR_WHITE } from '../../../../../src/styles';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { openDirtyPopup } from '../../../../neb-popup';
import { store } from '../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import { FEATURE_FLAGS, getFeatures } from '../../../../neb-utils/feature-util';

export const ELEMENTS = {
  collection: {
    id: 'collection',
  },
  header: {
    id: 'header',
  },
  featureForm: {
    id: 'feature-form',
  },
  searchBar: {
    id: 'search-bar',
  },
};

class PageFeatureFlags extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
      __model: Array,
      __featureFormSavingError: Object,
      __searchText: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          margin: 20px;
        }

        .content {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        }

        .form {
          overflow-y: auto;
          padding-top: 0;
        }

        .search-bar {
          background-color: ${CSS_COLOR_WHITE};
          padding: 20px;
          width: 100%;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__model = [];
    this.__isDirty = false;
    this.__featureFormSavingError = null;
    this.__searchText = '';
    this.__filteredFlags = [];
  }

  __initHandlers() {
    this.__handlers = {
      changeDirty: dirty => {
        this.__isDirty = dirty;
      },
      cancel: async () => {
        if (this.__isDirty) {
          if (await openDirtyPopup()) {
            this.__isDirty = false;
            this.__model = [...this.__model];
          }
        }
      },
      save: async features => {
        try {
          this.__savingError = null;

          await featureFlagApiClient.update(features);

          store.dispatch(openSuccess('Feature Access saved successfully'));

          this.__model = features;
        } catch (e) {
          store.dispatch(
            openError('An error occurred when saving Feature Access'),
          );

          console.error(e);
          this.__featureFormSavingError = e;
        }
      },
      search: searchText => {
        this.__searchText = searchText;

        const searchTerms = searchText
          .trim()
          .toLowerCase()
          .split(' ');

        const allFlags = Object.values(FEATURE_FLAGS);
        const filteredFlags = allFlags.filter(flag =>
          searchTerms.every(term => flag.includes(term)),
        );

        this.__filteredFlags =
          searchTerms && filteredFlags.length ? filteredFlags : ['none'];
      },
    };
  }

  async __fetch() {
    const features = await getFeatures();
    this.__model = features;
  }

  async connectedCallback() {
    await this.__fetch();

    super.connectedCallback();
  }

  render() {
    return html`
      <div class="content">
        <neb-search-bar
          id="${ELEMENTS.searchBar.id}"
          class="search-bar"
          placeholderText="Search Feature Flags"
          .searchText="${this.__searchText}"
          .onSearch="${this.__handlers.search}"
        ></neb-search-bar>
        <neb-form-feature-flags
          id="${ELEMENTS.featureForm.id}"
          class="form"
          .model="${this.__model}"
          .onCancel="${this.__handlers.cancel}"
          .onSave="${this.__handlers.save}"
          .onChangeDirty="${this.__handlers.changeDirty}"
          .savingError="${this.__featureFormSavingError}"
          .filteredFlags="${this.__filteredFlags}"
        ></neb-form-feature-flags>
        <div></div>
      </div>
    `;
  }
}

window.customElements.define('neb-page-feature-flags', PageFeatureFlags);
