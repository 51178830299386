import '../../neb-popup-header';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import * as clearinghouseApi from '../../../../../../src/api-clients/clearinghouse';
import { getLocations } from '../../../../../../src/api-clients/locations';
import { getPayerGroups } from '../../../../../neb-api-client/src/billing/payer-groups';
import {
  savePayerPlan,
  updatePayerPlan,
  getPayerPlan,
  getPayerPlans,
  PAYER_PLAN_VERSION_CLAIMS,
  getPayerPlanAssociatedRules,
} from '../../../../../neb-api-client/src/payer-plan-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import { mapToV3Raw } from '../../../../../neb-utils/payer-plan-util';
import FormPayerPlan from '../../forms/neb-form-payer-plan';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

export const BANNER_MESSAGE = {
  success: 'Payer Plan saved successfully',
  error: 'An error occurred when saving the Payer Plan',
};

class NebOverlayPayerPlan extends Overlay {
  static get properties() {
    return {
      __activeClearinghouses: Array,
      __activeLocations: Array,
      __activeServiceLocations: Array,
      __context: Object,
      __payerGroups: Array,
      __payerPlan: Object,
      __selectedClearinghousePayerPlan: Object,
      __payers: Array,
      __rules: Array,
      __names: Array,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 880px;
        }

        .container {
          display: flex;
          flex-direction: column;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.initState();
    this.initHandlers();
  }

  initState() {
    super.initState();

    this.__context = {
      rteEnabled: false,
    };

    this.__payerPlan = FormPayerPlan.createModel();
    this.__rules = [];
    this.__names = [];
    this.__payerGroups = [];
    this.__selectedClearinghousePayerPlan = {};
    this.__activeLocations = [];
    this.__activeServiceLocations = [];
    this.__activeClearinghouses = [];
    this.__payers = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      savePayerPlan: async model => {
        const body = mapToV3Raw(model, this.__activeClearinghouses);

        try {
          const result = model.id
            ? await updatePayerPlan(model.id, body, 4)
            : await savePayerPlan(body, 4);

          store.dispatch(openSuccess(BANNER_MESSAGE.success));

          this.isDirty = false;
          this.handlers.dismiss(result);
        } catch (err) {
          if (err.message.includes('409')) {
            await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: 'Duplicate Alias',
              message: `There is an existing record matching this payer's Alias.
                Please review record details and create new or update existing payer accordingly.`,
            });
          } else store.dispatch(openError(BANNER_MESSAGE.error));
        }
      },
      fetchRules: () => this.__fetchRules(),
    };
  }

  __getLocations() {
    return getLocations({ hideInactive: true });
  }

  async __getPayerGroups() {
    const res = await getPayerGroups();
    return res.data;
  }

  async __getActiveClearinghouses() {
    const clearinghousesWithCHC = await clearinghouseApi.getClearinghousesWithCHC();
    return clearinghousesWithCHC.filter(ch => ch.active);
  }

  async connectedCallback() {
    const [
      activeLocations,
      payerGroups,
      activeClearinghouses,
    ] = await Promise.all([
      this.__getLocations(),
      this.__getPayerGroups(),
      this.__getActiveClearinghouses(),
    ]);

    this.__activeLocations = activeLocations;
    this.__activeServiceLocations = this.__activeLocations.filter(
      location => !location.addressOnly,
    );

    this.__payerGroups = payerGroups;
    this.__activeClearinghouses = activeClearinghouses;

    super.connectedCallback();
  }

  async __getPayers() {
    const { payers } = this.model;
    if (payers) return payers;

    const { payerPlan } = await getPayerPlans(
      { limit: 999 },
      PAYER_PLAN_VERSION_CLAIMS,
    );

    return payerPlan;
  }

  async __fetchRules() {
    const { rules, names } = await getPayerPlanAssociatedRules(this.model.id);

    this.__rules = rules;
    this.__names = names;
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.id) {
        this.__payerPlan = await getPayerPlan(this.model.id, 4);

        await this.__fetchRules();
      }

      this.__payers = await this.__getPayers();

      if (this.model.context && this.model.context.rteEnabled) {
        this.__payerPlan = {
          ...this.__payerPlan,
          realTimeEligibilityEnabled: true,
        };
      }
    }

    super.updated(changedProps);
  }

  renderContent() {
    return html`
      <div class="container">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          .title="${this.model.id ? 'Update Payer Plan' : 'Add Payer Plan'}"
          .onCancel="${this.handlers.dismiss}"
          showCancelButton
        ></neb-popup-header>

        <neb-form-payer-plan
          id="${ELEMENTS.form.id}"
          class="container"
          .model="${this.__payerPlan}"
          .layout="${this.layout}"
          .onChangeDirty="${this.handlers.dirty}"
          .onUpdateRuleSet="${this.handlers.fetchRules}"
          .onCancel="${this.handlers.dismiss}"
          .onSave="${this.handlers.savePayerPlan}"
          .activeLocations="${this.__activeLocations}"
          .activeServiceLocations="${this.__activeServiceLocations}"
          .payerGroups="${this.__payerGroups || []}"
          .payers="${this.__payers}"
          .rules="${this.__rules}"
          .names="${this.__names}"
          .activeClearinghouses="${this.__activeClearinghouses || []}"
        ></neb-form-payer-plan>
      </div>
    `;
  }
}

window.customElements.define('neb-overlay-payer-plan', NebOverlayPayerPlan);
