import '../../../../packages/neb-www-practice-charting/src/components/notes/neb-charting-notes-detail';

import { html, css } from 'lit';

import { getDocuments } from '../../../../packages/neb-api-client/src/document-api-client';
import {
  getAllEncounters,
  getEncounterApptsWithSelfCheckInNarratives,
} from '../../../../packages/neb-api-client/src/encounters-api-client';
import { getMacroSetList } from '../../../../packages/neb-api-client/src/macro-set-api-client';
import { saveNotes } from '../../../../packages/neb-api-client/src/notes';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { MACRO_SET_STATUS } from '../../../../packages/neb-utils/macro-sets';
import {
  formatCurrentEncounterApptsWithNarrative,
  formatEncounterCards,
} from '../../../../packages/neb-utils/neb-charting-util';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-detail';
import { safeRequest } from '../shared/safe-request';

import {
  getNotesItemFromLocalStorage,
  removeNotesItemFromLocalStorage,
  setNotesItemInLocalStorage,
} from './neb-chart-notes-local-storage-util';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
};

export const EMPTY_STRUCTURED_DOCUMENT_LENGTH = 2;

const ITEM_NAME = 'Chart Notes';

class NebUnsignedEncounterNotesDetail extends NebUnsignedEncounterDetail {
  static get properties() {
    return {
      __currentEncounterApptsWithNarratives: { type: Array },
      __intakeQuestionnaires: { type: Array },
      __macroSets: { type: Array },
      __priorEncounters: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          overflow: hidden;
        }
      `,
    ];
  }

  static get config() {
    return {
      itemName: ITEM_NAME,
      refetchKey: PANEL_KEYS.CHART_NOTES,
    };
  }

  static createModel() {
    return {
      subjective: '',
      objective: '',
      assessment: '',
      plan: '',
    };
  }

  get __elements() {
    return {
      view: this.shadowRoot.getElementById(ELEMENTS.view.id),
    };
  }

  initState() {
    super.initState();

    this.__currentEncounterApptsWithNarratives = [];
    this.__intakeQuestionnaires = [];
    this.__macroSets = [];
    this.__priorEncounters = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      navigateToPanel: panel => {
        setNotesItemInLocalStorage({
          detail: this.__elements.view.getState(),
          encounterId: this.encounter.id,
        });

        this.__preventDirtyChecking = true;

        this.onClose({ navigateTo: panel });
      },
    };
  }

  isDirty() {
    return this.__elements.view.isDirty();
  }

  async save(notes) {
    await saveNotes(notes, this.encounter.id);
  }

  firstUpdated() {
    safeRequest(async () => {
      const { data: allMacroSets } = await getMacroSetList();

      this.__macroSets = allMacroSets.filter(
        ({ status }) => status === MACRO_SET_STATUS.ACTIVE,
      );
    }, 'Macro Sets');
  }

  updated(changedProps) {
    if (changedProps.has('encounter') && this.encounter.id) {
      const notesItem = getNotesItemFromLocalStorage();

      if (notesItem) {
        removeNotesItemFromLocalStorage();

        if (notesItem.encounterId === this.encounter.id) {
          this.__elements.view.setState(notesItem.detail);
        }
      }

      this.__fetchIntakeQuestionnaires();
      this.__fetchSelfCheckInNarratives();
      this.__fetchPriorEncounters();
    }
  }

  __fetchIntakeQuestionnaires() {
    safeRequest(async () => {
      const { data: documents } = await getDocuments(
        this.encounter.patientId,
        999,
        0,
        null,
        null,
        null,
        null,
        true,
      );

      this.__intakeQuestionnaires = documents
        .filter(
          doc =>
            doc.documentData &&
            doc.documentData.length > EMPTY_STRUCTURED_DOCUMENT_LENGTH &&
            doc.isQuestionnaire,
        )
        .sort((a, b) => b.uploadDate.localeCompare(a.uploadDate))
        .map(doc => ({ value: doc, displayValue: doc.name }));
    }, 'Intake Questionnaires');
  }

  __fetchSelfCheckInNarratives() {
    safeRequest(async () => {
      const narratives = await getEncounterApptsWithSelfCheckInNarratives(
        this.encounter.id,
        true,
      );

      if (narratives && narratives.length) {
        const { providers, appointmentTypes } = store.getState();

        this.__currentEncounterApptsWithNarratives = formatCurrentEncounterApptsWithNarrative(
          narratives,
          providers.item,
          appointmentTypes.items,
        );
      }
    }, 'Self Check-in Narratives');
  }

  __fetchPriorEncounters() {
    safeRequest(async () => {
      const { data: priorEncounters } = await getAllEncounters(
        this.encounter.patientId,
        {
          encounterId: this.encounter.id,
        },
        1,
        true,
      );

      if (priorEncounters && priorEncounters.length) {
        const { providers, appointmentTypes } = store.getState();

        this.__priorEncounters = formatEncounterCards(
          priorEncounters,
          providers.item,
          appointmentTypes.items,
          this.__locations,
        );
      }
    }, 'Prior Encounters');
  }

  getTitle() {
    return ITEM_NAME;
  }

  renderContent() {
    return html`
      <neb-charting-notes-detail
        id="${ELEMENTS.view.id}"
        .currentEncounterApptsWithNarratives="${
          this.__currentEncounterApptsWithNarratives
        }"
        .docsEnabled="${!!this.__intakeQuestionnaires.length}"
        .documents="${this.__intakeQuestionnaires}"
        .encounter="${this.encounter}"
        .expanded="${true}"
        .initialNotes="${this.model}"
        .layout="${this.layout}"
        .macroSets="${this.__macroSets}"
        .priorEncounters="${this.__priorEncounters}"
        .onCancel="${this.handlers.cancel}"
        .onNavButtonClicked="${this.handlers.navigateToPanel}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.changeDirty}"
      ></neb-charting-notes-detail>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-notes-detail',
  NebUnsignedEncounterNotesDetail,
);
