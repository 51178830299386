import { openPopup } from '@neb/popup';
import { css, html } from 'lit';
import moment from 'moment-timezone';

import { uploadFile } from '../../../packages/neb-api-client/src/files-api-client';
import {
  openError,
  openInfo,
  openSuccess,
} from '../../../packages/neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import { SORT_DIR } from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { downloadURLtoFile } from '../../../packages/neb-utils/blobProcessor';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../packages/neb-utils/feature-util';
import '../../../packages/neb-lit-components/src/components/neb-tooltip';
import * as clearinghouseApi from '../../api-clients/clearinghouse';
import * as ePaymentFilesApi from '../../api-clients/epayment-files';
import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import * as eraEobApi from '../../api-clients/era-eob';
import { CSS_SPACING, layoutStyles, baseStyles } from '../../styles';
import { getDisplayClearinghouseName } from '../../utils/e-claim-files';
import {
  EPAYMENT_FILE_TOO_LARGE_MESSAGE,
  EPAYMENT_FILE_VALID_SIZE_MESSAGE,
  EPAYMENT_FILE_SIZE_MESSAGE,
  EPAYMENT_MAX_FILE_SIZE_MESSAGE,
  successfullyMessage,
  errorOccurred,
} from '../../utils/user-message';
import { CONFIG } from '../tables/practice/clearinghouse/neb-table-epayment-file-upload';

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

const REFRESH_LABEL = 'REFRESH ALL';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonIcons: { selector: '.button-icon' },
  dates: { selector: '.date' },
  inputFile: { id: 'input-file' },
  buttonUploadFile: { id: 'button-upload-file' },
  buttonRefresh: { id: 'button-refresh' },
  label: { id: 'label' },
  form: { id: 'form' },
  selectClearinghouse: { id: 'select-clearinghouse' },
  tooltipEraDownloadDisabled: { id: 'tooltip-era-download-disabled' },
  tooltipTextEraDownloadDisabled: { id: 'tooltip-era-download-disabled-text' },
};
class CollectionPageEpaymentFile extends CollectionPage {
  static get config() {
    return {
      showInactiveFilter: null,
      singularName: 'ePayment File',
      pluralName: 'ePayment Files',
      initialSortKey: 'createdAt',
      initialSortOrder: SORT_DIR.DESC,
      description: '',
      tableConfig: CONFIG,
      hideHeader: true,
    };
  }

  static get properties() {
    return {
      clearinghouses: Array,
      __defaultClearinghouse: Object,
      __selectedClearinghouse: Object,
      __hasAcknowledgementFeatureFlag: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      layoutStyles,
      baseStyles,
      css`
        .container {
          padding-top: 0px;
        }
        .container-buttons {
          display: flex;
          gap: ${CSS_SPACING};
          align-items: center;
        }
        .button {
          padding-bottom: 16px;
        }
        .tooltip {
          margin-bottom: 19px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.clearinghouses = [];
    this.__defaultClearinghouse = {};
    this.__selectedClearinghouse = {};
    this.__errors = {
      clearinghouse: '',
    };

    this.__hasAcknowledgementFeatureFlag = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      sort: (_, result) => {
        this.service.setSortParams(result[0]);
      },

      selectFiles: async e => {
        const { files: rawFiles } = e.currentTarget;
        const files = await this.__getValidFiles(
          this.__mapFiles(rawFiles, this.__selectedClearinghouse.data),
        );

        if (files.length) {
          const filesCount = await openPopup(
            POPUP_RENDER_KEYS.UPLOAD_PROCESSING,
            {
              files,
              clearinghouse: this.__selectedClearinghouse.data,
              uploadFile,
            },
          );

          store.dispatch(openInfo(`${filesCount} Files Uploaded`));
          const items = await this.fetch();
          this.service.setItems(items);
        }
      },

      changeSelectedClearinghouse: ({ value }) => {
        this.__selectedClearinghouse = value;
        this.__validate();
      },
      refreshAll: async () => {
        const items = await this.fetch();
        this.service.setItems(items);
      },
      tableChange: e => {
        const terms = e.name.split('.');
        const index = terms[0];

        const updatedItem = { ...this.__tableState.pageItems[index] };
        updatedItem.checked = e.value;

        this.__tableState.pageItems.splice(index, 1, updatedItem);
        this.__tableState = {
          ...this.__tableState,
          pageItems: [...this.__tableState.pageItems],
        };
      },
      selectAll: () => {
        this.__tableState = this.__setCheckboxes(true);
      },
      deselectAll: () => {
        this.__tableState = this.__setCheckboxes(false);
      },

      resendSelected: async () => {
        const selectedEPaymentFiles = this.__tableState.pageItems
          .filter(item => item.checked)
          .map(item => ({
            id: item.id,
            s3KeyX12: item.s3KeyX12,
            clearinghousePartnerName: this.__getPartnerByClearinghouseId(
              item.clearinghouseId,
            ),
          }));

        try {
          await ePaymentFilesApi.uploadEPaymentFiles(selectedEPaymentFiles);

          store.dispatch(openSuccess('Resending'));
        } catch (e) {
          console.error(e);

          store.dispatch(
            openError('An error occurred when attempting to resend'),
          );
        }

        const items = await this.fetch();
        this.service.setItems(items);
      },
      openNoValidResends: () =>
        openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: 'Resend Selected',
          message: 'Please select files to resend',
        }),
      clickUploadFile: () => {
        this.__elements.inputFile.click();
      },
      manageEClaimERA: async e => {
        const terms = e.name.split('.');
        const index = terms[0];
        const reportCellInformation = terms[1].split('-');
        const selectedReportNumber = reportCellInformation[1];

        const item = this.__tableState.pageItems[index];

        const selectedEClaimERA = item.eClaimERAs.find(
          eClaimERA => eClaimERA.reportNumber === Number(selectedReportNumber),
        );

        const eClaimERA = await eraEobApi.getDataById({
          id: selectedEClaimERA.id,
          type: 'ERA',
        });

        await openOverlay(OVERLAY_KEYS.ERA_EOB_MANAGEMENT, {
          readonly: false,
          era: eClaimERA.data[0],
        });
      },
      downloadFile: async index => {
        try {
          const { id: ePaymentFileId, fileName } = this.__tableState.pageItems[
            index
          ];

          const url = await ePaymentFilesApi.downloadEPaymentOrOtherFileById(
            ePaymentFileId,
          );

          downloadURLtoFile(url, fileName);

          store.dispatch(
            openSuccess(successfullyMessage('File', 'downloaded')),
          );
        } catch (e) {
          console.error(e);
          store.dispatch(openError(errorOccurred('downloading', 'file')));
        }
      },
    };
  }

  __validate() {
    this.__errors = {
      ...this.__errors,
      clearinghouse: this.__selectedClearinghouse.data ? '' : 'Required',
    };
  }

  __setCheckboxes(checked) {
    this.__tableState.pageItems = this.__tableState.pageItems.map(item => {
      if (checked === true) {
        return {
          ...item,
          checked,
        };
      }
      return { ...item, checked: false };
    });

    return { ...this.__tableState };
  }

  __getPartnerByClearinghouseId(value) {
    const clearinghouse = this.clearinghouses.find(ch => ch.data.id === value);
    return clearinghouse
      ? `${getDisplayClearinghouseName(clearinghouse.data)}`
      : '';
  }

  __buildTable() {
    return [
      {
        key: 'checked',
        label: '',
        flex: css`0 0 28px`,
      },
      {
        key: 'createdAt',
        label: 'Uploaded',
        flex: css`1 0 0`,
        sortable: true,
        formatter: value =>
          value ? moment(value).format('MM/DD/YYYY h:mm:ss A') : '',
      },
      {
        key: 'fileName',
        label: 'File Name',
        flex: css`1 0 0`,
      },
      {
        key: 'eClaimERAs',
        label: 'ERA',
        flex: css`0 0 200px`,
      },
      {
        key: 'clearinghouseId',
        label: 'Clearinghouse',
        flex: css`1 0 0`,
        formatter: value => `${this.__getPartnerByClearinghouseId(value)} `,
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`0 0 100px`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`2 0 0`,
      },
      ...(this.__hasAcknowledgementFeatureFlag
        ? [
            {
              key: 's3KeyX12',
              label: '',
              flex: css`.5 0 0`,
            },
          ]
        : []),
    ];
  }

  async connectedCallback() {
    this.__hasAcknowledgementFeatureFlag = await hasFeatureOrBeta(
      FEATURE_FLAGS.ACKNOWLEDGEMENT,
    );

    await this.__getAllClearinghouses();
    this.__validate();

    super.connectedCallback();
  }

  __mapFiles(files) {
    const formatted = [];

    for (let i = 0; i < files.length; i++) {
      formatted.push(files[i]);
    }

    return formatted;
  }

  async __getAllClearinghouses() {
    const res = await clearinghouseApi.fetchMany();
    this.clearinghouses = res
      .filter(ch => ch.active)
      .map(clearinghouse => ({
        label: `${getDisplayClearinghouseName(clearinghouse)}`,
        data: clearinghouse,
      }));
  }

  async __getValidFiles(files) {
    let accepted = true;

    const validFiles = [];
    const invalidFiles = [];

    files.forEach(file => {
      if (file.size <= MAX_FILE_SIZE) validFiles.push(file);
      else invalidFiles.push(file);
    });

    if (invalidFiles.length) {
      if (validFiles.length) {
        accepted = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
          title: EPAYMENT_FILE_TOO_LARGE_MESSAGE(
            invalidFiles.length,
            files.length,
          ),
          message: EPAYMENT_FILE_VALID_SIZE_MESSAGE,
          confirmText: 'YES',
          cancelText: 'NO',
        });
      } else {
        await openPopup(POPUP_RENDER_KEYS.MESSAGE, {
          title: EPAYMENT_FILE_SIZE_MESSAGE,
          message: EPAYMENT_MAX_FILE_SIZE_MESSAGE,
          confirmText: 'Ok',
        });
      }
    }

    return accepted ? validFiles : [];
  }

  __getTooltipText(downloadDisabledClearinghouses) {
    const clearinghouseCount = downloadDisabledClearinghouses.length;
    const clearinghouseNames = downloadDisabledClearinghouses.map(
      clearinghouse => clearinghouse.label,
    );

    let tooltipText = '';

    if (clearinghouseCount === 1) {
      tooltipText = `You have disabled auto-downloading of ERAs for ${
        clearinghouseNames[0]
      }.`;
    } else if (clearinghouseCount === 2) {
      tooltipText = `You have disabled auto-downloading of ERAs for ${
        clearinghouseNames[0]
      } and ${clearinghouseNames[1]}.`;
    } else {
      const lastClearinghouse = clearinghouseNames.pop();
      const clearinghouseNamesString = clearinghouseNames.join(', ');
      tooltipText = `You have disabled auto-downloading of ERAs for ${clearinghouseNamesString}, and ${lastClearinghouse}.`;
    }

    tooltipText +=
      ' Please be sure to download those files directly from the clearinghouse.';

    return tooltipText;
  }

  firstUpdated() {
    this.__elements = {
      inputFile: this.shadowRoot.getElementById(ELEMENTS.inputFile.id),
    };
  }

  async fetch() {
    const data = await ePaymentFilesApi.getEPaymentFiles(2);

    return data;
  }

  __renderAddButton() {
    const sortClearinghouse = this.clearinghouses.sort((a, b) =>
      a.label.localeCompare(b.label),
    );

    return html`
      <div class="container-buttons">
        <neb-select
          id="${ELEMENTS.selectClearinghouse.id}"
          class="clearinghouse"
          helper="Required"
          placeholder="Clearinghouse"
          .items="${this.clearinghouses}"
          .value="${this.__selectedClearinghouse}"
          .onChange="${this.handlers.changeSelectedClearinghouse}"
          .sort="${sortClearinghouse}"
          .error="${this.__errors.clearinghouse}"
        ></neb-select>

        <input
          id="${ELEMENTS.inputFile.id}"
          type="file"
          multiple
          hidden
          @change="${this.handlers.selectFiles}"
          onclick="this.value=null;"
        />

        <neb-button
          id="${ELEMENTS.buttonUploadFile.id}"
          class="button"
          label="Upload ERA File"
          role="${BUTTON_ROLE.OUTLINE}"
          .onClick="${this.handlers.clickUploadFile}"
          .disabled="${!this.__selectedClearinghouse.data}"
        ></neb-button>

        <neb-button
          id="${ELEMENTS.buttonRefresh.id}"
          class="button"
          @click="${this.handlers.refreshAll}"
          .label="${REFRESH_LABEL}"
        >
        </neb-button>
        ${this.renderDownloadDisabledTooltip()}
      </div>
    `;
  }

  renderDownloadDisabledTooltip() {
    const downloadDisabledClearinghouses = this.clearinghouses.filter(
      clearinghouse =>
        clearinghouse.data.sftpActive === true &&
        clearinghouse.data.sftpDownloadActive === false,
    );

    let tooltipText = '';

    if (downloadDisabledClearinghouses.length) {
      tooltipText = this.__getTooltipText(downloadDisabledClearinghouses);
    }

    return downloadDisabledClearinghouses.length
      ? html`
          <neb-tooltip
            id="${ELEMENTS.tooltipEraDownloadDisabled.id}"
            class="tooltip"
            icon="neb:infoMinimal"
            defaultAnchor="bottom"
          >
            <div
              id="${ELEMENTS.tooltipTextEraDownloadDisabled.id}"
              slot="tooltip"
            >
              ${tooltipText}
            </div>
          </neb-tooltip>
        `
      : '';
  }

  renderNoItemsContent() {
    return html`
      <neb-text>No items.</neb-text>
    `;
  }

  renderTable() {
    return html`
      <neb-table-epayment-file-upload
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .model="${this.__tableState.pageItems}"
        .config="${this.__buildTable()}"
        .onSort="${this.handlers.sort}"
        .sortParams="${[this.__tableState.sortParams]}"
        .onChange="${this.handlers.tableChange}"
        .onSelectAll="${this.handlers.selectAll}"
        .onDeselectAll="${this.handlers.deselectAll}"
        .onResendSelected="${this.handlers.resendSelected}"
        .onOpenNoValidResends="${this.handlers.openNoValidResends}"
        .onSelectERA="${this.handlers.manageEClaimERA}"
        .onDownloadFile="${this.handlers.downloadFile}"
      ></neb-table-epayment-file-upload>
    `;
  }
}

window.customElements.define(
  'neb-collection-page-epayment-file',
  CollectionPageEpaymentFile,
);
