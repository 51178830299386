import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({
  microservice: 'api',
});

export const getProblemList = async patientId => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/problem-list`,
  });

  return res.data;
};
