import * as patientPhotoApi from '../../packages/neb-api-client/src/patient-image-api-client';

export const sortPatients = patients =>
  // eslint-disable-next-line complexity
  patients.sort((a, b) => {
    if (a.data.name.last < b.data.name.last) return -1;
    if (a.data.name.last > b.data.name.last) return 1;
    if (a.data.name.first < b.data.name.first) return -1;
    if (a.data.name.first > b.data.name.first) return 1;
    if (a.data.name.middle < b.data.name.middle) return -1;
    if (a.data.name.middle > b.data.data.name.middle) return 1;
    if (a.data.name.preferred < b.data.name.preferred) return -1;
    if (a.data.name.preferred > b.data.name.preferred) return 1;
    return 0;
  });

export const searchPatients = (patientsList, value) => {
  const removeSpecialCharacters = str => str.replace(/[^a-zA-Z0-9 ]/g, '');

  const searchTerms = value
    .trim()
    .toLowerCase()
    .split(' ');

  return patientsList.filter(item => {
    const normalizedLabel = item.label.toLowerCase();

    return searchTerms.every(term =>
      normalizedLabel.includes(removeSpecialCharacters(term)),
    );
  });
};

export const fetchPhoto = async patientId => {
  try {
    const photoSrc = await patientPhotoApi.getPatientImage(
      patientId,
      'large',
      true,
    );
    return photoSrc;
  } catch (error) {
    return console.error(
      `Failed to fetch photo for patient ${patientId}:`,
      error,
    );
  }
};
