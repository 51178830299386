import '../neb-lit-components/src/components/encounter/neb-encounter-card';

import { html } from 'lit';

import { parseDate } from './date-util';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  HIDE_PREFERRED_OPTS,
} from './formatters';

export const SERVICE_DATE_FORMAT = 'MM/DD/YYYY';
export const SERVICE_TIME_FORMAT = 'h:mm A';
export const SERVICE_FULL_DATE_FORMAT = 'MM/DD/YYYY h:mm A';
export const SERVICE_DATE_LONG_FORMAT = 'MMMM DD, YYYY';

const findById = (items, id) => items.find(item => item.id === id);

export const NO_PRIOR_ENCOUNTERS_MESSAGE =
  'There are no prior encounters for this patient.';
export const soapNoteExists = note => note && note.length > 0;

export const getPatientDisplayName = (patient, opts = DEFAULT_NAME_OPTS) => {
  const {
    firstName: first,
    lastName: last,
    middleName: middle,
    preferredName: preferred,
    suffix,
  } = patient;

  return objToName({ first, last, middle, preferred, suffix }, opts);
};

export const formatEncounterCardDropdown = encounters => {
  if (!encounters) return [];
  return encounters.map((enc, index) => {
    const model = {
      markerColor: enc.markerColor,
      formattedServiceDate: enc.formattedServiceDate,
      formattedServiceTime: enc.formattedServiceTime,
      provider: enc.provider,
      appointmentType: enc.appointmentType,
      encounterNumber: enc.encounterNumber,
      locationName: enc.locationName,
    };
    return {
      value: enc.id,
      displayValue: enc.formattedServiceDate,
      displayHtml: () => html`
        <neb-encounter-card
          id="encounter-card-${index}"
          .layout="large"
          .model="${model}"
        ></neb-encounter-card>
      `,
    };
  });
};

export const formatSectionTitle = (modelName, model) => {
  const modelCount = model && model.length > 0 ? ` (${model.length})` : '';
  return `${modelName}${modelCount}`;
};

export function getPatientId(state) {
  return state.charting &&
    state.charting.encounterId &&
    state.encounters &&
    state.encounters[state.charting.encounterId]
    ? state.encounters[state.charting.encounterId].patientId
    : null;
}

export function addProviderNameToProblem(state, problem) {
  const list = state.providers.item;
  const provider = list.find(provider => provider.id === problem.providerId);
  return {
    ...problem,
    providerName: provider ? objToName(provider.name, DEFAULT_NAME_OPTS) : '',
  };
}

export function getFormatedNameOptions(summary = false) {
  return summary ? HIDE_PREFERRED_OPTS : DEFAULT_NAME_OPTS;
}

export function formatProviderName(id, providers, summary = false) {
  const OPTS = getFormatedNameOptions(summary);
  const provider = findById(providers, id);
  return provider ? objToName(provider.name, OPTS) : '';
}

function getAppointmentTypeName(id, appointmentTypes) {
  const appointmentType = findById(appointmentTypes, id);
  return appointmentType.name;
}

export function formatServiceDate(encounter) {
  const serviceDateTime = parseDate(encounter.serviceDate);
  return {
    serviceDate: encounter.serviceDate,
    formattedServiceDate: serviceDateTime.format(SERVICE_DATE_FORMAT),
    formattedServiceTime: serviceDateTime.format(SERVICE_TIME_FORMAT),
    fullDate: serviceDateTime.format(SERVICE_FULL_DATE_FORMAT),
  };
}

function formatSignedData(encounter, providers) {
  const dateTime = parseDate(encounter.signedOn);
  return {
    signerId: encounter.signerId,
    signedDate: !dateTime ? null : dateTime.format(SERVICE_DATE_FORMAT),
    signedTime: !dateTime ? null : dateTime.format(SERVICE_TIME_FORMAT),
    signedDateTime: !dateTime
      ? null
      : dateTime.format(SERVICE_FULL_DATE_FORMAT),
    signerName: formatProviderName(encounter.signerId, providers, true),
    signedFileS3Key: encounter.signedFileS3Key,
  };
}

export function formatEncounter(
  encounter,
  providers,
  appointmentTypes,
  summary = false,
) {
  const provider = formatProviderName(encounter.providerId, providers, summary);
  const appointmentType = getAppointmentTypeName(
    encounter.appointmentTypeId,
    appointmentTypes,
  );
  return {
    id: encounter.id,
    provider,
    appointmentType,
    appointmentId: encounter.appointmentId,
    encounterOnly: encounter.encounterOnly,
    patientId: encounter.patientId,
    providerId: encounter.providerId,
    signed: encounter.signed,
    encounterNumber: encounter.encounterNumber,
    locationId: encounter.locationId,
    caseId: encounter.caseId,
    patientAuthorizationId: encounter.patientAuthorizationId,
    appointmentTypeId: encounter.appointmentTypeId,
    ...formatServiceDate(encounter),
    ...(encounter.signed ? formatSignedData(encounter, providers) : {}),
  };
}

export const formatCurrentEncounterApptsWithNarrative = (
  appointments,
  providers,
  apptTypes,
) =>
  appointments.map(appointment => ({
    providerName: formatProviderName(appointment.providerId, providers),
    appointmentTypeName: getAppointmentTypeName(
      appointment.appointmentTypeId,
      apptTypes,
    ),
    startTime: parseDate(appointment.start).format('h:mm A'),
    selfCheckInQuestionnaireNarrative:
      appointment.selfCheckInQuestionnaireNarrative,
  }));

export const formatPriorEncounters = ({
  priorEncounters,
  providers = [],
  appointmentTypes = [],
}) =>
  priorEncounters.map(x => ({
    id: x.id || x.encounterId,
    provider: x.provider || formatProviderName(x.providerId, providers),
    appointmentType:
      x.appointmentType ||
      getAppointmentTypeName(x.appointmentTypeId, appointmentTypes),
    ...formatServiceDate(x),
  }));

export const formatEncounterCards = (
  encounters,
  providers,
  apptTypes,
  locations = [],
) =>
  encounters.map(model => {
    const apptType = findById(apptTypes, model.appointmentTypeId);
    const location = findById(locations, model.locationId);
    return {
      ...formatEncounter(model, providers, apptTypes),
      markerColor: apptType.color,
      locationName: location ? location.name : '',
    };
  });

export function getTableRowInfo(obj) {
  const { isNew, ...model } = obj;
  delete obj.isNew;
  return {
    isNew,
    obj: { ...model },
  };
}

export function formatIcdSelectorIdCode(code) {
  return code ? code.replace('.', '') : '';
}

export function mapProviderNameToProblems({ problems = [], providers }) {
  if (!providers || providers.length === 0) return problems;

  return problems.map(problem => {
    const provider = providers.find(p => p.id === problem.providerId);
    return {
      ...problem,
      providerName: provider ? objToName(provider.name, DEFAULT_NAME_OPTS) : '',
    };
  });
}
