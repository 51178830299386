import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';
import { ERA_DETAILS_TYPES } from '../../../components/forms/era-eob/utils';

const buildReportDataToPostLineItem = ({ selectedIdToPost, reportData }) => ({
  ...reportData,
  claims: reportData.claims?.map(({ lineItems = [], ...claim }) => ({
    ...claim,
    lineItems: lineItems.map(({ posted, ...lineItem }) => ({
      ...lineItem,
      posted:
        posted ||
        [lineItem.id, lineItem.lineItemReportId].includes(selectedIdToPost),
    })),
  })),
});

const buildReportDataToPostClaim = ({ selectedIdToPost, reportData }) => ({
  ...reportData,
  claims: reportData.claims?.map(({ lineItems = [], ...claim }) => ({
    ...claim,
    posted:
      !!claim.posted ||
      [claim.id, claim.claimReportId].includes(selectedIdToPost),
    lineItems: lineItems.map(lineItem => ({
      ...lineItem,
      posted:
        !!lineItem.posted ||
        [claim.id, claim.claimReportId].includes(selectedIdToPost),
    })),
  })),
});

const buildReportDataToPostERA = reportData => ({
  ...reportData,
  posted: true,
  claims: reportData.claims?.map(({ lineItems = [], ...claim }) => ({
    ...claim,
    posted: true,
    lineItems: lineItems.map(lineItem => ({
      ...lineItem,
      posted: true,
    })),
  })),
});

const BUILD_REPORT_DATA_TO_POST = Object.freeze({
  [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]: ({ selectedIdToPost, reportData }) =>
    buildReportDataToPostLineItem({ selectedIdToPost, reportData }),
  [ERA_DETAILS_TYPES.CLAIM_LEVEL]: ({ selectedIdToPost, reportData }) =>
    buildReportDataToPostClaim({ selectedIdToPost, reportData }),
  [ERA_DETAILS_TYPES.ERA_LEVEL]: ({ reportData }) =>
    buildReportDataToPostERA(reportData),
});

export default ({
  model: { id },
  reportData,
  selectedIdToPost,
  selectedNodeType,
}) =>
  updateERAReport(id, {
    reportData: BUILD_REPORT_DATA_TO_POST[selectedNodeType]({
      selectedIdToPost,
      reportData,
    }),
  });
