import { html, css, LitElement } from 'lit';

import {
  getReports,
  getReportsV2,
} from '../../../../packages/neb-api-client/src/reports-api-client';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../packages/neb-utils/feature-util';
import { CSS_COLOR_WHITE, CSS_SPACING } from '../../../styles';

import '../../cards/neb-report-links-card';

export const ELEMENTS = {
  reportCard: { selector: '[id^=report-card-]' },
  logoutIframe: { id: 'logout-iframe' },
};

class NebPageReports extends LitElement {
  static get properties() {
    return {
      __hasTestingReportFF: Boolean,
      __hasExcelReportLink: Boolean,
    };
  }

  static get styles() {
    return [
      css`
        :host {
          height: 100%;
          background: ${CSS_COLOR_WHITE};
          overflow: auto;
        }

        .container {
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax(max(300px, calc((100% - ${CSS_SPACING} * 2) / 3)), 1fr)
          );
          gap: ${CSS_SPACING};
          padding: ${CSS_SPACING};
          background: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
  }

  __initState() {
    this.__hasExcelReportLink = false;
  }

  async connectedCallback() {
    this.__hasTestingReportFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.TESTING_REPORT,
    );

    this.__hasExcelReportLink = await hasFeatureOrBeta(
      FEATURE_FLAGS.EXCEL_REPORT_LINK,
    );

    const { reportsByCategory, logoutUrl } =
      this.__hasExcelReportLink || this.__hasTestingReportFF
        ? await getReportsV2()
        : await getReports();
    this.__reportsByCategory = reportsByCategory;
    this.__logoutUrl = logoutUrl;
    super.connectedCallback();
  }

  render() {
    return this.__hasExcelReportLink
      ? this.renderCardsWithFF()
      : this.renderCards();
  }

  renderCardsWithFF() {
    return html`
      <div class="container">
        ${
          this.__reportsByCategory.map(
            ({ category, reports }) => html`
              <neb-report-links-card
                id="report-card-${category.id}"
                .category="${category}"
                .reports="${reports}"
                .showExcelLink="${this.__hasExcelReportLink}"
                .showProdVersionLink="${this.__hasTestingReportFF}"
              ></neb-report-links-card>
            `,
          )
        }
      </div>
      <iframe
        id="${ELEMENTS.logoutIframe.id}"
        src="${this.__logoutUrl}"
        style="border: 0; width: 0; height: 0;"
      ></iframe>
    `;
  }

  renderCards() {
    return html`
      <div class="container">
        ${
          this.__reportsByCategory.map(
            ({ category, reports }, index) => html`
              <neb-report-links-card
                id="report-card-${index}"
                .category="${category}"
                .reports="${reports}"
                .showExcelLink="${this.__hasExcelReportLink}"
                .showProdVersionLink="${this.__hasTestingReportFF}"
              ></neb-report-links-card>
            `,
          )
        }
      </div>
      <iframe
        id="${ELEMENTS.logoutIframe.id}"
        src="${this.__logoutUrl}"
        style="border: 0; width: 0; height: 0;position:absolute;top:0px;left:0px;"
        scrolling="no"
      ></iframe>
    `;
  }
}

window.customElements.define('neb-page-reports', NebPageReports);
