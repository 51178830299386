import '../../neb-popup-header';

import { html, css } from 'lit';
import moment from 'moment-timezone';

import {
  getPatientPackage,
  completePatientPackage,
  expirePatientPackage,
} from '../../../../../../src/api-clients/patient-package';
import { getMerchantAccounts } from '../../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import {
  getSinglePayment,
  createSinglePayment,
  updateSinglePayment,
  deleteSinglePayment,
  getRecurringPayment,
  createRecurringPayment,
  updateRecurringPayment,
  deleteRecurringPayment,
  getRecurringPaymentExpirationDate,
  getPaymentPlan,
  createPaymentPlan,
  updatePaymentPlan,
  deletePaymentPlan,
} from '../../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  SCHEDULED_PAYMENT_TYPE,
  SCHEDULE_PAYMENT_STATUS,
} from '../../../../../neb-utils/enums';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../../neb-utils/neb-refresh';
import { OVERLAY_KEYS, openOverlay } from '../../../utils/overlay-constants';
import NebFormScheduledPaymentPlan from '../../forms/neb-form-scheduled-payment-plan';
import FormScheduledPaymentRecurring from '../../forms/neb-form-scheduled-payment-recurring';
import FormScheduledPaymentSingle from '../../forms/neb-form-scheduled-payment-single';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  scheduledPaymentSingleForm: { id: 'scheduled-payment-single' },
  scheduledPaymentRecurringForm: { id: 'scheduled-payment-recurring' },
  scheduledPaymentPlanForm: { id: 'scheduled-payment-plan' },
};

export const SCHEDULED_PAYMENT_SAVE_SUCCESS =
  'Scheduled payment saved successfully';
export const SCHEDULED_PAYMENT_SAVE_ERROR =
  'An error occurred when saving the scheduled payment';
export const SCHEDULED_PAYMENT_DELETE_SUCCESS =
  'Scheduled payment deleted successfully';
export const SCHEDULED_PAYMENT_DELETE_ERROR =
  'An error occurred when deleting the scheduled payment';
export const PAYMENT_LIMIT = 240;

class NebOverlayScheduledPayment extends Overlay {
  static get properties() {
    return {
      __scheduledPaymentSingle: Object,
      __scheduledPaymentRecurring: Object,
      __scheduledPaymentPlan: Object,
      __schedule: Array,
      __patientPackage: Object,
      __navItems: Object,
    };
  }

  initState() {
    super.initState();

    this.__scheduledPaymentSingle = FormScheduledPaymentSingle.createModel();
    this.__scheduledPaymentRecurring = FormScheduledPaymentRecurring.createModel();
    this.__scheduledPaymentPlan = NebFormScheduledPaymentPlan.createModel();
    this.__schedule = [];
    this.__patientPackage = null;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: result => {
        this.isDirty = false;

        sendRefreshNotification(
          [
            REFRESH_CHANGE_TYPE.SCHEDULED_PAYMENTS,
            REFRESH_CHANGE_TYPE.PATIENT_PACKAGE,
            REFRESH_CHANGE_TYPE.LEDGER,
          ],
          this.model.patientId,
        );

        this.handlers.dismiss(result);
      },
      saveSingle: async result => {
        const { id } = result;

        try {
          const {
            schedule: [schedule],
            singlePayment,
          } = id
            ? await updateSinglePayment(this.model.patientId, result)
            : await createSinglePayment(this.model.patientId, result);

          store.dispatch(openSuccess(SCHEDULED_PAYMENT_SAVE_SUCCESS));

          const scheduledPayment = { ...schedule, singlePayment };

          this.handlers.save(scheduledPayment);
        } catch (err) {
          store.dispatch(openError(SCHEDULED_PAYMENT_SAVE_ERROR));
        }
      },
      deleteSingle: async () => {
        try {
          const {
            patientId,
            item: { id },
          } = this.model;

          await deleteSinglePayment(patientId, id);

          store.dispatch(openSuccess(SCHEDULED_PAYMENT_DELETE_SUCCESS));

          this.handlers.save({ ...this.model, active: false });
        } catch (err) {
          store.dispatch(openError(SCHEDULED_PAYMENT_DELETE_ERROR));
        }
      },
      saveRecurring: async result => {
        const { id } = result;

        try {
          const {
            schedule: [schedule],
            recurringPayment,
          } = id
            ? await updateRecurringPayment(this.model.patientId, result)
            : await createRecurringPayment(this.model.patientId, result);

          store.dispatch(openSuccess(SCHEDULED_PAYMENT_SAVE_SUCCESS));

          const scheduledPayment = { ...schedule, recurringPayment };

          this.handlers.save(scheduledPayment);
        } catch (err) {
          store.dispatch(openError(SCHEDULED_PAYMENT_SAVE_ERROR));
        }
      },
      deleteRecurring: async () => {
        try {
          const {
            patientId,
            item: { id, patientPackageId },
          } = this.model;

          await deleteRecurringPayment(patientId, id);

          if (patientPackageId) {
            const completedDate = moment().format('YYYY-MM-DDT00:00:00');

            await completePatientPackage(
              {
                id: patientPackageId,
                completedDate,
              },
              patientId,
            );
          }

          store.dispatch(openSuccess(SCHEDULED_PAYMENT_DELETE_SUCCESS));

          this.handlers.save({ ...this.model, active: false });
        } catch (err) {
          store.dispatch(openError(SCHEDULED_PAYMENT_DELETE_ERROR));
        }
      },
      stopRecurring: async () => {
        try {
          const {
            patientId,
            item: { id, patientPackageId },
          } = this.model;

          await deleteRecurringPayment(patientId, id);

          if (patientPackageId) {
            const { expirationDate } = await getRecurringPaymentExpirationDate(
              patientId,
              id,
            );

            await expirePatientPackage({
              id: patientPackageId,
              expirationDate,
            });
          }

          store.dispatch(openSuccess(SCHEDULED_PAYMENT_DELETE_SUCCESS));

          this.handlers.save({ ...this.model, active: false });
        } catch (err) {
          store.dispatch(openError(SCHEDULED_PAYMENT_DELETE_ERROR));
        }
      },
      savePlan: async result => {
        const { id } = result;

        try {
          const {
            schedule: [schedule],
            paymentPlan,
          } = id
            ? await updatePaymentPlan(this.model.patientId, result)
            : await createPaymentPlan(this.model.patientId, result);

          store.dispatch(openSuccess(SCHEDULED_PAYMENT_SAVE_SUCCESS));

          const scheduledPayment = { ...schedule, paymentPlan };

          this.handlers.save(scheduledPayment);
        } catch (err) {
          store.dispatch(openError(SCHEDULED_PAYMENT_SAVE_ERROR));
        }
      },
      deletePlan: async () => {
        try {
          const {
            patientId,
            item: { id },
          } = this.model;

          await deletePaymentPlan(patientId, id);

          store.dispatch(openSuccess(SCHEDULED_PAYMENT_DELETE_SUCCESS));

          this.handlers.save({ ...this.model, active: false });
        } catch (err) {
          store.dispatch(openError(SCHEDULED_PAYMENT_DELETE_ERROR));
        }
      },
      closeAll: () => this.handlers.dismiss(true),
      openSubscription: async () => {
        const { patientId } = this.model;

        await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
          item: {
            id: this.__patientPackage.id,
            patientId,
          },
          context: { fromRecurringPayment: true },
        });

        const { id } = this.__scheduledPaymentRecurring;

        await this.__getRecurringPayment(patientId, id);
      },
    };
  }

  async __getRecurringPayment(patientId, id) {
    const { schedule, recurringPayment } = await getRecurringPayment(
      patientId,
      id,
    );

    if (recurringPayment.patientPackageId) {
      const patientPackage = await getPatientPackage(
        recurringPayment.patientPackageId,
      );

      this.__patientPackage = patientPackage;
    } else this.__patientPackage = null;

    this.__schedule = this.__formatManualStatus(schedule);
    this.__scheduledPaymentRecurring = {
      ...recurringPayment,
    };
  }

  __formatManualStatus(schedule) {
    const updatedSchedule = schedule.map(item => ({
      ...item,
      status: item.manuallyPaid
        ? SCHEDULE_PAYMENT_STATUS.PAID_MANUALLY
        : item.status,
    }));

    return updatedSchedule;
  }

  async connectedCallback() {
    const merchantAccounts = await getMerchantAccounts({
      hideInactive: false,
    });

    this.__navItems = this.__genNavItems(merchantAccounts);

    const { item, type, patientId } = this.model;

    if (item) {
      if (type === SCHEDULED_PAYMENT_TYPE.SINGLE) {
        if (item.id) {
          const { schedule, singlePayment } = await getSinglePayment(
            patientId,
            item.id,
          );

          this.__schedule = this.__formatManualStatus(schedule);
          this.__scheduledPaymentSingle = { ...singlePayment };
        } else {
          this.__scheduledPaymentSingle = { ...item };
        }
      }

      if (type === SCHEDULED_PAYMENT_TYPE.RECURRING) {
        if (item.id) {
          await this.__getRecurringPayment(patientId, item.id);
        } else {
          this.__scheduledPaymentRecurring = { ...item };
        }
      }

      if (type === SCHEDULED_PAYMENT_TYPE.PLAN) {
        if (item.id) {
          const { schedule, paymentPlan } = await getPaymentPlan(
            patientId,
            item.id,
          );
          this.__schedule = this.__formatManualStatus(schedule);

          this.__scheduledPaymentPlan = { ...paymentPlan };
        } else {
          this.__scheduledPaymentPlan = { ...item };
        }
      }
    }

    super.connectedCallback();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING};
        }

        .content {
          width: 700px;
          overflow: auto;
        }

        .caption {
          padding: 0 ${CSS_SPACING};
        }

        .form {
          overflow: auto;
        }
      `,
    ];
  }

  __genNavItems(merchantAccounts) {
    return {
      [SCHEDULED_PAYMENT_TYPE.SINGLE]: {
        addTitle: 'Add Scheduled One-time Payment',
        updateTitle: 'Update Scheduled One-time Payment',
        updateDetails: 'Update Scheduled Payment details below.',
        render: () => html`
          <neb-form-scheduled-payment-single
            id="${ELEMENTS.scheduledPaymentSingleForm.id}"
            class="form"
            .layout="${this.layout}"
            .patientId="${this.model.patientId}"
            .merchantAccounts="${merchantAccounts}"
            .schedule="${this.__schedule}"
            .model="${this.__scheduledPaymentSingle}"
            .onSave="${this.handlers.saveSingle}"
            .onDelete="${this.handlers.deleteSingle}"
            .onCancel="${this.handlers.dismiss}"
            .onChangeDirty="${this.handlers.dirty}"
            manuallyRefreshOnSave
          ></neb-form-scheduled-payment-single>
        `,
      },
      [SCHEDULED_PAYMENT_TYPE.RECURRING]: {
        addTitle: 'Add Scheduled Recurring Payment',
        updateTitle: 'Update Scheduled Recurring Payment Series',
        updateDetails:
          'Update Scheduled Payment Name or Payment Method for the series below.',
        render: () => html`
          <neb-form-scheduled-payment-recurring
            id="${ELEMENTS.scheduledPaymentRecurringForm.id}"
            class="form"
            .layout="${this.layout}"
            .patientId="${this.model.patientId}"
            .forPatientPackage="${this.model.forPatientPackage}"
            .merchantAccounts="${merchantAccounts}"
            .model="${this.__scheduledPaymentRecurring}"
            .schedule="${this.__schedule}"
            .patientPackage="${this.__patientPackage}"
            .onSave="${this.handlers.saveRecurring}"
            .onDelete="${this.handlers.deleteRecurring}"
            .onStop="${this.handlers.stopRecurring}"
            .onCancel="${this.handlers.dismiss}"
            .onChangeDirty="${this.handlers.dirty}"
            .onOpenSubscription="${this.handlers.openSubscription}"
            manuallyRefreshOnSave
          ></neb-form-scheduled-payment-recurring>
        `,
      },
      [SCHEDULED_PAYMENT_TYPE.PLAN]: {
        addTitle: 'Add Scheduled Payment Plan',
        updateTitle: 'Update Scheduled Payment Plan Series',
        updateDetails:
          'Update Scheduled Payment Name or Payment Method for the series below.',
        render: () => html`
          <neb-form-scheduled-payment-plan
            id="${ELEMENTS.scheduledPaymentPlanForm.id}"
            class="form"
            .layout="${this.layout}"
            .patientId="${this.model.patientId}"
            .merchantAccounts="${merchantAccounts}"
            .model="${this.__scheduledPaymentPlan}"
            .schedule="${this.__schedule}"
            .onSave="${this.handlers.savePlan}"
            .onDelete="${this.handlers.deletePlan}"
            .onCancel="${this.handlers.dismiss}"
            .onChangeDirty="${this.handlers.dirty}"
            manuallyRefreshOnSave
          ></neb-form-scheduled-payment-plan>
        `,
      },
    };
  }

  __getNavItem() {
    return this.__navItems[this.model.type];
  }

  renderContent() {
    if (!this.__navItems) return '';

    const navItem = this.__getNavItem();

    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        .title="${
          this.model.item && !this.model.forPatientPackage
            ? navItem.updateTitle
            : navItem.addTitle
        }"
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.closeAll}"
        ?showBackButton="${this.index}"
        showCancelButton
      ></neb-popup-header>

      <span class="caption"
        >${
          this.model.item && !this.model.forPatientPackage
            ? navItem.updateDetails
            : 'Enter payment details.'
        }</span
      >

      ${navItem.render()}
    `;
  }
}

window.customElements.define(
  'neb-overlay-scheduled-payment',
  NebOverlayScheduledPayment,
);
