import '../neb-header';
import '../neb-tooltip';
import '../neb-action-bar';
import '../neb-radio-button';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../inputs/neb-textfield';
import '../controls/neb-switch';
import '../controls/neb-tab-group';
import '../controls/neb-button-icon';
import '../controls/neb-button-action';
import '../inputs/neb-select';
import '../../../../../src/components/controls/field-groups/neb-change-healthcare-settings';
import '../../../../../src/components/controls/field-groups/neb-clearinghouse-settings';

import {
  isRequired,
  isRequiredIf,
  inRange,
  isEmailAddress,
} from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { ETIN_CLEARINGHOUSES } from '../../../../../src/components/collection-pages/neb-collection-page-clearinghouse-settings';
import { TIERS } from '../../../../../src/components/forms/utils/form-utils';
import { PopupPassword } from '../../../../../src/components/popups/password/neb-popup-password';
import {
  CSS_SPACING,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
  CSS_FONT_SIZE_BODY,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../../src/styles';
import {
  PERIODS,
  ONE_DAY,
  CLEARINGHOUSE_ITEMS,
  CLEARINGHOUSES,
  FLEXCLEAR_CLEARINGHOUSE,
  RESERVED_PRACTICE_IDENTIFIERS,
  SFTP_UNAVAILABLE,
  CLEARINGHOUSE_PRESETS,
} from '../../../../../src/utils/clearinghouse-settings';
import { CONFIRM_MESSAGE } from '../../../../../src/utils/user-message';
import { testConnection } from '../../../../neb-api-client/src/files-api-client';
import {
  openSuccess,
  openError,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { SUBMISSION_METHODS } from '../../../../neb-utils/claims';
import {
  formatPhoneNumber,
  normalizeForSearch,
  toNumeric,
} from '../../../../neb-utils/formatters';
import {
  validFileName,
  alphanumeric,
  characterSetX12,
} from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { getTabWithError, getValueByPath } from '../../../../neb-utils/utils';
import { isPhoneNumber } from '../../../../neb-utils/validators';
import {
  SFTP_CSS,
  getSftpStatusIcon,
} from '../../utils/clearinghouse-sftp-status';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { getPayerTableConfig } from '../tables/neb-table-payer-identifiers';

import NebForm from './neb-form';

const APPEND_KEYS = {
  payerPlans: [
    'id',
    'active',
    'alias',
    'payerId',
    'payerName',
    'submissionMethodPrimary',
    'submissionMethodSecondary',
  ],
};

const SFTP_FORM_KEYS = [
  'sftpHost',
  'sftpPort',
  'sftpPathClaim',
  'sftpPathEra',
  'sftpPathAcknowledgement',
  'sftpPath277',
];

export const ELEMENTS = {
  tabClearinghouseIds: { id: 'tab-clearinghouse-identifiers' },
  tabContentClearinghouseIds: { id: 'tab-content-clearinghouse-identifiers' },
  tabContentPayerIds: { id: 'tab-content-payer-identifiers' },
  tabContentSftpIds: { id: 'tab-content-sftp-identifiers' },
  tabContentSettings: { id: 'tab-content-settings' },
  tabPayerIds: { id: 'tab-payer-identifiers' },
  tabSftpIds: { id: 'tab-sftp-identifiers' },
  selectPartner: { id: 'select-partner' },
  switchActive: { id: 'switch-active' },
  tabGroup: { id: 'tab-group' },
  searchPayerIdentifier: { id: 'search-payer-identifier' },
  tablePayerIdentifier: { id: 'table-payer-identifier' },
  addButtonPayerIdentifier: { id: 'add-button-payer-identifier' },
  payerPlansPagination: { id: 'pagination-payer-plans' },
  textfieldAlias: {
    id: 'textfield-alias-id',
  },
  textfieldClearinghouseIdentifierId: {
    id: 'textfield-clearinghouse-identifier-id',
  },
  textfieldClearinghouseETIN: { id: 'textfield-clearinghouse-etin' },
  textfieldClearinghouseApplicationCode: {
    id: 'textfield-clearinghouse-application-code',
  },
  textfieldPracticeIdentifierId: { id: 'textfield-practice-identifier-id' },
  textfieldPracticeETIN: { id: 'textfield-practice-etin' },
  textfieldPracticeApplicationCode: {
    id: 'textfield-practice-application-code',
  },
  iconClaimConfiguration: { id: 'checkbox-claim-configuration' },
  textfieldCustomExtension: { id: 'textfield-custom-extension' },
  textfieldCustomFilenameTemplate: { id: 'textfield-custom-filename-template' },
  fieldsChangeHealthcare: { id: 'fields-chc-settings' },
  toggleSftpActive: { id: 'toggle-sftp-active' },
  toggleTestMode: { id: 'toggle-test-mode' },
  textfieldSftpHost: { id: 'textfield-sftp-host' },
  textfieldSftpPort: { id: 'textfield-sftp-port' },
  textfieldSftpUsername: { id: 'textfield-sftp-username' },
  textfieldSftpPathClaim: { id: 'textfield-sftp-path-claim' },
  textfieldSftpPathEra: { id: 'textfield-sftp-path-era' },
  textfieldSftpPathAcknowledgement: {
    id: 'textfield-sftp-path-acknowledgement',
  },
  textfieldSftpPath277: { id: 'textfield-sftp-path-claim-status' },
  buttonTestConnection: { id: 'button-sftp-test-connection' },
  iconSftpStatus: { id: 'icon-sftp-status' },
  buttonSftpPassword: { id: 'button-password' },
  toggleEraDownload: { id: 'toggle-era-download' },
  tabSettings: { id: 'tab-settings' },
  clearinghouseSettings: { id: 'clearinghouse-settings' },
};
const required = v => (typeof v === 'string' ? v.trim() : v);

const isReserved = () => ({
  error: 'Reserved for CT ProClear',
  validate: (v, _, state) => {
    if (
      RESERVED_PRACTICE_IDENTIFIERS.includes(v.toLowerCase()) &&
      Object.values(FLEXCLEAR_CLEARINGHOUSE).includes(state.partner)
    ) {
      return false;
    }
    return true;
  },
});

const requiredChc = (isChcSetting = false) => ({
  error: 'Required',
  validate: (v, _, state) => {
    if (state.partner === CLEARINGHOUSES.CHC && isChcSetting) {
      return required(v);
    }

    if (state.partner !== CLEARINGHOUSES.CHC && !isChcSetting) {
      return required(v);
    }

    return true;
  },
});

const isDuplicate = (items, error, model) => ({
  error,
  validate: (v, _, _state) =>
    !(items.includes(v.toLowerCase()) && v !== model.alias),
});

const uniqueFileName = fileName => {
  fileName = fileName || '';
  const hasUnique =
    fileName.match('{number}') || fileName.match('{date}') || fileName === '';

  if (!hasUnique) {
    fileName += '_{number}_{date}';
  }
  return fileName;
};

const isSftpCredentialsPopulated = state =>
  state.sftpHost && state.sftpPort && state.sftpUsername;

export class FormClearingHouse extends NebForm {
  static get properties() {
    return {
      readOnly: { reflect: true, type: Boolean },
      activeItemCount: { reflect: true, type: Number },
      existingClearinghouses: { type: Array },
      hasAddOnCtVerify: { type: Boolean },
      isCHC: { type: Boolean },
      hasAcknowledgementFeatureFlag: { type: Boolean },
      beenWarned: { type: Boolean },
      defaultDisabled: { type: Boolean },
      claimConfiguration: {
        type: Boolean,
        reflect: true,
        attribute: 'claimConfiguration',
      },
      hasBYOC277FF: { type: Boolean },
      __tabId: { type: String },
      __tableItems: { type: Array },
      __tier: { type: String },
      __navItems: { type: Array },
      __payerPlansState: { type: Object },
      payerTableConfig: { type: Object },
      sftpStatus: { type: String },
      byocAddons: { type: Object },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .sftp-host {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        .sftp-port {
          grid-column-start: 3;
          grid-column-end: 4;
        }

        .text-field {
          width: 280px;
        }

        .select-partner {
          width: 280px;
        }

        .button-add {
          padding: 0 0 10px 20px;
        }

        .connection-status {
          display: flex;
          align-items: center;
        }

        .textfield-payer {
          padding-left: 20px;
          width: 75%;
        }

        .default-top-padding {
          padding-top: 10px;
        }

        .sftp-button {
          width: 200px;
          margin-left: ${CSS_SPACING};
        }

        .icon {
          height: 22px;
          width: 22px;
          margin-left: ${CSS_SPACING};
        }

        .icon-issue {
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-warning {
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-success {
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }
        .chevron {
          width: 16px;
          height: 16px;
          margin-right: ${CSS_SPACING};
          transform: rotate(0);
          transition: transform 200ms;
        }
        :host([claimConfiguration]) .chevron {
          transform: rotate(180deg);
        }
        .header {
          cursor: pointer;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }
        .expanding {
          transition: width 300ms ease;
        }
        .expanding:focus {
          width: 560px;
        }
      `,
    ];
  }

  static createModel() {
    return {
      id: '',
      partner: '',
      active: true,
      payerPlans: [],
      alias: '',
      clearinghouseIdentifierId: '',
      clearinghouseEtin: '',
      clearinghouseApplicationCode: '',
      practiceIdentifierId: '',
      practiceEtin: '',
      payerEtin: '',
      practiceApplicationCode: '',
      customExtension: '',
      customFilenameTemplate: '',
      chcPassword: '',
      chcSubmitterId: '',
      chcUserName: '',
      realTimeEligibilityEnabled: false,
      realTimeEligibilityPeriod: ONE_DAY,
      realTimeEligibilityFrequency: '7',
      realTimeEligibilityAutoUpdate: false,
      sftpActive: false,
      submitterContactName: '',
      submitterContactEmail: '',
      submitterContactNumber: '',
      submitterContactType: '',
      submitterContactOverride: false,
      sftpHost: '',
      sftpPort: '22',
      sftpUsername: '',
      sftpPassword: '',
      sftpPathClaim: '',
      sftpPathEra: '',
      sftpPathAcknowledgement: '',
      sftpPath277: '',
      testMode: false,
      sftpDownloadActive: false,
    };
  }

  createSelectors() {
    return {
      children: {
        partner: [isRequired()],
        sftpHost: [isRequiredIf('sftpActive')],
        sftpPort: {
          validators: [isRequiredIf('sftpActive')],
          format: v => (v ? `${v}` : ''),
          unformat: v => v * 1,
        },
        sftpUsername: [isRequiredIf('sftpActive')],
        sftpPassword: {
          unformat: v => (v === this.model.sftpPassword ? undefined : v),
        },
        sftpPathClaim: [isRequiredIf('sftpActive')],
        sftpPathEra: [isRequiredIf('sftpDownloadActive')],
        alias: [
          isDuplicate(
            this.existingClearinghouses.map(item => item && item.alias),
            'Duplicate Alias',
            this.model,
          ),
          requiredChc(),
        ],
        clearinghouseIdentifierId: [requiredChc()],
        clearinghouseEtin: [requiredChc()],
        clearinghouseApplicationCode: [requiredChc()],
        practiceIdentifierId: [requiredChc(), isReserved()],
        practiceEtin: [requiredChc()],
        practiceApplicationCode: [requiredChc()],
        customFilenameTemplate: {
          unformat: uniqueFileName,
        },
        payerPlans: {
          createItem: () => ({
            id: '',
            active: true,
            alias: '',
            payerId: '',
            payerName: '',
            primaryIdentifier: '',
            secondaryIdentifier: '',
            clearinghousePayerPlanId: '',
            submissionMethodPrimary: '',
            submissionMethodSecondary: '',
            payerEtin: '',
          }),
          children: {
            $: {
              children: {
                primaryIdentifier: [isRequired()],
              },
            },
          },
        },
        chcPassword: [requiredChc(true)],
        chcSubmitterId: [requiredChc(true)],
        chcUserName: [requiredChc(true)],
        realTimeEligibilityPeriod: selectors.select(PERIODS, PERIODS[0], {
          validators: [isRequiredIf('realTimeEligibilityEnabled')],
        }),
        realTimeEligibilityFrequency: {
          format: v => v.toString(),
          unformat: v => parseInt(v, 10),
          validators: [
            isRequiredIf('realTimeEligibilityEnabled'),
            inRange(1, 999),
          ],
        },
        submitterContactNumber: {
          format: v => formatPhoneNumber(v),
          unformat: v => toNumeric(v),
          validators: [
            isPhoneNumber,
            {
              error: 'Required',
              validate: (v, _, state) => {
                if (!v) {
                  if (
                    state.submitterContactName &&
                    !state.submitterContactEmail
                  ) {
                    return false;
                  }

                  if (state.submitterContactType && this.isCHC) {
                    return false;
                  }
                }

                return true;
              },
            },
          ],
        },
        submitterContactType: {
          unformat: (v, _, model) =>
            v && model.submitterContactNumber ? v : '',
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) => {
                if (!v) {
                  if (
                    state.submitterContactName &&
                    !state.submitterContactEmail
                  ) {
                    return false;
                  }

                  if (state.submitterContactNumber) {
                    return false;
                  }
                }

                return true;
              },
            },
          ],
        },
        submitterContactEmail: {
          validators: [
            isEmailAddress('mail@email.com'),
            {
              error: 'Required',
              validate: (v, _, state) => {
                if (
                  state.submitterContactName &&
                  !v &&
                  !state.submitterContactNumber &&
                  !state.submitterContactType
                ) {
                  return false;
                }

                return true;
              },
            },
          ],
        },
      },
    };
  }

  initState() {
    super.initState();
    this.isCHC = false;
    this.readOnly = false;
    this.beenWarned = false;
    this.hasAddOnCtVerify = false;
    this.hasAcknowledgementFeatureFlag = false;
    this.claimConfiguration = false;
    this.activeItemCount = 0;
    this.payerTableConfig = {};
    this.sftpStatus = '';
    this.byocAddons = {
      CT_MAXCLEAR: false,
      CT_PROCLEAR_TRIZETTO: false,
      CT_PROCLEAR_WAYSTAR: false,
      CT_FLEXCLEAR: false,
    };

    this.hasBYOC277FF = false;

    this.existingClearinghouses = [];
    this.__tabId = ELEMENTS.tabClearinghouseIds.id;
    this.__payerPlansState = CollectionService.createModel();
    this.__tableItems = [];
    this.__tier = '';
    this.__navItems = [
      {
        id: ELEMENTS.tabClearinghouseIds.id,
        label: 'Clearinghouse Identifiers',
        renderer: () =>
          this.state.partner === CLEARINGHOUSES.CHC
            ? this.__renderChcSettings()
            : this.__renderClearinghouseIdsTab(),
      },
      {
        id: ELEMENTS.tabPayerIds.id,
        label: 'Payer Identifiers',
        renderer: () => this.__renderPayerIdsTab(),
      },
    ];

    this.onSave = () => {};

    this.__payerPlansService = new CollectionService({
      onChange: state => {
        this.__payerPlansState = state;
      },
      onSearch: ({ terms, item }) => terms.every(term => item.includes(term)),
      onCacheItem: ({
        alias,
        payerName,
        primaryIdentifier,
        secondaryIdentifier,
        payerEtin,
      }) =>
        normalizeForSearch(
          [
            alias,
            payerName,
            primaryIdentifier,
            secondaryIdentifier,
            payerEtin,
          ].join(' '),
        ),
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectTab: id => {
        this.__tabId = id;
      },
      partnerChange: e => {
        this.__updateSftpPresets(e);
        this.__updateClearinghousePresets(e);
        this.__updatePracticePresets(e);
        this.__updateSettingPresets(e);
        this.__setShowEtin(e);

        this.beenWarned = false;
        this.formService.apply('sftpActive', false);
        this.formService.apply(e.name, e.value);
      },
      presetSelect: async e => {
        let riskIt;

        const { id, name } = e.currentTarget;

        if (
          !this.beenWarned &&
          CLEARINGHOUSE_PRESETS[this.state.partner] &&
          CLEARINGHOUSE_PRESETS[this.state.partner][name]
        ) {
          riskIt = await this.__openWarningPopup(
            'Changing the default values for this clearinghouse is not recommended.',
          );

          this.beenWarned = riskIt;
        }

        if (riskIt) this.shadowRoot.getElementById(`${id}`).focus();
      },
      addPayerPlan: async () => {
        const currentItems = [...this.state.payerPlans];
        const { items, clearinghousePayerPlans = [] } = await openOverlay(
          OVERLAY_KEYS.ADD_PAYER_PLANS,
          {
            items: currentItems,
            clearinghouseId: this.model.id,
          },
        );

        let mappedItems;

        if (items && items.length) {
          const defaultSubmissionMethod =
            this.__tier === TIERS.ADVANCED
              ? SUBMISSION_METHODS[1]
              : SUBMISSION_METHODS[0];

          mappedItems = items.map(p => {
            const obj = {
              ...p,
              active: (p.status = !!'Active'),
              submissionMethodPrimary: defaultSubmissionMethod,
              submissionMethodSecondary: defaultSubmissionMethod,
            };
            return obj;
          });
        }

        const matchingPlans = clearinghousePayerPlans.filter(plan =>
          items.some(item => item.id === plan.payerPlanId),
        );

        if (mappedItems) {
          this.__appendItems('payerPlans', mappedItems, matchingPlans);

          this.__syncPayerPlans();
        }
      },
      removePayerPlan: async (_, item) => {
        const accepted = await this.__openWarningPopup(
          'Remove Payer Plan',
          'This will remove the payer plan from the clearinghouse.',
        );

        if (accepted) {
          const index = this.state.payerPlans.findIndex(
            payerPlan => payerPlan.id === item.id,
          );

          this.formService.removeItem('payerPlans', index);
          this.__syncPayerPlans();
        }
      },
      editPayerPlan: e => {
        if (e.event !== 'blur' && e.event !== 'focus') {
          const key = e.name.split('.')[1];
          const localIndex = e.name.split('.')[0];

          const localItem = this.__payerPlansState.pageItems[localIndex];
          const index = this.__getPayerPlanIndex(localItem);
          const { pageIndex } = this.__payerPlansState;
          this.formService.apply(`payerPlans.${index}.${key}`, e.value);
          this.formService.validateKey(['payerPlans', index, key]);
          this.__payerPlansService.updateItem(this.state.payerPlans[index]);
          this.__payerPlansService.setPageIndex(pageIndex);
        }
      },

      editPayerSettings: async e => {
        const localRowIndex = e.name.split('.')[0];
        const payerPlanId = this.__payerPlansState.pageItems[localRowIndex].id;
        const editedPayerPlan = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: payerPlanId,
        });

        if (editedPayerPlan) {
          const localItem = this.__payerPlansState.pageItems[localRowIndex];
          const globalIndex = this.__getPayerPlanIndex(localItem);
          const item = {
            ...this.state.payerPlans[globalIndex],
            ...this.__mapPayerPlan(editedPayerPlan),
          };

          Object.keys(item).forEach(key => {
            if (item[key]) {
              this.formService.apply(
                `payerPlans.${globalIndex}.${key}`,
                item[key],
              );
            }
          });

          this.__syncPayerPlans();
        }
      },

      selectPayerPlansPage: index =>
        this.__payerPlansService.setPageIndex(index),
      searchPayerPlans: e => this.__payerPlansService.search(e.value),
      clearPayerPlansSearch: () => this.__payerPlansService.search(''),
      changeActiveClearinghouse: e => this.__changeActiveClearinghouse(e),

      changeFileDestination: filepath => {
        const cleanFilepath = filepath.value.replace(/^[/]+|[/]+$/g, '');
        this.formService.apply(filepath.name, cleanFilepath);
      },
      toggleSftpDownloadActive: e => {
        this.formService.apply('sftpDownloadActive', e.value);
        this.formService.validate();
      },
      toggleSftpActive: e => {
        this.formService.apply('sftpActive', e.value);
        this.formService.apply('sftpDownloadActive', e.value);
      },
      sftpPassword: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.PASSWORD,
          PopupPassword.createModel(),
        );

        if (result) this.formService.apply('sftpPassword', result);
      },
      changeTest: e => this.formService.apply(e.name, !e.value),
      toggleClaimConfig: () => {
        this.claimConfiguration = !this.claimConfiguration;
      },
      connect: async () => {
        try {
          const paths = this.__getValidPaths();

          const res = await testConnection({
            id: this.state.id,
            host: this.state.sftpHost,
            username: this.state.sftpUsername,
            port: this.state.sftpPort,
            paths,
            ...(this.state.sftpPassword === this.model.sftpPassword
              ? {}
              : { password: this.state.sftpPassword }),
          });

          switch (res.connectionStatus) {
            case 'Connection Failed: Check Routing':
              this.sftpStatus = SFTP_CSS.ERROR_ROUTING;
              store.dispatch(openError('Connection Failed: Check Routing'));
              break;
            case 'Connection Failed: Check Authentication':
              this.sftpStatus = SFTP_CSS.ERROR_AUTHENTICATION;
              store.dispatch(
                openError('Connection Failed: Check Authentication'),
              );

              break;
            case 'Connection Failed: Check Server':
              this.sftpStatus = SFTP_CSS.ERROR_SERVER;
              store.dispatch(openError('Connection Failed: Check Server'));
              break;
            case 'Connection Success':
              this.sftpStatus = SFTP_CSS.SUCCESS;
              store.dispatch(openSuccess('Connection Success'));
              break;

            default:
              this.sftpStatus = SFTP_CSS.WARNING;
              store.dispatch(openError('Connection Failed'));
              break;
          }
        } catch (e) {
          this.sftpStatus = SFTP_CSS.WARNING;
          store.dispatch(openError('Connection Failed'));
        }
      },
      save: () => {
        if (this.formService.validate()) {
          const model = this.formService.build();

          this.onSave(model);
        } else {
          this.__tabId = getTabWithError(
            this.__genNavItems(),
            this.errors,
            this.__tabId,
          );
        }
      },
      changeContactInformation: e => {
        this.handlers.change(e);

        this.formService.validateKey(['submitterContactEmail'], true);
        this.formService.validateKey(['submitterContactNumber'], true);
        this.formService.validateKey(['submitterContactType'], true);
      },
    };
  }

  connectedCallback() {
    const { item } = store.getState().practiceInformation;
    this.__tier = item ? item.tier : '';

    super.connectedCallback();
  }

  __getValidPaths() {
    return [
      this.state.sftpPathClaim,
      this.state.sftpDownloadActive && this.state.sftpPathEra,
      this.hasAcknowledgementFeatureFlag &&
        this.state.sftpDownloadActive &&
        this.state.sftpPathAcknowledgement,
      this.hasBYOC277FF &&
        this.state.sftpDownloadActive &&
        this.state.sftpPath277,
    ].filter(exists => exists);
  }

  __mapPayerPlan(item) {
    return {
      id: item.id,
      alias: item.alias,
      payerName: item.payerName,
      submissionMethodPrimary: item.submissionMethodPrimary,
      submissionMethodSecondary: item.submissionMethodSecondary,
      payerEtin: item.payerEtin || '',
    };
  }

  __setShowEtin(e) {
    this.payerTableConfig = {
      ...this.payerTableConfig,
      showEtin: ETIN_CLEARINGHOUSES.includes(e.value),
    };
  }

  __clearSftpPresets() {
    SFTP_FORM_KEYS.forEach(sftpFormKey => {
      this.formService.apply(sftpFormKey, '');
    });
  }

  __updateSftpPresets(e) {
    if (
      CLEARINGHOUSE_PRESETS[e.value] &&
      CLEARINGHOUSE_PRESETS[e.value].sftpHost
    ) {
      this.formService.apply(
        'sftpHost',
        CLEARINGHOUSE_PRESETS[e.value].sftpHost,
      );

      this.formService.apply(
        'sftpPort',
        CLEARINGHOUSE_PRESETS[e.value].sftpPort,
      );

      this.formService.apply(
        'sftpPathClaim',
        CLEARINGHOUSE_PRESETS[e.value].sftpPathClaim,
      );

      this.formService.apply(
        'sftpPathEra',
        CLEARINGHOUSE_PRESETS[e.value].sftpPathEra,
      );

      if (this.hasBYOC277FF) {
        this.formService.apply(
          'sftpPath277',
          CLEARINGHOUSE_PRESETS[e.value].sftpPath277,
        );
      }

      if (this.hasAcknowledgementFeatureFlag) {
        this.formService.apply(
          'sftpPathAcknowledgement',
          CLEARINGHOUSE_PRESETS[e.value].sftpPathAcknowledgement,
        );
      }
    } else {
      this.__clearSftpPresets();
    }
  }

  __updateClearinghousePresets(e) {
    if (
      CLEARINGHOUSE_PRESETS[e.value] &&
      CLEARINGHOUSE_PRESETS[e.value].clearinghouseIdentifierId
    ) {
      this.formService.apply(
        'clearinghouseIdentifierId',
        CLEARINGHOUSE_PRESETS[e.value].clearinghouseIdentifierId,
      );

      this.formService.apply(
        'clearinghouseEtin',
        CLEARINGHOUSE_PRESETS[e.value].clearinghouseEtin,
      );

      this.formService.apply(
        'clearinghouseApplicationCode',
        CLEARINGHOUSE_PRESETS[e.value].clearinghouseApplicationCode,
      );
    } else {
      this.formService.apply('clearinghouseIdentifierId', '');
      this.formService.apply('clearinghouseEtin', '');
      this.formService.apply('clearinghouseApplicationCode', '');
    }
  }

  __updatePracticePresets(e) {
    if (
      CLEARINGHOUSE_PRESETS[e.value] &&
      CLEARINGHOUSE_PRESETS[e.value].practiceIdentifierId
    ) {
      this.formService.apply(
        'practiceIdentifierId',
        CLEARINGHOUSE_PRESETS[e.value].practiceIdentifierId,
      );

      this.formService.apply(
        'practiceApplicationCode',
        CLEARINGHOUSE_PRESETS[e.value].practiceApplicationCode,
      );
    } else if (this.state.practiceIdentifierId) {
      this.formService.apply('practiceIdentifierId', '');
      this.formService.apply('practiceEtin', '');
      this.formService.apply('practiceApplicationCode', '');
    }
  }

  __updateSettingPresets(e) {
    if (
      CLEARINGHOUSE_PRESETS[e.value] &&
      CLEARINGHOUSE_PRESETS[e.value].submitterContactOverride
    ) {
      this.formService.apply(
        'submitterContactOverride',
        CLEARINGHOUSE_PRESETS[e.value].submitterContactOverride,
      );
    } else {
      this.formService.apply('submitterContactOverride', false);
    }
  }

  updated(changedProps) {
    if (changedProps.has('model')) {
      this.__syncPayerPlans();
    }

    if (changedProps.has('isCHC')) {
      const items = [
        {
          id: ELEMENTS.tabClearinghouseIds.id,
          label: 'Clearinghouse Identifiers',
          renderer: () =>
            this.state.partner === CLEARINGHOUSES.CHC
              ? this.__renderChcSettings()
              : this.__renderClearinghouseIdsTab(),
        },
        {
          id: ELEMENTS.tabPayerIds.id,
          label: 'Payer Identifiers',
          renderer: () => this.__renderPayerIdsTab(),
        },
      ];

      if (this.isCHC) {
        this.__navItems = [
          ...items,
          {
            id: ELEMENTS.tabSettings.id,
            label: 'Settings',
            renderer: () => this.__renderSettingsTab(),
          },
        ];
      } else {
        this.__navItems = [
          ...items,
          {
            id: ELEMENTS.tabSftpIds.id,
            label: 'SFTP Credentials',
            renderer: () => this.__renderSftpTab(),
          },
          {
            id: ELEMENTS.tabSettings.id,
            label: 'Settings',
            renderer: () => this.__renderSettingsTab(),
          },
        ];
      }
    }
  }

  __changeActiveClearinghouse(e) {
    return this.formService.apply(e.name, e.value);
  }

  __getPayerPlanIndex(localItem) {
    return this.__payerPlansState.allItems.findIndex(
      p => p.id === localItem.id,
    );
  }

  __syncPayerPlans() {
    this.__payerPlansService.setItems([...this.state.payerPlans]);

    this.__tableItems = [...this.__payerPlansState.pageItems];
  }

  __appendItems(name, items, plans) {
    items.forEach(item => {
      const index = this.state[name].length;
      const planMatches = plans.some(plan => plan.payerPlanId === item.id);
      this.formService.addItem(name);

      APPEND_KEYS[name].forEach(key => {
        if (planMatches) {
          const prevId = plans.find(plan => plan.payerPlanId === item.id);
          this.formService.apply(
            `${name}.${index}.primaryIdentifier`,
            prevId.primaryIdentifier,
          );
        }
        return this.formService.apply(
          `${name}.${index}.${key}`,
          getValueByPath(item, key.split('.')),
        );
      });
    });
  }

  __openWarningPopup(title, message) {
    return openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title,
      confirmText: 'Yes',
      cancelText: 'No',
      message: html`
        <p>${message}</p>
        <p>${CONFIRM_MESSAGE()}</p>
      `,
    });
  }

  __disableActive() {
    if (this.state.partner === CLEARINGHOUSES.TRIZETTO) {
      return !this.byocAddons.CT_PROCLEAR_TRIZETTO;
    }

    if (this.state.partner === CLEARINGHOUSES.WAYSTAR) {
      return !this.byocAddons.CT_PROCLEAR_WAYSTAR;
    }

    if (Object.values(FLEXCLEAR_CLEARINGHOUSE).includes(this.state.partner)) {
      return !this.byocAddons.CT_FLEXCLEAR;
    }
    return false;
  }

  __enableTestConnection() {
    if (!this.readOnly && this.state.sftpActive) {
      if (!this.state.sftpDownloadActive) {
        return (
          isSftpCredentialsPopulated(this.state) && this.state.sftpPathClaim
        );
      }

      return (
        isSftpCredentialsPopulated(this.state) &&
        this.state.sftpPathClaim &&
        this.state.sftpPathEra
      );
    }

    return false;
  }

  __genNavItems() {
    const tabs = {
      [ELEMENTS.tabClearinghouseIds.id]: {
        ...this.__navItems[0],
        fields: [
          'clearinghouseIdentifierId',
          'clearinghouseEtin',
          'clearinghouseApplicationCode',
          'practiceIdentifierId',
          'practiceEtin',
          'practiceApplicationCode',
        ],
      },
      [ELEMENTS.tabPayerIds.id]: {
        ...this.__navItems[1],
        fields: ['payerPlans'],
      },
    };

    if (this.state.partner !== CLEARINGHOUSES.CHC) {
      tabs[ELEMENTS.tabSftpIds.id] = {
        ...this.__navItems[2],
        fields: [
          'sftpHost',
          'sftpPort',
          'sftpUsername',
          'sftpPathClaim',
          'sftpPathEra',
        ],
      };

      tabs[ELEMENTS.tabSettings.id] = {
        ...this.__navItems[3],
        fields: [
          'submitterContactOverride',
          'submitterContactName',
          'submitterContactNumber',
          'submitterContactEmail',
          'submitterContactType',
        ],
      };
    } else {
      tabs[ELEMENTS.tabSettings.id] = {
        ...this.__navItems[2],
        fields: [
          'submitterContactOverride',
          'submitterContactName',
          'submitterContactNumber',
          'submitterContactEmail',
          'submitterContactType',
        ],
      };
    }

    return tabs;
  }

  __renderChcSettings() {
    return html`
      <neb-change-healthcare-settings
        id="${ELEMENTS.fieldsChangeHealthcare.id}"
        .model="${this.state}"
        .errors="${this.errors}"
        .hasAddOnCtVerify="${this.hasAddOnCtVerify}"
        .onChange="${this.handlers.change}"
        .readOnly="${this.readOnly}"
      ></neb-change-healthcare-settings>
    `;
  }

  __renderClaimConfigurationCheckbox() {
    return html`
      <div>
        <span class="header" @click="${this.handlers.toggleClaimConfig}">
          Advanced Claim (837) File Configuration
          <neb-icon
            id="${ELEMENTS.iconClaimConfiguration.id}"
            class="icon chevron"
            icon="neb:chevron"
          ></neb-icon
        ></span>
      </div>
      ${
        !this.claimConfiguration
          ? ''
          : html`
              <div class="grid">
                <neb-textfield
                  class="text-field expanding"
                  id="${ELEMENTS.textfieldCustomFilenameTemplate.id}"
                  name="customFilenameTemplate"
                  label="Custom File Name"
                  maxLength="50"
                  .mask="${validFileName}"
                  .onChange="${this.handlers.change}"
                  .value="${this.state.customFilenameTemplate}"
                  .error="${this.errors.customFilenameTemplate}"
                  ?disabled="${this.readOnly}"
                ></neb-textfield>

                <neb-textfield
                  class="text-field"
                  id="${ELEMENTS.textfieldCustomExtension.id}"
                  name="customExtension"
                  label="Custom Extension"
                  .mask="${alphanumeric}"
                  .value="${this.state.customExtension}"
                  .error="${this.errors.customExtension}"
                  .onChange="${this.handlers.change}"
                  ?disabled="${this.readOnly}"
                ></neb-textfield>
              </div>
            `
      }
    `;
  }

  __renderActiveSwitch() {
    return this.state.partner !== CLEARINGHOUSES.CHC
      ? html`
          <div></div>

          <neb-switch
            id="${ELEMENTS.switchActive.id}"
            name="active"
            label="Active"
            .onChange="${this.handlers.changeActiveClearinghouse}"
            ?on="${this.state.active}"
            ?disabled="${this.readOnly || this.__disableActive()}"
          ></neb-switch>
          <div></div>
          <neb-switch
            id="${ELEMENTS.toggleTestMode.id}"
            name="testMode"
            label="${this.state.testMode ? 'Test Mode' : 'Production Mode'}"
            .onChange="${this.handlers.changeTest}"
            ?on="${!this.state.testMode}"
            ?disabled="${this.readOnly || this.__disableActive()}"
          ></neb-switch>
        `
      : '';
  }

  __renderAlias() {
    return this.state.partner !== CLEARINGHOUSES.CHC
      ? html`
          <neb-textfield
            class="text-field"
            id="${ELEMENTS.textfieldAlias.id}"
            name="alias"
            label="Alias"
            helper="Required"
            .mask="${alphanumeric}"
            .value="${this.state.alias}"
            .error="${this.errors.alias}"
            .onChange="${this.handlers.change}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>
        `
      : '';
  }

  __renderPartner() {
    return html`
      <div class="grid grid-2">
        <neb-select
          id="${ELEMENTS.selectPartner.id}"
          class="select-partner"
          name="partner"
          label="Clearinghouse Partner"
          helper="Required"
          .disabled="${!!this.model.partner}"
          .items="${CLEARINGHOUSE_ITEMS}"
          .value="${this.state.partner}"
          .error="${this.errors.partner}"
          .onChange="${this.handlers.partnerChange}"
          .sort="${CLEARINGHOUSE_ITEMS.sort()}"
        ></neb-select>
      </div>
      <div class="grid grid-2">
        ${this.__renderAlias()} ${this.__renderActiveSwitch()}
      </div>
    `;
  }

  __renderClearinghouseIdsTab() {
    return html`
      <div
        id="${ELEMENTS.tabContentClearinghouseIds.id}"
        class="grid grid-lean"
      >
        <neb-header label="Clearinghouse Identifiers"></neb-header>

        <div class="grid">
          <neb-textfield
            class="text-field"
            id="${ELEMENTS.textfieldClearinghouseIdentifierId.id}"
            name="clearinghouseIdentifierId"
            label="Identifier ID (ISA 08)"
            helper="Required"
            .mask="${characterSetX12()}"
            .value="${this.state.clearinghouseIdentifierId}"
            .error="${this.errors.clearinghouseIdentifierId}"
            .onChange="${this.handlers.change}"
            @focus="${this.handlers.presetSelect}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>

          <neb-textfield
            class="text-field"
            id="${ELEMENTS.textfieldClearinghouseETIN.id}"
            name="clearinghouseEtin"
            label="ETIN (1000B NM109)"
            helper="Required"
            .mask="${characterSetX12()}"
            .value="${this.state.clearinghouseEtin}"
            .error="${this.errors.clearinghouseEtin}"
            .onChange="${this.handlers.change}"
            @focus="${this.handlers.presetSelect}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>

          <neb-textfield
            class="text-field"
            id="${ELEMENTS.textfieldClearinghouseApplicationCode.id}"
            name="clearinghouseApplicationCode"
            label="Application Code (GS 03)"
            helper="Required"
            .mask="${characterSetX12()}"
            .value="${this.state.clearinghouseApplicationCode}"
            .error="${this.errors.clearinghouseApplicationCode}"
            .onChange="${this.handlers.change}"
            @focus="${this.handlers.presetSelect}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>
        </div>

        <neb-header label="Practice Identifiers"></neb-header>

        <div class="grid">
          <neb-textfield
            class="text-field"
            id="${ELEMENTS.textfieldPracticeIdentifierId.id}"
            name="practiceIdentifierId"
            label="Identifier ID (ISA 06)"
            helper="Required"
            .mask="${characterSetX12()}"
            placeholder="${
              (CLEARINGHOUSE_PRESETS[this.state.partner] &&
                CLEARINGHOUSE_PRESETS[this.state.partner]
                  .practiceIdentifierIdPlaceholder) ||
                ''
            }"
            .value="${this.state.practiceIdentifierId}"
            .error="${this.errors.practiceIdentifierId}"
            .onChange="${this.handlers.change}"
            @focus="${this.handlers.presetSelect}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>

          <neb-textfield
            class="text-field"
            id="${ELEMENTS.textfieldPracticeETIN.id}"
            name="practiceEtin"
            label="ETIN (1000A NM109)"
            helper="Required"
            placeholder="${
              (CLEARINGHOUSE_PRESETS[this.state.partner] &&
                CLEARINGHOUSE_PRESETS[this.state.partner]
                  .practiceEtinPlaceholder) ||
                ''
            }"
            .mask="${characterSetX12()}"
            .value="${this.state.practiceEtin}"
            .error="${this.errors.practiceEtin}"
            .onChange="${this.handlers.change}"
            @focus="${this.handlers.presetSelect}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>

          <neb-textfield
            class="text-field"
            id="${ELEMENTS.textfieldPracticeApplicationCode.id}"
            name="practiceApplicationCode"
            label="Application Code (GS 02)"
            helper="Required"
            placeholder="${
              (CLEARINGHOUSE_PRESETS[this.state.partner] &&
                CLEARINGHOUSE_PRESETS[this.state.partner]
                  .practiceApplicationCodePlaceholder) ||
                ''
            }"
            .mask="${characterSetX12()}"
            .value="${this.state.practiceApplicationCode}"
            .error="${this.errors.practiceApplicationCode}"
            .onChange="${this.handlers.change}"
            @focus="${this.handlers.presetSelect}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>
        </div>
        ${this.__renderClaimConfigurationCheckbox()}
      </div>
    `;
  }

  __renderPayerPlansPagination() {
    return this.__payerPlansState.pageCount > 1
      ? html`
          <div class="grid grid-auto-right pad">
            <div></div>
            <neb-pagination
              id="${ELEMENTS.payerPlansPagination.id}"
              .pageCount="${this.__payerPlansState.pageCount}"
              .currentPage="${this.__payerPlansState.pageIndex}"
              .onPageChanged="${this.handlers.selectPayerPlansPage}"
            ></neb-pagination>
          </div>
        `
      : '';
  }

  __renderAddButton() {
    return !this.readOnly
      ? html`
          <neb-button-action
            id="${ELEMENTS.addButtonPayerIdentifier.id}"
            class="button-add"
            label="Add Payer Identifier"
            .onClick="${this.handlers.addPayerPlan}"
            ?disabled="${!this.state.active}"
          ></neb-button-action>
        `
      : '';
  }

  __renderPayerIdsTab() {
    this.__tableItems = [...this.__payerPlansState.pageItems];

    return html`
      <div id="${ELEMENTS.tabContentPayerIds.id}" class="grid grid-lean">
        <neb-textfield
          id="${ELEMENTS.searchPayerIdentifier.id}"
          class="textfield-payer"
          label=" "
          helper=" "
          .trailingIcon="${
            this.__payerPlansState.searchText ? 'neb:clear' : ''
          }"
          .value="${this.__payerPlansState.searchText}"
          .onChange="${this.handlers.searchPayerPlans}"
          .onClickIcon="${this.handlers.clearPayerPlansSearch}"
          placeholder="Enter payer name or identifier to filter list below."
          leadingIcon="neb:search"
        ></neb-textfield>

        ${this.__renderAddButton()}
        <neb-table-payer-identifiers
          id="${ELEMENTS.tablePayerIdentifier.id}"
          .model="${this.__tableItems}"
          .config="${getPayerTableConfig(this.payerTableConfig)}"
          .onSelectPayer="${this.handlers.editPayerSettings}"
          .onRemove="${this.handlers.removePayerPlan}"
          .onChange="${this.handlers.editPayerPlan}"
          ?showRemoveButton="${!this.readOnly}"
          .payerTableConfig="${this.payerTableConfig}"
          .readOnly="${this.readOnly}"
        >
        </neb-table-payer-identifiers>
        ${this.__renderPayerPlansPagination()}
      </div>
    `;
  }

  __renderTextfieldAcknowledgement(isDisabled) {
    return this.hasAcknowledgementFeatureFlag
      ? html`
          <neb-textfield
            id="${ELEMENTS.textfieldSftpPathAcknowledgement.id}"
            name="sftpPathAcknowledgement"
            label="Acknowledgement (999)"
            .value="${this.state.sftpPathAcknowledgement}"
            .error="${this.errors.sftpPathAcknowledgement}"
            .onChange="${this.handlers.changeFileDestination}"
            @focus="${this.handlers.presetSelect}"
            ?disabled="${isDisabled}"
          ></neb-textfield>
        `
      : '';
  }

  __renderSftpTab() {
    const isDisabled = !this.state.sftpActive || this.readOnly;

    return html`
      <div id="${ELEMENTS.tabContentSftpIds.id}" class="layout">
        <div class="grid grid-lean spacer-bottom">
          <neb-header label="Server"> </neb-header>

          <div class="grid grid-2">
            <neb-switch
              id="${ELEMENTS.toggleSftpActive.id}"
              name="sftpActive"
              label="Enable SFTP"
              .onChange="${this.handlers.toggleSftpActive}"
              ?on="${this.state.sftpActive}"
              ?disabled="${
                this.readOnly || SFTP_UNAVAILABLE.includes(this.state.partner)
              }"
            ></neb-switch>

            <div></div>
          </div>

          <div class="grid grid-3">
            <neb-textfield
              class="sftp-host"
              id="${ELEMENTS.textfieldSftpHost.id}"
              name="sftpHost"
              label="Host"
              helper="Required"
              .value="${this.state.sftpHost}"
              .error="${this.errors.sftpHost}"
              .onChange="${this.handlers.change}"
              @focus="${this.handlers.presetSelect}"
              ?disabled="${isDisabled}"
            ></neb-textfield>

            <neb-textfield
              class="sftp-port"
              id="${ELEMENTS.textfieldSftpPort.id}"
              name="sftpPort"
              label="Port"
              helper="Required"
              .value="${this.state.sftpPort}"
              .error="${this.errors.sftpPort}"
              .onChange="${this.handlers.change}"
              @focus="${this.handlers.presetSelect}"
              ?disabled="${isDisabled}"
            ></neb-textfield>
          </div>
        </div>

        <div class="grid grid-lean spacer-bottom">
          <neb-header label="Authentication"> </neb-header>

          <div class="grid grid-2">
            <neb-textfield
              id="${ELEMENTS.textfieldSftpUsername.id}"
              name="sftpUsername"
              label="Username"
              helper="Required"
              .value="${this.state.sftpUsername}"
              .error="${this.errors.sftpUsername}"
              .onChange="${this.handlers.change}"
              ?disabled="${isDisabled}"
            ></neb-textfield>

            <neb-button-action
              id="${ELEMENTS.buttonSftpPassword.id}"
              label="Add/Change Password"
              leadingIcon="edit"
              ?disabled="${isDisabled}"
              .onClick="${this.handlers.sftpPassword}"
            ></neb-button-action>
          </div>
        </div>

        <div class="grid grid-lean">
          <neb-header label="Routing"> </neb-header>

          <div class="grid">
            <neb-textfield
              id="${ELEMENTS.textfieldSftpPathClaim.id}"
              name="sftpPathClaim"
              label="File Destination Claims (837)"
              .value="${this.state.sftpPathClaim}"
              .error="${this.errors.sftpPathClaim}"
              .onChange="${this.handlers.changeFileDestination}"
              .helper="${this.state.sftpActive ? 'Required' : ''}"
              @focus="${this.handlers.presetSelect}"
              ?disabled="${isDisabled}"
            ></neb-textfield>

            <neb-textfield
              id="${ELEMENTS.textfieldSftpPathEra.id}"
              name="sftpPathEra"
              label="File Destination ERAs (835)"
              .value="${this.state.sftpPathEra}"
              .error="${this.errors.sftpPathEra}"
              .onChange="${this.handlers.changeFileDestination}"
              .helper="${this.state.sftpDownloadActive ? 'Required' : ''}"
              @focus="${this.handlers.presetSelect}"
              ?disabled="${isDisabled}"
            ></neb-textfield>

            ${this.__renderTextfieldAcknowledgement(isDisabled)}
            ${
              this.hasBYOC277FF
                ? html`
                    <neb-textfield
                      id="${ELEMENTS.textfieldSftpPath277.id}"
                      name="sftpPath277"
                      label="Claim Status Response (277)"
                      .value="${this.state.sftpPath277}"
                      .error="${this.errors.sftpPath277}"
                      .onChange="${this.handlers.changeFileDestination}"
                      @focus="${this.handlers.presetSelect}"
                      ?disabled="${isDisabled}"
                    ></neb-textfield>
                  `
                : ''
            }

            <neb-switch
              id="${ELEMENTS.toggleEraDownload.id}"
              label="Automatically download files from Routing File Destinations"
              name="sftpDownloadActive"
              .onChange="${this.handlers.toggleSftpDownloadActive}"
              ?on="${this.state.sftpDownloadActive}"
              ?disabled="${isDisabled}"
            ></neb-switch>
          </div>
        </div>

        <div class="connection-status">
          <neb-button
            class="sftp-button"
            id="${ELEMENTS.buttonTestConnection.id}"
            label="Test Connection"
            .role=""
            .onClick="${this.handlers.connect}"
            ?disabled="${!this.__enableTestConnection()}"
          ></neb-button>

          <neb-icon
            id="${ELEMENTS.iconSftpStatus.id}"
            class="icon sftp-test-icon icon-${this.sftpStatus}"
            .icon="${getSftpStatusIcon(this.sftpStatus)}"
          ></neb-icon>
        </div>
      </div>
    `;
  }

  __renderSettingsTab() {
    return html`
      <neb-clearinghouse-settings
        id="${ELEMENTS.clearinghouseSettings.id}"
        .model="${this.state}"
        .errors="${this.errors}"
        .isCHC="${this.isCHC}"
        .onChange="${this.handlers.change}"
        .onChangeContactInformation="${this.handlers.changeContactInformation}"
      ></neb-clearinghouse-settings>
    `;
  }

  __renderTabRow() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabGroup.id}"
        .selectedId="${this.__tabId}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(navItem => navItem.id === this.__tabId);

    return item ? item.renderer() : html``;
  }

  renderContent() {
    return html`
      ${this.__renderPartner()} ${this.__renderTabRow()}
      ${this.__renderTabContent()}
    `;
  }
}

customElements.define('neb-form-clearinghouse', FormClearingHouse);
