import '../inputs/neb-textfield';

import { isEmailAddress } from '@neb/form-validators';
import { html, css } from 'lit';

import { ELEMENTS as BASE_ELEMENTS, Item } from './neb-item';

export const ELEMENTS = { ...BASE_ELEMENTS };

export default class EmailItem extends Item {
  static get properties() {
    return {
      value: String,
      error: String,
      helper: String,
      condense: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([showremovebutton][condense]) .container {
          grid-template-columns: 1fr auto;
        }

        :host([condense]) .container {
          grid-template-columns: 1fr;
        }
      `,
    ];
  }

  static createModel() {
    return '';
  }

  static createSelectors() {
    return [isEmailAddress('mail@email.com')];
  }

  initState() {
    super.initState();
    this.condense = false;
    this.value = '';
    this.error = '';
    this.helper = '';

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      change: e => this.onChange({ name: this.name, value: e.value }),
    };
  }

  renderLeftContent() {
    return html`
      <neb-textfield
        id="${ELEMENTS.leftContent.id}"
        name="email"
        label="Email"
        .value="${this.value}"
        .error="${this.error}"
        .helper="${this.helper || ' '}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }
}

window.customElements.define('neb-email-item', EmailItem);
