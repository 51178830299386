import '../inputs/neb-select';
import '../inputs/neb-select-search';
import '../inputs/neb-textarea';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../neb-tooltip';
import '../neb-radio-button';
import '../neb-date-picker';
import '../ledger/neb-all-patients-card';
import '../patients/neb-patient-card';
import { isPropRequired, isRequired, isRequiredIf } from '@neb/form-validators';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import {
  getPatientRelationshipsExists,
  getPatientRelationshipsActiveGroup,
} from '../../../../neb-api-client/src/patient-relationship-api-client';
import { getOnlinePaymentMerchantAccount } from '../../../../neb-api-client/src/payments/merchant-accounts-api-client';
import { getProviderUsers } from '../../../../neb-api-client/src/practice-users-api-client';
import {
  PRACTICE_SETTINGS,
  getPracticeSettings,
} from '../../../../neb-api-client/src/services/practice-settings';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { createServerPatientsCollection } from '../../../../neb-utils/collections/server-patients';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  objToName,
  DEFAULT_NAME_OPTS,
  centsToCurrency,
  currencyToCents,
} from '../../../../neb-utils/formatters';
import { currency } from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';
import { CollectionService } from '../../../../neb-utils/services/collection';
import { BUTTON_ROLE } from '../neb-button';

import Form from './neb-form';

export const CHARGES = {
  OUTSTANDING_CHARGES: false,
  ALL_CHARGES: true,
};

const GROUP_STATEMENTS = {
  INDIVIDUAL: false,
  GROUP: true,
};

const NO_ASSOCIATED_PROVIDER_ID = 'null';

export const PATIENT_STATEMENTS_SETTINGS_KEY = 'patientStatementsSettings';

export const NO_ASSOCIATED_PROVIDER = {
  label: 'No Associated Provider',
  data: { id: NO_ASSOCIATED_PROVIDER_ID },
};

export const ELEMENTS = {
  description: {
    id: 'description',
  },
  buttonOutstandingCharges: {
    id: 'button-outstanding-charges',
  },
  buttonAllCharges: {
    id: 'button-all-charges',
  },
  dateOfServiceFrom: {
    id: 'date-of-service-from',
  },
  dateOfServiceTo: {
    id: 'date-of-service-to',
  },
  selectProviders: {
    id: 'select-provider',
  },
  selectRelatedPatients: {
    id: 'select-related-patients',
  },
  billingAddressLocations: {
    id: 'billing-address-locations',
  },
  payToAddressLocations: {
    id: 'pay-to-address-locations',
  },
  checkboxGroupStatement: {
    id: 'checkbox-group-statement',
  },
  checkboxPreviousBalance: {
    id: 'checkbox-previous-balance',
  },
  checkboxAgingTable: {
    id: 'checkbox-aging-table',
  },
  checkboxBalanceEmail: {
    id: 'checkbox-balance-email',
  },
  checkboxLocations: {
    id: 'checkbox-locations',
  },
  checkboxIncludePaymentDetails: {
    id: 'checkbox-include-payment-details',
  },
  checkboxShowCarePackageChargesAsPatientPaid: {
    id: 'checkbox-show-care-package-charges-as-patient-paid',
  },
  checkboxIncludeCreditCardCutOff: {
    id: 'checkbox-include-credit-card-cut-off',
  },
  buttonGenerate: {
    id: 'button-generate',
  },
  buttonPreview: {
    id: 'button-preview',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  patientSearch: {
    id: 'patient-search',
  },
  previousBalanceToolTip: {
    id: 'previous-balance-tooltip',
  },
  balanceEmailTooltip: {
    id: 'balance-email-tooltip',
  },
  textAreaComment: {
    id: 'text-area-comment',
  },
  checkboxDueByDate: {
    id: 'checkbox-due-by-date',
  },
  dueByDate: {
    id: 'due-by-date',
  },
  minimumBalance: {
    id: 'minimum-balance',
  },
};

export const ITEM_ALL_PATIENTS = {
  label: 'All Patients Seen',
  data: {
    id: 'all-patients',
  },
};

export const formatToItems = items =>
  items.map(data => ({
    label: objToName(data.name, DEFAULT_NAME_OPTS),
    data,
  }));

export class NebFormNewStatement extends Form {
  static get properties() {
    return {
      __patientItems: Array,
      __providerItems: Array,
      __hasActiveRelationships: Boolean,
      __activeRelationships: Array,
      __relatedPatientItems: Array,
      __onlinePaymentMerchantAccount: Object,
      __showPatient: Boolean,
      __showPayToAndBillingDropdowns: Boolean,
      __showSelectDueByDateField: Boolean,
      __activeLocations: Array,

      patientId: String,
      hasStatementImprovementsFeatureFlag: Boolean,
      hasStatementBatchesFeatureFlag: Boolean,
      hasStatementBatchesPreviewFeatureFlag: Boolean,
    };
  }

  constructor() {
    super();

    this.initServices();
  }

  static createModel() {
    return {
      patientId: '',
      groupStatements: GROUP_STATEMENTS.INDIVIDUAL,
      allCharges: CHARGES.OUTSTANDING_CHARGES,
      dateOfServiceFrom: null,
      dateOfServiceTo: null,
      providerIds: [undefined],
      previousBalance: false,
      agingTable: false,
      relatedPatientIds: [],
      balanceEmail: false,
      billingAddressId: '',
      dueByDate: null,
      payToAddressId: '',
      comment: '',
      includePaymentDetails: false,
      showCarePackageChargesAsPatientPaid: true,
      includeCreditCardCutOff: false,
      minimumBalance: '',
    };
  }

  createSelectors() {
    return {
      children: {
        patientId: selectors.select(
          this.__patientItems.flat(),
          this.state.patientId,
          {
            validators: [isPropRequired('data.id')],
          },
        ),
        dateOfServiceFrom: {
          validators: [
            isRequiredIf('dateOfServiceTo', false),
            {
              error: 'Required',
              validate: (v, _, state) =>
                this.__showPatient && equal(state.patientId, ITEM_ALL_PATIENTS)
                  ? v
                  : true,
            },
          ],
          unformat: v =>
            v === null
              ? v
              : parseDate(v)
                  .startOf('day')
                  .toISOString(),
        },
        dateOfServiceTo: {
          validators: [
            isRequiredIf('dateOfServiceFrom', false),
            {
              error: 'Required',
              validate: (v, _, state) =>
                this.__showPatient && equal(state.patientId, ITEM_ALL_PATIENTS)
                  ? v
                  : true,
            },
          ],
          unformat: v =>
            v === null
              ? v
              : parseDate(v)
                  .endOf('day')
                  .toISOString(),
        },
        providerIds: selectors.multiSelect(
          this.__providerItems,
          this.__providerItems,
          { validators: [isRequired()] },
        ),
        relatedPatientIds: selectors.multiSelect(
          this.__relatedPatientItems,
          this.__relatedPatientItems,
          {
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.groupStatements && state.patientId !== ITEM_ALL_PATIENTS
                    ? v.length
                    : true,
              },
            ],
          },
        ),
        billingAddressId: selectors.select(
          this.locationItems,
          selectors.ITEM_EMPTY,
        ),
        payToAddressId: selectors.select(
          this.locationItems,
          selectors.ITEM_EMPTY,
        ),
        dueByDate: {
          validators: [
            {
              error: 'Required',
              validate: v => (this.__showSelectDueByDateField ? !!v : true),
            },
          ],
          unformat: v =>
            v === null
              ? v
              : parseDate(v)
                  .startOf('day')
                  .toISOString(),
        },
        minimumBalance: {
          clipPristine: true,
          format: v => centsToCurrency(v, true),
          unformat: v => (v ? currencyToCents(v) : ''),
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__patientItems = [];
    this.__patientState = CollectionService.createModel();
    this.__providerItems = [];
    this.__activeRelationships = [];
    this.__relatedPatientItems = [];
    this.__hasActiveRelationships = false;
    this.__onlinePaymentMerchantAccount = {};
    this.__showPatient = false;
    this.__showPayToAndBillingDropdowns = false;
    this.__showSelectDueByDateField = false;
    this.__activeLocations = [];

    this.patientId = null;
    this.hasStatementImprovementsFeatureFlag = false;
    this.hasStatementBatchesFeatureFlag = false;
    this.hasStatementBatchesPreviewFeatureFlag = false;

    this.onPreview = () => {};
  }

  initServices() {
    this.__locationsService = new LocationsService(({ userLocations }) => {
      this.__activeLocations = this.__formatLocations(userLocations).filter(
        location => location.data.active,
      );
    });
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      selectPatient: async e => {
        if (!e.value) {
          this.formService.reset();
        }

        this.formService.apply('patientId', e.value);

        const selectedPatientId = e.value ? e.value.data.id : '';

        await this.__checkActiveRelationships(selectedPatientId);
      },
      searchPatients: e => this.__patientsService.search(e.value),
      requestPatients: () => this.__patientsService.fetchMore(),
      changePatients: state => {
        this.__patientState = state;

        const patientItems = formatToItems(state.pageItems);

        this.__patientItems = [ITEM_ALL_PATIENTS, ...patientItems];
      },
      changePaymentDetails: e => {
        this.formService.apply(e.name, e.value);

        if (e.value) {
          this.formService.apply('showCarePackageChargesAsPatientPaid', false);
          this.formService.apply('previousBalance', true);
        }
      },
      selectDateOfServiceFrom: ({ value }) => {
        this.formService.apply(
          'dateOfServiceFrom',
          value !== null ? value.toISOString() : value,
        );

        this.__updateDateOfServiceToSelectable();
      },
      selectDateOfServiceTo: ({ value }) => {
        this.formService.apply(
          'dateOfServiceTo',
          value !== null ? value.toISOString() : value,
        );

        this.__updateDateOfServiceFromSelectable();
      },
      selectDueByDate: ({ value }) => {
        this.formService.apply(
          'dueByDate',
          value !== null ? value.toISOString() : value,
        );
      },
      selectGroupStatements: async ({ value }) => {
        this.formService.apply('groupStatements', value);

        if (value && !equal(this.state.patientId, ITEM_ALL_PATIENTS)) {
          const relatedPatients = await patientApiClient.fetchSome([
            this.__activeRelationships.primary,
            ...this.__activeRelationships.related,
          ]);

          const primaryIndex = relatedPatients.findIndex(
            p => p.id === this.__activeRelationships.primary,
          );

          this.__relatedPatientItems = formatToItems(relatedPatients);

          this.__relatedPatientItems[primaryIndex].label = `${
            this.__relatedPatientItems[primaryIndex].label
          } (Primary)`;

          this.__relatedPatientItems[primaryIndex].disabled = true;

          this.formService.apply(
            'relatedPatientIds',
            this.__relatedPatientItems,
          );
        } else {
          this.formService.apply('relatedPatientIds', []);
        }
      },
      preview: () => {
        if (this.formService.validate()) {
          const model = this.formService.build();
          this.__storeStatementSettings(model);

          this.__saving = true;
          this.onPreview(model);
        }
      },
      save: params => {
        const model = this.formService.build();
        this.__storeStatementSettings(model);

        super.save(params);
      },
      toggleLocationDropdowns: () => {
        if (this.__showPayToAndBillingDropdowns) {
          this.formService.apply('billingAddressId', selectors.ITEM_EMPTY);
          this.formService.apply('payToAddressId', selectors.ITEM_EMPTY);
        }
        this.__showPayToAndBillingDropdowns = !this
          .__showPayToAndBillingDropdowns;
      },
      toggleDueByDateField: () => {
        if (this.__showSelectDueByDateField) {
          this.formService.apply('dueByDate', null);
        }
        this.__showSelectDueByDateField = !this.__showSelectDueByDateField;
      },
      renderPatientItem: (model, index) =>
        model.label === ITEM_ALL_PATIENTS.label
          ? html`
              <neb-all-patients-card></neb-all-patients-card>
            `
          : html`
              <neb-patient-card
                id="item-${index}"
                tabindex="0"
                .model="${model.data}"
              ></neb-patient-card>
            `,
      minimumBalanceClear: () => this.formService.apply('minimumBalance', ''),
    };

    this.__updateDateOfServiceFromSelectable();
    this.__updateDateOfServiceToSelectable();
  }

  __storeStatementSettings(rawModel) {
    if (!this.hasStatementImprovementsFeatureFlag) {
      return;
    }

    const keysToRemove = [
      'comment',
      'dateOfServiceFrom',
      'dateOfServiceTo',
      'providerIds',
      'billingAddressId',
      'payToAddressId',
      'groupStatements',
      'patientId',
      'relatedPatientIds',
      'minimumBalance',
    ];

    const settings = Object.fromEntries(
      Object.entries(rawModel).filter(([key]) => !keysToRemove.includes(key)),
    );

    localStorage.setItem(
      PATIENT_STATEMENTS_SETTINGS_KEY,
      JSON.stringify(settings),
    );
  }

  __loadDefaultSettings() {
    if (
      this.__settings[PRACTICE_SETTINGS.ENABLE_DEFAULT_STATEMENT_COMMENT] &&
      this.__settings[PRACTICE_SETTINGS.DEFAULT_STATEMENT_COMMENT]
    ) {
      this.formService.apply(
        'comment',
        this.__settings[PRACTICE_SETTINGS.DEFAULT_STATEMENT_COMMENT],
      );
    }

    if (this.hasStatementImprovementsFeatureFlag) {
      const localStorageData = localStorage.getItem(
        PATIENT_STATEMENTS_SETTINGS_KEY,
      );

      if (localStorageData) {
        const storedInfo = JSON.parse(localStorageData);

        Object.keys(storedInfo).forEach(paramName => {
          if (paramName === 'dueByDate' && storedInfo[paramName] !== null) {
            this.__showSelectDueByDateField = true;
          }
          this.formService.apply(paramName, storedInfo[paramName]);
        });
      }
    }
  }

  async firstUpdated() {
    await super.firstUpdated();
    this.__loadDefaultSettings();
  }

  connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();
  }

  disconnectedCallback() {
    this.__locationsService.disconnect();
    super.disconnectedCallback();
  }

  async load() {
    this.__patientsService = createServerPatientsCollection({
      onChange: this.handlers.changePatients,
      initialFetch: true,
    });

    await this.__patientsService.fetchComplete();

    this.__providerItems = formatToItems(await getProviderUsers(true)).concat(
      NO_ASSOCIATED_PROVIDER,
    );

    this.__onlinePaymentMerchantAccount = await getOnlinePaymentMerchantAccount();

    this.__settings = await getPracticeSettings();
  }

  async __loadPatientInfo(id) {
    const patient = await patientApiClient.fetchOne(id);
    this.state.patientId = {
      data: {
        label: '',
        id: this.patientId,
        emailAddresses: patient.emailAddresses,
        firstName: patient.name.first,
        lastName: patient.name.last,
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('patientId')) {
      this.__showPatient = !this.patientId;
      this.build();

      if (this.patientId) {
        this.__loadPatientInfo(this.patientId);
      } else {
        this.state.patientId = '';
      }
    }

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('model')) await this.__checkActiveRelationships();
  }

  __updateDateOfServiceFromSelectable() {
    this.handlers.dateOfServiceFromSelectable = date =>
      (this.state.dateOfServiceTo === null ||
        date.isSameOrBefore(this.state.dateOfServiceTo)) &&
      date.isSameOrBefore(parseDate().startOf('day'));
  }

  __updateDateOfServiceToSelectable() {
    this.handlers.dateOfServiceToSelectable = date =>
      (this.state.dateOfServiceFrom === null ||
        date.isSameOrAfter(this.state.dateOfServiceFrom)) &&
      date.isSameOrBefore(parseDate().endOf('day'));
  }

  __formatLocations(locations) {
    return locations.map(location => ({
      data: location,
      label: location.name,
    }));
  }

  __getDateOfServiceHelperText() {
    return this.__showPatient && equal(this.state.patientId, ITEM_ALL_PATIENTS)
      ? 'Required'
      : ' ';
  }

  __getDescription() {
    let patientName = '';

    if (this.state.patientId) {
      if (this.state.patientId.label) {
        patientName = this.state.patientId.label;
      } else {
        patientName = `${this.state.patientId.data.lastName}, ${
          this.state.patientId.data.firstName
        }`;
      }
    }

    return this.__showPatient
      ? 'Generate a new statement using the parameters below.'
      : `Generate a new statement for ${patientName} using the parameters below.`;
  }

  __shouldDisableField() {
    return this.__showPatient && !this.state.patientId;
  }

  __shouldDisableBalanceEmail() {
    return (
      !equal(this.state.patientId, ITEM_ALL_PATIENTS) &&
      (!this.state.patientId ||
        !this.state.patientId.data.emailAddresses ||
        !this.state.patientId.data.emailAddresses.length)
    );
  }

  __shouldDisablePreview() {
    return (
      !this.state.patientId || equal(this.state.patientId, ITEM_ALL_PATIENTS)
    );
  }

  async __checkActiveRelationships(selectedPatientId) {
    const patientId =
      selectedPatientId !== undefined
        ? selectedPatientId
        : this.model.patientId;

    if (patientId) {
      if (patientId === ITEM_ALL_PATIENTS.data.id) {
        this.__hasActiveRelationships = (await getPatientRelationshipsExists({
          active: 'true',
        })).exists;

        this.formService.apply('relatedPatientIds', []);
      } else {
        this.__activeRelationships = await getPatientRelationshipsActiveGroup(
          patientId,
        );

        this.__hasActiveRelationships =
          this.__activeRelationships.related.length > 0;
      }
    } else {
      this.__hasActiveRelationships = false;

      this.formService.apply('groupStatements', GROUP_STATEMENTS.INDIVIDUAL);
      this.formService.apply('relatedPatientIds', []);
    }
  }

  __getBalanceEmailTooltipText() {
    return this.__shouldDisableBalanceEmail()
      ? 'Send the patient an email to view their new statement via the online booking site. The patient must have an email address.'
      : 'Select this option to send the patient an email to view their new statement via the online booking site.';
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex: 1 0 auto;
          overflow: visible;
        }

        .layout {
          padding-bottom: 0;
        }

        .grid-buttons {
          padding-top: 5px;
          padding-left: ${CSS_SPACING};
          padding-right: ${CSS_SPACING};
          grid-template-columns: auto auto auto 1fr;
        }

        .button-radio {
          margin-left: -10px;
        }

        .date-picker {
          width: 100%;
        }

        .checkbox-group-statement {
          margin-top: 10px;
          margin-bottom: 9px;
        }

        .scrollable-content {
          padding-left: ${CSS_SPACING};
          padding-right: 10px;
        }

        .padding-right {
          padding-right: 4px;
        }

        .checkbox {
          padding-bottom: 5px;
        }

        .checkbox-bottom-spaced {
          padding-top: ${CSS_SPACING};
        }

        .grid-balance-email {
          display: grid;
          grid-gap: 0 6px 0 0;
          grid-template-columns: 1fr auto;
          grid-auto-rows: min-content;
          align-items: center;
        }
      `,
    ];
  }

  get locationItems() {
    return [selectors.ITEM_EMPTY, ...this.__activeLocations];
  }

  __renderBalanceEmail() {
    return this.__onlinePaymentMerchantAccount.id
      ? html`
          <div class="grid-balance-email padding-right">
            <neb-checkbox
              id="${ELEMENTS.checkboxBalanceEmail.id}"
              class="spacer-bottom"
              name="balanceEmail"
              label="Send Statement Email"
              .checked="${this.state.balanceEmail}"
              .onChange="${this.handlers.change}"
              ?disabled="${this.__shouldDisableBalanceEmail()}"
            ></neb-checkbox>

            <neb-tooltip
              id="${ELEMENTS.balanceEmailTooltip.id}"
              class="spacer-bottom"
              defaultAnchor="left"
              ?disabled="${this.__shouldDisableField()}"
              ><div slot="tooltip">${this.__getBalanceEmailTooltipText()}</div>
            </neb-tooltip>
          </div>
        `
      : html`
          <div></div>
        `;
  }

  __renderRelatedPatients() {
    const displayDropdown =
      this.state.groupStatements &&
      !equal(this.state.patientId, ITEM_ALL_PATIENTS);

    return displayDropdown
      ? html`
          <neb-select
            id="${ELEMENTS.selectRelatedPatients.id}"
            class="span padding-right"
            name="relatedPatientIds"
            label="Related Patients"
            allLabel="Related Patients"
            helper="Required"
            .items="${this.__relatedPatientItems}"
            .value="${this.state.relatedPatientIds}"
            .onChange="${this.handlers.change}"
            .error="${this.errors.relatedPatientIds}"
            multiSelect
            wrapText
          ></neb-select>
        `
      : '';
  }

  __renderGroupStatementOptions() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxGroupStatement.id}"
        class="checkbox-group-statement"
        name="groupStatements"
        label="Group by Relationship"
        .checked="${this.state.groupStatements}"
        .onChange="${this.handlers.selectGroupStatements}"
        ?disabled="${!this.__hasActiveRelationships}"
      ></neb-checkbox>
      <div></div>

      ${this.__renderRelatedPatients()}
    `;
  }

  renderPatientSearch() {
    return this.__showPatient
      ? html`
          <neb-select-search
            id="${ELEMENTS.patientSearch.id}"
            class="span padding-right"
            name="patientId"
            label="Search for a Patient..."
            helper="Required"
            itemHeight="80"
            .items="${this.__patientItems}"
            .value="${this.state.patientId}"
            .error="${this.errors.patientId}"
            .onChange="${this.handlers.selectPatient}"
            .onSearch="${this.handlers.searchPatients}"
            .onRequest="${this.handlers.requestPatients}"
            .onRenderItem="${this.handlers.renderPatientItem}"
            showSearch
          >
          </neb-select-search>
        `
      : '';
  }

  renderActionBar() {
    const allPatientsSelected =
      this.state.patientId.data?.id === ITEM_ALL_PATIENTS.data.id;

    const isStatementBatch =
      this.hasStatementBatchesFeatureFlag && allPatientsSelected;

    return html`
      <div class="grid grid-lean grid-buttons">
        <neb-button
          id="${ELEMENTS.buttonGenerate.id}"
          label="${isStatementBatch ? 'Create Batch' : 'Generate'}"
          .onClick="${this.handlers.save}"
          ?disabled="${this.__shouldDisableField()}"
        ></neb-button>

        ${
          isStatementBatch
            ? ''
            : html`
                <neb-button
                  id="${ELEMENTS.buttonPreview.id}"
                  label="Preview"
                  .onClick="${this.handlers.preview}"
                  ?disabled="${this.__shouldDisablePreview()}"
                  .role="${BUTTON_ROLE.OUTLINE}"
                ></neb-button>
              `
        }

        <neb-button
          id="${ELEMENTS.buttonCancel.id}"
          label="Cancel"
          .onClick="${this.handlers.cancel}"
          .role="${BUTTON_ROLE.OUTLINE}"
        ></neb-button>
      </div>
    `;
  }

  __renderCheckboxLocations() {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxLocations.id}"
        name="locations"
        label="Select Billing and Pay To Location"
        ?disabled="${this.__shouldDisableField()}"
        .checked="${this.__showPayToAndBillingDropdowns}"
        .onChange="${this.handlers.toggleLocationDropdowns}"
      ></neb-checkbox>
    `;
  }

  __renderPayToAndBillingDropdowns() {
    return this.__showPayToAndBillingDropdowns
      ? html`
          <neb-select
            id="${ELEMENTS.billingAddressLocations.id}"
            class="padding-right"
            name="billingAddressId"
            label="Billing Address"
            select
            wrapText
            .items="${this.locationItems}"
            .value="${this.state.billingAddressId}"
            .onChange="${this.handlers.change}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.payToAddressLocations.id}"
            class="padding-right"
            name="payToAddressId"
            label="Pay To Address"
            select
            wrapText
            .items="${this.locationItems}"
            .value="${this.state.payToAddressId}"
            .onChange="${this.handlers.change}"
          ></neb-select>
        `
      : '';
  }

  __renderCheckboxDueByDate() {
    return this.hasStatementImprovementsFeatureFlag
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxDueByDate.id}"
            class="checkbox-bottom-spaced"
            name="checkboxDueByDate"
            label="Include Due by Date"
            ?disabled="${this.__shouldDisableField()}"
            .checked="${this.__showSelectDueByDateField}"
            .onChange="${this.handlers.toggleDueByDateField}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderSelectDueByDate() {
    return this.__showSelectDueByDateField
      ? html`
          <neb-date-picker
            id="${ELEMENTS.dueByDate.id}"
            class="date-picker"
            name="dueByDate"
            label="Select Due by Date"
            placeholder="Select Due by Date"
            manualPopoverPosition="center"
            .selectedDate="${
              this.state.dueByDate !== null
                ? parseDate(this.state.dueByDate).startOf('day')
                : this.state.dueByDate
            }"
            .invalidText="${this.errors.dueByDate}"
            .onChange="${this.handlers.selectDueByDate}"
            ?invalid="${!!this.errors.dueByDate}"
            ?disabled="${this.__shouldDisableField()}"
            momentFlag
          ></neb-date-picker>
        `
      : '';
  }

  __renderAddComment() {
    return html`
      <neb-textarea
        id="${ELEMENTS.textAreaComment.id}"
        class="span"
        label="Statement Comment"
        name="comment"
        maxlength="500"
        showCount
        .disabled="${this.__shouldDisableField()}"
        .value="${this.state.comment}"
        .onChange="${this.handlers.change}"
      ></neb-textarea>
    `;
  }

  __renderIncludePaymentDetailCheckbox() {
    return this.hasStatementImprovementsFeatureFlag
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxIncludePaymentDetails.id}"
            class="spacer-bottom"
            name="includePaymentDetails"
            label="Include Payment Details"
            .checked="${this.state.includePaymentDetails}"
            .onChange="${this.handlers.changePaymentDetails}"
            ?disabled="${this.__shouldDisableField()}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderShowCarePackageChargesAsPatientPaid() {
    if (!this.hasStatementImprovementsFeatureFlag) return '';

    return html`
      <neb-checkbox
        id="${ELEMENTS.checkboxShowCarePackageChargesAsPatientPaid.id}"
        class="spacer-bottom"
        name="showCarePackageChargesAsPatientPaid"
        label="Show Care Package Charges as Patient Paid"
        .checked="${this.state.showCarePackageChargesAsPatientPaid}"
        .onChange="${this.handlers.change}"
        ?disabled="${
          this.__shouldDisableField() || this.state.includePaymentDetails
        }"
      ></neb-checkbox>
    `;
  }

  __renderIncludeCreditCardCutOffCheckbox() {
    return this.hasStatementImprovementsFeatureFlag
      ? html`
          <neb-checkbox
            id="${ELEMENTS.checkboxIncludeCreditCardCutOff.id}"
            class="checkbox-bottom-spaced"
            name="includeCreditCardCutOff"
            label="Include Credit Card Cut Off"
            .checked="${this.state.includeCreditCardCutOff}"
            .onChange="${this.handlers.change}"
            ?disabled="${this.__shouldDisableField()}"
          ></neb-checkbox>
        `
      : '';
  }

  __renderMinimumBalance() {
    if (!this.hasStatementBatchesPreviewFeatureFlag) return '';

    return html`
      <neb-textfield
        id="${ELEMENTS.minimumBalance.id}"
        name="minimumBalance"
        label="Minimum Statement Balance"
        class="padding-right"
        helper=" "
        .inputMode="${'numeric'}"
        .mask="${currency}"
        .value="${this.state.minimumBalance}"
        .onChange="${this.handlers.change}"
        .trailingIcon="${this.state.minimumBalance ? 'neb:clear' : ''}"
        .onClickIcon="${this.handlers.minimumBalanceClear}"
        ?disabled="${this.__shouldDisableField()}"
      ></neb-textfield>
    `;
  }

  __getProvidersClass() {
    return this.hasStatementBatchesPreviewFeatureFlag
      ? ''
      : 'span padding-right';
  }

  renderContent() {
    return html`
      <div class="grid grid-2 grid-lean scrollable-content">
        <div id="${ELEMENTS.description.id}" class="span">
          ${this.__getDescription()}
        </div>

        ${this.renderPatientSearch()} ${this.__renderGroupStatementOptions()}

        <neb-radio-button
          id="${ELEMENTS.buttonOutstandingCharges.id}"
          class="button-radio"
          name="allCharges"
          label="Outstanding Charges Only"
          .value="${CHARGES.OUTSTANDING_CHARGES}"
          .onChange="${this.handlers.change}"
          ?checked="${!this.state.allCharges}"
          ?disabled="${this.__shouldDisableField()}"
        ></neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.buttonAllCharges.id}"
          class="button-radio"
          name="allCharges"
          label="All Charges"
          .value="${CHARGES.ALL_CHARGES}"
          .onChange="${this.handlers.change}"
          ?checked="${this.state.allCharges}"
          ?disabled="${this.__shouldDisableField()}"
        ></neb-radio-button>

        <neb-date-picker
          id="${ELEMENTS.dateOfServiceFrom.id}"
          class="date-picker"
          name="dateOfServiceFrom"
          label="Date of Service From"
          placeholder="Select Date"
          helperText="${this.__getDateOfServiceHelperText()}"
          manualPopoverPosition="center"
          .invalidText="${this.errors.dateOfServiceFrom}"
          .selectedDate="${
            this.state.dateOfServiceFrom !== null
              ? parseDate(this.state.dateOfServiceFrom).startOf('day')
              : this.state.dateOfServiceFrom
          }"
          .isDateSelectable="${this.handlers.dateOfServiceFromSelectable}"
          .onChange="${this.handlers.selectDateOfServiceFrom}"
          ?invalid="${this.errors.dateOfServiceFrom}"
          ?disabled="${this.__shouldDisableField()}"
          momentFlag
        ></neb-date-picker>

        <neb-date-picker
          id="${ELEMENTS.dateOfServiceTo.id}"
          class="date-picker padding-right"
          name="dateOfServiceTo"
          label="Date of Service To"
          placeholder="Select Date"
          helperText="${this.__getDateOfServiceHelperText()}"
          manualPopoverPosition="center"
          .invalidText="${this.errors.dateOfServiceTo}"
          .selectedDate="${
            this.state.dateOfServiceTo !== null
              ? parseDate(this.state.dateOfServiceTo).endOf('day')
              : this.state.dateOfServiceTo
          }"
          .isDateSelectable="${this.handlers.dateOfServiceToSelectable}"
          .onChange="${this.handlers.selectDateOfServiceTo}"
          ?invalid="${this.errors.dateOfServiceTo}"
          ?disabled="${this.__shouldDisableField()}"
          momentFlag
        ></neb-date-picker>

        <neb-select
          id="${ELEMENTS.selectProviders.id}"
          class="${this.__getProvidersClass()}"
          name="providerIds"
          label="Providers"
          allLabel="Providers"
          helper="Required"
          emptyMessage="No results found"
          .items="${this.__providerItems}"
          .value="${this.state.providerIds}"
          .onChange="${this.handlers.change}"
          .error="${this.errors.providerIds}"
          ?disabled="${this.__shouldDisableField()}"
          multiSelect
          wrapText
        ></neb-select>

        ${this.__renderMinimumBalance()}

        <div class="grid grid-auto-right">
          <neb-checkbox
            id="${ELEMENTS.checkboxPreviousBalance.id}"
            class="spacer-bottom"
            name="previousBalance"
            label="Include Previous Balance"
            .checked="${this.state.previousBalance}"
            .onChange="${this.handlers.change}"
            ?disabled="${
              this.__shouldDisableField() || this.state.includePaymentDetails
            }"
          ></neb-checkbox>

          <neb-tooltip
            id="${ELEMENTS.previousBalanceToolTip.id}"
            class="spacer-bottom"
            defaultAnchor="top"
            ?disabled="${this.__shouldDisableField()}"
            ><div slot="tooltip">
              Previous balance is the sum total of all outstanding patient
              balances for services rendered prior to the "Date of Service From"
              selection above. <br />
              <br />
              To include a previous balance on the patient statement, select a
              "Date of Service" range.
            </div>
          </neb-tooltip>
        </div>

        ${this.__renderBalanceEmail()}
        ${this.__renderIncludePaymentDetailCheckbox()}
        ${this.__renderShowCarePackageChargesAsPatientPaid()}
        ${this.__renderCheckboxLocations()}
        <neb-checkbox
          id="${ELEMENTS.checkboxAgingTable.id}"
          name="agingTable"
          label="Include Aging Table"
          .checked="${this.state.agingTable}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.__shouldDisableField()}"
        ></neb-checkbox>
        ${this.__renderPayToAndBillingDropdowns()}
        ${this.__renderCheckboxDueByDate()}
        ${this.__renderIncludeCreditCardCutOffCheckbox()}
        ${this.__renderSelectDueByDate()} ${this.__renderAddComment()}
      </div>
    `;
  }
}

customElements.define('neb-form-new-statement', NebFormNewStatement);
