import { css } from 'lit';

import {
  objToName,
  formatDollarAmount,
} from '../../../../neb-utils/formatters';
import { buildPatientName } from '../../../../neb-utils/neb-patient-name-util';

const INITIAL_PERSON_FORM = {
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
  },
  name: {
    first: '',
    last: '',
    middle: '',
    preferred: '',
    suffix: '',
  },
  dateOfBirth: '',
  emails: [''],
  employmentStatus: '',
  phones: [
    {
      number: '',
      type: '',
    },
  ],
  sex: '',
};
export const INITIAL_GUARANTOR_FORM_FIELDS = {
  active: true,
  default: false,
  note: '',
  relation: 'Other',
};
export const INITIAL_GUARANTOR_FORM = {
  ...INITIAL_GUARANTOR_FORM_FIELDS,
  person: INITIAL_PERSON_FORM,
};
export const addLabelToPerson = person => ({
  ...person,
  label: buildPatientName(person, true),
});

export const addLabelToEntity = entity =>
  entity.firstName && entity.lastName
    ? {
        ...entity,
        label: buildPatientName(entity, true),
      }
    : {
        ...entity,
        label: entity.name,
      };

export const addLabelToOrg = org => ({ ...org, label: org.name });
export const NAME_OPTS = {
  reverse: true,
  middleInitial: true,
  preferred: true,
};
export const formatGuarantorName = guarantor =>
  guarantor.person
    ? objToName(
        {
          first: guarantor.person.firstName,
          last: guarantor.person.lastName,
          middle: guarantor.person.middleName,
          preferred: guarantor.person.preferredName,
          suffix: guarantor.person.suffix,
        },
        NAME_OPTS,
      )
    : guarantor.organization.name;
export const ACTIVE_GUARANTOR_TABLE_CONFIG = [
  {
    key: 'name',
    label: 'Name',
    flex: css`2 0 0`,
    formatter: (v, guarantor) => formatGuarantorName(guarantor),
  },
  {
    key: 'relation',
    label: 'Relation',
    flex: css`1 0 0`,
  },
  {
    key: 'balance',
    label: 'Balance',
    flex: css`1 0 0`,
    formatter: v => formatDollarAmount(v || 0),
  },
];
