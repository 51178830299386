export const INVERSE_PATIENT_RELATIONSHIP_TYPE = Object.freeze({
  Spouse: 'Spouse',
  Child: 'Parent',
  Parent: 'Child',
  Grandparent: 'Grandchild',
  Grandchild: 'Grandparent',
  Sibling: 'Sibling',
  Other: 'Other',
});

export const PATIENT_RELATIONSHIP_TYPE = Object.freeze({
  Spouse: 'Spouse',
  Child: 'Child',
  Parent: 'Parent',
  Grandparent: 'Grandparent',
  Grandchild: 'Grandchild',
  Sibling: 'Sibling',
  Other: 'Other',
});

export const PATIENT_RELATIONSHIP_TYPES = Object.values(
  PATIENT_RELATIONSHIP_TYPE,
);

export const getPostBodyFromRaw = obj => {
  if (obj) {
    delete obj.id;
    delete obj.patientId;
  }

  return obj;
};

export const patientRelationshipToRaw = model => {
  const raw = { ...model, relatedPatientId: model.relatedPatient.id };

  delete raw.relatedPatient;

  return raw;
};

export const rawPatientRelationshipToModel = raw => ({
  ...raw,
  note: raw.note || '',
});

export const checkCountPaymentMethodInSchedule = (patients, patientId) =>
  Object.entries(patients).some(([id, count]) => id !== patientId && count > 0);
