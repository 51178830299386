import ApiClient, {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const BASE_PATH = 'eob';

export const apiClient = new ApiClient({
  microservice: 'billing',
  useTenant: true,
});

export const post = data =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_PATH,
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const updateEob = (eobId, data) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/${eobId}/`,
    method: Method.PUT,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const getEob = async eobId => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_PATH}/${eobId}/`,
    queryParams: { eobId },
    version: 1,
  });

  return res;
};

export const updateStatus = (eobId, status) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/${eobId}/${status}/`,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

export const bulkUpdateStatus = data =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_PATH,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    body: JSON.stringify(data),
    version: 1,
  });

export const associateCharges = ({ version = 1, eobId, lineItemIds }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/${eobId}/charges/`,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body: JSON.stringify({ lineItemIds }),
    version,
  });

export const associatePayments = async (
  eobId,
  paymentIds,
  optOutLoadingIndicator = false,
) => {
  await apiClient.makeRequest({
    path: `eob/${eobId}/payments/`,
    method: Method.POST,
    body: JSON.stringify({ paymentIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const removeEobCharges = async ({
  version = 1,
  eobId,
  chargeIds,
  optOutLoadingIndicator = false,
  claimStatus,
}) => {
  const rawObject = { chargeIds, ...(claimStatus ? { claimStatus } : {}) };
  await apiClient.makeRequest({
    path: `eob/${eobId}/charges/`,
    method: Method.DELETE,
    body: JSON.stringify(rawObject),
    headers: {
      'Content-Type': 'application/json',
    },
    version,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const unlinkPayment = async (
  eobId,
  paymentId,
  optOutLoadingIndicator = false,
) => {
  await apiClient.makeRequest({
    path: `eob/${eobId}/payments/`,
    method: Method.DELETE,
    body: JSON.stringify({ paymentId }),

    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const createSecondaryClaims = async ({
  eobId,
  lineItemIds,
  optOutLoadingIndicator = false,
  version = 1,
}) => {
  const result = await apiClient.makeRequest({
    path: `eob/${eobId}/secondary-claims/`,
    method: Method.POST,
    body: JSON.stringify({ lineItemIds }),

    headers: {
      'Content-Type': 'application/json',
    },
    version,
    optOutLoadingIndicator,
  });
  return result;
};

export const getEobSecondaryClaimDraftsToBeCreated = async (
  eobId,
  lineItemIds,
  optOutLoadingIndicator = false,
  version = 1,
) => {
  const result = await apiClient.makeRequest({
    path: `eob/${eobId}/secondary-claims-to-create-total/`,
    method: Method.GET,
    queryParams: { lineItemIds },
    headers: {
      'Content-Type': 'application/json',
    },
    version,
    optOutLoadingIndicator,
  });
  return result;
};
