import { css, html } from 'lit';

import { openError } from '../../../packages/neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import { SORT_DIR } from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { NEBULA_REFRESH_EVENT } from '../../../packages/neb-utils/neb-refresh';
import * as rteRecordsApi from '../../api-clients/real-time-eligibility-records';
import TableRTEInsuranceLog from '../tables/neb-table-rte-insurance-log';

import '../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../packages/neb-lit-components/src/components/neb-text';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

class CollectionPageRTEInsuranceLog extends CollectionPage {
  static get config() {
    return {
      ...CollectionPage.config,
      hideDetailArrow: false,
      initialSortKey: 'submittedAt',
      initialSortOrder: SORT_DIR.DESC,
      showInactiveFilter: null,
      tableConfig: TableRTEInsuranceLog.config,
    };
  }

  static get properties() {
    return {
      patientId: String,
      patientInsuranceId: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .row-form {
          padding: 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.patientInsuranceId = '';
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      openS3: async rteRecordId => {
        let tab;

        try {
          const rteRecord = await rteRecordsApi.fetchS3(
            this.patientId,
            this.patientInsuranceId,
            rteRecordId,
            true,
          );
          const rteRecordJson = JSON.stringify(rteRecord, null, '\t');
          tab = window.open();

          tab.document.write(`
            <pre>
              <div id="rteRecord" style="margin-top: 25px; width: 75%; font-family: sans-serif; color: #000000;">
                ${rteRecordJson}
              </div>
            </pre>
          `);

          tab.document.title = `Real-Time Eligibility Record ${rteRecordId}`;

          tab.document.close();

          return tab;
        } catch (_) {
          store.dispatch(
            openError(
              'Cannot open Real-Time Eligibility Data tab, please check your pop-up blocker',
            ),
          );
        }

        return tab;
      },
      refresh: async () => {
        if (this.getConfig().useFetch) {
          this.service.fetch();
        } else {
          const items = await this.fetch();
          this.service.setItems(items);
        }
      },
      sort: (_, result) => {
        this.service.setSortParams(result[0]);
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);
  }

  disconnectedCallback() {
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);

    super.disconnectedCallback();
  }

  async fetch() {
    const { data } = await rteRecordsApi.fetchMany(
      this.patientId,
      this.patientInsuranceId,
      true,
    );

    return data;
  }

  renderNoItemsContent() {
    return html`
      <neb-text>No items.</neb-text>
    `;
  }

  renderTable() {
    return html`
      <neb-table-rte-insurance-log
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .config="${this.getConfig().tableConfig}"
        .layout="${this.layout}"
        .lookupKey="${this.getConfig().lookupKey}"
        .model="${this.__tableState.pageItems}"
        .sortParams="${[this.__tableState.sortParams]}"
        .onOpenS3="${this.handlers.openS3}"
        .onSort="${this.handlers.sort}"
        ?showDetailArrow="${!this.getConfig().hideDetailArrow}"
      ></neb-table-rte-insurance-log>
    `;
  }
}

window.customElements.define(
  'neb-collection-page-rte-insurance-log',
  CollectionPageRTEInsuranceLog,
);
