import moment from 'moment-timezone';

import { getPractices } from '../../src/api-clients/registry';
import {
  userLogin,
  getPracticeUser,
} from '../neb-api-client/src/permissions-api-client';
import { getPracticeUserMeta } from '../neb-api-client/src/practice-user-meta-api-client';
import {
  COGNITO_TYPES,
  authenticateUser,
  renew as renewViaCognito,
} from '../neb-utils/cognito-util';

export const ERROR_EXCEEDED =
  'Your account has been temporarily locked. Try again in one minute or contact customer support.';
export const MESSAGE_EXCEEDED = 'Password attempts exceeded';
export const KAFKA_DOWN_ERROR_MESSAGE =
  'An error has occurred. Please be informed that our engineering team has been notified and will address the issue shortly. We apologize for any inconvenience caused.';
const ERROR_MESSAGES = ['No Access', MESSAGE_EXCEEDED];
const ERROR_GENERIC = 'An error occurred.';
const MAXIMUM_DAYS = 90;
const SESSION_AUTHENTICATING = 'SESSION_AUTHENTICATING';
const SESSION_AUTHENTICATED = 'SESSION_AUTHENTICATED';
const SESSION_AUTHENTICATION_FAILED = 'SESSION_AUTHENTICATION_FAILED';
const SESSION_RENEWING = 'SESSION_RENEWING';
const SESSION_RENEWED = 'SESSION_RENEWED';
const SESSION_RENEW_FAILED = 'SESSION_RENEW_FAILED';
const SESSION_REQUIRES_AUTHENTICATION = 'SESSION_REQUIRES_AUTHENTICATION';
const SESSION_REQUIRES_TENANT_SELECTION = 'SESSION_REQUIRES_TENANT_SELECTION';
const SESSION_RECOMMEND_PASSWORD_CHANGE = 'SESSION_RECOMMEND_PASSWORD_CHANGE';
const SESSION_SET_ITEM = 'SESSION_SET_ITEM';
const SESSION_TENANT_SELECTED = 'SESSION_TENANT_SELECTED';
const SESSION_CLEAR = 'SESSION_CLEAR';
const SESSION_USER_HAS_NO_TENANT = 'SESSION_USER_HAS_NO_TENANT';
const KAFKA_DOWN_ERROR = 'KAFKA_DOWN_ERROR';

export const sessionReducer = (
  state = {
    isAuthenticated: false,
    requiresAuthentication: undefined,
    message: undefined,
    isAuthenticating: false,
    hasAuthenticationFailed: false,
    requiresTenantSelection: false,
    recommendPasswordChange: false,
    intermediateAccessToken: undefined,
    availableTenants: undefined,
    userHasNoPractice: false,
    isRenewing: false,
    hasRenewFailed: false,
    item: undefined,
  },
  action,
) => {
  switch (action.type) {
    case SESSION_AUTHENTICATING:
      return { ...state, isAuthenticating: true, message: null };

    case KAFKA_DOWN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        recommendPasswordChange: false,
        requiresAuthentication: false,
        message: KAFKA_DOWN_ERROR_MESSAGE,
        hasAuthenticationFailed: false,
        isAuthenticating: false,
        item: action.value,
      };

    case SESSION_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        recommendPasswordChange: false,
        requiresAuthentication: false,
        message: null,
        hasAuthenticationFailed: false,
        isAuthenticating: false,
        item: action.value,
      };

    case SESSION_AUTHENTICATION_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        requiresAuthentication: true,
        message: action.message,
        isAuthenticating: false,
        hasAuthenticationFailed: true,
        item: null,
      };

    case SESSION_RENEWING:
      return { ...state, isRenewing: true };

    case SESSION_RENEWED:
      return {
        ...state,
        isAuthenticated: true,
        requiresAuthentication: false,
        message: null,
        isRenewing: false,
        hasRenewFailed: false,
        item: action.value,
      };

    case SESSION_RENEW_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        requiresAuthentication: true,
        message: null,
        isRenewing: false,
        hasRenewFailed: true,
      };

    case SESSION_REQUIRES_AUTHENTICATION:
      return { ...state, requiresAuthentication: true };

    case SESSION_USER_HAS_NO_TENANT:
      return { ...state, userHasNoPractice: true };

    case SESSION_REQUIRES_TENANT_SELECTION:
      return {
        ...state,
        isAuthenticating: false,
        requiresTenantSelection: true,
        availableTenants: action.availableTenants,
        intermediateAccessToken: action.intermediateAccessToken,
      };

    case SESSION_RECOMMEND_PASSWORD_CHANGE:
      return {
        ...state,
        recommendPasswordChange: true,
        isAuthenticating: false,
      };

    case SESSION_SET_ITEM:
      return { ...state, item: action.value };

    case SESSION_TENANT_SELECTED:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        requiresTenantSelection: false,
        recommendPasswordChange: false,
        availableTenants: null,
        intermediateAccessToken: null,
        item: action.value,
      };

    case SESSION_CLEAR:
    case 'RESET_ALL':
      return {
        isAuthenticated: false,
        requiresAuthentication: true,
        message: null,
        isAuthenticating: false,
        hasAuthenticationFailed: false,
        requiresTenantSelection: false,
        recommendPasswordChange: false,
        availableTenants: null,
        intermediateAccessToken: null,
        isRenewing: false,
        hasRenewFailed: false,
        userHasNoPractice: false,
        item: null,
      };

    default:
      return state;
  }
};

const getCognitoAuth = async (
  username,
  password,
  type = COGNITO_TYPES.PRACTICE,
) => {
  try {
    return await authenticateUser({
      username,
      password,
      type,
    });
  } catch (err) {
    console.error(err);

    if (err.message === KAFKA_DOWN_ERROR_MESSAGE) {
      throw err;
    }

    if (ERROR_MESSAGES.includes(err.message)) {
      throw err;
    }
  }
  return undefined;
};

const getLoggedInUserInfo = async cognitoId => {
  const {
    name: { first, last },
    photo: { imageUrl },
    locations,
    defaultLocationId,
  } = await getPracticeUser(cognitoId);

  const profileImgUrl = imageUrl ? imageUrl.replace('large', 'small') : '';

  return {
    firstName: first,
    lastName: last,
    profileImgUrl,
    locations,
    defaultLocationId,
  };
};

export const setItem = value => ({
  type: SESSION_SET_ITEM,
  value,
});

export const requiresAuthentication = () => ({
  type: SESSION_REQUIRES_AUTHENTICATION,
});

export const clearSession = () => ({
  type: SESSION_CLEAR,
});

export const useSession = session => ({
  type: SESSION_AUTHENTICATED,
  value: session,
});

export const postAuthentication = cognitoAuth => async dispatch => {
  const { tenantId, tenantIds, id: cognitoId, type } = cognitoAuth;

  if (type === COGNITO_TYPES.SUPPORT) {
    return dispatch({
      type: SESSION_AUTHENTICATED,
      value: {
        ...cognitoAuth,
      },
    });
  }

  const tenantIdList = tenantIds && tenantIds.length ? tenantIds : [tenantId];
  const availableTenants = await getPractices(tenantIdList);

  if (availableTenants.length > 1) {
    return dispatch({
      type: SESSION_REQUIRES_TENANT_SELECTION,
      availableTenants,
    });
  }

  const [{ id, shortName: tenantShortName }] = availableTenants;
  const userInfo = await getLoggedInUserInfo(cognitoId);

  if (id) {
    await userLogin(cognitoAuth.id);
  }

  return dispatch({
    type: SESSION_AUTHENTICATED,
    value: {
      ...cognitoAuth,
      tenantShortName,
      tenantId: id,
      tenantIds: [id],
      ...userInfo,
    },
  });
};

const checkNoAccessErr = (err, dispatch) => {
  if (err.message === 'No Access') {
    return dispatch({
      type: SESSION_USER_HAS_NO_TENANT,
    });
  }
  return dispatch({
    type: SESSION_AUTHENTICATION_FAILED,
    message: err.message === MESSAGE_EXCEEDED ? ERROR_EXCEEDED : ERROR_GENERIC,
  });
};

/**
 * @param {Object} options
 * @param {String} options.clientId
 * @param {String} options.secret
 */
export const authenticate = options => async dispatch => {
  const { clientId, secret, type = COGNITO_TYPES.PRACTICE } = options;
  dispatch({
    type: SESSION_AUTHENTICATING,
  });

  try {
    const cognitoAuth = await getCognitoAuth(clientId, secret, type);

    if (cognitoAuth) {
      await dispatch(setItem({ ...cognitoAuth }));

      if (cognitoAuth.id) {
        if (type === COGNITO_TYPES.PRACTICE && cognitoAuth.tenantIds.length) {
          const practiceUserMeta = await getPracticeUserMeta(cognitoAuth.id);
          const daysSincePasswordChange = moment().diff(
            practiceUserMeta.lastPasswordChangeAt,
            'days',
          );

          if (daysSincePasswordChange > MAXIMUM_DAYS) {
            return dispatch({
              type: SESSION_RECOMMEND_PASSWORD_CHANGE,
            });
          }
        }

        return dispatch(postAuthentication(cognitoAuth));
      }
    } else {
      return dispatch({
        type: SESSION_AUTHENTICATION_FAILED,
        message: 'Incorrect email and/or password.',
      });
    }
  } catch (err) {
    if (err.message === KAFKA_DOWN_ERROR_MESSAGE) {
      await dispatch({
        type: KAFKA_DOWN_ERROR,
        message: KAFKA_DOWN_ERROR_MESSAGE,
      });
    } else {
      console.error(err);
      await checkNoAccessErr(err, dispatch);
    }
  }

  return undefined;
};

export const renew = options => async dispatch => {
  dispatch({
    type: SESSION_RENEWING,
  });

  try {
    const session = await renewViaCognito(options);

    if (session) {
      let tenantShortName = '';
      let tenantIds;
      let availableTenants;
      let userInfo;

      if (options.type !== COGNITO_TYPES.SUPPORT) {
        await dispatch(setItem({ ...session }));
        availableTenants = await getPractices(session.tenantIds);
        tenantIds = availableTenants.map(tenant => {
          if (session.tenantId === tenant.id) {
            tenantShortName = tenant.shortName;
          }
          return tenant.id;
        });

        userInfo = await getLoggedInUserInfo(session.id);
      }

      dispatch({
        type: SESSION_RENEWED,
        value: { ...session, tenantIds, tenantShortName, ...userInfo },
      });
    } else {
      dispatch({
        type: SESSION_AUTHENTICATION_FAILED,
        message: 'You do not have access to this application.',
      });
    }
  } catch (err) {
    dispatch({
      type: SESSION_RENEW_FAILED,
    });
  }
};
/**
 * @param {Object} options
 * @param {String} options.tenantId
 */

export const selectTenant = options => async (dispatch, getState) => {
  const { session } = getState();
  const { intermediateAccessToken: accessToken } = session;
  const avaliableTenantIds = options.availableTenants.map(({ id }) => id);
  const userInfo = await getLoggedInUserInfo(options.item.id);

  await dispatch({
    type: SESSION_TENANT_SELECTED,
    value: {
      ...options.item,
      tenantId: options.tenantId,
      tenantIds: avaliableTenantIds,
      tenantShortName: options.selectedTenant.shortName,
      ...userInfo,
    },
  });

  if (!options.item.idToken) {
    return dispatch(renew({ ...options, accessToken }));
  }

  return userLogin(options.item.id);
};
