import { css } from 'lit';

import {
  NebOverlayClaimError,
  ELEMENTS as BASE_ELEMENTS,
} from './neb-overlay-claim-error';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

class NebOverlayClaimErrorManageEncounter extends NebOverlayClaimError {
  static get properties() {
    return {};
  }

  initState() {
    super.initState();
  }

  static get styles() {
    return [super.styles, css``];
  }
}

customElements.define(
  'neb-overlay-claim-error-manage-encounter',
  NebOverlayClaimErrorManageEncounter,
);
