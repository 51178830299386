import { html, css } from 'lit';

import * as ledgerStatementApiClient from '../../neb-api-client/src/ledger-statement-api-client';
import { openSuccess, openError } from '../../neb-dialog/neb-banner-state';
import {
  NebFormNewStatement,
  ITEM_ALL_PATIENTS,
} from '../../neb-lit-components/src/components/forms/neb-form-new-statement';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../neb-lit-components/src/utils/overlay-constants';
import { store } from '../../neb-redux/neb-redux-store';
import { CSS_FONT_FAMILY, CSS_SPACING } from '../../neb-styles/neb-variables';
import { FEATURE_FLAGS, getFeatures } from '../../neb-utils/feature-util';
import { printStatementPdf } from '../../neb-utils/neb-pdf-print-util';

import NebPopup, { ELEMENTS as BASE_ELEMENTS } from './neb-popup';

export const BANNER = {
  BATCH_SUCCESS:
    'Statement batch generation started successfully. Click "View Batches" to check the batch status',
  BATCH_ERROR: 'An error occurred when generating the statement batch.',
  SINGLE_SUCCESS: 'Statement generated successfully',
  SINGLE_ERROR:
    'An error occurred when generating the statement. There are no charges for the selected parameters.',
  PREVIEW_ERROR:
    'An error occurred when previewing the statement. There are no charges for the selected parameters.',
  NO_ADDRESS_ERROR:
    'Practice Billing information has no Billing and/or Pay To Address',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  form: {
    id: 'form',
  },
};

class NebPopupNewStatement extends NebPopup {
  static get properties() {
    return {
      __formModel: Object,
      __hasStatementImprovementsFeatureFlag: Boolean,
      __hasStatementBatchesFeatureFlag: Boolean,
      __hasStatementBatchesPreviewFeatureFlag: Boolean,
    };
  }

  initState() {
    super.initState();

    this.__formModel = NebFormNewStatement.createModel();

    this.title = 'Generate New Statement';
    this.model = { patientId: '' };

    this.__hasStatementImprovementsFeatureFlag = false;
    this.__hasStatementBatchesFeatureFlag = false;
    this.__hasStatementBatchesPreviewFeatureFlag = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: model => this.__save(model, false, true),
      preview: model => this.__save(model, true),
      cancel: () => this.onClose(false),
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    const features = await getFeatures();

    this.__hasStatementImprovementsFeatureFlag = features.includes(
      FEATURE_FLAGS.STATEMENT_IMPROVEMENTS,
    );

    this.__hasStatementBatchesFeatureFlag = features.includes(
      FEATURE_FLAGS.STATEMENT_BATCHES,
    );

    this.__hasStatementBatchesPreviewFeatureFlag = features.includes(
      FEATURE_FLAGS.STATEMENT_BATCHES_PREVIEW,
    );
  }

  async __printPdf({
    model,
    statements,
    patientId,
    preview,
    addressIds,
    optOutLoadingIndicator = false,
  }) {
    if (this.__hasStatementImprovementsFeatureFlag) {
      const [statementsBuffer] = statements;
      await printStatementPdf(Promise.resolve(statementsBuffer));
    } else {
      await printStatementPdf(
        ledgerStatementApiClient.print({
          patientId,
          statements,
          addressIds,
          preview,
          comment: model.comment,
          optOutLoadingIndicator,
        }),
      );
    }

    if (!preview) this.onClose(true);
  }

  async __save(model, preview = false, optOutLoadingIndicator = false) {
    const patientId =
      model.patientId !== ITEM_ALL_PATIENTS.data.id ? model.patientId : null;

    try {
      const billingPayToAddress = {
        billingAddressId: model.billingAddressId,
        payToAddressId: model.payToAddressId,
      };

      const statements = patientId
        ? await ledgerStatementApiClient.add(
            patientId,
            model,
            preview,
            billingPayToAddress,
            this.__hasStatementImprovementsFeatureFlag,
            this.__hasStatementBatchesPreviewFeatureFlag,
          )
        : await ledgerStatementApiClient.addBatch(
            {
              model,
              preview,
              optOutLoadingIndicator: true,
              addressIds: billingPayToAddress,
            },
            this.__hasStatementImprovementsFeatureFlag,
            this.__hasStatementBatchesPreviewFeatureFlag,
          );

      if (patientId) {
        await this.__printPdf({
          model,
          statements,
          patientId,
          preview,
          addressIds: billingPayToAddress,
          optOutLoadingIndicator,
        });
      } else {
        store.dispatch(openSuccess(BANNER.BATCH_SUCCESS));
        this.onClose(true);

        if (this.__hasStatementBatchesFeatureFlag) {
          openOverlay(OVERLAY_KEYS.VIEW_BATCHES, ledgerStatementApiClient);
        }
      }
    } catch (e) {
      let error;

      if (patientId) {
        error = preview ? 'PREVIEW_ERROR' : 'SINGLE_ERROR';
      } else {
        error = 'BATCH_ERROR';
      }

      store.dispatch(openError(BANNER[error]));
    }

    this.__formModel = model;
  }

  modelChanged() {
    if (this.model.patientId) {
      this.__formModel = {
        ...this.__formModel,
        patientId: this.model.patientId,
      };
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          font-family: ${CSS_FONT_FAMILY};
          width: 725px;
          overflow: auto;
        }

        .container {
          padding-left: 0px;
          height: auto;
        }

        .header {
          padding-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  renderContent() {
    return html`
      <neb-form-new-statement
        id="${ELEMENTS.form.id}"
        .model="${this.__formModel}"
        .patientId="${this.model.patientId}"
        .onSave="${this.handlers.save}"
        .onPreview="${this.handlers.preview}"
        .onCancel="${this.handlers.cancel}"
        .hasStatementImprovementsFeatureFlag="${
          this.__hasStatementImprovementsFeatureFlag
        }"
        .hasStatementBatchesFeatureFlag="${
          this.__hasStatementBatchesFeatureFlag
        }"
        .hasStatementBatchesPreviewFeatureFlag="${
          this.__hasStatementBatchesPreviewFeatureFlag
        }"
      ></neb-form-new-statement>
    `;
  }
}

customElements.define('neb-popup-new-statement', NebPopupNewStatement);
