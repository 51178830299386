import { ConnectedService } from '../../packages/neb-redux/services/connected-service';
import { setRumTenant } from '../library/datadog';

export class DatadogRumService extends ConnectedService {
  constructor() {
    super({
      tenant: 'practiceInformation.item',
    });
  }

  _stateChanged({ tenant }) {
    setRumTenant(tenant);
  }
}
