import { css, html } from 'lit';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
} from '../../styles';
import { getRTEStatusIcon } from '../../utils/real-time-eligibility';

import '../../../packages/neb-lit-components/src/components/controls/neb-button-icon';
import '../../../packages/neb-lit-components/src/components/neb-text';
import '../misc/neb-icon';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  buttonIcons: { selector: '.button-icon' },
  dates: { selector: '.date' },
  statusIcons: { selector: '.icon-status' },
};

const DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

export default class TableRTEInsuranceLog extends Table {
  static get config() {
    return [
      {
        key: 'submittedAt',
        label: 'Date and Time',
        flex: css`1 0 0`,
        sortable: true,
        formatter: value => value.format(DATE_FORMAT),
        compare: (a, b) => {
          if (a.isSame(b)) {
            return 0;
          }
          return a.isBefore(b) ? -1 : 1;
        },
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`0 0 100px`,
      },
      {
        key: 'description',
        label: 'Description',
        flex: css`2 0 0`,
      },
    ];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container-icon {
          display: flex;
          align-items: center;
        }

        .icon-status {
          display: block;
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-warning {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-validated,
        .icon-partially-validated {
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        .button-icon {
          margin-left: 10px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.config = this.constructor.config;

    this.onOpenS3 = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      openS3: rteRecordId => this.onOpenS3(rteRecordId),
    };
  }

  __renderIconOpen(value, rowIndex) {
    const { id, chcJSONExists } = this.model[rowIndex];

    return chcJSONExists
      ? html`
          <neb-button-icon
            class="button-icon"
            icon="neb:open"
            .onClick="${() => this.handlers.openS3(id)}"
          ></neb-button-icon>
        `
      : '';
  }

  renderDataCell(value, columnConfig, rowIndex, _name, _error) {
    switch (columnConfig.key) {
      case 'status': {
        return html`
          <div class="container-icon">
            <neb-icon
              class="icon-status icon-${value}"
              .icon="${getRTEStatusIcon(value)}"
            ></neb-icon>

            ${this.__renderIconOpen(value, rowIndex)}
          </div>
        `;
      }

      case 'submittedAt': {
        return html`
          <neb-text class="date">${value}</neb-text>
        `;
      }

      default:
        return super.renderDataCell(value, columnConfig);
    }
  }
}

window.customElements.define(
  'neb-table-rte-insurance-log',
  TableRTEInsuranceLog,
);
