import '../../tables/neb-table-case-charges';

import { css, html } from 'lit';

import { getLedgerInvoiceItems } from '../../../../packages/neb-api-client/src/invoice-api-client';
import { fetchOne } from '../../../../packages/neb-api-client/src/patient-api-client';
import { getCharges } from '../../../../packages/neb-api-client/src/patient-cases';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/neb-page-collection';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import { LINE_ITEM_TYPE } from '../../../../packages/neb-utils/neb-ledger-util';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

class NebPageCaseCharges extends CollectionPage {
  static get config() {
    return {
      ...CollectionPage.config,
      hideDetailArrow: true,
      showInactiveFilter: null,
      hideHeader: true,
      pageSize: 100,
    };
  }

  static get properties() {
    return {
      __charges: Array,
      caseId: {
        type: String,
      },
      patientId: {
        type: String,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .container {
          display: flex;
          overflow: auto;
          padding: ${CSS_SPACING};
          width: auto;
          height: auto;
          min-height: 0;
          flex-flow: column nowrap;
          flex: 1 0 0px;
          margin-bottom: 20px;
        }

        .row {
          display: unset;
        }

        .row-form {
          padding-top: 0;
        }

        neb-pagination {
          padding-left: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();
    this.caseId = '';
    this.patientId = '';
    this.__charges = [];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      viewCharge: async ({ id, type }) => {
        if (type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE) {
          await openOverlay(OVERLAY_KEYS.LEDGER_LINE_ITEM, {
            patientId: this.patientId,
            id,
          });
        } else {
          const patient = await fetchOne(this.patientId);
          await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
            patient,
            lineItemIds: [id],
            selectedIds: [],
          });
        }
        await this.__refetch();
      },
      viewInvoice: async ({ id, invoiceId }) => {
        const [result, patient] = await Promise.all([
          invoiceId ? getLedgerInvoiceItems(invoiceId) : null,
          fetchOne(this.patientId),
        ]);
        const lineItemIds = result ? result.data.map(li => li.id) : [id];
        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
          patient,
          lineItemIds,
          selectedIds: [],
        });

        await this.__refetch();
      },
    };
  }

  async fetch() {
    const charges = await getCharges({
      caseId: this.caseId,
      patientId: this.patientId,
    });
    return charges;
  }

  async __refetch() {
    const charges = await this.fetch();
    this.service.setItems(charges);
  }

  renderTable() {
    return html`
      <neb-table-case-charges
        id="${ELEMENTS.table.id}"
        class="list-charges"
        .model="${this.__tableState.pageItems}"
        .onSelectCharge="${this.handlers.viewCharge}"
        .onSelectInvoice="${this.handlers.viewInvoice}"
      ></neb-table-case-charges>
    `;
  }
}

window.customElements.define('neb-page-case-charges', NebPageCaseCharges);
