import '../controls/neb-button-action';
import '../../../../../src/components/controls/inputs/neb-checkbox';
import '../inputs/neb-select';
import '../neb-calendar-picker';
import '../neb-loading-overlay';
import './neb-encounter-list-item';
import '../neb-patient-search';
import '../../../../../src/components/misc/neb-icon';
import '../../../../neb-styles/neb-icons';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { when } from 'lit/directives/when.js';

import { updateAppointmentRoom } from '../../../../../src/api-clients/appointments';
import NebFormAppointment from '../../../../../src/components/forms/appointments/neb-form-appointment';
import { openOverlayCheckInOut } from '../../../../../src/features/check-in-out/utils/open-overlay-check-in-out';
import LocationFilter from '../../../../../src/utils/locations/location-filter';
import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../../src/utils/locations/location-util';
import { SELECT_BY_TYPE } from '../../../../../src/utils/scheduling/appointments';
import { getAllEncounters } from '../../../../neb-api-client/src/encounters-api-client';
import { getSchedulingPermissions } from '../../../../neb-api-client/src/permissions-api-client';
import { fetchManyRooms } from '../../../../neb-api-client/src/rooms-api-client';
import {
  openError,
  openSuccess,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { AppointmentDetailsMomentService } from '../../../../neb-redux/services/appointment-details-moment';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING,
  CSS_SPACING_ROW,
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
  CSS_FONT_SIZE_HEADER,
} from '../../../../neb-styles/neb-variables';
import { roomConflictOverridePopup } from '../../../../neb-utils/calendar-resources-util';
import { parseDate } from '../../../../neb-utils/date-util';
import { roundUpTo5MinuteIncrement } from '../../../../neb-utils/neb-cal-util';
import { URL_NO_ACCESS } from '../../../../neb-utils/neb-request-security';
import { openAppointmentPage } from '../../utils/appointment-overlays-util';
import { openEncounterSummary } from '../../utils/encounter-overlays-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { fetchAndOpenMatchPopup } from '../../utils/patients';
import { APPOINTMENT_ACTIONS } from '../scheduling/neb-appointment-options';

export const ELEMENTS = {
  buttonAddPatient: {
    id: 'button-add-patient',
  },
  calendar: {
    id: 'calendar',
  },
  checkboxCheckedIn: {
    id: 'checkbox-check-in',
  },
  hideFiltersIcon: {
    id: 'hide-filters',
  },
  filterText: {
    id: 'filter-text',
  },
  noEvents: {
    id: 'no-events',
  },
  selectLocation: {
    id: 'select-location',
  },
  selectProvider: {
    id: 'select-provider',
  },
  list: {
    id: 'list',
  },
  spinner: {
    id: 'spinner',
  },
  textSearchPatient: {
    id: 'text-search-patient',
  },
  selectEncounterStatus: {
    id: 'select-encounter-status',
  },
};

export const ENCOUNTER_ACTIONS = {
  CHECK_IN_OUT: 'CHECK_IN_OUT',
  CREATE_OPEN_ENCOUNTER: 'CREATE_OPEN_ENCOUNTER',
  VIEW_APPOINTMENT_DETAILS: 'VIEW_APPOINTMENT_DETAILS',
  CHANGE_ROOM: 'CHANGE_ROOM',
  PATIENT_RECORD: 'PATIENT_RECORD',
  PRIOR_ENCOUNTER: 'PRIOR_ENCOUNTER',
};

export const SELECTED_PROVIDERS_KEY = 'encounter-selected-providers';

export const SELECTED_LOCATIONS_KEY = 'encounter-selected-locations';

export const SHOW_CHECK_IN_ONLY_KEY = 'encounter-show-check-in-only';

export const SELECTED_ENCOUNTER_STATUS_KEY = 'encounter-selected-status';

export const SHOW_FILTERS_KEY = 'encounter-show-filters';

const PATIENT_SEARCH = 'Search Patient';

export const ENCOUNTER_STATUSES = [
  { label: 'Signed', id: 'Signed' },
  { label: 'Open', id: 'Open' },
  { label: 'No Encounter', id: 'No Encounter' },
];

/**
 * Callback properties:
 * @prop onAddWalkInPatient
 * @prop onDisplayCard
 * @prop onSelect
 * @prop onSelectDate
 * @prop onUpdateOverlay
 */
class NebEncounterList extends LitElement {
  static get properties() {
    return {
      __selectedProviders: { type: Array },
      __selectedLocations: { type: Array },
      __selectedEncounterStatus: { type: Array },
      __showCheckedInOnly: { type: Boolean },
      __selectedAppointmentId: { type: String },
      __encounterDetails: { type: Object },
      __displayFilters: { type: Boolean },
      __filteredEvents: { type: Array },
      __toggledIndex: { type: Number },
      __toggledId: { type: String },
      __hasPriorEncounters: { type: Boolean },

      filteredPatient: { type: Object },
      sortByStatus: { type: Boolean },
      encounterId: { type: String },
      events: { type: Array, reflect: true },
      loadingIndicator: { type: Object },
      locations: { type: Object },
      providers: { type: Array },
      selectedDate: { type: Object },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();

    this.__appointmentService = new AppointmentDetailsMomentService(
      ({ appointment }) => {
        if (!appointment) return;

        const { eventId } =
          this.events.find(event => {
            const id = event.appointmentId || event.id;

            return appointment.id === id;
          }) || {};

        this.onUpdateOverlay(appointment, eventId);
      },
    );
  }

  __initState() {
    this.__selectedLocations = [];
    this.__selectedProviders = [];
    this.__selectedEncounterStatus = [];
    this.__showCheckedInOnly = this.__getCheckedInOutFromLocalStorage();

    this.__now = parseDate();
    this.__selectedAppointmentId = '';

    this.__encounterDetails = {};

    this.__displayFilters = this.__getDisplayFiltersFromLocalStorage();
    this.__filteredEvents = [];
    this.__toggledIndex = -1;
    this.__toggledId = '';
    this.__hasPriorEncounters = true;

    this.filteredPatient = null;
    this.loadingIndicator = { loading: true, message: 'Loading Encounters...' };

    this.sortByStatus = false;
    this.encounterId = '';
    this.events = [];
    this.locations = [];
    this.providers = [];
    this.selectedDate = parseDate();

    this.onAddWalkInPatient = () => {};

    this.onDisplayCard = () => {};

    this.onSelect = () => {};

    this.onSelectDate = () => {};

    this.onUpdateOverlay = () => {};

    this.onQuickAction = () => {};

    this.onPatientSearch = () => {};
  }

  connectedCallback() {
    super.connectedCallback();

    this.__appointmentService.connect();
  }

  disconnectedCallback() {
    this.__appointmentService.disconnect();

    super.disconnectedCallback();
  }

  async __updateAppointmentIdFromOverlayResult(item, result = {}) {
    let model = item;

    if (result.success) {
      model = result.model;
    }

    const appointmentId = model.appointmentId || model.id;

    if (this.__selectedAppointmentId !== appointmentId) {
      this.__selectedAppointmentId = appointmentId;
    } else {
      this.__appointmentService.update(appointmentId);
    }

    if (result.mode === 'checkIn') {
      await this.onUpdateOverlay(appointmentId);
    }
  }

  async __updateAppointmentIdFromPopupResult(item, result) {
    const model = item;
    const appointmentAction = APPOINTMENT_ACTIONS.CHANGE_ROOM;
    const appointmentId = model.appointmentId || model.id;

    if (result && result.room.id !== item.roomId) {
      const roomId = result.room.id;

      try {
        if (await roomConflictOverridePopup(roomId)) {
          await updateAppointmentRoom({
            id: appointmentId,
            body: { roomId },
          });

          this.__appointmentService.update(appointmentId);
          this.onUpdateOverlay(appointmentId);

          store.dispatch(openSuccess(appointmentAction.successMessage));
        }
      } catch (_) {
        store.dispatch(openError(appointmentAction.errorMessage));
      }
    }
  }

  __getCreatedEncounterId(encounterCreated) {
    let encounterId = '';

    if (
      encounterCreated &&
      encounterCreated.res &&
      encounterCreated.res.data &&
      encounterCreated.res.data[0]
    ) {
      encounterId = encounterCreated.res.data[0].id;
    }

    return encounterId;
  }

  async selectNewlyCreatedEncounter(result) {
    const encounterId = this.__getCreatedEncounterId(result.encounterCreated);
    await this.getUpdatedItem(encounterId);
  }

  async getUpdatedItem(encounterId) {
    if (encounterId) {
      const updatedItem = this.__filteredEvents.find(
        event => event.encounter && event.encounter.id === encounterId,
      );

      if (updatedItem) {
        await this.__handlers.selectEncounter(updatedItem);
      }
    }
  }

  __selectEncounterAfterCheckIn(result, item) {
    if (result && result.mode === 'checkIn') {
      return result.encounterCreated
        ? this.selectNewlyCreatedEncounter(result)
        : this.__handlers.selectEncounter(item);
    }

    return null;
  }

  __initHandlers() {
    this.__handlers = {
      addWalkInPatient: () => {
        if (getSchedulingPermissions()) {
          this.__openPopupAddNewPatient();
        } else {
          navigate(URL_NO_ACCESS);
        }
      },
      cardDisplayed: model => {
        this.onDisplayCard(model);
      },
      selectDate: date => {
        this.onSelectDate(date);
      },
      quickAction: () => {
        this.onQuickAction();
      },
      selectEncounter: async item => {
        if (!item.patient.id) {
          const popup = await fetchAndOpenMatchPopup(item.accountId, item.id);

          if (!popup.back) {
            await openAppointmentPage(item.id);
            this.onQuickAction();
          }
          if (popup.deleted) this.onQuickAction();
        } else {
          this.__encounterDetails = item;

          await this.onSelect({
            id: this.id,
            name: this.name,
            value: this.__encounterDetails,
          });
        }
      },
      selectEncounterStatus: ({ value }) => {
        this.__selectedEncounterStatus = value;
        localStorage.setItem(
          SELECTED_ENCOUNTER_STATUS_KEY,
          JSON.stringify(value),
        );
      },
      selectLocations: ({ value }) => {
        this.__selectedLocations = value;
        localStorage.setItem(SELECTED_LOCATIONS_KEY, JSON.stringify(value));
      },
      selectProvider: ({ value }) => {
        this.__selectedProviders = value;
        localStorage.setItem(SELECTED_PROVIDERS_KEY, JSON.stringify(value));
      },
      toggleCheckedIn: () => {
        this.__showCheckedInOnly = !this.__showCheckedInOnly;
        localStorage.setItem(SHOW_CHECK_IN_ONLY_KEY, this.__showCheckedInOnly);
      },
      action: async (item, action) => {
        if (!item.patient.id) {
          const popup = await fetchAndOpenMatchPopup(item.accountId, item.id);

          if (!popup.back) {
            await openAppointmentPage(item.id);
            this.onQuickAction();
          }
          if (popup.deleted) this.onQuickAction();

          return popup;
        }

        this.__selectedAppointmentId = item.appointmentId || item.id;
        return this.__handleAction(item, action);
      },
      toggleFilters: () => {
        this.__displayFilters = !this.__displayFilters;
        localStorage.setItem(SHOW_FILTERS_KEY, this.__displayFilters);
      },
      onPatientSearch: patient => {
        this.onPatientSearch(patient);
      },
      toggleItem: ({ id }) => {
        const index = this.__filteredEvents.findIndex(
          ({ id: encounterId }) => encounterId === id,
        );

        this.__toggledIndex = this.__toggledIndex === index ? -1 : index;

        if (this.__toggledIndex === -1) {
          return;
        }

        this.__toggledId = id;
        this.__checkPriorEncounters(index);
      },
    };
  }

  async __checkPriorEncounters(index) {
    const {
      patient: { id: patientId },
    } = this.__filteredEvents[index];

    if (!patientId) {
      this.__hasPriorEncounters = false;
    } else {
      const { data: priorEncounters } = await getAllEncounters(patientId);
      this.__hasPriorEncounters = !!priorEncounters.length;
    }
  }

  __handleAction(item, action) {
    switch (action) {
      case ENCOUNTER_ACTIONS.CHECK_IN_OUT:
        return this.__openCheckInOut(item);

      case ENCOUNTER_ACTIONS.CREATE_OPEN_ENCOUNTER:
        return this.__handlers.selectEncounter(item);

      case ENCOUNTER_ACTIONS.VIEW_APPOINTMENT_DETAILS:
        return this.__openViewAppointmentDetails(item);

      case ENCOUNTER_ACTIONS.CHANGE_ROOM:
        return this.__openChangeRoom(item);

      case ENCOUNTER_ACTIONS.PATIENT_RECORD:
        return this.__openPatientRecord(item);

      case ENCOUNTER_ACTIONS.PRIOR_ENCOUNTER:
        return this.__openPriorEncounter(item);

      default: {
        return undefined;
      }
    }
  }

  __openPriorEncounter(item) {
    const { encounter } = item;
    return openEncounterSummary({
      ...item,
      ...(encounter && { encounterId: encounter.id }),
    });
  }

  async __openCheckInOut(item) {
    const result = await this.__openCheckInOutOverlay(item);
    await this.__updateAppointmentIdFromOverlayResult(item, result);

    return this.__selectEncounterAfterCheckIn(result, item);
  }

  async __openViewAppointmentDetails(item) {
    const result = await this.__openViewAppointmentDetailsOverlay();
    return this.__updateAppointmentIdFromOverlayResult(item, result);
  }

  async __openChangeRoom(item) {
    const result = await this.__openChangeRoomPopup(item);
    this.__updateAppointmentIdFromPopupResult(item, result);
  }

  __openPatientRecord(item) {
    const {
      patient: { id: patientId },
    } = item;
    const root = `#/patients/${patientId}`;
    return navigate(`${root}/overview/profile`);
  }

  __openCheckInOutOverlay(appointment) {
    const start = parseDate(appointment.start);
    const { locationId } = appointment;

    const location = getLocationValue(
      this.locations,
      locationId,
      LOCATION_KEYS.NAME,
    );

    appointment = {
      ...appointment,
      start,
      id: this.__selectedAppointmentId,
      details: {
        startDisplayDate: start.format('dddd, MMMM DD, YYYY'),
        durationDisplay: this.__appointmentService.computeDurationDisplay(
          start,
          parseDate(appointment.end),
        ),
        location,
        appointmentTypeName: appointment.appointmentType,
        providerDisplayName: appointment.provider,
      },
    };

    return openOverlayCheckInOut(OVERLAY_KEYS.CHECK_IN_OUT, {
      appointment,
      patientId: appointment.patient.id,
      mode: appointment.status === 'Checked-In' ? 'checkOut' : 'checkIn',
      forceEncounterCreation: true,
    });
  }

  __openViewAppointmentDetailsOverlay() {
    return openOverlay(OVERLAY_KEYS.APPOINTMENT_PAGE, {
      appointmentId: this.__selectedAppointmentId,
    });
  }

  async __openChangeRoomPopup(item) {
    const rooms = (await fetchManyRooms()).filter(
      room =>
        room.active &&
        room.checkInAvailable &&
        room.locationId === item.locationId,
    );

    return openPopup(POPUP_RENDER_KEYS.APPOINTMENT_CHANGE_ROOM, {
      appointmentId: this.__selectedAppointmentId,
      rooms,
      roomId: item.roomId,
    });
  }

  __getProviderEvents(events) {
    return this.__selectedProviders.length === this.providers.length
      ? events
      : events.filter(event => {
          const providers = this.__selectedProviders.map(p => p.id || null);

          return providers.includes(event.providerId);
        });
  }

  __getEncounterStatusEvents(events) {
    return this.__selectedEncounterStatus.length === ENCOUNTER_STATUSES
      ? events
      : events.filter(event => {
          const encounterStatuses = this.__selectedEncounterStatus.map(
            x => x.id,
          );
          return encounterStatuses.includes(event.encounterStatus);
        });
  }

  __filterEvents() {
    if (!this.__selectedProviders.length) {
      this.__filteredEvents = [];
      return;
    }

    if (!this.__selectedEncounterStatus.length) {
      this.__filteredEvents = [];
      return;
    }

    const checkedInOnlyEvents = this.__showCheckedInOnly
      ? this.events.filter(event => event.status === 'Checked-In')
      : this.events;

    const providerEvents = this.__getProviderEvents(checkedInOnlyEvents);

    this.__filteredEvents = providerEvents.filter(
      LocationFilter(this.__selectedLocations).execute,
    );

    this.__filteredEvents = this.__getEncounterStatusEvents(
      this.__filteredEvents,
    );

    const index = this.__filteredEvents.findIndex(
      encounter => encounter.id === this.__toggledId,
    );
    this.__toggledIndex = index;
  }

  __oneProviderSelected() {
    return this.__selectedProviders.length === 1
      ? this.__selectedProviders[0].id
      : '';
  }

  async __openPopupAddNewPatient() {
    const time = roundUpTo5MinuteIncrement(this.__selectedDate);
    const start = parseDate(this.__selectedDate)
      .hours(time.hour)
      .minutes(time.min)
      .toISOString();

    const result = await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
      ...NebFormAppointment.createModel(),
      start,
      providerId: this.__oneProviderSelected(),
      type: SELECT_BY_TYPE.CUSTOM,
      walkIn: true,
    });

    if (result && result.model) {
      this.onAddWalkInPatient();
    }
  }

  __setSelectedEncounterStatusFromLocalStorage() {
    const storeSelectedEncounterStatus = JSON.parse(
      localStorage.getItem(SELECTED_ENCOUNTER_STATUS_KEY),
    );

    this.__selectedEncounterStatus =
      storeSelectedEncounterStatus || ENCOUNTER_STATUSES;
  }

  __setSelectedLocation() {
    const storedSelectedLocations = this.__getSelectedLocationsFromLocalStorage();

    this.__selectedLocations = storedSelectedLocations.length
      ? storedSelectedLocations
      : this.locations;
  }

  __setSelectedProvider() {
    const { session } = store.getState();
    const { r: role } = session.item.access[session.item.tenantId];
    const storedSelectedProviders = this.__getSelectedProvidersFromLocalStorage();

    if (storedSelectedProviders.length > 0) {
      this.__selectedProviders = storedSelectedProviders;
    } else if (role === 'provider' || role === 'specialist') {
      const { id } = session.item;
      this.__selectedProviders = this.providers.filter(x => x.id === id);
    } else this.__selectedProviders = this.providers;
  }

  __indexOfNextEvent() {
    const eventIndex = this.__filteredEvents.findIndex(event => {
      const date = parseDate(event.start);

      return date.isSameOrAfter(this.__now);
    });

    return eventIndex > -1 ? eventIndex : this.__filteredEvents.length - 1;
  }

  __getItemScrollIndex() {
    if (this.__filteredEvents.length > 0) {
      if (parseDate(this.__filteredEvents[0].start).isSame(this.__now, 'day')) {
        return this.__indexOfNextEvent();
      }

      return 0;
    }

    return -1;
  }

  __getSelectedLocationsFromLocalStorage() {
    const storeSelectedLocations = JSON.parse(
      localStorage.getItem(SELECTED_LOCATIONS_KEY),
    );

    return storeSelectedLocations
      ? this.locations.filter(l =>
          storeSelectedLocations.find(v => v.id === l.id),
        )
      : [];
  }

  __getSelectedProvidersFromLocalStorage() {
    const storedSelectedProviders = JSON.parse(
      localStorage.getItem(SELECTED_PROVIDERS_KEY),
    );

    if (!storedSelectedProviders) return [];

    return this.providers.filter(p =>
      storedSelectedProviders.find(v => v.id === p.id),
    );
  }

  __getCheckedInOutFromLocalStorage() {
    const checkedInOut = localStorage.getItem(SHOW_CHECK_IN_ONLY_KEY);
    return checkedInOut === 'true';
  }

  __getDisplayFiltersFromLocalStorage() {
    const displayFilters = localStorage.getItem(SHOW_FILTERS_KEY);
    return !displayFilters ? true : displayFilters === 'true';
  }

  __checkRefreshEvents(changedProps) {
    const props = [
      'events',
      '__selectedProviders',
      '__selectedLocations',
      '__showCheckedInOnly',
      '__selectedEncounterStatus',
    ];

    return props.some(prop => changedProps.has(prop));
  }

  __resetToggledValues(changedProps) {
    if (changedProps.has('__toggledIndex') && this.__toggledIndex === -1) {
      this.__toggledId = '';
      this.__hasPriorEncounters = true;
    }
  }

  update(changedProps) {
    if (
      changedProps.has('__selectedAppointmentId') &&
      this.__selectedAppointmentId
    ) {
      this.__appointmentService.update(this.__selectedAppointmentId);
    }

    if (changedProps.has('locations')) {
      this.__setSelectedLocation();
    }

    if (changedProps.has('providers')) {
      this.__setSelectedProvider();
    }

    if (changedProps.has('__selectedEncounterStatus')) {
      this.__setSelectedEncounterStatusFromLocalStorage();
    }

    if (this.__checkRefreshEvents(changedProps)) {
      this.__filterEvents();
    }

    this.__resetToggledValues(changedProps);

    super.update(changedProps);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-checkbox {
          display: flex;
          margin-top: ${CSS_SPACING};
        }

        .container-filter {
          display: flex;
          width: 100%;
          height: fit-content;
        }

        .container-header {
          display: flex;
          flex-direction: column;

          padding: ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .flex-col {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
        }

        .padding-top {
          padding-top: ${CSS_SPACING_ROW};
        }

        .calendar {
          margin-bottom: ${CSS_SPACING};
        }

        .select-provider {
          display: flex;
          width: 100%;
          margin-right: ${CSS_SPACING};
        }

        .select-location {
          display: flex;
          width: 100%;
        }

        .checkbox-checked-in {
          display: flex;
          justify-content: flex-start;
          margin-right: 20px;
        }

        .flex-center {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .pointer {
          cursor: pointer;
        }

        .color-fill {
          color: ${CSS_COLOR_HIGHLIGHT};
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          width: 16px;
          height: 16px;
          transform: rotate(0deg);
          transition: 250ms ease;
        }

        .icon-up {
          transform: rotate(180deg);
          transition: 250ms ease;
        }

        .container-list {
          flex: 1 0 0;
          display: block;
          overflow-y: scroll;

          -ms-overflow-style: none;
          scrollbar-width: none;
        }

        :host([loading]) .container-list {
          overflow-y: hidden;
        }

        .container-list::-webkit-scrollbar {
          display: none;
        }

        .item {
          display: flex;
          width: 100%;
        }

        .container-label {
          flex: 1 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .label-no-events {
          color: ${CSS_COLOR_HIGHLIGHT};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .spinner {
          position: absolute;
          display: flex;
          top: 95px;
          width: 100%;
          height: calc(100% - 95px);
        }

        .spinner-compressed {
          top: 330px;
          height: calc(100% - 330px);
        }

        .button-add-patient {
          display: flex;
          align-items: center;
          width: 100%;
          padding: ${CSS_SPACING};
          border-top: 1px solid ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_HIGHLIGHT};
        }
      `,
    ];
  }

  __renderEncounterStatusDropdown() {
    return html`
      <div class="container-filter">
        <neb-select
          id="${ELEMENTS.selectEncounterStatus.id}"
          class="select select-location padding-top"
          allLabel="Encounter Statuses"
          label="Select Encounter Status"
          .items="${ENCOUNTER_STATUSES}"
          .value="${this.__selectedEncounterStatus}"
          .onChange="${this.__handlers.selectEncounterStatus}"
          multiSelect
          wrapText
        ></neb-select>
      </div>
    `;
  }

  __renderPatientSearch() {
    return html`
      <div class="container-filter">
        <neb-patient-search
          id="${ELEMENTS.textSearchPatient.id}"
          class="padding-top"
          .patient="${this.filteredPatient}"
          .onChange="${this.__handlers.onPatientSearch}"
          .label="${PATIENT_SEARCH}"
        ></neb-patient-search>
      </div>
    `;
  }

  __renderProvidersDropdown() {
    return html`
      <div class="container-filter">
        <neb-select
          id="${ELEMENTS.selectProvider.id}"
          class="select select-provider"
          allLabel="Providers"
          label="Select Providers"
          .items="${this.providers}"
          .value="${this.__selectedProviders}"
          .onChange="${this.__handlers.selectProvider}"
          multiSelect
          wrapText
        ></neb-select>
      </div>
    `;
  }

  __renderLocationsDropdown() {
    return html`
      <div class="container-filter">
        <neb-select
          id="${ELEMENTS.selectLocation.id}"
          class="select select-location"
          allLabel="Locations"
          label="Select Locations"
          .items="${this.locations}"
          .value="${this.__selectedLocations}"
          .onChange="${this.__handlers.selectLocations}"
          multiSelect
          wrapText
        ></neb-select>
      </div>
    `;
  }

  __renderCheckbox() {
    return html`
      <div class="container-checkbox">
        <neb-checkbox
          id="${ELEMENTS.checkboxCheckedIn.id}"
          class="checkbox checkbox-checked-in"
          label="Show Checked-In Only"
          .checked="${this.__showCheckedInOnly}"
          .onChange="${this.__handlers.toggleCheckedIn}"
        ></neb-checkbox>
      </div>
    `;
  }

  __renderHeader() {
    const icon = {
      icon: true,
      'icon-up': this.__displayFilters,
    };

    return html`
      <div class="container-header">
        <neb-calendar-picker
          id="${ELEMENTS.calendar.id}"
          class="calendar"
          .selectedDate="${this.__selectedDate}"
          .onDateSelected="${this.__handlers.selectDate}"
          momentFlag
        >
        </neb-calendar-picker>

        ${this.__renderFilters()}

        <div class="flex-center color-fill">
          <div
            class="flex-center pointer"
            @click="${this.__handlers.toggleFilters}"
          >
            <neb-icon
              id="${ELEMENTS.hideFiltersIcon.id}"
              class="${classMap(icon)}"
              icon="neb:chevron"
            ></neb-icon>
            ${
              when(
                this.__displayFilters,
                () => html`
                  <div id="${ELEMENTS.filterText.id}">Hide Filters</div>
                `,
                () => html`
                  <div id="${ELEMENTS.filterText.id}">Show Filters</div>
                `,
              )
            }
          </div>
        </div>
        <div></div>
      </div>
    `;
  }

  __renderFilters() {
    return this.__displayFilters
      ? html`
          <div class="flex-col">
            ${this.__renderProvidersDropdown()}
            ${this.__renderLocationsDropdown()}
          </div>
          ${this.__renderEncounterStatusDropdown()}
          ${this.__renderPatientSearch()} ${this.__renderCheckbox()}
        `
      : html``;
  }

  __renderList() {
    const scrollToIndex =
      this.sortByStatus || this.filteredPatient
        ? -1
        : this.__getItemScrollIndex();
    return this.__filteredEvents.length || this.loadingIndicator?.loading
      ? html`
          <neb-encounter-list-item
            id="${ELEMENTS.list.id}"
            class="container-list"
            .items="${this.__filteredEvents}"
            .scrollToIndex="${scrollToIndex}"
            .onDisplayCard="${this.__handlers.cardDisplayed}"
            .onSelect="${this.__handlers.selectEncounter}"
            .onAction="${this.__handlers.action}"
            .onQuickAction="${this.__handlers.quickAction}"
            .encounterId="${this.encounterId}"
            .renderSpacers="${!!this.filteredPatient}"
            .toggledIndex="${this.__toggledIndex}"
            .hasPriorEncounters="${this.__hasPriorEncounters}"
            .onToggleItem="${this.__handlers.toggleItem}"
          >
          </neb-encounter-list-item>
        `
      : html`
          <div class="container-label">
            <div id="${ELEMENTS.noEvents.id}" class="label label-no-events">
              No Appointments
            </div>
          </div>
        `;
  }

  render() {
    const loadingIndicator = {
      spinner: true,
      'spinner-compressed': this.__displayFilters,
    };
    return html`
      <div class="container">
        ${this.__renderHeader()} ${this.__renderList()}

        <neb-button-action
          id="${ELEMENTS.buttonAddPatient.id}"
          class="button-add-patient"
          label="Add Walk-In Patient"
          .onClick="${this.__handlers.addWalkInPatient}"
        ></neb-button-action>

        ${
          this.loadingIndicator?.loading
            ? html`
                <neb-loading-overlay
                  id="${ELEMENTS.spinner.id}"
                  class="${classMap(loadingIndicator)}"
                  title="${this.loadingIndicator?.message}"
                  showDelay="0"
                  .show="${true}"
                ></neb-loading-overlay>
              `
            : ''
        }
      </div>
    `;
  }
}

window.customElements.define('neb-encounter-list', NebEncounterList);
