import { format } from 'date-fns';
import equal from 'fast-deep-equal';
import moment from 'moment-timezone';

import { getPatientAppointments } from '../neb-api-client/src/appointment-api-client';
import * as patientApiClient from '../neb-api-client/src/patient-api-client';
import { getPatientImage } from '../neb-api-client/src/patient-image-api-client';

import {
  splitIntoTerms,
  sanitizePhones,
  formatPhoneNumber,
  normalizeForSearch,
  objToInitials,
} from './formatters';
import { PATIENT_PAYMENT_DEFAULT } from './patientPayment';

export const EMPTY_ADDRESS = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
};

export const EMPTY_REFERRAL = {
  id: '',
  patientId: '',
  categoryId: '',
  description: '',
  sourceId: '',
  sourceType: '',
};

export const HIDE_INACTIVE_PATIENTS_KEY = 'hide-inactive-patients';

export const PATIENT_STATUS = Object.freeze({
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
});

function patientToSearchTerms(patient) {
  return normalizeForSearch(
    [
      patient.medicalRecordNumber,
      patient.name.first,
      patient.name.last,
      patient.name.preferred,
      patient.name.middle,
      format(patient.dateOfBirth, 'MMDDYYYY'),
      moment(patient.dateOfDeath).format('MMDDYYYY'),
      ...patient.phoneNumbers.map(item => item.number),
    ]
      .filter(term => term)
      .join(' '),
  );
}

export function mapPatientName(raw) {
  return {
    first: raw.firstName || '',
    last: raw.lastName || '',
    middle: raw.middleName || '',
    preferred: raw.preferredName || '',
    suffix: raw.suffix || '',
  };
}

export function mapPatientReferral(referralSource) {
  if (referralSource) {
    return {
      id: referralSource.id || '',
      patientId: referralSource.patientId || '',
      categoryId: referralSource.categoryId || '',
      description: referralSource.description || '',
      sourceId: referralSource.sourceId || '',
      sourceType: referralSource.sourceType || '',
    };
  }
  return EMPTY_REFERRAL;
}

export function mapToPatientModel(
  raw,
  rawAppointments,
  alertCount = 0,
  photoSrc = '',
) {
  const appointments = {
    last: null,
    next: null,
  };

  if (rawAppointments) {
    const appt = rawAppointments;

    if (appt.lastAppointment) {
      appointments.last = {
        id: appt.lastAppointment.id || '',
        date: new Date(appt.lastAppointment.start) || new Date(),
      };
    }

    if (appt.nextAppointment) {
      appointments.next = {
        id: appt.nextAppointment.id || '',
        date: new Date(appt.nextAppointment.start) || new Date(),
      };
    }
  }

  return {
    id: raw.id || '',
    tenantId: raw.tenantId || '',
    active: raw.status !== 'Inactive',
    deleted: raw.deleted || false,
    hasPhoto: Boolean(raw.patientImage) || false,
    medicalRecordNumber: raw.medicalRecordNumber || '',
    bookingAccountId: raw.bookingAccountId,
    mrn: raw.medicalRecordNumber || '',
    ssn: raw.ssn || '',
    sex: raw.sex || '',
    insurance: raw.insurance || PATIENT_PAYMENT_DEFAULT.SELF_PAY,
    referredBy: raw.referredBy || '',
    dateOfBirth: raw.dateOfBirth || null,
    phoneNumbers: raw.phoneNumbers || [],
    emailAddresses: raw.emailAddresses || [],
    preferredProviderId: raw.preferredProviderId || '',
    preferredLocationId: raw.preferredLocationId || '',
    addresses: (raw.addresses || []).map(addr => ({
      address1: addr.address1 || '',
      address2: addr.address2 || '',
      city: addr.city || '',
      state: addr.state || '',
      zipcode: addr.zipcode || '',
    })),
    appointments,
    name: mapPatientName(raw),
    statuses: {
      patient: raw.status || 'Active',
      deceased: raw.deceased || false,
      dateOfDeath: raw.dateOfDeath || null,
      relationship: raw.relationshipStatus || '',
      employment: raw.employmentStatus || '',
    },
    agreements: {
      privacy: raw.noticePrivacyPractice || '',
      consent: raw.completedConsent || '',
    },
    alertCount,
    photoSrc,
    previousPatient: !!raw.previousPatient,
    lastLocation: raw.lastLocation || null,
    todaysEncounter: raw.todaysEncounter || null,
    billType: raw.billType || 'Self Pay',
    caseBillTypeOverride: !!raw.caseBillTypeOverride,
    selfCheckInPIN: raw.selfCheckInPIN || '',
    patientReferralSourceId: raw.patientReferralSourceId || '',
    referralSource: mapPatientReferral(raw.referralSource),
  };
}

export async function fetchPatient(id, optOutLoadingIndicator = false) {
  const patient = await patientApiClient.fetchOne(
    id,
    optOutLoadingIndicator,
    true,
  );

  if (patient) {
    const appts = await getPatientAppointments(id, optOutLoadingIndicator);
    const photoSrc = patient.patientImage
      ? await getPatientImage(patient.id, undefined, optOutLoadingIndicator)
      : '';
    return mapToPatientModel(patient, appts, 0, photoSrc);
  }

  return null;
}

export function createModel() {
  return mapToPatientModel({});
}

export function patientToRaw(patient) {
  return {
    id: patient.id,
    tenantId: patient.tenantId,
    deleted: patient.deleted,
    bookingAccountId: patient.bookingAccountId,
    medicalRecordNumber: patient.medicalRecordNumber || null,
    ssn: patient.ssn || null,
    sex: patient.sex,
    insurance: patient.insurance || null,
    referredBy: patient.referredBy || null,
    dateOfBirth: format(patient.dateOfBirth, 'YYYY-MM-DD'),
    status: patient.statuses.patient || null,
    deceased: patient.statuses.deceased || false,
    dateOfDeath: patient.statuses.dateOfDeath
      ? moment(patient.statuses.dateOfDeath).format('YYYY-MM-DD')
      : null,
    relationshipStatus: patient.statuses.relationship || null,
    employmentStatus: patient.statuses.employment || null,
    firstName: patient.name.first,
    lastName: patient.name.last,
    middleName: patient.name.middle || null,
    preferredName: patient.name.preferred || null,
    suffix: patient.name.suffix || null,
    preferredProviderId: patient.preferredProviderId || null,
    preferredLocationId: patient.preferredLocationId || null,
    emailAddresses: patient.emailAddresses,
    phoneNumbers: sanitizePhones(patient.phoneNumbers),
    addresses: patient.addresses,
    previousPatient: patient.previousPatient
      ? Boolean(patient.previousPatient)
      : false,
    billType: patient.billType,
    caseBillTypeOverride: patient.caseBillTypeOverride ? 1 : 0,
    selfCheckInPIN: patient.selfCheckInPIN || null,
    relationshipIds: patient.relationshipIds || [],
    updateAllPINs: patient.updateAllPINs || false,
    patientReferralSourceId: patient.patientReferralSourceId || null,
    referralSource: patient.referralSource || null,
  };
}

export const renderMatchStrength = matches => {
  switch (matches) {
    case 3:
      return 'Moderate';

    case 4:
      return 'Strong';

    default:
      return 'Very Strong';
  }
};

export function filter(patients, search, hideInactive) {
  const terms = splitIntoTerms(search);
  return patients
    .filter(item => item.active || !hideInactive)
    .filter(patient => {
      const patientSearch = patientToSearchTerms(patient);
      return terms.every(term => patientSearch.indexOf(term) !== -1);
    });
}

export function findAndComparePatients(initalPatient, allPatients) {
  const patient = allPatients.find(p => p.id === initalPatient.id);
  return equal(initalPatient, patient);
}

export const mapPatientToPerson = patient => {
  const {
    id,
    phoneNumbers,
    emailAddresses,
    addresses,
    name: { first, last, middle, preferred },
    ...rest
  } = patient;

  const phones = phoneNumbers
    .filter(phone => phone)
    .map(({ type, number }) => ({
      type,
      number: formatPhoneNumber(number),
    }));
  const emails = emailAddresses.filter(email => email);
  const pAddress = addresses.filter(({ address1 }) => address1);

  const address = pAddress.length ? pAddress[0] : EMPTY_ADDRESS;

  return {
    ...rest,
    firstName: first,
    lastName: last,
    middleName: middle,
    preferredName: preferred,
    phones,
    emails,
    address,
    patientId: id,
  };
};

export const getPhotoModel = ({
  active,
  photoSrc: imgSrc = '',
  name,
  statuses = { patient: '' },
}) => ({
  active: active || statuses.patient === 'Active',
  imgSrc,
  initials: objToInitials(name || { first: '', last: '' }),
});

export const getHideInactivePatients = () => {
  const hideInactivePatients = JSON.parse(
    localStorage.getItem(HIDE_INACTIVE_PATIENTS_KEY),
  );

  return hideInactivePatients === null ? true : hideInactivePatients;
};

export function createEmptyPatientSummaryModel() {
  return {
    ...mapToPatientModel({}),
    alertCount: 0,
    patientBalance: 0,
    insuranceBalance: 0,
    totalBalance: 0,
    nextAppointment: null,
    futureAppointmentsCount: 0,
    pastAppointmentsCount: 0,
    activeInsuranceCount: 0,
    preferredLocationName: null,
    primaryInsurance: null,
  };
}
