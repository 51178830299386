import '../controls/neb-button-bar';
import '../controls/neb-horizontal-scroll';
import '../controls/neb-menu-button';
import '../controls/neb-tab-group';
import '../neb-action-bar';
import '../neb-header';
import '../patients/ledger/charges/neb-ledger-charges-claim-section';
import '../tables/neb-table';
import '../tables/neb-table-selected-line-items';
import '../../../../../src/components/controllers/charges/neb-charges-controller';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import * as eClaimFileApi from '../../../../../src/api-clients/eclaim-file';
import { checkIfSignedEncounterModsChange } from '../../../../../src/api-clients/encounters';
import { EditChargeTable } from '../../../../../src/components/tables/charges/neb-table-charges-edit';
import {
  CONFIRM_X12_CLAIM_DOWNLOAD,
  generateFileName,
} from '../../../../../src/utils/e-claim-files';
import { CHARGES_ORIGIN } from '../../../../../src/utils/neb-charges-util';
import {
  WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_ENCOUNTER_LEVEL,
  SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING,
  errorOccurred,
  NO_CLAIMS_PRIMARY_MESSAGE,
  NO_CLAIMS_SECONDARY_MESSAGE,
  NO_CLAIMS_SUBMISSION_METHOD_TITLE,
} from '../../../../../src/utils/user-message';
import { getClaimCounts } from '../../../../neb-api-client/src/claims';
import { getInvoicesFromEncounters } from '../../../../neb-api-client/src/encounters-api-client';
import { getDesignatedClearinghouseCount } from '../../../../neb-api-client/src/payer-plan-api-client';
import { openOverlayPatientInsuranceView } from '../../../../neb-app-layout/neb-open-overlay';
import {
  openError,
  openWarning,
} from '../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { downloadURLtoFile } from '../../../../neb-utils/blobProcessor';
import {
  SUBMISSION_METHOD,
  PAYMENT_RESPONSIBILITY_LEVEL_CODE,
  CLAIM_STATUS,
} from '../../../../neb-utils/claims';
import { BILLING_NOTE_TYPES } from '../../../../neb-utils/constants';
import { parseDate } from '../../../../neb-utils/date-util';
import { PROVIDER_TYPE } from '../../../../neb-utils/enums';
import { centsToCurrency } from '../../../../neb-utils/formatters';
import {
  ITEM_SELF,
  BILL_TYPE,
  ITEM_INSURANCE,
  EPSDT_ITEMS,
  calculateSummary,
  checkForWarnings,
  getOutOfCoverageDatePlans,
  fetchCaseItems,
  fetchPayerItems,
  fetchPackageItems,
  fetchInsuranceItems,
  fetchGuarantorItems,
  fetchPracticeUsers,
  fetchAdjustments,
  fetchTaxRateItems,
  fetchFeeSchedules,
  fetchPaymentTypeItems,
  mapCaseAuthorizations,
  mapCasesWithAuthorizationReference,
  removeAllAllocations,
  mapFilteredCaseAuthorizations,
} from '../../../../neb-utils/neb-ledger-util';
import {
  AUTHORIZATION_STATUS,
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  hasAuthorizationRemaining,
} from '../../../../neb-utils/patientAuthorization';
import { ITEM_EMPTY } from '../../../../neb-utils/selectors';
import { map } from '../../../../neb-utils/utils';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import { DESIGNATED_CLEARINGHOUSE } from '../claims/utils';
import { ELEMENTS as NEB_TEXT_ELEMENTS } from '../neb-text';
import { formatAdjustmentAmount } from '../patients/ledger/charges/neb-ledger-charges-util';
import PayerInfoLedgerTable from '../tables/neb-table-ledger-payer-info';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

export const EDIT_MODE = {
  DISABLED: 'disabled',
  HEADER: 'header',
  TABLE: 'table',
};

export const TABS = {
  CHARGES: 'charges',
  CLAIMS: 'claims',
};

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  tabGroup: { id: 'tab-group' },
  tablePayer: { id: 'payer-table' },
  tableSummary: { id: 'summary-table' },
  claimSection: { id: 'claim-section' },
  chargesController: {
    id: 'charges-controller',
  },
  invoiceText: { id: 'invoice-text' },
  form: { id: 'charges-form-management' },
};

const DIAGNOSES_COUNT_LIMIT = 12;
const CHARGES_COUNT_LIMIT = 6;

const DIAGNOSES_LIMIT_POPUP_MODEL = {
  title: 'Diagnosis Limit Exceeded',
  message: html`
    <div>
      This invoice includes more than ${DIAGNOSES_COUNT_LIMIT} diagnoses. Only
      the first ${DIAGNOSES_COUNT_LIMIT} diagnoses, ordered by date of service,
      will be included on the claim.
    </div>
    <br />
    <div>
      Do you want to continue creating this claim with the first
      ${DIAGNOSES_COUNT_LIMIT} diagnoses?
    </div>
  `,
  confirmText: 'OK',
  cancelText: 'Cancel',
};

const CHARGES_LIMIT_POPUP_MODEL = {
  title: 'Charge Limit Exceeded',
  message: html`
    <div>
      This invoice includes more than ${CHARGES_COUNT_LIMIT} charges. Only the
      first ${CHARGES_COUNT_LIMIT} charges, ordered by date of service, will be
      included on the claim.
    </div>
    <br />
    <div>
      If you want to create a claim for additional charges, you can remove them
      from this invoice and create a new invoice with those charges.
    </div>
    <br />
    <div>
      Do you want to continue creating this claim for the first
      ${CHARGES_COUNT_LIMIT} charges on the invoice?
    </div>
  `,
  confirmText: 'OK',
  cancelText: 'Cancel',
};

const DESIGNATED_CLEARINGHOUSE_POPUP_MODEL = {
  title: 'No Designated Clearinghouse',
  message: html`
    <div>
      Claims can not be made with payers that do not have a designated
      clearinghouse.
    </div>
    <br />
    <div>
      Please address your Payer Maintenance settings for this payer before
      submitting claims.
    </div>
    <br />
  `,
  confirmText: 'OK',
  cancelText: 'Cancel',
};

const CHECK_AUTHORIZATION_STATUS_POPUP_MODEL = {
  title: 'Check Authorization Status',
  message: html`
    <div>
      This invoice includes an authorization with a status of Appealed, Denied,
      Requested, or Inactive. The claim may be denied if it has not been
      approved.
    </div>
    <br />
    <div>Do you want to continue creating this claim?</div>
  `,
  confirmText: 'OK',
  cancelText: 'Cancel',
};

export const CHARGES_OUTSIDE_AUTH_DATE_RANGE_POPUP_MODEL = {
  title: 'Authorization Outside of Approved Date Range',
  message: html`
    <div>
      This invoice includes one or more charges that are outside of the approved
      date range for the authorization applied.
    </div>
    <br />
    <div>Do you want to continue creating this claim?</div>
  `,
  confirmText: 'OK',
  cancelText: 'Cancel',
};

const CHARGES_AND_DIAGNOSES_LIMIT_POPUP_MODEL = {
  title: 'Charge and Diagnosis Limit Exceeded',
  message: html`
    <div>
      This invoice includes more than ${CHARGES_COUNT_LIMIT} charges and
      ${DIAGNOSES_COUNT_LIMIT} diagnoses. Only the first ${CHARGES_COUNT_LIMIT}
      charges and ${DIAGNOSES_COUNT_LIMIT} diagnoses, ordered by date of
      service, will be included on the claim.
    </div>
    <br />
    <div>
      If you want to create a claim for additional charges, you can remove them
      from this invoice and create a new invoice with those charges.
    </div>
    <br />
    <div>
      Do you want to continue creating this claim for the first
      ${CHARGES_COUNT_LIMIT} charges and ${DIAGNOSES_COUNT_LIMIT} diagnoses?
    </div>
  `,
  confirmText: 'OK',
  cancelText: 'Cancel',
};

export const REMOVE_ALLOCATION_CONFIRMATION_POPUP = {
  title: 'Remove Allocation',
  message: html`
    <div>
      Changing the Bill Type, Payer, or Plan will unallocate all payments for
      these charges.
    </div>
    <br />
    <div>Are you sure that you want to continue?</div>
  `,
  confirmText: 'Yes',
  cancelText: 'No',
};

export class LedgerChargesForm extends NebForm {
  static get properties() {
    return {
      __hasProvider: Boolean,
      __selectedTab: String,
      __payerSearch: String,
      __initialState: Object,
      __menuItemsMap: Object,
      __summaryItems: Object,
      __authorizations: Array,

      closeOnSave: Boolean,
      editMode: String,
      displayItems: Array,
      detailItems: Array,
      lineItemIds: Array,
      claims: Object,
      patient: Object,
      invoiceHasNotes: Boolean,
      carePackageWithInsuranceEnabled: Boolean,
      multiCarePackageEnabled: Boolean,
      hasRCMChangeSecondary: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: flex;
        }

        .content {
          position: relative;
        }

        .layout {
          grid-template-rows: repeat(3, min-content) auto;
          padding-bottom: 0;
        }

        .button-bar {
          z-index: 1;
          padding-left: ${CSS_SPACING};
        }

        .summary-table {
          z-index: 1;
        }

        .overflow {
          overflow: visible hidden;
        }
      `,
    ];
  }

  static createModel() {
    return {
      payerInfo: PayerInfoLedgerTable.createModel(),
      items: EditChargeTable.createModel(),
    };
  }

  static createModelItemsMap() {
    return {
      ...PayerInfoLedgerTable.createModelItemsMap(),
      ...EditChargeTable.createModelItemsMap(),
    };
  }

  initState() {
    super.initState();

    this.__hasProvider = false;
    this.__navItems = [];
    this.__yLayoutPosition = 0;
    this.__selectedTab = TABS.CHARGES;
    this.__payerSearch = '';
    this.__menuItemsMap = LedgerChargesForm.createModelItemsMap();
    this.__initialState = LedgerChargesForm.createModel();
    this.__authorizations = [];

    this.__summaryItems = {
      totalCharges: 0,
      totalAdjustments: 0,
      totalPayments: 0,
      totalPatientBalance: 0,
      totalPayerBalance: 0,
      datesOfService: '',
      invoice: 0,
      claimStatus: '',
    };

    this.lineItemIds = [];
    this.modelChargesController = {
      selectedIds: [],
      lineItemIds: this.lineItemIds,
    };

    this.editMode = EDIT_MODE.DISABLED;
    this.displayItems = [];
    this.detailItems = [];
    this.patient = {};
    this.claims = {};
    this.invoiceHasNotes = false;
    this.carePackageWithInsuranceEnabled = false;
    this.multiCarePackageEnabled = false;
    this.hasRCMChangeSecondary = false;

    this.onEditPayerInfo = () => {};

    this.onToggleItem = () => {};

    this.onChangeClaims = () => {};

    this.onUpdate = () => {};

    this.onDismiss = () => {};

    this.onRefreshPayerInfo = () => {};

    this.onChangeEditMode = () => {};

    this.onChangeBillingNotes = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      dismiss: () => this.onDismiss(),
      refreshSummary: (updatedDisplayItems, refreshPayerInfo = false) => {
        this.__summaryItems = calculateSummary({
          items: updatedDisplayItems || this.displayItems,
          claims: this.claims.data || [],
        });

        if (updatedDisplayItems && updatedDisplayItems.length) {
          this.onUpdate(updatedDisplayItems.map(item => item.id));
        }

        if (refreshPayerInfo) {
          this.onRefreshPayerInfo();
        }
      },
      scroll: e => {
        const controllerElement = this.shadowRoot.getElementById(
          ELEMENTS.chargesController.id,
        );

        if (controllerElement) {
          const form = controllerElement.shadowRoot.getElementById(
            ELEMENTS.form.id,
          );

          if (form) {
            form.setYOffset(e.currentTarget.scrollTop);
          }
        }
      },
      selectTab: tab => {
        this.__selectedTab = tab;
      },
      toggleItem: e => this.onToggleItem(e),
      editPayerInfo: () => this.onEditPayerInfo(),
      searchPayers: search => {
        this.__payerSearch = search;
      },
      changePayerInfo: e => {
        const { event } = e;
        const segments = e.name.split('.');
        const key = segments[segments.length - 1];

        const root = segments.slice(0, segments.length - 1).join('.');

        const billTypePath = `${root}.billType`;
        const payerPath = `${root}.payerId`;
        const primInsPath = `${root}.insuranceId`;
        const secInsPath = `${root}.secondaryInsuranceId`;
        const packagePath = `${root}.packageId`;
        const authPath = `${root}.patientAuthorizationId`;

        const info = this.state.payerInfo[0];

        if (key === 'patientAuthorizationId' || key === 'caseId') {
          if (event !== 'select') return;
          let selectedCase;
          let newAuthorization;

          const previousAuthorization = this.__getAuthorizationValue();
          this.formService.apply(e.name, e.value);

          if (key === 'patientAuthorizationId' && e.value?.data?.id) {
            selectedCase = this.__setCase({ authId: e.value.data.id }).data;

            newAuthorization = e.value;
          } else if (key === 'caseId') {
            if (e.value?.data?.id) {
              selectedCase = e.value.data;

              if (
                e.value.data.id !==
                this.state.payerInfo[0].patientAuthorizationId?.patientCaseId
              ) {
                this.formService.apply(
                  authPath,
                  this.__menuItemsMap.authorizations.find(
                    auth => auth.data.patientCaseId === e.value.data.id,
                  ) || ITEM_EMPTY,
                );
              }
            } else {
              this.formService.apply(authPath, ITEM_EMPTY);
            }
          }

          if (newAuthorization && previousAuthorization !== newAuthorization) {
            if (
              previousAuthorization.data.patientCaseId !==
              newAuthorization.data.patientCaseId
            ) {
              this.__setCase({ caseId: newAuthorization.data.patientCaseId });
            }

            if (!hasAuthorizationRemaining(newAuthorization.data)) {
              this.__showAuthorizationWarning();
            }
          }

          if (selectedCase) {
            const hasPayment = info.allocations.length;

            const { payerInsurance } = selectedCase;

            if (!payerInsurance || hasPayment) return;

            const {
              primaryPayerId,
              primaryInsuranceId,
              secondaryInsuranceId,
            } = payerInsurance;

            if (primaryPayerId) {
              this.formService.apply(billTypePath, ITEM_INSURANCE);
              this.formService.apply(packagePath, ITEM_EMPTY);
              this.formService.validateKey(packagePath.split('.'));

              this.formService.apply(
                payerPath,
                this.__menuItemsMap.payers.find(
                  p => p.data.id === primaryPayerId,
                ) || ITEM_EMPTY,
              );

              if (primaryInsuranceId) {
                this.formService.apply(
                  primInsPath,
                  this.__menuItemsMap.insurances.find(
                    p => p.data.id === primaryInsuranceId,
                  ) || ITEM_EMPTY,
                );
              } else {
                this.formService.apply(primInsPath, ITEM_EMPTY);
              }

              if (secondaryInsuranceId) {
                this.formService.apply(
                  secInsPath,
                  this.__menuItemsMap.insurances.find(
                    p => p.data.id === secondaryInsuranceId,
                  ) || ITEM_EMPTY,
                );
              } else {
                this.formService.apply(secInsPath, ITEM_EMPTY);
              }
            }
          }

          return;
        }

        switch (key) {
          case 'billType':
            const notInsurance = e.value.data.id !== BILL_TYPE.INSURANCE;
            const payerItem = notInsurance ? ITEM_SELF : '';

            if (e.value.data.id !== info.billType.data.id) {
              this.formService.apply(payerPath, payerItem);
              this.formService.apply(primInsPath, ITEM_EMPTY);
              this.formService.apply(secInsPath, ITEM_EMPTY);
              this.formService.apply(packagePath, ITEM_EMPTY);
              this.formService.validateKey(packagePath.split('.'));
            }
            break;

          case 'payerId':
            if (e.value === '') {
              this.formService.apply(primInsPath, ITEM_EMPTY);
              this.formService.apply(secInsPath, ITEM_EMPTY);
            }
            break;

          case 'insuranceId':
            if (e.value === ITEM_EMPTY) {
              this.formService.apply(secInsPath, ITEM_EMPTY);
            }

            if (info.payerId === '' && e.value !== ITEM_EMPTY) {
              const planId = e.value.data.payerPlan.id;
              const payerItems = this.__menuItemsMap.payers;
              const result = payerItems.find(payer => payer.data.id === planId);

              this.formService.apply(payerPath, result);
            }
            break;

          default:
        }

        this.formService.apply(e.name, e.value);
      },
      editPlan: async plan => {
        await openOverlayPatientInsuranceView({
          patientId: this.patient.id,
          patientInsurance: { id: plan.id },
        });

        this.reload();
      },
      editPayer: async payer => {
        const result = await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: payer.id,
        });

        if (result) {
          this.reload();

          if (
            payer.submissionMethodPrimary !== result.submissionMethodPrimary
          ) {
            this.onChangeClaims();
          }
        }
      },
      editCase: async patientCase => {
        let authChanged = false;

        const result = await openOverlay(OVERLAY_KEYS.CASE, {
          item: patientCase,
          context: {
            patientId: this.patient.id,
            onAuthorizationChange: () => {
              authChanged = true;
            },
          },
        });

        if (result || authChanged) {
          authChanged = false;
          this.reload();
          this.onRefreshPayerInfo();
        }
      },
      addedCase: () => this.reload(),
      editAuthorization: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          id: this.state.payerInfo[0].patientAuthorizationId.data.id,
          patientId: this.patient.id,
          patientCaseId: this.state.payerInfo[0].patientAuthorizationId.data
            .patientCaseId,
        });

        if (result) {
          await this.reload();
          this.__setAuthorization();
        }
      },
      editPackage: async patientPackage => {
        await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_EDIT, {
          item: {
            id: patientPackage.id,
            patientId: this.patient.id,
          },
        });

        this.reload();
      },
      changeEditMode: editMode => {
        this.onChangeEditMode(editMode);
      },
      openBillingNotesOverlay: async ({ id }) => {
        if (id === NEB_TEXT_ELEMENTS.trailingIcon.id) {
          const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
            parentType: BILLING_NOTE_TYPES.INVOICE,
            parentId: this.displayItems[0].invoiceId,
            parentData: {
              datesOfService: this.__summaryItems.datesOfService,
              invoice: this.__summaryItems.invoice,
            },
            patientId: this.patient.id,
          });

          if (result) this.onChangeBillingNotes(result);
        }
      },
      openPackageSummaryOverlay: () => {
        openOverlay(OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY, {
          patientId: this.patient.id,
          lineItems: this.displayItems.map(item => ({ id: item.id })),
        });
      },

      addNewClaim: async data => {
        const isPrimary = data.index === 0;

        const { diagnosesCount, chargesCount } = await getClaimCounts({
          invoiceId: this.displayItems[0].invoiceId,
          patientInsuranceId: data.value.id,
        });

        const {
          data: { payerPlan },
        } = this.__menuItemsMap.insurances.find(
          ({ data: insurance }) => insurance.id === data.value.id,
        );

        const {
          designatedClearinghouseCount,
        } = await getDesignatedClearinghouseCount(payerPlan.id);

        const hasDesignatedClearinghouseMismatch =
          designatedClearinghouseCount !==
          DESIGNATED_CLEARINGHOUSE.CORRECT_COUNT;

        const isPrimaryElectronic =
          isPrimary &&
          payerPlan.submissionMethodPrimary ===
            SUBMISSION_METHOD.ELECTRONIC_CLAIMS;

        const isSecondaryElectronic =
          !isPrimary &&
          payerPlan.submissionMethodSecondary ===
            SUBMISSION_METHOD.ELECTRONIC_CLAIMS;

        if (
          isPrimary &&
          payerPlan.submissionMethodPrimary === SUBMISSION_METHOD.NO_CLAIMS
        ) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: NO_CLAIMS_SUBMISSION_METHOD_TITLE(),
            message: NO_CLAIMS_PRIMARY_MESSAGE,
          });
        }

        if (
          !isPrimary &&
          payerPlan.submissionMethodSecondary === SUBMISSION_METHOD.NO_CLAIMS
        ) {
          return openPopup(POPUP_RENDER_KEYS.MESSAGE, {
            title: NO_CLAIMS_SUBMISSION_METHOD_TITLE(),
            message: NO_CLAIMS_SECONDARY_MESSAGE,
          });
        }

        let result = true;

        if (
          diagnosesCount > DIAGNOSES_COUNT_LIMIT &&
          chargesCount > CHARGES_COUNT_LIMIT
        ) {
          result = await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            CHARGES_AND_DIAGNOSES_LIMIT_POPUP_MODEL,
          );
        } else if (diagnosesCount > DIAGNOSES_COUNT_LIMIT) {
          result = await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            DIAGNOSES_LIMIT_POPUP_MODEL,
          );
        } else if (chargesCount > CHARGES_COUNT_LIMIT) {
          result = await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            CHARGES_LIMIT_POPUP_MODEL,
          );
        }

        if (
          hasDesignatedClearinghouseMismatch &&
          (isPrimaryElectronic || isSecondaryElectronic)
        ) {
          await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            DESIGNATED_CLEARINGHOUSE_POPUP_MODEL,
          );

          result = false;
        }

        if (!result) return undefined;

        result = true;

        const selectedAuthorization = this.__getAuthorizationValue();

        const authorizationStatus = selectedAuthorization
          ? selectedAuthorization.data.status
          : '';

        const invalidStatus = ['Appealed', 'Denied', 'Requested', 'Inactive'];

        if (invalidStatus.includes(authorizationStatus)) {
          result = await openPopup(
            POPUP_RENDER_KEYS.CONFIRM,
            CHECK_AUTHORIZATION_STATUS_POPUP_MODEL,
          );
        }

        if (!result) return undefined;

        const validDateRangeStatus = [
          AUTHORIZATION_STATUS.Approved,
          AUTHORIZATION_STATUS.Completed,
        ];

        if (
          selectedAuthorization &&
          validDateRangeStatus.includes(authorizationStatus)
        ) {
          result = true;

          const authFrom = moment(selectedAuthorization.data.dateApprovedFrom);
          const authTo = moment(selectedAuthorization.data.dateApprovedTo);

          const chargesWithinRange = this.displayItems.every(item => {
            const chargeDate = parseDate(item.dateOfService);

            return (
              authFrom.isSameOrBefore(chargeDate) &&
              authTo.isSameOrAfter(chargeDate)
            );
          });

          if (!chargesWithinRange) {
            result = await openPopup(
              POPUP_RENDER_KEYS.CONFIRM,
              CHARGES_OUTSIDE_AUTH_DATE_RANGE_POPUP_MODEL,
            );
          }

          if (!result) return undefined;
        }

        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, {
          patientId: this.patient.id,
          invoiceId: this.displayItems[0].invoiceId,
          patientInsuranceId: data.value.id,
          type: isPrimary
            ? PAYMENT_RESPONSIBILITY_LEVEL_CODE.PRIMARY
            : PAYMENT_RESPONSIBILITY_LEVEL_CODE.SECONDARY,
        });

        return this.onChangeClaims(true);
      },

      viewClaim: async (key, index) => {
        const downloadableX12 =
          this.claims.data[index].eClaimFile &&
          this.claims.data[index].eClaimFile.id;

        if (downloadableX12 && key === 'claimNumber') {
          this.__downloadClaimX12(this.claims.data[index]);
        } else {
          await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, {
            claimId: this.claims.data[index].id,
          });

          await this.onChangeClaims();
        }
      },

      save: async () => {
        let result = '';
        let updateSignedEncounters = false;

        const { billType } = this.state.payerInfo[0];

        if (
          this.multiCarePackageEnabled &&
          billType.data.id === BILL_TYPE.PACKAGE
        ) {
          this.formService.apply('payerInfo.0.packageId', {
            data: { id: 'multi' },
            label: '',
          });
        }

        const valid = this.formService.validate();
        const onlyWarnings = await checkForWarnings(this.errors);

        if (
          this.displayItems.length > 0 &&
          this.displayItems[0].invoiceNumber
        ) {
          const initialCase = this.formService.__initialState.payerInfo[0]
            .caseId;

          if (this.state.payerInfo[0].caseId.data.id !== initialCase.data.id) {
            const { encounterId } = this.displayItems[0];

            const associatedInvoices = encounterId
              ? await getInvoicesFromEncounters([encounterId])
              : [];

            const associatedInvoiceNumbers = associatedInvoices.map(
              ({ invoiceNumber }) => invoiceNumber,
            );

            const hasMultipleInvoices = associatedInvoiceNumbers.length > 1;

            result = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
              title: 'Warning',
              message: hasMultipleInvoices
                ? WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_ENCOUNTER_LEVEL(
                    associatedInvoiceNumbers,
                  )
                : SINGLE_INVOICE_ASSOCIATED_TO_ENCOUNTER_WARNING(
                    this.displayItems[0].invoiceNumber,
                  ),
              buttons: [
                { name: 'save', label: 'YES' },
                { name: 'discard', label: 'NO' },
              ],
            });

            if (result !== 'save') {
              this.formService.apply('payerInfo.0.caseId', initialCase);
              return;
            }
          }
        }

        if (valid || onlyWarnings) {
          const outOfCoveragePlans = getOutOfCoverageDatePlans(
            this.state.payerInfo[0].datesOfService,
            this.state.payerInfo[0].insuranceId
              ? this.state.payerInfo[0].insuranceId.data
              : null,
            this.state.payerInfo[0].secondaryInsuranceId &&
              this.state.payerInfo[0].secondaryInsuranceId.data.id
              ? this.state.payerInfo[0].secondaryInsuranceId.data
              : null,
          );

          if (outOfCoveragePlans.length) {
            const saveOutOfCoverage = await openPopup(
              POPUP_RENDER_KEYS.LEDGER_INSURANCE_OUT_OF_COVERAGE,
              {
                title: 'Insurance Coverage Dates',
                message: html`
                  The start or term dates for insurance
                  ${outOfCoveragePlans.map(plan => plan.label).join(' and ')}
                  indicates that one or more dates of service are outside of the
                  covered date range. <br />
                  <br />
                  Are you sure you want to use this insurance?
                `,
                confirmText: 'Yes',
                cancelText: 'No',
              },
            );

            if (!saveOutOfCoverage) return;
          }

          const hasPayment = this.state.payerInfo[0].allocations.length;
          const shouldCancel =
            hasPayment && this.__modifiedHeader()
              ? await this.__handleExistingAllocations()
              : false;

          if (shouldCancel) {
            return;
          }

          const rawModel = this.formService.build();
          const model = map(rawModel, (keyPath, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          if (model.payerInfo[0].payerId !== this.model.payerInfo[0].payerId) {
            const {
              signedEncounterModsChange,
            } = await checkIfSignedEncounterModsChange({
              ...(model.payerInfo[0].payerId
                ? { primaryPayerId: model.payerInfo[0].payerId }
                : {}),
              patientId: this.patient.id,
              lineItemIds: this.lineItemIds,
            });

            if (signedEncounterModsChange) {
              updateSignedEncounters = await openPopup(
                POPUP_RENDER_KEYS.CONFIRM,
                {
                  title: 'Signed Encounter Modifiers',
                  message:
                    'Editing the payer on this invoice will update modifiers on a signed encounter. Do you wish to proceed?',
                  confirmText: 'Yes',
                  cancelText: 'No',
                },
              );

              if (!updateSignedEncounters) return;
            }
          }

          this.__saving = true;

          const previousCaseId = this.model.payerInfo[0].caseId;
          const currentCaseId = this.state.payerInfo[0].caseId.data.id;

          let modelWithSecondaryPayer;

          if (this.hasRCMChangeSecondary) {
            const payerInfo = model.payerInfo[0];
            const { secondaryInsuranceId } = payerInfo;

            const insuranceList = this.__menuItemsMap.insurances;
            const secondaryInsurance = insuranceList.find(
              ins => ins.data.id === secondaryInsuranceId,
            );

            modelWithSecondaryPayer = {
              ...model,
              payerInfo: [
                {
                  ...payerInfo,
                  secondaryPayerPlanId: secondaryInsurance
                    ? secondaryInsurance.data.payerPlanId
                    : null,
                },
              ],
            };
          }

          await this.onSave(
            this.hasRCMChangeSecondary ? modelWithSecondaryPayer : model,
            updateSignedEncounters,
            previousCaseId,
            currentCaseId,
          );

          this.__saving = false;
        }
      },
    };
  }

  async load() {
    const [{ payerId, caseId, guarantorId } = {}] = this.model.payerInfo;

    const selectedIds = this.getSelectedIds();

    const [
      payers,
      cases,
      packages,
      insurances,
      guarantors,
      users,
      adjustments,
      taxRates,
      paymentTypes,
      feeSchedules,
    ] = await Promise.all([
      fetchPayerItems(payerId),
      fetchCaseItems({
        patientId: this.patient.id,
        filterActive: false,
        caseId,
      }),
      fetchPackageItems(this.patient.id, { includeShared: true }, true),
      fetchInsuranceItems(this.patient.id),
      fetchGuarantorItems(this.patient.id, guarantorId),
      fetchPracticeUsers(),
      fetchAdjustments(true),
      selectedIds.length ? fetchTaxRateItems(true) : [],
      fetchPaymentTypeItems(),
      fetchFeeSchedules(true),
    ]);
    this.__authorizations = mapCaseAuthorizations(cases);

    this.__menuItemsMap = {
      authorizations: mapFilteredCaseAuthorizations(cases),
      payers,
      cases: mapCasesWithAuthorizationReference(cases),
      packages,
      insurances,
      guarantors,
      users,
      adjustments,
      dx: this.getEncountersDx(),
      taxRates,
      epsdt: EPSDT_ITEMS,
      paymentTypes,
      feeSchedules,
    };
  }

  createSelectors() {
    return {
      children: {
        payerInfo: PayerInfoLedgerTable.createSelectors(this.__menuItemsMap),
        items: EditChargeTable.createSelectors(this.__menuItemsMap),
      },
    };
  }

  async __handleExistingAllocations() {
    const accepted = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      REMOVE_ALLOCATION_CONFIRMATION_POPUP,
    );

    if (accepted) {
      await this.__removeAllAllocations();
      return false;
    }
    return true;
  }

  async __openClaimX12DownloadPopup() {
    const result = await openPopup(
      POPUP_RENDER_KEYS.CONFIRM,
      CONFIRM_X12_CLAIM_DOWNLOAD,
    );

    return result;
  }

  async __downloadClaimX12(item) {
    let allowDownload = true;

    const claimStatus = item.claimStatuses[0].status;

    if (claimStatus === CLAIM_STATUS.UPLOADING_TO_CLEARINGHOUSE) {
      allowDownload = await this.__openClaimX12DownloadPopup();
    }
    const { id } = store.getState().session.item;

    if (allowDownload) {
      try {
        const url = await eClaimFileApi.downloadFile(item.eClaimFile.id, {
          updatedBy: id,
        });

        downloadURLtoFile(
          url,
          generateFileName({
            clearinghousePartner: item.clearinghouse.partner,
            claimNumber: item.claimNumber,
            batchId: null,
            practiceIdentifierId: item.clearinghouse.practiceIdentifierId,
            customExtension: item.clearinghouse.customExtension,
            customFilenameTemplate: item.clearinghouse.customFilenameTemplate,
            createdAt: item.eClaimFile.createdAt,
          }),
        );

        this.onChangeClaims();
      } catch (e) {
        store.dispatch(openError(errorOccurred('downloading', 'X12 file')));
      }
    }
  }

  __setAuthorization() {
    const newAuthorization = this.__authorizations.find(
      auth => auth.data.id === this.model.payerInfo[0].patientAuthorizationId,
    );

    if (newAuthorization) {
      const currentDirty = this.__dirty;

      this.formService.apply(
        'payerInfo.0.patientAuthorizationId',
        newAuthorization,
      );

      this.__dirty = currentDirty;
    }
  }

  __showAuthorizationWarning() {
    store.dispatch(
      openWarning(
        NO_REMAINING_AUTHORIZATIONS_MESSAGE,
        this.handlers.editAuthorization,
      ),
    );
  }

  __removeAllAllocations() {
    const info = this.state.payerInfo[0];
    return removeAllAllocations(info.allocations, this.detailItems);
  }

  __modifiedHeader() {
    const billTypeChanged =
      this.model.payerInfo[0].billType !==
      this.state.payerInfo[0].billType.data.id;

    const payerChanged =
      this.model.payerInfo[0].payerId !==
      this.state.payerInfo[0].payerId.data.id;

    const planChanged =
      this.model.payerInfo[0].insuranceId !==
      this.state.payerInfo[0].insuranceId.data.id;

    const hasChanged = billTypeChanged || payerChanged || planChanged;

    return hasChanged;
  }

  __getInvoicesNotesIcon() {
    return this.invoiceHasNotes ? 'neb:editNote' : 'neb:addNote';
  }

  __buildSummaryTableConfig() {
    return [
      {
        key: 'datesOfService',
        label: 'Dates of Service',
        flex: this.__summaryItems.datesOfService.includes('-')
          ? css`1 0 70px`
          : css`1 0 30px`,
      },
      {
        key: 'invoice',
        label: 'Invoice',
        flex: css`1 0 0`,
        formatter: v =>
          v !== null
            ? html`
                <neb-text
                  id="${ELEMENTS.invoiceText.id}"
                  trailingIcon="${this.__getInvoicesNotesIcon()}"
                  .onClick="${this.handlers.openBillingNotesOverlay}"
                  >${v}</neb-text
                >
              `
            : html`
                <neb-text id="${ELEMENTS.invoiceText.id}" bold italic
                  >Not Invoiced</neb-text
                >
              `,
      },
      {
        key: 'claimStatus',
        label: 'Claim Status',
        flex: css`1 0 0`,
      },
      {
        key: 'totalCharges',
        label: 'Total Charges',
        flex: css`1 0 0`,
        formatter: v => centsToCurrency(v),
      },
      {
        key: 'totalAdjustments',
        label: 'Total Adjustments',
        flex: css`1 0 10px`,
        formatter: v => formatAdjustmentAmount(v),
      },
      {
        key: 'totalPayments',
        label: 'Total Payments',
        flex: css`1 0 0`,
        formatter: v => centsToCurrency(v),
      },
      {
        key: 'totalPayerBalance',
        label: 'Total Payer Balance',
        flex: css`1 0 30px`,
        formatter: v => centsToCurrency(v),
      },
      {
        key: 'totalPatientBalance',
        label: 'Total Patient Balance',
        flex: css`1 0 30px`,
        formatter: v => centsToCurrency(v),
      },
    ];
  }

  __hasEncounterCharges() {
    return (
      this.displayItems.some(
        ({ encounterId, suppressFromClaim }) =>
          encounterId && !suppressFromClaim,
      ) && !this.displayItems.every(e => e.holdClaim)
    );
  }

  __hasSecondaryChargesAndHoldClaim() {
    return this.displayItems
      .filter(e => e.secondaryOwed)
      .every(e => e.holdClaim);
  }

  getSelectedIds() {
    return this.displayItems.filter(item => item.checked).map(item => item.id);
  }

  getEncountersDx() {
    const encounterDiagnoses = this.model.items.reduce(
      (acc, { encounterCharge: { encounterId, diagnoses } = {} }) =>
        encounterId ? { ...acc, [encounterId]: diagnoses } : acc,
      {},
    );

    return Object.entries(encounterDiagnoses).reduce(
      (acc, [encounterId, diagnoses]) => {
        diagnoses.forEach(dx => acc.push({ code: dx.code, encounterId }));
        return acc;
      },
      [],
    );
  }

  update(changed) {
    if (changed.has('model')) {
      if (this.model.items.length) {
        this.reload();
      }

      this.modelChargesController = {
        selectedIds: this.getSelectedIds(),
        lineItemIds: this.lineItemIds,
      };
    }

    super.update(changed);
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (
      changedProps.has('model') ||
      (changedProps.has('__selectedTab') && this.__selectedTab === TABS.CHARGES)
    ) {
      this.__setAuthorization();

      this.__initialState = this.formService.convert(this.model, 'format');
    }

    if (
      changedProps.has('claims') &&
      this.claims.data &&
      this.displayItems.length
    ) {
      this.handlers.refreshSummary();
    }

    if (
      changedProps.has('displayItems') &&
      this.displayItems.length &&
      this.__menuItemsMap.users.length
    ) {
      this.__hasProvider = this.displayItems.some(charge => {
        const { providerId } = charge;
        const provider = this.__menuItemsMap.users.find(
          user => user.data.id === providerId,
        );
        return provider && provider.data.type === PROVIDER_TYPE.PROVIDER;
      });
    }
  }

  __getAuthorizationValue() {
    if (this.state.payerInfo[0].patientAuthorizationId?.data?.id) {
      return this.__authorizations.find(
        a =>
          a.data.id === this.state.payerInfo[0].patientAuthorizationId.data.id,
      );
    }

    if (!this.state.payerInfo[0].caseId?.data?.id) return ITEM_EMPTY;

    const {
      patientAuthorizations: [patientAuthorization],
    } = this.state.payerInfo[0].caseId.data;

    const authorizationId = patientAuthorization ? patientAuthorization.id : '';

    return (
      this.__authorizations.find(a => a.data.id === authorizationId) ||
      ITEM_EMPTY
    );
  }

  __setCase({ caseId, authId }) {
    if (authId) {
      caseId = this.__authorizations.find(auth => auth.data.id === authId)?.data
        ?.patientCaseId;
    }
    const associatedCase =
      this.__menuItemsMap.cases.find(
        c => c.data.id === caseId || c.data.id === authId,
      ) || ITEM_EMPTY;

    this.formService.apply('payerInfo.0.caseId', associatedCase);
    return associatedCase;
  }

  __genNavItems() {
    return [
      {
        id: TABS.CHARGES,
        label: 'Charges',
        disabled:
          this.__selectedTab === TABS.CLAIMS &&
          this.editMode !== EDIT_MODE.DISABLED,
        resolver: () => this.__renderChargesTab(),
      },
      {
        id: TABS.CLAIMS,
        label: `Claims${this.claims.count ? ` (${this.claims.count})` : ''}`,
        disabled:
          this.__selectedTab === TABS.CHARGES &&
          this.editMode !== EDIT_MODE.DISABLED,
        resolver: () => this.__renderClaimsTab(),
      },
    ];
  }

  __renderTabs() {
    this.__navItems = this.__genNavItems();

    return html`
      <neb-tab-group
        id="${ELEMENTS.tabGroup.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(({ id }) => id === this.__selectedTab);

    return item.resolver();
  }

  __renderChargesTab() {
    return html`
      <neb-table
        id="${ELEMENTS.tableSummary.id}"
        class="summary-table"
        .layout="${this.layout}"
        .config="${this.__buildSummaryTableConfig()}"
        .model="${[this.__summaryItems]}"
        preview
      ></neb-table>

      <neb-charges-controller
        id="${ELEMENTS.chargesController.id}"
        class="overflow"
        .model="${this.modelChargesController}"
        .lineItemIds="${this.lineItemIds}"
        .patient="${this.patient}"
        .layout="${this.layout}"
        .origin="${CHARGES_ORIGIN.LEDGER}"
        .onScroll="${this.handlers.scroll}"
        .onDismiss="${this.handlers.dismiss}"
        .onRefreshSummary="${this.handlers.refreshSummary}"
        .closeOnSave="${this.closeOnSave}"
        .editMode="${this.editMode}"
        .onChangeEditMode="${this.handlers.changeEditMode}"
      ></neb-charges-controller>
    `;
  }

  __renderClaimsTab() {
    return html`
      <neb-ledger-charges-claim-section
        id="${ELEMENTS.claimSection.id}"
        .insurances="${this.__menuItemsMap.insurances}"
        .model="${this.claims.data}"
        .onAddClaim="${this.handlers.addNewClaim}"
        .onSelectClaim="${this.handlers.viewClaim}"
        .payerInfo="${this.model.payerInfo[0]}"
        .hasProvider="${this.__hasProvider}"
        ?hasEncounterCharges="${this.__hasEncounterCharges()}"
        ?hasSecondaryHoldClaim="${this.__hasSecondaryChargesAndHoldClaim()}"
        ?isEditing="${this.editMode !== EDIT_MODE.DISABLED}"
      ></neb-ledger-charges-claim-section>
    `;
  }

  renderActionBar() {
    return this.editMode === EDIT_MODE.HEADER
      ? html`
          <neb-action-bar
            id="${ELEMENTS_BASE.actionBar.id}"
            .confirmLabel="${this.confirmLabel}"
            .cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.save}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      <neb-table-ledger-payer-info
        id="${ELEMENTS.tablePayer.id}"
        name="payerInfo"
        .layout="${this.layout}"
        .searchText="${this.__payerSearch}"
        .itemsMap="${this.__menuItemsMap}"
        .patientId="${this.patient.id}"
        .model="${this.state.payerInfo}"
        .errors="${this.errors.payerInfo}"
        .disableEdit="${this.editMode !== EDIT_MODE.DISABLED}"
        .allAuthorizations="${this.__authorizations}"
        .onChange="${this.handlers.changePayerInfo}"
        .onSearch="${this.handlers.searchPayers}"
        .onToggleEdit="${this.handlers.editPayerInfo}"
        .onSelectPlan="${this.handlers.editPlan}"
        .onSelectPayer="${this.handlers.editPayer}"
        .onSelectCase="${this.handlers.editCase}"
        .onAddedCase="${this.handlers.addedCase}"
        .onSelectPackage="${this.handlers.editPackage}"
        .onOpenPackageSummary="${this.handlers.openPackageSummaryOverlay}"
        .onSelectAuthorization="${this.handlers.editAuthorization}"
        ?writable="${this.editMode === EDIT_MODE.HEADER}"
        .editMode="${this.editMode}"
        .carePackageWithInsuranceEnabled="${
          this.carePackageWithInsuranceEnabled
        }"
        .multiCarePackageEnabled="${this.multiCarePackageEnabled}"
      ></neb-table-ledger-payer-info>

      ${this.__renderTabs()} ${this.__renderTabContent()}
    `;
  }
}

customElements.define('neb-form-ledger-charges', LedgerChargesForm);
