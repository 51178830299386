import ApiClient, {
  Method,
  buildQueryString,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const BASE_URL = 'era';

export const eclaimApiClient = new ApiClient({
  microservice: 'electronic-claims',
  useTenant: true,
});

export const billingApiClient = new ApiClient({
  microservice: 'billing',
  useTenant: true,
});

export const getEraData = async rest => {
  const result = await eclaimApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_URL}${buildQueryString(rest)}`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return result;
};

export const updateEraStatus = (eraId, status) =>
  eclaimApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_URL}/${eraId}/${status}/`,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

export const bulkUpdateStatus = data =>
  eclaimApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_URL,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.PUT,
    body: JSON.stringify(data),
    version: 1,
  });

export const reprocessEra = (reportId, optOutLoadingIndicator = false) =>
  eclaimApiClient.makeRequest({
    path: `era/reprocess/${reportId}`,
    method: Method.POST,
    headers: { 'Content-Type': 'application/json' },
    version: 1,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });

export const associatePayments = async (
  eraId,
  reportId,
  paymentIds,
  optOutLoadingIndicator = false,
) => {
  await billingApiClient.makeRequest({
    path: `era/${eraId}/payments/`,
    method: Method.POST,
    body: JSON.stringify({ reportId, paymentIds }),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const createSecondaryClaims = async ({
  eraId,
  lineItemIds,
  optOutLoadingIndicator = false,
  version = 1,
}) => {
  const result = await billingApiClient.makeRequest({
    path: `era/${eraId}/secondary-claims/`,
    method: Method.POST,
    body: JSON.stringify({ lineItemIds }),

    headers: {
      'Content-Type': 'application/json',
    },
    version,
    optOutLoadingIndicator,
  });

  return result;
};

export const associateCharges = ({
  version = 1,
  eraId,
  reportId,
  lineItemIds,
}) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_URL}/${eraId}/charges/`,
    responseType: RESPONSE_TYPE.RAW,
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body: JSON.stringify({ reportId, lineItemIds }),
    version,
  });

export const unlinkPayment = async (
  eraId,
  paymentId,
  reportId,
  optOutLoadingIndicator = false,
) => {
  await billingApiClient.makeRequest({
    path: `era/${eraId}/payments/`,
    method: Method.DELETE,
    body: JSON.stringify({ paymentId, reportId }),

    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const removeEraCharges = async ({
  version = 1,
  eraId,
  lineItemIds,
  optOutLoadingIndicator = false,
  reportId,
  claimStatus,
}) => {
  const rawObject = {
    lineItemIds,
    reportId,
    ...(claimStatus ? { claimStatus } : {}),
  };
  await billingApiClient.makeRequest({
    path: `era/${eraId}/charges/`,
    method: Method.DELETE,
    body: JSON.stringify(rawObject),
    headers: { 'Content-Type': 'application/json' },
    version,
    optOutLoadingIndicator,
    responseType: RESPONSE_TYPE.RAW,
  });
};

export const getEraSecondaryClaimDraftsToBeCreated = async (
  eraId,
  lineItemIds,
  optOutLoadingIndicator = false,
  version = 1,
) => {
  const result = await billingApiClient.makeRequest({
    path: `era/${eraId}/secondary-claims-to-create-total/`,
    method: Method.GET,
    queryParams: { lineItemIds },
    headers: {
      'Content-Type': 'application/json',
    },
    version,
    optOutLoadingIndicator,
  });

  return result;
};
