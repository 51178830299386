import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';
import { associateCharges } from '../../era';

const toDbNumber = number => Number(number.replace('.', ''));

export const mapLineItemToEClaimInfo = ({
  paymentInfo: { paid, billed, allowed },
  procedureCode,
  serviceDate,
  lineItemReportId,
  adjustments,
  patientAdjustments,
  units,
  modifiers,
}) => ({
  paid: toDbNumber(paid),
  units,
  billed: toDbNumber(billed),
  allowed: toDbNumber(allowed),
  modifiers,
  serviceDate,
  procedureCode: procedureCode.split(' - ')[0],
  lineItemReportId,
  adjustments: adjustments.map(adj => ({
    ...adj,
    amount: toDbNumber(adj.amount),
  })),
  patientAdjustments: patientAdjustments.map(adj => ({
    ...adj,
    amount: toDbNumber(adj.amount),
  })),
});

const setUpMatchEClaimInfoLineItem = ({
  reportDataClaims,
  claim: { claimReportId, lineItems },
  lineItemReportId,
  modifiers,
}) => {
  const reportDataLineItem = reportDataClaims
    .find(claim => claim.claimReportId === claimReportId)
    .lineItems.find(li => li.lineItemReportId === lineItemReportId);

  return lineItems.map(li =>
    li.lineItemReportId === lineItemReportId
      ? mapLineItemToEClaimInfo({ ...reportDataLineItem, modifiers })
      : li,
  );
};

const setUpMatchEClaimInfoClaim = ({
  claims: reportDataClaims,
  eClaimInfo: { claims },
  lineItemReportId,
  claimReportId,
  modifiers,
}) =>
  claims.map(claim => {
    const isClaimUpdated = claim.claimReportId === claimReportId;
    return !isClaimUpdated
      ? claim
      : {
          ...claim,
          lineItems: setUpMatchEClaimInfoLineItem({
            reportDataClaims,
            claim,
            lineItemReportId,
            modifiers,
          }),
        };
  });

const setUpMatchEClaimInfo = ({
  reportData: { eClaimInfo, claims },
  lineItemReportId,
  claimReportId,
  modifiers,
}) => ({
  ...eClaimInfo,
  claims: setUpMatchEClaimInfoClaim({
    claims,
    eClaimInfo,
    lineItemReportId,
    claimReportId,
    modifiers,
  }),
});

const setUpMatchReportDataClaims = ({
  reportData: { claims },
  lineItemId,
  lineItemReportId,
}) =>
  claims?.map(claim => ({
    ...claim,
    lineItems: claim.lineItems.map(lineItem => ({
      ...lineItem,
      id:
        lineItem.lineItemReportId === lineItemReportId
          ? lineItemId
          : lineItem.id,
      ...(lineItem.errorMessage && {
        errorMessage:
          lineItem.lineItemReportId === lineItemReportId
            ? []
            : lineItem.errorMessage,
      }),
    })),
  }));

const matchLineItem = ({
  reportData,
  lineItemId,
  lineItemReportId,
  modifiers,
  claimReportId,
}) => ({
  ...reportData,
  claims: setUpMatchReportDataClaims({
    reportData,
    lineItemId,
    lineItemReportId,
  }),
  eClaimInfo: setUpMatchEClaimInfo({
    reportData,
    lineItemReportId,
    claimReportId,
    modifiers,
  }),
});

export default ({
  model: { id: eraId, reportId },
  reportData,
  lineItemId,
  lineItemReportId,
  claimReportId,
  modifiers,
}) => {
  const report = matchLineItem({
    reportData,
    lineItemId,
    lineItemReportId,
    claimReportId,
    modifiers,
  });

  return Promise.all([
    updateERAReport(eraId, { reportData: report }),
    associateCharges({
      version: 2,
      lineItemIds: [lineItemId],
      eraId,
      reportId,
    }),
  ]);
};
