import { ChargeAdjustmentsTable } from '../../../../src/components/tables/charges/neb-table-charges-adjustments';
import { ChargeResponsibilityTable } from '../../../../src/components/tables/charges/neb-table-charges-responsibility';

function formatAdjustments({ adjustments }) {
  return adjustments
    .filter(a => !!a.codeId && !!a.amount)
    .map(a => ({ ...a, id: a.id || null }));
}

function formatDebits({ lineItemDebits, primaryPayerId }) {
  return lineItemDebits
    .filter(
      lid =>
        !!lid.debit.amount &&
        (!!lid.codePaymentId || primaryPayerId === lid.debit.payerId),
    )
    .map(lid => ({
      id: lid.debit.id || null,
      owedAmount: lid.debit.amount,
      patientInsuranceId: lid.patientInsuranceId || null,
      payerId: lid.debit.payerId,
      responsibilityTypeId: lid.codePaymentId || null,
    }));
}

export function formatToBilling(items, includeEncounterCharge = true) {
  return items.map(item => ({
    id: item.id,
    attributeToUserId: item.purchase
      ? item.purchase.attributeToUserId || null
      : null,
    billedWaiting: item.billedWaiting || false,
    billedPatient: item.billedPatient || false,
    billedPrimary: item.billedPrimary || false,
    billedSecondary: item.billedSecondary || false,
    billedPaymentReceived: item.billedPaymentReceived || false,
    billedPaidInFull: item.billedPaidInFull || false,
    diagnosisPointers: item.encounterCharge
      ? item.encounterCharge.diagnosisPointers
      : null,
    holdClaim: item.holdClaim || false,
    holdStatement: item.holdStatement || false,
    holdSuperBill: item.holdSuperBill || false,
    pos: item.pos,
    modifier_1: item.modifier_1 || null,
    modifier_2: item.modifier_2 || null,
    modifier_3: item.modifier_3 || null,
    modifier_4: item.modifier_4 || null,
    ...(includeEncounterCharge && {
      encounterCharge: item.encounterCharge || null,
    }),
    shaded24: item.shaded24 || null,
    ndc: item.ndc,
    reportTypeCode: item.reportTypeCode,
    reportTransmissionCode: item.reportTransmissionCode,
    codeQualifier: item.codeQualifier,
    identificationNumber: item.identificationNumber || null,
    supplementalInformation: item.supplementalInformation,
    EPSDTType: item.epsdt.type || null,
    units: item.units,
    unitCharge: item.unitCharge,
    feeScheduleCharge: item.feeScheduleCharge,
    allowedAmount: item.allowedAmount,
    feeScheduleId: item.feeScheduleId || null,
    taxName: item.taxRate.name || null,
    taxRate: item.taxRate.rate || null,
    debits: formatDebits(item),
    adjustments: formatAdjustments(item),
    nationalDrugCodeQualifier: item.nationalDrugCodeQualifier || null,
    nationalDrugCode: item.nationalDrugCode || null,
    nationalDrugCodeDosage: item.nationalDrugCodeDosage || null,
    nationalDrugCodeUnitOfMeasurement:
      item.nationalDrugCodeUnitOfMeasurement || null,
    nationalDrugCodeNumberCategory: item.nationalDrugCodeNumberCategory || null,
    nationalDrugCodeSequenceOrPrescription:
      item.nationalDrugCodeSequenceOrPrescription || null,
    orderingProvider: item.orderingProvider,
    orderingProviderCredentials: item.orderingProviderCredentials,
    orderingProviderFirstName: item.orderingProviderFirstName,
    orderingProviderLastName: item.orderingProviderLastName,
    orderingProviderMiddleName: item.orderingProviderMiddleName,
    orderingProviderNPI: item.orderingProviderNPI,
    orderingProviderOtherId: item.orderingProviderOtherId,
    orderingProviderQualifier: item.orderingProviderQualifier,
  }));
}

export function formatToModifiers(items) {
  return items.map(item => ({
    id: item.id,
    modifier_1: item.modifiers[0] || '',
    modifier_2: item.modifiers[1] || '',
    modifier_3: item.modifiers[2] || '',
    modifier_4: item.modifiers[3] || '',
    pos: item.pos,
    units: item.units,
    unitCharge: item.unitCharge,
    feeScheduleCharge: item.feeScheduleCharge,
    allowedAmount: item.allowedAmount,
    debits: formatDebits(item),
    adjustments: formatAdjustments(item),
    diagnosisPointers: item.encounterCharge
      ? item.encounterCharge.diagnosisPointers.map(dp => dp.diagnosisCode)
      : null,
  }));
}

const withAllocatedForPayment = (lid, payment = {}) => {
  const paymentAllocation = lid.debit.allocations.find(
    a => a.credit && !!payment && a.credit.paymentId === payment.id,
  );

  return {
    ...lid,
    codePaymentId: lid.codePaymentId || '',
    patientInsuranceId: lid.patientInsuranceId || '',
    debit: {
      ...lid.debit,
      allocated: paymentAllocation ? paymentAllocation.amount : 0,
    },
  };
};

const addDefaultLineItemDebit = (
  lids,
  { primaryPayerId = null, primaryInsuranceId = null } = {},
) => {
  if (lids.length === 0) {
    lids.push(
      ChargeResponsibilityTable.createItem(primaryPayerId, primaryInsuranceId),
    );
  }

  return lids;
};

const getLidsForNonPrimaryPayers = li =>
  addDefaultLineItemDebit(
    li.lineItemDebits.filter(
      lid =>
        !li.primaryPayerId ||
        lid.debit.payerId !== li.primaryPayerId ||
        lid.patientInsuranceId !== li.primaryInsuranceId,
    ),
  );

const getLidsForPrimaryPayer = li =>
  li.primaryPayerId
    ? addDefaultLineItemDebit(
        li.lineItemDebits.filter(
          lid =>
            li.primaryPayerId &&
            lid.debit.payerId === li.primaryPayerId &&
            lid.patientInsuranceId === li.primaryInsuranceId,
        ),
        li,
      )
    : [];

const getPreviewLidsForPrimaryPayer = li =>
  li.primaryPayerId
    ? addDefaultLineItemDebit(
        li.previewDebits.filter(
          previewDebit =>
            li.primaryPayerId &&
            previewDebit.payerId === li.primaryPayerId &&
            previewDebit.patientInsuranceId === li.primaryInsuranceId,
        ),
        li,
      )
    : [];

const getPreviewLidsForNonPrimaryPayers = li =>
  addDefaultLineItemDebit(
    li.previewDebits.filter(
      previewDebit =>
        !li.primaryPayerId ||
        previewDebit.payerId !== li.primaryPayerId ||
        previewDebit.patientInsuranceId !== li.primaryInsuranceId,
    ),
  );

const withAllocatedForPreview = previewDebit => ({
  ...previewDebit,
  codePaymentId: previewDebit.codePaymentId || '',
  patientInsuranceId: previewDebit.patientInsuranceId || '',
  debit: {
    allocated: previewDebit.allocated,
    allocations: [],
    amount: previewDebit.amount,
    payerId: previewDebit.payerId || null,
  },
});

const getLids = (li, payment) =>
  [
    ...getLidsForPrimaryPayer(li),
    ...getLidsForNonPrimaryPayers(li).sort((a, b) => a.order - b.order),
  ].map(lid => withAllocatedForPayment(lid, payment));

const getLidsWithPreview = li =>
  [
    ...getPreviewLidsForPrimaryPayer(li),
    ...getPreviewLidsForNonPrimaryPayers(li).sort((a, b) => a.order - b.order),
  ].map(debits => withAllocatedForPreview(debits));

export const formatLineItemBase = (li, providers, payerPlans, payment) => ({
  ...li,
  encounterCharge: { ...li.encounterCharge },
  purchase: {
    ...li.purchase,
    attributeToUserId: li.purchase ? li.purchase.attributeToUserId || '' : '',
    chargeId: li.purchase ? li.purchase.chargeId || '' : '',
  },
  taxRate: {
    rate: li.taxRate || 0,
    name: li.taxName || '',
  },
  epsdt: {
    code: li.EPSDTCode || false,
    type: li.EPSDTType || '',
  },
  lineItemDebits: getLids(li, payment),
  adjustments: li.adjustments.length
    ? li.adjustments.sort((a, b) => a.order - b.order)
    : [ChargeAdjustmentsTable.createModel()],
  primaryPayer: li.primaryPayerId
    ? payerPlans.find(p => p.id === li.primaryPayerId)
    : null,
  provider:
    li.encounterCharge && li.encounterCharge.providerId
      ? providers.find(p => p.id === li.encounterCharge.providerId)
      : null,
  feeScheduleId: li.feeScheduleId || '',
  nationalDrugCodeQualifier: li.nationalDrugCodeQualifier || '',
  nationalDrugCodeUnitOfMeasurement: li.nationalDrugCodeUnitOfMeasurement || '',
  nationalDrugCodeNumberCategory: li.nationalDrugCodeNumberCategory || '',
  patientAuthorizationId: li.patientAuthorizationId || '',
});

const formatDetailedLineItem = (
  li,
  providers,
  payerPlans,
  payment,
  patients,
) => {
  const patient = li.patientId ? patients[li.patientId] : null;

  const baseLineItem = formatLineItemBase(li, providers, payerPlans, payment);

  return {
    ...baseLineItem,
    patient,
  };
};

export const formatDetailedLineItems = (
  lineItems,
  providers,
  payerPlans,
  payment,
  patients,
) =>
  lineItems.map(li =>
    formatDetailedLineItem(li, providers, payerPlans, payment, patients),
  );

const formatAllocationLineItemBase = (li, payment) => ({
  adjustments: li.adjustments.length
    ? li.adjustments.sort((a, b) => a.order - b.order)
    : [ChargeAdjustmentsTable.createModel()],
  allowedAmount: li.allowedAmount,
  billedAmount: li.billedAmount,
  billedPatient: li.billedPatient,
  billedPrimary: li.billedPrimary,
  billedSecondary: li.billedSecondary,
  billType: li.billType,
  chargeNumber: li.chargeNumber,
  code: li.code,
  codeType: li.codeType,
  dateOfService: li.dateOfService,
  encounterCharge: { ...li.encounterCharge },
  feeScheduleCharge: li.feeScheduleCharge,
  feeScheduleId: li.feeScheduleId || '',
  holdStatement: li.holdStatement,
  holdSuperBill: li.holdSuperBill,
  holdClaim: li.holdClaim,
  id: li.id || '',
  invoiceId: li.invoiceId,
  lineItemDebits:
    li.previewDebits && li.previewDebits.length
      ? getLidsWithPreview(li)
      : getLids(li, payment),
  modifier_1: li.modifier_1 || '',
  modifier_2: li.modifier_2 || '',
  modifier_3: li.modifier_3 || '',
  modifier_4: li.modifier_4 || '',
  modifiers: li.modifiers || '',
  patientId: li.patientId,
  primaryPayerId: li.primaryPayerId,
  primaryInsuranceId: li.primaryInsuranceId,
  purchase: {
    ...li.purchase,
    chargeId: li.purchase ? li.purchase.chargeId || '' : '',
  },
  secondaryInsuranceId: li.secondaryInsuranceId,
  taxAmount: li.taxAmount,
  taxRate: {
    rate: li.taxRate || 0,
    name: li.taxName || '',
  },
  tertiaryInsuranceId: li.tertiaryInsuranceId,
  type: li.type,
  units: li.units,
  unitCharge: li.unitCharge,
});

const mapMinimalPatient = raw => ({
  id: raw.id || '',
  medicalRecordNumber: raw.medicalRecordNumber || '',
  firstName: raw.firstName || '',
  lastName: raw.lastName || '',
  middleName: raw.middleName || '',
  preferredName: raw.preferredName || '',
  suffix: raw.suffix || '',
});

const minimalPatient = (patientId, patient) => {
  if (patient) {
    return mapMinimalPatient(patient);
  }

  return mapMinimalPatient({ id: patientId });
};

const minimalProvider = provider => ({ id: provider.id, name: provider.name });

const formatAllocationLineItem = (
  li,
  providers,
  payerPlans,
  payment,
  patients,
) => {
  const patient = li.patientId ? patients[li.patientId] : null;

  const primaryPayer = li.primaryPayerId
    ? payerPlans.find(p => p.id === li.primaryPayerId)
    : null;

  const provider =
    li.encounterCharge && li.encounterCharge.providerId
      ? minimalProvider(
          providers.find(p => p.id === li.encounterCharge.providerId),
        )
      : null;

  const baseLineItem = formatAllocationLineItemBase(li, payment);

  return {
    ...baseLineItem,
    patient: minimalPatient(li.patientId, patient),
    provider,
    primaryPayer,
  };
};

export const formatAllocationLineItems = (
  lineItems,
  providers,
  payerPlans,
  payment,
  patients,
) =>
  lineItems.map(li =>
    formatAllocationLineItem(li, providers, payerPlans, payment, patients),
  );
