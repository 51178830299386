import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import {
  dismissAbandonedResponse,
  linkAbandonedResponse,
} from '../../../../packages/neb-api-client/src/electronic-claims/abandoned-responses';
import { getLedgerInvoiceItems } from '../../../../packages/neb-api-client/src/invoice-api-client';
import {
  openError,
  openSuccess,
} from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  OVERLAY_WIDTH_EXTRA_LARGE,
} from '../../../../packages/neb-styles/neb-variables';
import {
  objToName,
  DEFAULT_NAME_OPTS,
} from '../../../../packages/neb-utils/formatters';
import { fetchInsuranceItems } from '../../../../packages/neb-utils/neb-ledger-util';
import '../../forms/abandoned-response/neb-form-abandoned-claim-status';
import {
  DISMISS_CLAIM_RESPONSE_SUCCESS,
  DISMISSING_CLAIM_RESPONSE_ERROR,
  LINK_CLAIM_RESPONSE_SUCCESS,
  LINK_CLAIM_RESPONSE_ERROR,
} from '../../../utils/user-message';

const DISMISS_RESPONSE = {
  title: 'Dismiss Response',
  message: 'Are you sure you want to dismiss the response?',
  confirmText: 'Yes',
  cancelText: 'No',
};

const LINK_RESPONSE = {
  title: 'Link Claim',
  message: 'Are you sure you want to link this claim and response?',
  confirmText: 'Yes',
  cancelText: 'No',
};

const LINK_RESPONSE_NO_CLAIM_SELECTED = {
  title: 'Link Claim',
  message: 'Please select a claim to link the response.',
};

export const ELEMENTS = {
  header: { id: 'header' },
  form: { id: 'form' },
};

export const TITLE = 'View Unlinked Claim Response';

class NebOverlayAbandonedClaimStatus extends Overlay {
  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: ${OVERLAY_WIDTH_EXTRA_LARGE};
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      dismissResponse: async () => {
        try {
          if (await openPopup(POPUP_RENDER_KEYS.CONFIRM, DISMISS_RESPONSE)) {
            await dismissAbandonedResponse(this.model.id);
            store.dispatch(openSuccess(DISMISS_CLAIM_RESPONSE_SUCCESS));

            this.dismiss();
          }
        } catch (err) {
          store.dispatch(openError(DISMISSING_CLAIM_RESPONSE_ERROR));
        }
      },
      linkResponse: async response => {
        try {
          if (response && response.id) {
            if (await openPopup(POPUP_RENDER_KEYS.CONFIRM, LINK_RESPONSE)) {
              await linkAbandonedResponse(this.model.id, response);
              store.dispatch(openSuccess(LINK_CLAIM_RESPONSE_SUCCESS));

              this.dismiss();
            }
          } else {
            await openPopup(
              POPUP_RENDER_KEYS.MESSAGE,
              LINK_RESPONSE_NO_CLAIM_SELECTED,
            );
          }
        } catch (err) {
          store.dispatch(openError(LINK_CLAIM_RESPONSE_ERROR));
        }
      },
      dismiss: () => this.dismiss(),
      clickLink: async (name, options) => {
        await this.__openOverlay(name, options);
      },
    };
  }

  __getBatchId(claim) {
    return claim.batches.length ? claim.batches[0] : '';
  }

  __getProcedureModifiers({ procedureCode, modifiers }) {
    const nonEmptyModifiers = modifiers.filter(modifier => modifier);

    return nonEmptyModifiers.length
      ? `${procedureCode} - ${nonEmptyModifiers.join(', ')}`
      : procedureCode;
  }

  __generateServiceLine(procMod, lineItem, status) {
    return {
      procMod,
      effectiveDate: lineItem.effectiveDate,
      units: lineItem.units,
      billedAmount: lineItem.billedAmount,
      paidAmount: lineItem.paidAmount,
      statuses: [status],
    };
  }

  __mapServiceLines() {
    if (this.model.eClaimStatusLineItems) {
      const serviceLines = this.model.eClaimStatusLineItems.reduce(
        (accum, lineItem) => {
          const procMod = this.__getProcedureModifiers(lineItem);
          const status = {
            code: lineItem.code,
            description: lineItem.description,
            statusCode: lineItem.statusCode,
            statusCodeValue: lineItem.statusCodeValue,
          };

          if (accum[`${procMod}`]) {
            accum[`${procMod}`].statuses.push(status);
          } else {
            accum[`${procMod}`] = this.__generateServiceLine(
              procMod,
              lineItem,
              status,
            );
          }

          return accum;
        },
        {},
      );

      this.model.serviceLines = Object.values(serviceLines);
    }
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      this.__mapServiceLines();
    }

    super.update(changedProps);
  }

  async __openOverlay(name, options) {
    const [index, key] = name.split('.');
    const { claims, patient } = options;
    const claim = claims[index];

    switch (key) {
      case 'primaryPayerAlias':
        await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: claim.primaryPayerId,
        });

        break;

      case 'primaryPlanName': {
        const insurances = await fetchInsuranceItems(patient.id);

        const selectedInsurance = insurances.find(
          insurance => insurance.data.id === claim.primaryInsuranceId,
        ).data;

        await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_VIEW, {
          patientId: patient.id,
          patientInsurances: insurances,
          patientInsurance: selectedInsurance,
        });

        break;
      }

      case 'claimNumber':
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, {
          claimId: claim.id,
        });

        break;

      case 'batchNumber':
        await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
          batchId: this.__getBatchId(claim),
          patientId: patient.id,
        });

        break;

      case 'invoiceNumber':
        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
          patient: {
            id: patient.id,
            name: objToName(patient.name, DEFAULT_NAME_OPTS),
            mrn: patient.medicalRecordNumber,
          },
          lineItemIds: (await getLedgerInvoiceItems(claim.invoiceId)).data.map(
            li => li.id,
          ),
          selectedIds: [],
        });

        break;

      default:
    }
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        showCancelButton
        showBackButton
        title="${TITLE}"
        .onCancel="${this.handlers.dismiss}"
        .onBack="${this.handlers.dismiss}"
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-form-abandoned-claim-status
        id="${ELEMENTS.form.id}"
        .model="${this.model}"
        .onDismissResponse="${this.handlers.dismissResponse}"
        .onLinkResponse="${this.handlers.linkResponse}"
        .onClickLink="${this.handlers.clickLink}"
        .onClose="${this.handlers.dismiss}"
      >
      </neb-form-abandoned-claim-status>
    `;
  }
}

customElements.define(
  'neb-overlay-abandoned-claim-status',
  NebOverlayAbandonedClaimStatus,
);
