import ApiClient, { Method } from '../utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'billing' });

export const getClaimsTotals = ({ patientId, userLocationIds }) =>
  apiClient.makeRequest({
    path: 'claim-totals',
    ...(patientId && { queryParams: { patientId } }),
    headers: {
      'Content-Type': 'application/json',
      ...(userLocationIds && { userLocationIds }),
    },
    method: Method.GET,
    version: 3,
    cacheKey: patientId ? `claim-totals-${patientId}` : 'claim-totals',
  });
