import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import downloadCSV from './utils/download-csv';

export const apiClient = new ApiClient({ microservice: 'api' });
export const headerJSON = { 'Content-Type': 'application/json' };

const BASE_REQUEST = {
  method: Method.GET,
  responseType: RESPONSE_TYPE.RAW,
  optOutLoadingIndicator: false,
};
export const DOWNLOAD_PATIENTS_REQUEST = {
  ...BASE_REQUEST,
  path: 'patients/roster',
};

export const makeGetRequest = (
  jobId,
  { importStatus, limit = 25, offset = 0 },
) => ({
  ...BASE_REQUEST,
  path: `patientRoster/${jobId}`,
  queryParams: {
    limit,
    offset,
    importStatus,
  },
});

export const makeGetCountRequest = path => ({ ...BASE_REQUEST, path });

export const createPatients = async importJobId => {
  const path = `patientRoster/${importJobId}/patients`;
  const postRequest = {
    method: Method.PUT,
    headers: headerJSON,
    responseType: RESPONSE_TYPE.RAW,
    path,
    optOutLoadingIndicator: false,
  };
  const response = await apiClient.makeRequest(postRequest);
  return response.ok;
};

export const downloadPatients = async () => {
  const response = await apiClient.makeRequest(DOWNLOAD_PATIENTS_REQUEST);
  return downloadCSV(response);
};

export const STATUS_IN_QUERY = Object.freeze({
  'Needs Attention': 'need_attention',
  Duplicate: 'duplicate',
  Existing: 'existing',
});

export const downloadPatientRoster = async (importJobId, importStatus) => {
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    ...BASE_REQUEST,
    path: `patientRoster/${importJobId}/csv`,
    queryParams: {
      importStatus: STATUS_IN_QUERY[importStatus],
    },
  });

  return downloadCSV(response);
};

export const getPatientRoster = async (jobId, options) => {
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    ...makeGetRequest(jobId, options),
  });
  const body = await response.json();
  return body.data;
};
export const getImportPatientJobConfig = importPatientJobId => ({
  method: Method.GET,
  responseType: RESPONSE_TYPE.RAW,
  path: `importPatientJob/${importPatientJobId}`,
  optOutLoadingIndicator: false,
});

export const getImportPatientJob = async importPatientJobId => {
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    ...getImportPatientJobConfig(importPatientJobId),
  });
  const json = await response.json();
  return json.data[0];
};

export const getPatientRosterCounts = async importPatientJobId => {
  const path = `patientRoster/${importPatientJobId}/counts`;

  try {
    const response = await apiClient.makeRequest({
      optOutLoadingIndicator: false,
      ...makeGetCountRequest(path),
    });
    const json = await response.json();
    return json.data[0];
  } catch (err) {
    console.log('error: ', err.message);
    return {
      attentionCount: 0,
      readyCount: 0,
      duplicateCount: 0,
      existingCount: 0,
    };
  }
};
