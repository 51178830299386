import { ISO_DATE_FORMAT } from '../../neb-input/nebFormatUtils';
import { parseDate } from '../../neb-utils/date-util';

import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'api' });

export const getEmptyTreatmentPlan = () => ({
  startDate: parseDate()
    .startOf('day')
    .format(ISO_DATE_FORMAT),
  treatmentPhases: [],
});

export const calculateTreatmentPlan = (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) =>
  apiClient.makeRequest({
    path: `patients/${patientId}/treatmentPlans`,
    queryParams,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
  });

export const getTreatmentPlan = async (patientId, queryParams = {}) => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/treatmentPlans`,
    queryParams,
  });

  return res.data[0];
};

export const getCompletedTreatmentPlans = async patientId => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/treatmentPlans`,
    queryParams: { status: 'Completed' },
  });

  return res.data;
};

export const updateTreatmentPlan = (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) =>
  apiClient.makeRequest({
    path: `patients/${patientId}/treatmentPlans/${treatmentPlan.id}`,
    queryParams,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
  });

export const createTreatmentPlan = (
  patientId,
  treatmentPlan,
  queryParams = {},
  optOutLoadingIndicator = true,
) =>
  apiClient.makeRequest({
    path: `patients/${patientId}/treatmentPlans`,
    queryParams,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(treatmentPlan),
    optOutLoadingIndicator,
  });
