import {
  mapNextAppointmentToServerModel,
  mapServerModelToNextAppointment,
} from './mappers/next-appointment-mapper';
import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'api' });

const buildBaseUrl = encounterId => `encounters/${encounterId}/nextAppointment`;

export const createNextAppointment = (nextAppointment, encounterId) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: buildBaseUrl(encounterId),
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(nextAppointment),
  });

export const updateNextAppointment = async (nextAppointment, encounterId) => {
  const serverModel = mapNextAppointmentToServerModel(nextAppointment);
  const json = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${buildBaseUrl(encounterId)}/${nextAppointment.id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(serverModel),
  });
  return mapServerModelToNextAppointment(json.data[0]);
};

export const getNextAppointment = async encounterId => {
  const json = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${buildBaseUrl(encounterId)}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return json.count === 0
    ? null
    : mapServerModelToNextAppointment(json.data[0]);
};
