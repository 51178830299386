import { PRINT_UPCOMING_APPOINTMENTS_ERROR } from '../../../../../src/utils/user-message';
import { fetchOne } from '../../../../neb-api-client/src/patient-api-client';
import { openError } from '../../../../neb-dialog/neb-banner-state';
import { store } from '../../../../neb-redux/neb-redux-store';
import printUpcomingAppointments from '../../../../neb-utils/pdf/print-upcoming-appointments';

const buildPracticeInformationWithLocation = ({ practiceInfo, location }) =>
  location
    ? {
        ...practiceInfo,
        name: location.businessName || practiceInfo.name,
        hideLogo: location.hideLogo,
        selectedLocation: location,
      }
    : { ...practiceInfo, hideLogo: false };

export default async ({ patient, location = null }) => {
  try {
    const {
      practiceInformation: { item: practiceInfo },
    } = store.getState();

    const { id, firstName } = patient;

    const patientData = await (firstName ? patient : fetchOne(id));

    await printUpcomingAppointments({
      patient: patientData,
      practiceInfo: buildPracticeInformationWithLocation({
        practiceInfo,
        location,
      }),
    });
  } catch (e) {
    console.error(e);
    store.dispatch(openError(PRINT_UPCOMING_APPOINTMENTS_ERROR));
  }
};
