import '../../../src/components/forms/patients/profile/neb-patient-online-booking-link-form';
import '../../neb-lit-components/src/components/patients/neb-patient-summary';
import './neb-popup-patient-summary';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';

import { getPatientAppointments } from '../../neb-api-client/src/appointment-api-client';
import { BUTTON_ROLE } from '../../neb-lit-components/src/components/neb-button';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
  CSS_COLOR_HIGHLIGHT,
} from '../../neb-styles/neb-variables';

import NebPopup from './neb-popup';
import { POPUP_RENDER_KEYS } from './renderer-keys';

export const ELEMENTS = {
  form: {
    id: 'form',
  },
  backButton: {
    id: 'button-back',
  },
  popupPatientSummary: {
    id: 'popup-patient-summary',
  },
};
export const HEADER_ACCOUNT = 'Patient Record';
const HEADER_MATCH = 'Potential Matching Online Booking Accounts';
export const HEADER_REVIEW_MATCH = 'Selected Online Booking Account';
const HELP_TEXT =
  'If you would like to link this patient record to a different online booking account, cancel this window, and click on the “Send Invitation Email to Patient” link on the previous screen.';

class NebPopupMatchingOnlineAccount extends NebPopup {
  static get properties() {
    return {
      __appointments: Object,
    };
  }

  initHandlers() {
    super.initHandlers();

    this.handlers.cancel = response => this.onClose(response);

    this.handlers.match = async match => {
      const model = {
        title: 'Review Selection',
        account: this.model.account,
        appointments: this.__appointments,
        match,
        headerAccount: HEADER_ACCOUNT,
        headerReviewMatch: HEADER_REVIEW_MATCH,
        isOnlineAccountMatch: true,
      };
      const result =
        (await openPopup(POPUP_RENDER_KEYS.REVIEW_MATCH_SELECTION, model)) ||
        {};

      if (!result.back) {
        this.onClose(result);
      }
    };
  }

  initState() {
    super.initState();
    this.__appointments = null;
  }

  async modelChanged() {
    this.title = this.model.title;
    this.__appointments = await getPatientAppointments(this.model.account.id);
  }

  firstUpdated() {
    this.__elements = {
      form: this.shadowRoot.getElementById(ELEMENTS.form.id),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          width: 996px;
          display: flex;
          height: 600px;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
          overflow-x: hidden;
        }

        :host([layout='small']) .content {
          margin: 0;
        }

        :host([layout='small']) .container {
          padding: 0;
          height: 100%;
          min-height: 100px;
        }

        :host([layout='small']) .container-help {
          padding: 0 19px;
        }

        .footer {
          padding: 7px 17px;
          display: flex;
          justify-content: space-between;
          height: 70px;
        }

        :host([layout='small']) .footer {
          display: block;
          margin-top: 10px;
          padding: ${CSS_SPACING} 17px;
          border-top: 1px ${CSS_COLOR_HIGHLIGHT} solid;
        }

        .container-help {
          display: flex;
          padding: 0 100px 0 27px;
        }

        .text-help {
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .icon {
          flex: 0 0 20px;
          box-sizing: border-box;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .header {
          margin: 22px 23px 0 17px;
        }

        .container {
          flex: 1 0 0;
          padding: unset;
        }

        .popup-body {
          flex: 1 0 0;
        }

        .content {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
        }

        .form {
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
        }

        .back {
          min-width: 105px;
        }
      `,
    ];
  }

  __renderHelpText() {
    return html`
      <div class="container-help">
        <neb-icon class="icon" icon="neb:info"></neb-icon>
        <div class="text-help">${HELP_TEXT}</div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div class="popup-body">
        <div>
          <neb-popup-patient-summary
            id="${ELEMENTS.popupPatientSummary.id}"
            .layout="${this.layout}"
            .patient="${this.model.account}"
            .appointments="${this.__appointments}"
          ></neb-popup-patient-summary>
          <neb-patient-online-booking-link-form
            id="${ELEMENTS.form.id}"
            class="form"
            .layout="${this.layout}"
            .model="${this.model}"
            .accountTitle="${HEADER_ACCOUNT}"
            .matchTitle="${HEADER_MATCH}"
            .onMatch="${this.handlers.match}"
            .showHeader="${false}"
          ></neb-patient-online-booking-link-form>
        </div>
        ${this.layout === 'small' ? this.__renderHelpText() : ''}
      </div>
      <div class="footer">
        <neb-button
          id="${ELEMENTS.backButton.id}"
          class="button back"
          role="${BUTTON_ROLE.CONFIRM}"
          label="Back"
          .onClick="${this.handlers.cancel}"
        ></neb-button>
        ${this.layout !== 'small' ? this.__renderHelpText() : ''}
      </div>
    `;
  }
}

customElements.define(
  'neb-popup-matching-online-account',
  NebPopupMatchingOnlineAccount,
);
