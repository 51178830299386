import { updateERAReport } from '../../../../packages/neb-api-client/src/era-report-api-client';

const getLineItemTemplate = ([item]) => ({
  adjustments: [],
  id: item.id,
  lineItemReportId: item.lineItemReportId,
  patientAdjustments: [],
  paymentInfo: {
    allowed: '0.00',
    billed: '0.00',
    coins: '0.00',
    copay: '0.00',
    deduct: '0.00',
    paid: '0.00',
  },
  posted: false,
  procedureCode: item.procedureCode,
  renderingNPI: item.renderingNPI,
  serviceDate: item.serviceDate,
  units: 0,
});

const mergeEClaimInfo = (involvedClaimIds, eClaimInfo, claimId) => ({
  ...eClaimInfo,
  claims: eClaimInfo?.claims?.map(claim => {
    if (claim.claimReportId !== claimId) return claim;

    const [lineItems, lineItemsToMerge] = claim.lineItems.reduce(
      (acc, lineItem) => {
        acc[involvedClaimIds.has(lineItem.lineItemReportId) ? 1 : 0].push(
          lineItem,
        );

        return acc;
      },
      [[], []],
    );

    const mergedLineItem = lineItemsToMerge.reduce((acc, li, index) => {
      if (index === 0) return li;

      acc.units = (+acc.units + +li.units).toString();
      ['billed', 'allowed', 'paid'].forEach(field => {
        acc[field] += li[field];
      });

      ['adjustments', 'patientAdjustments'].forEach(type => {
        li[type].forEach(adj => {
          const existing = acc[type].find(a => a.code === adj.code);

          if (existing) {
            existing.amount += adj.amount;
          } else {
            acc[type].push({ ...adj, amount: adj.amount });
          }
        });
      });

      return acc;
    }, null);

    return {
      ...claim,
      lineItems: [...lineItems, mergedLineItem].filter(item => item !== null),
    };
  }),
});

const mergeLineItemsAndGetReportData = ({ items, reportData, claimId }) => {
  const lineItemTemplate = getLineItemTemplate(items);

  const involvedClaims = new Set(items.map(item => item.lineItemReportId));
  const eClaimInfo = mergeEClaimInfo(
    involvedClaims,
    reportData.eClaimInfo,
    claimId,
  );

  reportData.eClaimInfo = eClaimInfo;

  const mergedLineItem = items.reduce((acc, item) => {
    acc.units += item.units;

    ['billed', 'allowed', 'paid', 'deduct', 'coins', 'copay'].forEach(field => {
      acc.paymentInfo[field] = (
        +acc.paymentInfo[field] + +item.paymentInfo[field]
      ).toFixed(2);
    });

    ['adjustments', 'patientAdjustments'].forEach(type => {
      item[type].forEach(adj => {
        const existing = acc[type].find(a => a.code === adj.code);

        if (existing) {
          existing.amount = (+existing.amount + +adj.amount).toFixed(2);
        } else acc[type].push({ ...adj, amount: adj.amount });
      });
    });

    return acc;
  }, lineItemTemplate);

  return {
    ...reportData,
    claims: reportData.claims?.map(claim => {
      const hasInvolvedLineItems = claim.lineItems.some(lineItem =>
        involvedClaims.has(lineItem.lineItemReportId),
      );

      const lineItems = claim.lineItems.filter(
        lineItem => !involvedClaims.has(lineItem.lineItemReportId),
      );

      if (hasInvolvedLineItems) lineItems.push(mergedLineItem);

      return { ...claim, lineItems };
    }),
  };
};

export default ({ claimId, items, reportData, model: { id } }) => {
  const report = mergeLineItemsAndGetReportData({ items, reportData, claimId });
  return updateERAReport(id, { reportData: report });
};
