import './neb-icon';

import { css, html, LitElement } from 'lit';

import {
  baseStyles,
  colorFlip,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_BORDER_GREY_1,
  CSS_COLOR_BLACK,
  CSS_COLOR_YELLOW,
  CSS_FONT_WEIGHT_BOLD,
  layoutStyles,
} from '../../styles';
import { ADD_ONS, hasAddOn } from '../../utils/add-ons';
import { getRTEStatusIcon } from '../../utils/real-time-eligibility';

export const ELEMENTS = {
  title: { id: 'title' },
  details: { id: 'details' },
  realTimeEligibilityIcon: { id: 'rte-icon' },
  confirmedIcon: { id: 'confirmed-icon' },
  appointmentStatusIcon: { id: 'arrived-icon' },
  encounterStatusIcon: { id: 'encounter-status-icon' },
  recurringIcon: { id: 'recurring-icon' },
  splitIcon: { id: 'split-icon' },
  background: { id: 'background' },
  eventContainer: { id: 'event-container' },
  eventInfoContainer: { id: 'event-info-container' },
  iconContainer: { id: 'icon-container' },
  resizeUpHandle: { id: 'resize-up-handle' },
  resizeDownHandle: { id: 'resize-down-handle' },
};

class NebCalendarEvent extends LitElement {
  static get properties() {
    return {
      contextOpen: Boolean,
      model: Object,
      isResizing: Boolean,
      hasSplits: Boolean,
      month: { type: Boolean, reflect: true },
      small: { type: Boolean, reflect: true },
      preview: { type: Boolean, reflect: true },
      __hover: { type: Boolean },
      __arrived: { type: Boolean, attribute: 'arrived', reflect: true },
      __strikeThrough: {
        type: Boolean,
        attribute: 'strike-through',
        reflect: true,
      },
      __isRescheduled: { type: Boolean, attribute: 'rescheduled' },

      __hasCTVerify: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
          overflow: hidden;
          user-select: none;
          cursor: pointer;
        }

        :host([preview]) {
          box-shadow: rgb(0 0 0 / 65%) 0px 0px 10px 0px;
          min-height: 20px;
          overflow: visible;
        }

        :host([small]:hover) {
          z-index: 0;
          min-height: 20px;
          border: ${CSS_BORDER_GREY_1};
        }

        .background {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 4px;
        }

        :host([preview]) .background {
          opacity: 1;
        }

        :host(:hover) .background {
          opacity: 1;
        }

        :host([preview]:hover) .background {
          opacity: 0.7;
        }

        .container {
          position: relative;
          display: flex;
          justify-content: space-between;
          height: 100%;
          padding: 0 5px;
          border-radius: 4px;
        }

        :host([month]) .container {
          flex-wrap: nowrap;
          min-height: inherit;
        }

        .container-event-info {
          display: flex;
          height: fit-content;
          width: fit-content;
          flex-wrap: wrap;
          user-select: none;
        }

        :host([arrived]) .container-event-info {
          font-style: italic;
        }

        :host([strike-through]) .container-event-info {
          text-decoration: line-through;
        }

        :host([rescheduled]) .container-event-info {
          opacity: 0.6;
        }

        :host([month]) .container-event-info {
          flex-wrap: nowrap;
        }

        :host([preview]) .container-event-info {
          justify-content: center;
          align-self: center;
          width: 100%;
        }

        .label-title {
          font-size: 11px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          margin-right: 5px;
        }

        :host([preview]) .label-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
        }

        .label-details {
          font-size: 11px;
        }

        :host([month]) .label-details {
          min-width: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .container-icons {
          grid-gap: 10px;
          padding: 3px 0;
        }

        .icon {
          display: block;
          height: 11px;
          width: 11px;
          fill: ${CSS_COLOR_BLACK};
        }

        .resize-handle {
          width: 17px;
          height: 17px;
          border-radius: 10px;
          position: absolute;
          background-color: white;
          cursor: ns-resize;
          z-index: 1;
        }
        .resize-up {
          top: -5px;
          right: 10%;
        }

        .resize-down {
          bottom: -5px;
          left: 10%;
        }

        .hover-blocker {
          width: 100%;
          height: 60px;
          pointer: ns-resize;
          position: absolute;
        }

        .hover-blocker-up {
          top: -60px;
        }

        .hover-blocker-down {
          bottom: -60px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.contextOpen = false;
    this.model = {
      title: '',
      details: '',
      color: '',
      rteStatus: '',
      isBOT: false,
      arrived: false,
      checkedIn: false,
      checkedOut: false,
      confirmed: false,
      recurring: false,
      noShow: false,
      hasEncounter: false,
      signedEncounter: false,
    };

    this.isResizing = false;
    this.hasSplits = false;

    this.__arrived = false;
    this.__strikeThrough = false;
    this.__isRescheduled = false;
    this.__hasCTVerify = false;

    this.onResizeDown = () => {};

    this.onResizeUp = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      enter: () => {
        this.__hover = true;
      },
      leave: () => {
        this.__hover = false;
      },
      resizeDown: e => {
        this.onResizeDown(this);

        e.preventDefault();
      },
      resizeUp: e => {
        this.onResizeUp(this);

        e.preventDefault();
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model) {
      this.__strikeThrough =
        this.model.noShow || this.model.canceled || this.model.isRescheduled;

      this.__isRescheduled = this.model.isRescheduled;

      this.__arrived =
        !this.model.checkedIn && !this.model.checkedOut && this.model.arrived;
    }
  }

  disconnectedCallback() {
    if (this.contextOpen) {
      window.dispatchEvent(
        new CustomEvent('neb-context-menu-close', {
          bubbles: true,
          composed: true,
        }),
      );
    }

    super.disconnectedCallback();
  }

  async firstUpdated() {
    this.__hasCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  __getRTEFill() {
    switch (this.model.rteStatus) {
      case 'warning':
        return `fill: ${CSS_COLOR_YELLOW}`;
      case 'validated':
        return `fill: ${CSS_BANNER_SUCCESS_COLOR}`;
      default:
        return `fill: ${CSS_BANNER_ERROR_COLOR}`;
    }
  }

  __getStandardFill() {
    const color = this.__hover
      ? this.model.textColor
      : this.model.fauxTextColor;

    return `fill: ${color};`;
  }

  __getAppointmentStatusIcon() {
    if (this.model.checkedIn) return 'neb:checkedIn';
    if (this.model.checkedOut) return 'neb:checkedOut';
    if (this.model.arrived) return 'neb:arrived';
    if (this.model.isRescheduled) return 'neb:rescheduled';
    if (this.model.canceled) return 'neb:canceled';
    if (this.model.noShow) return 'neb:noShow';

    return '';
  }

  __getEncounterStatusIcon() {
    if (!this.model.hasEncounter) return '';

    return this.model.signedEncounter ? 'neb:signed' : 'neb:unsigned';
  }

  __renderIcons() {
    if (this.month) return '';

    const icons = {
      realTimeEligibilityIcon:
        this.model.rteStatus && this.__hasCTVerify
          ? getRTEStatusIcon(this.model.rteStatus)
          : '',
      confirmedIcon: this.model.confirmed ? 'neb:confirmed' : '',
      appointmentStatusIcon: this.__getAppointmentStatusIcon(),
      encounterStatusIcon: this.__getEncounterStatusIcon(),
      recurringIcon: this.model.recurring ? 'neb:loop' : '',
      splitIcon: this.model.isSplit ? 'neb:splitAppointment' : '',
    };

    return html`
      <div
        id="${ELEMENTS.iconContainer.id}"
        class="container-icons grid grid-lean grid-2"
      >
        ${
          Object.entries(icons).map(
            ([key, icon]) =>
              icon &&
              html`
                <neb-icon
                  id="${ELEMENTS[key].id}"
                  class="icon"
                  icon="${icon}"
                  style="${
                    key === 'realTimeEligibilityIcon'
                      ? this.__getRTEFill()
                      : this.__getStandardFill()
                  }"
                ></neb-icon>
              `,
          )
        }
      </div>
    `;
  }

  __renderResizeHandles() {
    if (!this.preview || this.hasSplits) return '';

    return html`
      <div
        id="${ELEMENTS.resizeUpHandle.id}"
        class="resize-handle resize-up"
        @mousedown="${this.__handlers.resizeUp}"
        @touchstart="${this.__handlers.resizeUp}"
        style="border: 2px solid ${this.model.color}"
      ></div>

      <div
        id="${ELEMENTS.resizeDownHandle.id}"
        class="resize-handle resize-down"
        @mousedown="${this.__handlers.resizeDown}"
        @touchstart="${this.__handlers.resizeDown}"
        style="border: 2px solid ${this.model.color}"
      ></div>
    `;
  }

  __renderResizeBlockers() {
    if (!this.isResizing) return '';

    return html`
      <div class="hover-blocker hover-blocker-up"></div>
      <div class="hover-blocker hover-blocker-down"></div>
    `;
  }

  __getColors() {
    const colors = {};

    colors.background =
      this.__hover || this.preview ? this.model.color : this.model.fauxColor;

    if (this.preview) {
      colors.text = colorFlip(colors.background);
    } else {
      colors.text = this.__hover
        ? this.model.textColor
        : this.model.fauxTextColor;
    }

    return colors;
  }

  render() {
    const colors = this.__getColors();

    return html`
      <div
        id="${ELEMENTS.background.id}"
        class="background"
        style="background-color: ${colors.background};"
      ></div>

      <div
        id="${ELEMENTS.eventContainer.id}"
        class="container"
        style="border-left: 5px solid ${this.model.color};"
        @mouseenter="${this.__handlers.enter}"
        @mouseleave="${this.__handlers.leave}"
      >
        <div
          id="${ELEMENTS.eventInfoContainer.id}"
          class="container-event-info"
          style="color: ${colors.text};"
        >
          <div id="${ELEMENTS.title.id}" class="label-title">
            ${this.model.title}
          </div>
          <div id="${ELEMENTS.details.id}" class="label-details">
            ${this.model.details}
          </div>
        </div>
        ${this.__renderIcons()}
      </div>

      ${this.__renderResizeHandles()} ${this.__renderResizeBlockers()}
    `;
  }
}

customElements.define('neb-calendar-event', NebCalendarEvent);
