import '../inputs/neb-textfield';

import { isRequired } from '@neb/form-validators';
import { html, LitElement } from 'lit';

import { baseStyles, layoutStyles } from '../../../../../src/styles';

export const ELEMENTS = {
  textfieldFirst: { id: 'textfield-first' },
  textfieldLast: { id: 'textfield-last' },
  textfieldMiddle: { id: 'textfield-middle' },
  textfieldPreferred: { id: 'textfield-preferred' },
  textfieldSuffix: { id: 'textfield-suffix' },
};

export default class Name extends LitElement {
  static get properties() {
    return {
      name: String,
      model: Object,
      errors: Object,
      helpers: Object,
      disabled: {
        reflect: true,
        type: Boolean,
      },
      showSuffix: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [baseStyles, layoutStyles];
  }

  static createModel() {
    return {
      first: '',
      last: '',
      middle: '',
      preferred: '',
      suffix: '',
    };
  }

  static createSelectors(keys = ['first', 'last']) {
    const children = keys.reduce(
      (accum, curr) => ({
        ...accum,
        [curr]: [isRequired()],
      }),
      {},
    );

    return { children };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.disabled = false;
    this.showSuffix = false;
    this.name = '';
    this.model = Name.createModel();
    this.errors = Name.createModel();
    this.helpers = Name.createModel();

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e =>
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
    };
  }

  __renderSuffix() {
    return this.showSuffix
      ? html`
          <neb-textfield
            id="${ELEMENTS.textfieldSuffix.id}"
            name="suffix"
            label="Suffix"
            .error="${this.errors.suffix}"
            .helper="${this.helpers.suffix || ' '}"
            .value="${this.model.suffix}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.disabled}"
          ></neb-textfield>
        `
      : '';
  }

  render() {
    return html`
      <div class="grid grid-lean">
        <div class="grid grid-lean grid-2">
          <neb-textfield
            id="${ELEMENTS.textfieldFirst.id}"
            name="first"
            label="First Name"
            .error="${this.errors.first}"
            .helper="${this.helpers.first || ' '}"
            .value="${this.model.first}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.disabled}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textfieldLast.id}"
            name="last"
            label="Last Name"
            .error="${this.errors.last}"
            .helper="${this.helpers.last || ' '}"
            .value="${this.model.last}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.disabled}"
          ></neb-textfield>
        </div>

        <div class="grid grid-lean grid-2">
          <neb-textfield
            id="${ELEMENTS.textfieldMiddle.id}"
            name="middle"
            label="Middle Name"
            .error="${this.errors.middle}"
            .helper="${this.helpers.middle || ' '}"
            .value="${this.model.middle}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.disabled}"
          ></neb-textfield>

          ${this.__renderSuffix()}
        </div>

        <div class="grid grid-lean">
          <neb-textfield
            id="${ELEMENTS.textfieldPreferred.id}"
            name="preferred"
            label="Preferred Name"
            .error="${this.errors.preferred}"
            .helper="${this.helpers.preferred || ' '}"
            .value="${this.model.preferred}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.disabled}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }
}

window.customElements.define('neb-name', Name);
