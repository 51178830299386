import moment from 'moment-timezone';

import printUpcomingAppointments from '../../../src/api-clients/pdf/print-upcoming-appointments';
import { getAppointments } from '../../neb-api-client/src/appointment-api-client';
import { getActiveProviderUsers } from '../../neb-api-client/src/practice-users-api-client';
import { computeTime } from '../../neb-input/nebFormatUtils';
import { parseDate } from '../date-util';
import { HIDE_PREFERRED_OPTS, objToName } from '../formatters';
import { printPdf } from '../neb-pdf-print-util';

const DT_REQ_FORMAT = 'YYYY-MM-DDTHH:mm';
const DT_PDF_FORMAT = 'M/D/YYYY h:mm A';

const getUpcomingAppointments = (patientId, limit, location = null) => {
  const params = {
    patientId,
    sortField: 'start',
    sortDir: 'asc',
    secondarySortField: 'start',
    secondarySortDir: 'asc',
    limit,
    expand: 'appointmentType',
    status: ['Active'],
    start: parseDate().format(DT_REQ_FORMAT),
    ...(location ? { locationId: location.id } : {}),
  };
  return getAppointments(params);
};

const getProviderName = (providerId, providers) => {
  const { name } = providers.find(({ id }) => providerId === id);
  return objToName(name, HIDE_PREFERRED_OPTS);
};

const getAppointmentsInfo = (upcomingAppts, locations, providers) =>
  upcomingAppts.map(appt => {
    const {
      end,
      start,
      locationId,
      appointmentType: { name: appointmentType },
      providerId,
    } = appt;

    const duration = computeTime(moment(end).diff(moment(start)));
    const { name: locationName } = locations.find(
      ({ id }) => id === locationId,
    );

    const formattedTime = parseDate(moment(start)).format(DT_PDF_FORMAT);
    const providerName = providerId
      ? getProviderName(providerId, providers)
      : '';

    return {
      appointmentType,
      providerName,
      duration,
      locationId,
      locationName,
      formattedTime,
    };
  });

const getPracticeInformation = ({ id, name, hideLogo }) => ({
  id,
  name,
  hideLogo,
});

const getAppointmentLocations = (apptsInfo, locations) => {
  const appointmentLocationIds = [
    ...new Set(apptsInfo.map(({ locationId }) => locationId)),
  ];
  return locations
    .filter(({ id }) => appointmentLocationIds.includes(id))
    .sort(({ name: a }, { name: b }) => a.localeCompare(b))
    .map(
      ({
        id,
        name,
        phoneNumber,
        emailAddress,
        address1,
        address2,
        city,
        state,
        zipCode,
      }) => ({
        id,
        name,
        phoneNumber,
        emailAddress,
        address1,
        address2,
        city,
        state,
        zipCode,
      }),
    );
};

export default async ({ patient, practiceInfo, limit = 20 }) => {
  const { id: patientId, name } = patient;
  const { locations: practiceLocations } = practiceInfo;
  const {
    data: upcomingAppointments,
    count: totalAppointments,
  } = await getUpcomingAppointments(
    patientId,
    limit,
    practiceInfo.selectedLocation,
  );

  const providers = await getActiveProviderUsers();
  const appointmentsInfo = getAppointmentsInfo(
    upcomingAppointments,
    practiceLocations,
    providers,
  );

  const locations = getAppointmentLocations(
    appointmentsInfo,
    practiceLocations,
  );

  const patientName = objToName(name, HIDE_PREFERRED_OPTS);

  const remainingAppointments =
    totalAppointments - limit > 0 ? totalAppointments - limit : 0;

  const practiceInformation = getPracticeInformation(practiceInfo);

  const params = {
    appointmentsInfo,
    practiceInformation,
    patientName,
    totalAppointments,
    remainingAppointments,
    locations,
  };

  return printPdf(printUpcomingAppointments(params));
};
