import { css, html, LitElement } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import '../../../../../src/components/misc/neb-icon';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../neb-styles/neb-variables';

import { generateNodePreview, getType, isArray, isPrimitive } from './utils';

export const ELEMENTS = {
  previews: { selector: '.preview' },
  keys: { selector: '.key' },
  icons: { selector: '.icon' },
  chevrons: { selector: '.chevron' },
  reprocessMessage: { id: 'reprocess-message' },
  selectedNode: { id: 'selected-node' },
};

export const REPROCESS_ERA_MESSAGE = 'Reprocess ERA to see data.';

class NebTreeView extends LitElement {
  static get properties() {
    return {
      expandedDict: Object,
      selectedKey: String,
      data: Object,
      icons: Object,
      showNodePreview: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .preview {
          color: rgba(222, 175, 143, 0.9);
        }

        .chevron {
          color: #6fb3d2;
          display: inline-block;
        }

        .collapsable:before {
          display: inline-block;
          font-size: 0.8em;
          content: '▶';
          line-height: 1em;
          width: 1em;
          height: 1em;
          text-align: center;

          transition: transform 195ms ease-out;
          transform: rotate(90deg);
          color: #6fb3d2;
        }
        .array > li {
          margin-left: 40px;
        }

        .collapsable.collapsableCollapsed:before {
          transform: rotate(0);
        }

        .collapsable {
          cursor: pointer;
          user-select: none;
        }

        ul {
          padding: 0;
          clear: both;
        }

        ul,
        li {
          list-style: none;
          position: relative;
        }

        div > ul {
          margin-left: 50px;
        }
        li ul > li {
          position: relative;
          margin-left: 20px;
          padding-left: 0px;
        }

        ul ul:before {
          content: '';
          border-left: 1px solid #333;
          position: absolute;
          left: calc(0.5em - 1px);
          top: 0.3em;
          bottom: 0.3em;
        }

        ul ul:hover:before {
          border-left: 1px solid #666;
        }

        .node-container {
          display: flex;
          gap: 5px;
        }

        .key-container {
          display: flex;
          gap: 5px;
          padding: 1px 5px;
        }

        .icon {
          height: 20px;
          width: 20px;
        }

        .key,
        .icon {
          cursor: pointer;
        }

        div[selected] {
          background-color: ${CSS_COLOR_HIGHLIGHT};
          color: ${CSS_COLOR_WHITE};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .icon[selected] {
          fill: ${CSS_COLOR_WHITE};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.expandedDict = {};
    this.selectedKey = '';
    this.data = {};
    this.icons = {};
    this.showNodePreview = true;

    this.onToggleNode = () => {};

    this.onSelectKey = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      toggleNode: path => {
        this.onToggleNode(path);
      },
      selectIcon: ({ key, nodePath }) => {
        this.onSelectKey({ key, nodePath });
      },
      selectKey: ({ key, nodePath }) => {
        this.onSelectKey({ key, nodePath });
      },
    };
  }

  update(changedProps) {
    if (changedProps.has('data') && Object.keys(this.data).length) {
      const key = Object.keys(this.data)[0];

      this.__handlers.selectKey({ key, nodePath: key });

      if (!Object.keys(this.expandedDict).length) {
        this.__handlers.toggleNode(key);
      }
    }
    return super.update(changedProps);
  }

  updated() {
    const element = this.shadowRoot.getElementById(ELEMENTS.selectedNode.id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }

  __preventPropagation(e, callback = () => {}, params = {}) {
    e.preventDefault();

    return callback(params);
  }

  __renderNodePreview(node) {
    return this.showNodePreview
      ? html`
          <span part="preview" class="preview">
            ${generateNodePreview(node)}
          </span>
        `
      : '';
  }

  __renderPrimitive(key, path) {
    const nodeType = getType(key);

    return html`
      <span
        part="primitive primitive-${nodeType}"
        class="key"
        tabindex="0"
        @click="${
          e =>
            this.__preventPropagation(e, this.__handlers.selectKey, {
              key,
              nodePath: path,
            })
        }"
        ?selected="${key === this.selectedKey}"
        >${key}</span
      >
    `;
  }

  __hasData() {
    return Object.keys(this.data).length;
  }

  __renderNode(node, path = '') {
    if (!this.__hasData()) {
      return html`
        <div id="${ELEMENTS.reprocessMessage.id}">
          <p>${REPROCESS_ERA_MESSAGE}</p>
        </div>
      `;
    }

    const primitive = isPrimitive(node);
    const isExpanded = !path || !!this.expandedDict[path] || primitive;

    if (!isExpanded) return this.__renderNodePreview(node);

    if (primitive) return this.__renderPrimitive(node, path);

    if (isArray(node)) return this.__renderArray(node, path);

    return this.__renderObject(node, path);
  }

  __renderIconSpacer() {
    return html`
      <div class="icon"></div>
    `;
  }

  __renderLineItemIconSpacer() {
    return html`
      <div style="margin-left: 10px;"></div>
    `;
  }

  __renderIcon({ key, nodePath }) {
    const { css: iconStyle, icon, replaceChevron = false } = this.icons[key];
    const isSelected = key === this.selectedKey;

    return html`
      <neb-icon
        style="${isSelected ? '' : iconStyle}"
        name="${key}"
        class="icon"
        .icon="${icon}"
        ?expanded="${this.expandedDict[nodePath]}"
        ?selected="${isSelected}"
        key="SPECIAL.expand"
        @click="${
          e =>
            this.__preventPropagation(
              e,
              replaceChevron
                ? this.__handlers.toggleNode(nodePath)
                : this.__handlers.selectIcon({ e, key, nodePath }),
            )
        }"
      ></neb-icon>
    `;
  }

  __renderKeyIcon({ key, nodePath }) {
    return this.icons[key] && !this.icons[key].replaceChevron
      ? this.__renderIcon({ key, nodePath })
      : this.__renderIconSpacer();
  }

  __renderChevron({ key, nodePath, nodeData }) {
    return this.icons[key] && this.icons[key].replaceChevron
      ? this.__renderIcon({ key, nodePath })
      : html`
          <span
            part="key"
            class="${
              classMap({
                collapsable: !isPrimitive(nodeData),
                collapsableCollapsed: !this.expandedDict[nodePath],
                chevron: true,
              })
            }"
            @click="${
              e =>
                this.__preventPropagation(
                  e,
                  this.__handlers.toggleNode(nodePath),
                )
            }"
          >
          </span>
        `;
  }

  __renderObject(node, path = '') {
    return html`
      <ul part="object">
        ${
          Object.keys(node).map(key => {
            const header = key.split('/');
            const label =
              header.length === 1
                ? key
                : header.slice(0, header.length - 1).join('/');

            const nodeData = node[key];
            const nodePath = path ? `${path}.${key}` : key;

            const params = { key, nodePath, nodeData };

            return html`
              <li part="property" data-path="${nodePath}">
                <div class="node-container">
                  ${
                    header.length > 2
                      ? this.__renderLineItemIconSpacer()
                      : this.__renderChevron(params)
                  }
                  <div class="key-container"
                  ?selected="${key === this.selectedKey}">
                    ${this.__renderKeyIcon(params)}
                    <span
                    class="key"
                    id="${
                      key === this.selectedKey ? ELEMENTS.selectedNode.id : ''
                    }"
                    @click="${e =>
                      this.__preventPropagation(
                        e,
                        this.__handlers.selectKey,
                        params,
                      )}"
                    > ${label} <span>
                  </div>
                </div>
                ${
                  header.length > 2 ? '' : this.__renderNode(nodeData, nodePath)
                }
              </li>`;
          })
        }
      </ul>
    `;
  }

  __renderArray(node, path = '') {
    return html`
      <ul class="array" part="array">
        ${
          node.map(key => {
            const nodePath = path ? `${path}.${key}` : key;

            return html`
              <li part="property" data-path="${nodePath}">
                <div
                  class="key-container"
                  ?selected="${key === this.selectedKey}"
                >
                  ${this.__renderKeyIcon({ key, nodePath })}
                  ${this.__renderPrimitive(key, nodePath)}
                </div>
              </li>
            `;
          })
        }
      </ul>
    `;
  }

  render() {
    return html`
      ${this.__renderNode(this.data)}
    `;
  }
}

customElements.define('neb-tree-view', NebTreeView);
