import '../../../../neb-material-design/src/components/neb-md-textfield';
import '../neb-profile-photo-upload';
import '../controls/neb-tab-group';

import { html, css } from 'lit';

import {
  getTabWithError,
  map,
  arrayToObject,
} from '../../../../neb-utils/utils';
import NebProfile from '../field-groups/neb-profile';
import NebPermissions from '../permissions/neb-permissions';

import Form, { ELEMENTS as BASE_ELEMENTS } from './neb-form';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  permissions: {
    id: 'permissions',
  },
  profile: {
    id: 'profile',
  },
  tabGroup: {
    id: 'tab-group',
  },
};

export default class NebUserStaffForm extends Form {
  static get properties() {
    return {
      __selectedTab: Boolean,
      locations: Array,
      activeLocations: Array,
    };
  }

  static createModel() {
    return {
      ...NebProfile.createModel(),
      ...NebPermissions.createModel(),
    };
  }

  createSelectors() {
    return {
      children: {
        ...NebProfile.createSelectors({ locations: this.activeLocations }),
        ...NebPermissions.createSelectors(this.locations),
      },
    };
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      selectTab: tab => {
        this.__selectedTab = tab;
      },
      save: () => {
        if (this.formService.validate()) {
          const rawModel = this.formService.build();
          const model = map(rawModel, (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          this.__saving = true;
          this.onSave(model);
          this.__saving = false;
        } else {
          this.__selectedTab = getTabWithError(
            arrayToObject(this.__navItems),
            this.errors,
            this.__selectedTab,
          );
        }
      },
    };
  }

  initState() {
    super.initState();
    this.__navItems = [
      {
        id: 'profile',
        label: 'Profile',
        renderer: () => this.__renderProfile(),
        fields: ['name', 'phones', 'email'],
      },
      {
        id: 'permissions',
        label: 'Permissions & Rights',
        renderer: () => this.__renderPermissionsAndRights(),
        fields: ['superUser', 'permissions', 'locations'],
      },
    ];

    this.__selectedTab = 'profile';
    this.confirmLabel = 'Save Staff';
    this.activeLocations = [];
    this.locations = [];
  }

  static get styles() {
    return [
      super.styles,
      css`
        .layout {
          gap: 0;
          overflow-y: hidden;
          grid-template-rows: 45px auto;
        }

        .form {
          padding: 0;
        }

        .tabs {
          flex: 0 0 auto;
          padding-top: 12px;
        }

        .tab-content {
          flex: 1 0 0;
          overflow: auto;
        }
      `,
    ];
  }

  __renderProfile() {
    return html`
      <neb-profile
        id="${ELEMENTS.profile.id}"
        class="tab-content"
        name="profile"
        .model="${this.state}"
        .locations="${this.activeLocations}"
        .onSave="${this.handlers.save}"
        .onChange="${this.handlers.change}"
        .onAdd="${this.handlers.addItem}"
        .onRemove="${this.handlers.removeItem}"
        .onCancel="${this.handlers.cancel}"
        .errors="${this.errors}"
        .disableEmail="${this.model.email}"
        .layout="${this.layout}"
      ></neb-profile>
    `;
  }

  __renderPermissionsAndRights() {
    return html`
      <neb-permissions
        id="${ELEMENTS.permissions.id}"
        class="tab-content"
        name="permissions"
        .layout="${this.layout}"
        .model="${this.state}"
        .errors="${this.errors}"
        .locations="${this.locations}"
        .onChange="${this.handlers.change}"
      ></neb-permissions>
    `;
  }

  __renderTabContent() {
    const item = this.__navItems.find(i => i.id === this.__selectedTab);
    return item ? item.renderer() : '';
  }

  renderContent() {
    return html`
      <neb-tab-group
        class="tabs"
        id="${ELEMENTS.tabGroup.id}"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
      ${this.__renderTabContent()}
    `;
  }
}
customElements.define('neb-form-user-staff', NebUserStaffForm);
