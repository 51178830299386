import '../neb-cell-fee-schedule';

import '../inputs/neb-select';

import '../field-groups/neb-modifiers';
import '../../../../neb-material-design/src/components/neb-md-select';
import { html, css } from 'lit';

import {
  getDiagnosisItemHeight,
  ITEM_MIN_WIDTH,
} from '../../../../../src/utils/diagnoses';
import validateAuthorization from '../../../../neb-api-client/src/services/encounter-charge/validate-authorization';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { centsToCurrency } from '../../../../neb-utils/formatters';
import { number } from '../../../../neb-utils/masks';
import { DEFAULT_TOGGLE_ICON } from '../neb-toggle-button';

import NebTableOld, { ELEMENTS as ELEMENTS_BASE } from './neb-table-old';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  textNoDiagnoses: { selector: '.text-no-diagnoses', text: 'No Encounter Dx' },
  modifiers: { selector: 'neb-modifiers' },
  units: { selector: '.units', tag: 'neb-textfield' },
  cost: { selector: '.cost' },
  feeSchedule: { selector: '.fee-schedule' },
  carePackage: { selector: '.care-package' },
  diagnosisDropdown: { selector: '.diagnosis-dropdown', tag: 'neb-select' },
};

const UNPOST_ICON = 'neb:return';
const POSTED_ICON = 'neb:checkmark';
const ALL_ADDED_LABEL = {
  UNPOST: 'Unpost All',
  POST: 'Post All',
  ALL_POSTED: 'All Posted',
};

class NebTableEncounterCharges extends NebTableOld {
  static get properties() {
    return {
      diagnoses: Array,
      dirty: Boolean,
      carePackageChargesId: Array,
      showCarePackage: Boolean,
      showCost: Boolean,
      isEncounterSigned: Boolean,
      authorizedProcedures: Array,
      chartingPermission: Boolean,
      hasAutoPostCharges: Boolean,
      checkedIn: Boolean,
      wrapModifiers: { type: Boolean, reflect: true },
    };
  }

  initState() {
    super.initState();

    this.__config = this.__buildTableConfig();
    this.hasAutoPostCharges = false;
    this.__initAllPosted = false;

    this.isEncounterSigned = false;
    this.writable = true;
    this.showRemoveButton = true;
    this.showRemoveAllButton = true;
    this.reorder = true;
    this.addAllLabel = ALL_ADDED_LABEL.POST;
    this.addLabel = 'Post';
    this.lookupKey = 'chargeId';
    this.diagnoses = [];
    this.dirty = false;
    this.carePackageChargesId = [];
    this.showCost = false;
    this.authorizedProcedures = null;
    this.chartingPermission = null;
    this.addAllAlwaysEnabled = true;
    this.checkedIn = false;
    this.wrapModifiers = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      click: (_, rowIndex) => this.postOrUnpost(rowIndex),
      addAll: async () => {
        const unpostedCharges = this.model.filter(({ posted }) => !posted);
        const auth = await this.__validateAuthorization(unpostedCharges);
        if (!auth) return null;

        return this.onAddAll(this.model);
      },
      resize: () => {
        const windowSize = window.innerWidth;

        if (!this.checkedIn) {
          this.wrapModifiers = windowSize < 1024;
        }

        if (this.checkedIn) {
          this.wrapModifiers = windowSize < 1200;
        }

        this.__config = this.__buildTableConfig();

        return this.wrapModifiers;
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener('resize', this.handlers.resize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    window.removeEventListener('resize', this.handlers.resize);
  }

  __validateAuthorization(charges) {
    return this.authorizedProcedures && charges.length
      ? validateAuthorization(this.authorizedProcedures, charges)
      : true;
  }

  async postOrUnpost(rowIndex) {
    const { posted } = this.model[rowIndex];
    const auth =
      posted || (await this.__validateAuthorization([this.model[rowIndex]]));
    if (!auth) return null;

    return this.onChange({
      name: `${this.name}.${rowIndex}.posted`,
      value: !posted,
    });
  }

  __defaultTableConfig() {
    return [
      [
        {
          key: 'procedure',
          label: 'Procedure',
          flex: css`0 0 65px`,
          mobile: true,
        },
        {
          key: 'description',
          label: 'Description',
          flex: css`1 0 65px`,
          mobile: true,
        },
        this.checkedIn
          ? {
              key: 'modifiers',
              label: 'Modifiers',
              flex: this.wrapModifiers ? css`0 0 120px` : css`0 0 225px`,
            }
          : {
              key: 'modifiers',
              label: 'Modifiers',
              flex: this.wrapModifiers ? css`0 0 120px` : css`0 0 205px`,
            },
        {
          key: 'units',
          label: 'Units',
          flex: css`1 0 40px`,
        },
      ],
      [
        {
          key: 'billedAmount',
          label: 'Billed',
          flex: css`1 0 60px`,
          formatter: v => centsToCurrency(v),
        },
        {
          key: 'feeScheduleName',
          label: 'Fee Schedule',
          flex: css`1 0 70px`,
        },
      ],
      [
        {
          key: 'diagnosisPointers',
          label: 'Diagnosis Pointer',
          flex: css`1 0 100px`,
        },
      ],
    ];
  }

  __buildTableConfig() {
    let config = this.__defaultTableConfig(this.wrapModifiers)[0];

    if (this.showCost) {
      config = [
        ...config,
        {
          key: 'cost',
          label: 'Unit Chrg',
          flex: css`1 0 80px`,
        },
      ];
    }
    config = [...config, ...this.__defaultTableConfig()[1]];

    if (this.showCarePackage) {
      config = [
        ...config,
        {
          key: 'carePackage',
          label: 'Care Pkg',
          flex: css`1 0 30px`,
        },
      ];
    }

    return [...config, ...this.__defaultTableConfig()[2]];
  }

  __checkReloadTable(changedProps) {
    const reloadProps = ['layout', 'model', 'hasAutoPostCharges'];
    return reloadProps.some(prop => changedProps.has(prop));
  }

  __getAddAllLabel() {
    if (!this.allAdded) return ALL_ADDED_LABEL.POST;
    return this.hasAutoPostCharges
      ? ALL_ADDED_LABEL.ALL_POSTED
      : ALL_ADDED_LABEL.UNPOST;
  }

  firstUpdated() {
    this.handlers.resize();
  }

  update(changedProps) {
    if (this.__checkReloadTable(changedProps)) {
      this.__config = this.__buildTableConfig();

      const allPosted = this.model.every(m => m.posted);

      if (!this.dirty) {
        this.__initAllPosted = allPosted;
      }

      this.allAdded =
        this.model.length && !(this.hasAutoPostCharges && this.__initAllPosted)
          ? allPosted
          : null;

      this.addAllAlwaysEnabled = !this.hasAutoPostCharges;

      this.addAllLabel = this.__getAddAllLabel();

      this.addAllToggledIcon =
        allPosted && !this.hasAutoPostCharges
          ? UNPOST_ICON
          : DEFAULT_TOGGLE_ICON;

      this.showRemoveAllButton = !!this.model.length;
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          padding-top: ${CSS_SPACING};
        }

        :host([allAdded]) {
          --custom-add-all-column-width: 127px;
          --add-button-column-width: 127px;
        }

        :host(:not([allAdded])) {
          --custom-add-all-column-width: 127px;
          --add-button-column-width: 127px;
        }

        :host(:not([wrapModifiers])) .modifiers {
          --modifier-columns: repeat(4, 50.4px);
        }

        :host([wrapModifiers]) .modifiers {
          --modifier-columns: repeat(2, 50.4px);
        }

        .description {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }

        :host(:not([layout='small'])) .content {
          padding: 0 ${CSS_SPACING};
        }

        .cell {
          align-items: center;
          white-space: unset;
        }

        .units {
          --text-indent: 8px;
          width: 50px;
        }

        .text-no-diagnoses {
          font-size: 14px;
        }

        .button-add {
          display: flex;
        }

        .icon-drag,
        .icon-remove {
          min-width: ${CSS_SPACING};
        }
      `,
    ];
  }

  __getButtonText(posted) {
    if (!posted) return 'Post';
    return this.hasAutoPostCharges ? 'Posted' : 'Unpost';
  }

  get __postedButtonIcon() {
    return this.hasAutoPostCharges ? POSTED_ICON : UNPOST_ICON;
  }

  __isButtonDisabled(posted) {
    if (!posted) return false;
    return this.hasAutoPostCharges;
  }

  get __hideAddAllButtons() {
    return !this.model.length || this.allAdded === null;
  }

  renderAddButton(item, rowIndex) {
    return !this.__hideAddAllButtons
      ? html`
          <neb-toggle-button
            class="button-add"
            buttonText="${this.__getButtonText(item.posted)}"
            .isToggled="${!!item.posted}"
            .index="${rowIndex}"
            .canToggle="${true}"
            .toggledIcon="${this.__postedButtonIcon}"
            .onClick="${this.handlers.click}"
            .disabled="${this.__isButtonDisabled(item.posted)}"
          ></neb-toggle-button>
        `
      : '';
  }

  renderCell(value, key, rowIndex) {
    const item = this.model[rowIndex];
    const name = `${rowIndex}.${key}`;
    const error = key === 'button' ? null : this.errors[rowIndex][key];
    const disabled = this.isEncounterSigned && !this.chartingPermission;

    switch (key) {
      case 'procedure':
        return html`
          <div class="procedure" name="${name}">${value}</div>
        `;

      case 'description':
        return html`
          <div class="description" name="${name}">${value}</div>
        `;

      case 'modifiers':
        return html`
          <neb-modifiers
            class="modifiers"
            label=" "
            helper=" "
            name="${name}"
            .errors="${error}"
            .values="${value}"
            .onChange="${this.handlers.change}"
            ?disabled="${disabled}"
          ></neb-modifiers>
        `;

      case 'units':
        return html`
          <neb-textfield
            class="units"
            name="${name}"
            helper=" "
            maxLength="3"
            label=" "
            .inputMode="${'numeric'}"
            .error="${error}"
            .value="${value}"
            .mask="${number}"
            .onChange="${this.handlers.change}"
            ?disabled="${disabled}"
          ></neb-textfield>
        `;

      case 'cost':
        return html`
          <div class="cost" name="${name}">
            ${centsToCurrency(item.unitCharge)}
          </div>
        `;

      case 'feeScheduleName':
        return html`
          <neb-cell-fee-schedule
            class="fee-schedule"
            .model="${item}"
          ></neb-cell-fee-schedule>
        `;

      case 'carePackage':
        return html`
          <div class="care-package" name="${name}">
            ${this.__isCoveredByCarePkg(item.chargeId)}
          </div>
        `;

      case 'diagnosisPointers':
        return this.diagnoses.length
          ? html`
              <neb-select
                class="diagnosis-dropdown"
                maxSelection="4"
                label=" "
                helper=" "
                .name="${name}"
                .error="${error}"
                .value="${value}"
                .items="${this.diagnoses}"
                .onChange="${this.handlers.change}"
                forceAlignMenu="right"
                ?disabled="${disabled}"
                placeholder="Select"
                multiSelect
                showFullText
                wrapText
                itemHeight="${getDiagnosisItemHeight(this.diagnoses)}"
                itemMinWidth="${ITEM_MIN_WIDTH}"
                .forceDown="${true}"
              ></neb-select>
            `
          : html`
              <span class="text-no-diagnoses"
                >${ELEMENTS.textNoDiagnoses.text}</span
              >
            `;

      default:
        return value;
    }
  }

  __isCoveredByCarePkg(chargeId) {
    if (!this.carePackageChargesId.length) return '';
    return this.carePackageChargesId.includes(chargeId) ? 'Y' : 'N';
  }

  shouldEnableRemoveButton() {
    if (this.chartingPermission !== null) {
      return (
        (!this.isEncounterSigned && this.chartingPermission) ||
        this.chartingPermission
      );
    }
    return !this.isEncounterSigned;
  }

  shouldEnableRemoveAllButton() {
    return this.shouldEnableRemoveButton();
  }
}
customElements.define('neb-table-encounter-charges', NebTableEncounterCharges);
