import { store } from '../../neb-redux/neb-redux-store';
import { parseDate } from '../../neb-utils/date-util';
import { buildPracticeInformation } from '../../neb-utils/practice-information-util';

import { getBillingInfo } from './billing-information-api-client';
import * as patientApiClient from './patient-api-client';
import ApiClient, { Method } from './utils/api-client-utils';
import {
  buildPaymentsBody,
  buildReceiptBody,
  getPatientName,
} from './utils/patient-payment-utils';
import { strFromQuery } from './utils/query-params';

export const apiClient = new ApiClient({ microservice: 'billing' });
export const apiClientPDF = new ApiClient({
  microservice: 'pdf',
  useTenant: true,
});

const BASE_PATH = 'payments';
const ERA_PATH = 'era-payments';

const buildPaymentInfo = (p, patientName) => ({
  allocations: [],
  ...p,
  transactionDate: parseDate(p.transactionDate),
  patientName,
  payerName: p.payerPlan ? p.payerPlan.payerName : patientName,
});

export const formatPayment = async p => {
  let patient;

  if (p.patientId) {
    patient = await patientApiClient.fetchOne(p.patientId, true, true);
  }

  const patientName = patient ? getPatientName(patient) : '';

  return buildPaymentInfo(p, patientName);
};

export const formatPayments = async payments => {
  const patientIds = payments.map(p => p.patientId).filter(a => a);

  let patientsDict = {};

  if (patientIds.length) {
    const uniqueIds = [...new Set(patientIds)];
    const patients = await patientApiClient.fetchSome(
      uniqueIds,
      {},
      true,
      false,
    );

    patientsDict = patients.reduce((memo, patient) => {
      memo[patient.id] = getPatientName(patient);

      return memo;
    }, {});
  }

  return payments.map(p => {
    const patientName = patientsDict[p.patientId] || '';

    return buildPaymentInfo(p, patientName);
  });
};

export const getPaymentDetail = async (id, optOutLoadingIndicator = false) => {
  const result = await apiClient.makeRequest({
    path: `${BASE_PATH}/${id}`,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 5,
    cacheKey: id,
    optOutLoadingIndicator,
  });

  return formatPayment(result.data[0]);
};

export const updatePayment = async (id, data) => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/${id}`,
    method: Method.PUT,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 6,
    cacheKey: id,
  });

  return formatPayment(result.data[0]);
};

export const updatePaymentLocation = (id, locationId) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: true,
    path: `${BASE_PATH}/${id}/location`,
    method: Method.PUT,
    body: JSON.stringify({ locationId }),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

export const updateERAPayment = async (id, data) => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${ERA_PATH}/${id}`,
    method: Method.PUT,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    cacheKey: id,
  });

  return formatPayment(result.data[0]);
};

export const postERAPayment = async data => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: ERA_PATH,
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 2,
  });

  return formatPayment(result.data[0]);
};

export const postPayment = async data => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: BASE_PATH,
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 5,
    updateNotificationDetails: { payment: { patientId: data.patientId } },
  });

  return formatPayment(result.data[0]);
};

export const voidPayment = async data => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'void-payments',
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 6,
    cacheKey: data.paymentId,
  });

  return formatPayment(result.data[0]);
};

export const refundPayment = async data => {
  const result = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'refund-payments',
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 6,
    cacheKey: data.paymentId,
  });

  return formatPayment(result.data[0]);
};

export function getPaymentsHeaders(query) {
  return {
    'Content-Type': 'application/json',
    ...(query.statuses && { statuses: query.statuses }),
    ...(query.types && { types: query.types }),
    ...(query.payerIds && { payerIds: query.payerIds }),
    ...(query.paymentTypes && { paymentTypes: query.paymentTypes }),
    ...(query.paymentMethods && { paymentMethods: query.paymentMethods }),
  };
}

export const getPayments = async (
  patientId,
  queryParams = {},
  optOutLoadingIndicator = false,
) => {
  let queryParameters = queryParams;

  if (queryParams.sortParams) {
    const { key, dir } = queryParams.sortParams;
    queryParameters = { ...queryParams, sortField: key, sortDir: dir };
    delete queryParameters.sortParams;
  }

  const route = patientId
    ? `patients/${patientId}/${BASE_PATH}`
    : `${BASE_PATH}`;

  const path = [route, strFromQuery(queryParameters)]
    .filter(item => item)
    .join('?');

  const { data, count, totals } = await apiClient.makeRequest({
    path,
    headers: getPaymentsHeaders(queryParams),
    cacheKey: `payments-${patientId && `${patientId}-`}${JSON.stringify(
      queryParams,
    )}`,
    version: patientId ? 5 : 1,
    optOutLoadingIndicator,
  });

  return {
    count,
    data: await formatPayments(data),
    totals,
  };
};

export const getUnallocatedPayments = async body => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'payments/open-payments',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    version: 1,
  });

  return formatPayments(res.data);
};

export const getPaymentByElectronicPaymentId = async electronicPaymentId => {
  const payment = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/electronic/${electronicPaymentId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });

  return payment;
};

export const getUniquePatientsFromPatientPayments = async patientPayments => {
  const uniquePatientIds = [...new Set(patientPayments.map(p => p.patientId))];

  const patients = await patientApiClient.fetchSome(
    uniquePatientIds,
    {},
    true,
    true,
  );

  return patients;
};

export const printReceipts = async ({ patientPayments }) => {
  const body = await buildReceiptBody({
    practiceInformation: store.getState().practiceInformation.item,
    patientPayments,
    billingInfo: await getBillingInfo(),
  });

  const response = await apiClientPDF.makeRequest({
    optOutLoadingIndicator: false,
    path: 'receipts',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    version: 3,
  });

  return response.buffer;
};

export const printReceipt = async ({
  patientPayments,
  selectedLocation = null,
}) => {
  const billingInfo = await getBillingInfo();
  const currentState = store.getState();

  const body = await buildPaymentsBody({
    patientPayments,
    actionPayment: {},
    state: currentState,
    billingInfo,
  });

  if (selectedLocation) {
    const practiceInformation = buildPracticeInformation({
      selectedLocation,
      practiceInformation: currentState.practiceInformation.item,
    });

    body.practiceInformation = practiceInformation;
    body.patientPayments = body.patientPayments.map(payment => ({
      ...payment,
      locationId: selectedLocation.id,
    }));

    body.hideLogo = practiceInformation.hideLogo || false;
  }

  const response = await apiClientPDF.makeRequest({
    optOutLoadingIndicator: false,
    path: 'receipts',
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    version: 2,
  });

  return response.buffer;
};

export const emailPatientPaymentReceipt = async ({
  paymentDetails,
  email,
  patients,
  optOutLoadingIndicator = false,
  locationId,
}) => {
  const storeInfo = store.getState();
  const res = await apiClient.makeRequest({
    path: 'payment-receipt',
    responseType: 'None',
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.POST,
    body: JSON.stringify({
      paymentDetails: await buildPaymentsBody({
        patientPayments: [paymentDetails],
        actionPayment: {},
        state: { ...storeInfo },
        billingInfo: {},
        patients,
      }),
      email,
      ...(locationId && { locationId }),
    }),
    version: 1,
    optOutLoadingIndicator,
  });
  return res;
};

export const postPatientPaymentForEncounter = async (
  data,
  patientId,
  encounterId,
  optOutLoadingIndicator = false,
) => {
  const result = await apiClient.makeRequest({
    path: `patients/${patientId}/encounters/${encounterId}/payments`,
    method: Method.POST,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
    updateNotificationDetails: { payment: { patientId } },
  });

  return formatPayment(result.data[0]);
};

export const getPatientPaymentsForEncounter = async (
  patientId,
  encounterId,
  optOutLoadingIndicator = false,
) => {
  const result = await apiClient.makeRequest({
    path: `patients/${patientId}/encounters/${encounterId}/payments`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
    optOutLoadingIndicator,
  });

  return result;
};

function mapSplitsToRaw(data) {
  return {
    splits: data.splits.map(s => ({
      ...s,
      patientId: s.patientId || null,
      paymentId: s.id || null,
      payerPlanId: s.payerPlanId || null,
    })),
    paymentId: data.paymentId,
    claims: data.claims,
  };
}

function mapSplitToModel(payment) {
  const preserve =
    (payment.progenitor && payment.preserve === 1) || !payment.preserve
      ? payment.allocated
      : payment.preserve;

  return {
    ...payment,
    payerPlanId: payment.payerPlanId || '',
    patientId: payment.patientId || '',
    preserve: preserve || 0,
  };
}

function mapHistoryToModel(history) {
  if (history && history.length) {
    return history.flatMap(historyRow =>
      historyRow.splitHistoryDetails.map(detail => ({
        ...detail,
        userId: historyRow.userId,
        createdAt: historyRow.createdAt,
      })),
    );
  }

  return [];
}

function mapSplitsToModel(splits) {
  return {
    parent: mapSplitToModel(splits.parent),
    children: splits.children.map(c => mapSplitToModel(c)),
    history: mapHistoryToModel(splits.history),
  };
}

export const splitPayment = async data => {
  const body = mapSplitsToRaw(data);

  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `payments/${data.paymentId}/split`,
    method: Method.PUT,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 2,
    cacheKey: data.paymentId,
  });

  return mapSplitsToModel(res);
};

export const fetchSplits = async (id, queryParams = {}) => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `payments/${id}/split`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 2,
    queryParams,
  });

  return res.children && res.children.length ? mapSplitsToModel(res) : res;
};

export const fetchParentPaymentById = async id => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `payments/${id}/split`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    version: 2,
  });

  return res.parent && mapSplitToModel(res.parent);
};

export const bulkUpdatePaymentAssociations = ({ body }) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${BASE_PATH}/payment-associations`,
    method: Method.PUT,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    version: 1,
  });
