import ApiClient, { Method } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'api' });
export const headerJSON = { 'Content-Type': 'application/json' };

export function update(items) {
  return apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: 'cancelRescheduleReasons',
    method: Method.PUT,
    headers: headerJSON,
    body: JSON.stringify(items),
  });
}

export async function fetchMany() {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: 'cancelRescheduleReasons',
    cacheKey: 'cancel-reschedule-reasons',
  });
  return res.data;
}
