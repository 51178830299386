import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'api' });

const baseUrl = 'tags';

export const fetchMany = async (optOutLoadingIndicator = false) => {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    path: baseUrl,
    method: Method.GET,
    optOutLoadingIndicator,
  });

  return res.data;
};

export const save = tag =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(tag),
    headers: {
      'Content-Type': 'application/json',
    },
    path: baseUrl,
    method: Method.POST,
  });

export const update = tag =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    body: JSON.stringify(tag),
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${baseUrl}/${tag.id}`,
    method: Method.PUT,
  });

export const remove = id =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${baseUrl}/${id}`,
    method: Method.DELETE,
  });

export const replace = (oldId, newId) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${baseUrl}/${oldId}/replace-tag/${newId}`,
    method: Method.PUT,
  });
