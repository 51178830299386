/* eslint-disable complexity */
import '../../../../../../src/components/tables/claims-worklist/neb-table-claims-worklist-needs-attention';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import {
  ASSIGNED_TO,
  NebClaimsWorklistFilters,
} from '../../../../../../src/components/filters/neb-filters-claims-worklist';
import {
  CLAIM_NOT_REFRESHED_MESSAGE,
  CLAIM_REFRESH_SUCCESS_MESSAGE,
  REFRESH_AND_SUBMIT_CLAIMS_ERROR,
  REFRESH_CLAIMS_ERROR,
  generateInternalErrorMessage,
  generateValidationErrorMessage,
  successfulGenerateBatch,
} from '../../../../../../src/utils/user-message';
import * as claimsApi from '../../../../../neb-api-client/src/claims';
import { getLedgerInvoiceItems } from '../../../../../neb-api-client/src/invoice-api-client';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { getProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import * as claimsValidationErrorsApi from '../../../../../neb-api-client/src/validation-errors-api-client';
import {
  openError,
  openSuccess,
  openWarning,
} from '../../../../../neb-dialog/neb-banner-state';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../../neb-styles/neb-variables';
import {
  CLAIM_STATUS,
  DESIGNATED_CLEARINGHOUSE_POPUP,
  EXCESS_CHARGE_POPUP,
  getPrintSettings,
} from '../../../../../neb-utils/claims';
import { BILLING_NOTE_TYPES } from '../../../../../neb-utils/constants';
import { parseDate } from '../../../../../neb-utils/date-util';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../../neb-utils/formatters';
import {
  fetchInsuranceItems,
  findDateOfService,
  formatDate,
} from '../../../../../neb-utils/neb-ledger-util';
import {
  getFileURL,
  showError,
  showLoading,
} from '../../../../../neb-utils/neb-pdf-print-util';
import {
  EMPTY_RESPONSE,
  FetchService,
  SORT_DIR,
} from '../../../../../neb-utils/services/fetch';
import { setValueByPath } from '../../../../../neb-utils/utils';
import { openOverlay, OVERLAY_KEYS } from '../../../utils/overlay-constants';
import {
  BULK_ACTION_OPTIONS,
  BULK_ACTION_OPTION_ID,
  openWorklistItemPopup,
  WORKLIST_ITEMS_POPUP_ACTIONS,
  verifyClaimsWithPopups,
  CLAIMS_NO_ITEMS_INITIAL_LOAD,
} from '../utils';

import {
  createFilterQueries,
  getTotalFiltersApplied,
} from './claims-controller-utils';

export const ELEMENTS = {
  table: { id: 'table' },
  filters: { id: 'filters' },
  pagination: { id: 'pagination' },
};

export const NEEDS_ATTENTION_CLAIM_STATUSES = [
  CLAIM_STATUS.NEEDS_ATTENTION,
  CLAIM_STATUS.ERROR,
  CLAIM_STATUS.VALIDATION_ERROR,
];

const BULK_ACTIONS_NO_CLAIMS_SELECTED_POPUP_TEXT =
  'Please select one or more claims before performing an action.';

const CLAIM_LIMIT = 100;

class ControllerNeedsAttention extends LitElement {
  static get properties() {
    return {
      __providers: Array,
      __model: Array,
      __state: Object,
      __sortParams: Object,
      __showHidden: Boolean,
      __totalFiltersApplied: Number,
      __selectedRows: Object,
      __selectedRowsForCurrentPage: Array,
      __showExpandedFilters: Boolean,
      __hasCollapsedFilters: Boolean,
      __hasDisableInitialLoadFF: Boolean,
      __hasAppliedFilters: Boolean,
      __hasRcmRelease2FF: Boolean,

      emptyMessage: String,
      patientId: String,
      userLocations: Array,
      defaultLocationId: String,
      refreshing: { type: Boolean },
      expandFlags: Object,
      hasMaxClear: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) {
          height: auto;
        }

        .pagination {
          padding: ${CSS_SPACING};
          justify-content: flex-end;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.__state = FetchService.createModel();
    this.__hasDisableInitialLoadFF = false;
    this.__hasAppliedFilters = false;
    this.__hasRcmRelease2FF = false;
    this.userLocations = [];
    this.defaultLocationId = '';
    this.__providers = [];
    this.__showHidden = false;
    this.__totalFiltersApplied = 0;
    this.__sortParams = {
      key: 'claimNumber',
      dir: SORT_DIR.ASC,
    };

    this.__selectedRows = { selectAll: false };
    this.__selectedRowsForCurrentPage = [];
    this.__showExpandedFilters = false;
    this.__hasCollapsedFilters = true;

    this.emptyMessage = '';
    this.patientId = '';
    this.refreshing = false;
    this.expandFlags = {};
    this.hasMaxClear = false;

    this.onRequestStatus = () => {};

    this.onTotalChange = () => {};

    this.onChangeClaimStatus = () => {};

    this.onSelect = () => {};

    this.onToggleExpand = () => {};

    this.onExpandAll = () => {};
  }

  get __checkedRowItems() {
    return this.__state.pageItems.filter(
      row => this.__selectedRows.selectAll || this.__selectedRows[row.id],
    );
  }

  get __pageItems() {
    return this.__state.pageItems || this.__model.pageItems || [];
  }

  get __expandFlagsForCurrentPage() {
    return this.__pageItems.map(claim => !!this.expandFlags[claim.id]);
  }

  get __selectedRowsIds() {
    return Object.keys(this.__selectedRows)
      .filter(key => key !== 'selectAll')
      .filter(key => this.__selectedRows[key]);
  }

  get __totalClaims() {
    return this.__state.filteredCount;
  }

  __initHandlers() {
    this.__handlers = {
      pageChanged: index => {
        this.__service.setPageIndex(index);
      },
      changeState: state => {
        this.__resetTable(state);
        this.__changeTotal(state);
      },
      change: e => {
        setValueByPath(this.__state.pageItems, e.name.split('.'), e.value);
        this.__state = { ...this.__state };
      },
      changeSort: (_name, result) => {
        this.__sortParams = result[0];
        this.__service.setQuery('sortKey', result[0].key);
        this.__service.setQuery('sortDir', result[0].dir);
      },
      confirmHide: async () => {
        await this.__openConfirmHidePopup();
        this.__deselectAll();
      },
      confirmShow: async () => {
        await this.__openConfirmShowPopup();
        this.__deselectAll();
      },
      clickLink: (key, index) => {
        const row = this.__state.pageItems[index];

        if (key === 'patient') {
          const route = `/patients/${
            row.patientId
          }/${'claims/worklist'}/needs-attention`;

          navigate(route);
        } else {
          this.__openLinkOverlay(key, index, row);
        }
      },
      bulkAction: action => {
        this.__performBulkAction(action);
      },
      checkedRowBulkAction: action => {
        const checkedItems = this.__checkedRowItems;

        return checkedItems.length
          ? this.__performClaimBulkAction(action, checkedItems)
          : openPopup(POPUP_RENDER_KEYS.MESSAGE, {
              title: 'Bulk Actions',
              message: BULK_ACTIONS_NO_CLAIMS_SELECTED_POPUP_TEXT,
            });
      },
      applyFilters: (model, isButtonPress) => {
        if (isButtonPress) {
          this.__hasAppliedFilters = true;
        }
        const filterQueries = createFilterQueries(
          model,
          this.__getUserLocationIds(),
        );
        this.__totalFiltersApplied = getTotalFiltersApplied(model);

        Object.entries(filterQueries).forEach(([k, v]) => {
          v = k === 'assignedTo' && !v ? [] : v;

          this.__service.setQuery(k, v);
        });

        this.__deselectAll();
      },
      toggleExpand: (_, item, index, value) => {
        this.onToggleExpand([item, index, value]);
      },
      expandAll: (collapse = true) => {
        this.onExpandAll(this.__pageItems.map(claim => claim.id), collapse);
      },
      selectCheckbox: (item, index) =>
        this.__selectCheckbox(item, index, !this.__selectedRows[item.id]),
      deselectAll: () => this.__deselectAll(),
      selectAll: () => this.__selectAll(),
      selectAllForCurrentPage: () => {
        const { selectAll } = this.__selectedRows;
        const isAllSelected = this.__pageItems
          .map(item => item.checked)
          .every(Boolean);

        if (selectAll || isAllSelected) {
          this.__deselectAll();
        } else {
          this.__pageItems.forEach((item, index) =>
            this.__selectCheckbox(item, index, !isAllSelected),
          );
        }
      },
      selectedAmountLabel: () => {
        if (this.__selectedRows.selectAll) {
          return `${this.__totalClaims} Items Selected.`;
        }

        const totalItemsSelected = this.__selectedRowsIds.length;

        if (totalItemsSelected) {
          return `${totalItemsSelected} ${
            totalItemsSelected > 1 ? 'Items' : 'Item'
          } Selected.`;
        }

        return '';
      },
      changeErrors: async (claimRowIndex, errors) => {
        try {
          const claimStatusId = this.__state.pageItems[claimRowIndex]
            .claimStatuses[0].id;

          await claimsValidationErrorsApi.updateValidationErrors(
            claimStatusId,
            { errors },
          );

          const completed = errors.every(error => !!error.completedBy);
          this.__state.pageItems[claimRowIndex].completed = completed;
          this.__state = { ...this.__state };
        } catch (error) {
          console.error(error);
        }
      },
    };
  }

  __deselectRemovedItems(previousPageItems) {
    const selectedRows = previousPageItems.reduce((acc, key) => {
      if (this.__model.pageItems.some(pageItem => pageItem.id === key)) {
        acc[key] = this.__selectedRows[key];
      }

      return acc;
    }, {});

    this.__selectedRows = {
      ...selectedRows,
      selectAll: this.__selectedRows.selectAll,
    };
  }

  __deselectAll() {
    const selectedRows = Object.keys(this.__selectedRows).reduce((acc, key) => {
      acc[key] = false;

      return acc;
    }, {});

    this.__selectedRows = { ...selectedRows, selectAll: false };
    this.__state = this.__setAllCheckboxes(false);
  }

  __selectCheckbox(item, index, value) {
    this.__selectedRows = {
      ...this.__selectedRows,
      [item.id]: value,
      selectAll: false,
    };

    this.__setCheckboxByIndex({ index, value });
  }

  __selectAll() {
    const isAllSelected = !!this.__selectedRows.selectAll;

    const selectedRows = Object.keys(this.__selectedRows).reduce((acc, key) => {
      acc[key] = !isAllSelected;

      return acc;
    }, {});

    this.__selectedRows = { ...selectedRows, selectAll: !isAllSelected };
    this.__state = this.__setAllCheckboxes(!isAllSelected);
  }

  __setCheckboxByIndex({ index, value }) {
    this.__state.pageItems[index].checked = value;
    this.__state = { ...this.__state };
  }

  __setAssignedToQuery(query) {
    return this.__hasRcmRelease2FF
      ? { assignedTo: query.assignedTo || [ASSIGNED_TO.CLINIC.data.id] }
      : {};
  }

  __initServices() {
    const client = async query => {
      if (
        this.__hasDisableInitialLoadFF &&
        !this.__hasAppliedFilters &&
        !this.patientId
      ) {
        return EMPTY_RESPONSE;
      }

      const claims = await claimsApi.getClaimsData('needs-attention', {
        ...query,
        patientId: query.patientId || this.patientId,
        statuses: query.statuses || NEEDS_ATTENTION_CLAIM_STATUSES,
        includeLedgerData: false,
        locationIds: query.locationIds || this.__getUserLocationIds(),
        ...this.__setAssignedToQuery(query),
      });

      if (!claims.data.length) {
        return claims;
      }

      const claimIds = claims.data.map(claim => claim.id);
      this.__selectedRows = {
        ...claimIds.reduce((acc, id) => {
          acc[id] = false;
          return acc;
        }, {}),
        ...this.__selectedRows,
      };

      const patientIds = claims.data.map(x => x.patientId);
      const patients = await patientApiClient.fetchSome(
        patientIds,
        {},
        false,
        false,
        true,
      );

      claims.data.forEach(claim => {
        claim.patient = patients.find(p => p.id === claim.patientId);
      });

      return claims;
    };

    if (this.__service) {
      this.__service.cancel();
    }

    this.__service = new FetchService(
      {
        onChange: this.__handlers.changeState,
        onMapItem: item => ({
          ...item,
          dateOfService: findDateOfService(item.claimCharges),
          invoiceNumber: item.invoice.invoiceNumber,
          lastUpdated: formatDate(item.lastUpdated),
          primaryPayerId: item.insurance.payerPlanId,
          primaryInsuranceId:
            item.paymentResponsibilityLevelCode === 'Primary'
              ? item.claimCharges[0].lineItem.primaryInsuranceId
              : item.claimCharges[0].lineItem.secondaryInsuranceId,
          checked: this.__selectedRows[item.id] || false,
        }),
      },
      client,
      {
        pageSize: CLAIM_LIMIT,
        hideInactive: false,
      },
    );
  }

  async refresh() {
    if (this.userLocations && this.userLocations.length) {
      await this.load();
    }
  }

  __changeTotal(state) {
    this.onTotalChange({
      needsAttentionCount: state.filteredCount,
      needsAttentionAmount: state.body.totalChargeAmount || 0,
    });
  }

  async __openLinkOverlay(key, index, row) {
    let datesOfService = '';

    const currentPageItems = this.__model.pageItems.map(item => item.id);

    switch (key) {
      case 'claimNumber':
        await openOverlay(OVERLAY_KEYS.LEDGER_GENERATE_CLAIM, {
          claimId: row.id,
        });

        break;

      case 'batchId':
        {
          const { batches = [] } = row;
          const batchId = batches.length ? batches[0] : '';

          await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
            batchId,
            patientId: row.patient.id,
            onSelect: this.onSelect,
          });
        }
        break;

      case 'primaryPayerAlias':
        await openOverlay(OVERLAY_KEYS.PAYER_PLAN, {
          id: row.primaryPayerId,
        });

        break;

      case 'primaryPlanName':
        {
          const insurances = await fetchInsuranceItems(row.patient.id);

          await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_VIEW, {
            patientId: row.patient.id,
            patientInsurances: insurances,
            patientInsurance: insurances.find(
              insurance => insurance.data.id === row.primaryInsuranceId,
            ).data,
          });
        }
        break;

      case 'invoiceBillingNote':
        datesOfService = `${parseDate(row.dosFrom).format(
          'MM/DD/YYYY',
        )} - ${parseDate(row.dosTo).format('MM/DD/YYYY')}`;

        if (row.dosFrom === row.dosTo) {
          datesOfService = `${parseDate(row.dosFrom).format('MM/DD/YYYY')}`;
        }

        await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.INVOICE,
          parentId: row.invoiceId,
          parentData: {
            datesOfService,
            invoice: row.invoice.invoiceNumber,
          },
          patientId: row.patientId,
        });

        break;

      case 'claimBillingNote':
        datesOfService = `${parseDate(row.dosFrom).format(
          'MM/DD/YYYY',
        )} - ${parseDate(row.dosTo).format('MM/DD/YYYY')}`;

        if (row.dosFrom === row.dosTo) {
          datesOfService = `${parseDate(row.dosFrom).format('MM/DD/YYYY')}`;
        }

        await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.CLAIM,
          parentId: row.id,
          parentData: {
            datesOfService,
            claimNumber: row.claimNumber,
            amount: row.amount,
            payer: `${row.primaryPayerAlias}, ${row.primaryPlanName}`,
          },
          patientId: row.patientId,
        });

        break;

      default:
        await openOverlay(OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES, {
          patient: {
            id: row.patient.id,
            name: objToName(row.patient.name, DEFAULT_NAME_OPTS),
            mrn: row.patient.medicalRecordNumber,
          },
          lineItemIds: (await getLedgerInvoiceItems(row.invoiceId)).data.map(
            li => li.id,
          ),
          selectedIds: [],
        });

        break;
    }

    await this.refresh();
    this.__deselectRemovedItems(currentPageItems);
  }

  __setAllCheckboxes(checked) {
    this.__state.pageItems = this.__state.pageItems.map(item => ({
      ...item,
      checked,
    }));

    return { ...this.__state };
  }

  async __performBulkAction(action) {
    switch (action.id) {
      case 'selectAll':
        this.__selectAll();
        break;
      case 'deselectAll':
        this.__deselectAll();
        break;

      default:
        await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
          patientId: this.patientId,
          onSelect: this.onSelect,
        });

        this.__deselectAll();

        this.refresh();
        break;
    }
  }

  async __printClaims(ids) {
    let claimWindow;

    try {
      claimWindow = window.open();
      showLoading(claimWindow, 'Loading claim, please wait...');
    } catch (e) {
      console.error(e);
      store.dispatch(
        openError('Cannot open claim tab, please check your pop-up blocker'),
      );
    }

    if (claimWindow) {
      try {
        const settings = getPrintSettings();
        const res = await claimsApi.bulkPrintClaims(settings, ids);
        claimWindow.location = getFileURL(res.buffer);
      } catch (e) {
        console.error(e);
        const msg = 'An error occurred when printing the claim';
        store.dispatch(openError(msg));
        showError(claimWindow, msg);
      }
    }
  }

  async __performClaimBulkAction(action, claims) {
    const claimIds = claims.map(c => c.id);

    switch (action.id) {
      case 'printClaims':
        this.__printClaims(claimIds);
        break;

      case 'requestStatusUpdate':
        this.onRequestStatus(claims);
        break;

      case 'refreshClaims': {
        try {
          const refreshResponse = await claimsApi.refreshClaims(claimIds, 2);

          store.dispatch(
            openSuccess(CLAIM_REFRESH_SUCCESS_MESSAGE(refreshResponse.count)),
          );

          this.refresh();
        } catch (e) {
          console.error(e);
          store.dispatch(openError(REFRESH_CLAIMS_ERROR));
        }

        break;
      }
      case 'changeStatus':
        await this.onChangeClaimStatus(claims);
        this.refresh();
        break;

      case BULK_ACTION_OPTION_ID.REFRESH_AND_SUBMIT_CLAIMS: {
        try {
          const {
            excessChargeInvoiceNumbers = [],
            incorrectClearinghouseInvoiceNumbers = [],
            violatingInvoiceIds = [],
          } = await claimsApi.validateRefreshClaims(claimIds);

          if (
            await verifyClaimsWithPopups(
              excessChargeInvoiceNumbers,
              incorrectClearinghouseInvoiceNumbers,
              EXCESS_CHARGE_POPUP,
              DESIGNATED_CLEARINGHOUSE_POPUP,
            )
          ) {
            const filteredClaimIds = claims
              .filter(c => !violatingInvoiceIds.includes(c.invoiceId))
              .map(c => c.id);

            if (!filteredClaimIds.length) {
              return;
            }

            const claimBatchResponse = await claimsApi.refreshAndSubmitClaims(
              filteredClaimIds,
            );

            if (claimBatchResponse.failedRefreshCount) {
              store.dispatch(
                openWarning(
                  CLAIM_NOT_REFRESHED_MESSAGE(
                    claimBatchResponse.failedRefreshCount,
                  ),
                ),
              );
            }

            if (claimBatchResponse.internalErrorCount) {
              store.dispatch(
                openWarning(
                  generateInternalErrorMessage(
                    claimBatchResponse.internalErrorCount,
                  ),
                ),
              );
            }

            if (
              !claimBatchResponse.failedClaimsCount &&
              !claimBatchResponse.internalErrorCount
            ) {
              store.dispatch(openSuccess(successfulGenerateBatch));

              await this.refresh();
              await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
                patientId: this.patientId,
                onSelect: this.onSelect,
              });

              this.__deselectAll();
              await this.refresh();
              return;
            }

            store.dispatch(
              openWarning(
                generateValidationErrorMessage(
                  claimBatchResponse.failedClaimsCount,
                ),
              ),
            );

            await this.refresh();

            if (claimBatchResponse.successfulClaimsCount) {
              await openOverlay(OVERLAY_KEYS.CLAIM_BATCHES, {
                patientId: this.patientId,
                onSelect: this.onSelect,
              });

              await this.refresh();
            } else return;
          }
        } catch (e) {
          console.error(e);
          store.dispatch(openError(REFRESH_AND_SUBMIT_CLAIMS_ERROR));
        }

        break;
      }

      default:
    }

    this.__deselectAll();
  }

  async __openConfirmHidePopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.HIDE,
      this.__checkedRowItems,
    );

    if (result) {
      this.refresh();
    }
  }

  async __openConfirmShowPopup() {
    const result = await openWorklistItemPopup(
      WORKLIST_ITEMS_POPUP_ACTIONS.SHOW,
      this.__checkedRowItems,
    );

    if (result) {
      this.refresh();
    }
  }

  __appendPatientAndProvider(items) {
    return items.map(item => {
      const provider = this.__providers.find(p => p.id === item.providerId);

      const result = { ...item };

      if (provider) {
        result.provider = provider;
      }

      return result;
    });
  }

  __applyAssignedToDefaultFilter() {
    const model = {
      ...NebClaimsWorklistFilters.createModel(),
      assignedTo: [ASSIGNED_TO.CLINIC],
    };

    this.__totalFiltersApplied = getTotalFiltersApplied(model);
  }

  async load() {
    const [, providers] = await Promise.all([
      this.__service.fetch(),
      getProviderUsers(),
    ]);

    this.__providers = providers;

    if (this.__hasRcmRelease2FF) {
      this.__applyAssignedToDefaultFilter();
    }
  }

  async connectedCallback() {
    this.__hasDisableInitialLoadFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__hasRcmRelease2FF = await hasFeatureOrBeta(
      FEATURE_FLAGS.RCM_RELEASE_2,
    );

    super.connectedCallback();
  }

  firstUpdated() {
    this.refresh();
  }

  __getEmptyMessage() {
    return this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
      ? CLAIMS_NO_ITEMS_INITIAL_LOAD
      : this.emptyMessage;
  }

  __resetTable(state) {
    const newState = state || this.__state;

    this.__state = {
      ...newState,
      pageItems: this.__appendPatientAndProvider(newState.pageItems),
    };
  }

  __sortFirstEncounterLocation() {
    const allOrderedClaimCharges = this.__state.pageItems.map(pi => {
      const orderedClaimCharges = pi.claimCharges.sort(
        (a, b) => a.dateOfService - b.dateOfService,
      );

      return {
        ...pi,
        claimCharges: [...orderedClaimCharges],
      };
    });

    this.__model = {
      ...this.__model,
      pageItems: allOrderedClaimCharges,
    };
  }

  __getUserLocationIds() {
    return this.userLocations
      ? this.userLocations.map(({ data }) => data.id)
      : [];
  }

  __hasChangedProps(changedProps) {
    return !!(
      changedProps.has('userLocations') ||
      changedProps.has('__providers') ||
      (changedProps.has('__state') && this.userLocations.length)
    );
  }

  __hasChangedPropsOnlyUserLocations(changedProps) {
    return !!(
      changedProps.has('userLocations') &&
      this.userLocations &&
      this.userLocations.length
    );
  }

  __checkSelectAllItems() {
    return (
      this.__selectedRows.selectAll ||
      this.__totalClaims === this.__selectedRowsIds.length ||
      this.__selectedRowsIds.filter(row => !!row).length < CLAIM_LIMIT ||
      this.__state.pageCount <= 1
    );
  }

  __isRefreshAndSubmitDisabled() {
    return (
      this.__selectedRowsIds.length === 0 ||
      this.__selectedRowsIds.length > 100 ||
      this.__selectedRows.selectAll
    );
  }

  __getBulkActions() {
    return [
      ...(this.__selectedRowsIds.length
        ? [
            {
              ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SELECTED_AMOUNT],
              label: this.__handlers.selectedAmountLabel(),
              showTextOnly: true,
            },
          ]
        : []),
      ...(this.__checkSelectAllItems()
        ? []
        : [
            {
              ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SELECT_ALL],
              onSelect: this.__handlers.selectAll,
              ...(this.__totalClaims && {
                label: `Select All ${this.__totalClaims} Items`,
              }),
            },
          ]),
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.REFRESH_AND_SUBMIT_CLAIMS],
        onSelect: () =>
          this.__handlers.checkedRowBulkAction(
            BULK_ACTION_OPTIONS[
              BULK_ACTION_OPTION_ID.REFRESH_AND_SUBMIT_CLAIMS
            ],
          ),
        disabled: this.__isRefreshAndSubmitDisabled(),
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.REFRESH_CLAIMS],
        onSelect: () =>
          this.__handlers.checkedRowBulkAction(
            BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.REFRESH_CLAIMS],
          ),
        disabled: this.__selectedRowsIds.length === 0,
      },
      ...(this.hasMaxClear
        ? [
            {
              ...BULK_ACTION_OPTIONS[
                BULK_ACTION_OPTION_ID.REQUEST_STATUS_UPDATE
              ],
              onSelect: () =>
                this.__handlers.checkedRowBulkAction(
                  BULK_ACTION_OPTIONS[
                    BULK_ACTION_OPTION_ID.REQUEST_STATUS_UPDATE
                  ],
                ),
              disabled: this.__selectedRowsIds.length === 0,
            },
          ]
        : []),
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.CHANGE_STATUS],
        onSelect: () => {
          this.__handlers.checkedRowBulkAction(
            BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.CHANGE_STATUS],
          );
        },
        disabled: this.__selectedRowsIds.length === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.PRINT_CLAIMS],
        onSelect: () =>
          this.__handlers.checkedRowBulkAction(
            BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.PRINT_CLAIMS],
          ),
        disabled: this.__selectedRowsIds.length === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.HIDE_WORKLIST_ITEMS],
        onSelect: () =>
          this.__checkedRowItems.length
            ? this.__handlers.confirmHide(
                BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.HIDE_WORKLIST_ITEMS],
              )
            : this.__handlers.checkedRowBulkAction(
                BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.HIDE_WORKLIST_ITEMS],
              ),
        disabled: this.__selectedRowsIds.length === 0,
      },
      {
        ...BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SHOW_WORKLIST_ITEMS],
        onSelect: () =>
          this.__checkedRowItems.length
            ? this.__handlers.confirmShow(
                BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SHOW_WORKLIST_ITEMS],
              )
            : this.__handlers.checkedRowBulkAction(
                BULK_ACTION_OPTIONS[BULK_ACTION_OPTION_ID.SHOW_WORKLIST_ITEMS],
              ),
        disabled: this.__selectedRowsIds.length === 0,
      },
    ];
  }

  __buildConfig() {
    return [
      {
        key: 'hidden',
        label: '',
        flex: css`0 0 30px`,
        truncate: true,
      },
      ...(this.__hasRcmRelease2FF
        ? [
            {
              key: 'completed',
              label: '',
              flex: css`0 0 30px`,
              truncate: true,
            },
          ]
        : []),
      {
        key: 'dateOfService',
        label: 'Service Date',
        flex: css`1.5 0 0`,
        sortable: true,
      },
      {
        key: 'locationName',
        label: 'Location',
        flex: css`1.25 0 0`,
      },
      {
        key: 'invoiceNumber',
        label: 'Invoice',
        flex: css`1 0 0`,
        truncate: true,
        sortable: true,
      },
      {
        key: 'claimNumber',
        label: 'Claim',
        flex: css`1 0 0`,
        truncate: true,
        sortable: true,
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`1.5 0 0`,
        truncate: true,
      },

      {
        key: 'lastUpdated',
        label: 'Last Updated',
        flex: css`1.25 0 0`,
        truncate: true,
      },

      ...(!this.patientId
        ? [
            {
              key: 'patient.name',
              label: 'Patient',
              flex: css`2 0 0`,
              truncate: true,
            },
          ]
        : []),
      {
        key: 'primaryPayerAlias',
        label: 'Payer',
        flex: css`1 0 0`,
        truncate: true,
        sortable: true,
      },
      {
        key: 'primaryPlanName',
        label: 'Plan',
        flex: css`1 0 0`,
        truncate: true,
      },
      {
        key: 'provider',
        label: 'Billing Provider',
        flex: css`1 0 0`,
      },
      {
        key: 'amount',
        label: 'Amount',
        flex: css`1 0 0`,
        truncate: true,
        sortable: true,
      },
    ];
  }

  __changePropsForSelectedRows(changedProps) {
    return (
      changedProps.has('__state') ||
      changedProps.has('__pageItems') ||
      changedProps.has('__selectedRows')
    );
  }

  update(changedProps) {
    if (this.__hasChangedProps(changedProps)) {
      this.__resetTable();
      this.__sortFirstEncounterLocation();

      if (this.expandFlags.expandAll) {
        this.__handlers.expandAll(false);
      }
    }

    if (this.refreshing) {
      this.refresh();
      this.refreshing = false;
    }

    if (this.__changePropsForSelectedRows(changedProps)) {
      this.__selectedRowsForCurrentPage = this.__pageItems.map(
        claim =>
          !!this.__selectedRows.selectAll || !!this.__selectedRows[claim.id],
      );
    }

    if (changedProps.has('patientId') && this.userLocations?.length) {
      this.refresh();
    }

    super.update(changedProps);
  }

  updated(changedProps) {
    if (this.__hasChangedPropsOnlyUserLocations(changedProps)) {
      const locationIds = this.defaultLocationId
        ? [this.defaultLocationId]
        : this.__getUserLocationIds();
      this.__service.setQuery('locationIds', locationIds);
      this.refresh();
    }

    super.updated(changedProps);
  }

  renderFilters() {
    const totalFiltersApplied = this.__totalFiltersApplied
      ? this.__totalFiltersApplied
      : 0;

    return html`
      <neb-filters-claims-worklist
        id="${ELEMENTS.filters.id}"
        .status="${'needs-attention'}"
        .userLocations="${this.userLocations}"
        .defaultLocationId="${this.defaultLocationId}"
        .showHidden="${this.__showHidden}"
        .providers="${this.__providers}"
        .onApply="${this.__handlers.applyFilters}"
        ?enablePatient="${!this.patientId}"
        .expanded="${this.__showExpandedFilters}"
        .totalFiltersApplied="${totalFiltersApplied}"
        .hasCollapsedFilters="${this.__hasCollapsedFilters}"
        .hasRcmRelease2FF="${this.__hasRcmRelease2FF}"
      ></neb-filters-claims-worklist>
    `;
  }

  renderPagination() {
    return this.__state.pageCount > 1
      ? html`
          <neb-pagination
            id="${ELEMENTS.pagination.id}"
            class="pagination"
            .currentPage="${this.__state.pageIndex}"
            .onPageChanged="${this.__handlers.pageChanged}"
            .pageCount="${this.__state.pageCount}"
          ></neb-pagination>
        `
      : '';
  }

  render() {
    return html`
      ${this.renderFilters()}

      <neb-table-claims-worklist-needs-attention
        id="${ELEMENTS.table.id}"
        .bulkActionItems="${this.__getBulkActions()}"
        .hasRcmRelease2FF="${this.__hasRcmRelease2FF}"
        .selectedItems="${this.__selectedRowsForCurrentPage}"
        .onSelectCheckbox="${this.__handlers.selectCheckbox}"
        .onSelectAll="${this.__handlers.selectAllForCurrentPage}"
        .config="${this.__buildConfig()}"
        .totalClaims="${this.__state.filteredCount}"
        .sortParams="${[this.__sortParams]}"
        .emptyMessage="${this.__getEmptyMessage()}"
        .model="${this.__model.pageItems}"
        .defaultLocationId="${this.defaultLocationId}"
        .onChange="${this.__handlers.change}"
        .onSort="${this.__handlers.changeSort}"
        .onClickLink="${this.__handlers.clickLink}"
        .onToggleExpand="${this.__handlers.toggleExpand}"
        .expandFlags="${this.__expandFlagsForCurrentPage}"
        .onExpandAll="${this.__handlers.expandAll}"
        .onChangeErrors="${this.__handlers.changeErrors}"
      >
      </neb-table-claims-worklist-needs-attention>
      ${this.renderPagination()}
    `;
  }
}

customElements.define(
  'neb-controller-needs-attention',
  ControllerNeedsAttention,
);
