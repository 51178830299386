import '../inputs/neb-select';
import '../inputs/neb-textfield';
import '../recurring/neb-recurring-month-year-options';
import '../field-groups/neb-duration';
import '../inputs/neb-floating-label';
import '../../../../../src/components/misc/neb-icon';

import equal from 'fast-deep-equal';
import { html, css } from 'lit';
import { RRule } from 'rrule';

import {
  CSS_COLOR_HIGHLIGHT,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import { parseDate } from '../../../../neb-utils/date-util';
import { number } from '../../../../neb-utils/masks';
import {
  toRecurringText,
  OCCURRENCE_OPTIONS,
  DAILY,
  WEEKLY,
  MONTHLY,
  YEARLY,
  VISITS,
  TIMES,
  DATE,
} from '../../../../neb-utils/recurring';
import * as selectors from '../../../../neb-utils/selectors';
import { PERIOD } from '../../../../neb-utils/utils';
import { required } from '../../../../neb-utils/validators';
import { POPOVER_POSITION } from '../neb-date-picker';
import * as rDayPicker from '../recurring/neb-recurring-day-picker';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

export const RECURRENCE_OPTIONS = [
  {
    label: 'Day(s)',
    data: { id: DAILY },
  },
  {
    label: 'Week(s)',
    data: { id: WEEKLY },
  },
  {
    label: 'Month(s)',
    data: { id: MONTHLY },
  },
  {
    label: 'Year(s)',
    data: { id: YEARLY },
  },
];

const getDurationHours = duration =>
  Math.floor((duration / (1000 * 60 * 60)) % 24);

const getDurationMinutes = duration =>
  Math.floor((duration / (1000 * 60)) % 60);

const getTime = date => ({
  hours: parseDate(date).hours() % 12 || '12',
  minutes: `${parseDate(date)
    .minutes()
    .toString()
    .padStart(2, '0')}`,
  period: parseDate(date).format('a'),
});

const getInitialStart = () => {
  const date = parseDate();
  const incrementHoursBy = date.minutes() > 30 ? 1 : 0;
  const setMinutes = incrementHoursBy ? 0 : 30;

  return date
    .startOf('hour')
    .add(incrementHoursBy, 'hours')
    .add(setMinutes, 'minutes');
};

const RECURRING_YEAR_LIMIT = 3;

const withinBoundary = () => ({
  error: `Exceeds ${RECURRING_YEAR_LIMIT} years from start`,
  validate: (v, _, state) => {
    if (state.endType === DATE) {
      return (
        parseDate(v).diff(parseDate(state.startDate), 'years', true) <=
        RECURRING_YEAR_LIMIT
      );
    }
    return true;
  },
});

const getMomentWithTime = (date, { hours, minutes, period }) => {
  if (period === PERIOD.AM && Number(hours) === 12) {
    hours = 0;
  } else if (period === PERIOD.PM && Number(hours) < 12) {
    hours = Number(hours) + 12;
  }

  return parseDate(date).set({
    hours: Number(hours),
    minutes: Number(minutes),
  });
};

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  startDate: { id: 'start-date' },
  recursAmount: { id: 'recurs-amount' },
  recursType: { id: 'recurs-type' },
  endType: { id: 'end-type' },
  endDate: { id: 'endDate' },
  visits: { id: 'visits' },
  narrative: { id: 'narrative' },
  durationTime: { id: 'duration-time' },
  startTime: { id: 'start-time' },
  recurringDay: { id: 'recurring-day' },
  recurringWeek: { id: 'recurring-week' },
  recurringMonthOptions: { id: 'recurring-month' },
  recurringYearOptions: { id: 'recurring-year' },
};

export default class NebFormRecurringAppointment extends NebForm {
  static get properties() {
    return {
      __visitsError: String,
      appointmentDuration: Object,
      selectedDate: Object,
      startTime: Object,
      blockedOffTime: {
        reflect: true,
        type: Boolean,
      },
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  static createModel(selectedDate = null, startTime = null) {
    const startDate = getInitialStart();

    return {
      startDate: selectedDate
        ? parseDate(selectedDate).toISOString()
        : startDate.toISOString(),
      occurrence: OCCURRENCE_OPTIONS[0],
      recurrence: RECURRENCE_OPTIONS[0],
      selectedDays: rDayPicker.DAY_NAMES,
      selectedDateOption: {},
      endType: DATE,
      endDate: null,
      visits: 0,
      durationTime: {
        hours: '0',
        minutes: '30',
        period: null,
      },
      startTime: startTime || getTime(startDate),
      rrule: null,
      walkIn: false,
      locationId: '',
    };
  }

  __getMaxVisits(state) {
    const endDate = new Date(state.startDate);
    endDate.setFullYear(endDate.getFullYear() + RECURRING_YEAR_LIMIT);

    const copy = {
      ...state,
      visits: 0,
      endDate,
    };

    const rrule = this.__generateRRule(copy);
    return rrule.all().length;
  }

  __validateVisits(v, state) {
    if (v) {
      const max = this.__getMaxVisits(state);
      const valid = v <= max;

      this.__visitsError = valid ? '' : `Exceeds max (<= ${max})`;
      return valid;
    }
    this.__visitsError = state.endType === DATE ? '' : 'Required';
    return !this.__visitsError;
  }

  createSelectors() {
    return {
      children: {
        startDate: {
          ignorePristine: true,
          validators: [required()],
        },
        endDate: {
          clipPristine: true,
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) => {
                if (state.endType === DATE) {
                  return Array.isArray(v) ? v.length : v;
                }
                return true;
              },
            },
            withinBoundary(),
          ],
        },
        recurrence: selectors.select(
          RECURRENCE_OPTIONS,
          RECURRENCE_OPTIONS[0],
          { validators: [] },
        ),
        selectedDays: { unsafe: true, clipPristine: true, validators: [] },
        selectedDateOption: {
          unsafe: true,
          clipPristine: true,
          validators: [],
        },
        visits: {
          validators: [
            {
              error: 'Visit count error',
              validate: (v, _, state) => this.__validateVisits(v, state),
            },
          ],
        },
        durationTime: {
          unsafe: true,
          clipPristine: true,
          validators: [
            {
              error: 'Required',
              validate: v =>
                Number(v.minutes) === 0
                  ? Number(v.hours) > 0
                  : Number(v.hours) >= 0,
            },
          ],
        },
        startTime: {
          unsafe: true,
          clipPristine: true,
          validators: [],
        },
        rrule: {
          unsafe: true,
          clipPristine: true,
          validators: [],
        },
      },
    };
  }

  initState() {
    super.initState();

    this.__visitsError = '';

    this.appointmentDuration = null;
    this.selectedDate = null;
    this.startTime = null;
    this.blockedOffTime = false;
    this.layout = 'large';

    this.onRecurringModelChange = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      startDateChange: ({ value }) => {
        this.formService.apply(
          'startDate',
          value !== null
            ? getMomentWithTime(value, this.state.startTime).toISOString()
            : value,
        );

        if (!this.state.startDate) this.formService.apply('rrule', null);

        this.__updateStartDateSelectable();
      },
      endTypeChange: ({ value }) => {
        this.formService.apply('endType', value);

        if (this.state.endType === DATE) {
          this.handlers.visitsChange({ value: 0 });
        } else {
          this.handlers.endDateChange({ value: null });
        }
      },
      endDateChange: ({ value }) => {
        this.formService.apply(
          'endDate',
          value !== null
            ? parseDate(value)
                .startOf('day')
                .toISOString()
            : value,
        );

        if (!this.state.endDate) this.formService.apply('rrule', null);

        this.__updateEndDateSelectable();
      },
      visitsChange: ({ value }) => {
        this.formService.apply('visits', Number(value));

        if (!this.state.visits) {
          this.formService.apply('rrule', null);
        }
      },
      timeChange: ({ name, value }) => {
        const durationStartOrEnd = name.split('.')[0];
        const hoursMinutesOrPeriod = name.split('.')[1];

        this.formService.apply(durationStartOrEnd, {
          ...this.state[durationStartOrEnd],
          [hoursMinutesOrPeriod]: value,
        });

        if (durationStartOrEnd === 'startTime') {
          this.handlers.startDateChange({
            value: this.state.startDate,
          });
        }
      },
      recurrenceChange: ({ value }) => {
        this.__handleRecurrenceChange(value);
      },
      recurringDayPickerChange: value => {
        this.formService.apply('selectedDays', value);

        this.__selectDayAndWeek();
      },
      recurringMonthYearOptionsChange: value => {
        this.formService.apply('selectedDateOption', value);
      },
    };

    this.__updateStartDateSelectable();
    this.__updateEndDateSelectable();
  }

  __updateStartDateSelectable() {
    this.handlers.startDateSelectable = date =>
      date >= parseDate(getInitialStart()).startOf('day') &&
      (this.state.endDate === null ||
        date < parseDate(this.state.endDate).startOf('day')) &&
      (this.blockedOffTime ? date >= parseDate().startOf('day') : true);
  }

  __updateEndDateSelectable() {
    this.handlers.endDateSelectable = date => {
      if (this.state.startDate) {
        return date > parseDate(this.state.startDate).startOf('day');
      }
      return date > parseDate(getInitialStart()).startOf('day');
    };
  }

  __updateDurationTime() {
    this.formService.apply('durationTime', {
      hours: getDurationHours(this.appointmentDuration),
      minutes: getDurationMinutes(this.appointmentDuration),
      period: null,
    });
  }

  __updateStartTime() {
    this.formService.apply(
      'startTime',
      this.startTime || getTime(this.selectedDate),
    );

    this.handlers.startDateChange({ value: this.selectedDate });
  }

  __areAllDaysSelected() {
    return equal(
      [...this.state.selectedDays].sort(),
      [...rDayPicker.DAY_NAMES].sort(),
    );
  }

  __selectDayAndWeek() {
    if (
      this.__areAllDaysSelected() &&
      !equal(this.state.recurrence, RECURRENCE_OPTIONS[0])
    ) {
      this.handlers.recurrenceChange({
        value: RECURRENCE_OPTIONS[0],
      });
    }

    if (
      !this.__areAllDaysSelected() &&
      equal(this.state.recurrence, RECURRENCE_OPTIONS[0])
    ) {
      this.handlers.recurrenceChange({
        value: RECURRENCE_OPTIONS[1],
      });
    }
  }

  __handleRecurrenceChange(recurrence) {
    if (recurrence !== this.state.recurrence) {
      this.formService.apply('recurrence', recurrence);

      switch (this.state.recurrence) {
        case RECURRENCE_OPTIONS[0]:
          this.formService.apply('selectedDays', [...rDayPicker.DAY_NAMES]);
          this.formService.apply('selectedDateOption', {});
          break;

        case RECURRENCE_OPTIONS[1]:
          this.formService.apply(
            'selectedDays',
            this.state.selectedDays.length && !this.__areAllDaysSelected()
              ? this.state.selectedDays
              : [
                  rDayPicker.DAY_NAMES[
                    parseDate(this.state.startDate).weekday()
                  ],
                ],
          );

          this.formService.apply('selectedDateOption', {});
          break;

        default:
          this.formService.apply('selectedDays', []);
          this.formService.apply('selectedDateOption', {});
      }
    }
  }

  __getEndTypes() {
    return this.blockedOffTime ? [DATE, TIMES] : [DATE, VISITS];
  }

  __generateRRule(state) {
    const {
      selectedDays,
      selectedDateOption,
      visits: count,
      occurrence: interval,
      endDate,
      startDate,
      recurrence,
    } = state;

    let rRuleOptions = {
      freq: RRule[recurrence.data.id],
      dtstart: parseDate(startDate)
        .tz('UTC', true)
        .toDate(),
      interval,
      until: endDate
        ? parseDate(endDate)
            .tz('UTC', true)
            .toDate()
        : endDate,
      count,
    };

    if (selectedDays.length) {
      rRuleOptions = {
        ...rRuleOptions,
        byweekday: selectedDays.map(
          day => RRule[rDayPicker.RRULE_BY_WEEK_DAY[day]],
        ),
      };
    } else if (selectedDateOption && !selectedDateOption.month) {
      rRuleOptions = {
        ...rRuleOptions,
        byweekday: RRule[selectedDateOption.weekDay],
        bymonthday: selectedDateOption.monthDay,
        bysetpos: selectedDateOption.setPos,
      };
    } else if (selectedDateOption.month) {
      rRuleOptions = {
        ...rRuleOptions,
        byweekday: RRule[selectedDateOption.weekDay],
        bymonthday: selectedDateOption.monthDay,
        bysetpos: selectedDateOption.setPos,
        bymonth: selectedDateOption.month,
      };
    }

    if (!count) delete rRuleOptions.count;

    return new RRule(rRuleOptions);
  }

  update(changedProps) {
    if (changedProps.has('appointmentDuration') && this.appointmentDuration) {
      this.__updateDurationTime();
    }

    if (
      (changedProps.has('selectedDate') && this.selectedDate) ||
      (changedProps.has('startTime') && this.startTime)
    ) {
      this.__updateStartTime();
    }

    if (this.state.startDate && (this.state.endDate || this.state.visits)) {
      const newRRule = this.__generateRRule(this.state);
      this.formService.apply('rrule', newRRule);
      this.formService.validateKey(['endDate']);
      this.formService.validateKey(['visits']);
    }

    this.onRecurringModelChange(this.state);

    super.update(changedProps);
  }

  firstUpdated() {
    if (this.appointmentDuration) this.__updateDurationTime();

    if (this.selectedDate || this.startTime) this.__updateStartTime();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .wrapper-content {
          display: flex;
          width: 100%;
          max-width: 700px;
          flex-wrap: wrap-reverse;
          column-gap: 20px;
        }

        :host(:not([layout='large'])) .wrapper-content,
        :host([blockedOffTime]) .wrapper-content {
          display: flex;
          flex-direction: column-reverse;
        }

        .wrapper-recurring-details,
        .wrapper-duration {
          display: flex;
          flex-direction: column;
          flex: 1 1 0;
        }

        :host(:not([layout='large'])) .wrapper-duration,
        :host([blockedOffTime]) .wrapper-duration {
          width: 100%;
          padding: ${CSS_SPACING_ROW} 0;
        }

        :host(:not([layout='large'])) .wrapper-recurring-details,
        :host([blockedOffTime]) .wrapper-recurring-details {
          width: 100%;
        }

        .wrapper-recurs,
        .wrapper-ends {
          display: flex;
          flex-direction: row;
          align-items: end;
          padding: ${CSS_SPACING_ROW} 0;
        }

        :host(:not([layout='large'])) .wrapper-ends,
        :host([blockedOffTime]) .wrapper-ends {
          flex-direction: column-reverse;
        }

        .end-type,
        .recurs-amount {
          width: 50%;
          padding-right: 10px;
        }

        .ends-option,
        .recurs-type {
          width: 50%;
          padding-left: 10px;
        }

        :host(:not([layout='large'])) .end-type,
        :host([blockedOffTime]) .end-type,
        :host(:not([layout='large'])) .ends-option,
        :host([blockedOffTime]) .ends-option {
          width: 100%;
          padding: 0;
        }

        .start-date,
        .duration-time {
          width: 100%;
          padding: ${CSS_SPACING_ROW} 0;
        }

        .start-time {
          display: flex;
          flex-direction: column;
          padding: ${CSS_SPACING_ROW} 0;
        }

        .icon {
          height: 40px;
          width: 40px;
          fill: ${CSS_COLOR_HIGHLIGHT};
          padding-right: 10px;
        }

        .narrative {
          display: flex;
          align-items: center;
          color: ${CSS_COLOR_HIGHLIGHT};
        }

        .label {
          font-size: 12px;
          padding-left: 5px;
        }

        .title,
        .recurs-options {
          padding: ${CSS_SPACING_ROW} 0;
        }

        .recurringNarrative {
          width: fit-content;
          width: -moz-fit-content;
        }
      `,
    ];
  }

  __renderSpan(text, className) {
    return html`
      <span class="${className}">${text}</span>
    `;
  }

  __renderRecursOptions(recurring) {
    const index = RECURRENCE_OPTIONS.findIndex(
      v => v.data.id === recurring.data.id,
    );

    switch (index) {
      case 0:
        return html`
          ${this.__renderSpan('On', 'label')}

          <neb-recurring-day-picker
            id="${ELEMENTS.recurringDay.id}"
            name="selectedDays"
            .selectedDays="${this.state.selectedDays}"
            .onChange="${this.handlers.recurringDayPickerChange}"
          ></neb-recurring-day-picker>
        `;

      case 1:
        return html`
          ${this.__renderSpan('On', 'label')}

          <neb-recurring-day-picker
            id="${ELEMENTS.recurringWeek.id}"
            name="selectedDays"
            .selectedDays="${this.state.selectedDays}"
            .onChange="${this.handlers.recurringDayPickerChange}"
          ></neb-recurring-day-picker>
        `;

      case 2:
        return this.state.startDate
          ? html`
              <neb-recurring-month-year-options
                id="${ELEMENTS.recurringMonthOptions.id}"
                class="recurring-month"
                .date="${this.state.startDate}"
                .selectedDateOption="${this.state.selectedDateOption}"
                .onChange="${this.handlers.recurringMonthYearOptionsChange}"
                .optionType="${MONTHLY}"
              ></neb-recurring-month-year-options>
            `
          : '';

      case 3:
        return this.state.startDate
          ? html`
              <neb-recurring-month-year-options
                id="${ELEMENTS.recurringYearOptions.id}"
                class="recurring-year"
                .date="${this.state.startDate}"
                .selectedDateOption="${this.state.selectedDateOption}"
                .onChange="${this.handlers.recurringMonthYearOptionsChange}"
                .optionType="${YEARLY}"
              ></neb-recurring-month-year-options>
            `
          : '';

      default:
        return '';
    }
  }

  __renderEndsOption() {
    return this.state.endType === DATE
      ? html`
          <neb-date-picker
            id="${ELEMENTS.endDate.id}"
            class="ends-option"
            name="endDate"
            label="End date"
            placeholder="End date"
            helperText="Required"
            .manualPopoverPosition="${POPOVER_POSITION.TOP}"
            .invalidText="${this.errors.endDate}"
            .selectedDate="${
              this.state.endDate !== null
                ? parseDate(this.state.endDate)
                : this.state.endDate
            }"
            .isDateSelectable="${this.handlers.endDateSelectable}"
            .onChange="${this.handlers.endDateChange}"
            ?invalid="${this.errors.endDate}"
            ?disabled="${!this.state.startDate}"
            momentFlag
          ></neb-date-picker>
        `
      : html`
          <neb-textfield
            id="${ELEMENTS.visits.id}"
            class="ends-option"
            name="visits"
            label=" "
            maxLength="3"
            helper="Required"
            .mask="${number}"
            .inputMode="${'numeric'}"
            .value="${this.state.visits}"
            .onChange="${this.handlers.visitsChange}"
            .error="${this.__visitsError}"
          ></neb-textfield>
        `;
  }

  __renderRecurringText() {
    return this.state.rrule
      ? html`
          Occurs
          ${
            toRecurringText(
              this.state.rrule,
              this.blockedOffTime ? 'times' : 'visits',
            )
          }
        `
      : '';
  }

  __renderRecurringDetails() {
    return html`
      <div class="wrapper-recurring-details">
        ${this.__renderSpan('Recurring Details', 'title')}

        <neb-date-picker
          id="${ELEMENTS.startDate.id}"
          class="start-date"
          name="startDate"
          label="Starts"
          placeholder="Start date"
          helperText="Required"
          .invalidText="${this.errors.startDate}"
          .selectedDate="${
            this.state.startDate !== null
              ? parseDate(this.state.startDate)
              : this.state.startDate
          }"
          .isDateSelectable="${this.handlers.startDateSelectable}"
          .onChange="${this.handlers.startDateChange}"
          ?invalid="${this.errors.startDate}"
          momentFlag
        ></neb-date-picker>

        <div class="wrapper-recurs">
          <neb-select
            id="${ELEMENTS.recursAmount.id}"
            class="recurs-amount"
            name="occurrence"
            label="Recurs Every"
            .items="${OCCURRENCE_OPTIONS}"
            .value="${this.state.occurrence}"
            .onChange="${this.handlers.change}"
          ></neb-select>

          <neb-select
            id="${ELEMENTS.recursType.id}"
            name="recurrence"
            class="recurs-type"
            label=" "
            .items="${RECURRENCE_OPTIONS}"
            .value="${this.state.recurrence}"
            .onChange="${this.handlers.recurrenceChange}"
          ></neb-select>
        </div>

        <div class="recurs-options">
          ${this.__renderRecursOptions(this.state.recurrence)}
        </div>

        <div class="wrapper-ends">
          <neb-select
            id="${ELEMENTS.endType.id}"
            class="end-type"
            name="endType"
            label="Ends"
            helper=" "
            .items="${this.__getEndTypes()}"
            .value="${this.state.endType}"
            .onChange="${this.handlers.endTypeChange}"
          ></neb-select>

          ${this.__renderEndsOption()}
        </div>

        ${
          this.state.rrule
            ? html`
                <div id="${ELEMENTS.narrative.id}" class="narrative">
                  <neb-icon class="icon" icon="neb:loop"></neb-icon>

                  <span class="recurringNarrative"
                    >${this.__renderRecurringText()}</span
                  >
                </div>
              `
            : ''
        }
      </div>
    `;
  }

  __renderDuration() {
    return html`
      <div class="wrapper-duration">
        ${this.__renderSpan('Duration', 'title')}
        <neb-duration
          id="${ELEMENTS.durationTime.id}"
          class="duration-time"
          name="durationTime"
          helper="Required"
          .model="${this.state.durationTime}"
          .onChange="${this.handlers.timeChange}"
          .error="${this.errors.durationTime}"
        ></neb-duration>

        <div class="start-time">
          ${this.__renderSpan('Start Time', 'label')}

          <neb-duration
            id="${ELEMENTS.startTime.id}"
            name="startTime"
            .model="${this.state.startTime}"
            .onChange="${this.handlers.timeChange}"
          ></neb-duration>
        </div>
      </div>
    `;
  }

  render() {
    return html`
      <div class="wrapper-content">
        ${this.__renderRecurringDetails()} ${this.__renderDuration()}
      </div>
    `;
  }
}

customElements.define(
  'neb-form-recurring-appointment',
  NebFormRecurringAppointment,
);
