import { parseDate } from '../../neb-utils/date-util';

import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';

export const apiClient = new ApiClient({ microservice: 'api' });
export const billingApiClient = new ApiClient({ microservice: 'billing' });
export const appointmentsApiClient = new ApiClient({
  microservice: 'appointments',
});

const BASE_URL = 'appointments';
const APPOINTMENT_TYPES = {
  future: true,
  past: false,
};

export const APPOINTMENT_STATUS = {
  ACTIVE: 'Active',
  NO_SHOW: 'No-Show',
  CHECKED_IN: 'Checked-In',
  CHECKED_OUT: 'Checked-Out',
};

const DEFAULT_STATUSES = [
  APPOINTMENT_STATUS.ACTIVE,
  APPOINTMENT_STATUS.CHECKED_IN,
  APPOINTMENT_STATUS.CHECKED_OUT,
];

export const APPOINTMENTS_ACTION_VERBS = {
  cancel: 'cancel',
  checkIn: 'check-in',
  checkOut: 'check-out',
  edit: 'edit',
  instantiateRecurrence: 'instantiate-recurrence',
  noShow: 'no-show',
  reschedule: 'reschedule',
  returnToScheduled: 'return-to-scheduled',
};

export async function quickCheckInAppointment(id, patientPackageId) {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: true,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${BASE_URL}/${id}/quick-check-in`,
    body: JSON.stringify({ patientPackageId }),
    version: 1,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

  return res.data[0];
}

export const quickCheckOutAppointment = id =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: true,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    path: `${BASE_URL}/${id}/quick-check-out`,
    version: 1,
    updateNotificationDetails: {
      appointment: {
        id,
      },
    },
  });

export const apiCreateAppointment = appointment =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appointment),
    updateNotificationDetails: {
      appointment: { patientId: appointment.patientId },
    },
  });

export const createAppointment = appointment =>
  appointmentsApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appointment),
    updateNotificationDetails: {
      appointment: { patientId: appointment.patientId },
    },
  });

export const getAppointments = (queryParams, optOutLoadingIndicator = false) =>
  appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}`,
    queryParams,
    version: 1,
    cacheKey: `${JSON.stringify(queryParams)}-appointments`,
    optOutLoadingIndicator,
  });

export const getAppointmentById = async (
  appointmentId,
  includeOptions,
  optOutLoadingIndicator = false,
) => {
  const res = await billingApiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${appointmentId}`,
    queryParams: includeOptions,
    optOutLoadingIndicator,
    version: 2,
  });

  return {
    appointment: res.data[0],
    provider: res.provider,
  };
};

export const getQuickActions = async (
  appointmentId,
  optOutLoadingIndicator = false,
) => {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${appointmentId}/quick-actions`,
    optOutLoadingIndicator,
    version: 1,
  });

  return res;
};

export const SUPPORTED_UPDATE_ACTIONS = [
  'cancel',
  'checkIn',
  'checkOut',
  'edit',
  'instantiateRecurrence',
  'noShow',
  'reschedule',
  'returnToScheduled',
];

export const apiUpdateAppointment = (
  appointmentId,
  actionVerb,
  appointment = {},
  optOutLoadingIndicator = false,
) => {
  if (!SUPPORTED_UPDATE_ACTIONS.includes(actionVerb)) {
    throw new Error(
      `Unsupported action: ${actionVerb}, not in ${SUPPORTED_UPDATE_ACTIONS}`,
    );
  }

  return apiClient.makeRequest({
    method: Method.PUT,
    path: `${BASE_URL}/${appointmentId}/${actionVerb}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appointment),
    optOutLoadingIndicator,
    updateNotificationDetails: {
      appointment: {
        patientId: appointment.patientId,
      },
    },
  });
};

export const updateAppointment = (
  appointmentId,
  actionVerb,
  appointment = {},
  optOutLoadingIndicator = false,
) => {
  if (!SUPPORTED_UPDATE_ACTIONS.includes(actionVerb)) {
    throw new Error(
      `Unsupported action: ${actionVerb}, not in ${SUPPORTED_UPDATE_ACTIONS}`,
    );
  }

  const appointmentsActionVerb = APPOINTMENTS_ACTION_VERBS[actionVerb];

  const version = actionVerb === 'reschedule' ? 2 : 1;

  return appointmentsApiClient.makeRequest({
    method: Method.PUT,
    path: `${BASE_URL}/${appointmentId}/${appointmentsActionVerb}`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(appointment),
    optOutLoadingIndicator,
    updateNotificationDetails: {
      appointment: {
        patientId: appointment.patientId,
      },
    },
    version,
  });
};

export const rescheduleAppointment = appointment =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}/${appointment.id}/reschedule`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...appointment,
      duration: parseDate(appointment.end).diff(parseDate(appointment.start)),
    }),
    updateNotificationDetails: {
      appointment: {
        patientId: appointment.patientId,
      },
    },
  });

export const cancelAppointment = appointment =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `${BASE_URL}/${appointment.id}/cancel`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...appointment, status: 'Canceled' }),
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: {
      appointment: {
        patientId: appointment.patientId,
      },
    },
  });

export const deleteAppointment = (appointmentId, patientId) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: `${BASE_URL}/${appointmentId}`,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    version: 2,
    updateNotificationDetails: {
      appointment: {
        patientId,
      },
      patient: patientId,
    },
  });

export const deleteSeries = (appointmentId, patientId) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: `${BASE_URL}/${appointmentId}/deleteSeries`,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
    updateNotificationDetails: {
      appointment: {
        patientId,
      },
    },
  });

export const getPatientAppointments = async (
  patientId,
  optOutLoadingIndicator = false,
) => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/appointments`,
    cacheKey: `${patientId}-patient-appointments`,
    optOutLoadingIndicator,
  });

  return res.data[0];
};

export const getProviderAppointments = (
  query = {},
  optOutLoadingIndicator = false,
) => {
  const sortParams = query.sortParams || { key: '', dir: '' };
  const sortDir = sortParams.dir;
  const sortField = sortParams.key;
  const start = query.start || '';
  const end = query.end || '';
  const expand = query.expand || '';
  const includeEncounterOnly = query.includeEncounterOnly || false;
  const startBefore = query.startBefore || '';
  const status = (query.statuses || DEFAULT_STATUSES).join(',');

  const raw = {
    ...query,
    sortField,
    sortDir,
    status,
    start,
    end,
    startBefore,
    includeEncounterOnly,
    expand,
  };

  const queryParams = Object.entries(raw).reduce(
    (accum, [k, v]) =>
      v && typeof v !== 'object' ? { ...accum, [k]: v } : accum,
    {},
  );

  return appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: BASE_URL,
    version: 1,
    queryParams,
    cacheKey: `${JSON.stringify(queryParams)}-provider-appointments`,
    optOutLoadingIndicator,
  });
};

export const getBookingAppointments = ({ type, offset, limit }) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    version: 1,
    path: `${BASE_URL}/booking?future=${
      APPOINTMENT_TYPES[type]
    }&offset=${offset}&limit=${limit}`,
  });

export const getBookingAppointment = async appointmentId => {
  const res = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/booking/${appointmentId}`,
    cacheKey: `${appointmentId}-booking-appointment`,
  });

  return res.data[0];
};

export const getGuestAppointments = async queryParams => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    version: 'public1',
    path: BASE_URL,
    queryParams,
  });

  return res.data;
};

export const getGuestAppointmentsWithPin = async selfCheckInPIN => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    version: 'public1',
    body: JSON.stringify({ selfCheckInPIN }),
    path: `${BASE_URL}`,
  });

  return res.data;
};

export const roomConflictCheck = id =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `${BASE_URL}/calendar-resources/${id}/conflict`,
    version: 1,
  });

export const getBlockedOffTimeById = async id => {
  const res = await billingApiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    version: 1,
    path: `${BASE_URL}/${id}/blocked-off-time`,
  });

  return res ? res.data[0] : res;
};

export const getRescheduledAppointmentsById = async (
  appointmentId,
  optOutLoadingIndicator = false,
) => {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: `${BASE_URL}/${appointmentId}/reschedule`,
    optOutLoadingIndicator,
    version: 1,
  });

  return res;
};
