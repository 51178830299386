import { mapToModelV3 } from '../../neb-utils/payer-plan-util';

import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';

const baseUrl = 'payer-plan';
export const apiClient = new ApiClient({ microservice: 'billing' });

export const PAYER_PLAN_VERSION_CLAIMS = 2;
export const PAYER_PLAN_VERSION_DESIGNATED_CLEARINGHOUSE = 3;

const calculateCacheKey = (queryParams = {}) => {
  const cacheKey = 'all';

  const queryParamsString = Object.entries(queryParams)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .join();

  return queryParamsString ? `${cacheKey},${queryParamsString}` : cacheKey;
};

export const getPayerPlans = (
  queryParams,
  version,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path: baseUrl,
    queryParams: { limit: 999, ...queryParams },
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: calculateCacheKey(queryParams),
    optOutLoadingIndicator,
  });

export async function fetchMany(query = {}, optOutLoadingIndicator = false) {
  const res = await getPayerPlans(query, null, optOutLoadingIndicator);

  return {
    count: res.count,
    data: res.payerPlan,
  };
}

export const getPayerPlan = async (id, version) => {
  const raw = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${baseUrl}/${id}`,
    version,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `one-${id}`,
  });

  return mapToModelV3(raw);
};

export const savePayerPlan = (payerPlan, version) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: baseUrl,
    body: JSON.stringify(payerPlan),
    version,
    method: Method.POST,
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const updatePayerPlan = (id, payerPlan, version) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `${baseUrl}/${id}`,
    body: JSON.stringify(payerPlan),
    version,
    method: Method.PUT,
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `one-${id}`,
  });

export const updateCHCPayerPlans = (
  chcPayerPlans,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path: 'chc-payer-plans',
    body: JSON.stringify(chcPayerPlans),
    version: 2,
    method: Method.PUT,
    optOutLoadingIndicator,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: RESPONSE_TYPE.RAW,
  });

export const getActivePayerPlans = (patientId, version) =>
  apiClient.makeRequest({
    optOutLoadingIndicator: false,
    path: `patients/${patientId}/payer-plans`,
    version,
    queryParams: {
      hideInactive: true,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    cacheKey: `patient-${patientId}`,
  });

export async function validatePayerClearinghouses(
  id,
  optOutLoadingIndicator = false,
) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `${baseUrl}/${id}/clearinghouse-validation`,
    version: PAYER_PLAN_VERSION_CLAIMS,
  });

  return res;
}

export async function getDesignatedClearinghouseCount(
  id,
  optOutLoadingIndicator = false,
) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator,
    path: `${baseUrl}/${id}/designated-clearinghouse`,
    version: PAYER_PLAN_VERSION_DESIGNATED_CLEARINGHOUSE,
  });

  return res;
}

export async function getPayerPlanAssociatedRules(id) {
  const res = await apiClient.makeRequest({
    headers: {
      'Content-Type': 'application/json',
    },
    method: Method.GET,
    optOutLoadingIndicator: true,
    path: `${baseUrl}/${id}/rules`,
    version: 1,
  });

  return { rules: res.data, names: res.names };
}
