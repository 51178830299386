import { html, css } from 'lit';

import { baseStyles } from '../../../../neb-styles/neb-styles';
import {
  CSS_SPACING,
  CSS_SPACING_ROW,
} from '../../../../neb-styles/neb-variables';
import { padArray } from '../../../../neb-utils/utils';
import { isEmail } from '../../../../neb-utils/validators';

import EmailItem from './neb-email-item';
import { ELEMENTS as ELEMENTS_BASE, List } from './neb-list';

export const emailListSelectorOld = {
  genItem: () => '',
  format: v => padArray(v, ''),
  unformat: v => v.filter(item => item),
  validators: [isEmail],
};

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  items: {
    selector: 'neb-email-item',
  },
};

export default class EmailList extends List {
  static get properties() {
    return {
      helpers: Array,
      condense: {
        reflect: true,
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }

        .container {
          display: grid;
          grid-gap: ${CSS_SPACING_ROW} ${CSS_SPACING};
          grid-template-columns: 1fr;
        }

        .button-add {
          align-self: flex-start;
          margin: 0.4rem 0 ${CSS_SPACING} 0;
        }
      `,
    ];
  }

  static createModel() {
    return [EmailItem.createModel()];
  }

  static createSelectors() {
    return {
      createItem: () => EmailItem.createModel(),
      format: v => padArray(v, EmailItem.createModel()),
      unformat: v => v.filter(item => item),
      children: {
        $: EmailItem.createSelectors(),
      },
    };
  }

  initState() {
    super.initState();

    this.addLabel = 'Add Email Address';
    this.condense = false;
    this.model = [];
    this.errors = [];
    this.helpers = [];

    this.onAdd = () => {};

    this.onRemove = () => {};

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      add: () => this.onAdd(this.name),
      remove: name => this.onRemove(this.name, Number(name)),
      change: e =>
        this.onChange({
          name: `${this.name}.${e.name}`,
          value: e.value,
        }),
    };
  }

  renderItem(item, index) {
    return html`
      <neb-email-item
        .name="${index}"
        .value="${item}"
        .error="${this.errors[index]}"
        .helpers="${this.helpers[index]}"
        .onRemove="${this.handlers.remove}"
        .onChange="${this.handlers.change}"
        ?showRemoveButton="${this.model.length > 1}"
        ?condense="${this.condense}"
      ></neb-email-item>
    `;
  }
}

window.customElements.define('neb-email-list', EmailList);
