import '../../../../packages/neb-lit-components/src/components/charting/encounter-charges/neb-encounter-charges-detail';
import '../../../../packages/neb-lit-components/src/components/neb-tooltip';

import { navigate } from '@neb/router';
import { css, html } from 'lit';

import * as permissionsApiClient from '../../../../packages/neb-api-client/src/permissions-api-client';
import checkUnallocationAuthorization from '../../../../packages/neb-api-client/src/services/encounter-charge/check-unallocation-authorization';
import saveCharges from '../../../../packages/neb-api-client/src/services/encounter-charge/save-charges';
import nebTableEncounterChargesService from '../../../../packages/neb-lit-components/src/components/tables/services/neb-table-encounter-charges-service';
import {
  getFeeSchedule,
  mapSettingsCharges,
} from '../../../../packages/neb-utils/fee-schedule';
import nebEncounterChargesControllerService from '../../../../packages/neb-www-practice-charting/src/components/encounter-charges/neb-encounter-charges-controller-service';
import * as chargesApi from '../../../api-clients/charges';
import {
  CSS_COLOR_BLACK,
  CSS_COLOR_YELLOW,
  CSS_SPACING,
} from '../../../styles';
import { PANELS } from '../neb-page-unsigned-encounter';
import { PANEL_KEYS } from '../neb-unsigned-encounter-data-controller';
import {
  NebUnsignedEncounterDetail,
  BASE_ELEMENTS,
} from '../neb-unsigned-encounter-detail';
import {
  didRouteChange,
  getNotesItemFromLocalStorage,
  isNotesDirty,
  removeNotesItemFromLocalStorage,
} from '../notes/neb-chart-notes-local-storage-util';
import { safeRequest } from '../shared/safe-request';

import { formatChargesModel, formatDiagnosesChargesDetail } from './formatters';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  view: { id: 'view' },
  warningTooltip: { id: 'warning-tooltip' },
  warningPopover: { id: 'warning-popover' },
};

export const CHARGES_ITEM_NAME = 'Charges';

const CANCEL_LABEL = 'Save and Close';
const REMOVE_LABEL = 'Cancel';

class NebUnsignedEncounterChargesDetail extends NebUnsignedEncounterDetail {
  static get properties() {
    return {
      diagnoses: { type: Array },
      warnings: { type: Array },
      __activeCharges: { type: Array },
      __authorizedProcedures: { type: Array },
      __chargesModel: { type: Object },
      __addChargesModel: { type: Object },
      __formattedDiagnoses: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .icon-warning {
          width: 24px;
          height: 20px;
          padding-left: 5px;
          vertical-align: bottom;
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-warn-tooltip {
          padding-left: 5px;
          vertical-align: bottom;
          display: inline-block;
        }

        .charges-bullet-popup {
          margin: 0;
          padding: 0 ${CSS_SPACING};
        }

        .popover-text {
          font-weight: normal;
          color: ${CSS_COLOR_BLACK};
        }
      `,
    ];
  }

  static get config() {
    return {
      itemName: CHARGES_ITEM_NAME,
      refetchKey: PANEL_KEYS.CHARGES,
    };
  }

  static createModel() {
    return [];
  }

  get __elements() {
    return {
      view: this.shadowRoot.getElementById(ELEMENTS.view.id),
    };
  }

  getTitle() {
    return this.model.length > 0
      ? `${CHARGES_ITEM_NAME} (${this.model.length})`
      : CHARGES_ITEM_NAME;
  }

  get emptyAddChargesModel() {
    return {
      results: [],
      currentPage: 0,
      count: 0,
      allItems: [],
    };
  }

  initState() {
    super.initState();

    this.diagnoses = [];
    this.warnings = [];
    this.__formattedDiagnoses = [];

    this.__chargesModel = { items: [] };
    this.__addChargesModel = this.emptyAddChargesModel;
    this.__activeCharges = [];
    this.__feeSchedule = null;

    this.__authorizedProcedures = [];

    this.__hasReviewLedgerPermission = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      codeBundleAdded: () => this.onRefetch(PANEL_KEYS.CHARGES),
      checkingUnallocationAuth: charges => {
        const { items: pristineCharges } = this.__chargesModel;
        return checkUnallocationAuthorization({
          charges,
          pristineCharges,
        });
      },
      reviewLedger: () =>
        navigate(`#/patients/${this.encounter.patientId}/ledger/charges`),
      changeFilter: search => {
        if (search.length) {
          const searchTerms = search.split(' ');

          const matchingCharges = searchTerms.reduce(
            (acc, st) =>
              acc.filter(
                ({ procedure, description }) =>
                  procedure.toLowerCase().includes(st.toLowerCase()) ||
                  description.toLowerCase().includes(st.toLowerCase()),
              ),
            this.__activeCharges,
          );

          this.__addChargesModel = {
            results: matchingCharges.slice(0, 10),
            count: matchingCharges.length,
            currentPage: 1,
            allItems: matchingCharges,
          };
        } else {
          this.__addChargesModel = this.emptyAddChargesModel;
        }
      },
      changePage: pageNumber => {
        this.__addChargesModel = {
          ...this.__addChargesModel,
          currentPage: pageNumber,
          results: this.__addChargesModel.allItems.slice(
            (pageNumber - 1) * 10,
            pageNumber * 10,
          ),
        };
      },
      saveCharges: (charges, opts) =>
        this.handlers.save(charges, { ...opts, suppressSaveBanner: true }),
    };
  }

  isDirty() {
    const notesItem = getNotesItemFromLocalStorage();

    return (
      this.__elements.view.isDirty() || (notesItem && isNotesDirty(notesItem))
    );
  }

  close() {
    const notesItem = getNotesItemFromLocalStorage();

    if (notesItem) {
      this.__preventDirtyChecking = true;
      return this.onClose({ navigateTo: PANELS.CHART_NOTES });
    }

    return this.onClose();
  }

  proceededFromDirty() {
    if (didRouteChange()) {
      removeNotesItemFromLocalStorage();
    }

    super.proceededFromDirty();
  }

  async save({ items: charges }) {
    const { items: pristineCharges } = this.__chargesModel;
    const { id: encounterId, patientId, signed } = this.encounter;

    const billingEncounterCharges = await this.__loadEncounterCharges();

    return saveCharges({
      pristineCharges,
      charges,
      billingEncounterCharges,
      encounterId,
      patientId,
      signed,
    });
  }

  async __loadEncounterCharges() {
    const { items: charges } = this.__chargesModel;
    const { id: encounterId } = this.encounter;
    const { encounterCharges } = await nebEncounterChargesControllerService({
      charges,
      encounterId,
    });
    return encounterCharges;
  }

  __setChargesModel() {
    this.__chargesModel = {
      items: formatChargesModel(
        this.model,
        this.__feeSchedule,
        this.__activeCharges,
      ),
    };
  }

  __loadFeeSchedule() {
    return safeRequest(async () => {
      this.__feeSchedule = await getFeeSchedule(
        this.encounter.patientId,
        this.encounter.appointmentId,
        true,
      );
    }, 'Fee Schedule');
  }

  __loadActiveCharges() {
    return safeRequest(async () => {
      const charges = await chargesApi.getMany({
        optOutLoadingIndicator: true,
        hideInactive: true,
      });

      this.__activeCharges = mapSettingsCharges(charges, this.__feeSchedule);
    }, 'Active Charges');
  }

  __loadAuthorizedProcedures() {
    return safeRequest(async () => {
      const { appointmentId } = this.encounter;

      if (appointmentId) {
        const { authorizedProcedures } = await nebTableEncounterChargesService({
          appointmentId,
        });
        this.__authorizedProcedures = authorizedProcedures;
      } else {
        this.__authorizedProcedures = [];
      }
    }, 'Authorized Procedures');
  }

  firstUpdated() {
    this.__hasReviewLedgerPermission =
      permissionsApiClient.getChartingPermissions() &&
      permissionsApiClient.getBillingPermissions();
  }

  updated(changedProps) {
    if (changedProps.has('diagnoses') && this.diagnoses) {
      this.__formattedDiagnoses = formatDiagnosesChargesDetail(this.diagnoses);
    }

    if (
      (changedProps.has('encounter') || changedProps.has('model')) &&
      this.encounter.id
    ) {
      this.__loadingPromise = Promise.all([
        this.__loadFeeSchedule()
          .then(() => this.__loadActiveCharges())
          .then(() => this.__setChargesModel()),
        this.__loadAuthorizedProcedures(),
      ]);
    }
  }

  __getTooltipTextTemplate(warnings) {
    return warnings.length === 1
      ? warnings[0]
      : html`
          <ul class="charges-bullet-popup">
            ${
              warnings.map(
                tip =>
                  html`
                    <li>${tip}</li>
                  `,
              )
            }
          </ul>
        `;
  }

  renderWarnings() {
    if (this.warnings.length === 0) {
      return '';
    }

    const tooltipTextTemplate = this.__getTooltipTextTemplate(this.warnings);

    return html`
      <neb-tooltip
        id="${ELEMENTS.warningTooltip.id}"
        class="icon-warn-tooltip"
        icon="neb:warning"
        iconColor="${CSS_COLOR_YELLOW}"
        large
        defaultAnchor="right"
        iconWidth="${24}"
        iconHeight="${20}"
        popoverMarginTop="${20}"
        ><div
          slot="tooltip"
          id="${ELEMENTS.warningPopover.id}"
          class="popover-text"
        >
          ${tooltipTextTemplate}
        </div></neb-tooltip
      >
    `;
  }

  renderContent() {
    const providerId = this.encounter.providerId
      ? this.encounter.providerId
      : '';

    return html`
      <neb-encounter-charges-detail
        id="${ELEMENTS.view.id}"
        .model="${this.__chargesModel}"
        .providerId="${providerId}"
        .activeCharges="${this.__activeCharges}"
        .diagnoses="${this.__formattedDiagnoses}"
        .encounter="${this.encounter}"
        .addChargesModel="${this.__addChargesModel}"
        .visible="${true}"
        .layout="${this.layout}"
        .authorizedProcedures="${this.__authorizedProcedures}"
        .onSave="${this.handlers.saveCharges}"
        .onCancel="${this.handlers.cancel}"
        .onFilterChanged="${this.handlers.changeFilter}"
        .onPageChanged="${this.handlers.changePage}"
        .onCodeBundleAdded="${this.handlers.codeBundleAdded}"
        .onCheckingUnallocationAuth="${this.handlers.checkingUnallocationAuth}"
        .onReviewLedger="${this.handlers.reviewLedger}"
        .onChangeDirty="${this.handlers.changeDirty}"
        .reviewLedgerPermission="${this.__hasReviewLedgerPermission}"
        .feeSchedule="${this.__feeSchedule}"
        enable-save-and-close
        disable-save-indicator
        .cancelLabel="${CANCEL_LABEL}"
        .removeLabel="${REMOVE_LABEL}"
      ></neb-encounter-charges-detail>
    `;
  }
}

customElements.define(
  'neb-unsigned-encounter-charges-detail',
  NebUnsignedEncounterChargesDetail,
);
