import {
  DEFAULT_NAME_OPTS,
  objToName,
} from '../../../../packages/neb-utils/formatters';
import { mapPatientName } from '../../../../packages/neb-utils/patient';

const mapEncounter = encounter => ({ id: encounter.id });

const mapPatient = patient => ({
  id: patient.id,
  firstName: patient.firstName,
  lastName: patient.lastName,
  middleName: patient.middleName,
  preferredName: patient.preferredName,
  suffix: patient.suffix,
});

export const createNebFormCheckInModel = () => ({
  patient: {
    id: null,
    firstName: '',
    lastName: '',
    middleName: '',
    preferredName: '',
    suffix: '',
  },
  encounter: null,
  createEncounter: false,
});

export const mapToCheckInModel = model => {
  const { appointment } = model;

  return {
    patient: mapPatient(appointment.patient),
    encounter: appointment.encounter
      ? mapEncounter(appointment.encounter)
      : null,
    createEncounter: false,
  };
};

export const getPatientDisplayName = model =>
  objToName(mapPatientName(model.patient), DEFAULT_NAME_OPTS);

export const encounterExists = model =>
  !!model.encounter && !!model.encounter?.id;
