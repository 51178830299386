import { html, LitElement, css } from 'lit';

import { UNSUPPORTED_ADVANCED_TOKENS } from '../../../../src/utils/macros';
import { ckeditorStyles } from '../../../neb-styles/neb-ckeditor-styles';
import {
  CSS_BORDER_GREY_2,
  CSS_COLOR_HIGHLIGHT,
  CSS_COLOR_ERROR,
} from '../../../neb-styles/neb-variables';

export const ELEMENTS = {
  richTextEditor: {
    id: 'rich-text-editor',
  },
};

export const HIDE_FONT_AND_SIZE_TEMPLATE = `
    .ck.ck-dropdown.ck-font-family-dropdown, .ck.ck-dropdown.ck-font-size-dropdown {
      display: none;
    }
    `;

const buildRichTextEditorTemplate = ({
  hideFontControls = true,
  defaultFontFamily = 'Helvetica',
  defaultFontSize = '16',
}) => `
    ${ckeditorStyles}

    <style>
    html {
      height: 100%;
    }

    body {
      margin: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .ck.ck-editor {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
    }

    .ck.ck-editor__main {
      flex: 1;
      overflow-y: auto;
      font-family: sans-serif, Arial, Verdana, 'Trebuchet MS',
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border: none;
      border-top: ${CSS_BORDER_GREY_2} !important;
    }

    .ck.ck-editor__editable {
      height: 100% !important;
      box-sizing: border-box;
    }

    .ck-dropdown {
      position: relative !important;
    }

    .ck .ck-insert-table-dropdown-grid-box {
      width: var(--ck-insert-table-dropdown-box-width) !important;
      height: var(--ck-insert-table-dropdown-box-height) !important;
      margin: var(--ck-insert-table-dropdown-box-margin) !important;
      border: 1px solid var(--ck-insert-table-dropdown-box-border-color) !important;
    }

    .ck .ck-insert-table-dropdown__grid {
      width: calc(
        var(--ck-insert-table-dropdown-box-width) * 10 +
          var(--ck-insert-table-dropdown-box-margin) * 20 +
          var(--ck-insert-table-dropdown-padding) * 2
      ) !important;
      padding: var(--ck-insert-table-dropdown-padding)
        var(--ck-insert-table-dropdown-padding) 0 !important;
    }

    .ck.ck-dropdown__panel {
      background: var(--ck-color-dropdown-panel-background) !important;
      border: 1px solid var(--ck-color-dropdown-panel-border) !important;
    }

    .ck.ck-dropdown.ck-font-family-dropdown > div {
      max-height: 170px;
      overflow-y: auto;
    }

    .ck.ck-dropdown.ck-font-size-dropdown > div {
      max-height: 170px;
      overflow-y: auto;
    }

    ${hideFontControls ? HIDE_FONT_AND_SIZE_TEMPLATE : ''}

    span.ck.ck-button__label {
      font-size: 12px !important;
    }

    .ck-content .table {
      margin: 1em 0 !important;
    }

    span[contenteditable='false'] {
      cursor: pointer;
      user-select: all;
      -moz-user-select: all;
      -webkit-user-select: all;
      text-decoration: underline;
      color: ${CSS_COLOR_HIGHLIGHT};
    }

    ${Object.keys(UNSUPPORTED_ADVANCED_TOKENS).reduce(
      (acc, value) => `${acc}
      span[data-macro-replace-field='${value}'] {
        color: ${CSS_COLOR_ERROR};
      }
      `,
      '',
    )}
  </style>

  <textarea name="content" id="editor"></textarea>

  <script src="../assets/ckeditor/ckeditor.js"></script>
  <script>
    window.hideFontControls = ${hideFontControls};
    window.defaultFontFamily = '${defaultFontFamily}';
    window.defaultFontSize = '${defaultFontSize}';
  </script>
  <script src="../ckeditor-scripts/neb-rich-text-editor-script.js" type="module"></script>
`;

class NebRichTextEditor extends LitElement {
  static get properties() {
    return {
      value: String,
      hideFontControls: Boolean,
      font: Object,
      key: String,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.value = '';
    this.hideFontControls = false;
    this.font = {
      defaultFontFamily: 'Helvetica',
      defaultFontSize: '16',
    };

    this.__previousSection = -1;
    this.__editorText = '';

    this.onChange = () => {};

    this.onLinkClick = () => {};

    this.onSectionChanged = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      messageChanged: ({ source, data }) => {
        if (source !== this.__elements.iframe.contentWindow) {
          return;
        }

        if (data.iframeReady) {
          this.sync();
        } else if (data.linkClicked) {
          this.onLinkClick({
            index: data.index,
            dataset: data.dataset,
          });
        } else if (data.selectionChanged) {
          if (this.__previousSection !== data.sectionIndex) {
            this.onSectionChanged(data.sectionIndex);
          }

          this.__previousSection = data.sectionIndex;
        } else if (data.text === undefined) {
          this.__editorText = '';
        } else {
          this.__editorText = data.text;
          this.onChange(data.text);
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('message', this.__handlers.messageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('message', this.__handlers.messageChanged);
  }

  sync() {
    if (
      this.value.replace(/\n/g, '') !== this.__editorText.replace(/\n/g, '')
    ) {
      this.__elements.iframe.contentWindow.postMessage(
        {
          body: this.value,
        },
        '*',
      );
    }
  }

  firstUpdated() {
    this.__elements = {
      iframe: this.shadowRoot.getElementById(ELEMENTS.richTextEditor.id),
    };
  }

  updated(_changedProps) {
    this.sync();
  }

  insertText(text) {
    this.__elements.iframe.contentWindow.postMessage(
      {
        body: text,
        isInsert: true,
      },
      '*',
    );
  }

  setText(text) {
    this.__elements.iframe.contentWindow.postMessage(
      {
        body: text,
        isInsert: false,
      },
      '*',
    );
  }

  static get styles() {
    return css`
      :host {
        display: block;
      }

      .rich-text-editor {
        height: 100%;
        width: 100%;
        border: none;
        display: flex;
      }
    `;
  }

  render() {
    return html`
      <iframe
        id="${ELEMENTS.richTextEditor.id}"
        class="rich-text-editor"
        title="rich text editor"
        srcdoc="${
          buildRichTextEditorTemplate({
            hideFontControls: this.hideFontControls,
            defaultFontFamily: this.font.defaultFontFamily,
            defaultFontSize: this.font.defaultFontSize,
          })
        }"
      >
      </iframe>
    `;
  }
}

window.customElements.define('neb-rich-text-editor', NebRichTextEditor);
