import ApiClient, { Method, RESPONSE_TYPE } from './utils/api-client-utils';
import ApiClientV2 from './utils/api-client-v2';
import { getQueryParams } from './utils/query-params';

export const apiClient = new ApiClient({ microservice: 'api' });
export const apiClientBilling = new ApiClient({ microservice: 'billing' });
export const coreApiClient = new ApiClientV2({
  microservice: 'core',
});
export const headerJSON = { 'Content-Type': 'application/json' };

export const getEncounter = async (
  encounterId,
  optOutLoadingIndicator = false,
) => {
  const response = await apiClient.makeRequest({
    method: Method.GET,
    path: `encounters/${encounterId}`,
    headers: headerJSON,
    cacheKey: `${encounterId}-encounter`,
    optOutLoadingIndicator,
  });

  return response.data[0];
};

export const getAllEncounters = (
  patientId,
  queryParams = {},
  version = 1,
  optOutLoadingIndicator = false,
) =>
  apiClientBilling.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: `patients/${patientId}/encounters`,
    headers: headerJSON,
    queryParams,
    version,
    cacheKey: `${patientId}-${JSON.stringify(queryParams)}-all-encounters`,
  });

export const getEncounterDiagnoses = async (
  encounterId,
  optOutLoadingIndicator = false,
) => {
  const response = await apiClient.makeRequest({
    method: Method.GET,
    path: `encounters/${encounterId}/diagnoses`,
    headers: headerJSON,
    cacheKey: `${encounterId}-encounters-diagnoses`,
    optOutLoadingIndicator,
  });

  return response.data;
};

export const updateEncounterDiagnoses = (
  encounterId,
  diagnoses,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    method: Method.PUT,
    path: `encounters/${encounterId}/diagnoses`,
    headers: headerJSON,
    cacheKey: `${encounterId}-encounters-diagnoses`,
    body: JSON.stringify(diagnoses),
    optOutLoadingIndicator,
    updateNotificationDetails: { encounter: { id: encounterId } },
  });

export const copyEncounter = async (encounterId, queryParams) => {
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `encounters/${encounterId}/copy-encounter`,
    headers: headerJSON,
    queryParams,
    version: 2,
  });

  return response.data;
};

export const searchPriorEncounters = async (
  encounterId,
  filterType,
  search,
  offset = 0,
  limit = 10,
) => {
  const queryParams = {
    search,
    limit,
    offset,
    filterType,
  };
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `encounters/${encounterId}/search`,
    headers: headerJSON,
    queryParams,
    cacheKey: `${encounterId}-${JSON.stringify(
      queryParams,
    )}-search-prior-encounters`,
  });

  return response;
};

export const getEncounterHistory = async encounterId => {
  const res = await coreApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/history',
    optOutLoadingIndicator: false,
    headers: headerJSON,
    replacements: { encounterId },
    cacheKey: `${encounterId}-encounter-history`,
  });

  return res.data;
};

export const getEncounterVersion = async (encounterId, versionId) => {
  const res = await coreApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/history/:id',
    optOutLoadingIndicator: false,
    headers: headerJSON,
    replacements: { encounterId, id: versionId },
    cacheKey: `${encounterId}-${versionId}-encounter-version`,
  });

  return res.data[0];
};

export const startEncounterHistory = (
  encounterId,
  signed,
  optOutLoadingIndicator = false,
) =>
  signed
    ? coreApiClient.makeRequest({
        optOutLoadingIndicator,
        method: Method.POST,
        path:
          '/api/v1/tenants/:tenantId/encounters/:encounterId/history/signed',
        replacements: { encounterId },
        headers: headerJSON,
        responseType: RESPONSE_TYPE.RAW,
      })
    : true;

export const createEncounterHistory = (
  encounterId,
  signed,
  optOutLoadingIndicator = false,
) =>
  signed
    ? coreApiClient.makeRequest({
        optOutLoadingIndicator,
        method: Method.POST,
        path: '/api/v1/tenants/:tenantId/encounters/:encounterId/history',
        replacements: { encounterId },
        headers: headerJSON,
        responseType: RESPONSE_TYPE.RAW,
      })
    : true;

export const printEncounterSummaries = async ({ patientId, encounterIds }) => {
  const { buffer } = await apiClientBilling.makeRequest({
    method: Method.POST,
    path: `patients/${patientId}/print-encounters`,
    body: JSON.stringify({ encounterIds, multiBusiness: true }),
    headers: headerJSON,
    version: 2,
  });

  return buffer;
};

export const printEncounterVersion = async (
  encounterId,
  versionId,
  queryParams = {},
) => {
  const res = await coreApiClient.makeRequest({
    method: Method.GET,
    path: '/api/v1/tenants/:tenantId/encounters/:encounterId/history/:id/print',
    optOutLoadingIndicator: false,
    queryParams,
    headers: headerJSON,
    replacements: { encounterId, id: versionId },
    cacheKey: `${encounterId}-${versionId}-${JSON.stringify(
      queryParams,
    )}-print-encounter-version`,
  });

  return res.data[0];
};

export const updateEncounter = async (encounterId, encounter) => {
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `encounters/${encounterId}`,
    body: JSON.stringify(encounter),
    headers: headerJSON,
    updateNotificationDetails: { encounter: { id: encounterId } },
  });

  return response.data[0];
};

export const getInvoicesFromEncounters = (encounterIds = []) =>
  apiClientBilling.makeRequest({
    version: 1,
    optOutLoadingIndicator: false,
    method: Method.GET,
    path: `invoices/encounter-charges?${getQueryParams(
      'encounterIds',
      encounterIds,
    )}`,
  });

export const updateCase = ({
  patientId,
  encounterIds,
  appointmentIds = null,
  caseId = null,
  patientAuthorizationId = null,
}) =>
  apiClientBilling.makeRequest({
    version: 1,
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `patients/${patientId}/encounters/cases`,
    body: JSON.stringify({
      caseId,
      encounterIds,
      appointmentIds,
      patientAuthorizationId,
    }),
  });

export const updateEncounterDateOfCurrentIllness = async (
  encounterId,
  doci,
) => {
  const response = await apiClient.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.PUT,
    path: `encounters/${encounterId}/current-illness`,
    body: JSON.stringify(doci),
    headers: headerJSON,
    updateNotificationDetails: { encounter: { id: encounterId } },
  });

  return response.data[0];
};

export const getEncountersDetails = async encounterIds => {
  const response = await apiClientBilling.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: 'encounters-details',
    body: JSON.stringify({ encounterIds }),
    headers: headerJSON,
    version: 1,
    cacheKey: `${JSON.stringify(encounterIds)}-encounters-details`,
  });

  return response.data;
};

export const getEncounterApptsWithSelfCheckInNarratives = async (
  encounterId,
  optOutLoadingIndicator = false,
) => {
  const response = await apiClientBilling.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: `encounters/${encounterId}/questionnaire-narratives`,
    headers: headerJSON,
    version: 1,
  });

  return response.data;
};

export const updateEncounterCharges = ({
  charges,
  savedLineItems,
  encounterId,
  patientId,
}) =>
  apiClientBilling.makeRequest({
    optOutLoadingIndicator: false,
    clearCacheKeys: [encounterId],
    method: Method.PUT,
    path: `encounters/${encounterId}/charges`,
    headers: headerJSON,
    body: JSON.stringify({
      charges,
      savedLineItems,
      encounterId,
      patientId,
    }),
    version: 1,
  });

export const associateDocumentsToEncounter = async (
  encounterId,
  documentIds,
) => {
  await apiClientBilling.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `encounters/${encounterId}/documents`,
    body: JSON.stringify({ documentIds }),
    headers: headerJSON,
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
};

export const associateEncounters = async (patientId, associationDocuments) => {
  await apiClientBilling.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.POST,
    path: `patients/${patientId}/encounters/documents`,
    body: JSON.stringify({ associationDocuments }),
    headers: headerJSON,
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
};

export const removeDocumentsFromEncounter = async (
  encounterId,
  documentIds,
) => {
  await apiClientBilling.makeRequest({
    optOutLoadingIndicator: false,
    method: Method.DELETE,
    path: `encounters/${encounterId}/documents`,
    body: JSON.stringify({ documentIds }),
    headers: headerJSON,
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
};

export const updateEncounterCurrentIllnessDateWithCaseDOO = (
  encounterId,
  body,
) =>
  apiClientBilling.makeRequest({
    method: Method.PUT,
    path: `encounters/${encounterId}/current-illness-date`,
    headers: headerJSON,
    body: JSON.stringify(body),
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });

export const updateBulkEncounterCurrentIllnessDateWithCaseDOO = body =>
  apiClientBilling.makeRequest({
    method: Method.PUT,
    path: 'encounters/current-illness-date',
    headers: headerJSON,
    body: JSON.stringify(body),
    responseType: RESPONSE_TYPE.RAW,
    version: 1,
  });
