import './neb-patient-summary';

import { LitElement, html, css } from 'lit';

import { UpdateNotificationService } from '../../../../../src/services/update-notifications';
import {
  getLocationValue,
  LOCATION_KEYS,
} from '../../../../../src/utils/locations/location-util';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPatientImage } from '../../../../neb-api-client/src/patient-image-api-client';
import { openOverlayPatientInsuranceView } from '../../../../neb-app-layout/neb-open-overlay';
import { store } from '../../../../neb-redux/neb-redux-store';
import { LayoutService } from '../../../../neb-redux/services/layout';
import { LocationsService } from '../../../../neb-redux/services/locations';
import { baseStyles } from '../../../../neb-styles/neb-styles';
import * as patientUtil from '../../../../neb-utils/patient';
import { mapToPatientModel } from '../../../../neb-utils/patient';
import { mapToPatientInsuranceModel } from '../../../../neb-utils/patientInsurance';
import { openAppointmentPage } from '../../utils/appointment-overlays-util';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';

export const ELEMENTS = {
  view: {
    id: 'view',
  },
};

class PatientSummaryController extends LitElement {
  static get properties() {
    return {
      patientId: String,
      disableAlertsOverlayLink: Boolean,
      showTreatmentPlanLink: Boolean,
      alertCount: Number,
      __model: Object,
      __layout: String,
      __locations: Array,
      __loading: Boolean,
      initialModel: Object,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: block;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();

    this.onOpenAppointment = appointmentId =>
      openAppointmentPage(appointmentId);
  }

  __initState() {
    this.initialModel = {};
    this.patientId = null;
    this.alertCount = 0;
    this.disableAlertsOverlayLink = false;
    this.showTreatmentPlanLink = false;

    this.__locations = [];
    this.__loading = true;
    this.__model = patientUtil.createEmptyPatientSummaryModel();

    this.onCloseAppointmentsHistory = () => {};

    this.onModelLoaded = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      openAppointment: appointmentId => {
        this.onOpenAppointment(appointmentId);
      },
      openAppointmentHistory: (patientId, appointmentHistoryFilter) => {
        this.onCloseAppointmentsHistory();
        this.__openOverlay(
          patientId,
          'APPOINTMENT_HISTORY',
          appointmentHistoryFilter,
        );
      },
      openAlerts: patientId => this.__openOverlay(patientId, 'PATIENT_ALERT'),
      openEdit: async () => {
        const patient = await patientUtil.fetchPatient(this.__model.id);

        await openOverlay(OVERLAY_KEYS.PATIENT, patient);
        return this.__loadPatientInfo();
      },
      viewInsurance: () =>
        openOverlayPatientInsuranceView({
          patientId: this.patientId,
          patientInsurance: { id: this.__model.primaryInsurance.id },
        }),
    };
  }

  async __loadPatientInfo() {
    if (!this.patientId) return;

    const optOutLoadingIndicator = true;

    const [patientSummary, patientImage] = await Promise.all([
      patientApiClient.getPatientSummary({
        id: this.patientId,
        optOutLoadingIndicator,
      }),
      getPatientImage(this.patientId, undefined, optOutLoadingIndicator),
    ]);

    const {
      patient,
      alertsCount,
      appointmentsInfo,
      patientBalance,
      primaryInsurance,
      activeInsuranceCount,
    } = patientSummary;

    this.__model = {
      ...mapToPatientModel(patient, null, alertsCount, patientImage),
      ...appointmentsInfo,
      ...patientBalance,
      activeInsuranceCount,
      preferredLocationName: patient.preferredLocationId
        ? getLocationValue(
            this.__locations,
            patient.preferredLocationId,
            LOCATION_KEYS.NAME,
          )
        : '',
      primaryInsurance: primaryInsurance
        ? mapToPatientInsuranceModel(primaryInsurance)
        : null,
    };

    this.onModelLoaded({ ...this.__model });
    this.__loading = false;
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });

    this.__layoutService = new LayoutService(layout => {
      this.__layout = layout;
    });

    this.__notificationService = new UpdateNotificationService({
      defaultQuery: {},
      callback: () => this.__loadPatientInfo(),
    });
  }

  connectedCallback() {
    super.connectedCallback();

    this.__layoutService.connect();
    this.__locationsService.connect();
    this.__notificationService.connect();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__layoutService.disconnect();
    this.__locationsService.disconnect();
    this.__notificationService.disconnect();
  }

  updated(changedProps) {
    if (changedProps.has('patientId') && this.patientId) {
      this.__notificationService.update({
        patient: { id: this.patientId },
        alert: { patientId: this.patientId },
        appointment: { patientId: this.patientId },
        insurance: { patientId: this.patientId },
        payment: { patientId: this.patientId },
      });

      if (this.initialModel && this.initialModel.id === this.patientId) {
        this.__model = this.initialModel;
        this.__loading = false;
      } else {
        this.__loadPatientInfo();
      }
    }

    if (changedProps.has('alertCount')) {
      this.__model = {
        ...this.__model,
        alertCount: this.alertCount,
      };
    }
  }

  async __openOverlay(id, keyType, appointmentHistoryFilter) {
    const {
      popup: { overlays },
    } = store.getState();
    const isOpened = overlays.some(
      overlay => overlay.key === OVERLAY_KEYS[keyType],
    );

    if (
      !isOpened ||
      keyType === 'APPOINTMENT_HISTORY' ||
      keyType === 'PATIENT_ALERT'
    ) {
      await openOverlay(OVERLAY_KEYS[keyType], {
        patientId: id,
        ...(appointmentHistoryFilter && { filter: appointmentHistoryFilter }),
      });
    }
  }

  render() {
    return html`
      <neb-patient-summary
        id="${ELEMENTS.view.id}"
        .model="${this.__model}"
        .layout="${this.__layout}"
        .onAppointmentSelect="${this.__handlers.openAppointment}"
        .onEditPatient="${this.__handlers.openEdit}"
        .onOpenAlerts="${this.__handlers.openAlerts}"
        .onOpenAppointmentHistory="${this.__handlers.openAppointmentHistory}"
        .onViewInsurance="${this.__handlers.viewInsurance}"
        .disableAlertsOverlayLink="${this.disableAlertsOverlayLink}"
        .showTreatmentPlanLink="${this.showTreatmentPlanLink}"
        .loading="${this.__loading}"
      ></neb-patient-summary>
    `;
  }
}

window.customElements.define(
  'neb-patient-summary-controller',
  PatientSummaryController,
);
