import '../neb-card';
import '../controls/neb-tab-group';
import '../neb-nav-list';
import '../claims/neb-claims-worklist-controller';
import '../../../../../src/components/controllers/era-eob/neb-era-eob-management-controller';
import '../../../../../src/components/controllers/practice/clearinghouse/neb-practice-clearinghouse-files-controller';
import '../../../../../src/components/controllers/ledger/neb-ledger-claims-controller';

import { matchRouteSwitch, navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { hasByoc } from '../../../../../src/utils/clearinghouse-settings';
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';
import { setHoverBarYOffset } from '../../../../neb-utils/neb-ledger-util';
import { ELEMENTS as BALANCE_PAGE_ELEMENTS } from '../patients/ledger/balance/neb-ledger-balance-controller';
import { ELEMENTS as CHARGE_PAGE_ELEMENTS } from '../patients/ledger/charges/neb-ledger-charges-controller';

import { ELEMENTS as LEDGER_ELEMENTS } from './ledger/neb-practice-ledger';

export const ELEMENTS = {
  tabs: { id: 'tabs' },
  ledger: { id: 'ledger' },
  claimsWorklistController: { id: 'claims-worklist-controller' },
  ledgerClaimsController: { id: 'ledger-claims-controller' },
  eraEobManagementController: { id: 'era-eob-management-controller' },
  clearinghouseFilesController: { id: 'clearinghouse-files-controller' },
  navList: { id: 'nav-list' },
};

export const TABS = {
  LEDGER: 'ledger',
  CLAIMS_WORKLIST: 'claims-worklist',
  CLAIMS: 'claims',
  ERA_MANAGEMENT: 'era-eob-management',
  CLEARINGHOUSE_FILES: 'clearinghouse-files',
};

class NebPracticeLedgerPage extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      __hasAddOnCtBYOC: Boolean,
      route: String,
      layout: {
        reflect: true,
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__navItems = [];
    this.__selectedTab = '';
    this.__hasAddOnCtBYOC = false;
    this.route = '';
    this.layout = '';
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => {
        if (tab !== this.__selectedTab) {
          navigate(this.__buildRoute(tab));
        }
      },

      scroll: e => {
        if (this.__selectedTab === TABS.LEDGER) {
          return setHoverBarYOffset({
            context: this.shadowRoot.getElementById(ELEMENTS.ledger.id),
            top: e.currentTarget.scrollTop,
            chargesPageId: LEDGER_ELEMENTS.chargesPage.id,
            balancePageId: LEDGER_ELEMENTS.balancePage.id,
            openChargesId: CHARGE_PAGE_ELEMENTS.openCharges.id,
            closedChargesId: CHARGE_PAGE_ELEMENTS.closedCharges.id,
            outstandingChargesId: BALANCE_PAGE_ELEMENTS.outstandingCharges.id,
          });
        }

        return undefined;
      },

      selectNavItem: index => {
        const { name } = this.__navItems[index];
        navigate(this.__buildRoute(name));
      },
    };
  }

  async connectedCallback() {
    super.connectedCallback();

    this.addEventListener('scroll', this.__handlers.scroll);

    this.__hasAddOnCtBYOC = await hasByoc();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('scroll', this.__handlers.scroll);
  }

  update(changedProps) {
    if (changedProps.has('route') && this.route) {
      const tab = this.__getRouteTail();

      if (!tab) {
        if (this.layout === 'small') {
          navigate('#/practice-ledger/');
        } else {
          navigate(this.__buildRoute(TABS.LEDGER));
        }
      }

      this.__selectedTab = Object.values(TABS).includes(tab) ? tab : '';
    }

    super.update(changedProps);
  }

  __getRouteTail() {
    const segments = this.route.split('/');
    return segments.slice(1)[0];
  }

  __genNavItems() {
    let tabs = [
      {
        id: TABS.LEDGER,
        exact: false,
        label: 'Ledger',
        name: 'ledger',
        path: '/ledger/',
        resolver: () => html`
          <neb-practice-ledger
            id="${ELEMENTS.ledger.id}"
            class="content"
            .layout="${this.layout}"
            .route="${this.route}"
          >
          </neb-practice-ledger>
        `,
      },
      {
        id: TABS.CLAIMS,
        exact: false,
        label: 'Claims',
        name: 'claims',
        permission: 'billing',
        path: '/claims/',
        resolver: tail => html`
          <neb-ledger-claims-controller
            id="${ELEMENTS.ledgerClaimsController.id}"
            .layout="${this.layout}"
            .route="${tail}"
            .baseRoute="${'#/practice-ledger/claims/'}"
          ></neb-ledger-claims-controller>
        `,
      },
    ];

    if (this.layout !== 'small') {
      tabs = [
        ...tabs,
        {
          id: TABS.ERA_MANAGEMENT,
          exact: false,
          label: 'ERA/EOB',
          name: 'era-eob-management',
          permission: 'billing',
          path: '/era-eob-management/',
          resolver: tail => html`
            <neb-era-eob-management-controller
              id="${ELEMENTS.eraEobManagementController.id}"
              .layout="${this.layout}"
              .route="${tail}"
              .baseRoute="${'#/practice-ledger/era-eob-management/'}"
            >
            </neb-era-eob-management-controller>
          `,
        },
      ];
    }

    if (this.__hasAddOnCtBYOC && this.layout !== 'small') {
      tabs = [
        ...tabs,
        {
          id: TABS.CLEARINGHOUSE_FILES,
          exact: false,
          label: 'Clearinghouse Files',
          name: 'clearinghouse-files',
          permission: 'billing',
          path: '/clearinghouse-files/',
          resolver: () => html`
            <neb-practice-clearinghouse-files-controller
              id="${ELEMENTS.clearinghouseFilesController.id}"
              .layout="${this.layout}"
              .route="${this.route}"
            ></neb-practice-clearinghouse-files-controller>
          `,
        },
      ];
    }

    return tabs;
  }

  __buildRoute(tab) {
    return `#/practice-ledger/${tab}`;
  }

  static get styles() {
    return css`
      :host {
        display: block;
        overflow: auto;
        scrollbar-gutter: stable;
      }

      :host([layout='small']) {
        background-color: ${CSS_COLOR_WHITE};
      }

      :host([layout='small']) .container {
        margin-top: 0;
      }

      .container {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin: ${CSS_SPACING};
      }

      .content {
        margin: 0;
        flex: 1 0 0;
        width: 100%;
        background-color: ${CSS_COLOR_WHITE};
        padding-bottom: 20px;
      }

      .tabs {
        border-radius: 5px 5px 0 0;
        display: table;
      }
    `;
  }

  getSelectedIndex() {
    return this.__navItems.findIndex(item => item.name === this.__selectedTab);
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectTab}"
        role="section"
      ></neb-tab-group>
    `;
  }

  __renderMobile() {
    const selectedIndex = this.getSelectedIndex();

    return selectedIndex === -1
      ? html`
          <neb-nav-list
            id="${ELEMENTS.navList.id}"
            class="list"
            .selectedIndex="${selectedIndex}"
            .items="${this.__navItems.map(item => item.label)}"
            .onSelect="${this.__handlers.selectNavItem}"
          ></neb-nav-list>
        `
      : '';
  }

  render() {
    this.__navItems = this.__genNavItems();

    return html`
      <div class="container">
        ${this.layout === 'small' ? this.__renderMobile() : this.__renderTabs()}
        ${matchRouteSwitch(this.__navItems, this.route)}
      </div>
    `;
  }
}

window.customElements.define('neb-practice-ledger-page', NebPracticeLedgerPage);
