export const ERA_EOB_STATUS = { OPEN: 'Open', CLOSED: 'Closed' };

export const getEraEobStatuses = ({
  hasRcmEraEobEnhancementsFF = false,
  currentStatus,
}) => {
  const statuses = Object.values({
    ...(hasRcmEraEobEnhancementsFF ? { WORKING: 'Working' } : {}),
    ...ERA_EOB_STATUS,
  });

  return currentStatus === 'Working' ? statuses.reverse() : statuses;
};
