import ApiClient, {
  Method,
} from '../../packages/neb-api-client/src/utils/api-client-utils';

export const appointmentsApiClient = new ApiClient({
  microservice: 'appointments',
});

export const apiClient = new ApiClient({
  microservice: 'api',
});

export const getAlertsToDisplayByPatient = async patientId => {
  const res = await appointmentsApiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/alerts`,
    version: 1,
  });

  return res.data ? res.data : null;
};

export const getAllAlertsByPatient = async patientId => {
  const res = await apiClient.makeRequest({
    method: Method.GET,
    path: `patients/${patientId}/alerts`,
  });

  return res.count ? res.data[0].alerts : null;
};

export const createAlert = async (patientId, alert) => {
  const res = await apiClient.makeRequest({
    method: Method.POST,
    path: `patients/${patientId}/alerts`,
    body: JSON.stringify(alert),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};

export const updateAlerts = async (patientId, alerts, isReorder = false) => {
  const res = await apiClient.makeRequest({
    method: Method.PUT,
    path: `patients/${patientId}/alerts`,
    body: JSON.stringify({ alerts, isReorder }),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};

export const deleteAlert = async (patientId, alert) => {
  const res = await apiClient.makeRequest({
    method: Method.DELETE,
    path: `patients/${patientId}/alerts/${alert.id}`,
    body: JSON.stringify(alert),
    headers: {
      'Content-Type': 'application/json',
    },
    opts: {
      updateNotificationDetails: { alert: { patientId } },
    },
  });

  return res.count ? res.data[0].alerts : null;
};
