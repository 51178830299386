import '../../../../src/components/overlays/abandoned-response/neb-overlay-abandoned-era';
import '../../../../src/components/overlays/abandoned-response/neb-overlay-abandoned-claim-status';
import '../../../../src/components/overlays/appointments/neb-overlay-unmatched-appointments';
import '../../../../src/components/overlays/charges/neb-overlay-charge';
import '../../../../src/components/overlays/conversions/neb-overlay-conversion';
import '../../../../src/components/overlays/conversions/neb-overlay-conversion-job';
import '../../../../src/components/overlays/conversions/neb-overlay-copy-conversion';
import '../../../../src/components/overlays/hot-buttons/neb-overlay-copy-categories';
import '../../../../src/components/overlays/document-tags/neb-overlay-document-tags';
import '../../../../src/components/overlays/era-eob/neb-overlay-era-eob-management';
import '../../../../src/components/overlays/eob/neb-overlay-eob';
import '../../../../src/components/overlays/macros/neb-overlay-advanced-macro-type';
import '../../../../src/components/overlays/macros/neb-overlay-macro-folder-finder';
import '../../../../src/components/overlays/macros/neb-overlay-macro-choose';
import '../../../../src/components/overlays/macros/neb-overlay-macro-no-default';
import '../../../../src/components/overlays/macros/neb-overlay-macro-region';
import '../../../../src/components/overlays/macros/neb-overlay-macro-spine';
import '../../../../src/components/overlays/macros/neb-overlay-macro-button-type';
import '../../../../src/components/overlays/macros/neb-overlay-macro-set';
import '../../../../src/components/overlays/settings/neb-overlay-practice-location';
import '../../../../src/components/overlays/support/neb-overlay-practice-details';
import '../../../../src/components/overlays/neb-overlay-relationship';
import '../../../../src/components/overlays/neb-overlay-update-payment';
import '../../../../src/components/overlays/neb-overlay-associated-rule-sets';
import '../../../../src/components/overlays/settings/neb-overlay-add-code-bundle';
import '../../../../src/components/overlays/settings/neb-overlay-add-clearinghouse';
import '../../../../src/components/overlays/settings/neb-overlay-add-hot-buttons';
import '../../../../src/components/overlays/settings/neb-overlay-hot-buttons';
import '../../../../src/components/overlays/settings/neb-overlay-associate-rules';
import '../../../../src/components/overlays/settings/neb-overlay-payer-rule-set';
import '../../../../src/components/overlays/settings/neb-overlay-charge-rule-set';
import '../../../../src/components/overlays/settings/neb-overlay-associate-charges';
import '../../../../src/components/overlays/settings/neb-overlay-associate-payer-groups';
import '../../../../src/components/overlays/settings/neb-overlay-add-check-in-questions';
import '../../../../src/components/overlays/encounters/neb-overlay-view-code-bundle-detail';
import '../../../../src/components/overlays/encounters/neb-overlay-add-code-bundle-to-encounter';
import '../../../../src/components/overlays/encounters/neb-overlay-visit-summary';
import '../../../../src/components/overlays/ledger/payments/neb-overlay-split-payment';
import '../../../../src/components/overlays/era-eob/payments/neb-overlay-associate-payment';
import '../../../../src/components/overlays/patient/encounters/neb-overlay-associate-encounters';
import '../../../../src/components/overlays/ledger/neb-overlay-ledger-line-item';
import '../../../../src/components/overlays/neb-overlay-encounter-info';
import '../../../../src/components/overlays/appointments/neb-overlay-appointment-form';
import '../../../../src/components/overlays/patient/neb-overlay-case';
import '../../../../src/components/overlays/ledger/neb-overlay-billing-note';
import '../../../../src/components/overlays/settings/neb-overlay-rule-set-associate-payer';
import '../../../../src/components/overlays/settings/billing/neb-overlay-fee-schedules';

import '../components/overlays/neb-overlay-authorization';
import '../components/overlays/neb-overlay-add-diagnoses';
import '../components/overlays/neb-overlay-add-authorized-procedures';
import '../components/overlays/scheduling/neb-overlay-rescheduled-appointment-page';
import '../components/overlays/encounter/neb-overlay-encounter-history';
import '../components/overlays/encounter/neb-overlay-encounter-list';
import '../components/overlays/encounter/neb-overlay-encounter-summary';
import '../components/overlays/patient/neb-overlay-patient';
import '../components/overlays/patient/neb-overlay-patient-add-charge';
import '../components/overlays/patient/neb-overlay-patient-alert';
import '../components/overlays/patient/neb-overlay-patient-create';
import '../components/overlays/patient/neb-overlay-patient-list';
import '../components/overlays/patient/neb-overlay-patient-insurance-add';
import '../components/overlays/patient/neb-overlay-patient-insurance-view';
import '../components/overlays/patient/neb-overlay-patient-insurance-edit';
import '../components/overlays/patient/neb-overlay-patient-insurance-add-covered-procedure';
import '../components/overlays/patient/neb-overlay-patient-insurance-services';
import '../components/overlays/patient/neb-overlay-patient-package-add';
import '../components/overlays/patient/neb-overlay-patient-package-edit';
import '../components/overlays/patient/neb-overlay-patient-package-history';
import '../components/overlays/patient/neb-overlay-patient-associate-fee-schedule';
import '../components/overlays/patient/ledger/neb-overlay-claim-status-history-log';
import '../components/overlays/patient/ledger/neb-overlay-ledger-add-charge';
import '../components/overlays/patient/ledger/neb-overlay-ledger-encounter-charge';
import '../components/overlays/patient/ledger/neb-overlay-ledger-purchase';
import '../components/overlays/patient/ledger/neb-overlay-ledger-fee';
import '../components/overlays/patient/ledger/neb-overlay-ledger-view-selected-charges';
import '../components/overlays/patient/ledger/neb-overlay-ledger-view-selected-charges-v2';
import '../components/overlays/patient/ledger/neb-overlay-ledger-generate-claim';
import '../components/overlays/payment/neb-overlay-add-payment';
import '../components/overlays/payment/neb-overlay-add-patient-payment';
import '../components/overlays/payment/neb-overlay-add-payer-payment';
import '../components/overlays/payment/neb-overlay-add-scheduled-payment';
import '../components/overlays/payment/neb-overlay-allocate-payment';
import '../components/overlays/payment/neb-overlay-scheduled-payment';
import '../components/overlays/payment/neb-overlay-failed-scheduled-payment';
import '../components/overlays/payment/neb-overlay-payment-detail';
import '../components/overlays/payment/neb-overlay-payment-detail-2';
import '../components/overlays/scheduling/neb-overlay-appointment-edit-form';
import '../components/overlays/scheduling/neb-overlay-appointment-page';
import '../components/overlays/scheduling/neb-overlay-check-in-out';
import '../../../../src/features/check-in-out/components/check-in/neb-overlay-check-in';
import '../../../../src/features/check-in-out/components/check-out/neb-overlay-check-out';
import '../components/overlays/scheduling/neb-overlay-discount';
import '../components/overlays/scheduling/neb-overlay-request-appointment';
import '../components/overlays/settings/neb-overlay-inventory-item';
import '../components/overlays/settings/neb-overlay-merchant-account';
import '../components/overlays/settings/neb-overlay-card-readers';
import '../components/overlays/settings/neb-overlay-add-payer-plans';
import '../components/overlays/settings/neb-overlay-associate-payer';
import '../components/overlays/settings/neb-overlay-payer-groups';
import '../components/overlays/settings/neb-overlay-organization';
import '../components/overlays/settings/neb-overlay-payer-plan';
import '../components/overlays/settings/neb-overlay-select-charges';
import '../components/overlays/settings/neb-overlay-package-template';
import '../components/overlays/neb-overlay-appointment-history';
import '../components/overlays/neb-overlay-appointment-type';
import '../components/overlays/neb-overlay-blocked-off-time-form';
import '../../../../src/components/overlays/blocked-off-time/neb-overlay-blocked-off-time-page';
import '../components/overlays/neb-overlay-claim-batches';
import '../components/overlays/neb-overlay-feature-flag';
import '../components/overlays/neb-overlay-guarantor';
import '../components/overlays/neb-overlay-user';
import '../components/overlays/neb-overlay-view-batches';
import '../components/overlays/neb-overlay-person';
import '../components/overlays/neb-overlay-test';
import '../components/overlays/support/neb-overlay-practice';
import '../components/overlays/neb-overlay-unlinked-claim-responses';
import '../components/overlays/neb-overlay-add-insurance';
import '../components/overlays/patient/neb-overlay-add-insurance-rte';
import '../components/overlays/settings/neb-overlay-clearinghouse';
import '../components/overlays/settings/neb-overlay-vendor';
import '../components/overlays/encounter/neb-overlay-manage-encounter';
import '../components/overlays/neb-overlay-rooms';
import '../components/overlays/neb-overlay-failed-payments';
import '../../../../src/components/overlays/statements/neb-overlay-preview-statement-batch';
import '../../../../src/components/overlays/era-eob/logs/neb-overlay-report-logs';
import '../../../../src/components/overlays/patient/packages/neb-overlay-patient-packages-summary';
import '../../../../src/components/overlays/settings/neb-overlay-referral-source-category';
import '../../../../src/components/overlays/claim-errors/neb-overlay-claim-error';
import '../../../../src/components/overlays/claim-errors/neb-overlay-claim-error-itd-doo';
import '../../../../src/components/overlays/claim-errors/neb-overlay-claim-error-manage-encounter';

import { html } from 'lit';

import { OVERLAY_KEYS } from './overlay-constants';

export const RENDERER_OVERLAYS = {
  [OVERLAY_KEYS.ABANDONED_CLAIM_STATUS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-abandoned-claim-status
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-abandoned-claim-status>
  `,
  [OVERLAY_KEYS.ABANDONED_ERA.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-abandoned-era
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-abandoned-era>
  `,
  [OVERLAY_KEYS.AUTHORIZATION.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-authorization
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-authorization>
  `,
  [OVERLAY_KEYS.ADD_AUTHORIZED_PROCEDURES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-authorized-procedures
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-authorized-procedures>
  `,
  [OVERLAY_KEYS.ADD_DIAGNOSIS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-diagnoses
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-diagnoses>
  `,
  [OVERLAY_KEYS.ADD_HOT_BUTTONS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-hot-buttons
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-hot-buttons>
  `,
  [OVERLAY_KEYS.PAYER_RULE_SET.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-payer-rule-set
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-payer-rule-set>
  `,
  [OVERLAY_KEYS.CHARGE_RULE_SET.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-charge-rule-set
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-charge-rule-set>
  `,
  [OVERLAY_KEYS.ASSOCIATE_CHARGES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associate-charges
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-associate-charges>
  `,
  [OVERLAY_KEYS.ASSOCIATE_PAYER_GROUPS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associate-payer-groups
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-associate-payer-groups>
  `,
  [OVERLAY_KEYS.ASSOCIATE_PAYER_GROUPS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associate-payer-groups
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-associate-payer-groups>
  `,
  [OVERLAY_KEYS.ADD_CHARGE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-charge
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-charge>
  `,
  [OVERLAY_KEYS.ADD_CODE_BUNDLE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-code-bundle
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-code-bundle>
  `,
  [OVERLAY_KEYS.ADD_CODE_BUNDLE_TO_ENCOUNTER.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-code-bundle-to-encounter
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-code-bundle-to-encounter>
  `,
  [OVERLAY_KEYS.ADD_CHECK_IN_QUESTIONS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-check-in-questions
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-check-in-questions>
  `,
  [OVERLAY_KEYS.ADD_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-payment>
  `,
  [OVERLAY_KEYS.ALLOCATE_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-allocate-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-allocate-payment>
  `,
  [OVERLAY_KEYS.ADD_PATIENT_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-patient-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-patient-payment>
  `,
  [OVERLAY_KEYS.ADD_PAYER_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-payer-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-payer-payment>
  `,
  [OVERLAY_KEYS.UPDATE_PAYER_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-update-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-update-payment>
  `,
  [OVERLAY_KEYS.RELATIONSHIP.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-relationship
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-relationship>
  `,
  [OVERLAY_KEYS.REPORT_LOGS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-report-logs
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-report-logs>
  `,
  [OVERLAY_KEYS.RESCHEDULED_APPOINTMENT_PAGE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-rescheduled-appointment-page
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-rescheduled-appointment-page>
  `,
  [OVERLAY_KEYS.ADD_SCHEDULED_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-scheduled-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-scheduled-payment>
  `,
  [OVERLAY_KEYS.APPOINTMENT_EDIT_FORM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-appointment-edit-form
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-appointment-edit-form>
  `,
  [OVERLAY_KEYS.APPOINTMENT_FORM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-appointment-form
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-appointment-form>
  `,
  [OVERLAY_KEYS.APPOINTMENT_PAGE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-appointment-page
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-appointment-page>
  `,
  [OVERLAY_KEYS.APPOINTMENT_HISTORY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-appointment-history
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-appointment-history>
  `,
  [OVERLAY_KEYS.APPOINTMENT_REQUEST.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-request-appointment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-request-appointment>
  `,
  [OVERLAY_KEYS.APPOINTMENT_TYPE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-appointment-type
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-appointment-type>
  `,
  [OVERLAY_KEYS.ASSOCIATE_FEE_SCHEDULE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-associate-fee-schedule
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-associate-fee-schedule>
  `,
  [OVERLAY_KEYS.BLOCKED_OFF_TIME_FORM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-blocked-off-time-form
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-blocked-off-time-form>
  `,
  [OVERLAY_KEYS.BLOCKED_OFF_TIME_PAGE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-blocked-off-time-page
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-blocked-off-time-page>
  `,
  [OVERLAY_KEYS.CASE.name]: (hide, index, layout, model, closeHandler) => html`
    <neb-overlay-case
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    >
    </neb-overlay-case>
  `,
  [OVERLAY_KEYS.CHECK_IN_OUT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-check-in-out
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-check-in-out>
  `,
  [OVERLAY_KEYS.CHECK_IN.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-check-in
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-check-in>
  `,
  [OVERLAY_KEYS.CHECK_OUT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-check-out
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-check-out>
  `,
  [OVERLAY_KEYS.DISCOUNT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-discount
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-discount>
  `,
  [OVERLAY_KEYS.CLAIM_BATCHES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-claim-batches
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-claim-batches>
  `,
  [OVERLAY_KEYS.CLAIM_HISTORY_LOG.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-claim-status-history-log
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-claim-status-history-log>
  `,
  [OVERLAY_KEYS.CONVERSION.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-conversion
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-conversion>
  `,
  [OVERLAY_KEYS.CONVERSION_COPY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) =>
    html`
      <neb-overlay-copy-conversion
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-overlay-copy-conversion>
    `,
  [OVERLAY_KEYS.CONVERSION_JOB.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) =>
    html`
      <neb-overlay-conversion-job
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-overlay-conversion-job>
    `,
  [OVERLAY_KEYS.COPY_CATEGORIES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) =>
    html`
      <neb-overlay-copy-categories
        .index="${index}"
        .layout="${layout}"
        .model="${model}"
        .onClose="${closeHandler}"
        ?hidden="${hide}"
      ></neb-overlay-copy-categories>
    `,
  [OVERLAY_KEYS.DOCUMENT_TAGS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-document-tags
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-document-tags>
  `,
  [OVERLAY_KEYS.ENCOUNTER_HISTORY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-encounter-history
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-encounter-history>
  `,
  [OVERLAY_KEYS.ENCOUNTER_LIST.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-encounter-list
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-encounter-list>
  `,
  [OVERLAY_KEYS.ENCOUNTER_SUMMARY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-encounter-summary
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-encounter-summary>
  `,
  [OVERLAY_KEYS.ENCOUNTER_INFO.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-encounter-info
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-encounter-info>
  `,
  [OVERLAY_KEYS.FAILED_PAYMENTS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-failed-payments
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-failed-payments>
  `,
  [OVERLAY_KEYS.FAILED_SCHEDULED_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-failed-scheduled-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-failed-scheduled-payment>
  `,
  [OVERLAY_KEYS.FEE_SCHEDULES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-fee-schedules
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-fee-schedules>
  `,
  [OVERLAY_KEYS.ADD_PAYER_PLANS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-payer-plans
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-payer-plans>
  `,
  [OVERLAY_KEYS.PAYER_GROUPS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-payer-groups
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-payer-groups>
  `,
  [OVERLAY_KEYS.ASSOCIATE_PAYER.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associate-payer
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-associate-payer>
  `,
  [OVERLAY_KEYS.ASSOCIATE_RULE_SET_PAYER.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-rule-set-associate-payer
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-rule-set-associate-payer>
  `,
  [OVERLAY_KEYS.ASSOCIATE_RULES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associate-rules
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-associate-rules>
  `,
  [OVERLAY_KEYS.FORM_FEATURE_FLAG.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-feature-flag
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-feature-flag>
  `,
  [OVERLAY_KEYS.GUARANTOR.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-guarantor
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-guarantor>
  `,
  [OVERLAY_KEYS.HOT_BUTTONS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-hot-buttons
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-hot-buttons>
  `,
  [OVERLAY_KEYS.LEDGER_ADD_CHARGE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-add-charge
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-add-charge>
  `,
  [OVERLAY_KEYS.LEDGER_ENCOUNTER_CHARGE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-encounter-charge
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-encounter-charge>
  `,
  [OVERLAY_KEYS.MANAGE_ENCOUNTER.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-manage-encounter
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-manage-encounter>
  `,
  [OVERLAY_KEYS.LEDGER_PURCHASE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-purchase
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-purchase>
  `,
  [OVERLAY_KEYS.LEDGER_FEE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-fee
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-fee>
  `,
  [OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-view-selected-charges
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-view-selected-charges>
  `,
  [OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-view-selected-charges-v2
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-view-selected-charges-v2>
  `,
  [OVERLAY_KEYS.LEDGER_GENERATE_CLAIM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-generate-claim
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-generate-claim>
  `,
  [OVERLAY_KEYS.LEDGER_LINE_ITEM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-ledger-line-item
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-ledger-line-item>
  `,
  [OVERLAY_KEYS.ADVANCED_MACRO_TYPE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-advanced-macro-type
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-advanced-macro-type>
  `,
  [OVERLAY_KEYS.MACRO_FOLDER_FINDER.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-macro-folder-finder
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-macro-folder-finder>
  `,
  [OVERLAY_KEYS.MACRO_CHOOSE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-macro-choose
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-macro-choose>
  `,
  [OVERLAY_KEYS.MACRO_NO_DEFAULT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-macro-no-default
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-macro-no-default>
  `,
  [OVERLAY_KEYS.MACRO_REGION.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-macro-region
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-macro-region>
  `,
  [OVERLAY_KEYS.MACRO_SPINE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-macro-spine
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-macro-spine>
  `,
  [OVERLAY_KEYS.MACRO_BUTTON_TYPE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-macro-button-type
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-macro-button-type>
  `,
  [OVERLAY_KEYS.MACRO_SETS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-macro-set
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-macro-set>
  `,
  [OVERLAY_KEYS.INVENTORY_ITEM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-inventory-item
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-inventory-item>
  `,
  [OVERLAY_KEYS.MERCHANT_ACCOUNT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-merchant-account
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-merchant-account>
  `,
  [OVERLAY_KEYS.CARD_READERS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-card-readers
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-card-readers>
  `,
  [OVERLAY_KEYS.ORGANIZATION_FORM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-organization
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-organization>
  `,
  [OVERLAY_KEYS.SELECT_CHARGES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-select-charges
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-select-charges>
  `,
  [OVERLAY_KEYS.PACKAGE_TEMPLATE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-package-template
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-package-template>
  `,
  [OVERLAY_KEYS.PATIENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient>
  `,
  [OVERLAY_KEYS.PATIENT_ADD_CHARGE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-add-charge
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-add-charge>
  `,
  [OVERLAY_KEYS.PATIENT_ALERT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-alert
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-alert>
  `,
  [OVERLAY_KEYS.PATIENT_CREATE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-create
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-create>
  `,
  [OVERLAY_KEYS.PATIENT_LIST.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-list
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-list>
  `,
  [OVERLAY_KEYS.ASSOCIATE_ENCOUNTERS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associate-encounters
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    >
    </neb-overlay-associate-encounters>
  `,
  [OVERLAY_KEYS.PATIENT_INSURANCE_ADD.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-insurance-add
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-insurance-add>
  `,
  [OVERLAY_KEYS.PATIENT_INSURANCE_VIEW.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-insurance-view
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-insurance-view>
  `,
  [OVERLAY_KEYS.PATIENT_INSURANCE_EDIT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-insurance-edit
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-insurance-edit>
  `,
  [OVERLAY_KEYS.PATIENT_INSURANCE_SERVICES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-insurance-services
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-insurance-services>
  `,
  [OVERLAY_KEYS.PATIENT_INSURANCE_ADD_COVERED_PROCEDURE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-insurance-add-covered-procedure
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-insurance-add-covered-procedure>
  `,
  [OVERLAY_KEYS.PATIENT_PACKAGE_ADD.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-package-add
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-package-add>
  `,
  [OVERLAY_KEYS.PATIENT_PACKAGE_EDIT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-package-edit
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-package-edit>
  `,
  [OVERLAY_KEYS.PATIENT_PACKAGE_HISTORY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-package-history
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-package-history>
  `,
  [OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-patient-packages-summary
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-patient-packages-summary>
  `,
  [OVERLAY_KEYS.PAYER_PLAN.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-payer-plan
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-payer-plan>
  `,
  [OVERLAY_KEYS.PERSON.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-person
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-person>
  `,
  [OVERLAY_KEYS.PAYMENT_DETAIL.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-payment-detail
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-payment-detail>
  `,
  [OVERLAY_KEYS.PAYMENT_DETAIL_2.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-payment-detail-2
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-payment-detail-2>
  `,
  [OVERLAY_KEYS.SCHEDULED_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-scheduled-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-scheduled-payment>
  `,
  [OVERLAY_KEYS.TEST.name]: (hide, index, layout, model, closeHandler) => html`
    <neb-overlay-test
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    >
    </neb-overlay-test>
  `,
  [OVERLAY_KEYS.USER_ADD.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-user
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    >
    </neb-overlay-user>
  `,
  [OVERLAY_KEYS.UNLINKED_CLAIM_RESPONSES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-unlinked-claim-responses
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-unlinked-claim-responses>
  `,
  [OVERLAY_KEYS.UNMATCHED_APPOINTMENTS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-unmatched-appointments
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-unmatched-appointments>
  `,
  [OVERLAY_KEYS.ASSOCIATED_RULE_SETS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associated-rule-sets
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-associated-rule-sets>
  `,
  [OVERLAY_KEYS.VIEW_BATCHES.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-view-batches
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-view-batches>
  `,
  [OVERLAY_KEYS.VIEW_CODE_BUNDLE_DETAIL.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-view-code-bundle-detail
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-view-code-bundle-detail>
  `,
  [OVERLAY_KEYS.PRACTICE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-practice
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-practice>
  `,
  [OVERLAY_KEYS.PRACTICE_DETAILS.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-practice-details
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-practice-details>
  `,
  [OVERLAY_KEYS.ADD_INSURANCE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-insurance
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-insurance>
  `,
  [OVERLAY_KEYS.PRACTICE_LOCATION.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-practice-location
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-practice-location>
  `,
  [OVERLAY_KEYS.ADD_INSURANCE_RTE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-insurance-rte
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-insurance-rte>
  `,
  [OVERLAY_KEYS.CLEARINGHOUSE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-clearinghouse
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-clearinghouse>
  `,
  [OVERLAY_KEYS.VENDOR.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-vendor
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-vendor>
  `,
  [OVERLAY_KEYS.ERA_EOB_MANAGEMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-era-eob-management
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-era-eob-management>
  `,
  [OVERLAY_KEYS.EOB_FORM.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-eob
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-eob>
  `,
  [OVERLAY_KEYS.ADD_CLEARINGHOUSE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-add-clearinghouse
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-add-clearinghouse>
  `,
  [OVERLAY_KEYS.SPLIT_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-split-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-split-payment>
  `,
  [OVERLAY_KEYS.ASSOCIATE_PAYMENT.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-associate-payment
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-associate-payment>
  `,
  [OVERLAY_KEYS.ROOMS.name]: (hide, index, layout, model, closeHandler) => html`
    <neb-overlay-rooms
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-rooms>
  `,
  [OVERLAY_KEYS.VISIT_SUMMARY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-visit-summary
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-visit-summary>
  `,
  [OVERLAY_KEYS.BILLING_NOTE.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-billing-note
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-billing-note>
  `,
  [OVERLAY_KEYS.ADD_REFERRAL_SOURCE_CATEGORY.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-referral-source-category
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-referral-source-category>
  `,
  [OVERLAY_KEYS.PREVIEW_STATEMENT_BATCH.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-preview-statement-batch
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-preview-statement-batch>
  `,
  [OVERLAY_KEYS.CLAIM_ERROR.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-claim-error
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-claim-error>
  `,
  [OVERLAY_KEYS.CLAIM_ERROR_ITD_DOO.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-claim-error-itd-doo
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-claim-error-itd-doo>
  `,
  [OVERLAY_KEYS.CLAIM_ERROR_MANAGE_ENCOUNTER.name]: (
    hide,
    index,
    layout,
    model,
    closeHandler,
  ) => html`
    <neb-overlay-claim-error-manage-encounter
      .index="${index}"
      .layout="${layout}"
      .model="${model}"
      .onClose="${closeHandler}"
      ?hidden="${hide}"
    ></neb-overlay-claim-error-manage-encounter>
  `,
};
