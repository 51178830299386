import '../../../packages/neb-lit-components/src/components/neb-button-actions';
import '../../components/misc/neb-icon';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textbox';
import { openPopup } from '@neb/popup';
import { html, LitElement, css } from 'lit';

import { getAppointmentBillingInfo } from '../../../packages/neb-api-client/src/appointment-billing-info-api-client';
import { getLineItemDetails } from '../../../packages/neb-api-client/src/ledger/line-items';
import { getPatientGuarantors } from '../../../packages/neb-api-client/src/patient-guarantor-api-client';
import {
  openOverlayPatientInsuranceView,
  openOverlayPatientPackageEdit,
} from '../../../packages/neb-app-layout/neb-open-overlay';
import { openWarning } from '../../../packages/neb-dialog/neb-banner-state';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import {
  disableSignIfEncounterOnly,
  hideIfEncounterOnly,
} from '../../../packages/neb-lit-components/src/utils/encounter-only-util';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import { BILL_TYPE } from '../../../packages/neb-utils/neb-ledger-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../packages/neb-utils/neb-refresh';
import {
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  hasAuthorizationRemaining,
} from '../../../packages/neb-utils/patientAuthorization';
import {
  baseStyles,
  CSS_COLOR_GREY_1,
  CSS_COLOR_HIGHLIGHT,
  CSS_WARNING_COLOR,
  layoutStyles,
} from '../../styles';

import {
  mapModelToView,
  verifyAssociatedInvoices,
} from './neb-charting-encounter-header-util';
import {
  createNewCase,
  updateAssociatedCase,
  updateEncounterDOOCaseDOO,
} from './neb-charting-util';

export const ELEMENTS = {
  buttonActions: { id: 'button-actions' },
  buttonReopen: { id: 'button-reopen' },
  buttonSign: { id: 'button-sign' },
  iconAuthorizationWarning: { id: 'icon-authorization-warning' },
  iconClose: { id: 'icon-close' },
  editCase: { id: 'edit-case' },
  editAuthorization: { id: 'edit-authorization' },

  appointmentType: {
    id: 'appointment-type',
    label: 'Appt Type',
  },
  DOS: { id: 'dos', label: 'DOS' },
  patientCase: { id: 'patient-case', label: 'Case' },
  lastVisit: { id: 'last-visit', label: 'Last Visit' },
  billType: { id: 'bill-type', label: 'Bill Type' },
  renderingProvider: { id: 'rendering-provider', label: 'Rendering Provider' },
  planName: { id: 'plan-name', label: 'Plan' },
  authorization: { id: 'authorization', label: 'Authorization' },
};
class NebChartingEncounterHeader extends LitElement {
  static get properties() {
    return {
      __authWarningShownOnce: Boolean,
      __previousAuthorization: Object,
      __multiCarePackageEnabled: Boolean,
      __lineItems: Array,

      historyExists: Boolean,
      layout: {
        type: String,
        reflect: true,
      },
      model: Object,
    };
  }

  static createModel() {
    return {
      appointmentType: '',
      appointment: {
        status: '',
        roomId: null,
      },
      case: {
        label: 'No Case',
      },
      patientAuthorizationId: '',
      fullDate: '',
      provider: '',
      encounterInfo: {},
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .container-actions {
          margin-top: 10px;
          width: 200px;
        }

        .grid-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 24px 24px;
          column-gap: 20px;
          row-gap: 0px;
          width: 100%;
        }

        .grid-label-value {
          display: grid;
          grid-template-columns: fit-content(100%) minmax(40px, auto);
          grid-template-rows: 24px;
          column-gap: 5px;
          width: auto;
        }

        .grid-label-icon-value {
          display: grid;
          grid-template-columns: fit-content(100%) 24px minmax(40px, auto);
          grid-template-rows: 24x;
          column-gap: 5px;
          width: auto;
        }

        .link {
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
        }

        .edit-icon {
          cursor: pointer;
          margin-top: 2px;
          margin-right: 2px;
          width: 12px;
          height: 12px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button-actions {
          height: 24px;
          width: 24px;

          z-index: 2;
        }

        .icon-close {
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_COLOR_GREY_1};
        }

        .icon-authorization-warning {
          cursor: pointer;
          display: inline-block;
          width: 20px;
          height: 20px;
          padding-top: 0px;
          padding-bottom: 0px;
          fill: ${CSS_WARNING_COLOR};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__authWarningShownOnce = false;
    this.__previousAuthorization = null;
    this.__multiCarePackageEnabled = false;
    this.__lineItems = [];
    this.__menuItems = Object.freeze({
      REQUEST_NEXT_APPOINTMENT: {
        label: 'Request Next Appointment',
        onSelect: () => this.onRequestNextAppointment(),
      },
      ADD_NEW_APPOINTMENT: {
        label: 'Add New Appointment',
        onSelect: () => this.onAddNewAppointment(),
      },
      CHANGE_ROOM: {
        label: 'Change Room',
        onSelect: () => this.onChangeRoom(),
      },
      DELETE_ENCOUNTER: {
        label: 'Delete Encounter',
        onSelect: () => this.onDeleteEncounter(),
      },
      PRINT_ENCOUNTER: {
        label: 'Print Encounter',
        onSelect: () => this.onPrint(),
      },
      COPY_PRIOR_ENCOUNTER: {
        label: 'Copy Prior Encounter',
        onSelect: () => this.onOpenCopyPriorEncounter(),
      },
      VIEW_ENCOUNTER_HISTORY: {
        label: 'View Encounter History',
        onSelect: () => this.onViewHistory(),
      },
      OPEN_VISIT_SUMMARY: {
        label: 'Open Visit Summary',
        onSelect: () => this.onOpenVisitSummary(),
      },
    });

    this.historyExists = false;
    this.layout = '';
    this.model = {
      appointmentId: '',
      appointmentType: '',
      appointment: {
        status: '',
        roomId: null,
      },
      case: {
        label: 'No Case',
      },
      caseId: '',
      fullDate: '',
      provider: '',
      encounterInfo: {},
    };

    this.onAddNewAppointment = () => {};

    this.onCloseDashboard = () => {};

    this.onOpenCopyPriorEncounter = () => {};

    this.onOpenSignEncounterPopup = () => {};

    this.onOpenReopenEncounterPopup = () => {};

    this.onRequestNextAppointment = () => {};

    this.onChangeRoom = () => {};

    this.onDeleteEncounter = () => {};

    this.onPrint = () => {};

    this.onViewHistory = () => {};

    this.onOpenVisitSummary = () => {};

    this.onAuthorizationDetailChanged = () => {};

    this.onUpdateEncounter = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      close: () => this.onCloseDashboard(),
      reopen: () => this.onOpenReopenEncounterPopup(),
      sign: () => this.onOpenSignEncounterPopup(),
      openUpdateAuthorization: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          id: this.model.patientAuthorizationId,
          patientId: this.model.patientId,
          patientCaseId: this.model.caseId,
        });

        if (result) this.onAuthorizationDetailChanged();
      },
      addNewCase: async () => {
        const warningMessageResult = await verifyAssociatedInvoices([
          this.model.id,
        ]);
        if (!warningMessageResult) return;

        const result = await createNewCase(this.model.patientId);

        if (!result) return;

        await this.__updateAssociatedCase(result.id);
      },
      openCase: async () => {
        if (!this.model.case) {
          return;
        }
        const guarantors = await getPatientGuarantors(this.model.patientId);

        const result = await openOverlay(OVERLAY_KEYS.CASE, {
          item: this.model.case,
          context: {
            patientId: this.model.patientId,
            guarantors,
            onGuarantorChange: () => {},
            onAuthorizationChange: () => {},
          },
        });

        if (result && result.patientCase) this.onUpdateEncounter();
      },
      openEditCasePopup: async () => {
        const result = await openPopup(
          POPUP_RENDER_KEYS.UPDATE_ASSOCIATED_CASE,
          {
            encounterIds: [this.model.id],
            patientId: this.model.patientId,
            defaultCaseId: this.model.caseId,
            patientAuthorizationId: this.model.patientAuthorizationId,
          },
        );

        if (!result) return;

        if (result.type === 'updateCase') {
          await this.__updateAssociatedCase(
            result.caseId,
            result.patientAuthorizationId,
          );
        }

        if (result.type === 'addNewCase') {
          await this.__handlers.addNewCase();
        }
      },

      openPlan: () =>
        this.__multiCarePackageEnabled &&
        this.model.encounterInfo.appointmentBillingInfo.billType ===
          BILL_TYPE.PACKAGE
          ? openOverlay(OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY, {
              patientId: this.model.patientId,
              lineItems: this.__lineItems,
            })
          : this.__openPlanView(),

      authorizationWarningClick: () => this.__showAuthorizationWarning(),
    };
  }

  async __updateAssociatedCase(newCaseId, newPatientAuthorizationId) {
    const { id: encounterId, appointmentId, patientId, caseId } = this.model;

    await updateAssociatedCase({
      encounterIds: [encounterId],
      appointmentIds: [appointmentId],
      patientId,
      newCaseId,
      newPatientAuthorizationId,
    });

    if (encounterId && newCaseId && newCaseId !== caseId) {
      await updateEncounterDOOCaseDOO(encounterId, {
        patientCaseId: newCaseId,
      });
    }

    this.onUpdateEncounter();
  }

  async updated(changedProps) {
    if (changedProps.has('model')) {
      if (this.model.id) {
        this.__lineItems = await getLineItemDetails({
          encounterId: this.model.id,
        });
      }

      if (this.model.appointmentId) {
        const appointmentBillingInfo = await getAppointmentBillingInfo(
          this.model.appointmentId,
        );

        if (appointmentBillingInfo) {
          this.__multiCarePackageEnabled =
            appointmentBillingInfo.multiCarePackage;
        }
      }
    }
  }

  async __openPlanView() {
    if (
      this.model.encounterInfo.appointmentBillingInfo.billType ===
      BILL_TYPE.INSURANCE
    ) {
      await openOverlayPatientInsuranceView({
        patientId: this.model.patientId,
        patientInsurance: {
          id: this.model.encounterInfo.appointmentBillingInfo.primaryInsurance
            .id,
        },
      });

      sendRefreshNotification(
        [REFRESH_CHANGE_TYPE.PATIENT_INSURANCE, REFRESH_CHANGE_TYPE.PATIENT],
        this.model.patientId,
      );
    }

    if (
      this.model.encounterInfo.appointmentBillingInfo.billType ===
      BILL_TYPE.PACKAGE
    ) {
      await openOverlayPatientPackageEdit({
        item: {
          id: this.model.encounterInfo.appointmentBillingInfo.patientPackage.id,
          patientId: this.model.patientId,
        },
      });
    }
  }

  __shouldRenderChangeRoom(items) {
    if (this.model.appointment.status === 'Checked-In') {
      items.splice(2, 0, this.__menuItems.CHANGE_ROOM);
    }
    return items;
  }

  __getMenuItems() {
    const dropdownItems = [
      this.__menuItems.REQUEST_NEXT_APPOINTMENT,
      this.__menuItems.ADD_NEW_APPOINTMENT,
      this.__menuItems.PRINT_ENCOUNTER,
    ];

    if (this.model.appointment) {
      this.__shouldRenderChangeRoom(dropdownItems);
    }

    if (this.historyExists) {
      dropdownItems.push(this.__menuItems.VIEW_ENCOUNTER_HISTORY);
    }

    if (!this.model.signed) {
      if (!hideIfEncounterOnly(this.model)) {
        dropdownItems.push(this.__menuItems.COPY_PRIOR_ENCOUNTER);
      }
      dropdownItems.push(this.__menuItems.DELETE_ENCOUNTER);
    }

    dropdownItems.push(this.__menuItems.OPEN_VISIT_SUMMARY);

    return dropdownItems;
  }

  __showAuthorizationWarning() {
    if (this.layout === 'small') {
      store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
    } else {
      store.dispatch(
        openWarning(
          NO_REMAINING_AUTHORIZATIONS_MESSAGE,
          this.__handlers.openUpdateAuthorization,
        ),
      );
    }
  }

  __setPreviousAuthAndShowWarning() {
    this.__previousAuthorization = this.model.case.patientAuthorizations?.find(
      authorization => authorization.id === this.model.patientAuthorizationId,
    );

    this.__showAuthorizationWarning();
  }

  __previousAuthorizationHasRemaining() {
    return hasAuthorizationRemaining(this.__previousAuthorization);
  }

  __currentAuthorizationHasRemaining() {
    const value = this.model.case.patientAuthorizations?.find(
      authorization => authorization.id === this.model.patientAuthorizationId,
    );

    return hasAuthorizationRemaining(value);
  }

  __handleAuthorizationWarning() {
    if (
      this.__previousAuthorizationHasRemaining() &&
      !this.__currentAuthorizationHasRemaining()
    ) {
      this.__setPreviousAuthAndShowWarning();
      return;
    }

    if (
      !this.__previousAuthorizationHasRemaining() &&
      this.__currentAuthorizationHasRemaining()
    ) {
      this.__authWarningShownOnce = false;
    }
  }

  __handleAuthorizationWarningOnFirstLoad() {
    if (!this.__currentAuthorizationHasRemaining()) {
      this.__authWarningShownOnce = true;

      this.__setPreviousAuthAndShowWarning();
    }
  }

  update(changedProps) {
    if (changedProps.has('model') && this.model && this.model.case) {
      if (!this.__authWarningShownOnce) {
        this.__handleAuthorizationWarningOnFirstLoad();
      }

      this.__handleAuthorizationWarning();
    }

    super.update(changedProps);
  }

  __getAuthorizationClassName(viewModel) {
    return viewModel.showAuthorizationWarning
      ? 'grid-label-icon-value'
      : 'grid-label-value';
  }

  __renderAuthorizationIcon(viewModel) {
    return viewModel.showAuthorizationWarning
      ? html`
          <neb-icon
            id="${ELEMENTS.iconAuthorizationWarning.id}"
            class="icon-authorization-warning"
            icon="neb:warning"
            @click="${this.__handlers.authorizationWarningClick}"
          ></neb-icon>
        `
      : html``;
  }

  __renderAuthorization(viewModel) {
    return html`
      <div class="${this.__getAuthorizationClassName(viewModel)}">
        <neb-text bold truncate>
          ${
            !this.model.signed
              ? html`
                  <neb-icon
                    id="${ELEMENTS.editAuthorization.id}"
                    class="edit-icon"
                    icon="neb:edit"
                    @click="${this.__handlers.openEditCasePopup}"
                  ></neb-icon>
                `
              : ''
          }${ELEMENTS.authorization.label}:</neb-text
        >
        ${this.__renderAuthorizationIcon(viewModel)}
        <neb-text
          id="${ELEMENTS.authorization.id}"
          class="${viewModel.authorization ? 'link' : ''}"
          link
          truncate
          .onClick="${this.__handlers.openUpdateAuthorization}"
          >${viewModel.authorization}</neb-text
        >
      </div>
    `;
  }

  __renderPlanLinkOrCheckmark(viewModel) {
    if (
      this.__multiCarePackageEnabled &&
      this.model.encounterInfo.appointmentBillingInfo.billType ===
        BILL_TYPE.PACKAGE &&
      !this.__lineItems.length
    ) {
      return '';
    }

    return this.__multiCarePackageEnabled &&
      this.model.encounterInfo.appointmentBillingInfo.billType ===
        BILL_TYPE.PACKAGE
      ? html`
          <neb-text
            id="${ELEMENTS.planName.id}"
            class="link"
            link
            .onClick="${this.__handlers.openPlan}"
            >✓</neb-text
          >
        `
      : html`
          <neb-text
            id="${ELEMENTS.planName.id}"
            class="${viewModel.planName ? 'link' : ''}"
            link
            truncate
            @click="${this.__handlers.openPlan}"
            >${viewModel.planName}</neb-text
          >
        `;
  }

  __renderPlan(viewModel) {
    return html`
      <div class="grid-label-value">
        <neb-text bold truncate>${ELEMENTS.planName.label}:</neb-text>
        <span>${this.__renderPlanLinkOrCheckmark(viewModel)}</span>
      </div>
    `;
  }

  render() {
    if (!this.model) return html``;

    const viewModel = mapModelToView(this.model);

    return html`
      <div class="container">
        <div class="grid-container">
          <div class="grid-label-value">
            <neb-text bold truncate>${ELEMENTS.DOS.label}:</neb-text>
            <neb-text truncate id="${ELEMENTS.DOS.id}"
              >${viewModel.dateOfService}</neb-text
            >
          </div>
          <div class="grid-label-value">
            <neb-text bold truncate>
              ${
                !this.model.signed
                  ? html`
                      <neb-icon
                        id="${ELEMENTS.editCase.id}"
                        class="edit-icon"
                        icon="neb:edit"
                        @click="${this.__handlers.openEditCasePopup}"
                      ></neb-icon>
                    `
                  : ''
              }${ELEMENTS.patientCase.label}:</neb-text
            >
            <neb-text
              id="${ELEMENTS.patientCase.id}"
              class="${viewModel.caseName ? 'link' : ''}"
              link
              truncate
              @click="${this.__handlers.openCase}"
              >${viewModel.caseName}</neb-text
            >
          </div>
          <div class="grid-label-value">
            <neb-text bold truncate>${ELEMENTS.lastVisit.label}:</neb-text>
            <neb-text truncate id="${ELEMENTS.lastVisit.id}"
              >${viewModel.lastVisit}</neb-text
            >
          </div>
          <div class="grid-label-value">
            <neb-text bold truncate>${ELEMENTS.billType.label}:</neb-text>
            <neb-text truncate id="${ELEMENTS.billType.id}"
              >${viewModel.billType}</neb-text
            >
          </div>
          <div class="grid-label-value">
            <neb-text bold truncate
              >${ELEMENTS.appointmentType.label}:</neb-text
            >
            <neb-text truncate id="${ELEMENTS.appointmentType.id}"
              >${viewModel.appointmentType}</neb-text
            >
          </div>
          <div class="grid-label-value">
            <neb-text bold truncate
              >${ELEMENTS.renderingProvider.label}:</neb-text
            >
            <neb-text truncate id="${ELEMENTS.renderingProvider.id}"
              >${viewModel.provider}</neb-text
            >
          </div>
          ${this.__renderAuthorization(viewModel)}
          ${this.__renderPlan(viewModel)}
        </div>

        <div class="grid grid-3 grid-lean container-actions">
          ${this.__renderButtons()}

          <neb-button-actions
            id="${ELEMENTS.buttonActions.id}"
            class="button button-actions"
            align="right"
            maxVisibleItems="10"
            vertical
            .value="${this.__getMenuItems()}"
            .forceDownMenu="${true}"
          ></neb-button-actions>

          <neb-icon
            id="${ELEMENTS.iconClose.id}"
            class="icon icon-close"
            icon="neb:close"
            @click="${this.__handlers.close}"
          ></neb-icon>
        </div>
      </div>
    `;
  }

  __renderButtons() {
    return this.model.signed
      ? html`
          <neb-button
            id="${ELEMENTS.buttonReopen.id}"
            class="button button-reopen"
            label="Reopen"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.reopen}"
          ></neb-button>
        `
      : html`
          <neb-button
            id="${ELEMENTS.buttonSign.id}"
            class="button button-sign"
            label="Sign"
            .role="${BUTTON_ROLE.CONFIRM}"
            ?disabled="${disableSignIfEncounterOnly(this.model)}"
            .onClick="${this.__handlers.sign}"
          ></neb-button>
        `;
  }
}

customElements.define(
  'neb-charting-encounter-header',
  NebChartingEncounterHeader,
);
