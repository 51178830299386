import { html, css } from 'lit';

import { NebActionBar, ELEMENTS as BASE_ELEMENTS } from './neb-action-bar';
import { BUTTON_ROLE } from './neb-button';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  emailReceiptButton: {
    id: 'button-email-receipt',
  },
};

class NebActionBarPayment extends NebActionBar {
  static get properties() {
    return {
      ...super.properties,
      emailReceiptLabel: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .button-secondary-container {
          display: flex;
          flex-wrap: wrap;
        }

        .button,
        .button-with-dropdown {
          margin-bottom: 20px;
        }

        .button-with-dropdown {
          display: flex;
          margin-right: 10px;
          align-items: center;
        }

        .button-actions {
          width: 25px;
        }
      `,
    ];
  }

  __initState() {
    super.__initState();
    this.emailReceiptLabel = 'Email Receipt';

    this.onEmailReceipt = () => {};
  }

  __initHandlers() {
    super.__initHandlers();

    this.__handlers = {
      ...this.__handlers,
      emailReceipt: () => this.onEmailReceipt(),
    };
  }

  __renderEmailReceiptButton() {
    return this.emailReceiptLabel
      ? html`
          <neb-button
            id="${ELEMENTS.emailReceiptButton.id}"
            class="button"
            .label="${this.emailReceiptLabel}"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.__handlers.emailReceipt}"
          ></neb-button>
        `
      : '';
  }

  __renderCancelButton(buttonStyle) {
    return html`
      <neb-button
        id="${ELEMENTS.cancelButton.id}"
        class="${buttonStyle}"
        .label="${this.cancelLabel}"
        .role="${this.__getCancelButtonRole()}"
        .onClick="${this.__handlers.cancel}"
        ?disabled="${this.processing}"
      ></neb-button>
    `;
  }

  __renderButtonWithDropdown() {
    return html`
      <div class="button-with-dropdown">${this.__renderCancelButton()}</div>
    `;
  }

  render() {
    return html`
      <div class="container">
        ${this.__renderConfirmButton()}

        <div class="button-secondary-container">
          ${this.__renderButtonWithDropdown()}
          ${this.__renderEmailReceiptButton()}${this.__renderRemoveButton()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-action-bar-payment', NebActionBarPayment);
