import { LitElement, html, css } from 'lit';

import { updateCardTextSize } from '../../../../../../src/utils/cards';
import { baseStyles } from '../../../../../neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import TextService from '../../../../../neb-utils/services/text';
import '../../neb-card';

export const CARDS = {
  ACTIVITY: 'activity',
  BALANCE: 'balance',
  CHARGES: 'charges',
  PAYMENTS: 'payments',
};
export const ELEMENTS = {
  activityCard: {
    id: 'activity-card',
    tag: 'neb-card',
  },
  balanceCard: {
    id: 'balance-card',
    tag: 'neb-card',
  },
  chargesCard: {
    id: 'charge-card',
    tag: 'neb-card',
  },
  paymentsCard: {
    id: 'payment-card',
    tag: 'neb-card',
  },
};
const INITIAL_CARD_VALUES = [
  {
    encountersNotBalanced: '0',
    numberOfPurchases: '0',
  },
  {
    open: '$0.00',
    responsible: '$0.00',
  },
  {
    unallocated: '$0.00',
    warning: '0',
  },
  {
    payers: '$0.00',
    patient: '$0.00',
  },
];

class NebLedgerCards extends LitElement {
  static get properties() {
    return {
      cardValues: {
        type: Array,
      },
      selectedCard: {
        type: String,
      },
      hideChargeButton: {
        type: Boolean,
      },
      hideBalanceButton: {
        type: Boolean,
      },
      disableCardClick: {
        type: Boolean,
      },
      loading: {
        type: Boolean,
      },
      layout: {
        type: String,
        reflect: true,
      },
      patient: {
        type: Object,
      },
    };
  }

  constructor() {
    super();

    this.__initState();

    this.__initHandlers();
  }

  __initState() {
    this.__textService = new TextService();
    this.cardValues = INITIAL_CARD_VALUES;
    this.selectedCard = CARDS.ACTIVITY;
    this.hideChargeButton = false;
    this.hideBalanceButton = false;
    this.disableCardClick = false;
    this.loading = false;
    this.layout = '';

    this.onButtonClick = () => {};

    this.onCardSelect = () => {};

    this.onAddPatientPayment = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      addPatientPayment: () => this.onAddPatientPayment(),
      selectActivityCard: () => this.onCardSelect(CARDS.ACTIVITY),
      selectBalanceCard: () => this.onCardSelect(CARDS.BALANCE),
      selectChargesCard: () => this.onCardSelect(CARDS.CHARGES),
      selectPaymentsCard: () => this.onCardSelect(CARDS.PAYMENTS),
      addCharge: () => this.onButtonClick(CARDS.CHARGES),
      addPayment: () => this.onButtonClick(CARDS.PAYMENTS),
      printLastStatement: () => this.onButtonClick(CARDS.BALANCE),
      updateTextSize: () => this.__updateTextSize(),
    };
  }

  connectedCallback() {
    super.connectedCallback();

    this.__textService.connect();
    window.addEventListener('resize', this.__handlers.updateTextSize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__textService.disconnect();
    window.removeEventListener('resize', this.__handlers.updateTextSize);
  }

  __capitalizeTitle(title) {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: grid;
          grid-column-gap: ${CSS_SPACING};
          grid-template-columns: repeat(4, minmax(200px, auto));
          --card-text-size: 1px;
        }
        :host([layout='medium']) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: ${CSS_SPACING};
        }
        :host([layout='small']) {
          grid-template-columns: repeat(1, minmax(200px, auto));
          grid-gap: ${CSS_SPACING};
        }
        .card {
          --text-size: var(--card-text-size);
        }
      `,
    ];
  }

  __buildCardConfig() {
    return [
      {
        id: ELEMENTS.activityCard.id,
        title: CARDS.ACTIVITY,
        cardHandler: this.__handlers.selectActivityCard,
        buildItems: ({ encountersNotBalanced, numberOfPurchases }) => [
          {
            value: encountersNotBalanced,
            description: 'Encounters Not Balanced',
          },
          {
            value: numberOfPurchases,
            description: 'Purchases Last 90 Days',
          },
        ],
      },
      {
        id: ELEMENTS.chargesCard.id,
        title: CARDS.CHARGES,
        buttonConfig: {
          label: 'Add New Charge',
          icon: 'plus',
        },
        buttonHandler: this.__handlers.addCharge,
        cardHandler: this.__handlers.selectChargesCard,
        buildItems: ({ open, responsible, patientResponsible }) => {
          const patientResponsibleValue = responsible || patientResponsible;
          const patientResponsibleDescription = responsible
            ? 'Responsible'
            : 'Pt. Responsible';
          return [
            {
              value: open,
              description: 'Open',
            },
            {
              value: patientResponsibleValue,
              description: patientResponsibleDescription,
            },
          ];
        },
      },
      {
        id: ELEMENTS.paymentsCard.id,
        title: CARDS.PAYMENTS,
        buttonConfig:
          this.layout === 'large' || this.patient
            ? {
                label: !this.patient ? 'Add EOB' : 'Add New Payment',
                icon: 'plus',
              }
            : {},
        buttonHandler: this.__shouldAddPatientPayment()
          ? this.__handlers.addPatientPayment
          : this.__handlers.addPayment,
        cardHandler: this.__handlers.selectPaymentsCard,
        buildItems: ({ unallocated, warning }) => {
          const items = [
            {
              value: unallocated,
              description: 'Unallocated',
            },
          ];

          if (warning) items.push({ value: warning, description: 'Warnings' });

          return items;
        },
      },
      {
        id: ELEMENTS.balanceCard.id,
        title: CARDS.BALANCE,
        buttonConfig: {
          label: 'Print Last Statement',
          icon: 'print',
        },
        buttonHandler: this.__handlers.printLastStatement,
        cardHandler: this.__handlers.selectBalanceCard,
        buildItems: ({ payers, patient, patients }) => {
          const patientValue = patient || patients;
          const patientDescription = patient ? 'Patient' : 'Patients';
          return [
            {
              value: payers,
              description: 'Payers',
            },
            {
              value: patientValue,
              description: patientDescription,
            },
          ];
        },
      },
    ];
  }

  __buildButtonConfig(card) {
    if (card.id === ELEMENTS.chargesCard.id) {
      return this.hideChargeButton ? null : card.buttonConfig;
    }

    if (card.id === ELEMENTS.balanceCard.id) {
      return this.hideBalanceButton ? null : card.buttonConfig;
    }

    return card.buttonConfig;
  }

  __shouldAddPatientPayment() {
    return this.layout === 'small' && this.patient;
  }

  __updateTextSize() {
    const items = this.cardValues.reduce(
      (acc, curr) => [...acc, ...Object.values(curr)],
      [],
    );
    updateCardTextSize(this, items);
  }

  updated(changedProps) {
    if (changedProps.has('cardValues')) {
      setTimeout(() => this.__updateTextSize(), 20);
    }
  }

  render() {
    const cardConfig = this.__buildCardConfig();

    return html`
      ${
        this.cardValues.map((cardValues, i) => {
          const card = cardConfig[i];
          return html`
            <neb-card
              id="${card.id}"
              class="card"
              tabindex="0"
              title="${this.__capitalizeTitle(card.title)}"
              .selected="${this.selectedCard === card.title}"
              .items="${card.buildItems(cardValues)}"
              .buttonConfig="${this.__buildButtonConfig(card)}"
              .onButtonClick="${card.buttonHandler}"
              .onCardClick="${card.cardHandler}"
              .layout="${this.layout}"
              ?disableCardClick="${this.disableCardClick}"
              ?loading="${this.loading}"
            ></neb-card>
          `;
        })
      }
    `;
  }
}

customElements.define('neb-ledger-cards', NebLedgerCards);
