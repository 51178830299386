import '../../../packages/neb-material-design/src/components/neb-md-select';
import '../../../packages/neb-material-design/src/components/neb-md-textfield';
import '../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textarea';
import '../../../packages/neb-lit-components/src/components/inputs/neb-select';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../packages/neb-lit-components/src/components/neb-card-big-icon';
import '../../../packages/neb-lit-components/src/components/neb-calendar-picker';
import '../../../packages/neb-lit-components/src/components/neb-file-select';
import '../../../packages/neb-lit-components/src/components/controls/neb-button-bar';
import '../../../packages/neb-lit-components/src/components/neb-file-preview';

import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import mime from 'mime-types';
import moment from 'moment-timezone';

import { getBillingCodesPayments } from '../../../packages/neb-api-client/src/billing-codes';
import { fetchPdf } from '../../../packages/neb-api-client/src/ledger-superbill-api-client';
import * as patientApiClient from '../../../packages/neb-api-client/src/patient-api-client';
import { getPatientRelationshipsActiveGroup } from '../../../packages/neb-api-client/src/patient-relationship-api-client';
import { getPatientBalance } from '../../../packages/neb-api-client/src/patient-totals-api-client';
import {
  getActivePayerPlans,
  getPayerPlans,
} from '../../../packages/neb-api-client/src/payer-plan-api-client';
import { getCardReaders } from '../../../packages/neb-api-client/src/payments/card-readers-api-client';
import { getMerchantAccounts } from '../../../packages/neb-api-client/src/payments/merchant-accounts-api-client';
import { getPaymentMethods } from '../../../packages/neb-api-client/src/payments/payment-methods-api-client';
import { getProviderUsers } from '../../../packages/neb-api-client/src/practice-users-api-client';
import NebForm, {
  ELEMENTS as ELEMENTS_BASE,
} from '../../../packages/neb-lit-components/src/components/forms/neb-form';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import { POPOVER_POSITION } from '../../../packages/neb-lit-components/src/components/neb-date-picker';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../packages/neb-styles/neb-variables';
import {
  getMimeTypeFromDataUrl,
  readDataUrlFromBlob,
  validateBlob,
  validateImageResolution,
} from '../../../packages/neb-utils/blobProcessor';
import { parseDate } from '../../../packages/neb-utils/date-util';
import {
  GENIUS_SALE_TYPES,
  isApproved,
  formatElectronicPayment,
} from '../../../packages/neb-utils/electronic-payments-util';
import { PAYMENT_METHODS } from '../../../packages/neb-utils/enums';
import {
  formatDollarAmount,
  currencyToCents,
  centsToCurrency,
  objToName,
  DEFAULT_NAME_OPTS,
} from '../../../packages/neb-utils/formatters';
import {
  currency as currencyMask,
  number,
} from '../../../packages/neb-utils/masks';
import { mergeCardReaders } from '../../../packages/neb-utils/neb-payment-util';
import { printPdf } from '../../../packages/neb-utils/neb-pdf-print-util';
import {
  currency,
  ITEM_EMPTY,
  select,
} from '../../../packages/neb-utils/selectors';
import { map } from '../../../packages/neb-utils/utils';
import { required } from '../../../packages/neb-utils/validators';
import { getLocations } from '../../api-clients/locations';
import {
  openPayfacIframePopup,
  openPayfacReaderPopup,
} from '../../features/payfac/utils';
import { openError } from '../../store';
import {
  PAYMENT_TYPE,
  getPreferredPaymentAssociation,
} from '../../utils/payment-associations-util';
import {
  IMG_BAD_FILE_ERROR,
  TEXT_BIG_FILE_EOB,
} from '../../utils/user-message';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  amount: {
    id: 'amount',
    label: 'Amount',
  },
  authEFT: {
    id: 'auth-EFT',
    label: 'Authorization/Check/EFT#',
  },
  cardLastFourDigits: {
    id: 'card-last-four-digits',
    label: 'Last 4 Digits of Card',
  },
  cardOnFile: {
    id: 'card-on-file',
  },
  cardNumberId: {
    id: 'card-number-id',
  },
  cardSaleId: {
    id: 'card-sale-id',
  },
  cardSwipe: {
    id: 'card-swipe',
  },
  dateOfServiceFromDate: {
    id: 'date-of-service-from',
    label: 'Date of Service From',
  },
  dateOfServiceToDate: {
    id: 'date-of-service-to',
    label: 'Date of Service To',
  },
  linkProcessManually: {
    id: 'link-process-manually',
  },
  locationDropdown: {
    id: 'locations-dropdown',
    label: 'Location',
  },
  manualCardEntry: {
    id: 'manual-card-entry',
  },
  note: {
    id: 'note',
    label: 'Ledger Note',
  },
  payerDropdown: {
    id: 'payer-dropdown',
  },
  paymentMethod: {
    id: 'payment-method',
    label: 'Payment Method',
  },
  paymentType: {
    id: 'payment-type',
    label: 'Payment Type',
  },
  providerDropdown: {
    id: 'provider-dropdown',
    label: 'Provider',
  },
  transactionDate: {
    id: 'transaction-date',
    label: 'Transaction Date',
    helperText: 'Required',
  },
  referenceId: {
    id: 'reference-id',
    label: 'Reference ID',
    helperText: '',
  },
  patientBalance: {
    id: 'patient-balance',
  },
  adjustmentAmount: { id: 'adjustmentAmount', label: 'Adjustment Amount' },
  fileSelect: { id: 'file-select' },
  contentPreview: {
    id: 'content-preview-image',
  },
  imagePreview: { id: 'image-preview' },
  deletePdfButton: {
    id: 'button-pdf-delete',
  },
  buttonBar: {
    id: 'button-bar',
  },
};

const DEFAULT_AMOUNT = '$0.00';
const VALID_MIME_TYPE = ['application/pdf'];
const VALID_FILE_EXTENSIONS = [...VALID_MIME_TYPE.map(t => mime.extension(t))];
const MAX_FILE_SIZE = 7 * 1024 * 1024;
const DROP_FILE_TEXT = 'Drop a PDF file here to upload';
const SELECT_FILE_TEXT = 'select a PDF file';

const genDefaultPayerPayment = () => ({
  codePaymentId: {
    id: '',
    label: '',
  },
  payerPlanId: '',
  amount: 0,
  transactionDate: moment(),
  dateOfServiceFrom: '',
  dateOfServiceTo: '',
  paymentMethod: '',
  authEFT: '',
  referenceId: '',
  note: '',
  cardSaleId: null,
  electronicPaymentId: null,
  electronicReferenceId: null,
  maskedCardDescription: null,
  locationId: null,
  s3key: null,
  file: null,
  deletePreviousFile: false,
  adjustmentAmount: 0,
  eobId: null,
  providerId: null,
});

const NO_RESULTS_FOUND = 'No results found';

export class NebFormPayment extends NebForm {
  static get properties() {
    return {
      __paymentCodes: Array,
      __openEdgeEnabled: Boolean,
      __openEdgeConfigured: Boolean,
      __cardReaderConfigured: Boolean,
      __cardsExist: Boolean,
      __cardsOnFile: Array,
      __payerPlanItems: Array,
      __allMerchantAccounts: Array,
      __activeMerchantAccounts: Array,
      __merchantAccountsWithCardReaders: Array,
      __filteredPayerPlanItems: Array,
      __cardReaders: Array,
      __fileBlob: File,
      chargeInfo: Object,
      patientId: String,
      forPayer: Boolean,
      alwaysShowActionBar: Boolean,
      disablePaymentTypeAndAmount: Boolean,
      paymentType: String,
      __patientBalance: Number,
      __providerItems: Array,
      __locationItems: Array,
      __allLocations: Array,
    };
  }

  static createModel() {
    return genDefaultPayerPayment();
  }

  createSelectors() {
    return {
      children: {
        codePaymentId: select(
          this.__paymentCodes,
          this.__codePaymentsDefault(),
          {
            validators: [
              {
                error: 'Required',
                validate: v => v.data,
              },
            ],
          },
        ),
        payerPlanId: select(this.__filteredPayerPlanItems, ITEM_EMPTY, {
          validators: [
            {
              error: 'Required',
              validate: v => !this.forPayer || (v.data && v.data.id),
            },
          ],
        }),
        amount: this.__getAmountSelector(),
        authEFT: {
          validators: [
            {
              error: 'Required',
              validate: v => !this.forPayer || this.__authEFTNotRequired() || v,
            },
          ],
        },
        transactionDate: {
          ignorePristine: true,
          validators: [required()],
          format: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
          unformat: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
        },
        dateOfServiceFrom: {
          validators: [
            {
              error: 'Required',
              validate: v => v || (!v && !this.state.dateOfServiceTo),
            },
          ],
          format: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
          unformat: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
        },
        dateOfServiceTo: {
          validators: [
            {
              error: 'Required',
              validate: v => v || (!v && !this.state.dateOfServiceFrom),
            },
          ],
          format: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
          unformat: date =>
            date
              ? parseDate(date)
                  .startOf('day')
                  .toISOString()
              : null,
        },
        paymentMethod: [required()],
        maskedCardDescription: {
          validators: [
            {
              error: 'Required',
              validate: (v, _, state) => {
                if (
                  state.paymentMethod ===
                    PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD &&
                  state.cardSaleId
                ) {
                  return !!v;
                }
                return true;
              },
            },
            {
              error: 'Must be 4 digits',
              validate: (v, _, state) => {
                if (
                  state.paymentMethod ===
                    PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD &&
                  !this.__openEdgeEnabled
                ) {
                  if (v !== null) {
                    const maskedCardRegexp = /XXXXXXXXXXXX(----|\d\d\d\d)/;
                    return !!maskedCardRegexp.exec(v);
                  }
                  return true;
                }
                return true;
              },
            },
          ],
        },
        adjustmentAmount: currency(),
        providerId: {
          validators: [
            {
              error: 'Required',
              validate: v => {
                if (!this.forPayer) {
                  return v;
                }
                return true;
              },
            },
          ],
        },
        locationId: {
          validators: [
            {
              error: 'Required',
              validate: v => {
                if (!this.forPayer) {
                  return v;
                }
                return true;
              },
            },
          ],
        },
      },
    };
  }

  initState() {
    super.initState();
    this.__paymentCodes = [];
    this.__openEdgeEnabled = false;
    this.__openEdgeConfigured = false;
    this.__cardReaderConfigured = false;

    this.__payerSearchValue = '';
    this.__payerPlanItems = [];
    this.__filteredPayerPlanItems = [];
    this.__allMerchantAccounts = [];
    this.__activeMerchantAccounts = [];
    this.__merchantAccountsWithCardReaders = [];
    this.__disablePaymentType = false;
    this.__cardReaders = [];
    this.__cardsExist = false;
    this.__cardsOnFile = [];
    this.__patientBalance = 0;
    this.__fileBlob = null;
    this.__providerItems = [];
    this.__allLocations = [];
    this.__locationItems = [];

    this.forPayer = false;
    this.chargeInfo = {};
    this.alwaysShowActionBar = false;
    this.disablePaymentTypeAndAmount = false;
    this.locations = [];
    this.paymentType = '';
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      savePayment: (electronicPayment = {}) => {
        if (!this.formService.validate()) return;

        const rawModel = this.formService.build();

        const model = map(rawModel, (_, value) =>
          typeof value === 'string' ? value.trim() : value,
        );
        if (this.__isDebitOrCredit()) model.authEFT = '';

        this.__saving = true;
        this.onSave({
          ...model,
          payerPlanId: model.payerPlanId || undefined,
          ...electronicPayment,
        });
      },
      changePayerPlan: e => {
        const value = e.value === '' ? ITEM_EMPTY : e.value;
        this.formService.apply(e.name, value);
      },
      changeLocation: e => {
        const locationId = e.value ? e.value.id : null;
        this.formService.apply(e.name, locationId);
      },
      changeProvider: e => {
        const providerId = e.value ? e.value.id : null;
        this.formService.apply(e.name, providerId);
      },
      changeMaskedCardDescription: e => {
        this.formService.apply(e.name, `XXXXXXXXXXXX${e.value || '----'}`);
      },
      changePaymentMethod: e => {
        this.formService.apply(e.name, e.value);

        this.__checkSetTransactionDateToToday();
        this.formService.validate();
      },

      cardOnFile: async () => {
        if (!this.formService.validate()) {
          return;
        }

        if (this.__popupIsOpen) {
          return;
        }

        this.__popupIsOpen = true;

        try {
          const rawModel = map(this.formService.build(), (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          const res = await openPopup(POPUP_RENDER_KEYS.CARD_ON_FILE, {
            ...(await this.__getOpenEdgePayfieldsTemplate()),
            merchantAccounts: this.__allMerchantAccounts,
            paymentMethods: this.__cardsOnFile,
            logContent: {
              action: 'payment - cardOnFile - neb-form-payment',
              rawModel,
            },
          });

          if (isApproved(res)) {
            const electronicPayment = formatElectronicPayment(res);

            this.handlers.savePayment(electronicPayment);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.__popupIsOpen = false;
        }
      },

      transactionDateChanged: (date = null) => {
        this.formService.apply(
          'transactionDate',
          date
            ? parseDate(date)
                .startOf('day')
                .toISOString()
            : date,
        );
      },
      dateOfServiceFromDateChanged: (date = null) => {
        this.formService.apply(
          'dateOfServiceFrom',
          date
            ? parseDate(date)
                .startOf('day')
                .toISOString()
            : date,
        );

        this.__updateDateOfServiceToSelectable();
      },
      dateOfServiceToDateChanged: (date = null) => {
        this.formService.apply(
          'dateOfServiceTo',
          date
            ? parseDate(date)
                .startOf('day')
                .toISOString()
            : date,
        );

        this.__updateDateOfServiceFromSelectable();
      },

      // eslint-disable-next-line complexity
      cardSwipe: async () => {
        if (!this.formService.validate()) {
          return;
        }

        if (this.__popupIsOpen) {
          return;
        }

        this.__popupIsOpen = true;

        try {
          const rawModel = this.formService.build();

          const pendingModel = map(rawModel, (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          const res = await openPayfacReaderPopup({
            ...(await this.__getOpenEdgePayfieldsTemplate()),
            merchantAccounts: this.__merchantAccountsWithCardReaders,
            geniusSaleType: GENIUS_SALE_TYPES.SALE,
            title: 'Merchant Account Details',
            subheader:
              'Select the merchant account details for this transaction, then follow the prompts on the card reader.',
            pendingModel: {
              ...pendingModel,
              patientId: this.patientId || null,
              payerPlanId: this.forPayer
                ? this.state.payerPlanId.data.id
                : null,
            },
            logContent: {
              ...pendingModel,
              patientId: this.patientId || null,
              payerPlanId: this.forPayer
                ? this.state.payerPlanId.data.id
                : null,
              action: 'payment - cardSwipe - neb-form-payment',
            },
          });

          if (isApproved(res)) {
            const electronicPayment = formatElectronicPayment(res);

            this.handlers.savePayment(electronicPayment);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.__popupIsOpen = false;
        }
      },

      manualCardEntry: async () => {
        if (!this.formService.validate()) {
          return;
        }

        if (this.__popupIsOpen) {
          return;
        }

        this.__popupIsOpen = true;

        try {
          const rawModel = map(this.formService.build(), (_, value) =>
            typeof value === 'string' ? value.trim() : value,
          );

          const res = await openPayfacIframePopup({
            ...(await this.__getOpenEdgePayfieldsTemplate()),
            merchantAccounts: this.__activeMerchantAccounts,
            logContent: {
              action: 'payment - manualCardEntry - neb-form-payment',
              rawModel,
            },
          });

          if (isApproved(res)) {
            const electronicPayment = formatElectronicPayment(res);

            this.handlers.savePayment(electronicPayment);
          }
        } catch (error) {
          console.error(error);
        } finally {
          this.__popupIsOpen = false;
        }
      },

      toggleOpenEdge: () => {
        this.__openEdgeEnabled = !this.__openEdgeEnabled;

        this.__checkSetTransactionDateToToday();
      },
      searchPayer: e => {
        const searchTerms = e.value
          .toLowerCase()
          .trim()
          .split(' ');

        this.__filteredPayerPlanItems = this.__payerPlanItems.filter(f =>
          searchTerms.every(v => f.label.toLowerCase().search(v) !== -1),
        );

        this.formService.apply('payerPlanId', this.state.payerPlanId);
      },
      invalidFile: () => this.__invalidFile(),
      printReport: () => this.__printReport(),
      deletePdfFromEob: () => {
        if (this.state.s3key !== null) {
          this.formService.apply('deletePreviousFile', 'true');
        }

        this.__fileBlob = null;
        this.formService.apply('file', null);
        this.formService.apply('s3key', null);
      },
    };

    this.__updateDateOfServiceFromSelectable();

    this.__updateDateOfServiceToSelectable();
  }

  __allowZeroAmount() {
    const isEra = this.state.eRA && !this.state.eRA.isLegacy;

    return isEra || this.forPayer;
  }

  __getAmountSelector() {
    return currency({
      validators: [
        required(),
        {
          error: 'Required',
          validate: v => this.__allowZeroAmount() || v !== DEFAULT_AMOUNT,
        },
        {
          error: 'Cannot exceed $200,000.00 for electronic payments',
          validate: v =>
            !this.__shouldShowOpenEdgeButtons() ||
            (this.__shouldShowOpenEdgeButtons() &&
              parseInt(Number(v.replace(/[^0-9.]+/g, '') * 100), 10) <=
                20000000),
        },
        {
          error: 'Required for electronic payments',
          validate: v => {
            const amount = v.replace(',', '');
            return (
              !this.__isDebitOrCredit() ||
              (this.__isDebitOrCredit() &&
                parseFloat(Number(amount.replace('$', ''))) > 0)
            );
          },
        },
        {
          error: `Cannot be less than the allocated amount: ${centsToCurrency(
            this.__getTotalAllocatedAmount(),
          )}`,
          validate: v => {
            const value = currencyToCents(v);
            if (!this.__isEditingPartiallyAllocated()) return true;
            return value >= this.__getTotalAllocatedAmount();
          },
        },
      ],
    });
  }

  async __getAllPayerPlans() {
    const { payerPlan } = await getPayerPlans({
      search: '',
      hideInactive: true,
    });

    return payerPlan;
  }

  async __getPayerPlans() {
    const isPatientLedger = !!this.patientId;

    let activePayerPlans = [];
    let hasActivePayerPlans = false;

    if (isPatientLedger) {
      activePayerPlans = await getActivePayerPlans(this.patientId);
      hasActivePayerPlans = activePayerPlans && activePayerPlans.length;
    }

    const items = hasActivePayerPlans
      ? activePayerPlans
      : await this.__getAllPayerPlans();

    if (this?.model?.payerPlan?.status === 'Inactive') {
      items.push(this.model.payerPlan);
    }

    this.__payerPlanItems = items.map(item => ({
      data: item,
      label: `(${item.alias}) ${item.payerName}`,
    }));

    this.__filteredPayerPlanItems = this.__payerPlanItems;
  }

  async __getCodePayments() {
    const paymentBillingCodes = await getBillingCodesPayments({}, true);
    this.__paymentCodes = paymentBillingCodes
      .filter(
        payment =>
          payment.active &&
          (this.forPayer ? payment.forInsurance : payment.forPatient),
      )
      .map(payment => ({
        data: payment,
        label: `${payment.code} - ${payment.description}`,
      }));
  }

  __getActiveLocations() {
    return this.__locationItems.filter(location => location.active);
  }

  __getLocation() {
    const currentSelectedLocation = this.__allLocations.find(
      location => location.id === this.state.locationId,
    );

    return currentSelectedLocation;
  }

  __getActiveProviders() {
    return this.__providerItems.filter(provider => provider.active);
  }

  __getProvider() {
    const currentSelectedProvider = this.__providerItems.find(
      provider => provider.id === this.state.providerId,
    );

    return currentSelectedProvider;
  }

  __codePaymentsDefault() {
    const defaultKey = this.forPayer ? 'insuranceDefault' : 'patientDefault';
    return this.__paymentCodes.find(({ data }) => data[defaultKey]) || '';
  }

  async __getOpenEdgePayfieldsTemplate() {
    let holderAddress = {};
    let patientName = {};

    if (!this.forPayer) {
      const {
        addresses,
        firstName,
        lastName,
      } = await patientApiClient.fetchOne(this.patientId, true, true);

      holderAddress =
        addresses.length > 0
          ? {
              postalCode: addresses[0].zipcode,
              address1: addresses[0].address1,
              address2: addresses[0].address2,
              city: addresses[0].city,
              state: addresses[0].state,
            }
          : {
              postalCode: '',
              address1: '',
              address2: '',
              city: '',
              state: '',
            };

      patientName = { firstName, lastName };
    }

    return {
      amount: this.state.amount,
      title: 'Card Details',
      subheader: 'Enter in the card details for this transaction.',
      message: 'Payment Amount',
      confirmLabel: 'Submit',
      merchantAccounts: [],
      holderType: this.forPayer ? 'payer' : 'patient',
      paymentType: this.state.codePaymentId,
      holderId: this.forPayer ? this.state.payerPlanId.data.id : this.patientId,
      ...holderAddress,
      ...patientName,
    };
  }

  __updateDateOfServiceFromSelectable() {
    this.handlers.dateOfServiceFromSelectable = date =>
      (!this.state.dateOfServiceTo ||
        date <= parseDate(this.state.dateOfServiceTo).startOf('day')) &&
      date <= parseDate().startOf('day');
  }

  __updateDateOfServiceToSelectable() {
    this.handlers.dateOfServiceToSelectable = date =>
      (!this.state.dateOfServiceFrom ||
        date >= parseDate(this.state.dateOfServiceFrom).startOf('day')) &&
      date <= parseDate().startOf('day');
  }

  __checkSetTransactionDateToToday() {
    if (this.__shouldShowOpenEdgeButtons()) {
      this.formService.apply(
        'transactionDate',
        parseDate()
          .startOf('day')
          .toISOString(),
      );
    }
  }

  __authEFTNotRequired() {
    return (
      this.state.paymentMethod === PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD ||
      this.state.paymentMethod === PAYMENT_METHODS.DIRECT_DEPOSIT
    );
  }

  __isDebitOrCredit() {
    return this.state.paymentMethod === PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD;
  }

  __isASplitPayment() {
    return !!this.state.parentPaymentId;
  }

  __shouldShowProcessManuallyLink() {
    return (
      this.__openEdgeConfigured &&
      this.__isDebitOrCredit() &&
      !this.model.cardSaleId &&
      !this.__isASplitPayment()
    );
  }

  __shouldShowOpenEdgeButtons() {
    return (
      this.__openEdgeConfigured &&
      this.__isDebitOrCredit() &&
      this.__openEdgeEnabled
    );
  }

  __disableCardSwipe() {
    return !this.__cardReaderConfigured || this.__isASplitPayment();
  }

  __disableCardOnFile() {
    return !this.__cardsExist || this.__isASplitPayment();
  }

  __isManualDebitOrCredit() {
    if (this.__isDebitOrCredit()) {
      return (
        (!this.__openEdgeEnabled && this.__openEdgeConfigured) ||
        !this.__openEdgeConfigured
      );
    }

    return false;
  }

  __addLabel(plan) {
    return plan
      ? { ...plan, label: `(${plan.alias}) ${plan.payerName}` }
      : null;
  }

  __computeSelectedDate() {
    return this.state.transactionDate
      ? parseDate(this.state.transactionDate).startOf('day')
      : null;
  }

  __computeDateOfServiceToDate() {
    return this.state.dateOfServiceTo
      ? parseDate(this.state.dateOfServiceTo).startOf('day')
      : null;
  }

  __computeDateOfServiceFromDate() {
    return this.state.dateOfServiceFrom
      ? parseDate(this.state.dateOfServiceFrom).startOf('day')
      : null;
  }

  async __fetchCardsOnFile(merchantAccounts) {
    if (!merchantAccounts.length) return [];

    let relatedPatientIds = [];

    const relationshipGroup = await getPatientRelationshipsActiveGroup(
      this.patientId,
    );

    relatedPatientIds = [
      relationshipGroup.primary,
      ...relationshipGroup.related,
    ];

    const paymentMethods = await getPaymentMethods(
      merchantAccounts[0],
      this.patientId,
      {
        fetchAll: true,
      },
      relatedPatientIds.length > 1 ? relatedPatientIds : null,
    );

    return paymentMethods;
  }

  async setPreferredLocationAndProvider(providerItems, locationItems) {
    if (this.paymentType !== PAYMENT_TYPE.EOB) {
      if (
        !this.model.id &&
        (!this.model.providerId || !this.model.locationId)
      ) {
        const { providerId, locationId } = await getPreferredPaymentAssociation(
          {
            providerItems,
            locationItems,
            patientId: this.patientId,
          },
        );
        this.model.providerId = this.model.providerId
          ? this.model.providerId
          : providerId;

        this.model.locationId = this.model.locationId
          ? this.model.locationId
          : locationId;
      }
    }
  }

  __getLocationItems() {
    const { locations } = store.getState().session.item;
    const userLocations = this.__allLocations.reduce((acc, location) => {
      if (locations.includes(location.id)) {
        acc.push(location);
      }
      return acc;
    }, []);

    return userLocations;
  }

  async load() {
    const [merchantAccounts, cardReaders] = await Promise.all([
      getMerchantAccounts({}, true),
      getCardReaders({}, true),
      this.__getCodePayments(),
      this.__getPayerPlans(),
    ]);

    const providers = await getProviderUsers();
    this.__providerItems = providers.map(provider => ({
      label: objToName(provider.name, DEFAULT_NAME_OPTS),
      ...provider,
    }));

    const allLocations = await getLocations();
    this.__allLocations = allLocations.map(location => ({
      label: location.name,
      ...location,
    }));

    this.__locationItems = this.__getLocationItems();

    await this.setPreferredLocationAndProvider(
      this.__providerItems
        .filter(provider => provider.active)
        .map(item => ({ data: item })),
      this.__locationItems
        .filter(location => location.active)
        .map(item => ({ data: item })),
    );

    this.__allMerchantAccounts = merchantAccounts;

    this.__activeMerchantAccounts = merchantAccounts.filter(
      ({ active }) => active,
    );

    this.__cardReaders = cardReaders;

    if (!this.forPayer) {
      this.__cardsOnFile = await this.__fetchCardsOnFile(
        this.__activeMerchantAccounts,
      );

      this.__cardsExist = this.__cardsOnFile.length > 0;
    }

    if (
      this.__activeMerchantAccounts.length > 0 ||
      !!this.model.electronicPaymentId
    ) {
      this.__openEdgeEnabled =
        !!this.model.cardSaleId || !this.model.maskedCardDescription;

      this.__openEdgeConfigured = true;
    }

    this.__merchantAccountsWithCardReaders = mergeCardReaders(
      this.__activeMerchantAccounts,
      this.__cardReaders,
    );

    this.__cardReaderConfigured =
      this.__merchantAccountsWithCardReaders.length > 0;

    if (!this.forPayer) await this.__loadPatientBalance();
  }

  async __loadPatientBalance() {
    const { balance } = await getPatientBalance(this.patientId);
    this.__patientBalance = balance;
  }

  update(changedProps) {
    if (this.__isDebitOrCredit()) this.state.authEFT = '';
    super.update(changedProps);
  }

  __getTotalAllocatedAmount() {
    if (this.model.credits) {
      return this.model.amount - this.model.available;
    }
    return 0;
  }

  get paymentHasAllocations() {
    return (
      this.state.credits &&
      this.state.credits.some(c => c.allocations && c.allocations.length > 0)
    );
  }

  static get styles() {
    return [
      super.styles,
      css`
        .single-column {
          width: 100%;
        }

        .link-open-edge {
          color: ${CSS_COLOR_HIGHLIGHT};
          cursor: pointer;
          text-decoration: underline;
          align-self: right;
        }

        .font-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .payment-link-container {
          display: flex;
          justify-content: flex-end;
        }

        .patient-balance {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding-left: 20px;
        }

        .file-item {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-gap: 20ch;
        }

        .container-file-upload {
          display: flex;
          flex-direction: column;

          box-sizing: border-box;
        }

        .preview {
          height: 100%;
        }

        .left {
          position: absolute;
          left: 0px;
          padding: 10px;
        }
      `,
    ];
  }

  __renderTransactionDatePicker() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.transactionDate.id}"
        class="single-column"
        name="transactionDate"
        label="${ELEMENTS.transactionDate.label}"
        .selectedDate="${this.__computeSelectedDate()}"
        .onClick="${this.handlers.transactionDateChanged}"
        .onClear="${this.handlers.transactionDateChanged}"
        .invalidText="${this.errors.transactionDate}"
        placeholder="Select Date"
        .manualPopoverPosition="${POPOVER_POSITION.BOTTOM}"
        ?invalid="${this.errors.transactionDate}"
        ?disabled="${
          this.__shouldShowOpenEdgeButtons() || this.__isASplitPayment()
        }"
        helperText="Required"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderDateOfServiceFromDatePicker() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.dateOfServiceFromDate.id}"
        class="single-column"
        name="transactionFromDate"
        label="${ELEMENTS.dateOfServiceFromDate.label}"
        .selectedDate="${this.__computeDateOfServiceFromDate()}"
        .onClick="${this.handlers.dateOfServiceFromDateChanged}"
        .onClear="${this.handlers.dateOfServiceFromDateChanged}"
        .isDateSelectable="${this.handlers.dateOfServiceFromSelectable}"
        .invalidText="${this.errors.dateOfServiceFrom}"
        helperText=" "
        placeholder="Select Date"
        .manualPopoverPosition="${POPOVER_POSITION.BOTTOM}"
        ?invalid="${this.errors.dateOfServiceFrom}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderDateOfServiceToDatePicker() {
    return html`
      <neb-date-picker
        id="${ELEMENTS.dateOfServiceToDate.id}"
        class="single-column"
        name="transactionToDate"
        label="${ELEMENTS.dateOfServiceToDate.label}"
        .selectedDate="${this.__computeDateOfServiceToDate()}"
        .onClick="${this.handlers.dateOfServiceToDateChanged}"
        .onClear="${this.handlers.dateOfServiceToDateChanged}"
        .isDateSelectable="${this.handlers.dateOfServiceToSelectable}"
        .invalidText="${this.errors.dateOfServiceTo}"
        helperText=" "
        placeholder="Select Date"
        .manualPopoverPosition="${POPOVER_POSITION.BOTTOM}"
        ?invalid="${this.errors.dateOfServiceTo}"
        momentFlag
      ></neb-date-picker>
    `;
  }

  __renderDivSpacer() {
    return this.layout !== 'small'
      ? html`
          <div id="spacer"></div>
        `
      : '';
  }

  __renderAuthCheckEFT() {
    if (this.forPayer) {
      return this.__isDebitOrCredit()
        ? ''
        : html`
            <neb-textfield
              id="${ELEMENTS.authEFT.id}"
              name="authEFT"
              maxLength="45"
              class="textfield"
              label="${ELEMENTS.authEFT.label}"
              .onChange="${this.handlers.change}"
              .value="${this.state.authEFT}"
              .error="${this.errors.authEFT}"
              required
              helper="${this.__authEFTNotRequired() ? ' ' : 'Required'}"
              ?disabled="${this.__isDebitOrCredit()}"
              ?invalid="${!!this.errors.authEFT}"
            ></neb-textfield>
          `;
    }

    return this.state.paymentMethod === PAYMENT_METHODS.CHECK ||
      this.state.paymentMethod === PAYMENT_METHODS.DIRECT_DEPOSIT ||
      this.state.paymentMethod === PAYMENT_METHODS.ERA
      ? html`
          <div>
            <neb-md-textfield
              id="${ELEMENTS.authEFT.id}"
              name="authEFT"
              labelText="${ELEMENTS.authEFT.label}"
              maxLength="30"
              helperText=" "
              .value="${this.state.authEFT}"
              ?disabled="${this.__isASplitPayment()}"
              .onChange="${this.handlers.change}"
            ></neb-md-textfield>
            ${this.__renderDivSpacer()}
          </div>
        `
      : '';
  }

  __renderCardClass() {
    return this.forPayer ? 'grid-2' : 'grid-3';
  }

  __renderCards() {
    return !this.model.cardSaleId
      ? html`
          <div class="grid grid-lean span ${this.__renderCardClass()}">
            <neb-card-big-icon
              id="${ELEMENTS.cardSwipe.id}"
              title="Card Swipe"
              icon="creditCard"
              tooltip="${
                !this.__cardReaderConfigured
                  ? 'Set up a card reader in your Merchant Account Settings to enable processing card transactions by swiping a card.'
                  : ''
              }"
              ?selected="${false}"
              ?disabled="${this.__disableCardSwipe()}"
              .onClick="${this.handlers.cardSwipe}"
            ></neb-card-big-icon>
            <neb-card-big-icon
              id="${ELEMENTS.manualCardEntry.id}"
              title="Manual Card Entry"
              icon="keyboard"
              ?selected="${false}"
              ?disabled="${this.__isASplitPayment()}"
              .onClick="${this.handlers.manualCardEntry}"
            ></neb-card-big-icon>
            ${
              !this.forPayer
                ? html`
                    <neb-card-big-icon
                      id="${ELEMENTS.cardOnFile.id}"
                      title="Card on File"
                      icon="cardOnFile"
                      ?selected="${false}"
                      ?disabled="${this.__disableCardOnFile()}"
                      .onClick="${this.handlers.cardOnFile}"
                    ></neb-card-big-icon>
                  `
                : ''
            }
          </div>
        `
      : html`
          <div>
            <div class="font-bold">Card Number</div>
            <div id="${ELEMENTS.cardNumberId.id}">
              ${
                this.model.maskedCardDescription
                  ? this.model.maskedCardDescription
                  : '-'
              }
            </div>
          </div>
          <div>
            <div class="font-bold">Card Sale ID</div>
            <div id="${ELEMENTS.cardSaleId.id}">
              ${this.model.cardSaleId ? this.model.cardSaleId : '-'}
            </div>
          </div>
        `;
  }

  __renderCardLastFourDigits() {
    return this.__isManualDebitOrCredit()
      ? html`
          <div>
            <neb-textfield
              id="${ELEMENTS.cardLastFourDigits.id}"
              class="single-column"
              name="maskedCardDescription"
              label="${ELEMENTS.cardLastFourDigits.label}"
              maxLength="4"
              helper=" "
              .mask="${number}"
              .inputMode="${'numeric'}"
              .value="${
                this.state.maskedCardDescription
                  ? this.state.maskedCardDescription
                      .replaceAll('X', '')
                      .replaceAll('-', '')
                  : ''
              }"
              .error="${this.errors.maskedCardDescription}"
              ?disabled="${this.__isASplitPayment()}"
              .onChange="${this.handlers.changeMaskedCardDescription}"
            ></neb-textfield>
            ${this.__renderDivSpacer()}
          </div>
        `
      : '';
  }

  __renderManualPaymentLink() {
    return this.__shouldShowProcessManuallyLink()
      ? html`
          <div
            <div
              id="${ELEMENTS.linkProcessManually.id}"
              class="link-open-edge"
              @click="${this.handlers.toggleOpenEdge}"
            >
              ${
                this.__openEdgeEnabled
                  ? 'Process Manually'
                  : 'Process Electronically'
              }
            </div>
          </div>
        `
      : '';
  }

  __getPaymentMethods() {
    if (this.forPayer) {
      return [
        PAYMENT_METHODS.CHECK,
        PAYMENT_METHODS.DIRECT_DEPOSIT,
        PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD,
        PAYMENT_METHODS.ERA,
      ];
    }
    return [
      PAYMENT_METHODS.CASH,
      PAYMENT_METHODS.CHECK,
      PAYMENT_METHODS.DEBIT_OR_CREDIT_CARD,
    ];
  }

  __isNonLegacyPayment() {
    return (
      (this.state.eRA && !this.state.eRA.isLegacy) ||
      (this.state.eobPayments &&
        this.state.eobPayments.length &&
        !this.state.eobPayments[0].eOB.isLegacy)
    );
  }

  __isEditingPartiallyAllocated() {
    return !!(this.model.id && this.model.amount && this.model.available);
  }

  __isEditingFullyAllocated() {
    return this.model.id && this.model.amount && this.model.available === 0;
  }

  __patientPaymentAmountDisabled() {
    return (
      this.model.cardSaleId ||
      this.paymentHasAllocations ||
      this.disablePaymentTypeAndAmount ||
      this.__isASplitPayment()
    );
  }

  __payerPaymentAmountDisabled() {
    return (
      this.model.cardSaleId ||
      (this.paymentHasAllocations && !this.__isNonLegacyPayment()) ||
      this.disablePaymentTypeAndAmount ||
      this.__isEditingFullyAllocated() ||
      this.__isASplitPayment()
    );
  }

  __paymentAmountDisabled() {
    return this.forPayer
      ? this.__payerPaymentAmountDisabled()
      : this.__patientPaymentAmountDisabled();
  }

  __paymentMethodDisabled() {
    return this.model.cardSaleId || this.__isASplitPayment();
  }

  __renderPaymentType() {
    return html`
      <neb-select
        id="${ELEMENTS.paymentType.id}"
        name="codePaymentId"
        label="${ELEMENTS.paymentType.label}"
        helper="Required"
        .items="${this.__paymentCodes}"
        .value="${this.state.codePaymentId}"
        .error="${this.errors.codePaymentId}"
        .onChange="${this.handlers.change}"
        ?disabled="${this.disablePaymentTypeAndAmount}"
      ></neb-select>
    `;
  }

  __payerPlanIdValue() {
    return this.state.payerPlanId && this.state.payerPlanId.data.id
      ? this.state.payerPlanId
      : '';
  }

  __renderPayer() {
    return html`
      <neb-select-search
        id="${ELEMENTS.payerDropdown.id}"
        name="payerPlanId"
        label="Payer"
        helper="Required"
        .error="${this.errors.payerPlanId}"
        search="${this.__payerSearchValue}"
        .emptyMessage="${NO_RESULTS_FOUND}"
        .items="${this.__filteredPayerPlanItems}"
        .value="${this.__payerPlanIdValue()}"
        .onSearch="${this.handlers.searchPayer}"
        .onChange="${this.handlers.changePayerPlan}"
        ?disabled="${this.paymentHasAllocations || this.__isASplitPayment()}"
        showSearch
      ></neb-select-search>
    `;
  }

  __renderAmount() {
    return html`
      <neb-textfield
        id="${ELEMENTS.amount.id}"
        name="amount"
        label="${ELEMENTS.amount.label}"
        helper="${this.__allowZeroAmount() ? ' ' : 'Required'}"
        maxLength="11"
        .mask="${currencyMask}"
        .inputMode="${'numeric'}"
        .value="${this.state.amount}"
        .onChange="${this.handlers.change}"
        .error="${this.errors.amount}"
        ?valid="${!this.errors.amount}"
        ?disabled="${this.__paymentAmountDisabled()}"
      ></neb-textfield>
    `;
  }

  __renderAdjustmentAmount() {
    return html`
      <neb-textfield
        id="${ELEMENTS.adjustmentAmount.id}"
        name="${ELEMENTS.adjustmentAmount.id}"
        class="textfield"
        label="${ELEMENTS.adjustmentAmount.label}"
        helper=" "
        maxLength="15"
        ?disabled="${!!this.state.eRA}"
        .value="${this.state.adjustmentAmount || DEFAULT_AMOUNT}"
        .error="${this.errors.adjustmentAmount}"
        .mask="${currencyMask}"
        .inputMode="${'numeric'}"
        .onChange="${this.handlers.change}"
      ></neb-textfield>
    `;
  }

  __isNewDocument() {
    return this.state.s3key === null;
  }

  __invalidFile() {
    store.dispatch(openError(IMG_BAD_FILE_ERROR));
    this.__fileBlob = null;
  }

  __printReport() {
    return printPdf(fetchPdf(this.model.s3key));
  }

  __renderFileUpload() {
    return html`
      <div id="containerFileUpload" class="container-file-upload file-item">
        <neb-file-select
          id="${ELEMENTS.fileSelect.id}"
          class="input-file spacer"
          .onSelectFile="${this.__processFileBlobForPreview.bind(this)}"
          .touchDevice="${this.touchDevice}"
          .showPreview="${this.__fileBlob !== null}"
          .allowedExtensions="${VALID_FILE_EXTENSIONS}"
          .validMimeTypes="${VALID_MIME_TYPE}"
          .maxFileSize="${MAX_FILE_SIZE}"
          .invalidFileMessage="${TEXT_BIG_FILE_EOB}"
          .dropFileText="${DROP_FILE_TEXT}"
          .selectFileText="${SELECT_FILE_TEXT}"
        >
          ${this.__fileBlob ? this.__renderPreview() : ''}
        </neb-file-select>
      </div>
    `;
  }

  __genActions() {
    return [
      {
        name: 'reportID',
        label: 'EOB Report',
        icon: 'receipt',
        onClick: this.handlers.printReport,
        disabled: false,
      },
    ];
  }

  __renderReportEobLinK() {
    return html`
      <neb-button-bar
        id="${ELEMENTS.buttonBar.id}"
        class="pad"
        .config="${this.__genActions()}"
      ></neb-button-bar>
    `;
  }

  __renderPreview() {
    return html`
      <div id="${ELEMENTS.contentPreview.id}" class="content-container left">
        <neb-file-preview
          id="${ELEMENTS.imagePreview.id}"
          class="preview"
          .fileBlob="${this.__fileBlob}"
          .onInvalidFile="${this.handlers.invalidFile}"
        ></neb-file-preview>
      </div>
    `;
  }

  async __processFileBlobForPreview(fileBlob) {
    if (
      validateBlob(fileBlob, MAX_FILE_SIZE, VALID_MIME_TYPE) &&
      (await validateImageResolution(fileBlob))
    ) {
      await this.__readFile(fileBlob);
    }
  }

  async __readFile(blob) {
    if (blob) {
      let pdf = await readDataUrlFromBlob(blob);
      pdf = pdf.replace(`data:${getMimeTypeFromDataUrl(pdf)};base64,`, '');

      this.__fileBlob = blob;
      this.formService.apply('file', pdf);
    }
  }

  __showDeleteButton() {
    return this.__fileBlob !== null || this.state.s3key !== null;
  }

  __renderDeleteButton() {
    return this.__showDeleteButton()
      ? html`
          <div class="container-button">
            <neb-button
              id="${ELEMENTS.deletePdfButton.id}"
              class="button button-delete"
              label="Remove document"
              .role="${BUTTON_ROLE.DELETE}"
              ?disabled="${!this.__showDeleteButton()}"
              .onClick="${this.handlers.deletePdfFromEob}"
            ></neb-button>
          </div>
        `
      : html``;
  }

  __renderUploadArea() {
    return html`
      ${
        this.__isNewDocument()
          ? this.__renderFileUpload()
          : this.__renderReportEobLinK()
      }
    `;
  }

  __renderPaymentMethod() {
    const paymentMethods = this.__getPaymentMethods();

    return html`
      <neb-select
        id="${ELEMENTS.paymentMethod.id}"
        name="paymentMethod"
        label="${ELEMENTS.paymentMethod.label}"
        helper="Required"
        .items="${paymentMethods}"
        .value="${this.state.paymentMethod}"
        .error="${this.errors.paymentMethod}"
        .onChange="${this.handlers.changePaymentMethod}"
        ?disabled="${this.__paymentMethodDisabled()}"
      ></neb-select>
    `;
  }

  __renderReferenceId() {
    return html`
      <neb-md-textfield
        id="${ELEMENTS.referenceId.id}"
        name="referenceId"
        labelText="${ELEMENTS.referenceId.label}"
        helperText=" "
        maxLength="50"
        .value="${this.state.referenceId}"
        .onChange="${this.handlers.change}"
        ?disabled="${this.__isASplitPayment()}"
        disableValidation
      ></neb-md-textfield>
    `;
  }

  __renderDocumentFields() {
    if (
      this.state.eRA ||
      (this.state.eobPayments && this.state.eobPayments.length === 0) ||
      (this.state.eobPayments && this.state.eobPayments[0].eOB.isLegacy)
    ) {
      return '';
    }

    return html`
      ${this.__renderUploadArea()} ${this.__renderDeleteButton()}
    `;
  }

  __getPayerFields() {
    return html`
      ${this.__renderPaymentType()} ${this.__renderPayer()}
      ${this.__renderTransactionDatePicker()} ${this.__renderReferenceId()}
      ${this.__renderAmount()} ${this.__renderAdjustmentAmount()}
      ${this.__renderDateOfServiceFromDatePicker()}
      ${this.__renderDateOfServiceToDatePicker()}
      ${this.__renderPaymentMethod()} ${this.__renderAuthCheckEFT()}
      ${this.__renderCardLastFourDigits()}
      ${this.__shouldShowOpenEdgeButtons() ? this.__renderCards() : ''}
      ${this.__renderDocumentFields()}
    `;
  }

  __renderProviderLocationFields() {
    return html`
      <neb-select
        id="${ELEMENTS.providerDropdown.id}"
        name="providerId"
        label="${ELEMENTS.providerDropdown.label}"
        helper="Required"
        .items="${this.__getActiveProviders()}"
        .value="${this.__getProvider()}"
        .error="${this.errors.providerId}"
        .onChange="${this.handlers.changeProvider}"
      ></neb-select>
      <neb-select
        id="${ELEMENTS.locationDropdown.id}"
        name="locationId"
        label="${ELEMENTS.locationDropdown.label}"
        helper="Required"
        .items="${this.__getActiveLocations()}"
        .value="${this.__getLocation()}"
        .error="${this.errors.locationId}"
        .onChange="${this.handlers.changeLocation}"
      ></neb-select>
    `;
  }

  __getFields() {
    return this.forPayer
      ? this.__getPayerFields()
      : html`
          ${this.__renderPaymentType()} ${this.__renderAmount()}
          ${this.__renderPaymentMethod()}
          ${this.__renderTransactionDatePicker()}
          ${this.__renderConditionalFields()}
          ${this.__renderProviderLocationFields()}
        `;
  }

  __renderPatientBalance() {
    return html`
      <div id="${ELEMENTS.patientBalance.id}" class="patient-balance">
        Patient Balance: ${formatDollarAmount(this.__patientBalance)}
      </div>
    `;
  }

  __renderConditionalFields() {
    return html`
      ${this.__renderAuthCheckEFT()} ${this.__renderCardLastFourDigits()}
      ${this.__shouldShowOpenEdgeButtons() ? this.__renderCards() : ''}
    `;
  }

  __renderNoteField() {
    return !this.model.id
      ? html`
          <neb-textarea
            id="${ELEMENTS.note.id}"
            class="span"
            name="note"
            label="${ELEMENTS.note.label}"
            .value="${this.state.note || ''}"
            .onChange="${this.handlers.change}"
            maxlength="1000"
            showCount
          ></neb-textarea>
        `
      : '';
  }

  __renderFormContent() {
    return html`
      ${this.__getFields()} ${this.__renderNoteField()}

      <div class="payment-link-container span">
        ${this.__renderManualPaymentLink()}
      </div>
    `;
  }

  renderActionBar() {
    return (this.alwaysShowActionBar || this.__dirty) &&
      (!this.__shouldShowOpenEdgeButtons() || this.model.cardSaleId)
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            class="action-bar"
            confirmLabel="${this.model.id ? 'Save' : 'Post'}"
            cancelLabel="${this.cancelLabel}"
            .onConfirm="${this.handlers.savePayment}"
            .onCancel="${this.handlers.cancel}"
          ></neb-action-bar>
        `
      : '';
  }

  renderContent() {
    return html`
      ${this.forPayer ? '' : this.__renderPatientBalance()}
      <div id="payer-payment-container" class="grid grid-2 grid-span-mobile">
        ${this.__renderFormContent()}
      </div>
    `;
  }
}

customElements.define('neb-form-payment', NebFormPayment);
