import './neb-calendar-day-column';

import { css, html } from 'lit';

import { CALENDAR_TYPES } from '../../../packages/neb-utils/calendar-resources-util';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_WHITE,
  CSS_FONT_SIZE_BODY,
} from '../../styles';

import {
  ELEMENTS as ELEMENTS_BASE,
  NebBaseCalendarView,
} from './neb-base-calendar-view';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  calendarDayColumns: { selector: 'neb-calendar-day-column' },
  roomHeaders: { selector: '.room-header' },
};

const VIEWPORT_OFFSET = 46;

class NebRoomCalendarDayView extends NebBaseCalendarView {
  static get properties() {
    return {
      targetDate: String,
      rooms: Array,
      location: Object,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .columns-header-container-layout,
        .columns-container-layout {
          position: relative;
          display: flex;
        }

        .column-header {
          flex: 1;
          min-width: 300px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          font-size: ${CSS_FONT_SIZE_BODY};
          font-weight: bold;
        }

        .day-column {
          flex: 1;
          min-width: 300px;
          border-right: 1px solid ${CSS_COLOR_GREY_2};
          border-top: 1px solid ${CSS_COLOR_GREY_2};
          border-left: 1px solid ${CSS_COLOR_GREY_2};
          margin-left: 10px;
        }

        .day-column:first-child {
          margin-left: 0;
        }

        .container-column-header {
          display: flex;
          flex: 1 0 0;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-column-header:not(:first-child) {
          margin-left: 10px;
        }

        .room-header {
          margin-bottom: 31px;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.rooms = [];
    this.location = {};
    this.targetDate = '';
    this.viewportTopOffset = VIEWPORT_OFFSET;
  }

  renderColumnHeaders() {
    return this.rooms.map(
      room => html`
        <div class="container-column-header">
          <div class="room-header column-header">${room.name}</div>
        </div>
      `,
    );
  }

  renderColumns() {
    return this.rooms.map(room => {
      const { events = null, masked = null } = this.getEvents(
        this.targetDate,
        this.location.id,
        room.id,
      );

      return html`
        <neb-calendar-day-column
          class="day-column"
          type="${CALENDAR_TYPES.ROOM}"
          .events="${events}"
          .maskedSlots="${masked}"
          .locationId="${this.location.id}"
          .resourceId="${room.id}"
          .date="${this.targetDate}"
          .zoomInterval="${this.zoomInterval}"
          .isDragging="${this.isDragging}"
          .active="${this.location.active}"
          .start="${this.startMinute}"
          .end="${this.endMinute}"
          .onUpdateDragging="${this.handlers.updateDragging}"
          .onUpdateCalendar="${this.handlers.updateCalendar}"
        ></neb-calendar-day-column>
      `;
    });
  }
}

customElements.define('neb-room-calendar-day-view', NebRoomCalendarDayView);
